/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { WtTheme } from '../../controller/theme'
import { useHistory } from 'react-router-dom'
import { DemoStartDialog } from '../../controller/BaseTool'
import { LoginValidate } from '../../controller/caller'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'

export const DemoApp = () => {
    const switchBackToAccount = () => {
        let uInfo = JSON.parse(localStorage.getItem("uInfo"))

        localStorage.setItem("userId", uInfo.emp._id)
        localStorage.setItem("userWhatsApp", uInfo.emp.whatsapp)
        localStorage.setItem("userName", uInfo.emp.name)
        localStorage.setItem("userEmail", uInfo.emp.email)
        localStorage.setItem("userEmailVerified", uInfo.emp.emailVerified)
        localStorage.setItem("userWhatsAppVerified", uInfo.emp.whatsappVerified)
        localStorage.setItem("bId", uInfo.business._id)
        localStorage.setItem("bName", uInfo.business.name)
        localStorage.setItem("bWebsite", uInfo.business.website)
        localStorage.setItem("userRole", uInfo.business.role)
        localStorage.setItem("onboardingStatus", uInfo.business.onboardingStatus)
        localStorage.setItem('isDemo', false)
        localStorage.setItem("userAuthToken", uInfo.authToken)

        localStorage.removeItem('c1')
        localStorage.removeItem('cObj')
        localStorage.removeItem('demoMobileNo')
        localStorage.removeItem("userEmailT")
        localStorage.removeItem("userNameT")

        window.location.href = "/dashboard"
    }

    const [openDemoStartDialog, setOpenDemoStartDialog] = useState({ open: false, onClose: null })
    const openDemoStartDialogBox = () => setOpenDemoStartDialog({ open: true, onClose: () => { setOpenDemoStartDialog(false) } })

    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <section>
            <Container className="m-3" style={{ width: '98%' }} fluid>
                <Row className="p-4" style={{ border: '1px solid ' + WtColor.LineColor, borderRadius: '8px' }}>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="">
                            <p style={{ color: WtColor.TextMidGrey, fontSize: '20px', fontWeight: '500' }}>Dashboard Demo without setup</p>
                            <p style={{ color: WtColor.TextLightGrey, fontSize: '14px', fontWeight: '400' }}>
                                {
                                    localStorage.getItem('isDemo') !== 'true' ?
                                        'New user? Want to try demo of the dashboard before setup, you can do that from here.' :
                                        'You are currently a Demo User.'
                                }
                            </p>
                            <p style={{ color: WtColor.TextLightGrey, fontSize: '14px', fontWeight: '400' }}>
                                {
                                    localStorage.getItem('isDemo') !== 'true' ?
                                        'You can switch back to your account anytime.' :
                                        'You can use demo again anytime.'
                                }
                            </p>
                            <br />
                            <p style={{ color: WtColor.TextLightGrey, fontSize: '12px', fontWeight: '400' }}>
                                {
                                    localStorage.getItem('isDemo') !== 'true' ?
                                        'Try Demo now' : 'Switch Back to your Account'
                                }
                            </p>
                            {
                                localStorage.getItem('isDemo') !== 'true' ?
                                    <button onClick={() => LoginValidate() ? openDemoStartDialogBox() : openURL(WtUrl.loginUrl)} className="btn btn-sm text-white theme_bg ps-3 pe-3 rounded-3" style={{ fontWeight: '500' }}>Try Demo</button> :
                                    <button onClick={() => switchBackToAccount()} className="btn btn-sm text-white theme_bg ps-3 pe-3 rounded-3" style={{ fontWeight: '500' }}>End Demo</button>
                            }
                        </div>
                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div>
                            <p className="float-end">
                                <img src={WtTheme.ImageBaseUrl + '/demo_dashboard.png'} className="img-fluid" style={{ width: '400px' }} alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <DemoStartDialog open={openDemoStartDialog.open} onClose={openDemoStartDialog.onClose} />
        </section>
    )
}

export const DemoForHomePageApp = () => {
    const [openDemoStartDialog, setOpenDemoStartDialog] = useState({ open: false, onClose: null })
    const openDemoStartDialogBox = () => setOpenDemoStartDialog({ open: true, onClose: () => { setOpenDemoStartDialog(false) } })

    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <section>
            <Container className="m-3" style={{ width: '98%' }} fluid>
                <Row className="p-4" style={{ border: '1px solid ' + WtColor.LineColor, borderRadius: '8px' }}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="mb-5">
                            <p className="text-center" style={{ fontSize: '24px', fontWeight: '500' }}>Want to Experience our Features Live even before you do the Setup</p>
                            <p className="text-center" style={{ fontSize: '20px', fontWeight: '400' }}>Hurray! We have a solution for that</p>
                        </div>
                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div>
                            <p className="float-end">
                                <img src={WtTheme.ImageBaseUrl + '/demo_dashboard.png'} className="img-fluid" style={{ width: '400px' }} alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="">
                            <p style={{ color: WtColor.TextMidGrey, fontSize: '18px', fontWeight: '400' }}>
                                You can now use the WhatsTool Business features even before setting up your Api.
                            </p>
                            <p style={{ color: WtColor.TextMidGrey, fontSize: '18px', fontWeight: '400' }}>
                                Try our Demo to deeply understand how we work, by using the demo number we give and with the pre setted data.
                            </p>
                            <br />

                            <button onClick={() => LoginValidate() ? openDemoStartDialogBox() : openURL(WtUrl.loginUrl)} className="btn btn-sm text-white theme_bg ps-3 pe-3 rounded-3" style={{ fontWeight: '500' }}>Try Demo</button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <DemoStartDialog open={openDemoStartDialog.open} onClose={openDemoStartDialog.onClose} />
        </section>
    )
}