/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-array-constructor */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import './index.css'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, IconButton, Typography, TextField, Avatar, Slide, InputAdornment, List, ListItemButton, ListItem, ListItemAvatar, ListItemText, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem, CircularProgress, Box, Tooltip, Checkbox, FormGroup } from '@mui/material'
import { Close, TaskAltOutlined, EastOutlined, KeyboardBackspaceOutlined, PictureAsPdfOutlined } from '@mui/icons-material'
import { Table, Form, Container, Row, Col } from 'react-bootstrap'
import { createLabelsAPI, fetchAllQuickReplyAPI, updateLabelsAPI, createAttributesAPI, updateAttributesAPI, fetchAllAttributesAPI, assignAttributesAPI, createUpdateQuickReplyAPI, createContactsAPI, updateContactsAPI, uploadCSVAPI, fetchCampaignAnalytics, fetchChannels, razorpayPaymentAPI, createChannelAPI, createUpdateEmployeeAPI, fetchCampaignSpecificAnalyticsV2, demoLoginAPI, uploadOwnMedia, fetchCampaignSpecificAnalyticsV3 } from './APIs'
import { WtTheme } from './theme'
import { now } from 'moment'
import { DefaultMediaUIPreview, MediaUI, TemplateUI } from './BaseToolUI'
import { Link, useHistory } from 'react-router-dom'
import FormData from 'form-data'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import { Player } from '@lottiefiles/react-lottie-player'
import { AppOnboardingUI } from '../view/OnBoarding/App'
import { Env } from './constant'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import axios from 'axios'
import globalContext from '../context/global/globalContext'
import ReactGA from 'react-ga'
import { WtUrl } from '../constants/urls'
import { WtColor } from '../constants/colours'
import ReactAudioPlayer from 'react-audio-player'
import { WtFile } from '../constants/files'
import { WtString } from '../constants/strings'
var moment = require('moment')

ReactGA.initialize(Env.analyticsTrackID)
// analyticsEvent(localStorage.getItem("userName"), "logout button clicked", window.location.search)

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

export function analyticsEvent(category, action, label) { ReactGA.event({ 'category': category, 'action': action, 'label': label }) }

export const RemainingTimeSlot = (val, useFor) => {
    let oneDay = 86400000
    let lastMessageTime = val
    let currentSystemTime = now()
    let remTimeCalc = ((lastMessageTime + oneDay) - currentSystemTime)

    let hours = parseInt(((remTimeCalc / (1000 * 60 * 60))) % 24)
    let min = parseInt(((remTimeCalc / (1000 * 60))) % 60)

    let remTimeUI = (remTimeCalc <= 0) ? 0 : hours + ":" + min
    if (useFor === "ui") {
        return remTimeUI
    } else {
        return ((remTimeCalc <= 0) ? 0 : remTimeCalc)
    }
}
export const ShowTime = (val) => {
    return moment.unix((val) / 1000).format("h:mm")
}
export const ShowDateTime = (val) => {
    return moment.unix((val) / 1000).format("DD MMM, YYYY h:mm a")
}
export const ShowDate = (val) => {
    return moment.unix((val) / 1000).format("DD MMM, YYYY")
}
export const ShowMonth = (val) => {
    return moment.unix((val) / 1000).format("MMM")
}
export const ShowNextMonth = (val) => {
    return moment.unix((val) / 1000).add(1, 'months').format("MMM")
}
export const ShowYear = (val) => {
    return moment.unix((val) / 1000).format("YYYY")
}
export const ShowNextYear = (val) => {
    return moment.unix((val) / 1000).add(1, 'years').format("YYYY")
}
export const ShowTimeDayDate = (val) => {
    // Use For Showing Chat timing
    let time = ""
    var incomingVal = moment.unix((val) / 1000).format("DD MMM, YYYY")
    let today = moment().endOf("day").format("DD MMM, YYYY")
    let yesterday = moment().subtract(1, 'day').format("DD MMM, YYYY")
    if (incomingVal === today) {
        time = moment.unix((val) / 1000).format("h:mm a")
    } else if (incomingVal === yesterday) {
        time = moment.unix((val) / 1000).format("h:mm a ") + "yesterday"
    } else {
        time = moment.unix((val) / 1000).format("h:mm a DD MMM, YYYY")
    }

    return time
}
export const ShowTimeDayDateInChat = (val) => {
    // Use For Showing Chat timing
    let time = ""
    var incomingVal = moment.unix((val) / 1000).format("DD MMM, YYYY")
    let today = moment().endOf("day").format("DD MMM, YYYY")
    let yesterday = moment().subtract(1, 'day').format("DD MMM, YYYY")
    if (incomingVal === today) {
        time = moment.unix((val) / 1000).format("h:mm a")
    } else if (incomingVal === yesterday) {
        time = "yesterday"
    } else {
        time = moment.unix((val) / 1000).format("DD MMM, YYYY")
    }

    return time
}
export const copyToClipboard = (evt) => { navigator.clipboard.writeText(evt) }

export const generateKey = (val) => { return `${val}_${new Date().getTime()}` }


export const useOnScreenObserver = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false)
    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )
    useEffect(() => {
        observer.observe(ref.current)
        return () => { observer.disconnect() }
    }, [])
    return isIntersecting
}


export const ChatTextFormater = (text) => {
    let bold = /\*(.*?)\*/gm
    let strike = /~(.*?)~/gm
    let italic = /_(.*?)_/gm
    let newline = /\n/gm
    let html = text?.replace(bold, '<strong>$1</strong>');
    html = html?.replace(strike, '<strike>$1</strike>')
    html = html?.replace(italic, '<em>$1</em>')
    html = html?.replace(newline, '<br/>')
    return html;
}
export const templateVariableSetter = (message, variables) => {
    let count = (message.match(/[^{\}]+(?=}{2})/g) || []).length
    let j = 1
    let newMsg = message
    for (let index = 0; index < count; index++) {
        newMsg = newMsg.replaceAll('{{' + j + '}}', variables[j - 1]?.text ? variables[j - 1]?.text : '{{' + j + '}}')
        j++
    }
    return ChatTextFormater(newMsg)
}

export const checkTemplateVariables = (input) => {
    let count = (input.match(/[^{\}]+(?=}{2})/g) || [])
    return count
}
export const checkVar = (input) => {
    let count = (input.match(/[^{\}]+(?=}{2})/g) || []).length
    let j = 1
    for (let index = 0; index < count; index++) {
        if (input.includes('{{' + j + '}}')) {
            j++
        }
    }

    return j - 1
}




function TransitionDown(props) { return <Slide {...props} direction="down" /> }
export const ShowSnackbar = ({ open, errorMsg, errorMsgBg, close }) => {
    return (
        <Snackbar open={open} autoHideDuration={2000} TransitionComponent={TransitionDown} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={close}>
            <div className="text-white ps-5 pt-2 pb-1" style={{ background: errorMsgBg, display: "flex", borderRadius: "6px" }} >
                <img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} style={{ width: "24px", height: '24px', marginLeft: "-36px", marginTop: "3px" }} alt={WtTheme.SiteName} />
                <Typography style={{ marginTop: "3px" }} className="ms-3">{errorMsg}</Typography>
                <IconButton className="ms-5 me-2" size="small" aria-label="close" color="inherit" onClick={close}><Close fontSize="small" /></IconButton>
            </div>
        </Snackbar>
    )
}


export function NoChannelDialog({ open }) {
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, textTransform: "none", boxShadow: "none" }

    const openUrl = (url) => { window.location.replace(url) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                Channel is not yet Created !!!
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }}>
                    Go to Dashboard to Complete the OnBoarding process for creating Channel...
                </DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => openUrl(Env.baseUI_URL + "/dashboard")} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Go to Dashboard</button>
            </DialogContent>
        </Dialog>
    )
}


export function AppOnboardingDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <AppOnboardingUI onCloseHere={onCloseHere} />
        </Dialog>
    )
}


export function ConfirmDialog({ open, title, message, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => onCloseHere(true)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Yes</button>
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">No</button>
            </DialogContent>
        </Dialog>
    )
}

export function ConfirmcustomBtnDialog({ open, title, message, cancelBtn, continueBtn, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => onCloseHere(true)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">{continueBtn}</button>
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">{cancelBtn}</button>
            </DialogContent>
        </Dialog>
    )
}

export function ConfirmSingleActionForTemplateDialog({ open, title, message, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => onCloseHere(true)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Okay</button>
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Cancel</button>
            </DialogContent>
        </Dialog>
    )
}
export function ConfirmSingleActionDialog({ open, title, message, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => onCloseHere(true)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Save</button>
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Cancel</button>
            </DialogContent>
        </Dialog>
    )
}


export function LoginSignupDialog({ open, purpose, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    let title = ''
    let message = 'Please Login to Buy this Plan'

    const history = useHistory()
    const openURL = (url) => {
        history.push(url)
        onCloseHere(true)
    }

    if (purpose === 'free') title = 'Get started with Free Plan'
    if (purpose === 'growth') title = 'Buy Growth Plan'
    if (purpose === 'pro') title = 'Buy Pro Plan'
    if (purpose === 'enterprise') title = 'Buy Enterprise Plan'
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: purpose === 'free' ? 'Please Login to continue with Free Plan' : message }}></DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => openURL('/login')} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Login</button>
            </DialogContent>
        </Dialog>
    )
}




// Demo Work
export function DemoStartDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const [ui, setUi] = useState('start_demo') // start_demo || country_code || completion_ui
    const handleUi = (val) => setUi(val)

    const [countryCodeLs, setCountryCodeLs] = useState([])
    const [countryCode, setCountryCode] = useState('91')
    const handleCountryCode = (val) => {
        setCountryCode(val)
        setUi('start_demo')
    }

    const getCountryCode = async () => {
        const countryCodeResponse = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
        setCountryCodeLs(await countryCodeResponse.json())
    }
    const [filterList, setFilterList] = useState([])
    useEffect(() => { setFilterList(countryCodeLs) }, [countryCodeLs])
    const searchItems = (searchValue) => {
        const filteredData = countryCodeLs.filter((item) => { return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase()) })
        if (filteredData.length !== 0) {
            setFilterList(filteredData)
        } else {
            setFilterList([])
        }
    }

    const [whatsapp, setWhatsApp] = useState('')
    const handleWhatsApp = (val) => {
        let number = val.replace(/[^0-9]/ig, "")
        if (number.length < 16) setWhatsApp(number)
    }

    useEffect(() => { getCountryCode() }, [])

    const tryDemo = async () => {
        if (whatsapp !== "") {
            try {
                let data = { "mobile": countryCode + whatsapp, 'name': localStorage.getItem('userName'), 'businessId': localStorage.getItem('bId') }
                const response = await demoLoginAPI(data)
                if (response.status === 200) {
                    setUi('completion_ui')

                    localStorage.setItem("userId", response.data.data.emp._id)
                    localStorage.setItem("userWhatsApp", countryCode + whatsapp)
                    localStorage.setItem("userName", response.data.data.emp.name)
                    localStorage.setItem("userEmail", response.data.data.emp.email)
                    localStorage.setItem("userEmailVerified", response.data.data.emp.emailVerified)
                    localStorage.setItem("userWhatsAppVerified", response.data.data.emp.whatsappVerified)
                    localStorage.setItem("bId", response.data.data.business._id)
                    localStorage.setItem("bName", response.data.data.business.name)
                    localStorage.setItem("bWebsite", response.data.data.business.website)
                    localStorage.setItem("userRole", response.data.data.business.role)
                    localStorage.setItem("onboardingStatus", response.data.data.business.onboardingStatus)
                    localStorage.setItem('isDemo', true)
                    localStorage.setItem('demoMobileNo', countryCode + whatsapp)
                    response.data.data?.channels?.map((v, i) => {
                        localStorage.setItem("c" + (i + 1), response.data.data?.channels[i]?.whatsapp)
                        localStorage.setItem("cObj", JSON.stringify(response.data.data?.channels))
                    })
                    localStorage.setItem("userAuthToken", response.data.data.authToken)
                    localStorage.removeItem("userEmailT")
                    localStorage.removeItem("userNameT")

                    setTimeout(() => {
                        onCloseHere(true)
                        window.location.href = "/dashboard"
                    }, 2000);
                }
            } catch (error) { }
        }
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"lg"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogContent>
                <div className='hand' style={{ display: "flex" }}>
                    <p style={{ flex: 1 }}><KeyboardBackspaceOutlined style={{ display: ui === 'start_demo' || ui === 'completion_ui' ? 'none' : 'block', color: WtColor.TextLightGrey }} onClick={() => handleUi('start_demo')} /></p>
                    <p><Close style={{ color: WtColor.TextLightGrey }} onClick={() => onCloseHere(false)} /></p>
                </div>
                <Container className="mt-3" fluid>
                    {
                        ui === "start_demo" &&
                        <>
                            <Row>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
                                    <div style={{ lineHeight: '18px' }}>
                                        <p style={{ color: WtColor.TextLightGrey }}>As a Demo User,</p>
                                        <p style={{ color: WtColor.TextDarkColor }}>You can check the working of the features with the pre Data already set.</p>
                                        <p style={{ color: WtColor.TextDarkColor }}>You will using the features with the demo number we give.</p>
                                        <p style={{ color: WtColor.TextDarkColor }}>Your Demo Period will be one month.</p>
                                        <p style={{ color: WtColor.TextDarkColor }}>After your Demo period you will be switched back to your account where you need to do your setup to use the features</p>
                                    </div>
                                </Col>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
                                    <div>
                                        <p className="float-end">
                                            <img src={WtTheme.ImageBaseUrl + '/demo_dashboard.png'} className="img-fluid" style={{ width: '400px' }} alt={WtTheme.SiteName} />
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <TextField
                                    value={whatsapp} onChange={(e) => handleWhatsApp(e.target.value)}
                                    label="Enter Your WhatsApp Number" id="outlined-start-adornment" size='small' sx={{ width: '40ch' }}
                                    InputProps={{ startAdornment: <InputAdornment position="start" className="hand" onClick={() => handleUi('country_code')}>+{countryCode} | </InputAdornment> }} />
                                <br />
                                <button onClick={() => tryDemo()} className="btn btn-sm text-white theme_bg mt-4 mb-5 rounded-3 ps-3 pe-3" disabled={whatsapp !== '' && whatsapp.length > 5 && whatsapp.length < 16 ? false : true}>Start Demo</button>
                            </div>
                        </>
                    }
                    {
                        ui === "country_code" &&
                        <div>
                            <div className="m-3">
                                <TextField fullWidth onChange={(e) => searchItems(e.target.value)} type="text" label="Search Country Code" variant="outlined" />
                            </div>
                            <List sx={{ width: '100%', maxWidth: 420, bgcolor: 'background.paper' }}>
                                {
                                    filterList?.map((v, i) => {
                                        return (
                                            <ListItemButton className="hand" key={i}>
                                                <ListItem onClick={() => handleCountryCode(v.dial_code?.slice(1))} >
                                                    <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                                                    <ListItemText primary={v.name} secondary={v.dial_code} />
                                                </ListItem>
                                            </ListItemButton>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    }
                    {
                        ui === 'completion_ui' &&
                        <div>
                            <p className="text-center"><Player autoplay loop src="https://assets8.lottiefiles.com/packages/lf20_vz0tqhxp.json" className="items-lottie"></Player></p>
                        </div>
                    }
                </Container>
            </DialogContent>
        </Dialog >
    )
}
export function DemoDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const switchBackToAccount = () => {
        let uInfo = JSON.parse(localStorage.getItem("uInfo"))

        localStorage.setItem("userId", uInfo.emp._id)
        localStorage.setItem("userWhatsApp", uInfo.emp.whatsapp)
        localStorage.setItem("userName", uInfo.emp.name)
        localStorage.setItem("userEmail", uInfo.emp.email)
        localStorage.setItem("userEmailVerified", uInfo.emp.emailVerified)
        localStorage.setItem("userWhatsAppVerified", uInfo.emp.whatsappVerified)
        localStorage.setItem("bId", uInfo.business._id)
        localStorage.setItem("bName", uInfo.business.name)
        localStorage.setItem("bWebsite", uInfo.business.website)
        localStorage.setItem("userRole", uInfo.business.role)
        localStorage.setItem("onboardingStatus", uInfo.business.onboardingStatus)
        localStorage.setItem('isDemo', false)
        localStorage.setItem("userAuthToken", uInfo.authToken)
        localStorage.removeItem('c1')
        localStorage.removeItem('cObj')
        localStorage.removeItem('demoMobileNo')
        localStorage.removeItem("userEmailT")
        localStorage.removeItem("userNameT")

        window.location.href = "/dashboard"

        onCloseHere(true)
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"lg"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogContent>
                <p className="text-center">
                    <img src={WtTheme.ImageBaseUrl + '/oops_demo_dashboard.png'} style={{ width: "300px" }} className="img-fluid" alt={WtTheme.SiteName} />
                </p>
                <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>Hey Sorry, as you are a demo user you cannot perform this action</p>
                <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "16px" }}>Try to explore other things in demo or switch back to your account to perform this action</p>

                <p className="text-center">
                    <button onClick={() => switchBackToAccount()} className="btn btn-sm text-white theme_bg ps-4 pe-4 rounded-3">Switch Back to Account</button>
                </p>
                <p className="text-center">
                    <button onClick={() => onCloseHere(false)} className="btn btn-sm ps-4 pe-4 rounded-3 bg-white" style={{ color: WtColor.CloudyGrey }}>Continue Demo</button>
                </p>
            </DialogContent>
        </Dialog>
    )
}
// End of Demo Work



// Channel Work
export function AddChannelDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const [isLoading, setIsLoading] = useState(undefined)
    const showLoader = () => { setIsLoading(true) }
    const hideLoader = () => { setIsLoading(false) }

    const channelTypeVar = [{ value: 'waba', name: 'WhatsApp Cloud API' }, { value: 'dialog360', name: 'Dialog 360' }]
    const [channelType, setChannelType] = useState('waba')
    const handleChannelType = (val) => { setChannelType(val) }

    const [channelValues, setChannelValues] = useState({ title: '', whatsapp: '', wabaId: '', wabaToken: '', apiKey: '', domain: '' })
    const handleChannelValues = (prop) => (event) => {
        if (prop === "whatsapp") {
            let number = event.target.value.replace(/[^0-9]/ig, "")
            setChannelValues({ ...channelValues, [prop]: number })
        } else {
            setChannelValues({ ...channelValues, [prop]: event.target.value })
        }
    }

    // Create Channels 
    const createChannels = async () => {
        if (channelValues.whatsapp.trim() !== "" && channelValues.title.trim() !== "") {
            showLoader()
            try {
                let data = ""
                if (channelType === "waba") {
                    data = {
                        'whatsapp': channelValues.whatsapp,
                        'wabaId': channelValues.wabaId,
                        'wabaToken': channelValues.wabaToken,
                        'title': channelValues.title,
                        'type': channelType
                    }
                }
                if (channelType === "dialog360") {
                    data = {
                        'whatsapp': channelValues.whatsapp,
                        'title': channelValues.title,
                        'type': channelType,
                        'apiKey': channelValues.apiKey,
                        'domain': channelValues.domain
                    }
                }
                const response = await createChannelAPI(data)
                if (response.status === 201) {
                    // localStorage.removeItem("addons_p")
                    hideLoader()
                    onCloseHere(true)
                } else {
                    hideLoader()
                    onCloseHere(false)
                }
            } catch (err) { hideLoader() }
        }
    }
    // End of Create Channels 
    return (
        <>
            <Dialog open={isLoading}><CircularProgress style={{ margin: '100px', color: '#01a82d' }} /></Dialog>
            <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                    Add Channel <Close className="float-end hand" onClick={() => onCloseHere(false)} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="mb-3">
                            <div className="pt-4">
                                <Box component="form" noValidate autoComplete="off" sx={{ '& .MuiTextField-root': { mb: 2 } }}>
                                    <TextField label="Title" value={channelValues.title} onChange={handleChannelValues('title')} placeholder="Enter Channel Title" fullWidth size="small" required />
                                    <TextField label="WhatsApp" value={channelValues.whatsapp} onChange={handleChannelValues('whatsapp')} placeholder="Enter WhatsApp Number" helperText="Enter WhatsApp no. with country code without + symbol" fullWidth size="small" required />

                                    <TextField label="Select Channel Type" value={channelType} onChange={(e) => handleChannelType(e.target.value)} helperText="Select channel type" fullWidth size="small" select>
                                        {channelTypeVar.map((option) => (
                                            <MenuItem className="text-uppercase" key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {
                                        (() => {
                                            switch (channelType) {
                                                case "waba":
                                                    return (
                                                        <>
                                                            <TextField label="WhatsApp Business Account ID" value={channelValues.wabaId} onChange={handleChannelValues('wabaId')} placeholder="Enter WhatsApp Business Account ID" multiline fullWidth size="small" />
                                                            <TextField label="Permanent Access Token" value={channelValues.wabaToken} onChange={handleChannelValues('wabaToken')} placeholder="Enter Permanent Access Token" multiline fullWidth size="small" />
                                                        </>
                                                    )
                                                case "dialog360":
                                                    return (
                                                        <>
                                                            <TextField label="API Key" value={channelValues.apiKey} onChange={handleChannelValues('apiKey')} placeholder="Enter API Key" multiline fullWidth size="small" />
                                                            <TextField label="Domain" value={channelValues.domain} onChange={handleChannelValues('domain')} placeholder="Enter Domain" multiline fullWidth size="small" />
                                                        </>
                                                    )

                                                default: return (<></>)
                                            }
                                        })()
                                    }
                                    <br />
                                </Box>
                            </div>
                        </div>
                    </DialogContentText>
                    <div className="mt-5">
                        <button style={CreateButtonStyle} onClick={() => createChannels()} disabled={(channelValues.whatsapp.trim() !== "" && channelValues.title.trim() !== "") ? false : true} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey">Create</button>
                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>
        </>

    )
}
// End of Channel Work


// Employees Work
export function AddEmployeeDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const [isLoading, setIsLoading] = useState(undefined)
    const showLoader = () => { setIsLoading(true) }
    const hideLoader = () => { setIsLoading(false) }

    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const userRoles = [{ value: 'admin' }]
    const [roleType, setChannelType] = useState('admin');
    const handleEmployeeRole = (val) => { setChannelType(val) }

    const [employee, setChannelValues] = useState({ name: '', email: '', pass: '' })
    const handleChannelValues = (prop) => (event) => { setChannelValues({ ...employee, [prop]: event.target.value }) }


    // Create Employee 
    const createEmployee = async () => {
        if (employee.name.trim() !== "" && employee.email.trim() !== "" && employee.pass.trim() !== "") {
            showLoader()
            try {
                let data = "name=" + employee.name + "&email=" + employee.email + "&pass=" + employee.pass + "&role=" + roleType
                const response = await createUpdateEmployeeAPI(data)
                console.log(JSON.stringify(response.data))
                if (response.status === 201) {
                    setChannelValues({ name: '', email: '', pass: '' })
                    hideLoader()
                    onCloseHere(true)
                } else { hideLoader() }
            } catch (err) { hideLoader() }
        }
    }
    // End of Create Employee 
    return (
        <>
            <Dialog open={isLoading}><CircularProgress style={{ margin: '100px', color: '#01a82d' }} /></Dialog>
            <Dialog open={open} fullWidth={true} maxWidth={"sm"} aria-labelledby="alert-dialog-title" onClose={() => onCloseHere(false)} TransitionComponent={Transition} aria-describedby="alert-dialog-description">
                <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                    Add Agent <Close className="float-end hand" onClick={() => onCloseHere(false)} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="mt-3 mb-3">
                            <div className="pt-4">
                                <Box component="form" noValidate autoComplete="off" sx={{ '& .MuiTextField-root': { mb: 2 } }}>
                                    <TextField label="Name" value={employee.name} onChange={handleChannelValues('name')} placeholder="Enter Channel Title" fullWidth size="small" required />
                                    <TextField label="Email" type={"email"} value={employee.email} onChange={handleChannelValues('email')} placeholder="Enter WhatsApp Number" fullWidth size="small" required />
                                    <TextField label="Password" value={employee.pass} onChange={handleChannelValues('pass')} placeholder="Enter Password" fullWidth size="small" required />

                                    <TextField label="Select Role" value={roleType} onChange={(e) => handleEmployeeRole(e.target.value)} helperText="Select agent role" fullWidth size="small" select>
                                        {userRoles.map((v, i) => (
                                            <MenuItem className="text-uppercase" key={i} value={v.value}>
                                                {v.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <br />
                                </Box>
                            </div>
                        </div>
                    </DialogContentText>
                    <div className="mt-5">
                        <button style={CreateButtonStyle} onClick={() => createEmployee()} disabled={(employee.name !== "" && employee.email !== "" && employee.pass !== "") ? false : true} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey">Create</button>
                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
// End of Employees Work


// Label Related Dialog
export function AddEditLabelsDialog({ open, purpose, labelData, onClose }) {
    const [labelName, setLabelName] = useState("")
    const [labelDescription, setLabelDescription] = useState("")
    const [labelColor, setLabelColor] = useState(WtColor.BaseColor)


    if (purpose === "" || purpose === undefined) return
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }


    let labelId = (purpose === "edit") ? labelData._id : undefined
    let creatorName = (purpose === "edit") ? labelData.createdBy : localStorage.getItem('userName')


    const handleLabelName = (val) => {
        setLabelName(val)
    }
    const handleLabelDescription = (val) => {
        setLabelDescription(val)
    }
    const handleLabelColor = (val) => {
        setLabelColor(val)
    }

    const createLable = async () => {
        if (labelName.trim() !== "" && labelDescription.trim() !== "") {
            try {
                let data = {
                    "label_name": labelName,
                    "label_description": labelDescription,
                    "label_color": labelColor,
                    "createdBy": creatorName
                }
                const response = await createLabelsAPI(data)
                if (response.status === 201) {
                    onCloseHere(true)
                } else {
                    onCloseHere(false)
                }
            } catch (error) {
                onCloseHere(false)
            }
        }
    }
    const updateLable = async () => {
        if (labelName.trim() !== "" && labelDescription.trim() !== "") {
            try {
                let data = {
                    "labelId": labelId,
                    "label_name": labelName,
                    "label_description": labelDescription,
                    "label_color": labelColor,
                    "updatedBy": creatorName
                }
                const response = await updateLabelsAPI(data)
                if (response.status === 202) {
                    onCloseHere(true)
                } else {
                    onCloseHere(false)
                }
            } catch (error) {
                onCloseHere(false)
            }
        }
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {purpose === "add" ? "Add Labels" : "Edit Labels"}
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-1 pb-5 ps-5 pe-5">
                <DialogContentText style={{ color: WtColor.CloudyGrey }}>
                    {purpose === "add" ? "Labels let you group the conversations together." : ""}
                </DialogContentText>
                <TextField label="Label Name" defaultValue={purpose === "edit" ? labelData?.label_name : ''} onChange={(e) => handleLabelName(e.target.value)} className="w-100 mb-4 mt-5" placeholder="Label Name" variant="outlined" />
                <TextField label="Label Description" defaultValue={purpose === "edit" ? labelData?.label_description : ""} onChange={(e) => handleLabelDescription(e.target.value)} className="w-100 mb-4" placeholder="Label Description" variant="outlined" />

                <label htmlFor="chooseColor" style={{ color: WtColor.TextMidGrey, fontWeight: "500" }}>Color</label>
                <Form.Control type="color" defaultValue={purpose === "edit" ? labelData?.label_color : ""} onChange={(e) => handleLabelColor(e.target.value)} title="Choose your color" className="mt-2" style={{ width: "80px", height: "80px" }} id="chooseColor" />

                <div className="mt-5">
                    <button style={CreateButtonStyle} onClick={() => (purpose === "edit") ? updateLable() : createLable()} disabled={(labelName.trim() !== "" && labelDescription.trim() !== "") ? false : true} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey">{(purpose === "edit") ? "Update" : "Create"}</button>
                    <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                </div>

                {
                    purpose === "edit" ?
                        <p style={{ marginTop: "10px", color: WtColor.TextDarkColor }}>Note: When you edit your label it will be updated from all the customers and chat.</p> : ""
                }
            </DialogContent>
        </Dialog >
    )
}
// End of Label Related Dialog




// Contacts
export function AddEditContactsDialog(props) {
    const { open, purpose, params, onClose } = props

    const [uiOpt, setUiOpt] = useState("add_contact") // add_contact | country_code

    const [countryCode, setCountryCode] = useState("+91")

    const [name, setName] = useState('')
    const [whatsappNumber, setWhatsAppNumber] = useState('')
    const [email, setEmail] = useState((purpose === "edit") ? params.email ? params.email : "" : "")

    const [countryCodeList, setCountryCodeList] = useState([])

    const [filterList, setFilterList] = useState([])
    const [searchInput, setSearchInput] = useState('')

    const getCountryCode = async () => {
        const countryCodeResponse = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
        setCountryCodeList(await countryCodeResponse.json())
    }

    useEffect(() => { getCountryCode() }, [])

    if (purpose === "" || purpose === undefined) return
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const handleUI = (ui) => { setUiOpt(ui) }

    const handleCountryCode = (code) => {
        setCountryCode(code)
        setUiOpt("add_contact")
    }


    const handleName = (name) => { setName(name.trim()) }
    const handleWhatsAppNumber = (number) => { let num = number.replace(/[^0-9+]/ig, ""); setWhatsAppNumber(num) }
    const handleEmail = (email) => { setEmail(email) }


    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const InValidButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }


    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = countryCodeList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilterList(filteredData)
        } else {
            setFilterList(countryCodeList)
        }
    }


    const create = async () => {
        try {
            let data = { "whatsapp": countryCode.slice(1) + whatsappNumber, "name": name, "email": email }
            const response = await createContactsAPI(data)
            if (response.status === 201) { onCloseHere(true) } else { onCloseHere(false) }
        } catch (error) { onCloseHere(false) }
    }

    const update = async () => {
        try {
            let data = { "contactId": params._id, "whatsapp": params.whatsapp, "name": name, "email": email }
            const response = await updateContactsAPI(data)
            if (response.status === 202) { onCloseHere(true) } else { onCloseHere(false) }
        } catch (error) { onCloseHere(false) }
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {
                    (uiOpt === "country_code") ?
                        <>
                            <KeyboardBackspaceOutlined style={{ color: WtColor.TextMidGrey, marginTop: "-5px" }} className="hand me-3" onClick={() => handleUI("add_contact")} /> Choose Country Code
                        </>
                        :
                        <>{purpose === "add" ? "Add Contact" : "Edit Contact"} </>
                }
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                {
                    (() => {
                        if (uiOpt === "add_contact") {
                            return (
                                <div>
                                    <TextField defaultValue={purpose === "edit" ? params.name : ''} onChange={(e) => handleName(e.target.value)} type="text" label="Name" className="w-100 mb-3" placeholder="Enter Name" variant="outlined" required />
                                    {
                                        purpose !== "edit" &&
                                        <TextField fullWidth className="mb-3" type={"tel"} label="Mobile Number"
                                            InputProps={{ startAdornment: <InputAdornment position="start" className="hand"><Link className="text-dark" onClick={() => purpose !== "edit" ? handleUI("country_code") : {}}>{countryCode}</Link>&nbsp;&nbsp; | </InputAdornment>, }}
                                            onChange={(e) => handleWhatsAppNumber(e.target.value)} variant="outlined" disabled={purpose === "edit" ? true : false} required />
                                    }
                                    <TextField defaultValue={(purpose === "edit") ? params.email ? params.email : "" : ""} onChange={(e) => handleEmail(e.target.value)} type="email" label="Email Address" className="w-100 mb-3" placeholder="Enter Email Address" variant="outlined" />

                                    <div className="mt-5">
                                        {
                                            (purpose !== "edit") ?
                                                ((name.trim() !== "") && (whatsappNumber !== undefined) && (whatsappNumber.length >= 8) && (whatsappNumber.length <= 15)) ?
                                                    <button style={ValidButtonStyle} onClick={() => create()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Add</button>
                                                    :
                                                    <button style={InValidButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey" disabled>Add</button>
                                                :
                                                ((name.trim() !== "") && (params.name !== name || params.email !== email)) ?
                                                    <button style={ValidButtonStyle} onClick={() => update()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Update</button>
                                                    :
                                                    <button style={InValidButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey" disabled>Update</button>
                                        }
                                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "country_code") {
                            return (
                                <div>
                                    <div className="m-3">
                                        <TextField fullWidth onChange={(e) => searchItems(e.target.value)} type="text" label="Search Country Code" variant="outlined" />
                                    </div>
                                    <List sx={{ width: '100%', maxWidth: 420, bgcolor: 'background.paper' }}>
                                        {
                                            ((filterList.length === 0) ? countryCodeList : filterList).map((v, i) => {
                                                return (
                                                    <ListItemButton className="hand" key={i}>
                                                        <ListItem onClick={() => handleCountryCode(v.dial_code)} >
                                                            <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                                                            <ListItemText primary={v.name} secondary={v.dial_code} />
                                                        </ListItem>
                                                    </ListItemButton>
                                                )
                                            })
                                        }
                                    </List>
                                </div>
                            )
                        }
                    })()
                }
            </DialogContent>
        </Dialog >
    )
}
export function UploadCSVDialog(props) {
    const { open, onClose } = props

    const [errMsg, setErrMsg] = useState(undefined)

    const [dialogSize, setDialogSize] = useState("sm")

    const [uiOpt, setUiOpt] = useState("upload_contact") // upload_contact | progress_report

    const [csv, setCSV] = useState(undefined)

    const [uploadedCSVData, setUploadedCSVData] = useState(undefined)


    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const InValidButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const handleDialogSize = (size) => {
        setDialogSize(size)
    }

    const handleUI = (ui) => { setUiOpt(ui) }

    const handleCSV = (e) => {
        const [file] = e.target.files
        if (file.type.match('text/csv')) {
            setErrMsg("")
            setCSV(e.target.files[0])
        } else {
            setErrMsg("Wrong File Selected!!!")
            setCSV(undefined)
        }
    }

    const upload = async () => {
        try {
            const form = new FormData()
            form.append('csv', csv)
            const options = { headers: { 'Accept': 'multipart/form-data' } }
            const response = await uploadCSVAPI(form, options)
            if (response.status === 201) {
                setUploadedCSVData(response.data.data)
                handleDialogSize("md")
                handleUI("progress_report")
            }
        } catch (error) { }
    }

    const closeDialog = () => {
        handleUI("upload_contact")
        onCloseHere(false)
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={dialogSize} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {(uiOpt === "progress_report") ? "CSV Uplaoded Data" : "Uplaod CSV"}
                <Close className="float-end hand" onClick={() => closeDialog()} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                {
                    (() => {
                        if (uiOpt === "upload_contact") {
                            return (
                                <div>
                                    {errMsg ? <p className="text-center" style={{ color: WtColor.RedColor }}>{errMsg}</p> : ""}
                                    <input onChange={(e) => handleCSV(e)} type="file" label="CSV" accept="text/csv" className="w-100 mb-3 form-control" placeholder="Select CSV" variant="outlined" focused required />

                                    <div className="mt-5">
                                        {
                                            (csv !== undefined) ?
                                                <button style={ValidButtonStyle} onClick={() => upload()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Upload</button>
                                                :
                                                <button style={InValidButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey" disabled>Upload</button>
                                        }
                                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "progress_report") {
                            return (
                                <div>
                                    <div style={{ color: WtColor.TextMidGrey, lineHeight: "0.7" }}>
                                        <p>Total Contacts: {uploadedCSVData?.inputContactCount ? uploadedCSVData.inputContactCount : ""}</p>
                                        <p>Already Exist: {uploadedCSVData?.alreadyExists ? uploadedCSVData.alreadyExists : ""}</p>
                                        <p>WhatsApp Missing: {uploadedCSVData?.whatsappMissing ? uploadedCSVData.whatsappMissing : ""}</p>
                                        <p>Duplicate Entries: {uploadedCSVData?.duplicateEntries ? uploadedCSVData.duplicateEntries : ""}</p>
                                        <p>Total Inserted: {uploadedCSVData?.insertCount ? uploadedCSVData.insertCount : ""}</p>
                                    </div>
                                    <div className="mt-5">
                                        <Table className="fw-bold" responsive>
                                            <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }}>
                                                <tr><th className="ps-5">Name</th><th>WhatsApp</th><th>Email</th></tr>
                                            </thead>
                                            <tbody style={{ background: "#fff", fontSize: "14px" }}>
                                                {
                                                    uploadedCSVData.contacts ? uploadedCSVData.contacts.map((v, i) => {
                                                        return (
                                                            <tr className="hand" key={i}>
                                                                <td style={{ color: WtColor.TextMidGrey }} className="ps-5">{v.name ? (v.name === "") ? "empty" : v.name : "empty"}</td>
                                                                <td style={{ color: WtColor.TextLightGrey }}>{v.whatsapp ? (v.whatsapp === "") ? "empty" : v.whatsapp : "empty"}</td>
                                                                <td style={{ color: WtColor.TextLightGrey }}>{v.email ? (v.email === "") ? "empty" : v.email : "empty"}</td>
                                                            </tr>
                                                        )
                                                    }) : ''
                                                }
                                            </tbody>
                                        </Table>
                                    </div>

                                    <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => closeDialog()}>Close</button>
                                </div>
                            )
                        }
                    })()
                }
            </DialogContent>
        </Dialog >
    )
}
// End of Contacts



// Attributes Dailog
export function AddEditAttributeDialog({ open, purpose, params, onClose }) {
    const [applyTo, setApplyTo] = useState("contact")
    const [name, setName] = useState(purpose === "edit" ? params.field_name : undefined)
    const [key, setKey] = useState(undefined)
    const [description, setDescription] = useState(purpose === "edit" ? params.field_description : undefined)
    const [type, setType] = useState(purpose === "edit" ? params.field_type : "text")
    const [listValues, setValues] = useState(purpose === "edit" ? params.list ? Object.values(params.list).join(",") : undefined : undefined)

    if (purpose === "" || purpose === undefined) return
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }



    const handleApplyTo = (val) => { setApplyTo(val) }
    const handleName = (val) => {
        setName(val)
        let temp = val.toLowerCase().replace(/[ ]/g, "_")
        setKey(temp)
    }
    const handleKey = (val) => {
        setKey(val)
    }
    const handleDescription = (val) => { setDescription(val) }
    const handleType = (val) => { setType(val) }
    const handleListValues = (val) => { setValues(val) }

    const create = async () => {
        if (name && key && name.trim() !== "" && key.trim() !== "") {
            try {
                let data = ""
                if (type === "list") {
                    let tempLsVal = new Array()
                    tempLsVal = listValues.replace(/[ ]/g, "").split(",")
                    data = {
                        "appliesTo": applyTo,
                        "field_name": name,
                        "field_key": key,
                        "field_description": description,
                        "field_type": type,
                        "list": tempLsVal,
                        "createdBy": localStorage.getItem("userName")
                    }
                } else {
                    data = {
                        "appliesTo": applyTo,
                        "field_name": name,
                        "field_key": key,
                        "field_description": description,
                        "field_type": type,
                        "createdBy": localStorage.getItem("userName")
                    }
                }

                const response = await createAttributesAPI(data)
                if (response.status === 201) { onCloseHere(true) } else { }
            } catch (error) { }
        }
    }

    const update = async () => {
        if (name && name.trim() !== "") {
            try {
                let data = ""
                if (type === "list") {
                    let tempLsVal = new Array()
                    tempLsVal = listValues.replace(/[ ]/g, "").split(",")
                    data = {
                        "appliesTo": applyTo,
                        "fieldId": params._id,
                        "field_name": name,
                        "field_key": key,
                        "field_description": description,
                        "field_type": type,
                        "list": tempLsVal,
                        "updatedBy": localStorage.getItem("userName")
                    }
                } else {
                    data = {
                        "updatedBy": localStorage.getItem("userName"),
                        "fieldId": params._id,
                        "field_name": name,
                        "field_key": key,
                        "field_description": description,
                        "appliesTo": applyTo
                    }
                }
                const response = await updateAttributesAPI(data)
                if (response.status === 202) { onCloseHere(true) } else { }
            } catch (error) { }
        }
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {purpose === "add" ? "Add Field" : "Edit Field"}
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">

                <label style={{ color: WtColor.TextMidGrey }} htmlFor="field_name">Display Name</label>
                <input defaultValue={purpose === "edit" ? params.field_name : ""} onChange={(e) => handleName(e.target.value)} style={{ border: "1px solid " + WtColor.CloudyGrey }} type="text" id="field_name" className="form-control rounded-3 mb-3" />

                {
                    (purpose === "add") ?
                        <>
                            <label style={{ color: WtColor.TextMidGrey }} htmlFor="field_key">Key</label>
                            <input defaultValue={key} value={key} onChange={(e) => handleKey(e.target.value)} style={{ border: "1px solid " + WtColor.CloudyGrey }} type="text" id="field_key" className="form-control rounded-3 mb-3" />
                        </> : ""
                }


                <label style={{ color: WtColor.TextMidGrey }} htmlFor="field_description">Description</label>
                <textarea onChange={(e) => handleDescription(e.target.value)} style={{ border: "1px solid " + WtColor.CloudyGrey }} id="field_description" className="form-control rounded-3 mb-3">{purpose === "edit" ? params.field_description : ""}</textarea>

                {
                    (purpose === "add") ?
                        <>
                            <label style={{ color: WtColor.TextMidGrey }} htmlFor="field_type">Type</label>
                            <select onChange={(e) => handleType(e.target.value)} style={{ border: "1px solid " + WtColor.CloudyGrey }} id="field_type" className="form-control rounded-3 mb-3">
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="link">Link</option>
                                <option value="date">Date</option>
                                <option value="list">List</option>
                                <option value="checkbox">Checkbox</option>
                            </select>
                        </> : ""
                }
                {
                    type && type === "list" ?
                        <>
                            <label style={{ color: WtColor.TextMidGrey }} htmlFor="list_values">List Values</label>
                            <textarea defaultValue={listValues} onChange={(e) => handleListValues(e.target.value)} style={{ border: "1px solid " + WtColor.CloudyGrey }} id="list_values" className="form-control rounded-3 mb-3" placeholder="Please enter comma separated values"></textarea>
                        </> : ""
                }

                <div className="mt-5">
                    <button style={CreateButtonStyle} onClick={() => (purpose === "edit") ? update() : create()} disabled={(name && name.trim() !== "") ? false : true} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey">{(purpose === "edit") ? "Update" : "Add"}</button>
                    <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                </div>
            </DialogContent>
        </Dialog >
    )
}
export function AssignedAttributeDialog({ open, channel, endUserWhatsApp, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const [uiOpt, setUiOpt] = useState("attribute_list") //attribute_list || attribute_action

    const [attrList, setAttrList] = useState([])

    const [attrId, setAttrId] = useState("")
    const [attrName, setAttrName] = useState("")
    const [attrType, setAttrType] = useState("")
    const [attrListData, setAttrListData] = useState([])

    const [inputData, setInputData] = useState("")

    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const handleUI = (ui) => setUiOpt(ui)

    const fetchAttrList = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) setAttrList(response.data.data)
        } catch (error) { }
    }

    const getAttrType = (id, type, name, list) => { setAttrId(id); setAttrName(name); setAttrType(type); list ? setAttrListData(list) : setAttrListData([]) }

    const handleInputData = (val) => {
        if (attrType === "date") {
            let temp = new Date(val)
            let temp2 = temp.getTime()
            setInputData(temp2)
        } else { setInputData(val) }
    }

    const assign = async () => {
        try {
            let data = { "extUserId": endUserWhatsApp, "extChannelId": channel, "fieldId": attrId, "inputData": inputData }
            const response = await assignAttributesAPI(data)
            if (response.status === 200) onCloseHere(true)
        } catch (error) { }
    }

    const attrAction = (v) => {
        handleUI("attribute_action")
        getAttrType(v._id, v.field_type, v.field_name, v.list ? v.list : "")
    }

    useEffect(() => { fetchAttrList() }, [])
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {(uiOpt === "attribute_action") && < KeyboardBackspaceOutlined style={{ color: WtColor.TextMidGrey, marginTop: "-5px" }} className="hand me-3" onClick={() => handleUI("attribute_list")} />}
                Custom Field Assign<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                {
                    (() => {
                        if (uiOpt === "attribute_list") {
                            return (
                                <div>
                                    {
                                        attrList.map((v, i) => {
                                            return (
                                                <div onClick={() => attrAction(v)} className="pb-2 pt-2 hand" style={{ color: WtColor.TextMidGrey, borderBottom: "1px solid " + WtColor.LineColor }} key={i}>
                                                    {v.field_name}
                                                    <EastOutlined style={{ color: WtColor.BaseColor }} className="float-end hand" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        if (uiOpt === "attribute_action") {
                            return (
                                <div>
                                    {
                                        (() => {
                                            if (attrId && attrName && attrType) {
                                                if (attrType === "date") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="date_item">{attrName}</p>
                                                            <input type="date" onChange={(e) => handleInputData(e.target.value)} className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="date_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "list" && attrListData) {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="list_item">{attrName}</p>
                                                            <select onChange={(e) => handleInputData(e.target.value)} className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="list_item">
                                                                {
                                                                    attrListData.map((v, i) => {
                                                                        return (
                                                                            <option key={i}>{v}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "link") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="link_item">{attrName}</p>
                                                            <input onChange={(e) => handleInputData(e.target.value)} type="url" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="link_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "number") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="humber_item">{attrName}</p>
                                                            <input onChange={(e) => handleInputData(e.target.value)} type="number" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="humber_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "text") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="text_item">{attrName}</p>
                                                            <input onChange={(e) => handleInputData(e.target.value)} type="text" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="text_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "checkbox") {
                                                    return (
                                                        <div>
                                                            <Form.Check onChange={(e) => handleInputData(e.target.checked)} inline label={attrName} name={attrName + "_checkbox"} id="inline-checkbox-1" type="checkbox" />
                                                        </div>
                                                    )
                                                }
                                            } else { return "Something went wrong !!!" }
                                        })()
                                    }

                                    <div className="mt-5">
                                        <button style={CreateButtonStyle} onClick={() => assign()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey">Assign</button>
                                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                                    </div>
                                </div>
                            )
                        }
                    })()
                }
            </DialogContent>
        </Dialog>
    )
}

export function AssignedAttributeNewDialog({ open, channel, endUserWhatsApp, onClose }) {
    const [uiOpt, setUiOpt] = useState("attribute_list") //attribute_list || attribute_action

    const [attrList, setAttrList] = useState([])

    const [attrId, setAttrId] = useState("")
    const [attrName, setAttrName] = useState("")
    const [attrType, setAttrType] = useState("")
    const [attrKey, setAttrKey] = useState("")
    const [attrListData, setAttrListData] = useState([])

    const [inputData, setInputData] = useState("")

    const assignAttribute = useContext(globalContext)
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const handleUI = (ui) => { setUiOpt(ui) }

    const fetchAttrList = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) setAttrList(response.data.data)
        } catch (error) { }
    }

    const getAttrType = (id, type, name, list, key) => { setAttrId(id); setAttrName(name); setAttrType(type); list ? setAttrListData(list) : setAttrListData([]); setAttrKey(key) }

    const handleInputData = (val) => {
        if (attrType === "date") {
            let temp = new Date(val)
            let temp2 = temp.getTime()
            setInputData(temp2?.toString())
        } else {
            setInputData(val)
        }
    }

    const assignAttr = useCallback(async (channel, whatsapp, id, inputData, key) => {
        if (inputData !== "") {
            await assignAttribute.assignAttribute(channel, whatsapp, id, inputData, key)
            onCloseHere(true)
        }
    }, [])

    const attrAction = (v) => {
        handleUI("attribute_action")
        getAttrType(v._id, v.field_type, v.field_name, v.list ? v.list : "", v.field_key)
    }

    useEffect(() => { fetchAttrList() }, [])


    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: (confirm) => { setOpenDemoDialog(false) } })
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {
                    (uiOpt === "attribute_action") ? <KeyboardBackspaceOutlined style={{ color: WtColor.TextMidGrey, marginTop: "-5px" }} className="hand me-3" onClick={() => handleUI("attribute_list")} /> : " "
                }
                Custom Field Assign<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />
                {
                    (() => {
                        if (uiOpt === "attribute_list") {
                            return (
                                <div>
                                    {
                                        attrList.map((v, i) => {
                                            return (
                                                <div onClick={() => attrAction(v)} className="pb-2 pt-2 hand" style={{ color: WtColor.TextMidGrey, borderBottom: "1px solid " + WtColor.LineColor }} key={i}>
                                                    {v.field_name}
                                                    <EastOutlined style={{ color: WtColor.BaseColor }} className="float-end hand" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        if (uiOpt === "attribute_action") {
                            return (
                                <div>
                                    {
                                        (() => {
                                            if (attrId && attrName && attrType) {
                                                if (attrType === "date") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="date_item">{attrName}</p>
                                                            <input type="date" onChange={(e) => handleInputData(e.target.value)} className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="date_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "list" && attrListData) {
                                                    return (
                                                        <div>
                                                            <InputLabel id="select_list_item" style={{ color: WtColor.TextMidGrey }}>Select {attrName}</InputLabel>
                                                            <Select labelId="select_list_item" id="demo-simple-select" fullWidth
                                                                defaultValue={"Select " + attrName} label={"Select " + attrName} onChange={(e) => handleInputData(e.target.value)}>
                                                                <MenuItem value={"Select " + attrName} style={{ display: "none" }}>{"Select " + attrName}</MenuItem>
                                                                {
                                                                    attrListData?.map((v, i) => {
                                                                        return (
                                                                            <MenuItem value={v} key={i}>{v}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "link") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="link_item">{attrName}</p>
                                                            <input onChange={(e) => handleInputData(e.target.value)} type="url" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="link_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "number") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="humber_item">{attrName}</p>
                                                            <input onChange={(e) => handleInputData(e.target.value)} type="number" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="humber_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "text") {
                                                    return (
                                                        <div>
                                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="text_item">{attrName}</p>
                                                            <input onChange={(e) => handleInputData(e.target.value)} type="text" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="text_item" />
                                                        </div>
                                                    )
                                                }
                                                if (attrType === "checkbox") {
                                                    return (
                                                        <div>
                                                            <Form.Check onChange={(e) => handleInputData(e.target.checked)} inline label={attrName} name={attrName + "_checkbox"} id="inline-checkbox-1" type="checkbox" />
                                                        </div>
                                                    )
                                                }
                                            } else { return "Something went wrong !!!" }
                                        })()
                                    }

                                    <div className="mt-5">
                                        <button style={CreateButtonStyle} onClick={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await assignAttr(channel, endUserWhatsApp, attrId, inputData, attrKey) : localStorage.getItem('demoMobileNo') === endUserWhatsApp ? await assignAttr(channel, endUserWhatsApp, attrId, inputData, attrKey) : openDemoDialogBox()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Assign</button>
                                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                                    </div>
                                </div>
                            )
                        }
                    })()
                }
            </DialogContent>
        </Dialog>
    )
}
export function EditAssignedAttributeNewDialog({ open, attr_info, channel, endUserWhatsApp, onClose }) {
    const [attrList, setAttrList] = useState([])

    const [attrId, setAttrId] = useState("")
    const [attrName, setAttrName] = useState("")
    const [attrType, setAttrType] = useState("")
    const [attrKey, setAttrKey] = useState("")
    const [attrListData, setAttrListData] = useState([])

    const [inputData, setInputData] = useState("")

    const assignEditAttribute = useContext(globalContext)
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const fetchAttrList = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) {
                response?.data?.data?.map((v) => {
                    if (v.field_key === attr_info.key) {
                        setAttrList(v)

                        setAttrId(v._id)
                        setAttrName(v.field_name)
                        setAttrType(v.field_type)
                        setAttrKey(v.field_key)
                        v.list ? setAttrListData(v.list) : setAttrListData([])
                    }
                })
            }
        } catch (error) { }
    }

    // const getAttrType = (id, type, name, list) => { setAttrId(id); setAttrName(name); setAttrType(type); list ? setAttrListData(list) : setAttrListData([]) }

    const handleInputData = (val) => {
        if (attrType === "date") {
            let temp = new Date(val)
            let temp2 = temp.getTime()
            setInputData(temp2)
        } else {
            console.log(val)
            setInputData(val)
        }
    }

    const assignEditAttr = useCallback(async (channel, whatsapp, id, inputData, key) => {
        if (inputData !== "") {
            await assignEditAttribute.assignEditAttribute(channel, whatsapp, id, inputData, key)
            onCloseHere(true)
        }
    }, [])
    useEffect(() => { fetchAttrList() }, [])

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = (id) => {
        setOpenDemoDialog({
            open: true, onClose: (confirm) => {
                setOpenDemoDialog(false)
            }
        })
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                Custom Field Edit <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />
                <div>
                    {
                        (() => {
                            if (attr_info) {
                                if (attrType === "date") {
                                    return (
                                        <div>
                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="date_item">{attrName}</p>
                                            <input type="date" onChange={(e) => handleInputData(e.target.value)} className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="date_item" />
                                        </div>
                                    )
                                }
                                if (attrType === "list" && attrListData) {
                                    return (
                                        <div>
                                            <InputLabel id="select_list_item" style={{ color: WtColor.TextMidGrey }}>Select {attrName}</InputLabel>
                                            <Select labelId="select_list_item" id="demo-simple-select" fullWidth
                                                defaultValue={attr_info.attr_data} label={"Select " + attrName} onChange={(e) => handleInputData(e.target.value)}>
                                                <MenuItem value={attr_info.attr_data} style={{ display: "none" }}>{attr_info.attr_data}</MenuItem>
                                                {
                                                    attrListData?.map((v, i) => {
                                                        return (
                                                            <MenuItem value={v} key={i} style={{ display: v === attr_info.attr_data ? "none" : "block" }}>{v}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    )
                                }
                                if (attrType === "link") {
                                    return (
                                        <div>
                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="link_item">{attrName}</p>
                                            <input defaultValue={attr_info.attr_data} onChange={(e) => handleInputData(e.target.value)} type="url" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="link_item" />
                                        </div>
                                    )
                                }
                                if (attrType === "number") {
                                    return (
                                        <div>
                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="humber_item">{attrName}</p>
                                            <input defaultValue={attr_info.attr_data} onChange={(e) => handleInputData(e.target.value)} type="number" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="humber_item" />
                                        </div>
                                    )
                                }
                                if (attrType === "text") {
                                    return (
                                        <div>
                                            <p style={{ color: WtColor.TextMidGrey }} htmlFor="text_item">{attrName}</p>
                                            <input defaultValue={attr_info.attr_data} onChange={(e) => handleInputData(e.target.value)} type="text" className="form-control rounded-2" style={{ border: "1px solid " + WtColor.LineColor }} id="text_item" />
                                        </div>
                                    )
                                }
                                if (attrType === "checkbox") {
                                    return (
                                        <div>
                                            <Form.Check defaultChecked={attr_info.attr_data} onChange={(e) => handleInputData(e.target.checked)} inline label={attrName} name={attrName + "_checkbox"} id="inline-checkbox-1" type="checkbox" />
                                        </div>
                                    )
                                }
                            } else { return "Something went wrong !!!" }
                        })()
                    }
                    <div className="mt-5">
                        <button style={CreateButtonStyle} onClick={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await assignEditAttr(channel, endUserWhatsApp, attrId, inputData, attrKey) : localStorage.getItem('demoMobileNo') === endUserWhatsApp ? await assignEditAttr(channel, endUserWhatsApp, attrId, inputData, attrKey) : openDemoDialogBox()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Assign</button>
                        <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
// End of Attributes Dailog



// Quick Reply 
export function AddEditQuickReply(props) {
    const { open, purpose, params, onClose } = props

    const [quickReply, setQuickReply] = useState("")

    if (purpose === "" || purpose === undefined) return
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const CancelButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }


    const handleQuickReply = (val) => {
        setQuickReply(val)
    }

    const create = async () => {
        if (quickReply.trim() !== "") {
            try {
                let data = { "text": quickReply }
                const response = await createUpdateQuickReplyAPI(data)
                if (response.status === 201) {
                    onCloseHere(true)
                } else {
                    onCloseHere(false)
                }
            } catch (error) { onCloseHere(false) }
        }
    }

    const update = async () => {
        if (quickReply.trim() !== "") {
            try {
                let data = { "text": quickReply, "_id": params._id }
                const response = await createUpdateQuickReplyAPI(data)
                if (response.status === 202) { onCloseHere(true) } else { onCloseHere(false) }
            } catch (error) { onCloseHere(false) }
        }
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {purpose === "add" ? "Add Quick Reply" : "Edit Quick Reply"}
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                <label style={{ color: WtColor.TextMidGrey }} htmlFor="quick_reply">Quick Reply</label>
                <textarea onChange={(e) => handleQuickReply(e.target.value)} style={{ border: "1px solid " + WtColor.CloudyGrey }} rows={6} id="quick_reply" placeholder="Enter Quick Reply..." className="form-control rounded-3 mt-2 mb-3">{purpose === "edit" ? params.text : ""}</textarea>

                <div className="mt-5">
                    <button style={CreateButtonStyle} onClick={() => (purpose === "edit") ? update() : create()} disabled={(quickReply.trim() !== "") ? false : true} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey">{(purpose === "edit") ? "Update" : "Create"}</button>
                    <button style={CancelButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => onCloseHere(false)}>Cancel</button>
                </div>
            </DialogContent>
        </Dialog>

    )
}
// End of Quick Reply 


// Conversation Related Dialog
export const NewChatAskForWhatsAppUI = ({ open, onClose }) => {
    const onCloseHere = (confirm, whatsapp_number, channel_name, channel_number) => { if (onClose) onClose(confirm, whatsapp_number, channel_name, channel_number) }

    const [uiOpt, setUiOpt] = useState("whatsapp_number") // whatsapp_number | country_code | channel_selection
    const handleUI = (ui) => { setUiOpt(ui) }

    const [countryCode, setCountryCode] = useState("+91")
    const handleCountryCode = (code) => {
        setCountryCode(code)
        setUiOpt("whatsapp_number")
    }

    const [whatsappNumber, setWhatsAppNumber] = useState("")
    const handleWhatsAppNumber = (number) => {
        let num = number.replace(/[^0-9+]/ig, "")
        setWhatsAppNumber(num)
    }

    const handleNextBtn = () => {
        // onCloseHere(true, countryCode + whatsappNumber)

        setUiOpt("channel_selection")
    }

    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const InValidButtonStyle = { color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }


    const [countryCodeList, setCountryCodeList] = useState([])
    const getCountryCode = async () => {
        const countryCodeResponse = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
        setCountryCodeList(await countryCodeResponse.json())
    }
    const [filterList, setFilterList] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = countryCodeList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilterList(filteredData)
        } else {
            setFilterList(countryCodeList)
        }
    }

    const [channelLs, setChannelLs] = useState(undefined)
    const getChannels = async () => {
        try {
            const response = await fetchChannels()
            if (response.status === 200) {
                setChannelLs(response.data.data)
            }
        } catch (error) { }
    }
    const [channelName, setChannelName] = useState(undefined)
    const [channelNumber, setChannelNumber] = useState(undefined)
    const handleChannelSelection = (name, number) => {
        setChannelName(name)
        setChannelNumber(number)
        onCloseHere(true, countryCode + whatsappNumber, name, number)
        setWhatsAppNumber("")
        handleUI("whatsapp_number")
    }

    useEffect(() => {
        getCountryCode()
        getChannels()
    }, [])

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = (id) => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {
                    (() => {
                        if (uiOpt === "country_code") {
                            return (
                                <>
                                    <KeyboardBackspaceOutlined style={{ color: WtColor.TextMidGrey, marginTop: "-5px" }} className="hand me-3" onClick={() => handleUI("whatsapp_number")} /> Choose Country Code
                                </>
                            )
                        }
                        if (uiOpt === "whatsapp_number") {
                            return (
                                <>New Chat</>
                            )
                        }
                        if (uiOpt === "channel_selection") {
                            return (
                                <>
                                    <KeyboardBackspaceOutlined style={{ color: WtColor.TextMidGrey, marginTop: "-5px" }} className="hand me-3" onClick={() => handleUI("whatsapp_number")} /> Select Channel
                                </>
                            )
                        }
                    })()
                }
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />
                {
                    (() => {
                        if (uiOpt === "channel_selection") {
                            return (
                                <div className="mb-5">
                                    {
                                        channelLs?.map((v, i) => {
                                            return (
                                                <div onClick={() => handleChannelSelection(v.title, v.whatsapp)} style={{ border: "1px solid " + WtColor.LineColor }} className="mb-3 hand" key={i}>
                                                    <p className='text-center pt-3'>{v.title}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        if (uiOpt === "whatsapp_number") {
                            return (
                                <div>
                                    <TextField fullWidth className="mb-3" id="whatsapp_no" type={"tel"} label="WhatsApp Number"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" className="hand"><Link className="text-dark" onClick={() => handleUI("country_code")}>{countryCode}</Link>&nbsp;&nbsp; | </InputAdornment>,
                                        }}
                                        value={whatsappNumber} onChange={(e) => handleWhatsAppNumber(e.target.value)} variant="outlined" required />

                                    <div className="mt-5">
                                        {
                                            ((whatsappNumber !== undefined) && (whatsappNumber.length >= 8) && (whatsappNumber.length <= 15)) ?
                                                <button style={ValidButtonStyle} onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? handleNextBtn() : openDemoDialogBox()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Next</button>
                                                :
                                                <button style={InValidButtonStyle} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 theme_grey" disabled>Next</button>
                                        }

                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "country_code") {
                            return (
                                <div>
                                    <div className="m-3">
                                        <TextField fullWidth onChange={(e) => searchItems(e.target.value)} type="text" label="Search Country Code" variant="outlined" />
                                    </div>
                                    <List sx={{ width: '100%', maxWidth: 420, bgcolor: 'background.paper' }}>
                                        {
                                            ((filterList.length === 0) ? countryCodeList : filterList).map((v, i) => {
                                                return (
                                                    <ListItemButton className="hand" key={i}>
                                                        <ListItem onClick={() => handleCountryCode(v.dial_code)} >
                                                            <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                                                            <ListItemText primary={v.name} secondary={v.dial_code} />
                                                        </ListItem>
                                                    </ListItemButton>
                                                )
                                            })
                                        }
                                    </List>
                                </div>
                            )
                        }
                    })()
                }
            </DialogContent>
        </Dialog>
    )
}
export function QuickReplyDialog(props) {
    const { open, handleQuickReplyCallBack, onClose } = props
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const [dialogBoxSize, setDailogBoxSize] = useState("md")

    const [quickReplyList, setQuickReplyList] = useState([])
    const [filteredQuickReply, setFilteredQuickReply] = useState(quickReplyList)

    const searchQuickReplyItems = (searchValue) => {
        const filteredData = quickReplyList.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
        })
        setFilteredQuickReply(filteredData)
    }

    const fetchQuickReplies = async () => {
        try {
            const response = await fetchAllQuickReplyAPI()
            if (response.data.status === 200 && response.data.message === "Success") {
                setQuickReplyList(response.data.data)
            }
        } catch (error) { }
    }
    // End of Templates & Quick Replies UI Handing Work only

    // Select Quick Reply for sending Callback to Chatscreen
    const selectQuickReply = async (val) => {
        await handleQuickReplyCallBack(val)
        onCloseHere(false)
    }
    // End of Select Quick Reply for sending Callback to Chatscreen

    useEffect(() => { setFilteredQuickReply(quickReplyList) }, [quickReplyList])
    useEffect(() => { fetchQuickReplies() }, [])
    return (
        <Dialog className="choose_to_send_dialog" open={open} fullWidth={true} maxWidth={dialogBoxSize} TransitionComponent={Transition} keepMounted onClose={() => onCloseHere(false)}>

            <DialogTitle className="mt-1 mb-1" style={{ display: "flex", borderBottom: "1px solid " + WtColor.LineColor }}>
                <input onChange={(e) => searchQuickReplyItems(e.target.value)} type="search" className="form-control w-100 ms-2 me-1" placeholder="Search Quick Replies" style={{ border: "2px solid " + WtColor.LineColor, borderRadius: "30px", flex: 1 }} />
                <Avatar className="ms-1 me-2" style={{ background: WtColor.CloudyGrey }} onClick={() => onCloseHere(false)}><IconButton onClick={() => onCloseHere(false)}><Close className="text-white" /></IconButton></Avatar>
            </DialogTitle>
            <DialogContent>
                <Table className="fw-bold" hover responsive>
                    <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }}>
                        <tr><th>Title</th><th>Action</th></tr>
                    </thead>
                    <tbody>
                        {
                            filteredQuickReply.length !== 0 ?
                                filteredQuickReply.map((v, i) => {
                                    let title = v.text
                                    return (
                                        <tr onClick={() => selectQuickReply(title)} className="hand" key={i}>
                                            <td style={{ color: WtColor.TextMidGrey }}>{title}</td>
                                            <td><TaskAltOutlined style={{ fontSize: "20px", color: WtColor.BaseColor }} /></td>
                                        </tr>
                                    )
                                })
                                : "Sorry! no such Quick Reply found."
                        }
                    </tbody>
                </Table>
            </DialogContent>
        </Dialog>
    )
}
// End of Conversation Related Dialog


export function MultiPurposeDialog(props) {
    const { open, purposeVal, demandVal, reqData, varName, wholeTemplate, mediaInfo, onClose } = props
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const [dialogTitle, setDialogTitle] = useState((demandVal === "template") ? "Template" : "Media")

    const [purpose, setPurpose] = useState(purposeVal) //NewChat | Chat | Bot | Campaign
    const [demand, setDemand] = useState(demandVal) //template | media

    const handlePurpose = (val) => { setPurpose(val) }
    const handleDemand = (val) => { setDemand(val) }

    useEffect(() => {
        handlePurpose(purposeVal)
        handleDemand(demandVal)
        setDialogTitle((demandVal === "template") ? "Choose Template" : "Choose Media")
    }, [purposeVal, demandVal])
    return (
        <>
            {/* Show UI */}
            {
                (demand === "template") ?
                    <TemplateUI open={open} purpose={purpose} reqDataVal={reqData} varName={varName} wholeTemplate={wholeTemplate} mediaInfo={mediaInfo} onCloseHere={onCloseHere} /> :
                    <MediaUI open={open} purpose={purpose} reqDataVal={reqData} onCloseHere={onCloseHere} />
            }
            {/* End of Show UI */}
        </>
    )
}


// Payment & Plan Related Dialog
export function PlanCheckDialog(props) {
    const history = useHistory()
    const { open, purpose, onClose } = props
    let img_url = ''
    let title = ''
    let msg = ''
    let btn_text = ''
    if (purpose === 'LimitExhausted') {
        img_url = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/RechargeNow.png'
        title = 'Your Premium count for this feature is over'
        msg = 'Recharge now to use this feature'
        btn_text = 'Recharge Now'
    }
    if (purpose === 'FreeTrialOver') {
        img_url = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/FreeTrialOver.png'
        title = 'Your free trial is over'
        msg = 'Your free trial is over Upgrade to premium to use the features of whatsTool'
        btn_text = 'Upgrade Now'
    }
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const opeUrl = (url) => {
        history.push(url)
    }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"xs"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <img className="img-fluid" src={img_url} alt={WtTheme.SiteName} />
            <DialogContent>
                <h5 className="text-center" style={{ color: WtColor.TextDarkColor, fontWeight: "18px" }}>{title}</h5>
                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontWeight: "15px" }}>{msg}</p>
                <br />
                <hr />
                <br />
                <p className="text-center">
                    <button onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey01">Cancel</button>
                    <button style={ValidButtonStyle} onClick={() => opeUrl('/dashboard/payment/plan-subscription')} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">{btn_text}</button>
                </p>
            </DialogContent>
        </Dialog>
    )
}

export function PaymentGatewayChooseDialog({ open, razorpayObj, stripeObj, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const RadioBtnColor = {
        color: WtColor.BaseColor,
        '&.Mui-checked': {
            color: WtColor.BaseColor,
        },
    }

    const [paymentGateway, setPaymentGateway] = useState('razorpay')
    const handlePaymentGateway = (e) => { setPaymentGateway(e.target.value) }

    const history = useHistory()
    const openURL = (url) => { history.push(url) }

    const handlePaymentOption = async () => {
        if (paymentGateway === "razorpay") {
            function loadScript(src) {
                return new Promise((resolve) => {
                    const script = document.createElement("script")
                    script.src = src
                    script.onload = () => { resolve(true) }
                    script.onerror = () => { resolve(false) }
                    document.body.appendChild(script)
                })
            }
            async function displayRazorpay() {
                const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")
                if (!res) { alert("Razorpay SDK failed to load. Are you online?"); return }

                const result = await razorpayPaymentAPI(
                    razorpayObj.paymentUrl,
                    {
                        "planId": razorpayObj.planId ? razorpayObj.planId : null,
                        "validity": razorpayObj.validity ? razorpayObj.validity : null,
                        "qty": razorpayObj.qty ? razorpayObj.qty : null,
                        "amount": razorpayObj.amount ? razorpayObj.amount : null,
                        "billId": razorpayObj.billId ? razorpayObj.billId : null
                    }
                )
                if (!result) { alert("Server error. Are you online?"); return }

                const { amount, id: order_id, currency } = result.data.data
                // console.log(result)

                const options = {
                    key: Env.razorpayKey,
                    amount: amount,
                    currency: currency,
                    name: WtTheme.SiteName,
                    description: razorpayObj.planName,
                    order_id: order_id,
                    handler: async function (response) {
                        const data = {
                            orderCreationId: order_id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature
                        }
                        // console.log("handler:", response, "--", response.razorpay_signature)
                        const result = await axios.post(`${Env.baseApiCallURL + Env.razorpayPaymentVerify}`, data)
                        if (result.status === 200) {
                            openURL("/dashboard/completion")
                            onCloseHere(false)
                        } else {
                            // alert(result.data.msg)
                            openURL("/dashboard/payment-failed")
                            onCloseHere(false)
                        }
                    },
                    prefill: { name: localStorage.getItem("userName"), email: localStorage.getItem("userEmail"), contact: "" },
                    notes: { address: "" },
                    theme: { color: WtColor.BaseColor }
                }
                const paymentObject = new window.Razorpay(options)
                paymentObject.open()
            }
            await displayRazorpay()
        }
        if (paymentGateway === "stripe") {
            let planId = stripeObj.planId ? stripeObj.planId : null
            let planName = stripeObj.planName ? stripeObj.planName : null
            let amount = stripeObj.amount ? stripeObj.amount : null
            let validity = stripeObj.validity ? stripeObj.validity : null
            let qty = stripeObj.qty ? stripeObj.qty : null
            let billId = stripeObj.billId ? stripeObj.billId : null
            let paymentUrl = stripeObj.paymentUrl ? stripeObj.paymentUrl : null
            let stripeUrl = WtUrl.paymentUrl +
                "?planId=" + planId +
                "&planName=" + planName +
                "&amount=" + amount +
                "&validity=" + validity +
                "&qty=" + qty +
                "&billId=" + billId +
                "&planUrl=" + paymentUrl

            openURL(stripeUrl)
            onCloseHere(false)
        }
    }


    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => { setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) }) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                Select payment method<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />
                <DialogContentText className="mt-4" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }}>
                    <RadioGroup defaultValue="razorpay" className='ms-3' name="radio-buttons-group" onChange={handlePaymentGateway}>
                        <FormControlLabel value="razorpay" control={<Radio sx={RadioBtnColor} />} label={<><img src={WtTheme.ImageBaseUrl + "/razorpay_icon_full.svg"} alt={WtTheme.SiteName} className="img-fluid" style={{ width: "80%", marginTop: "10px" }} /><br /><p style={{ color: WtColor.TextMidGrey, fontSize: "14px", marginLeft: "15px" }}>supports Indian users only</p></>} style={{ border: paymentGateway === "razorpay" ? "1px solid " + WtColor.BaseColor : "1px solid " + WtColor.LineColor, borderRadius: "10px", marginBottom: "20px" }} />
                        <FormControlLabel value="stripe" control={<Radio sx={RadioBtnColor} />} label={<><img src={WtTheme.ImageBaseUrl + "/stripe_icon_full.svg"} alt={WtTheme.SiteName} className="img-fluid" style={{ width: "80%" }} /><br /><p style={{ color: WtColor.TextMidGrey, fontSize: "14px", marginLeft: "15px" }}>supports worldwide</p></>} style={{ border: paymentGateway === "stripe" ? "1px solid " + WtColor.BaseColor : "1px solid " + WtColor.LineColor, borderRadius: "10px", marginBottom: "20px" }} />
                    </RadioGroup>
                </DialogContentText>
                <br />
                <hr />
                <div className='float-end'>
                    <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Cancel</button>
                    <button style={ValidButtonStyle} onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? handlePaymentOption() : openDemoDialogBox()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Next</button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
// End of Payment & Plan Related Dialog


// Broadcast Related Dialog
export function CampaignAnalyticsDialog({ open, title, id, onClose }) {
    const openSnackbar = useContext(globalContext)
    const snackbarShow = useContext(globalContext)
    const snackbarClose = useContext(globalContext)

    const tableRef = useRef(null)
    const downloadTableRef = useRef(null)
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    const [reloadStatus, setReloadStatus] = useState(true)

    const [analytics, setAnalytics] = useState([])
    const [tempVarCount, setTempCount] = useState(0)
    const getAnalytics = async () => {
        try {
            let data = { 'campaignId': id }
            const response = await fetchCampaignAnalytics(data)
            if (response.status === 200) {
                setAnalytics(response.data.data.campaigns)
                if (response.data.data.campaigns[0]?.components && response.data.data.campaigns[0]?.components?.length !== 0) {
                    setTempCount(response.data.data.campaigns[0]?.components?.length)
                } else {
                    setTempCount(0)
                }
            }
        } catch (error) { }
    }
    const [totalContactsCount, setTotalContactsCount] = useState(0)
    const [successCount, setSuccessCount] = useState(0)
    const [failedCount, setFailedCount] = useState(0)
    const [sentCount, setSentCount] = useState(0)
    const [deliveredCount, setDeliveredCount] = useState(0)
    const [readCount, setReadCount] = useState(0)
    const [userReplyCount, setUserReplyCount] = useState(0)
    // const [userBtnReplyCount, setUserBtnReplyCount] = useState(0)
    // const [userBtnReplyText, setUserBtnReplyText] = useState(0)
    // const [noReplyCount, setNoReplyCount] = useState(0)
    const getSpecificAnalytics = async () => {
        try {
            let data = { 'campaignId': id }
            const response = await fetchCampaignSpecificAnalyticsV3(data)
            if (response.status === 200) {
                if (response.data?.data?.campaign?.status === 'completed') setReloadStatus(false)
                setTotalContactsCount(response.data?.data?.campaign?.numbersCount)
                setSuccessCount(response.data?.data?.count?.sent)
                setFailedCount(response.data?.data?.count?.failed)
                setSentCount(response.data?.data?.count?.sent)
                setDeliveredCount(response.data?.data?.count?.delivered)
                setReadCount(response.data?.data?.count?.read)
                setUserReplyCount(response.data?.data?.replies?.userReplyText)

                // if (response.data?.data?.campaign?.template?.components) {
                //     response.data?.data?.campaign?.template?.components?.map((v, i) => {
                        
                //     })
                // }
                // setUserBtnReplyCount(response.data?.data?.replies?.buttonReply)
                // setUserBtnReplyText(response.data?.data?.replies?.userReplyText)
                // setNoReplyCount(response.data?.data?.replies?.userReplyText)
            }
        } catch (error) { }
    }
    const [reportUI, setReportUI] = useState('total')
    const handleReportUI = (ui, count) => {
        if (count !== 0) {
            setReportUI(ui)
        } else {
            snackbarShow.snackbarShow('No data found in report!!!', WtColor.RedColor)
        }
    }
    const handleDownloadReport = (count) => {
        if (count !== 0) {
            snackbarShow.snackbarShow('Downloading starts...', WtColor.BaseColor)
            setTimeout(() => { downloadTableRef.current.click() }, 1000)
        } else {
            snackbarShow.snackbarShow('No data found in report!!!', WtColor.RedColor)
        }
    }

    const CampaignLoaderUISchema = [
        { title: 'Total', reportKey: 'total', value: totalContactsCount, maxValue: totalContactsCount, loaderText: totalContactsCount, textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Total Contacts' },
        { title: 'Success', reportKey: 'success', value: successCount, maxValue: totalContactsCount, loaderText: successCount, textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Click to see Success Report' },
        { title: 'Failed', reportKey: 'failed', value: failedCount, maxValue: totalContactsCount, loaderText: failedCount, textColor: WtColor.RedColor, pathColor: WtColor.RedColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Click to see Failed Report' },
        { title: 'Sent', reportKey: 'sent', value: sentCount, maxValue: totalContactsCount, loaderText: sentCount, textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Click to see Sent Report' },
        { title: 'Delivered', reportKey: 'delivered', value: deliveredCount, maxValue: totalContactsCount, loaderText: deliveredCount, textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Click to see Delivered Report' },
        { title: 'Read', reportKey: 'read', value: readCount, maxValue: totalContactsCount, loaderText: readCount, textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Click to see Read Report' },
        { title: 'Text Reply', reportKey: 'userReply', value: userReplyCount, maxValue: totalContactsCount, loaderText: userReplyCount, textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey, tooltipText: 'Click to see User Text Reply Report' },
    ]

    useEffect(() => {
        if (reloadStatus) {
            setTimeout(() => {
                getSpecificAnalytics()
                getAnalytics()
            }, 10000)
        }
        getSpecificAnalytics()
        getAnalytics()
    }, [id])


    const [maxTimeVal, setMaxTimeVal] = useState(10)
    const [startTimeVal, setStartTimeVal] = useState(10)
    const handleTimeVal = () => {
        if (startTimeVal > 0) setStartTimeVal(startTimeVal - 1)
    }
    useEffect(() => {
        if (reloadStatus) { setTimeout(() => { handleTimeVal() }, 1000) }
    }, [startTimeVal])
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"xl"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ display: "flex", background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                <span style={{ flex: "1" }}>{title} Analytics</span>
                {
                    reloadStatus &&
                    <span className="me-5" style={{ display: 'flex' }}>
                        <div style={{ width: 40, height: 'auto', marginLeft: "auto", marginRight: "auto", marginTop: '-4px' }}>
                            <CircularProgressbar value={startTimeVal} maxValue={maxTimeVal} text={startTimeVal} styles={buildStyles({ textColor: WtColor.TextDarkColor, pathColor: WtColor.BaseColor, trailColor: WtColor.CloudyGrey })} />
                        </div>
                        <div>
                            <p style={{ marginBottom: '0px', color: WtColor.TextMidGrey, fontSize: '12px', marginLeft: '4px', marginTop: '6px' }}>Data will refresh in 10sec</p>
                        </div>
                    </span>
                }
                <span style={{ color: WtColor.ProcessingBlue }} className="hand">
                    <DownloadTableExcel filename={title + "_" + reportUI + "_CampaignReport"} sheet="users" currentTableRef={tableRef.current}>
                        <span ref={downloadTableRef}>Download Report</span>
                    </DownloadTableExcel>
                </span>
                <Close className="float-end hand ms-5" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <Container className="mt-3 mb-3" style={{ display: 'flex' }} fluid>
                    {
                        CampaignLoaderUISchema?.map((v, i) => {
                            return (
                                <div className="hand" style={{ flex: 1 }} key={i}>
                                    <Tooltip title={v.tooltipText} placement="bottom">
                                        <div style={{ width: 60, height: "auto", marginLeft: "auto", marginRight: "auto" }}>
                                            <p className="text-center" style={{ whiteSpace: 'nowrap', marginLeft: '-6px' }}>{v.title}</p>
                                            <div onClick={() => handleReportUI(v.reportKey, v.value)}>
                                                <CircularProgressbar value={v.value} maxValue={v.maxValue} text={v.loaderText} styles={buildStyles({ textColor: v.textColor, pathColor: v.pathColor, trailColor: v.trailColor })} />
                                            </div>
                                            <p onClick={() => handleDownloadReport(v.value)} className="mt-3" style={{ marginLeft: '-33px' }}><button className="btn btn-sm pt-0 pb-1 ps-3 pe-3" style={{ color: WtColor.TextDarkColor, background: WtColor.NeonGreenColor, borderRadius: '8px', fontSize: '12px', whiteSpace: 'nowrap' }}>Download Report</button></p>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        })
                    }
                </Container>
                <div className="report-ui app_list" style={{ height: '300px' }}>
                    <Table className='mt-3' ref={tableRef} responsive>
                        <thead style={{ background: WtColor.LineColor, color: WtColor.TextDarkColor }}>
                            <tr>
                                {reportUI === 'total' && <th className="ps-5">Channel</th>}
                                <th>WhatsApp</th>
                                {
                                    tempVarCount !== 0 &&
                                    [...Array(tempVarCount)].map((_v, i) => {
                                        return (<th key={i}>Variable {i + 1}</th>)
                                    })
                                }
                                <th>Text Reply</th>
                                {reportUI === 'total' && <><th>Sent Date</th><th>Delivered Date</th><th>Status</th></>}
                                <th>Error</th>
                            </tr>
                        </thead>
                        {
                            analytics.length !== 0 ?
                                <tbody style={{ background: "#fff", fontSize: "14px" }}>
                                    {
                                        analytics
                                            .filter((v) => {
                                                if (reportUI === 'total') { return v.status }
                                                if (reportUI === 'success') { return v.status !== 'failed' }
                                                if (reportUI === 'failed') { return v.status === 'failed' }
                                                if (reportUI === 'sent') { return v.status !== 'failed' }
                                                if (reportUI === 'delivered') { return v.status === 'delivered' || v.status === 'read' }
                                                if (reportUI === 'read') { return v.status === 'read' }
                                                if (reportUI === 'userReply') { return v.userReplyText }
                                            })
                                            .map((v, i) => {
                                                let errorMsg = v.errorMessage ? v.errorMessage?.toString() : "No Error"
                                                return (
                                                    <tr className="hand" key={i}>
                                                        {reportUI === 'total' && <td className="ps-5" style={{ color: WtColor.TextMidGrey }}>{v.extChannelId && v.extChannelId}</td>}
                                                        <td style={{ color: WtColor.TextMidGrey, paddingTop: "8px" }}>{v.whatsappRaw && v.whatsappRaw}</td>
                                                        {
                                                            tempVarCount !== 0 &&
                                                            [...Array(tempVarCount)].map((_v1, i1) => {
                                                                return (<td key={i1} style={{ color: v.components[i1] !== null ? WtColor.BrownColor : WtColor.TextLightGrey }}>{v.components[i1] !== null ? v.components[i1] : '{{Default Variable}}'}</td>)
                                                            })
                                                        }
                                                        <td style={{ color: v.userReplyText ? WtColor.BaseColor : WtColor.TextLightGrey, paddingTop: "8px" }}>{v.userReplyText ? v.userReplyText : 'Not yet!!!'}</td>
                                                        {
                                                            reportUI === 'total' &&
                                                            <>
                                                                <td style={{ color: WtColor.TextMidGrey, paddingTop: "8px" }}>{v.sentDate && ShowDateTime(v.sentDate)}</td>
                                                                <td style={{ color: WtColor.TextMidGrey, paddingTop: "8px" }}>{v.deliveredDate && ShowDateTime(v.deliveredDate)}</td>
                                                                <td style={{ color: WtColor.TextMidGrey, paddingTop: "8px" }}>{v.status && v.status}</td>
                                                            </>
                                                        }
                                                        <td style={{ color: WtColor.TextMidGrey, paddingTop: "8px" }}>{errorMsg}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                                :
                                <tbody><tr><td colSpan={6} style={{ color: WtColor.TextLightGrey, textAlign: 'center' }}>Analytics Loading... Please wait...</td></tr></tbody>
                        }
                    </Table>
                </div>

                {openSnackbar.openSnackbar.open && <ShowSnackbar open={openSnackbar.openSnackbar.open} errorMsg={openSnackbar.openSnackbar.errorMsg} errorMsgBg={openSnackbar.openSnackbar.errorMsgBg} close={snackbarClose.snackbarClose} />}
            </DialogContent>
            <DialogActions className="pt-3 pb-4" style={{ borderTop: "1px solid " + WtColor.LineColor }}>
                <button onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey01">Close</button>
            </DialogActions>
        </Dialog >
    )
}
// End of Broadcast Related Dialog



// Media Handling Codes
export const getFileType = (file) => {
    if (file.type.match('image/png') || file.type.match('image/jpeg') || file.type.match('image/jpg') || file.type.match('image/gif')) return 'image'
    if (file.type.match('video/mp4')) return 'video'
    if (file.type.match('audio/mpeg')) return 'audio'
    if (file.type.match('application/pdf')) return "document"
    return 'other'
}
export const getFileType2 = (val) => {
    if (val === "png" || val === "jpeg" || val === "jpg" || val === "gif") return 'image'
    if (val === "mp4") return 'video'
    if (val === "mp3") return 'audio'
    if (val === "pdf" || val === "xls" || val === "csv") return 'document'
    return 'other'
}
// End of Media Handling Codes


// Upload Media Work
export function UploadMedia({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    const mediaInputFileChooser = useRef(null)
    const handleMediaInputFileChooser = () => { mediaInputFileChooser.current.click() }

    const [chooseMediaFile, setChooseMediaFile] = useState([])
    const [chooseMedia, setChooseMedia] = useState(undefined)

    const [mediaType, setMediaType] = useState("image")

    const onDragOverHandler = (e) => e.preventDefault()
    const onDropHandler = (e) => {
        e.preventDefault()
        let file = ""
        if (e.dataTransfer.items) {
            file = [...e.dataTransfer.items].find((item) => item.kind === "file").getAsFile()
        } else {
            file = e.dataTransfer.files[0]
        }
        let file_size = e.dataTransfer.files[0].size
        mediaSetter(e, file, file_size, "drag")
    }
    const onMediaChoose = (e) => {
        const [file] = e.target.files
        let file_size = e.target.files[0].size
        mediaSetter(e, file, file_size, "click")
    }
    const mediaSetter = (e, file, file_size, val) => {
        if (getFileType(file) !== "other") {
            if ((getFileType(file) === "image") && (file_size <= WtFile.imgSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else if ((getFileType(file) === "video") && (file_size <= WtFile.VideoSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else if ((getFileType(file) === "audio") && (file_size <= WtFile.audioSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else if ((getFileType(file) === "document") && (file_size <= WtFile.pdfSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else {
                setMediaType(getFileType(file))
                // SnackbarShow("File size limit reached", WtColor.RedColor)
            }
        } else {
            setMediaType(getFileType(file))
            // SnackbarShow("File is not valid", WtColor.RedColor)
        }
    }


    const uploadMediaFile = async () => {
        try {
            let mediaData = new FormData()
            mediaData.append('file', chooseMediaFile)
            const mediaResponse = await uploadOwnMedia(mediaData)
            if (mediaResponse.status === 201) {
                setChooseMediaFile([])
                setChooseMedia(undefined)
                onCloseHere(true)
            }
        } catch (error) { }
    }
    // End of Media Handle
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"xl"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                Upload Media <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <div className="p-5 m-5" onClick={handleMediaInputFileChooser} onDrop={onDropHandler} onDragOver={onDragOverHandler} style={{ border: "6px dotted", borderColor: WtColor.LineColor, height: "380px" }}>
                    {
                        (chooseMedia === undefined) ? (
                            <div className="dragDropView pt-0" >
                                <label className="dragDropLabel">
                                    <DefaultMediaUIPreview />
                                </label>
                            </div>
                        ) : (
                            <div className="dragDropView" >
                                <label className="dragDropLabel">
                                    {
                                        (() => {
                                            if (mediaType === "image") {
                                                return <img src={chooseMedia} alt={WtTheme.SiteName} />
                                            }
                                            if (mediaType === "other") {
                                                return <DefaultMediaUIPreview />
                                            }
                                            if (mediaType === "video") {
                                                return <video className="hand p-2" controls><source src={chooseMedia} type="video/mp4" /></video>
                                            }
                                            if (mediaType === "audio") {
                                                return <ReactAudioPlayer src={chooseMedia} controls className="p-2" />
                                            }
                                            if (mediaType === "document") {
                                                return <p className="text-center"><PictureAsPdfOutlined className="p-2" /></p>
                                            }
                                        })()
                                    }
                                </label>
                            </div>
                        )
                    }
                    <input type={"file"} onChange={onMediaChoose} ref={mediaInputFileChooser} style={{ display: "none" }} accept="image/png, image/jpeg, image/jpg, video/mp4, audio/mpeg, application/pdf" className="form-control" multiple />
                </div>
                <br />
                <hr />
                <div className="float-end">
                    <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Cancel</button>
                    <button style={ValidButtonStyle} disabled={chooseMedia === undefined ? true : false} onClick={() => uploadMediaFile()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Upload</button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
// End of Upload Media Work


// WT Loader Work
export function WtLoader({ open }) {
    return (
        <Dialog className="wt_loader" open={open}>
            <p className='text-center'><img src={WtUrl.imageBaseUrl + '/wtb_loader_anim.gif'} style={{ width: '120px', height: '120px' }} alt={WtString.SiteName} /></p>
        </Dialog>
    )
}
// End of WT Loader Work