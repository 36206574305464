import React from "react"
import { Close } from "@mui/icons-material"
import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material"
import { WtColor } from "../constants/colours"


const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })


// Pricing - HomePage
export function PricingFeaturesDialog({ open, title, message, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: WtColor.TextMidGrey, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
                <br />
                <hr />
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Close</button>
            </DialogContent>
        </Dialog>
    )
}
// End of Pricing - HomePage