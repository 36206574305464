/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import './index.css'
import { ContactList } from './ContactList'
import { UserInfo } from './UserInfo'
import ContactsState from '../../context/contacts/contactsState'
import contactsContext from '../../context/contacts/contactsContext'
import globalContext from '../../context/global/globalContext'
import { ContactsFilter } from './Filter'
import { WtLoader } from '../../controller/BaseTool'

export const Contacts = () => { return (<ContactsState><ContactsApp /></ContactsState>) }

export const ContactsApp = () => {
    const isLoading = useContext(contactsContext)
    const notificationBarStatus = useContext(globalContext)
    const splitUIName = useContext(contactsContext)

    const contactsStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '96vh',
        width: '95vw'
    }
    return (
        <div style={contactsStyle}>
            <ContactList />
            {splitUIName.splitUIName === 'on_user_info' && <UserInfo />}
            {splitUIName.splitUIName === 'on_filter' && <ContactsFilter />}
            {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
        </div>
    )
}