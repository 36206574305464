/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { MoreVert, PictureAsPdfOutlined, Upload } from '@mui/icons-material'
import { WtTheme } from '../../controller/theme'
import { deleteOwnMediaAPI } from '../../controller/APIs'
import { ConfirmDialog, ShowSnackbar, UploadMedia, WtLoader, copyToClipboard } from '../../controller/BaseTool'
import ReactAudioPlayer from 'react-audio-player'
import { Menu, MenuItem } from '@mui/material'


export const MediaFiles = () => {
    const notificationBarStatus = useContext(globalContext)
    const mediaLs = useContext(globalContext)
    const getMediaLs = useContext(globalContext)
    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)
    const openSnackbar = useContext(globalContext)
    const snackbarShow = useContext(globalContext)
    const snackbarClose = useContext(globalContext)

    useEffect(() => {
        if (mediaLs.mediaLs?.length === 0) {
            handleWTLoader.handleWTLoader(true)
            getMediaLs.getMediaLs()
        }
    }, [])

    const copyURL = (v) => {
        copyToClipboard(v)
        snackbarShow.snackbarShow("URL Copied", WtColor.BaseColor)
    }

    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const deleteMedia = async (id) => {
        setOpenConfirmDialogBox({
            open: true, title: WtTheme.SiteName, message: 'Are your sure to delete this Media?', onClose: async (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) {
                    handleWTLoader.handleWTLoader(true)
                    try {
                        let data = { '_id': id }
                        const response = await deleteOwnMediaAPI(data)
                        if (response.status === 204) {
                            getMediaLs.getMediaLs()
                            handleWTLoader.handleWTLoader(false)
                        } else { handleWTLoader.handleWTLoader(false) }
                    } catch (error) { handleWTLoader.handleWTLoader(false) }
                }
            }
        })

    }

    const checkMediaType = (fileName) => { return fileName?.substr(fileName?.lastIndexOf('.') + 1) }

    const [openUploadMedia, setOpenUploadMedia] = useState({ open: false, onClose: null })
    const openUploadMediaDialog = () => setOpenUploadMedia({
        open: true, onClose: (confirm) => {
            setOpenUploadMedia(false)
            if (confirm) {
                handleWTLoader.handleWTLoader(true)
                getMediaLs.getMediaLs()
            }
        }
    })


    const mainStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    const profile_menu_item = {
        flex: '0.85'
    }
    return (
        <div style={mainStyle}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Media</h5>
                        </div>
                        <div>
                            <button onClick={() => openUploadMediaDialog()} className="btn btn-sm ps-3 pe-3" style={{ background: WtColor.BaseColor, color: '#fff' }}><Upload style={{ fontSize: '18px', marginTop: '-4px' }} /> Upload Media</button>
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <Container fluid>
                            <Tabs defaultActiveKey="images">
                                <Tab eventKey="images" title="Images">
                                    <Row className="mt-1 app_list" style={{ height: notificationBarStatus.notificationBarStatus ? '500px' : '590px' }} fluid>
                                        {
                                            mediaLs.mediaLs?.filter((v) => { return checkMediaType(v.link) === "png" || checkMediaType(v.link) === "jpg" || checkMediaType(v.link) === "jpeg" || checkMediaType(v.link) === "gif" }).map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} key={i} className="hand mb-3">
                                                        <div className="rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey, width: '250px', height: '200px' }}>
                                                            <OptionsUI id={v._id} deleteAction={deleteMedia} url={v.link} copyAction={copyURL} />
                                                            <p className="text-center"><img src={v.link} style={{ width: 'auto', maxWidth: '240px', height: 'auto', maxHeight: '180px', padding: '8px' }} className="rounded-2 img-fluid" alt={WtTheme.SiteName} /></p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="videos" title="Videos">
                                    <Row className="mt-1 app_list" style={{ height: notificationBarStatus.notificationBarStatus ? '500px' : '590px' }} fluid>
                                        {
                                            mediaLs.mediaLs?.filter((v) => { return checkMediaType(v.link) === "mp4" }).map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} key={i} className="hand mb-3">
                                                        <div className="rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey, width: '250px', height: '200px' }}>
                                                            <OptionsUI id={v._id} deleteAction={deleteMedia} url={v.link} copyAction={copyURL} />
                                                            <p className="text-center"><video className="rounded-2" style={{ width: 'auto', maxWidth: '240px', height: 'auto', maxHeight: '180px', padding: '8px' }} controls><source src={v.link} type="video/mp4" /></video></p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="audios" title="Audios">
                                    <Row className="mt-1 app_list" style={{ height: notificationBarStatus.notificationBarStatus ? '500px' : '590px' }} fluid>
                                        {
                                            mediaLs.mediaLs?.filter((v) => { return checkMediaType(v.link) === "mp3" }).map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} key={i} className="hand mb-3">
                                                        <div className="rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey, width: '250px', height: '200px' }}>
                                                            <OptionsUI id={v._id} deleteAction={deleteMedia} url={v.link} copyAction={copyURL} />
                                                            <p className="text-center mt-5" style={{ width: 'auto', maxWidth: '240px', height: 'auto', maxHeight: '180px', padding: '8px' }}><ReactAudioPlayer src={v.link} controls style={{ width: "230px" }} /></p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="documents" title="Documents">
                                    <Row className="mt-1 app_list" style={{ height: notificationBarStatus.notificationBarStatus ? '500px' : '590px' }} fluid>
                                        {
                                            mediaLs.mediaLs?.filter((v) => { return checkMediaType(v.link) === "pdf" || checkMediaType(v.link) === "xls" || checkMediaType(v.link) === "csv" }).map((v, i) => {
                                                return (
                                                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} key={i} className="hand mb-3">
                                                        <div className="rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey, width: '250px', height: '200px' }}>
                                                            <OptionsUI id={v._id} deleteAction={deleteMedia} url={v.link} copyAction={copyURL} />
                                                            <p className="text-center mt-5" style={{ width: 'auto', maxWidth: '240px', height: 'auto', maxHeight: '180px', padding: '8px' }}><PictureAsPdfOutlined style={{ fontSize: "70px" }} /></p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Container>
                    </div>
                </div>
            </div>
            {openSnackbar.openSnackbar.open && <ShowSnackbar open={openSnackbar.openSnackbar.open} errorMsg={openSnackbar.openSnackbar.errorMsg} errorMsgBg={openSnackbar.openSnackbar.errorMsgBg} close={snackbarClose.snackbarClose} />}
            {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
            {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} onClose={openConfirmDialogBox.onClose} />}
            {openUploadMedia.open && <UploadMedia open={openUploadMedia.open} onClose={openUploadMedia.onClose} />}
        </div >
    )
}

export const OptionsUI = ({ id, deleteAction, url, copyAction }) => {
    // Option Menu Handing
    const [msgMenuAnchor, setMsgMenuAnchor] = useState(null)
    const open = Boolean(msgMenuAnchor)
    const handleMsgOptOpen = (event) => setMsgMenuAnchor(event.currentTarget)
    const handleMsgOptClose = () => setMsgMenuAnchor(null)
    const handleCopyURL = (event) => {
        copyAction(url)
        setMsgMenuAnchor(null)
    }
    const handleDeleteMedia = (event) => {
        deleteAction(id)
        setMsgMenuAnchor(null)
    }
    // End of Option Menu Handing
    return (
        <>
            <MoreVert className="float-end" onClick={handleMsgOptOpen} style={{ color: WtColor.TextLightGrey, position: 'relative', top: '3px', right: '3px' }} />
            <>
                <Menu anchorEl={msgMenuAnchor} open={open} onClose={handleMsgOptClose}>
                    <MenuItem onClick={handleCopyURL}>Copy URL</MenuItem>
                    <MenuItem onClick={handleDeleteMedia}>Delete</MenuItem>
                </Menu>
            </>
        </>
    )
}