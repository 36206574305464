/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Tooltip, Zoom } from '@mui/material'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { InfoOutlined, AddOutlined, Delete, ModeEdit } from '@mui/icons-material'
import { deleteQuickReplyAPI, fetchAllQuickReplyAPI } from '../../controller/APIs'
import { AddEditQuickReply, ConfirmDialog, DemoDialog, WtLoader } from '../../controller/BaseTool'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'


export const QuickReply = () => {
    const notificationBarStatus = useContext(globalContext)
    const [quickReplyLs, setQuickReplyLs] = useState([])

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    const [openAddEditDialog, setOpenAddEditDialog] = useState({ open: false, onClose: null })
    const openAddEditDialogBox = (purpose, params) => {
        setOpenAddEditDialog({
            open: true, purpose: purpose, params: params, onClose: (confirm) => {
                setOpenAddEditDialog(false)
                if (confirm) fetchAllQuickReply()
            }
        })
    }

    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const openConfirmationForDeletionBox = (id) => {
        setOpenConfirmDialogBox({
            open: true, title: 'Quick Reply Deletion', message: 'Are your sure to delete this Quick Reply?', onClose: (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) deleteQuickReply(id)
            }
        })
    }

    // List of Quick Reply 
    const fetchAllQuickReply = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchAllQuickReplyAPI()
            if (response.status === 200) {
                handleWTLoader.handleWTLoader(false)
                setQuickReplyLs(response.data.data)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of List of Quick Reply 

    // Delete Quick Reply 
    const deleteQuickReply = async (id) => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = { "_id": id }
            const response = await deleteQuickReplyAPI(data)
            if (response.status === 204) {
                fetchAllQuickReply()
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of Delete Quick Reply 

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })

    useEffect(() => { fetchAllQuickReply() }, [])


    const profile_base_view = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    const profile_menu_item = {
        flex: '0.85'
    }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} onClose={openConfirmDialogBox.onClose} />}
                {openAddEditDialog.open && <AddEditQuickReply open={openAddEditDialog.open} purpose={openAddEditDialog.purpose} params={openAddEditDialog.params} onClose={openAddEditDialog.onClose} />}
                {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Quick Reply &nbsp;
                                <Tooltip title="Create shortcuts for the messages you send to your customers most frequently" placement="bottom-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }}>
                                    <InfoOutlined style={{ color: WtColor.TextLightGrey, marginTop: "-5px", fontSize: '15px' }} />
                                </Tooltip>
                            </h5>
                        </div>
                        <div>
                            <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditDialogBox("add") : openDemoDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius }}><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Add Quick Reply</button>
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <div className="app_list" style={{ height: "610px" }}>
                            <Table className="fw-bold" responsive>
                                <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: "12px" }}>
                                    <tr><th>Quick Reply</th><th>Action</th></tr>
                                </thead>
                                <tbody style={{ background: "#fff", fontSize: "12px" }}>
                                    {
                                        quickReplyLs.map((v, i) => {
                                            return (
                                                <tr className="hand" key={i}>
                                                    <td style={{ color: WtColor.TextMidGrey, fontSize: '13px' }}>
                                                        {(v.text.length >= 150) ? v.text.substring(0, 140) + " ..." : v.text}
                                                    </td>
                                                    <td>
                                                        <Container fluid>
                                                            <Row>
                                                                <Col>
                                                                    <ModeEdit onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditDialogBox("edit", v) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                                </Col>
                                                                <Col>
                                                                    <Delete onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openConfirmationForDeletionBox(v._id) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}