/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { getId } from './App'
import { Collapse, IconButton, List, ListItemButton, ListItemText, Tooltip } from '@mui/material'
import { DragIndicatorSharp, SmartToyOutlined, StartOutlined } from '@mui/icons-material'
import { TemplatesDialog } from './Dialogs'
import { useHistory } from 'react-router-dom'
import { ConfirmSingleActionDialog } from '../../controller/BaseTool'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'

const onDragStart = (event, msgType) => {
	event.dataTransfer.setData('application/reactflow', JSON.stringify({ msgType: msgType }))
	event.dataTransfer.effectAllowed = 'move'
}

const LeftSidebar = ({ saveBots, onSelectTemplate }) => {
	const [openTemplateDialog, setOpenTemplateDialog] = useState({ open: false, onClose: null })
	const openTemplateDialogBox = () => {
		setOpenTemplateDialog({
			open: true, onClose: (template) => {
				setOpenTemplateDialog(false)
				if (template) onSelectTemplate(template)
			}
		})
	}


	// LeftSideBar Open Close Code
	const [leftSideBarDisplay, setLeftSideBarDisplay] = useState(localStorage.getItem('botLeftSidebar') === 'true' ? 'none' : 'block')
	const [leftSideBarWidth, setLeftSideBarWidth] = useState(localStorage.getItem('botLeftSidebar') === 'true' ? '60px' : '300px')
	const [rotateLeftSideBarIcon, setRotateLeftSideBarIcon] = useState(localStorage.getItem('botLeftSidebar') === 'true' ? 'rotate(0deg)' : 'rotate(180deg)')
	const handleLeftSideBarOpenClose = () => {
		if (leftSideBarDisplay === "block") {
			setLeftSideBarDisplay("none")
			setLeftSideBarWidth("60px")
			setRotateLeftSideBarIcon("rotate(0deg)")
			localStorage.setItem('botLeftSidebar', 'true')
		} else {
			setLeftSideBarDisplay("block")
			setLeftSideBarWidth("300px")
			setRotateLeftSideBarIcon("rotate(180deg)")
			localStorage.setItem('botLeftSidebar', 'false')
		}
	}
	// End of LeftSideBar Open Close Code

	// Dialogs
	const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
	const openConfirmDialogBox = () => setOpenConfirmDialog({
		open: true, title: 'Your changes are not saved', msg: 'Work in progress, Please save it before leaving this page.',
		onClose: async (confirm) => {
			if (confirm) await saveBots()
			if (!confirm) {
				localStorage.setItem('userActivity', 'false')
				history.push('/dashboard/chatbot-list')
			}
			setOpenConfirmDialog(false)
		}
	})
	// End of Dialogs

	const history = useHistory()
	const openChatbotList = () => {
		if (localStorage.getItem('userActivity') === 'false') history.push('/dashboard/chatbot-list')
		if (localStorage.getItem('userActivity') === 'true') openConfirmDialogBox()
	}


	const [openWAppMsgItems, setOpenWAppMsgItems] = useState(true)
	const handleWAppMsgItems = () => setOpenWAppMsgItems(!openWAppMsgItems)
	const [openMediaMsgItems, setOpenMediaMsgItems] = useState(false)
	const handleMediaMsgItems = () => setOpenMediaMsgItems(!openMediaMsgItems)


	// const MsgData_Test = [
	// 	{ id: getId(), msgKey: 'interactive_buttons', title: 'Interactive Message', img: WtTheme.ImageBaseUrl + '/interactive.svg' },
	// 	{ id: getId(), msgKey: 'interactive_menu', title: 'Menu Message', img: WtTheme.ImageBaseUrl + '/interactive_menu.svg' },
	// 	{ id: getId(), msgKey: 'interactive_products_single', title: 'Single Product Message', img: WtTheme.ImageBaseUrl + '/interactive_menu.svg' },
	// 	{ id: getId(), msgKey: 'interactive_products_multiple', title: 'Multiple Product Message', img: WtTheme.ImageBaseUrl + '/interactive_menu.svg' },
	// 	{ id: getId(), msgKey: 'text', title: 'Text Message', img: WtTheme.ImageBaseUrl + '/text.svg' },
	// 	{ id: getId(), msgKey: 'button_ref', title: 'Quick Reply Button', img: WtTheme.ImageBaseUrl + '/bot_button.svg' },
	// 	{ id: getId(), msgKey: 'action', title: 'Action', img: WtTheme.ImageBaseUrl + '/actionIcon.svg' },
	// 	{ id: getId(), msgKey: 'image', title: 'Image Message', img: WtTheme.ImageBaseUrl + '/image.svg' },
	// 	{ id: getId(), msgKey: 'video', title: 'Video Message', img: WtTheme.ImageBaseUrl + '/videoMsg.svg' },
	// 	{ id: getId(), msgKey: 'audio', title: 'Audio Message', img: WtTheme.ImageBaseUrl + '/audioMsg.svg' },
	// 	{ id: getId(), msgKey: 'document', title: 'Document Message', img: WtTheme.ImageBaseUrl + '/documentIcon.svg' }
	// ]
	const MsgData = [
		{ id: getId(), msgKey: 'interactive_buttons', title: 'Interactive Message', img: WtTheme.ImageBaseUrl + '/interactive.svg' },
		{ id: getId(), msgKey: 'interactive_menu', title: 'Menu Message', img: WtTheme.ImageBaseUrl + '/interactive_menu.svg' },
		{ id: getId(), msgKey: 'text', title: 'Text Message', img: WtTheme.ImageBaseUrl + '/text.svg' },
		{ id: getId(), msgKey: 'button_ref', title: 'Quick Reply Button', img: WtTheme.ImageBaseUrl + '/bot_button.svg' },
		{ id: getId(), msgKey: 'action', title: 'Action', img: WtTheme.ImageBaseUrl + '/actionIcon.svg' },
		{ id: getId(), msgKey: 'image', title: 'Image Message', img: WtTheme.ImageBaseUrl + '/image.svg' },
		{ id: getId(), msgKey: 'video', title: 'Video Message', img: WtTheme.ImageBaseUrl + '/videoMsg.svg' },
		{ id: getId(), msgKey: 'audio', title: 'Audio Message', img: WtTheme.ImageBaseUrl + '/audioMsg.svg' },
		{ id: getId(), msgKey: 'document', title: 'Document Message', img: WtTheme.ImageBaseUrl + '/documentIcon.svg' }
	]
	const [botMsgLs, setBotMsgList] = useState([])
	useEffect(() => { setBotMsgList(MsgData) }, [])
	const searchItems = (searchValue) => {
		if (searchValue.length > 0) {
			setOpenWAppMsgItems(true)
			setOpenMediaMsgItems(true)
		} else {
			setOpenMediaMsgItems(false)
		}
		const filteredData = MsgData.filter((item) => { return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase()) })
		setBotMsgList(filteredData)
	}
	return (
		<>
			<aside className="leftSideBarMenu" style={{ width: leftSideBarWidth, maxWidth: leftSideBarWidth, overflowX: "hidden", height: "100%" }}>
				<div className="" style={{ display: "flex" }}>
					<div style={{ flex: 1, display: leftSideBarDisplay }}>
						<Tooltip title="All Bots" placement="right-end"><IconButton onClick={openChatbotList}><SmartToyOutlined style={{ color: WtColor.TextLightGrey }} /></IconButton></Tooltip>
					</div>
					<div>
						<IconButton onClick={() => handleLeftSideBarOpenClose()}><StartOutlined className="leftSideBarIcon" style={{ color: WtColor.TextLightGrey, transform: rotateLeftSideBarIcon }} /></IconButton>
					</div>
				</div>

				<div style={{ display: leftSideBarDisplay, borderBottom: '1px solid ' + WtColor.LineColor, paddingBottom: '6px' }}>
					<input onChange={(e) => searchItems(e.target.value)} text="search" placeholder='Search' style={{ border: 'none', outline: 'none', fontSize: '18px', color: WtColor.TextMidGrey }} />
				</div>

				<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
					<ListItemButton onClick={handleWAppMsgItems}>
						<ListItemText primary={<div className='fw-bold' style={{ display: leftSideBarDisplay, color: WtColor.TextDarkColor }}>WhatsApp Message Items</div>} />
						{openWAppMsgItems ? <ExpandLess style={{ color: WtColor.TextDarkColor }} /> : <ExpandMore style={{ color: WtColor.TextDarkColor }} />}
					</ListItemButton>
					<Collapse in={openWAppMsgItems} className="ms-2 me-2" timeout="auto" unmountOnExit>
						{
							botMsgLs?.filter((v, i) => i < 5).map((v, i) => {
								return (
									<div className={"hand" + i === 0 ? " " : " mt-2"} key={v.id} style={{ display: "flex", border: "1px solid " + WtColor.LineColor, borderRadius: "10px" }} onDragStart={event => onDragStart(event, v.msgKey)} draggable>
										<img src={v.img} className="img-fluid" alt={WtTheme.SiteName} style={{ width: "40px" }} />
										<p style={{ display: leftSideBarDisplay, fontSize: "16px", marginTop: "13px", fontWeight: "500", color: WtColor.TextMidGrey, flex: 1 }}>{v.title}</p>
										<DragIndicatorSharp style={{ display: leftSideBarDisplay, marginTop: "13px", color: WtColor.TextLightGrey }} />
									</div>
								)
							})
						}
					</Collapse>
				</List>

				<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
					<ListItemButton onClick={handleMediaMsgItems}>
						<ListItemText primary={<div className='fw-bold' style={{ display: leftSideBarDisplay, color: WtColor.TextDarkColor }}>Media</div>} />
						{openMediaMsgItems ? <ExpandLess style={{ color: WtColor.TextDarkColor }} /> : <ExpandMore style={{ color: WtColor.TextDarkColor }} />}
					</ListItemButton>
					<Collapse in={openMediaMsgItems} className="ms-2 me-2" timeout="auto" unmountOnExit>
						{
							botMsgLs?.filter((v, i) => i > 4).map((v, i) => {
								return (
									<div className={"hand" + i === 0 ? " " : " mt-2"} key={v.id} style={{ display: "flex", border: "1px solid " + WtColor.LineColor, borderRadius: "10px" }} onDragStart={event => onDragStart(event, v.msgKey)} draggable>
										<img src={v.img} className="img-fluid" alt={WtTheme.SiteName} style={{ width: "40px" }} />
										<p style={{ display: leftSideBarDisplay, fontSize: "16px", marginTop: "13px", fontWeight: "500", color: WtColor.TextMidGrey, flex: 1 }}>{v.title}</p>
										<DragIndicatorSharp style={{ display: leftSideBarDisplay, marginTop: "13px", color: WtColor.TextLightGrey }} />
									</div>
								)
							})
						}
					</Collapse>
				</List>

				<div className='dndflow_section mb-3 mt-2' style={{ display: leftSideBarDisplay, fontSize: "16px", fontWeight: "500" }}>Templates</div>

				<div className="hand" onClick={() => openTemplateDialogBox()} style={{ display: "flex", borderRadius: "10px" }}>
					<img src={WtTheme.ImageBaseUrl + "/template.svg"} className="img-fluid" alt={WtTheme.SiteName} style={{ width: "40px" }} />
					<p style={{ display: leftSideBarDisplay, fontSize: "16px", marginTop: "13px", fontWeight: "500", color: WtColor.TextMidGrey, flex: 1 }}>Open Templates</p>
				</div>

				{openTemplateDialog.open && <TemplatesDialog open={openTemplateDialog.open} onClose={openTemplateDialog.onClose} />}
				{openConfirmDialog.open && <ConfirmSingleActionDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />}
			</aside >
		</>
	)
}

export default LeftSidebar