import { Env } from "./controller/constant";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import uuid from "react-native-uuid";
import { LoginValidate } from "./controller/caller";
const app = initializeApp(Env.firebaseConfig);
const messaging = getMessaging(app);

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");

      getToken(messaging, { vapidKey: Env.vapid }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken: ", currentToken);
          const pairs = document.cookie.split(";");
          let deviceid = "";
          console.log("pairs: ", pairs);
          pairs.forEach((e) => {
            const v = e.trim();
            console.log("e", v.startsWith("deviceIds"));
            if (v.startsWith("deviceIds")) {
              deviceid = e.split("=")[1];
              console.log("device: ", e.split("="));
            }
          });
          console.log("deviceId: ", deviceid);
          if (deviceid) {
            getdeviceIdToken(deviceid, currentToken);
          } else {
            const id = getDeviceId();
            getdeviceIdToken(id, currentToken);
            setCookie("deviceIds", id, 365);
            setCookie("fcmTokens", currentToken, 1);
          }
        } else {
          console.log("Can not get token:");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}
function getdeviceIdToken(id, currentToken) {
  const fcmDetails = {
    fcmToken: currentToken,
    deviceId: id,
  };
  console.log("loginValidations", LoginValidate());
  if (LoginValidate() === true) {
    pushFcmCode(fcmDetails);
  }
}

function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

const pushFcmCode = async (code) => {
  const result = await axios.post(
    Env.baseApiCallURL + "/emp/update-fcm-token",
    code
  );
  if (result.status === 200) {
    
    console.log(result.data);
  }
};

requestPermission();
getDeviceId();
function getDeviceId() {
  return uuid.v4();
}

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
});
