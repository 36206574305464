/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import './index.css'
import { FormControlLabel, Radio, RadioGroup, TextField, Tooltip } from '@mui/material'
import Accordion from 'react-bootstrap/Accordion'
import { Add, InfoOutlined, MenuBookOutlined, VideocamOutlined } from '@mui/icons-material'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom"
import { Player } from '@lottiefiles/react-lottie-player'
import { WtTheme } from '../../controller/theme'
import { createChannelAPI, fetchPlanList, fetchUserLocation, setOnBoardingStatusInBusiness } from '../../controller/APIs'
import { DemoStartDialog, PaymentGatewayChooseDialog } from '../../controller/BaseTool'
import { WtColor } from '../../constants/colours'


export const AppOnboardingUI = (props) => {
    const history = useHistory()
    const { onCloseHere } = props

    const CancelButtonStyle = { color: WtColor.TextMidGrey, textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, textTransform: "none", boxShadow: "none" }
    const FooterDownButtonStyle = { background: "#fff", color: WtColor.TextDarkColor, textTransform: "none", boxShadow: "none" }
    const FooterStyle = { display: "flex", position: "relative", bottom: 0, background: WtColor.LineColor, color: WtColor.TextMidGrey, paddingTop: "15px", paddingBottom: "15px", marginLeft: "-12px", marginRight: "-12px" }

    // UI Decide
    const [uiOpt, setUiOpt] = useState(undefined)
    const handleUiOpt = (ui) => { setUiOpt(ui) }
    // End of UI Decide


    // Hooks Handling
    const [channelType, setChannelType] = useState('waba')
    const handleChannelType = (event) => { setChannelType(event.target.value) }

    const [monthlyUsage, setMonthlyUsage] = useState('')
    const handleMonthlyUsage = (val) => { setMonthlyUsage(val) }
    // End of Hooks Handling

    // Create Channels 
    const [title, setTitle] = useState("")
    const [whatsapp, setWhatsapp] = useState("")
    const [apiKey, setApiKey] = useState("")
    const [domain, setDomain] = useState("https://waba.360dialog.io")
    const [wabaId, setWabaId] = useState("")
    const [wabaToken, setWabaToken] = useState("")

    const handleTitle = (val) => {
        setTitle(val)
        localStorage.setItem("tempChTitle", val)
    }
    const handleWhatsApp = (val) => {
        let number = val?.replace(/[^0-9]/ig, "")
        setWhatsapp(number)
        localStorage.setItem("tempChWhatsApp", number)
    }
    const handleApiKey = (val) => { setApiKey(val) }
    const handleDomain = (val) => { setDomain(val) }
    const handleWabaId = (val) => { setWabaId(val) }
    const handleWabaToken = (val) => { setWabaToken(val) }

    useEffect(() => {
        if (localStorage.getItem("tempChTitle") && localStorage.getItem("tempChTitle")?.trim() !== "") {
            handleTitle(localStorage.getItem("tempChTitle"))
        }
        if (localStorage.getItem("tempChWhatsApp") && localStorage.getItem("tempChWhatsApp")?.trim() !== "") {
            handleWhatsApp(localStorage.getItem("tempChWhatsApp"))
        }
    }, [])

    const createChannels = async () => {
        if (whatsapp?.trim() !== "" && title?.trim() !== "") {
            try {
                let data = ""
                if (channelType === "waba" && wabaId?.trim() !== "" && wabaToken?.trim() !== "") {
                    data = { 'whatsapp': whatsapp, 'wabaId': wabaId, 'wabaToken': wabaToken, 'title': title, 'type': channelType }
                }
                if (channelType === "dialog360" && apiKey?.trim() !== "" && domain?.trim() !== "") {
                    data = { 'whatsapp': whatsapp, 'title': title, 'type': channelType, 'apiKey': apiKey, 'domain': domain }
                }
                const response = await createChannelAPI(data)
                if (response.status === 201) {
                    localStorage.setItem("onboardingStatus", "completed")
                } else { onCloseHere(false) }
            } catch (err) { }
        }
    }
    // End of Create Channels

    const openURL = (url) => { history.push(url) }

    const [wtbAddOnId, setWtbAddOnId] = useState("")
    const [wtbAddOnPrice, setWtbAddOnPrice] = useState("")
    const getPlanList = async () => {
        try {
            const response = await fetchPlanList()
            if (response.status === 200) {
                response.data.data?.map((v, i) => {
                    if (v._id === "WTBSetup") {
                        v.plans?.map((v1) => {
                            setWtbAddOnId(v1._id)
                            if (v1.percentageS === 0) setWtbAddOnPrice(v1.price)
                            if (v1.percentageS !== 0) {
                                let finalPrice = Math.floor(v1.price - ((v1.price * v1.percentageS) / 100))
                                setWtbAddOnPrice(finalPrice)
                            }
                        })
                    }
                })
            }
        } catch (error) { }
    }

    useEffect(() => { getPlanList() }, [])

    const updateOnboardingStatus = async (nextKey) => {
        if (nextKey === "buy_now") {
            handleUiOpt("need_help")
            localStorage.setItem("onboardingStatus", "need_help")
            localStorage.setItem('planPageTab', 'upgrage_plan')
            let stripePaymentObj = {
                "paymentUrl": "/payments/wtb-setup",
                "planId": wtbAddOnId,
                "validity": "",
                "qty": "",
                "amount": wtbAddOnPrice,
                "billId": "",
                "planName": "WTB Quick Setup"
            }
            let razorpayPaymentObj = {
                "paymentUrl": "/payments/razorpay/wtb-setup",
                "planId": wtbAddOnId,
                "validity": "",
                "qty": "",
                "amount": wtbAddOnPrice,
                "billId": "",
                "planName": "WTB Quick Setup"
            }
            openPaymentGatewayOptDialogBox(razorpayPaymentObj, stripePaymentObj)
            return
        }
        if (nextKey === "check_demo") {
            openDemoStartDialogBox()
            return
        }
        if (nextKey === "buy_now_360_sd") {
            handleUiOpt("dialog360_purchase")
            localStorage.setItem("onboardingStatus", "dialog360_purchase")
            // let stripePaymentObj = {
            //     "paymentUrl": "/payments/stripe/d360-security-deposit",
            //     "planId": "",
            //     "validity": "",
            //     "qty": "",
            //     "amount": "2000",
            //     "billId": "",
            //     "planName": "Dialog360 Security Deposit"
            // }
            // let razorpayPaymentObj = {
            //     "paymentUrl": "/payments/razorpay/d360-security-deposit",
            //     "planId": "",
            //     "validity": "",
            //     "qty": "",
            //     "amount": "",
            //     "billId": "",
            //     "planName": "Dialog360 Security Deposit"
            // }
            // openPaymentGatewayOptDialogBox(razorpayPaymentObj, stripePaymentObj)
            localStorage.setItem('planPageTab', 'upgrage_plan')
            openURL('/dashboard/payment/plan-subscription')

            return
        }
        if (nextKey === "i_am_new_user") {
            setChannelType('waba')
            try {
                const response = await fetchUserLocation()
                if (response.status === 200) {
                    if (response.data.country !== 'India') {
                        updateOnboardingStatus('waba')
                    }
                    console.log(response.data.country)
                }

            } catch (error) { }
        }
        if (nextKey === "dialog360_purchase") { setChannelType('dialog360') }
        if (nextKey === "dialog360_channel_add") {
            setChannelType('dialog360')
            localStorage.setItem('settingIndex', 1)
            openURL('/dashboard/settings/channels')
            return
        }
        try {
            let data = { 'onboardingStatus': nextKey }
            const response = await setOnBoardingStatusInBusiness(data)
            if (response.status === 200) {
                handleUiOpt(nextKey)
                if (nextKey !== "completed") localStorage.setItem("onboardingStatus", nextKey)
                if (nextKey === "completed") {
                    createChannels()
                    localStorage.removeItem("addons_p")
                }
                if (nextKey === "waiting_for_agent_response") {
                    localStorage.setItem("onboardingStatus", "waiting_for_agent_response")
                    onCloseHere(true)
                }
                if (nextKey === "pending") onCloseHere(true)
            }
        } catch (error) { }
    }

    const [openPaymentGatewayOptDialog, setOpenPaymentGatewayOptDialog] = useState({ open: false, onClose: null })
    const openPaymentGatewayOptDialogBox = (razorpayObj, stripeObj) => {
        setOpenPaymentGatewayOptDialog({
            open: true, razorpayObj: razorpayObj, stripeObj: stripeObj, onClose: (confirm) => { setOpenPaymentGatewayOptDialog(false) }
        })
    }

    useEffect(() => { handleUiOpt(localStorage.getItem("onboardingStatus")) }, [uiOpt])


    const [openDemoStartDialog, setOpenDemoStartDialog] = useState({ open: false, onClose: null })
    const openDemoStartDialogBox = () => setOpenDemoStartDialog({ open: true, onClose: () => { setOpenDemoStartDialog(false) } })
    return (
        <Container className="" fluid>
            <PaymentGatewayChooseDialog open={openPaymentGatewayOptDialog.open} razorpayObj={openPaymentGatewayOptDialog.razorpayObj} stripeObj={openPaymentGatewayOptDialog.stripeObj} onClose={openPaymentGatewayOptDialog.onClose} />
            <DemoStartDialog open={openDemoStartDialog.open} onClose={openDemoStartDialog.onClose} />
            <section className='onboarding'>
                {
                    (() => {
                        if (uiOpt === "undefined" || uiOpt === "pending") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Hey {localStorage.getItem("userName")?.replace(/ .*/, '')}! 👋</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>Welcome to WhatsTool Business</p>
                                        <p className="text-center" style={{ color: WtColor.TextLightGrey }}>We are super excited to onboard you 🤗</p>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>Let's Grow Your Business using<br /> WhatsApp Business API 🚀</p>
                                            <p className="text-center mt-5">
                                                <button style={ValidButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => updateOnboardingStatus('setup_now')}>Setup Now </button>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" onClick={() => updateOnboardingStatus('pending')}>I'll do it Later</button>
                                        </div>
                                        <div>
                                            <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => updateOnboardingStatus('check_demo')}>Check Demo</button>
                                            <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => updateOnboardingStatus('need_help_info')}>Need Help 💡</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "need_help_info") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Need Help?</h3>
                                        <p className="text-center mt-5">
                                            <Link to={{ pathname: "https://youtu.be/CEt_KMMv3V8" }} target="_blank"><button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }}>WhatsApp API Info</button></Link>
                                            <Link to={{ pathname: 'https://business.whatstool.in/pricing' }} target="_blank"><button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }}>Pricing Info</button></Link>
                                            <Link to={{ pathname: "https://youtu.be/XyxZAFF8sEc" }} target="_blank"><button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }}>WT Business Dashboard Info</button></Link>
                                            <button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }} onClick={() => updateOnboardingStatus('need_help_other_info')}>Other Help?</button>
                                        </p>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" onClick={() => updateOnboardingStatus('undefined')}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "need_help_other_info") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Other Help?</h3>
                                        <p className="text-center mt-5">
                                            <Link to={{ pathname: "https://wa.me/917353902312" }} target="_blank"><button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }}>Chat with Our Customer Support</button></Link>
                                            <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLScKQj5z44Nf8XvAtdn7tFPBN-HzKBR9-HqmDVoWOtLgUY_Zng/viewform?usp=sf_link" }} target="_blank"><button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }}>Request a callback</button></Link>
                                            <button className="w-75 btn btn-sm rounded-3 mb-3" style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor }}>Join a LIVE Demo</button>
                                        </p>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" onClick={() => updateOnboardingStatus('need_help_info')}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "setup_now" || uiOpt === "waiting_for_agent_response") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Nice</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>Let’s Setup Now</p>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>How would you like to do it?</p>
                                            <p className="text-center mt-5">
                                                <button style={ValidButtonStyle} className="btn btn-md rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => updateOnboardingStatus('setup_yourself')}>
                                                    {(localStorage.getItem("addons_p") && localStorage.getItem("addons_p") === "success") ? "Start Setup Now" : "Setup Myself"}
                                                </button><br /><br />
                                                <button style={ValidButtonStyle} className="btn btn-md rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => updateOnboardingStatus('free_setup_service')}>
                                                    Free Setup Service
                                                </button><br /><br />
                                                {
                                                    (localStorage.getItem("addons_p") && localStorage.getItem("addons_p") === "success") ? "" :
                                                        <button style={ValidButtonStyle} className="btn btn-md rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" disabled={(localStorage.getItem("addons_p") && localStorage.getItem("addons_p") === "success") ? true : false} onClick={() => updateOnboardingStatus('need_help')}>Need Setup Service </button>
                                                }
                                            </p>
                                            {/* <p className='text-center'>or</p>
                                            <div className="pb-3 rounded-3" style={{ background: WtColor.BaseColor }}>
                                                <p className="text-center text-white pt-4">Free Setup Service</p>
                                                <p className="text-center mt-4">
                                                    <button onClick={() => openURL('/dashboard/payment/plan-subscription')} className="btn btn-sm bg-white fw-bold ps-4 pe-4 pt-2 pb-2" style={{ color: WtColor.BaseColor }}>Buy Plan</button>
                                                </p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('undefined')}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === 'free_setup_service') {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Free Setup Service</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>&nbsp;</p>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>We provide free setup service for our premium users. Please upgrade to avail our free setup service.</p>
                                            <p className="text-center mt-5">
                                                <button style={ValidButtonStyle} className="btn btn-md rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => openURL('/dashboard/payment/plan-subscription')}>
                                                    Buy Plan
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('setup_now')}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "setup_yourself") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Wow</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>That's great! Setup Now</p>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-4 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>To setup your WhatsApp Business Account we need few api details</p>
                                            <div className="mt-5" style={{ display: "flex" }}>
                                                <button style={ValidButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => updateOnboardingStatus('yes_i_have')}>Yes, I've WhatsApp API</button>
                                                <button style={ValidButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => updateOnboardingStatus('i_am_new_user')}>No, I am New User</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('setup_now')}>Back</button>
                                        </div>
                                        {
                                            (localStorage.getItem("addons_p") && localStorage.getItem("addons_p") === "success") ? "" :
                                                <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" disabled={(localStorage.getItem("addons_p") && localStorage.getItem("addons_p") === "success") ? true : false} onClick={() => updateOnboardingStatus('need_help')}>Need Setup Service</button>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "yes_i_have") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Great!</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>Let's setup your details now.</p>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>Which WhatsApp api do you have?</p>
                                            <RadioGroup row value={channelType} onChange={handleChannelType}>
                                                <FormControlLabel value="dialog360" control={<Radio />} label="360Dialog" />
                                                <FormControlLabel value="waba" control={<Radio />} label="WhatsApp Cloud API" />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('setup_yourself')}>Back</button>
                                        </div>
                                        <div>
                                            <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => updateOnboardingStatus(channelType)}>Next </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "dialog360" || uiOpt === "waba") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Amazing, Let's get Started</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>Congrats on your first step for WhatsApp Api</p>
                                        <p className="text-center ps-5 pe-5" style={{ color: WtColor.TextMidGrey }}>You are Just 3 steps away from setting your account. Once you do that you will be having all the power of WhatsApp</p>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>Enter these details</p>
                                            <div>
                                                <TextField value={title} onChange={(e) => handleTitle(e.target.value)} label="Business Name" variant="outlined" className="mb-4" size="small" placeholder="Enter Business Name" defaultValue={localStorage.getItem("bName")} fullWidth required />
                                                <TextField value={whatsapp} onChange={(e) => handleWhatsApp(e.target.value)} type={"tel"} label="WhatsApp API Number" variant="outlined" className="mb-4" size="small" placeholder="Enter WhatsApp Number" helperText="Enter WhatsApp API Number along with country code. Example: 917353902312" fullWidth required />
                                                <p style={{ marginTop: "-20px", fontSize: "12px" }}>
                                                    Keep in mind these info for WhatsApp API Number. &nbsp;
                                                    <Tooltip title={<div><ol><li>Add a phone number that is not associated with any WhatsApp account/ Or a New Number(Verification can take few minutes).</li><li>You can also delete the existing WhatsApp account and use that number (this change can't be reverted)</li></ol></div>} placement="bottom">
                                                        <InfoOutlined style={{ color: WtColor.TextMidGrey, fontSize: "16px" }} />
                                                    </Tooltip>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('setup_yourself')}>Back</button>
                                        </div>
                                        <button style={FooterDownButtonStyle} disabled={whatsapp?.trim() !== "" && title?.trim() !== "" ? false : true} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1"
                                            onClick={
                                                () => {
                                                    // if (uiOpt === "dialog360_channel_add") updateOnboardingStatus('get_whatsapp_api_details')
                                                    if (uiOpt === "dialog360") updateOnboardingStatus('get_whatsapp_api_details')
                                                    if (uiOpt === "waba") updateOnboardingStatus('get_whatsapp_api_details')
                                                }
                                            }>
                                            Save & Continue </button>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "i_am_new_user") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Monthly Messages Usage</h3>
                                        <div className="ps-5 pe-5" onChange={(e) => handleMonthlyUsage(e.target.value)}>
                                            <label htmlFor='usage' className="mt-5" style={{ color: WtColor.TextMidGrey }}>How many Message do you want to send?</label>
                                            <select id='usage' value={monthlyUsage} className="form-control mb-5 mt-2">
                                                <option value=''>Select messages usages</option>
                                                <option value='below_1000'>Below 1000 Message/Month</option>
                                                <option value='above_1000'>Above 1000 Message/Month</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('setup_yourself')}>Back</button>
                                        </div>
                                        <button style={ValidButtonStyle} disabled={monthlyUsage === '' ? true : false} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg"
                                            onClick={
                                                () => {
                                                    if (monthlyUsage === 'below_1000') updateOnboardingStatus('waba')
                                                    if (monthlyUsage === 'above_1000') updateOnboardingStatus('dialog360_purchase')
                                                }
                                            }
                                        >Next</button>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "dialog360_purchase") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Let's Get Started</h3>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className='text-center' style={{ color: WtColor.TextMidGrey }}>To send more than 1000 message<br />you need to upgrade.</p>
                                            <p className="text-center">
                                                <button
                                                    style={ValidButtonStyle} className="btn btn-md rounded-3 ps-3 pe-3 pb-1 text-white theme_bg"
                                                    onClick={() => updateOnboardingStatus('buy_now_360_sd')}
                                                >
                                                    Upgrade Plan
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('i_am_new_user')}>Back</button>
                                        </div>
                                        {
                                            localStorage.getItem('addons_p') === 'dialog360_buy_success' ?
                                                <button style={ValidButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg"
                                                    onClick={
                                                        () => updateOnboardingStatus('dialog360_channel_add')
                                                    }
                                                >Next</button> : ''

                                        }
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "get_whatsapp_api_details") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Get WhatsApp Api Details</h3>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>You need to setup your facebook business and connect your phone number with WhatsApp</p>
                                            <Container fluid>
                                                <Row>
                                                    <Col><Link to={{ pathname: "https://whatstool.notion.site/WT-Business-Setup-Process-f5f4e7e7e082448ba49eaf62f41b048c" }} target="_blank"><p style={{ color: WtColor.ProcessingBlue }} className="fw-bold text-center hand" ><MenuBookOutlined /> Learn More</p></Link></Col>
                                                    <Col><Link to={{ pathname: "https://youtu.be/CEt_KMMv3V8" }} target="_blank"><p style={{ color: WtColor.ProcessingBlue }} className="fw-bold text-center hand" ><VideocamOutlined /> Watch Video</p></Link></Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('dialog360')}>Back</button>
                                        </div>
                                        <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => updateOnboardingStatus('skip_to_watch_info')}>Skip & Continue</button>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "skip_to_watch_info") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Configure WhatsApp Api</h3>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>Enter these details</p>
                                            <div>
                                                {
                                                    (channelType === "dialog360") ?
                                                        <>
                                                            <TextField value={apiKey} onChange={(e) => handleApiKey(e.target.value)} label="API Key" variant="outlined" className="mb-4" size="small" placeholder="Enter API Key" fullWidth required />
                                                            <TextField onChange={(e) => handleDomain(e.target.value)} label="Domain" variant="outlined" className="mb-4" size="small" placeholder="Enter Domain" defaultValue={"https://waba.360dialog.io"} fullWidth required />
                                                        </>
                                                        :
                                                        <>
                                                            <TextField value={wabaId} onChange={(e) => handleWabaId(e.target.value)} label="WhatsApp Business Account Id" variant="outlined" className="mb-4" size="small" placeholder="Enter WABA Id" fullWidth required />
                                                            <TextField value={wabaToken} onChange={(e) => handleWabaToken(e.target.value)} label="Permanent Access Token" variant="outlined" className="mb-4" size="small" placeholder="Enter Permanent Access Token" fullWidth multiline required />
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('get_whatsapp_api_details')}>Back</button>
                                        </div>
                                        {
                                            channelType === "waba" ?
                                                whatsapp?.trim() !== "" && title?.trim() !== "" && channelType === "waba" && wabaId?.trim() !== "" && wabaToken?.trim() !== "" ?
                                                    <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => updateOnboardingStatus('completed')}>Complete </button>
                                                    :
                                                    <button style={FooterDownButtonStyle} disabled className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1">Complete</button>

                                                :

                                                whatsapp?.trim() !== "" && title?.trim() !== "" && channelType === "dialog360" && apiKey?.trim() !== "" && domain?.trim() !== "" ?
                                                    <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1" onClick={() => updateOnboardingStatus('completed')}>Complete </button>
                                                    :
                                                    <button style={FooterDownButtonStyle} disabled className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1">Complete</button>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "completed") {
                            return (
                                <div>
                                    <p className="text-center mt-3"><Player autoplay loop src="https://assets1.lottiefiles.com/datafiles/MmpCm2kvWjgU0Ai/data.json" className="items-lottie"></Player></p>
                                    <p className="text-center"><button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" onClick={() => onCloseHere(false)}>Finish</button></p>
                                </div>
                            )
                        }
                        if (uiOpt === "need_help") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>WTB Setup Service</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>We will help you setup</p>
                                    </div>
                                    <div className='app_list' style={{ height: "400px" }}>
                                        <div style={{ border: "1px solid " + WtColor.BaseColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}><img src='https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b1.svg' alt={WtTheme.SiteName} /> &nbsp;What you will get in this service?</p><br />
                                            <p className="text-center">
                                                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b2.svg" alt={WtTheme.SiteName} />
                                            </p>
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}>60 min call with expert</p>
                                            <p className="text-center"><Add style={{ color: WtColor.TextDarkColor }} /></p>
                                            <p className="text-center">
                                                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b3.svg" alt={WtTheme.SiteName} />
                                            </p>
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}>WhatsApp Cloud API Setup</p>
                                            <p className="text-center"><Add style={{ color: WtColor.TextDarkColor }} /></p>
                                            <p className="text-center">
                                                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b4.svg" alt={WtTheme.SiteName} />
                                            </p>
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}>WT Business WhatsApp Setup</p>
                                            <p className="text-center"><Add style={{ color: WtColor.TextDarkColor }} /></p>
                                            <p className="text-center">
                                                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b5.svg" alt={WtTheme.SiteName} />
                                            </p>
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}>Onboarding in WTB and explanation</p>
                                            <p className="text-center"><Add style={{ color: WtColor.TextDarkColor }} /></p>
                                            <p className="text-center">
                                                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b6.svg" alt={WtTheme.SiteName} />
                                            </p>
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}>WhatsApp API Knowledge</p>
                                            <p className="text-center"><Add style={{ color: WtColor.TextDarkColor }} /></p>
                                            <p className="text-center">
                                                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/dc4e4719318459e60a8f227e94205b33f2feefd1/prod/home/wtx_website/Icons/b7.svg" alt={WtTheme.SiteName} />
                                            </p>
                                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}>WhatsApp Group for support and clarification</p>
                                            <div className='rounded-3 mt-5' style={{ marginLeft: "-50px", marginRight: "-50px", marginBottom: "-66px", background: WtColor.BaseColor }}>
                                                <p className='text-center text-white pt-5' style={{ fontSize: "24px" }}>WT Business Setup service price is just</p>
                                                <p className='text-center text-white pb-5'><span>₹</span><span style={{ fontSize: "30px" }}>&nbsp;{wtbAddOnPrice}</span></p>
                                            </div>
                                        </div>
                                        <AppOnboardingUIFaq />
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('setup_now')}>Back</button>
                                        </div>
                                        <div>
                                            <button style={ValidButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg" onClick={() => updateOnboardingStatus('buy_now')}>Buy Now </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (uiOpt === "buy_now_success") {
                            return (
                                <div>
                                    <div>
                                        <h3 className="text-center mt-5" style={{ color: WtColor.TextDarkColor }}>Congratulations! 🥳</h3>
                                        <p className="text-center" style={{ color: WtColor.TextMidGrey }}>You are near to setup service</p>
                                        <p className="text-center pe-5 ps-5" style={{ color: WtColor.TextMidGrey }}>Setup Process Started! You will receive a quick guide call soon.</p>
                                    </div>
                                    <div className='app_list' style={{ height: "400px" }}>
                                        <div style={{ border: "1px solid " + WtColor.LineColor }} className="rounded-3 m-5 ps-5 pe-5 pt-5 pb-5">
                                            <p className="" style={{ color: WtColor.TextMidGrey, fontSize: "20px" }}>
                                                <ol>
                                                    <li>Book the meeting of setup call as per your time.</li>
                                                    <li>If the time doest match please contact our customer support.</li>
                                                </ol>
                                            </p>
                                        </div>
                                        <AppOnboardingUIFaq />
                                    </div>
                                    <div style={FooterStyle}>
                                        <div style={{ flex: 1 }}>
                                            <button style={CancelButtonStyle} className="btn btn-md ms-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey" disabled={uiOpt === "undefined" ? true : false} onClick={() => updateOnboardingStatus('waiting_for_agent_response')}>Close</button>
                                        </div>
                                        <div>
                                            <Link to={{ pathname: "https://calendly.com/wtb-setup/60min" }} target="_blank">
                                                <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1">Book Setup Call</button>
                                            </Link>
                                            <Link to={{ pathname: "https://api.whatsapp.com/send/?phone=917353902312&text=Hi, I need help in WTB Setup Service" }} target="_blank">
                                                <button style={FooterDownButtonStyle} className="btn btn-md me-3 rounded-3 ps-3 pe-3 pb-1">Contact Now</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })()
                }
            </section>
        </Container >
    )
}

export const AppOnboardingUIFaq = () => {
    return (
        <>
            <p className='text-center fw-bold hand' style={{ color: WtColor.TextDarkColor }}>FAQ</p>
            <div className="mb-5">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>How will I proceed to setup service after payment?</Accordion.Header>
                        <Accordion.Body>
                            You will get email and WhatsApp message to setup a call in the Calendar
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Will I get my refund if agent fail to call me</Accordion.Header>
                        <Accordion.Body>
                            yes
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What is the process of Setup service?</Accordion.Header>
                        <Accordion.Body>
                            You will get email and WhatsApp message to setup a call in the Calendar and our support team will do it for you
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Who will do the setup and demo ?</Accordion.Header>
                        <Accordion.Body>
                            Setup will be done by WTB trained experts.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}

