/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import chatContext from '../../context/chat/chatContext'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const FilterSidebar = () => {
    const inboxFilter = useContext(chatContext)
    const updateInboxFilter = useContext(chatContext)
    const getChannels = useContext(chatContext)
    const channelLs = useContext(chatContext)
    const channelLsCount = useContext(chatContext)
    const getLabelLs = useContext(globalContext)
    const lableLs = useContext(globalContext)

    const notificationBarStatus = useContext(globalContext)

    const [openInbox, setOpenInbox] = useState(true)
    const handleOpenInbox = () => { setOpenInbox(!openInbox) }

    const [openChannels, setOpenChannels] = useState(localStorage.getItem("ch_filterInbox_section") === "channel" ? true : false)
    const handleOpenChannels = () => { setOpenChannels(!openChannels) }
    useEffect(() => { getChannels.getChannels() }, [])

    // Labels Work
    const [openLabels, setOpenLabels] = useState(localStorage.getItem("ch_filterInbox_section") === "label" ? true : false)
    const handleOpenLabels = () => { setOpenLabels(!openLabels) }
    useEffect(() => { getLabelLs.getLabelLs() }, [])
    const [labelSearchLs, setLabelSearchLs] = useState(lableLs.lableLs)
    const searchItems = (searchValue) => {
        const filteredData = lableLs.lableLs.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
        })
        setLabelSearchLs(filteredData)
    }
    useEffect(() => { setLabelSearchLs(lableLs.lableLs) }, [lableLs.lableLs])
    // End of Labels Work
    return (
        <div className='filter_sidebar list_scroll' style={{ flex: "0.15", height: notificationBarStatus.notificationBarStatus ? '90vh' : "100vh", borderRight: "1px solid " + WtColor.LineColor }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
                <ListItemButton onClick={handleOpenInbox}>
                    <ListItemText primary={<div className='fw-bold' style={{ color: WtColor.TextDarkColor }}>Inbox</div>} />
                    {openInbox ? <ExpandLess style={{ color: WtColor.TextDarkColor }} /> : <ExpandMore style={{ color: WtColor.TextDarkColor }} />}
                </ListItemButton>
                <Collapse in={openInbox} timeout="auto" unmountOnExit>
                    <List component="div" style={{ paddingLeft: "10px" }} disablePadding>
                        <ListItemButton selected={inboxFilter.inboxFilter.index === 0} onClick={() => updateInboxFilter.updateInboxFilter("inbox", 0, "all", "All Chats")}>
                            <ListItemText primary="All Chats" style={{ color: WtColor.TextMidGrey }} />
                        </ListItemButton>
                        <ListItemButton selected={inboxFilter.inboxFilter.index === 1} onClick={() => updateInboxFilter.updateInboxFilter("inbox", 1, "mine", "Mine Chats")}>
                            <ListItemText primary="Mine" style={{ color: WtColor.TextMidGrey }} />
                        </ListItemButton>
                        <ListItemButton selected={inboxFilter.inboxFilter.index === 2} onClick={() => updateInboxFilter.updateInboxFilter("inbox", 2, "unassigned", "Unassigned Chats")}>
                            <ListItemText primary="Unassigned" style={{ color: WtColor.TextMidGrey }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
                <ListItemButton onClick={handleOpenChannels}>
                    <ListItemText primary={<div className='fw-bold' style={{ color: WtColor.TextDarkColor }}>Channels</div>} />
                    {openChannels ? <ExpandLess style={{ color: WtColor.TextDarkColor }} /> : <ExpandMore style={{ color: WtColor.TextDarkColor }} />}
                </ListItemButton>
                <Collapse in={openChannels} timeout="auto" unmountOnExit>
                    <List component="div" style={{ paddingLeft: "10px" }} disablePadding>
                        {
                            channelLs.channelLs?.map((v, i) => {
                                return (
                                    <ListItemButton selected={inboxFilter.inboxFilter.index === i + 4} onClick={() => updateInboxFilter.updateInboxFilter("channel", i + 4, v.whatsapp, v.title + " - Channel Chats")} key={i}>
                                        <ListItemText primary={v.title} style={{ color: WtColor.TextMidGrey }} />
                                    </ListItemButton>
                                )
                            })
                        }
                    </List>
                </Collapse>
            </List>

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
                <ListItemButton onClick={handleOpenLabels}>
                    <ListItemText primary={<div className='fw-bold' style={{ color: WtColor.TextDarkColor }}>Labels</div>} />
                    {openLabels ? <ExpandLess style={{ color: WtColor.TextDarkColor }} /> : <ExpandMore style={{ color: WtColor.TextDarkColor }} />}
                </ListItemButton>
                <Collapse in={openLabels} timeout="auto" unmountOnExit>
                    <List component="div" style={{ paddingLeft: "10px" }} disablePadding>
                        <input onChange={(e) => searchItems(e.target.value)} type="search" className="mt-2 mb-2 ms-3" placeholder='Search Labels...' style={{ width: "85%", borderRadius: "30px", border: "1px solid " + WtColor.LineColor, outline: "none", paddingLeft: "10px", paddingRight: "10px", paddingTop: "6px", paddingBottom: "6px" }} />
                        {
                            labelSearchLs?.map((v, i) => {
                                return (
                                    <ListItemButton selected={inboxFilter.inboxFilter.index === i + 4 + channelLsCount.channelLsCount} onClick={() => updateInboxFilter.updateInboxFilter("label", i + 4 + channelLsCount.channelLsCount, v._id, v.label_name + " - Label Chats")} key={i}>
                                        <ListItemText primary={v.label_name} style={{ color: WtColor.TextMidGrey }} />
                                    </ListItemButton>
                                )
                            })
                        }
                    </List>
                </Collapse>
            </List>
        </div >
    )
}