class TestingLocal {
    constructor() {
        this.env_type = 'testing'
        this.baseApiCallURL = 'https://whatstool-new-testing.de.r.appspot.com'
        this.baseUI_URL = 'http://localhost:3000'
        this.authKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBJZCI6IjYyODIyYzdhZTM4MjJhOTVjZTJmNGZlOSIsImJ1c2luZXNzSWQiOiI2MjdkMGU5MWYxMzBjZjFhNjNlNTY2YTUiLCJpYXQiOjE2NTU3MTAxOTUsImV4cCI6MTY1ODMwMjE5NX0.kamufAHDCgeD8FnPamZ5R-y1Q3vF1vFhg4iq0XZ3KaM'
        this.stripePublishableKey = 'pk_test_51LKfTdSJsK0kbpwCxISBjCwIXRNPYh77ZgLzc5rqIuDi69PutsO8yrhLiRcSaPLbUytAFxzfcDnQPczP4JueOPRz00tGldZ8Rf'
        this.razorpayKey = 'rzp_test_URoTmjpRLBZMvU'
        this.razorpayPaymentVerify = '/payments/razorpay/verify'
        this.dialog360ApiKey = 'NeX41TBy7TKwgyHyDAK6vUb4AK'
        this.firebaseConfig = {
            apiKey: "AIzaSyCmdS9-mTVq9CzujF_VbWUpFgn1hOBuKIc",
            authDomain: "whatstool-new-testing.firebaseapp.com",
            databaseURL: "https://whatstool-new-testing-default-rtdb.asia-southeast1.firebasedatabase.app",
            projectId: "whatstool-new-testing",
            storageBucket: "whatstool-new-testing.appspot.com",
            messagingSenderId: "438072315153",
            appId: "1:438072315153:web:6705074d5aad9f422dd0b0",
            measurementId: "G-KEWHW8JJ8M"
        }
        this.mqttHostname = 'mustang.rmq.cloudamqp.com'
        this.mqttPass = '1XL9V79Mm4sIlxFNGT6IBJOR5TL2qtAh'
        this.mqttUsername = 'ugolrbqd:ugolrbqd'
        this.mqttPort = '1883'
        this.vapid = 'BJwDdOBty8BirU40YNBeSBF8MjCmBTuFmTV4lQ1Jf9bMJqYpoCZQXcsvU-LJmqnITopan2XDSeJxyf02bSQSJUE'
        this.analyticsTrackID = 'UA-217481344-1'
    }
}
class TestingLive {
    constructor() {
        this.env_type = 'testing_live'
        this.baseApiCallURL = 'https://whatstool-new-testing.de.r.appspot.com'
        this.baseUI_URL = 'https://teamtesting.whatstool.in'
        this.authKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBJZCI6IjYyODIyYzdhZTM4MjJhOTVjZTJmNGZlOSIsImJ1c2luZXNzSWQiOiI2MjdkMGU5MWYxMzBjZjFhNjNlNTY2YTUiLCJpYXQiOjE2NTU3MTAxOTUsImV4cCI6MTY1ODMwMjE5NX0.kamufAHDCgeD8FnPamZ5R-y1Q3vF1vFhg4iq0XZ3KaM'
        this.stripePublishableKey = 'pk_test_51LKfTdSJsK0kbpwCxISBjCwIXRNPYh77ZgLzc5rqIuDi69PutsO8yrhLiRcSaPLbUytAFxzfcDnQPczP4JueOPRz00tGldZ8Rf'
        this.razorpayKey = 'rzp_test_URoTmjpRLBZMvU'
        this.razorpayPaymentVerify = '/payments/razorpay/verify'
        this.dialog360ApiKey = 'NeX41TBy7TKwgyHyDAK6vUb4AK'
        this.firebaseConfig = {
            apiKey: "AIzaSyCmdS9-mTVq9CzujF_VbWUpFgn1hOBuKIc",
            authDomain: "whatstool-new-testing.firebaseapp.com",
            databaseURL: "https://whatstool-new-testing-default-rtdb.asia-southeast1.firebasedatabase.app",
            projectId: "whatstool-new-testing",
            storageBucket: "whatstool-new-testing.appspot.com",
            messagingSenderId: "438072315153",
            appId: "1:438072315153:web:6705074d5aad9f422dd0b0",
            measurementId: "G-KEWHW8JJ8M"
        }
        this.mqttHostname = 'mustang.rmq.cloudamqp.com'
        this.mqttPass = '1XL9V79Mm4sIlxFNGT6IBJOR5TL2qtAh'
        this.mqttUsername = 'ugolrbqd:ugolrbqd'
        this.mqttPort = '1883'
        this.vapid = 'BJwDdOBty8BirU40YNBeSBF8MjCmBTuFmTV4lQ1Jf9bMJqYpoCZQXcsvU-LJmqnITopan2XDSeJxyf02bSQSJUE'
        this.analyticsTrackID = 'UA-217481344-1'
    }

}

class Production {
    constructor() {
        this.env_type = 'production'
        this.baseApiCallURL = 'https://allin1-tools-for-whatsapp.uc.r.appspot.com'
        this.baseUI_URL = 'https://business.whatstool.in'
        this.authKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBJZCI6IjYyODIyYzdhZTM4MjJhOTVjZTJmNGZlOSIsImJ1c2luZXNzSWQiOiI2MjdkMGU5MWYxMzBjZjFhNjNlNTY2YTUiLCJpYXQiOjE2NTU3MTAxOTUsImV4cCI6MTY1ODMwMjE5NX0.kamufAHDCgeD8FnPamZ5R-y1Q3vF1vFhg4iq0XZ3KaM'
        this.stripePublishableKey = 'pk_live_51KxPA6SEwYnKj5RhnjwQJehSCd2KO8FcU6O2pFKe5ZIcPTspcn5eTPHMOvnb0nl7rQHYFlbRicW5o3WlebZZvU2t00HOE2tnsX'
        this.razorpayKey = 'rzp_live_l5A6hG3NQCLBE9'
        this.razorpayPaymentVerify = '/payments/razorpay/verify'
        this.dialog360ApiKey = 'NeX41TBy7TKwgyHyDAK6vUb4AK'
        this.firebaseConfig = {
            apiKey: "AIzaSyB4DUUIA03VZVrAj_FUKxn1P8vxLWgYOoM",
            authDomain: "allin1-tools-for-whatsapp.firebaseapp.com",
            databaseURL: "https://allin1-tools-for-whatsapp.firebaseio.com",
            projectId: "allin1-tools-for-whatsapp",
            storageBucket: "allin1-tools-for-whatsapp.appspot.com",
            messagingSenderId: "913786346835",
            appId: "1:913786346835:web:3c82d826094c71b4ae95a6",
            measurementId: "G-DZEVVENW8N"
        }
        this.mqttHostname = 'puffin.rmq2.cloudamqp.com'
        this.mqttPass = 'q3H6br7eMlXf5pIoguCmMWffltc56SyL'
        this.mqttUsername = 'tjwlkdly:tjwlkdly'
        this.vapid = 'BD0oFOkjn1PbkTSwXBHY5yJ4sHdBJdxLKmUNOeS8JK_psTyi6NN59HQrtCezx-yoDAC0DFXw-CzYQ5MossMna3A'
        this.analyticsTrackID = 'UA-250697155-1'
    }
}

// export const Env = new TestingLocal()
export const Env = new TestingLive()
// export const Env = new Production()