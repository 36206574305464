/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react'
import { chatUnreadAPI, fetchChannels, fetchChats, fetchChatsV2, fetchMessages } from '../../controller/APIs'
import { listenFirebaseRealtimeDB } from '../../controller/fireSocket'
import ChatContext from './chatContext'

const ChatState = (props) => {
    // Chat Loader Management
    const [chatingStatus, setChatingStatus] = useState(false)
    const [loader, setLoader] = useState(true)
    // End of Chat Loader Management


    // Filter State Management
    const inboxFilterInitialVal = {
        "section": localStorage.getItem("ch_filterInbox_section") && localStorage.getItem("ch_filterInbox_section") !== "" ? parseInt(localStorage.getItem("ch_filterInbox_section")) : "inbox",
        "index": localStorage.getItem("ch_filterInbox_index") && localStorage.getItem("ch_filterInbox_index") !== "" ? parseInt(localStorage.getItem("ch_filterInbox_index")) : 0,
        "key": localStorage.getItem("ch_filterInbox_key") && localStorage.getItem("ch_filterInbox_key") !== "" ? localStorage.getItem("ch_filterInbox_key") : "all",
        "title": localStorage.getItem("ch_filterInbox_title") && localStorage.getItem("ch_filterInbox_title") !== "" ? localStorage.getItem("ch_filterInbox_title") : "All Chats"
    }
    const [inboxFilter, setInboxFilter] = useState(inboxFilterInitialVal)
    const [chatLsSeed, setChatLsSeed] = useState(1)
    const updateInboxFilter = useCallback((section, index, key, title) => {
        setChatListData([])
        localStorage.setItem("ch_filterInbox_section", section)
        localStorage.setItem("ch_filterInbox_index", index)
        localStorage.setItem("ch_filterInbox_key", key)
        localStorage.setItem("ch_filterInbox_title", title)
        setChatListSizeStatus(true)
        setInboxFilter(
            {
                "section": section,
                "index": index,
                "key": key,
                "title": title
            }
        )
        setChatLsSeed(Math.random())
    }, [])


    const [channelLs, setChannelLs] = useState([])
    const [channelLsCount, setChannelLsCount] = useState([])
    const getChannels = async () => {
        try {
            const response = await fetchChannels()
            if (response.status === 200) {
                setChannelLs(response.data?.data)
                setChannelLsCount(response.data?.data?.length)
            }
        } catch (error) { }
    }
    // End of Filter State Management


    // Chat Search Management
    const [searchValue, setSearchValue] = useState('')
    const handleSearchValue = (v) => setSearchValue(v)
    // End of Chat Search Management


    // Chats State Management
    const [busyC, setBusyC] = useState(false)
    const [chatListSizeStatus, setChatListSizeStatus] = useState(true)
    const [chatListData, setChatListData] = useState([])
    const getChats = async (filter, filterValue, skip, limit, reason) => {
        if (localStorage.getItem('uHash') && localStorage.getItem('uHash') !== "") localStorage.removeItem('uHash')
        if (localStorage.getItem('isDemo') === 'true') setChatListData([])
        let allChats = []
        try {
            if (chatListSizeStatus) {
                let data = { "filter": filter, "filterValue": filterValue, "skip": skip, "limit": limit }
                const response = await fetchChatsV2(data)
                if (response.status === 200) {
                    if (reason === "first_time") {
                        allChats = response.data.data
                        setChatListData(response.data.data)
                        setBusyC(false)
                    }
                    if (reason === "fetch_more") {
                        if (response.data.data?.length === 0) setChatListSizeStatus(false)
                        allChats = [...allChats, ...response.data.data]
                        setChatListData([...chatListData, ...response.data.data])
                        setBusyC(false)
                    }
                }
            }
            const _handleNewChat = (newChat) => {
                if (localStorage.getItem('isDemo') === 'true' && newChat?.extUserId !== localStorage.getItem('demoMobileNo')) return
                let chatIndex = allChats.findIndex(e => e.hash === newChat?.hash)
                if (chatIndex === -1) {
                    let nChat = [newChat]
                    allChats = [...chatListData, ...allChats, ...nChat]
                    setChatListData([...allChats])
                } else {
                    allChats[chatIndex] = newChat
                    setChatListData([...allChats])
                }
            }
            listenFirebaseRealtimeDB(localStorage.getItem("bId"), (listerner) => {
                if (listerner && listerner.messageUpdate && listerner.messageUpdate.chat) {
                    _handleNewChat(listerner.messageUpdate.chat)
                }
            })
        } catch (error) { }
    }
    const getChatsBySearch = async (searchVal) => {
        try {
            let data = { "filter": 'all', "searchText": searchVal, "skip": 0 }
            const response = await fetchChats(data)
            if (response.status === 200) {
                setChatListData(response.data.data)
            }
        } catch (error) { }
    }
    // End of Chats State Management



    // ChatInfo State Management
    const activeChatInfoInitialVal = {
        "name": undefined,
        "whatsapp": undefined,
        "channel": undefined,
        "channelName": undefined,
        "hash": undefined,
        "msgDate": undefined,
        "chatId": undefined,
        "unreadCount": undefined
    }
    const [msgSendAccess, setMsgSendAccess] = useState(undefined)
    const [activeChatInfo, setActiveChatInfo] = useState(activeChatInfoInitialVal)

    const updateActiveChatInfo = async (name, whatsapp, channel, channelName, hash, msgDate, chatId, unreadCount) => {
        setMessageLsData([])
        localStorage.setItem("uHash", hash)
        localStorage.setItem("activeChatWhatsApp", whatsapp)

        await getMessages(hash, 20, 0, "first_time")

        setActiveChatInfo(
            {
                "name": name,
                "whatsapp": whatsapp,
                "channel": channel,
                "channelName": channelName,
                "hash": hash,
                "msgDate": msgDate,
                "chatId": chatId,
                "unreadCount": unreadCount
            }
        )
        localStorage.setItem('notificationClicked', 'false')

        setMsgSendAccess(msgDate)
        if (unreadCount !== 0) await chatUnread(chatId)
    }
    // End of ChatInfo State Management



    // Chat UnRead State Management
    const chatUnread = async (id) => {
        try {
            let data = { 'chatId': id }
            await chatUnreadAPI(data)
        } catch (error) { }
    }
    // End of Chat UnRead State Management


    // Messages State Management
    const [busyM, setBusyM] = useState(false)
    const [messageLsData, setMessageLsData] = useState([])
    const [messageLsDataCount, setMessageLsDataCount] = useState(20)
    const getMessages = async (hash, limit, skip, reason) => {
        let allMessages = []
        try {
            let data = { 'hash': hash, 'limit': limit, 'skip': skip }
            const response = await fetchMessages(data)
            if (response.status === 200 && response.data?.data && response.data?.data?.count !== 0 && response.data?.data?.messages) {
                if (reason === "first_time") {
                    allMessages = response.data?.data?.messages
                    setMessageLsData(allMessages)
                    setBusyM(false)
                }
                if (reason === "fetch_more") {
                    allMessages = [...messageLsData, ...response.data?.data?.messages]
                    // let msg = allMessages.reverse()
                    setMessageLsData(allMessages)
                    setBusyM(false)
                }
                setMessageLsDataCount(response.data?.data?.count)
                setChatingStatus(true)
                setLoader(false)
            }
            const _handleNewMessage = (newMessage) => {
                if (localStorage.getItem("uHash") === newMessage?.hash) {
                    let messageIndex = allMessages.findIndex(e => e._id === newMessage?._id)
                    if (messageIndex === -1) {
                        allMessages.push(newMessage)
                    } else {
                        allMessages[messageIndex] = newMessage
                    }
                    setMessageLsData(allMessages)
                    if (newMessage?.fromId === localStorage.getItem("activeChatWhatsApp")) {
                        setMsgSendAccess(newMessage?.sentDate)
                    }
                }
            }
            listenFirebaseRealtimeDB(localStorage.getItem("bId"), (listerner) => {
                if (listerner && listerner.messageUpdate && listerner.messageUpdate.message) {
                    _handleNewMessage(listerner.messageUpdate.message)
                }
            })
        } catch (error) { }
    }
    // End of Messages State Management
    return (
        <ChatContext.Provider value={{
            chatingStatus, setChatingStatus, loader, setLoader,
            inboxFilter, updateInboxFilter, chatLsSeed,
            channelLs, channelLsCount, getChannels,
            searchValue, setSearchValue, handleSearchValue,
            busyC, setBusyC, chatListData, getChats, getChatsBySearch, chatListSizeStatus, setMessageLsData,
            activeChatInfo, setActiveChatInfo, updateActiveChatInfo, msgSendAccess,
            busyM, setBusyM, messageLsData, getMessages, messageLsDataCount
        }}>
            {props.children}
        </ChatContext.Provider>
    )
}
export default ChatState