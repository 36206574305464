/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useCallback } from 'react'
import './index.css'
import { ChatList } from './ChatList'
import { EmptyScreen } from './EmptyScreen'
import { MsgBody } from './MsgBody'
import { FilterSidebar } from './Sidebar'
import { UserInfo } from './UserInfo'
import ChatState from '../../context/chat/chatState'
import chatContext from '../../context/chat/chatContext'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const Chat = () => { return (<ChatState><ChatApp /></ChatState>) }

export const ChatApp = () => {
    const notificationBarStatus = useContext(globalContext)
    const chatingStatus = useContext(chatContext)
    const loader = useContext(chatContext)

    const [chatScrSize, setChatScrSize] = useState("0.40")
    const [userInfoScrSize, setUserInfoScrSize] = useState("0.20")
    const [userInfoScrVisibility, setUserInfoScrVisibility] = useState("block")
    const handleChatAndUserInfoScr = useCallback((chSrcSize, uiSrcSize, uiSrcVis) => {
        setChatScrSize(chSrcSize)
        setUserInfoScrSize(uiSrcSize)
        setUserInfoScrVisibility(uiSrcVis)
    }, [])



    const chatStyle = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: ' #fff',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh',
        width: '95vw',
        boxShadow: '-1px 4px 20px - 6px rgba(0, 0, 0, 0.75)'
    }
    return (
        <div style={chatStyle}>
            <FilterSidebar />
            <ChatList />
            {
                chatingStatus.chatingStatus &&
                <>
                    <div className='msg bg-light' style={{ flex: chatScrSize, display: loader.loader ? "none" : "flex", flexDirection: "column", borderRight: "1px solid " + WtColor.LineColor }}>
                        <MsgBody userInfoScrSize={userInfoScrSize} handleChatAndUserInfoScr={handleChatAndUserInfoScr} />
                    </div>
                    <div style={{ flex: userInfoScrSize, display: loader.loader ? "none" : userInfoScrVisibility }}>
                        <UserInfo handleChatAndUserInfoScr={handleChatAndUserInfoScr} />
                    </div>
                </>
            }
            {!chatingStatus.chatingStatus && <EmptyScreen />}
        </div>
    )
}