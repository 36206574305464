class WtThemes {
    constructor() {
        this.SiteName = 'WhatsTool Business'
        this.ShortSiteName = 'WT Business'
        this.SiteDescription = 'Transform your WhatsApp into a support and sales channel to help your customers discover and purchase your products'
        this.SiteEmail = 'support@whatstool.in'
        this.SiteContact = '+917353902312'
        this.SiteAddress = 'Prisha Cowork 1st Floor, 6th E Main Road,Behind Brigade Gardenia RBI Layout, Gaurav Nagar, JP Nagar 7th Phase, J. P. Nagar, Bengaluru, Karnataka 560078'

        this.RoundLogo = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtb_logo.png'
        this.TextLogo = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/logo_text.png'
        this.TextWhiteLogo = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/logo_text_white.png'


        // Images Work
        this.ImageBaseUrl = "https://whatstool.in/wtb_files/images"
        // End of Images Work

        this.localUI_URL = 'http://localhost:3000'
        this.localLiveUI_URL = 'https://teamtesting.whatstool.in'
        this.productionUI_URL = 'https://business.whatstool.in'


        // Default Screens
        this.chat_default_url = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/default_screens/chat_default.png"
        this.contact_default_url = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/default_screens/contacts_default.png"
        this.campaign_default_url = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/default_screens/campaign_default.png"
        this.chatbot_default_url = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/default_screens/chatbot_default.png"
        this.analytics_default_url = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/default_screens/analytics_default.png"
        this.payment_default_url = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/default_screens/payment_default.png"
        // End of Default Screens

        this.BaseColor = '#00A82D'
        this.BaseDarkColor = '#009C2A'
        this.BaseLightColor = '#92CC37'
        this.SelectionColor = '#EFFFED'
        this.LineColor = '#F0F2F8'
        this.TextColor = '#9EA4B5'
        this.TextDarkColor = '#3F4A5F'
        this.TextMidGrey = '#616874'
        this.TextLightGrey = '#9EA4B5'
        this.ChatSelectionColor = '#F9F9F9'
        this.DraftYellow = '#F2B415'
        this.ProcessingBlue = '#0B86DF'
        this.CloudyGrey = '#B8BDCC'
        this.RedColor = '#B3261E'
        this.BrownColor = '#AC590B'
        this.NeonGreenColor = '#EFFFED'
        this.OrangeColor = '#F07A0C'
        this.PrivateNoteColor = "#FFFCEC"
        this.LiteGreen = "#40C900"
        this.DarkGreen = "#40C900"
        this.LiteBlue = "#36A4F3"
        this.DarkBlue = "#1C6CA7"
        this.LiteBrown = "#C78F00"
        this.BlackishBrown = "#856000"


        this.InteractiveMsgColor = '#FD7900'
        this.SingleProductMsgColor = '#C78F00'
        this.MultiProductMsgColor = '#AC590B'
        this.MenuMsgColor = '#856000'
        this.TextMsgColor = '#79CF35'
        this.QuickReplyMsgColor = '#2F8D02'
        this.ActionMsgColor = '#1C5500'
        this.ImageMsgColor = '#36A4F3'
        this.VideoMsgColor = '#3654F3'
        this.AudioMsgColor = '#C236F3'
        this.DocumentMsgColor = '#EB156F'
        this.TemplateMsgColor = '#1C6CA7'

        // URLs
        this.loginUrl = '/login'
        this.changePasswordWithEmailUrl = '/change-password-with-email'
        this.forgotPasswordUrl = '/forgot-password'
        this.forgotPasswordStatusUrl = '/reset_pass_result'
        this.signupUrl = '/signup'
        this.signupSuccessUrl = '/signup-success'
        this.emailVerificationUrl = '/email-verification'
        this.whatsappVerificationUrl = '/whatsapp-verification'
        this.otpVerificationUrl = '/otp-verification'
        this.superAdminUrl = '/super-admin'
        this.dashboardUrl = '/dashboard'

        this.profileUrl = '/dashboard/profile'
        this.profileChangePasswordUrl = '/dashboard/profile/change-password'

        this.paymentPlanSubscriptionUrl = '/dashboard/payment/plan-subscription'
        this.paymentAddOnsUrl = '/dashboard/payment/add-ons'
        this.paymentSummaryUrl = '/dashboard/payment/summary'

        this.paymentUrl = '/dashboard/payment'
        this.paymentCompletionUrl = '/dashboard/completion'
        this.paymentSuccessUrl = '/dashboard/payment-success'
        this.paymentFailedUrl = '/dashboard/payment-failed'
        this.paymentAndroidUrl = '/dashboard/payment-android'
        this.billingAndroidUrl = '/dashboard/billing-android'
        this.chatUrl = '/dashboard/chat'
        this.ChatbotListUrl = '/dashboard/chatbot-list'
        this.buildChatbotsUrl = '/dashboard/build-chatbots'
        this.contactsUrl = '/dashboard/contacts'
        this.broadcastUrl = '/dashboard/broadcast'
        this.broadcastCreateUrl = '/dashboard/broadcast-create'
        this.analyticsUrl = '/dashboard/analytics'
        this.settingsUrl = '/dashboard/settings'
        this.businessInformationUrl = '/dashboard/settings/business-information'
        this.channelUrl = '/dashboard/settings/channels'
        this.agentsUrl = '/dashboard/settings/agents'
        this.labelsUrl = '/dashboard/settings/labels'
        this.customFieldsUrl = '/dashboard/settings/custom-fields'
        this.quickReplyUrl = '/dashboard/settings/quick-reply'
        this.apiIntegrationUrl = '/dashboard/settings/api-integration'
        this.appIntegrationUrl = '/dashboard/settings/app-integration'
        this.testingUrl = '/dashboard/testing'

        // Static Pages URls
        this.whatsappBusinessApiUrl = '/whatsApp-business-api'
        this.crmUrl = '/crm'
        this.intergrationUrl = '/integration'
        this.educationalSectorUrl = '/educational-sector-industry'
        this.ecommerceSectorUrl = '/ecommerce-sector-industry'
        this.healthSectorUrl = '/health-sector-industry'
        this.tourTravelSectorUrl = '/tour-travel-sector-industry'
        this.pricingUrl = '/pricing'
        this.wtdPaymentUrl = '/wtd-payment'
        this.quickGuideUrl = '/quick-guide'
        this.chatbotsUrl = '/chatbots'
        this.multiAgentsUrl = '/multi-agents'
        this.broadcastMessageUrl = '/broadcast-message'
        this.clientsUrl = '/clients'
        this.csvToVcfUrl = '/csv-to-vcf'

        this.supportUrl = '/support'
        this.termsOfServiceUrl = '/terms-of-service'
        this.privacyPolicyUrl = '/privacy-policy'
        this.cancellationUrl = '/cancellation'

        this.blogUrl = 'https://blog-business.whatstool.in'
        this.teamUrl = 'https://whatstool.in/team'
        this.faqUrl = 'https://whatstool.in/faq'

        // App Urls
        this.whatstoolAppUrl = 'https://play.google.com/store/apps/details?id=com.whatstools.statussaver.directchat.trendingstatus.searchprofile'
        this.whatstoolBusinessAppUrl = 'https://play.google.com/store/apps/details?id=in.wtb'
        this.whatsSaveAppUrl = 'https://play.google.com/store/apps/details?id=com.whatstool.whatssave.exportcontact.autosave'
        this.instaramAppUrl = 'https://play.google.com/store/apps/details?id=igtool.toolsforinstagram.instagramphotodownloader.instagramvideodownloader.repost'
        this.appDeveloperUrl = 'https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp'
        this.wtDesktopAppUrl = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/desktop/WhatsTool.zip'
        // End of URLs


        // SEO Data
        this.pricingTitle = 'Pricing'
        this.quickGuideTitle = 'Quick Guide'
        this.chatbotTitle = 'Chatbots'
        this.multiAgentTitle = 'Multi Agent Support'
        this.broadcastMsgTitle = 'Broadcast Messaging'
        this.clientsTitle = 'Clients'
        this.supportTitle = 'Customer Support'
        this.termsOfServiceTitle = 'Terms of Services'
        this.privacyPolicyTitle = 'Privacy Policy'
        this.cancellationTitle = 'Cancellation & Refund Policy'

        this.commonKeywords = 'whatstool business, whatsapp business api, whatsapp api, whatsapp API, whatsapp API india, whatsapp API send message, whatsapp API in india, whatsapp API provider india, whatsapp API cost india, whatsapp API pricing in india'
        // End of SEO Data
    }
}
export const WtTheme = new WtThemes()