/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'react-bootstrap'
import { DeleteOutlineOutlined, ContentCopyOutlined, AddOutlined } from '@mui/icons-material'
import { WtTheme } from '../../controller/theme'
import { deleteChannelAPI, fetchChannels, } from '../../controller/APIs'
import { AddChannelDialog, ConfirmDialog, ShowSnackbar, WtLoader } from '../../controller/BaseTool'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'


export const Channels = () => {
    const notificationBarStatus = useContext(globalContext)
    const [channelList, setChannelList] = useState([])

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    // Snackbar Code
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const SnackbarShow = (errorMsg, errorMsgBg) => { setErrorMsg(errorMsg); setErrorMsgBg(errorMsgBg); setOpenSnackbar(true) }
    const SnackbarClose = () => { setOpenSnackbar(false) }
    // End of Snackbar Code

    const onClickButtonCopy = (evt) => {
        navigator.clipboard.writeText(evt)
        SnackbarShow('Copied to clipboard', WtColor.BaseColor)
    }

    const [openAddChannelDialog, setOpenAddChannelDialog] = useState({ open: false, onClose: null })
    const openAddChannelDialogBox = () => {
        setOpenAddChannelDialog({
            open: true, onClose: (confirm) => {
                setOpenAddChannelDialog(false)
                if (confirm) fetchAllChannels()
            }
        })
    }

    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const openConfirmationForDeletionBox = (id) => {
        setOpenConfirmDialogBox({
            open: true, title: 'Channel Deletion', message: 'Are your sure to delete this Channel?', onClose: (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) deleteChannels(id)
            }
        })
    }

    // List of Channels 
    const fetchAllChannels = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchChannels()
            if (response.status === 200) {
                setChannelList(response.data.data)
                response.data.data?.map((v, i) => {
                    localStorage.setItem("c" + (i + 1), response.data.data[i]?.whatsapp)
                    localStorage.setItem("cObj", JSON.stringify(response.data?.data))
                })
                handleWTLoader.handleWTLoader(false)
            } else {
                handleWTLoader.handleWTLoader(false)
            }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of List of Channels 

    // Delete Channels 
    const deleteChannels = async (id) => {
        handleWTLoader.handleWTLoader(true)
        try {
            const data = "_id=" + id
            const response = await deleteChannelAPI(data)
            if (response.status === 204) {
                fetchAllChannels()
                handleWTLoader.handleWTLoader(false)
            } else {
                handleWTLoader.handleWTLoader(false)
            }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of Delete Channels 


    // const handle360Callback = (callbackObject) => {
    //     try {
    //         const data = { "channelId": callbackObject.channels[0] }
    //         setTimeout(async () => {
    //             const response = await updateDialog360(data)
    //             if (response.status === 201) {
    //                 handleWTLoader.handleWTLoader(false)
    //                 fetchAllChannels()
    //             }
    //         }, 1000)
    //     } catch (err) { handleWTLoader.handleWTLoader(false) }
    // }

    useEffect(() => { fetchAllChannels() }, [])

    const profile_base_view = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    const profile_menu_item = {
        flex: '0.85'
    }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} duration={3000} close={SnackbarClose} />
                {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} onClose={openConfirmDialogBox.onClose} />}
                {openAddChannelDialog.open && <AddChannelDialog open={openAddChannelDialog.open} onClose={openAddChannelDialog.onClose} />}
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}><h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Channels</h5></div>
                        <div>
                            <button onClick={() => openAddChannelDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius }}><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Add Channels</button>
                            {/* {
                                localStorage.getItem('activePlan') !== 'Free' || localStorage.getItem('addons_p') === 'dialog360_buy_success' ?
                                    <ConnectButton className='rounded-2 text-white float-end' partnerId={'tjYdNWPA'} label="Connect 360 Dialog" callback={handle360Callback}
                                        style={{ textTransform: "none", boxShadow: "none", marginRight: "5px", 'backgroundColor': '#FE4269', height: "36px", paddingTop: "7px" }} />
                                    : ""
                            } */}
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <div className="app_list" style={{ height: "610px" }}>
                            <Table className="fw-bold" responsive>
                                <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: "15px" }}>
                                    <tr>
                                        <th>Type</th><th>Name</th><th>Webhook URL</th><th>WhatsApp</th>
                                        {(localStorage.getItem("userRole") !== "admin") && <th>Action</th>}
                                    </tr>
                                </thead>
                                <tbody style={{ background: "#fff", fontSize: "12px" }}>
                                    {
                                        channelList?.map((v, i) => {
                                            let apiKeyOrToken = ""
                                            if (v.apiKey) apiKeyOrToken = v.apiKey
                                            if (v.wabaToken) apiKeyOrToken = v.wabaToken
                                            return (
                                                <tr className="hand" key={i}>
                                                    <td>
                                                        {
                                                            (() => {
                                                                if (v.type === "dialog360" || v.type === "whiteLabel") {
                                                                    return <img src={WtTheme.ImageBaseUrl + "/dialog360.png"} style={{ width: "30px", height: "30px" }} alt={WtTheme.SiteName} className="img-fluid" />
                                                                }
                                                                if (v.type === "waba") {
                                                                    return <img src={WtTheme.ImageBaseUrl + "/whatsapp.png"} style={{ width: "30px", height: "30px" }} alt={WtTheme.SiteName} className="img-fluid" />
                                                                }
                                                            })()
                                                        }
                                                    </td>
                                                    <td className='text-uppercase' style={{ color: WtColor.TextMidGrey, paddingTop: "16px" }}>{v.title ? v.title : "---"}</td>
                                                    <td style={{ color: WtColor.ProcessingBlue, paddingTop: "16px", display: "none" }}>{apiKeyOrToken?.substring(0, 20) + "..."}</td>
                                                    <td style={{ color: WtColor.ProcessingBlue, paddingTop: "16px" }}>{v.webhookUrl?.substring(0, 30) + "..."} &nbsp;&nbsp;<ContentCopyOutlined onClick={() => onClickButtonCopy(v.webhookUrl)} style={{ width: "14px" }} /></td>
                                                    <td style={{ color: WtColor.BaseColor, paddingTop: "16px" }}>{v.whatsapp} &nbsp;&nbsp;<ContentCopyOutlined onClick={() => onClickButtonCopy(v.whatsapp)} style={{ width: "14px" }} /></td>
                                                    {
                                                        (localStorage.getItem("userRole") === "admin") ? "" : <td style={{ paddingTop: "16px" }}><DeleteOutlineOutlined onClick={() => openConfirmationForDeletionBox(v._id)} className="text-danger" /></td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}