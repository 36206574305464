import React, { useEffect, useState, useContext } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import './index.css'
import { useHistory } from 'react-router-dom'
import { Logout } from '../../controller/caller'
import { ConfirmDialog } from '../../controller/BaseTool'
import globalContext from '../../context/global/globalContext'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'

export const ProfileSidebar = () => {
    const notificationBarStatus = useContext(globalContext)
    const [profileMenuOpen, setProfileMenuOpen] = useState(true)
    const handleProfileMenuCollapse = () => { setProfileMenuOpen(!profileMenuOpen) }

    const history = useHistory()
    const openUrl = (url) => { history.push(url) }

    const [sidebarIndex, setSidebarIndex] = useState(0)
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
    const askForLogout = () => {
        setOpenConfirmDialog({
            open: true, title: 'Whatstool Business', msg: 'Do you want to Logout?', onClose: async (confirm) => {
                setOpenConfirmDialog(false)
                if (confirm) Logout()
            }
        })
    }

    useEffect(() => {
        if (window.location.href === WtUrl.localUI_URL + WtUrl.profileUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.profileUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.profileUrl) setSidebarIndex(0)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.profileChangePasswordUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.profileChangePasswordUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.profileChangePasswordUrl) setSidebarIndex(1)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.paymentPlanSubscriptionUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.paymentPlanSubscriptionUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.paymentPlanSubscriptionUrl) setSidebarIndex(2)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.paymentAddOnsUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.paymentAddOnsUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.paymentAddOnsUrl) setSidebarIndex(3)
    }, [])


    const ProfileStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh',
        width: '94vw',
        flex: "0.15",
        borderRight: "1px solid " + WtColor.LineColor
    }
    return (
        <div className="bg-white" style={ProfileStyle}>
            <List sx={{ width: '100%' }} component="nav" aria-labelledby="nested-list-subheader">
                <ListItemButton onClick={handleProfileMenuCollapse}><ListItemText primary="Profile Information" />{profileMenuOpen ? <ExpandLess /> : <ExpandMore />}</ListItemButton>
                <Collapse in={profileMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => openUrl(WtUrl.profileUrl)} selected={sidebarIndex === 0}><ListItemText primary="Profile" /></ListItemButton>
                        {
                            localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' &&
                            <ListItemButton sx={{ pl: 4 }} onClick={() => openUrl(WtUrl.profileChangePasswordUrl)} selected={sidebarIndex === 1}><ListItemText primary="Password" /></ListItemButton>
                        }
                    </List>
                </Collapse>

                <ListItemButton onClick={() => openUrl(WtUrl.paymentPlanSubscriptionUrl)} selected={sidebarIndex === 2}><ListItemText primary="Plan &amp; Subscription" /></ListItemButton>
                <ListItemButton onClick={() => openUrl(WtUrl.paymentAddOnsUrl)} selected={sidebarIndex === 3}><ListItemText primary="Add-Ons Plan" /></ListItemButton >

                <ListItemButton onClick={() => askForLogout()}><ListItemText primary="Logout" /></ListItemButton >
            </List>

            {openConfirmDialog.open && <ConfirmDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />}
        </div>
    )
}