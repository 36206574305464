import axios from 'axios'
import { Buffer } from 'buffer'
import { Env } from './constant'

// Gen New Token: S. Purpose
export const genNewTokenAPI = () => {
    try {
        const response = axios.get(Env.baseApiCallURL + '/business/renew-auth-token', {}, {})
        return response
    } catch (error) {
        console.log("🔴genNewTokenAPI")
    }
}
// End of Gen New Token: S. Purpose



// Authentication
export const loginAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/auth/emp_login', payload, {})
        return response
    } catch (error) {
        console.log("🔴loginAPI")
    }
}
export const sendResetPasswordLinkAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/auth/send_reset_password_link', payload, {})
        return response
    } catch (error) {
        console.log("🔴sendResetPasswordLinkAPI")
    }
}
export const resetPasswordAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/auth/reset_password', payload, {})
        return response
    } catch (error) {
        console.log("🔴resetPasswordAPI")
    }
}
export const signupAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/registration/business_registration', payload, {})
        return response
    } catch (error) {
        console.log("🔴signupAPI")
    }
}
export const otpVerificationAPI = async (payload) => {
    try {
        const response = await axios.get(payload, {})
        return response
    } catch (error) {
        console.log("🔴otpVerificationAPI")
    }
}
export const resendOtpAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/registration/send_otp_again', payload, {})
        return response
    } catch (error) {
        console.log("🔴resendOtpAPI")
    }
}
export const changePasswordAPI = async (payload) => {
    try {
        const response = await axios.put(Env.baseApiCallURL + '/emp/change-password', payload, {})
        return response
    } catch (error) {
        console.log("🔴changePasswordAPI")
    }
}
export const demoLoginAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/demo/login', payload, {})
        return response
    } catch (error) {
        console.log("🔴demoLoginAPI")
    }
}
// End of Authentication




// Public API Intergration
export const fetchAPIGeneratedKey = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/api/public/generate-key', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAPIGeneratedKey")
    }
}
export const createCallbackURL = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/business/add/callback-url', payload, {})
        return response
    } catch (error) {
        console.log("🔴createCallbackURL")
    }
}
export const getAPIKeyAndWebhookAPI = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/business/api-dev-tools', {}, {})
        return response
    } catch (error) {
        console.log("🔴getAPIKeyAndWebhook")
    }
}

// End of Public API Integration





// Plan and Payments
export const fetchPlanListOpen = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/registration/plan-list', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchPlanListOpen")
    }
}
export const fetchPlanList = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/plans/list', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchPlanList")
    }
}
export const fetchPlanUsage = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/plans/dashboard', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchPlanUsage")
    }
}
export const fetchPlanPriceCalc = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/registration/calculate-price', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchPlanPriceCalc")
    }
}
export const fetchPaymentHistory = async (payload) => {
    try {
        const response = await axios.get(Env.baseApiCallURL + payload, {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchPaymentHistory")
    }
}
export const wtbSetupPayment = async (payload) => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/payments/wtb-setup', payload, {})
        return response
    } catch (error) {
        console.log("🔴wtbSetupPayment")
    }
}
// End of Plan and Payments





// User Location Check
export const fetchUserLocation = async () => {
    try {
        const response = await axios.get('http://ip-api.com/json', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchUserLocation")
    }
}
// End of User Location Check






// Business
export const fetchBusinessInfo = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/business/business_details', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchBusinessInfo")
    }
}
export const setOnBoardingStatusInBusiness = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/business/set-onboarding-status', payload, {})
        return response
    } catch (error) {
        console.log("🔴setOnBoardingStatusInBusiness")
    }
}
// End of Business


//Employee 
export const fetchEmployeeListAPI = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/business/employee_list', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchEmployeeListAPI")
    }
}
export const createUpdateEmployeeAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/business/employee_create_update', payload, {})
        return response
    } catch (error) {
        console.log("🔴createUpdateEmployeeAPI")
    }
}
export const deleteEmployeeAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/business/delete_employee', payload, {})
        return response
    } catch (error) {
        console.log("🔴deleteEmployeeAPI")
    }
}
//End of Employee 


// Registration
export const sendEmails = async (payload) => {
    try {

        const response = await axios.post(Env.baseApiCallURL + '/registration/send_temp_mgs', payload, {})
        return response
    } catch (error) {
        console.log("🔴sendEmails")
    }
}
export const sendTrailWhatsAppMessage = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/registration/send_temp_mgs', payload, {})
        return response
    } catch (error) {
        console.log("🔴sendTrailWhatsAppMessage")
    }
}
export const checkNumber = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/registration/check_number', payload, {})
        return response
    } catch (error) {
        console.log("🔴checkNumber")
    }
}
// End of Registration


// Invoice
export const fetchAddressOfInvoiceAPI = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/invoice/address_fetch_all', {}, {})
        return response
    } catch (error) {
        console.log("🔴createUpdateInvoice")
    }
}
export const createUpdateInvoice = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/invoice/address_create_update', payload, {})
        return response
    } catch (error) {
        console.log("🔴createUpdateInvoice")
    }
}
export const fetchAllInvoiceAPI = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/invoice/invoice_fetch_all', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllInvoiceAPI")
    }
}
// End of Invoice


// Channels
export const fetchChannels = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/channel/list', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchChannels")
    }
}
export const createChannelAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/channel/create', payload, {})
        return response
    } catch (error) {
        console.log("🔴createChannelAPI")
    }
}
export const deleteChannelAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/channel/delete', payload, {})
        return response
    } catch (error) {
        console.log("🔴deleteChannelAPI")
    }
}
export const updateDialog360 = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/channel/create/dialog360', payload, {})
        return response
    } catch (error) {
        console.log("🔴updateDialog360")
    }
}
// End of Channels


// Contacts
export const fetchContactsAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/contact/fetch_contacts', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchContactsAPI")
    }
}
export const fetchContactsV2API = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/contact/fetch_contacts/v2', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchContactsV2API")
    }
}
export const createContactsAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/contact/create-contact', payload, {})
        return response
    } catch (error) {
        console.log("🔴createContactsAPI")
    }
}
export const updateContactsAPI = async (payload) => {
    try {
        const response = await axios.put(Env.baseApiCallURL + '/contact/update-contact', payload, {})
        return response
    } catch (error) {
        console.log("🔴updateContactsAPI")
    }
}
export const deleteContactsAPI = async (payload) => {
    try {
        const response = await axios.delete(Env.baseApiCallURL + '/contact/delete-contact', { data: payload }, {})
        return response
    } catch (error) {
        console.log("🔴deleteContactsAPI")
    }
}
export const uploadCSVAPI = async (payload, options) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/contact/upload_csv', payload, options)
        return response
    } catch (error) {
        console.log("🔴uploadCSVAPI")
    }
}
// End of Contacts


// Conversation
export const fetchChats = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/fetch_chats', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchChats")
    }
}
export const fetchChatsV2 = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/fetch_chats_v2', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchChatsV2")
    }
}
export const chatUnreadAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/clear-unread', payload, {})
        return response
    } catch (error) {
        console.log("🔴chatUnreadAPI")
    }
}
export const fetchMessages = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/message/fetch_messages', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchMessagess")
    }
}
export const sendMessage = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/message/send_message_to_end_user', payload, {})
        return response
    } catch (error) {
        console.log("🔴sendMessage")
    }
}
export const sendPrivateMessageAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/add-private-note', payload, {})
        return response
    } catch (error) {
        console.log("🔴sendPrivateMessage")
    }
}
export const assignAgentAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/assign_emp', payload, {})
        return response
    } catch (error) {
        console.log("🔴assignAgentAPI")
    }
}
// End of Conversation


// User Info 
export const fetchUserInfoAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/users/get-user-details', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchUserInfo")
    }
}
// End of User Info 




// Campaign
export const fetchAllCampaignList = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/campaign/list-campaigns', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllCampaignList")
    }
}
export const createCampaign = async (payload, options) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/create', payload, options)
        return response
    } catch (error) {
        console.log("🔴createCampaign")
    }
}
export const createCampaignV2 = async (payload, options) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/create/v2', payload, options)
        return response
    } catch (error) {
        console.log("🔴createCampaignV2")
    }
}
export const changeCampaignStatus = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/set-campaign-status', payload, {})
        return response
    } catch (error) {
        console.log("🔴changeCampaignStatus")
    }
}
export const duplicateCampaign = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/duplicate', payload, {})
        return response
    } catch (error) {
        console.log("🔴duplicateCampaign")
    }
}
export const deleteCampaign = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/delete', payload, {})
        return response
    } catch (error) {
        console.log("🔴deleteCampaign")
    }
}
export const fetchCampaignAnalytics = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/list-campaign-numbers', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchCampaignAnalytics")
    }
}
export const fetchCampaignSpecificAnalytics = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/get-campaign-status', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchCampaignSpecificAnalytics")
    }
}
export const fetchCampaignSpecificAnalyticsV2 = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/status/v2', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchCampaignSpecificAnalyticsV2")
    }
}
export const fetchCampaignSpecificAnalyticsV3 = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/campaign/status/v3', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchCampaignSpecificAnalyticsV3")
    }
}
// End of Campaign


// Chatbot
export const fetchBotListAPI = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/bot/list_flow', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchBotListAPI")
    }
}
export const createUpdateBotFlow = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/bot/create_update_flow', payload, {})
        return response
    } catch (error) {
        console.log("🔴createUpdateBotFlow")
    }
}
export const deleteChatBotFlow = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/bot/delete_flow', payload, {})
        return response
    } catch (error) {
        console.log("🔴deleteBotFlow")
    }
}
export const fetchAllBots = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/bot/fetch_bots', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllBots")
    }
}
export const fetchAllCustomFields = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/fields/list')
        return response
    } catch (error) {
        console.log("🔴fields/list")
    }
}
export const botUpdateDelete = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/bot/update_delete', payload, {})
        return response
    } catch (error) {
        console.log("🔴botUpdateDelete")
    }
}
export const updateBotStatusAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/bot/update_flow_live', payload, {})
        return response
    } catch (error) {
        console.log("🔴updateBotStatusAPI")
    }
}
// End of Chatbot


// Media
export const uploadOwnMedia = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/media/upload_own', payload, {})
        return response
    } catch (error) {
        console.log("🔴uploadOwnMedia")
    }
}
export const fetchMedia = (channel, id) => {
    try {
        let token_gen = String(Number(Date.now()))
        token_gen = Buffer.from(token_gen).toString('base64').slice(0, -2)
        token_gen = token_gen.split('').reverse().join('')
        const response = Env.baseApiCallURL + '/media/fetch?extChannelId=' + channel + '&mediaId=' + id + "&authorization=" + localStorage.getItem("userAuthToken") + "&token=" + token_gen
        return response
    } catch (error) {
        console.log("🔴fetchMedia" + error)
    }
}
export const fetchAllMediaAPI = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/media/fetch_own', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllMediaAPI")
    }
}
export const deleteOwnMediaAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/media/delete_own', payload, {})
        return response
    } catch (error) {
        console.log("🔴deleteOwnMedia")
    }
}
// End of Media


// Template
export const fetchAllTemplatesAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/template/templates', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllTemplatesAPI")
    }
}
export const templateSync = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/template/templates-force', payload, {})
        return response
    } catch (error) {
        console.log("templateSync")
    }
}
// End of Template



// Quick Reply
export const fetchAllQuickReplyAPI = async () => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/quick_reply/list', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllQuickReplyAPI")
    }
}
export const createUpdateQuickReplyAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/quick_reply/create_update', payload, {})
        return response
    } catch (error) {
        console.log("🔴createUpdateQuickReplyAPI")
    }
}
export const deleteQuickReplyAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/quick_reply/delete', payload, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllQuickReplyAPI")
    }
}
// End of Quick Reply


// Labels Work
export const fetchAllLabelsAPI = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/labels/get-labels', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllLabels")
    }
}
export const createLabelsAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/labels/create', payload, {})
        return response
    } catch (error) {
        console.log("🔴createLabelsAPI")
    }
}
export const updateLabelsAPI = async (payload) => {
    try {
        const response = await axios.put(Env.baseApiCallURL + '/labels/update', payload, {})
        return response
    } catch (error) {
        console.log("🔴updateLabelsAPI")
    }
}
export const deleteLabelsAPI = async (payload) => {
    try {
        const response = await axios.delete(Env.baseApiCallURL + '/labels/delete', { data: payload }, {})
        return response
    } catch (error) {
        console.log("🔴deleteLabelsAPI")
    }
}
export const fetchAssignedListOfLabelsAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/assigned-labels', payload, {})
        return response
    } catch (error) {
        console.log("🔴assignedListOfLabelsAPI")
    }
}
export const assignedLabelsAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/add-label', payload, {})
        return response
    } catch (error) {
        console.log("🔴assignedLabelsAPI")
    }
}
export const removeLabelsAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/remove-label', payload, {})
        return response
    } catch (error) {
        console.log("🔴removeLabelsAPI")
    }
}
// End of Labels Work


// Attributes Work
export const fetchAllAttributesAPI = async () => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/fields/list', {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchAllAttributesAPI")
    }
}
export const fetchListAttributesAPI = async (payload) => {
    try {
        const response = await axios.get(Env.baseApiCallURL + '/fields/list' + payload, {}, {})
        return response
    } catch (error) {
        console.log("🔴fetchListAttributesAPI")
    }
}
export const createAttributesAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/fields/create', payload, {})
        return response
    } catch (error) {
        console.log("🔴createAttributesAPI")
    }
}
export const updateAttributesAPI = async (payload) => {
    try {
        const response = await axios.put(Env.baseApiCallURL + '/fields/update', payload, {})
        return response
    } catch (error) {
        console.log("🔴updateAttributesAPI")
    }
}
export const deleteAttributeAPI = async (payload) => {
    try {
        const response = await axios.delete(Env.baseApiCallURL + '/fields/delete', { data: payload }, {})
        return response
    } catch (error) {
        console.log("🔴deleteAttributeAPI")
    }
}
export const assignAttributesAPI = async (payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + '/chat/add-field', payload, {})
        return response
    } catch (error) {
        console.log("🔴assignAttributesAPI")
    }
}
export const assignEditAttributesAPI = async (payload) => {
    try {
        const response = await axios.put(Env.baseApiCallURL + '/chat/edit-field', payload, {})
        return response
    } catch (error) {
        console.log("🔴assignEditAttributesAPI")
    }
}
export const removeAttributeAPI = async (payload) => {
    try {
        const response = await axios.put(Env.baseApiCallURL + '/chat/remove-field', payload, {})
        return response
    } catch (error) {
        console.log("🔴removeAttributeAPI")
    }
}
// End of Attributes Work



// Razorpay Payment Work
// /payments/razorpay/buy-plan
// /payments/razorpay/wtb-setup
// /payments/razorpay/d360-security-deposit
// /payments/razorpay/chats-addon
// /payments/razorpay/monthly-bill
// /payments/razorpay/buy-agent
// /payments/razorpay/verify
export const razorpayPaymentAPI = async (url, payload) => {
    try {
        const response = await axios.post(Env.baseApiCallURL + url, payload, {})
        return response
    } catch (error) {
        console.log("🔴razorpayPaymentAPI")
    }
}
// End of Razorpay Payment Work