/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import './index.css'
import { Container } from 'react-bootstrap'
import { CampaignList } from './CampaignList'
import { BroadcastOnBoarding } from './OnBoarding'
import { fetchAllCampaignList } from '../../controller/APIs'
import { WtLoader } from '../../controller/BaseTool'

export const Broadcast = () => {
    let isBusy = false
    const [campaignListStatus, setCampaignListStatus] = useState(undefined)
    const handleCampaignListStatus = (val) => { setCampaignListStatus(val) }

    // Loader Code
    const [isLoading, setIsLoading] = useState(undefined)
    const showLoader = () => { setIsLoading(true) }
    const hideLoader = () => { setIsLoading(false) }
    // End of Loader Code

    //Fetch Campaign List
    const [campaignList, setCampaignList] = useState([])
    const fetchCampaignList = async () => {
        if (!isBusy) {
            isBusy = true
            showLoader()
            try {
                const response = await fetchAllCampaignList()
                if (response.status === 200) {
                    if (response.data.data.campaigns.length === 0) {
                        handleCampaignListStatus(false)
                    } else {
                        handleCampaignListStatus(true)
                    }
                    setCampaignList(response.data.data.campaigns)
                    hideLoader()
                    isBusy = false
                }
            } catch (error) {
                isBusy = false
            }
        }
    }
    // End of Fetch Campaign List

    useEffect(() => { fetchCampaignList() }, [])
    return (
        <Container fluid>
            {isLoading && <WtLoader open={isLoading} />}
            {
                (() => {
                    if (!isBusy) {
                        if (campaignListStatus === true) {
                            return (
                                <CampaignList campaignList={campaignList} fetchCampaignList={fetchCampaignList} />
                            )
                        }
                        if (campaignList.length === 0 || campaignListStatus === false) {
                            return (<BroadcastOnBoarding />)
                        }
                    }
                })()
            }
        </Container>
    )
}