/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { LockOutlined, AttachFileOutlined, FlashOnOutlined, SentimentSatisfiedAltOutlined, PermIdentityOutlined, ScheduleOutlined, InfoOutlined, CallOutlined } from '@mui/icons-material'
import { Avatar, IconButton, Skeleton, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'
import chatContext from '../../context/chat/chatContext'
import { fetchMedia, sendMessage, sendPrivateMessageAPI } from '../../controller/APIs'
import { RemainingTimeSlot, ShowTimeDayDate, MultiPurposeDialog, QuickReplyDialog, copyToClipboard } from '../../controller/BaseTool'
import { WtTheme } from '../../controller/theme'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { ActivityTextMessage, AudioMessage, BusinessImageMessage, ButtonReplyMessage, DocumentMessage, EndUserImageMessage, EndUserLocationMessage, InteractiveButtonReplyMessage, InteractiveButtonsMessage, InteractiveButtonMessage, NotValidMessage, PrivateImageMessage, PrivateTextMessage, PrivateVideoMessage, TemplateButtonMessage, TemplateMessage, TextMessage, VideoMessage, InteractiveListMessage, InteractiveListReplyMessage, TextReplyMessage, TextMenuReplyMessage, TextImageReplyMessage, TextVideoReplyMessage } from './MessagesUI'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const MsgBody = (props) => {
    const { userInfoScrSize, handleChatAndUserInfoScr } = props

    const activeChatInfo = useContext(chatContext)
    const messageLsData = useContext(chatContext)
    const getMessages = useContext(chatContext)
    const busyM = useContext(chatContext)
    const msgSendAccess = useContext(chatContext)
    const messageLsDataCount = useContext(chatContext)

    const userInfo = useContext(globalContext)

    const scrollToEndRef = useRef(null)
    const moreMsgRef = useRef(null)
    const [moreMsgRefStatus, setMoreMsgRefStatus] = useState(false)

    let { hash } = useParams()

    var channel = activeChatInfo.activeChatInfo.channel
    var endUserWhatsApp = activeChatInfo.activeChatInfo.whatsapp
    var _hash = activeChatInfo.activeChatInfo.hash

    const [messageSkip, setMessageSkip] = useState(20)
    const handleMsgList = () => {
        setMessageSkip(messageSkip + 20)
        getMessages.getMessages(_hash, 20, messageSkip, "fetch_more")
    }

    let busy = false
    const handleMessageScroll = (e) => {
        if (!busy) {
            busy = true
            setMoreMsgRefStatus(false)
            const currentScrollY = e.target.scrollTop
            const cHeight = e.target.clientHeight
            const sHeight = e.target.scrollHeight
            const substractHeight = (sHeight - cHeight)
            if (((currentScrollY / substractHeight).toFixed(4)) === "0.0000") {
                setMoreMsgRefStatus(true)
                if (messageLsDataCount.messageLsDataCount > messageSkip) {
                    handleMsgList()
                }
                busy = false
            } else {
                busy = false
            }
        }
    }


    // Message Footer Work
    const [sendType, setSendType] = useState("reply_to_end_user") // reply_to_end_user | private_note
    const handleSendTab = (e) => {
        if (e === "reply_to_end_user") {
            setSendType("reply_to_end_user")
            setPrivateNote("")
        } else {
            setSendType("private_note")
            setTextMessage("")
        }
    }

    const handleOnKeyDown = (e) => {
        if (e.ctrlKey && (e.keyCode === 13)) {
            e.preventDefault()
            let msg_text = textMessage + "\r\n"
            setTextMessage(msg_text)
            scrollToEndRef.current?.scrollIntoView()
        } else if (e.keyCode === 13) {
            e.preventDefault()
            sendTextMsgToEndUser()
        } else if (e.ctrlKey && e.key === '/') {
            e.preventDefault()
            openMultiPurposeDialogBox("Chat", "template", "+" + localStorage.getItem("activeChatWhatsApp"))
        }
    }

    // Text Message Work
    const [textMessage, setTextMessage] = useState("")
    const [privateNote, setPrivateNote] = useState("")
    const handleTextMessageOnChange = (e) => setTextMessage(e.target.value)
    const handleResetTextMessage = () => {
        setTextMessage("")
        setPrivateNote("")
        setEmojiKeyboardStatus(false)
    }
    const handlePrivateNote = (val) => setPrivateNote(val)
    const sendTextMsgToEndUser = async () => {
        setMoreMsgRefStatus(false)
        scrollToEndRef.current?.scrollIntoView()
        if ((textMessage.trim().length !== 0 && RemainingTimeSlot(msgSendAccess.msgSendAccess, "") !== 0) || (privateNote.trim().length !== 0)) {
            try {
                let data = {
                    "extUserId": activeChatInfo.activeChatInfo.whatsapp,
                    "extChannelId": activeChatInfo.activeChatInfo.channel,
                    "payload": {
                        "recipient_type": "individual",
                        "type": "text",
                        "text": {
                            "body": (sendType === "reply_to_end_user") ? textMessage : privateNote
                        }
                    }
                }
                handleResetTextMessage()
                const response = (sendType === "reply_to_end_user") ? await sendMessage(data) : await sendPrivateMessageAPI(data)
                if (response.status === 200) {
                    handleResetTextMessage()
                }
            } catch (error) { }
        } else {
            handleResetTextMessage()
        }
    }
    // End of Text Message Work

    useEffect(() => {
        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey && (event.key === "/" || event.keyCode === 191)) {
                event.preventDefault()
                openMultiPurposeDialogBox("Chat", "template", "+" + localStorage.getItem("activeChatWhatsApp"))
            } else if (event.Enter) {
                event.preventDefault()
                sendTextMsgToEndUser()
            }
        })
    }, [])

    const handleQuickReplyCallBack = (val) => { if (RemainingTimeSlot(msgSendAccess.msgSendAccess, "") !== 0) setTextMessage(val) }
    const [openQuickReplyDialog, setQuickReplyDailog] = useState({ open: false, onClose: null })
    const openQuickReplyDialogBox = () => setQuickReplyDailog({ open: true, onClose: () => setQuickReplyDailog(false) })

    const [openMultiPurposeDialog, setOpenMultiPurposeDialog] = useState({ open: false, onClose: null })
    const openMultiPurposeDialogBox = (purposeVal, demandVal, reqData) => setOpenMultiPurposeDialog({ open: true, purposeVal: purposeVal, demandVal: demandVal, reqData: reqData, onClose: async (confirm) => setOpenMultiPurposeDialog(false) })
    // End of Message Footer Work


    // Emoji Keyboard Work
    const [emojiKeyboardStatus, setEmojiKeyboardStatus] = useState(false)
    const handleEmojiKeyboard = () => setEmojiKeyboardStatus(!emojiKeyboardStatus)
    const emojiPicker = (e) => {
        if (sendType === "reply_to_end_user") {
            setTextMessage(p => p + e.native)
        } else {
            setPrivateNote(p => p + e.native)
        }
    }
    // End of Emoji Keyboard Work

    useEffect(() => { moreMsgRef.current?.scrollIntoView() }, [messageLsData.messageLsData])
    useEffect(() => { scrollToEndRef.current?.scrollIntoView(); setMessageSkip(20) }, [activeChatInfo.activeChatInfo, userInfo.userInfo, hash])
    return (
        <>
            <div className='msg_header bg-white ps-3 pe-3 pt-3 pb-0' style={{ flex: "0.05", display: "flex", borderBottom: "1px solid " + WtColor.LineColor }}>
                <div className="hand" style={{ display: "flex", flex: "1", fontSize: "20px" }}>
                    <Avatar style={{ background: WtColor.LineColor, width: "50px", height: "50px", color: WtColor.BaseColor }}><PermIdentityOutlined /></Avatar>
                    <div className="ms-2">
                        <p style={{ color: WtColor.TextDarkColor, fontWeight: "500" }}>{userInfo.userInfo.name ? userInfo.userInfo.name === '' ? 'Loading...' : userInfo.userInfo.name : userInfo.userInfo.whatsapp === '' ? 'Loading...' : userInfo.userInfo.whatsapp}</p>
                        <Tooltip title="Click to Copy" placement='bottom'>
                            <p onClick={() => copyToClipboard(userInfo.userInfo.whatsapp)} style={{ marginTop: "-18px", color: WtColor.TextMidGrey, fontSize: "13px" }}><CallOutlined style={{ color: WtColor.TextDarkColor, fontSize: "14px" }} /> {userInfo.userInfo.whatsapp || userInfo.userInfo.whatsapp === '' ? '+' + userInfo.userInfo.whatsapp : ''}</p>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p style={{ marginTop: "8px", color: WtColor.BaseColor }}>
                        {
                            RemainingTimeSlot(msgSendAccess.msgSendAccess, "ui") === 0 ? <Tooltip title="Only Template can be send" placement='bottom'><LockOutlined style={{ color: WtColor.RedColor }} /></Tooltip> :
                                <><ScheduleOutlined style={{ marginTop: "-4px" }} /> {RemainingTimeSlot(msgSendAccess.msgSendAccess, "ui")}</>
                        }
                        {
                            userInfoScrSize === "0.20" ? "" : <IconButton className='ms-3' onClick={() => handleChatAndUserInfoScr("0.40", "0.20", "block")}><InfoOutlined /></IconButton>
                        }
                    </p>
                </div>
            </div>
            <div className='msg_body' style={{ flex: "0.85", background: "#f8f8f8" }} onScroll={handleMessageScroll}>
                <div className="ps-3 pe-3 pt-2 pb-5 mb-5">
                    {
                        busyM.busyM &&
                        <div>
                            <EndUserChatSkeleton />
                            <BusinessChatSkeleton />
                            <EndUserChatSkeleton />
                            <BusinessChatSkeleton />
                            <EndUserChatSkeleton />
                            <BusinessChatSkeleton />
                        </div>
                    }
                    {
                        !busyM.busyM &&
                        messageLsData.messageLsData?.sort((a, b) => (a.sentDate > b.sentDate) ? 1 : -1).map((v, i) => {
                            return (
                                <div key={i}>
                                    {(i === 20 && moreMsgRefStatus) ? <div ref={moreMsgRef} /> : ''}
                                    {
                                        (() => {
                                            if (v.toId && v.fromId && v.msg) {
                                                return (
                                                    (v.toId === activeChatInfo.activeChatInfo.whatsapp) ? (
                                                        <BusinessChatView v={v} key={i} />
                                                    ) : (
                                                        <EndUserChatView v={v} key={i} />
                                                    )
                                                )
                                            }
                                            if (v.msgType && v.msgType === "privatenote" && v.msg) {
                                                return <BusinessPrivateChatView v={v} key={i} />
                                            }
                                            if (v.activityText) {
                                                return <ActivityTextMessage v={v} key={i} />
                                            }
                                        })()
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div ref={scrollToEndRef} />
            </div>
            <div className='msg_footer' style={{ flex: "0.10", background: (sendType === "reply_to_end_user") ? "#fff" : WtColor.PrivateNoteColor, borderTop: "1px solid " + WtColor.LineColor }}>
                {
                    (RemainingTimeSlot(msgSendAccess.msgSendAccess, "") !== 0) ?
                        <>
                            <div>
                                <Tabs defaultActiveKey="reply_to_end_user" onSelect={(e) => handleSendTab(e)} className="">
                                    <Tab eventKey="reply_to_end_user" title="Reply">
                                        <textarea value={textMessage} disabled={(RemainingTimeSlot(msgSendAccess.msgSendAccess, "") !== 0) ? false : true} onKeyDown={(e) => handleOnKeyDown(e)} onChange={(e) => handleTextMessageOnChange(e)} className="w-100 ps-3 pt-2" style={{ border: "none", outlineWidth: "0px", resize: "none" }} rows="3" placeholder="Type your message here..."></textarea>
                                    </Tab>
                                    <Tab eventKey="private_note" title="Private Note">
                                        <textarea value={privateNote} onKeyDown={(e) => handleOnKeyDown(e)} onChange={(e) => handlePrivateNote(e.target.value)} className="w-100 ps-3 pt-2" style={{ border: "none", outlineWidth: "0px", resize: "none", background: (sendType === "reply_to_end_user") ? "#fff" : WtColor.PrivateNoteColor }} rows="3" placeholder="Only visible to you and the team"></textarea>
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className="ps-3 pe-3 pt-2 pb-3" style={{ display: "flex" }}>
                                <div style={{ flex: "1" }}>
                                    <Tooltip title='Emoji' placement='top'><IconButton onClick={() => handleEmojiKeyboard()}> <SentimentSatisfiedAltOutlined /></IconButton ></Tooltip>
                                    <div style={{ display: emojiKeyboardStatus ? "block" : "none", position: "absolute", bottom: "250px" }}>
                                        <Picker data={data} onEmojiSelect={(e) => emojiPicker(e)} />
                                    </div>
                                    {
                                        sendType === "reply_to_end_user" ? <Tooltip title='Quick Reply' placement='top'><IconButton onClick={() => openQuickReplyDialogBox()}> <FlashOnOutlined /></IconButton ></Tooltip> : ""
                                    }
                                    <Tooltip title='Media' placement='top'><IconButton onClick={() => openMultiPurposeDialogBox("Chat", "media", { channel, endUserWhatsApp, sendType })}><AttachFileOutlined /></IconButton></Tooltip>
                                    <Tooltip title='Template' placement='top'><IconButton onClick={() => openMultiPurposeDialogBox("Chat", "template", "+" + localStorage.getItem("activeChatWhatsApp"))}><img src={WtTheme.ImageBaseUrl + "/template.svg"} className="img-fluid" alt={WtTheme.SiteName} style={{ width: "30px", borderRadius: '50%' }} /></IconButton></Tooltip>
                                </div>
                                <div>
                                    {
                                        (textMessage !== "") ?
                                            <button onClick={() => sendTextMsgToEndUser()} className="btn btn-sm theme_bg text-white rounded-3 pe-4 ps-4" style={{ fontSize: "15px" }}>Send</button>
                                            :
                                            (privateNote !== "") ?
                                                <button onClick={() => sendTextMsgToEndUser()} className="btn btn-sm theme_bg text-white rounded-3 pe-4 ps-4" style={{ fontSize: "15px" }}>Send</button> :
                                                <button className="btn btn-sm rounded-3 pe-4 ps-4" disabled style={{ fontSize: "15px", color: WtColor.TextMidGrey, background: WtColor.CloudyGrey, border: "1px solid " + WtColor.CloudyGrey }}>Send</button>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <div className='p-3'>
                            <p style={{ color: WtColor.TextMidGrey }}>Only template can be send.</p>
                            <button onClick={() => openMultiPurposeDialogBox("Chat", "template", "+" + endUserWhatsApp)} className="btn btn-sm theme_bg text-white rounded-3 mt-3 pe-4 ps-4" style={{ fontSize: "15px" }}>Send Template</button>
                        </div>
                }


                {
                    openMultiPurposeDialog.open ?
                        <MultiPurposeDialog open={openMultiPurposeDialog.open} purposeVal={openMultiPurposeDialog.purposeVal} demandVal={openMultiPurposeDialog.demandVal} reqData={openMultiPurposeDialog.reqData} onClose={openMultiPurposeDialog.onClose} /> : ""
                }
                {
                    sendType === "reply_to_end_user" ?
                        openQuickReplyDialog.open ?
                            <QuickReplyDialog open={openQuickReplyDialog.open} handleQuickReplyCallBack={handleQuickReplyCallBack} onClose={openQuickReplyDialog.onClose} /> : "" : ""
                }
            </div>
        </>
    )
}


export const BusinessChatView = (props) => {
    const { v } = props
    let empName = v.emp ? "@" + v.emp.name : "@Bot"
    return (
        <div>
            <div className="clearfix"></div>
            <div className="chatViewB float-end">
                <div className="pb-3">
                    <div style={{ display: "flex", flexDirection: "row" }} key={v._id}>
                        <div>
                            <div className="chat_bubble me-3">
                                {(v.msg.type === "text") ? <TextMessage val={v.msg.text.body} /> : ""}
                                {(v.msg.type === "image" && v.msg.image.link) ? <BusinessImageMessage link={v.msg.image.link} caption={(v.msg.image.caption && v.msg.image.caption !== "") ? v.msg.image.caption : ""} /> : ""}
                                {(v.msg.type === "interactive" && v.msg.interactive.type && v.msg.interactive.type === "button") ? <InteractiveButtonMessage id={v._id} interactive={v.msg?.interactive} /> : ""}
                                {(v.msg.type === "interactive" && v.msg.interactive.type && v.msg.interactive.type === "list") ? <InteractiveListMessage id={v._id} interactive={v.msg?.interactive} /> : ""}
                                {(v.msg.type === "document" && v.msg.document) ? <DocumentMessage document={v.msg.document} link={v.msg.document.link} /> : ""}
                                {(v.msg.type === "audio" && v.msg.audio) ? <AudioMessage audio={v.msg.audio} src={v.msg.audio.link} /> : ""}
                                {(v.msg.type === "video" && v.msg.video && v.msg.video.link) ? <VideoMessage v={v} /> : ""}
                                {(v.msg.type === "template" && v.msg.template && v.template && v.template.components) ? <TemplateMessage templateComponent={v.template?.components} msgTemplate={v.msg?.template} view={v} /> : ""}
                                {(v.msg.type === "image" && v.msg.image.link) ? <><p>&nbsp;</p></> : ""}
                                <span className="float-end" style={{ color: WtColor.TextColor, fontSize: "12px" }}>{ShowTimeDayDate(v.sentDate)}</span>
                                <br />
                            </div>
                            {
                                (v.msg.type === "interactive" && v.msg.interactive.action && v.msg.interactive.action.buttons && (v.msg.interactive.action.buttons.length !== 0)) ? <>
                                    <InteractiveButtonsMessage id={v._id} buttons={v.msg.interactive.action.buttons} />
                                </> : ""
                            }
                            {
                                (v.msg.type === "template" && v.msg.template && v.template && v.template.components) ? v.template.components.map((v, i) => {
                                    return (
                                        <>
                                            {
                                                (() => {
                                                    if (v.type && v.type === "BUTTONS" && v.buttons) {
                                                        return <TemplateButtonMessage key={i} buttons={v.buttons} />
                                                    }
                                                })()
                                            }
                                        </>
                                    )
                                }) : ""
                            }
                            <div className="me-3">
                                {
                                    v.reactionEmoji ? <span className="float-start" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>{v.reactionEmoji}</span> : ""
                                }
                                <span className="float-end" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>{empName}</span>
                            </div>
                        </div>
                        <img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="34px" height="34px" alt={WtTheme.SiteName} />
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    )
}
export const BusinessPrivateChatView = (props) => {
    const { v } = props
    let empName = v.emp ? "@" + v.emp.name : "@Bot"
    return (
        <div>
            <div className="clearfix"></div>
            <div className="chatViewB float-end">
                <div className="pb-3">
                    <div style={{ display: "flex", flexDirection: "row" }} key={v._id}>
                        <div>
                            <div className="chat_bubble_private me-3" >
                                {(v.msgType === "privatenote" && v.msg.type === "text") ? <PrivateTextMessage val={v.msg.text.body} /> : ""}
                                {(v.msgType === "privatenote" && v.msg.type === "image") ? <PrivateImageMessage v={v} /> : ""}
                                {(v.msgType === "privatenote" && v.msg.type === "video") ? <PrivateVideoMessage v={v} /> : ""}
                                <span className="float-end" style={{ color: WtColor.TextColor, fontSize: "12px" }}><span style={{ color: WtColor.DraftYellow }}><LockOutlined style={{ fontSize: "12px", marginBottom: "4px" }} /> Private Note</span> &nbsp;&nbsp;&nbsp; {ShowTimeDayDate(v.sentDate)}</span>
                                <br />
                            </div>
                            <div className="me-3">
                                <span className="float-end" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>{empName}</span>
                            </div>
                        </div>
                        <img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="34px" height="34px" alt={WtTheme.SiteName} />
                    </div>
                </div>
            </div>
            <div className="ms-3">
                {
                    v.reactionEmoji ? <span className="float-start" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>{v.reactionEmoji}</span> : ""
                }
            </div>
            <div className="clearfix"></div>
        </div>
    )
}
export const EndUserChatView = (props) => {
    const activeChatInfo = useContext(chatContext)
    const { v } = props

    let channel = activeChatInfo.activeChatInfo?.channel
    let endUserName = activeChatInfo.activeChatInfo?.name

    return (
        <>
            <div className="clearfix"></div>
            <div className="chatViewU">
                <div className="pb-3">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Avatar style={{ background: WtColor.BaseColor, width: "34px", height: "34px", color: WtColor.LineColor }} className="pt-2 pb-1 ps-1 pe-1">{endUserName?.charAt(0)}</Avatar>
                        <div className="chat_bubble ms-3">
                            <>
                                {(v.msg.type === "text" && !v.context) ? <TextMessage val={v?.msg?.text?.body} /> : ""}
                                {(v.msg.type === "text" && v.context && v.context?.msg && v.context?.msg?.type && v.context?.msg?.type === 'text' && v.context?.msg?.text) ? <TextReplyMessage val={v?.msg?.text?.body} contextMsg={v.context?.msg?.text?.body} /> : ""}
                                {(v.msg.type === "text" && v.context && v.context?.msg && v.context?.msg?.type && v.context?.msg?.type === 'interactive') ? <TextMenuReplyMessage val={v?.msg?.text?.body} contextMsg={v.context} /> : ""}
                                {(v.msg.type === "text" && v.context && v.context?.msg && v.context?.msg?.type && v.context?.msg?.type === 'image') ? <TextImageReplyMessage val={v?.msg?.text?.body} contextMsg={v.context?.msg?.image?.link} /> : ""}
                                {(v.msg.type === "text" && v.context && v.context?.msg && v.context?.msg?.type && v.context?.msg?.type === 'video') ? <TextVideoReplyMessage val={v?.msg?.text?.body} contextMsg={v.context?.msg?.video?.link} /> : ""}
                                {(v.msg.type === "location" && v.msg?.location) ? <EndUserLocationMessage location={v.msg?.location} /> : ""}
                                {(v.msg.type === "image" && v.msg.image.id) ? <EndUserImageMessage src={fetchMedia(channel, v.msg.image.id)} caption={(v.msg.image.caption && v.msg.image.caption !== "") ? v.msg.image.caption : ""} /> : ""}
                                {(v.msg.type === "interactive" && v.msg.interactive.type && v.msg.interactive.type === "button_reply") ? <InteractiveButtonReplyMessage id={v._id} interactive={v.msg?.interactive} contextMsg={v.context} /> : ""}
                                {(v.msg.type === "interactive" && v.msg.interactive.type && v.msg.interactive.type === "list_reply") ? <InteractiveListReplyMessage id={v._id} interactive={v.msg?.interactive} contextMsg={v.context} /> : ""}
                                {(v.msg.type === "document" && v.msg.document) ? <DocumentMessage document={v.msg.document} link={fetchMedia(channel, v.msg.document.id)} /> : ""}
                                {(v.msg.type === "audio" && v.msg.audio) ? <AudioMessage audio={v.msg.audio} src={fetchMedia(channel, v.msg.audio.id)} /> : ""}
                                {(v.msg.type === "video" && v.msg.video) ? <VideoMessage v={v} channel={channel} /> : ""}
                                {(v.msg.type === "button" && v.msg.button) ? <ButtonReplyMessage msg={v.msg} /> : ""}
                                {(v.msg.type === "unsupported") ? <NotValidMessage val={"Message type is not supported."} /> : ""}
                            </>
                            <span className="float-start" style={{ color: WtColor.TextColor, fontSize: "12px" }}>{ShowTimeDayDate(v.sentDate)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </>
    )
}



export const BusinessChatSkeleton = () => {
    return (
        <div>
            <div className="clearfix"></div>
            <div className=" float-end">
                <div className="pb-3">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                            <div className=" me-3">
                                <Skeleton variant="rounded" width={210} height={60} />
                                <br />
                            </div>
                        </div>
                        <img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="34px" height="34px" alt={WtTheme.SiteName} />
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    )
}
export const EndUserChatSkeleton = () => {
    return (
        <>
            <div className="clearfix"></div>
            <div className="">
                <div className="pb-3">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Avatar style={{ background: WtColor.BaseColor, width: "34px", height: "34px", color: WtColor.LineColor }} className="pt-2 pb-1 ps-1 pe-1">U</Avatar>
                        <div className=" ms-3">
                            <Skeleton variant="rounded" width={210} height={60} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </>
    )
}