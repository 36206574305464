import React, { useContext } from 'react'
import { Tooltip, Zoom } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { WtTheme } from '../../controller/theme'
import { Container, Row, Col } from 'react-bootstrap'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'


export const AppIntegration = () => {
    const notificationBarStatus = useContext(globalContext)
    const AppIntegrationData = [
        { 'img': WtTheme.ImageBaseUrl + '/zoho_icon.png', 'desc': 'Integrate messages on the Zoho CRM', 'type': 'CRM Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/shopify.png', 'desc': 'Chat Widget, Cart Abandonment Emails, etc.', 'type': 'E-commerce Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/whatsapp_link_generate_icon.png', 'desc': 'Create shareable links & QR  for your WA business number', 'type': 'Messaging Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/zapier_icon.png', 'desc': 'Send an approved template WhatsApp message', 'type': 'Connect Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/woo_commerce_icon.png', 'desc': 'Chat Widget, Cart Abandonment Emails, etc.', 'type': 'E-commerce Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/hubspot_icon.png', 'desc': 'Integrate messages on the Hubspot CRM', 'type': 'CRM Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/pabbly_icon.png', 'desc': 'Automate or link with 700+ apps throgh pabbly Connect', 'type': 'Online Marketing & Sales Platform', 'status': 'coming soon' },
        { 'img': WtTheme.ImageBaseUrl + '/razorpay_icon.png', 'desc': 'Integrate messages on the Razorpay', 'type': 'Payment Provider', 'status': 'coming soon' }
    ]
    const profile_base_view = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    const profile_menu_item = {
        flex: '0.85'
    }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor }}>APP Integration &nbsp;
                                <Tooltip title="Application integration is the process of enabling independently designed applications to work together. Commonly required capabilities include: Keeping separate copies of data (in independently designed applications) consistent." placement="bottom-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }}>
                                    <InfoOutlined style={{ color: WtColor.TextLightGrey, marginTop: "-5px", fontSize: '15px' }} />
                                </Tooltip>
                            </h5>
                            <p style={{ fontSize: '10px', color: WtColor.TextLightGrey, marginTop: "-6px", marginBottom:'0px' }}>Connect WhatsTool Business to your favorite services</p>
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <Container className="app_list" style={{ height: "610px" }} fluid>
                            <Row>
                                {
                                    AppIntegrationData?.map((v, i) => {
                                        return (
                                            <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} key={i} className="mb-3 hand">
                                                <div className=" p-3" style={{ borderRadius: '6px', boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <img src={v.img} alt={WtTheme.SiteName} className="img-fluid" style={{ height: "35px" }} />
                                                        <div style={{ flex: "1" }}>&nbsp;</div>
                                                        <div>
                                                            <button className="btn btn-sm text-white " style={{ background: WtColor.BaseLightColor, borderRadius: "30px", paddingTop: "1px", paddingBottom: "1px", paddingLeft: "8px", paddingRight: "8px" }}>{v.status}</button>
                                                        </div>
                                                    </div>
                                                    <p style={{ color: WtColor.TextLightGrey }}>{v.type}</p>
                                                    <p style={{ color: WtColor.TextDarkColor }}>{v.desc}</p>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div >
    )
}