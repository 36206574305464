/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Dialog, DialogContent, DialogTitle, Autocomplete, Tooltip, FormControl, InputLabel, TextField, Input, InputAdornment, Avatar } from '@mui/material'
import { CreateOutlined, InfoOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import { MultiPurposeDialog, ShowSnackbar, WtLoader } from '../../controller/BaseTool'
import { ContactList, ImportContact, SelectContacts } from './Contact'
import FormData from 'form-data'
import { TopBar } from './TopBar'
import { createCampaign } from '../../controller/APIs'
import { useHistory } from "react-router-dom"
import BroadcastState from '../../context/broadcast/broadcastState'
import broadcastContext from '../../context/broadcast/broadcastContext'
import { WtColor } from '../../constants/colours'
import globalContext from '../../context/global/globalContext'

export const CreateCampaign = () => { return (<BroadcastState><CreateCampaignMain /></BroadcastState>) }

export const CreateCampaignMain = () => {
    const isLoading = useContext(broadcastContext)
    const handleWTLoader = useContext(broadcastContext)

    const mainBroadcastUI = useContext(broadcastContext) // create_campaign || contact_selection || contact_list
    const updateMainBroadcastUI = useContext(broadcastContext)
    const setButtonActive = useContext(broadcastContext)

    const campaignName = useContext(broadcastContext)
    const updateCampaignName = useContext(broadcastContext)

    const templateName = useContext(broadcastContext)
    const setTemplateName = useContext(broadcastContext)
    const contactCount = useContext(broadcastContext)
    const setContactCount = useContext(broadcastContext)
    const selectCSV = useContext(broadcastContext)

    const wholeTemplate = useContext(broadcastContext)
    const setWholeTemplate = useContext(broadcastContext)
    const templateNamespace = useContext(broadcastContext)
    const setTemplateNamespace = useContext(broadcastContext)
    const templateLanguage = useContext(broadcastContext)
    const setTemplateLanguage = useContext(broadcastContext)
    const templateVarNames = useContext(broadcastContext)
    const setTemplateVarNames = useContext(broadcastContext)
    const setTemplateVarCount = useContext(broadcastContext)
    const tempHeaderType = useContext(broadcastContext)
    const setTempHeaderType = useContext(broadcastContext)
    const mediaType = useContext(broadcastContext)
    const setMediaType = useContext(broadcastContext)
    const mediaLink = useContext(broadcastContext)
    const setMediaLink = useContext(broadcastContext)

    const stepOneStatus = useContext(broadcastContext)
    const stepTwoStatus = useContext(broadcastContext)
    const setStepTwoStatus = useContext(broadcastContext)
    const stepThreeStatus = useContext(broadcastContext)
    const setStepThreeStatus = useContext(broadcastContext)
    const confirmationDia = useContext(broadcastContext)
    const setConfirmationDia = useContext(broadcastContext)


    const channelLs = useContext(broadcastContext)
    const getChannelLs = useContext(broadcastContext)

    const channelName = useContext(broadcastContext)
    const channelId = useContext(broadcastContext)
    const updateChanelSelection = useContext(broadcastContext)

    // Handle Contacts Selection Tabs
    const handleContactSelectionTab = useContext(broadcastContext)
    // End of Handle Contacts Selection Tabs

    const history = useHistory()

    const openSnackbar = useContext(globalContext)
    const snackbarShow = useContext(globalContext)
    const snackbarClose = useContext(globalContext)


    const handleUISelection = (ui_val) => {
        updateMainBroadcastUI.updateMainBroadcastUI(ui_val)
        setButtonActive.setButtonActive(false)
    }

    // Main Variables
    const handleDialogBox = () => {
        if (stepTwoStatus.stepTwoStatus === 'completed') setConfirmationDia.setConfirmationDia(true)
        if ((stepTwoStatus.stepTwoStatus === 'completed' && stepThreeStatus.stepThreeStatus === 'completed') || stepTwoStatus.stepTwoStatus === 'completed') { setConfirmationDia.setConfirmationDia(true) }
    }

    const resetCampaignData = () => {
        setTemplateName.setTemplateName('Select Template')
        setContactCount.setContactCount('Select Contact')
        setStepTwoStatus.setStepTwoStatus('active')
        setStepThreeStatus.setStepThreeStatus('disabled')
        setButtonActive.setButtonActive(false)
    }
    // End of Varibles Handling

    const handleResetDialog = () => {
        if (stepTwoStatus.stepTwoStatus === 'completed' && stepThreeStatus.stepThreeStatus === 'completed') {
            setConfirmationDia.setConfirmationDia(true)
        } else {
            openMultiPurposeDialogBox("Campaign", "template", { "channel_name": channelName.channelName, "channel_number": channelId.channelId })
        }
    }

    // Functional UI Component
    const ChannelSelectionUI = () => {
        let allChannelName = []
        channelLs.channelLs?.map((v) => { return allChannelName?.push(`${v.title} ${v.whatsapp}`) })
        return (
            <Autocomplete disableClearable id="select_channel" options={allChannelName} defaultValue={channelName.channelName} focused
                onChange={(event, value) => updateChanelSelection.updateChanelSelection(value)} sx={{ m: 1, width: "100%", }}
                renderInput={
                    (params) =>
                        <TextField
                            {...params} label="&nbsp;" InputLabelProps={{ shrink: false }} onClick={() => handleDialogBox()}
                            color={(stepOneStatus.stepOneStatus === 'disabled') ? "grey" : (stepOneStatus.stepOneStatus === 'active') ? 'grey' : "success"}
                        />
                }
            />
        )
    }
    // End of Functional UI Component

    const [openMultiPurposeDialog, setOpenMultiPurposeDialog] = useState({ open: false, onClose: null })
    const openMultiPurposeDialogBox = (purposeVal, demandVal, reqData) => setOpenMultiPurposeDialog({ open: true, purposeVal: purposeVal, demandVal: demandVal, reqData: reqData, onClose: () => setOpenMultiPurposeDialog(false) })


    // Callbacks
    const resetConfirmationCallback = () => {
        resetCampaignData()
        setConfirmationDia.setConfirmationDia(false)
    }
    const closeDialogCallBack = () => { setConfirmationDia.setConfirmationDia(false) }
    const templateVaribalesCallback = (data) => {
        setTemplateVarCount.setTemplateVarCount(data.split('|').length)
        setTemplateVarNames.setTemplateVarNames(data)
        if (data === "") setTemplateVarCount.setTemplateVarCount(0)
    }
    const wholeTemplateCallback = (data) => {
        setWholeTemplate.setWholeTemplate(JSON.stringify(data))
        setTemplateName.setTemplateName(data.name)
        setTemplateNamespace.setTemplateNamespace(data.namespace)
        setTemplateLanguage.setTemplateLanguage(data.language)
        setTempHeaderType.setTempHeaderType(data.components[0].format)

        setStepTwoStatus.setStepTwoStatus('completed')
        setStepThreeStatus.setStepThreeStatus('active')
    }
    const templateMediaInfocallback = (type, link) => {
        setMediaLink.setMediaLink(link)
        setMediaType.setMediaType(type)
    }
    const broadcastCallback = () => SendBroadcastMsg()
    // End of Callbacks

    let busy = false
    const SendBroadcastMsg = async () => {
        if (!busy) {
            busy = true
            handleWTLoader.handleWTLoader(true)
            try {
                if (campaignName.campaignName.length === 0) {
                    snackbarShow.snackbarShow("'Campaign Name' shouldn't be empty!!!", WtColor.RedColor)
                }
                if (campaignName.campaignName.length <= 2 && campaignName.campaignName.length !== 0) {
                    snackbarShow.snackbarShow("'Campaign Name' text should be greater than 2 character...", WtColor.RedColor)
                }
                if (campaignName.campaignName.length !== 0 && campaignName.campaignName.length > 2) {
                    const form = new FormData()

                    form.append('title', campaignName.campaignName);
                    form.append('extChannelId', channelId.channelId)
                    form.append('templateName', templateName.templateName);
                    form.append('csv', selectCSV.selectCSV);
                    form.append('namespace', templateNamespace.templateNamespace);
                    form.append('language', templateLanguage.templateLanguage);

                    if (templateVarNames.templateVarNames !== "") form.append('defaultVariables', templateVarNames.templateVarNames);

                    let tempJS = JSON.stringify(wholeTemplate.wholeTemplate)
                    form.append('template', JSON.parse(tempJS))

                    if (tempHeaderType.tempHeaderType !== 'TEXT' && (mediaLink.mediaLink !== "" || mediaLink.mediaLink !== undefined)) {
                        form.append('headerType', mediaType.mediaType);
                        form.append('headerLink', mediaLink.mediaLink);
                    }

                    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
                    const response = await createCampaign(form, options)

                    if (response.status === 201) {
                        history.push('/dashboard/broadcast')
                        handleWTLoader.handleWTLoader(false)
                    } else {
                        handleWTLoader.handleWTLoader(false)
                    }
                }
                busy = false
            } catch (error) { handleWTLoader.handleWTLoader(false); busy = false }
        }
    }

    useEffect(() => { getChannelLs.getChannelLs() }, [])
    return (
        <section>
            {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
            {openSnackbar.openSnackbar.open && <ShowSnackbar open={openSnackbar.openSnackbar.open} errorMsg={openSnackbar.openSnackbar.errorMsg} errorMsgBg={openSnackbar.openSnackbar.errorMsgBg} close={snackbarClose.snackbarClose} />}
            <div className='fixed-top bg-white' style={{ left: '62px' }}><TopBar broadCast={broadcastCallback} /></div>
            <div className='mt-5 pt-3'>
                {
                    (() => {
                        // Create Campaign
                        if (mainBroadcastUI.mainBroadcastUI === "create_campaign") {
                            return (
                                <div className="mt-5 app_list" style={{ height: '510px', border: "1px solid " + WtColor.LineColor, margin: "auto", width: "35%", paddingLeft: "45px", paddingRight: "45px", paddingTop: "40px", paddingBottom: "40px" }}>
                                    <div>
                                        <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                                            <InputLabel htmlFor="campaign_name">Campaign Name</InputLabel>
                                            <Input defaultValue={campaignName.campaignName} onChange={(e) => updateCampaignName.updateCampaignName(e.target.value)} id="campaign_name" type="text" placeholder="Enter Campaign Name" endAdornment={<InputAdornment position="end"><CreateOutlined /></InputAdornment>} />
                                        </FormControl>
                                    </div>

                                    <div className='campaign'>
                                        <Row>
                                            <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                                                <Avatar style={{
                                                    background: stepOneStatus.stepOneStatus === 'completed' ? WtColor.BaseColor : WtColor.LineColor,
                                                    color: stepOneStatus.stepOneStatus === 'disabled' ? WtColor.TextLightGrey : stepOneStatus.stepOneStatus === 'active' ? WtColor.TextDarkColor : "white",
                                                    width: "27px", height: "27px", marginTop: "15px", fontSize: "15px", fontWeight: 'bold'
                                                }}>1</Avatar>
                                                <div className="dotted-lines">
                                                    {
                                                        [...Array(3)].map((e, i) => {
                                                            return (
                                                                <p key={i} style={{
                                                                    color: stepThreeStatus.stepThreeStatus === 'completed' ? WtColor.BaseColor : '#fff',
                                                                    marginBottom: "0px", paddingBottom: "0px", paddingLeft: "10px", paddingTop: i === 0 ? "6px" : "0px"
                                                                }}>|</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                            <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                                                <div>
                                                    <div className="mt-3 mb-1 ps-2" style={{ display: "flex" }}>
                                                        <div style={{ color: stepOneStatus.stepOneStatus === 'disabled' ? WtColor.CloudyGrey : stepOneStatus.stepOneStatus === 'active' ? WtColor.TextDarkColor : WtColor.BaseColor, flex: 1, fontSize: "16px", fontWeight: "500" }}>Channel</div>
                                                        <div>
                                                            <Tooltip title="Select Channel" placement="left"><InfoOutlined className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px", }} /></Tooltip>
                                                        </div>
                                                    </div>
                                                    <ChannelSelectionUI />
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row style={{ pointerEvents: stepTwoStatus.stepTwoStatus === 'disabled' ? 'none' : 'all' }}>
                                            <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                                                <Avatar style={{
                                                    background: stepTwoStatus.stepTwoStatus === 'completed' ? WtColor.BaseColor : WtColor.LineColor,
                                                    color: stepTwoStatus.stepTwoStatus === 'disabled' ? WtColor.TextLightGrey : stepTwoStatus.stepTwoStatus === 'active' ? WtColor.TextDarkColor : "white",
                                                    width: "27px", height: "27px", marginTop: "15px", fontSize: "15px", fontWeight: 'bold'
                                                }}>2</Avatar>

                                                <div className="dotted-lines">
                                                    {
                                                        [...Array(3)].map((e, i) => {
                                                            return (
                                                                <p key={i} style={{
                                                                    color: stepThreeStatus.stepThreeStatus === 'completed' ? WtColor.BaseColor : '#fff',
                                                                    marginBottom: "0px", paddingBottom: "0px", paddingLeft: "10px", paddingTop: i === 0 ? "6px" : "0px"
                                                                }}>|</p>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </Col>
                                            <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                                                <div>
                                                    <div className="mt-3 mb-1 ps-2" style={{ display: "flex" }}>
                                                        <div style={{
                                                            color: stepTwoStatus.stepTwoStatus === 'disabled' ? WtColor.CloudyGrey : stepTwoStatus.stepTwoStatus === 'active' ? WtColor.TextDarkColor : WtColor.BaseColor,
                                                            flex: 1, fontSize: "16px", fontWeight: "500"
                                                        }}>Message</div>
                                                        <div>
                                                            <Tooltip title="Select Template" placement="left"><InfoOutlined className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px", }} /></Tooltip>
                                                        </div>
                                                    </div>

                                                    <div
                                                        onClick={() => handleResetDialog()}
                                                        className={(stepTwoStatus.stepTwoStatus === 'disabled') ? 'input-div input-div-disabled' : (stepTwoStatus.stepTwoStatus === 'active') ? 'input-div input-div-active' : 'input-div input-div-completed'}>
                                                        <p className='input-text'>{templateName.templateName}</p>
                                                        <KeyboardArrowRightOutlined style={{ fill: WtColor.CloudyGrey }} />
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>


                                        <Row style={{ pointerEvents: stepThreeStatus.stepThreeStatus === 'disabled' ? 'none' : 'all' }}>
                                            <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                                                <Avatar style={{
                                                    background: stepThreeStatus.stepThreeStatus === 'completed' ? WtColor.BaseColor : WtColor.LineColor,
                                                    color: stepThreeStatus.stepThreeStatus === 'disabled' ? WtColor.TextLightGrey : stepThreeStatus.stepThreeStatus === 'active' ? WtColor.TextDarkColor : "white",
                                                    width: "27px", height: "27px", marginTop: "15px", fontSize: "15px", fontWeight: 'bold'
                                                }}>3</Avatar>
                                            </Col>
                                            <Col xxl={11} xl={11} lg={11} md={11} sm={11} xs={11}>
                                                <div>
                                                    <div className="mt-3 mb-1 ps-2" style={{ display: "flex" }}>
                                                        <div style={{
                                                            color: stepThreeStatus.stepThreeStatus === 'disabled' ? WtColor.CloudyGrey : stepThreeStatus.stepThreeStatus === 'active' ? WtColor.TextDarkColor : WtColor.BaseColor,
                                                            flex: 1, fontSize: "16px", fontWeight: "500"
                                                        }}>Contact</div>
                                                        <div>
                                                            <Tooltip title="Select Contacts" placement="left"><InfoOutlined className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px", }} /></Tooltip>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div onClick={() => handleUISelection('contact_selection')}
                                                            className={(stepThreeStatus.stepThreeStatus === 'disabled') ? 'input-div input-div-disabled' : (stepThreeStatus.stepThreeStatus === 'active') ? 'input-div input-div-active' : 'input-div input-div-completed'}>
                                                            <p className='input-text'>{contactCount.contactCount}</p>
                                                            <KeyboardArrowRightOutlined style={{ fill: WtColor.CloudyGrey }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            )
                        }
                        // End of Create Campaign

                        // Add Import CSV UI
                        if (mainBroadcastUI.mainBroadcastUI === "contact_selection") {
                            return (
                                <div className="pt-2">
                                    <Tabs defaultActiveKey="import_new_contacts" onSelect={(e) => handleContactSelectionTab.handleContactSelectionTab(e)}>
                                        <Tab eventKey="import_new_contacts" title="Import New Contacts">
                                            <ImportContact />
                                        </Tab>
                                        <Tab eventKey="select_your_contacts" title="Select Contacts">
                                            <SelectContacts />
                                        </Tab>
                                    </Tabs>
                                </div>
                            )
                        }
                        // End of Add Import CSV UI

                        // Add Contact List UI
                        if (mainBroadcastUI.mainBroadcastUI === "contact_list") { return (<ContactList />) }
                        // End of Add Contact List UI
                    })()
                }
            </div>
            <div className="broadcast_dialogs_area">
                {openMultiPurposeDialog.open && <MultiPurposeDialog open={openMultiPurposeDialog.open} reqData={openMultiPurposeDialog.reqData} varName={templateVaribalesCallback} wholeTemplate={wholeTemplateCallback} mediaInfo={templateMediaInfocallback} purposeVal={openMultiPurposeDialog.purposeVal} demandVal={openMultiPurposeDialog.demandVal} onClose={openMultiPurposeDialog.onClose} />}
                {confirmationDia.confirmationDia && <ConfirmDialogBox openDialog={confirmationDia.confirmationDia} closeDialog={closeDialogCallBack} resetConfirmation={resetConfirmationCallback} />}
            </div>
        </section>
    )
}

export default CreateCampaign

//Confirmation Dialoge Box
export const ConfirmDialogBox = ({ openDialog, closeDialog, resetConfirmation }) => {
    const handleClose = () => { closeDialog(false) }
    const handleYes = () => { resetConfirmation(true) }
    return (
        <>
            <Dialog open={openDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title" style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ fontSize: '1rem' }}>
                        <p>
                            If you click 'Yes' your all data will reset, Are you sure ?
                        </p>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='confirm-button' style={{ display: 'flex', flexDirection: 'row' }}>
                            <button className="btn btn-md theme_grey01  w-50" onClick={() => handleYes()}>Yes</button>
                            <button className="btn btn-md theme_grey01  w-50" onClick={() => handleClose()}>No</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </>
    )
}