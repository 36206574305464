import React from 'react'
import './index.css'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { WtTheme } from '../../controller/theme'
import { Client } from '../home/ui'
import { Link, useHistory } from 'react-router-dom'
import { Testimonial } from './ui'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'


// Chatbot Page UI
export const Chatbots = () => {
    return (
        <div className="">
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h1>
                                Start your digital transformation journey with <b>AI chatbots</b> to deliver automated and personalised experience
                            </h1>
                            <p className="text-muted mt-5">
                                We at Whatstool help lead your business’s customer service<br />journey through all key touchpoints. Engage your customers with<br />24x7 Chatbot support and grow your business widely.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center">
                                <img src={WtTheme.ImageBaseUrl + "/wtb_05.png"} style={{ width: '70%' }} className="img-fluid" alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h3 className="theme">Generate leads 24x7 and Grow<br />your business with Chatbots</h3>
                            <p className="text-muted mt-5">
                                Be available to your customers around the clock, never miss out on<br />a lead again! Our dashboard ensures customer conversations are<br />accounted for through Assignee, @Mentions, and Tagging.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center p-5">
                                <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_02.png"} className="img-fluid" alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients pe-3 ps-3" fluid>
                <Container className="mt-5 mb-5 pb-5">
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div>
                                <p className="text-center p-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_03.png"} className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="mt-5">
                                <h3 className="theme">Build your Bot within a matter of<br />minutes. No codes, just<br />conversational flow.</h3>
                                <p className="text-muted mt-5">
                                    Setup your Chatbot to interact with users just like any agent would.<br /> Create, test, and run your no-code Chatbot. Set a standard with<br />the quality of customer support, engagement, and interaction.
                                </p>
                                <p className="mt-5"><Link to={WtUrl.signupUrl} className="btn btn-md ps-5 pe-5 pt-2 pb-2 text-white fw-bold" style={{ background: "#128C7E" }}>Start Building</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <h5 className="fw-bold mt-5 mb-5 text-center">BENEFITS OF WHATSAPP CHATBOT</h5>
                <Container className="pe-5 ps-5">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_01.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Effectively and instantly<br />interact with clients</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_02.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Expand the company’s<br />availability and reach<br />&nbsp;</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_03.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Support customers by<br />responding to queries in<br />real-time</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_04.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Send contexual<br />responses automatically</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_05.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Personalize customer’s<br />experience</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_06.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Free up personnel to<br />solve more complex requests</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_07.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Make customer communications secure<br />by end-to-end encryption</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_08.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Shorten the sales<br />cycle<br />&nbsp;</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/wtx_chatbot_benifits_09.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Achieve better brand<br />recognition<br />&nbsp;</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}
// End of Chatbot Page UI

// MultiAgent Page UI
export const MultiAgent = () => {
    return (
        <div className="">
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h1>
                                Multi-Agent Shared Workspace For Teams To Collaborate
                            </h1>
                            <p className="text-muted mt-5">
                                Have multiple attendants in a single number. A handy tool for sales and customer support
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center">
                                <img src={WtTheme.ImageBaseUrl + "/wtb_03.png"} className="img-fluid shadow-lg rounded-3" alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h3 className="theme">Get close to your customers at WhatsApp</h3>
                            <p className="text-muted mt-5">
                                Customers want only one thing: quick and very simple contact with you. Effectively serve your customers with online chat: Unlimited messaging and multimedia, chat history and unlimited staff.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center p-5">
                                <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_001.png"} className="img-fluid" alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients pe-3 ps-3" fluid>
                <Container className="mt-5 mb-5 pb-5">
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div>
                                <p className="pt-5 pb-5 pe-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_002.png"} className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="mt-5">
                                <h3 className="theme">Optimize your company's WhatsApp service</h3>
                                <p className="text-muted mt-5">
                                    Keep several conversations under control: open a separate tab for each conversation. Take total control of your customer interactions - share a single WhatsApp account across multiple devices at the same time
                                </p>
                                <p className="mt-5"><Link to={WtUrl.signupUrl} className="btn btn-md ps-5 pe-5 pt-2 pb-2 text-white fw-bold" style={{ background: "#128C7E" }}>Get Started</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <h5 className="fw-bold mt-5 mb-5 text-center">BENEFITS OF MULTIPLE CHAT SUPPORT</h5>
                <Container className="pe-5 ps-5">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_001.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">More<br />Conversation</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_002.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Faster<br />Response</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_003.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Cost<br />Effectiveness</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_004.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Competitive<br />Advantage</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_005.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Greater Customer<br />Retention</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_006.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Increased<br />Sales</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WT_MultiAgent_Benefits_007.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Convenient for<br />Customers</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}
// End of MultiAgent Page UI

// BroadcastMessage Page UI
export const BroadcastMsg = () => {
    return (
        <div className="">
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row style={{ marginTop: "100px" }}>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="mt-5">
                            <h1>
                                Broadcast Promotional Messages (Officially)
                            </h1>
                            <p className="text-muted mt-5">
                                Well, WhatsApp recently allowed Businesses to Broadcast and Automate Promotional Messages via Whatsapp Business API<br />
                                You can Now Send Exciting Offers, Discount Coupon Code and Festival Wishes without getting your Number blocked!
                            </p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <p className="float-end">
                                <img src={WtTheme.ImageBaseUrl + "/wtb_broadcast_001.png"} className="img-fluid" width="400px" alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h3 className="theme">Broadcast Messages in a Few Clicks</h3>
                            <p className="text-muted mt-5">
                                Import contacts with labels and additional fields to create segments, schedule broadcasting lists and view real-time analytics all with a few clicks.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center p-5">
                                <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_002.png"} className="img-fluid" width="100%" alt={WtTheme.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients pe-3 ps-3" fluid>
                <Container className="mt-5 mb-5 pb-5">
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div>
                                <p className="pt-5 pb-5 pe-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_003.png"} className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="mt-5 pt-5">
                                <h3 className="theme">Set up segmented campaigns based on custom fields.</h3>
                                <p className="text-muted mt-5">
                                    Leverage the power of WhatsApp Marketing by starting a WhatsApp Broadcast for up to 100k recipients in a single click.
                                </p>
                                <p className="mt-5 pt-5"><Link to={WtUrl.signupUrl} className="btn btn-md ps-5 pe-5 pt-2 pb-2 text-white fw-bold" style={{ background: "#128C7E" }}>Get Started</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <h5 className="fw-bold mt-5 mb-5 text-center">BENEFITS OF BROADCAST MESSAGE</h5>
                <Container className="pe-5 ps-5">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_Benefits_001.png"} width="230px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Wider Target<br />Reach</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_Benefits_002.png"} width="230px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Better Connect<br />with Customer</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_Benefits_003.png"} width="230px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Cost<br />Effectiveness</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_Benefits_004.png"} width="230px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Up-Sell and<br />Cross-sell products</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_Benefits_005.png"} width="230px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Personilsed<br />Offers</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={WtTheme.ImageBaseUrl + "/WTX_Broadcast_Benefits_006.png"} width="240px" className="img-fluid" alt={WtTheme.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Schedule New<br />Compaigns</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </Container>
        </div>
    )
}
// End of BroadcastMessage Page UI

// Quick Guide Page UI
export const QuickGuide = () => {
    const FeaturesData = [
        { "t": "Free Whatsapp Business API", "d": "Get Whatsapp Business API Approval within 2 days (Free with any plan)", "img": WtTheme.ImageBaseUrl + "/WBApi.svg" },
        { "t": "Multi Agent Live Chat", "d": "Enable Multiple Human-Agent Live Chat Support on the same Whatsapp Number", "img": WtTheme.ImageBaseUrl + "/MultiAgent.svg" },
        { "t": "Broadcast Unlimited Campaigns", "d": "Broadcast Messages up to 1,00,000 users in one day", "img": WtTheme.ImageBaseUrl + "/BroadcastIcon.svg" },
        { "t": "Connect Your APIs", "d": "Easily Connect with Shopify, Salesforce, WooCommerce, Zapier, Payment Portals, CRMs & more", "img": WtTheme.ImageBaseUrl + "/API.svg" },
        { "t": "Connect no Code A.I. Chatbots", "d": "Connect No-Code Chatbots from Google Dialogflow in a Single Click", "img": WtTheme.ImageBaseUrl + "/Chatbots.svg" },
        { "t": "Import and Export Contacts", "d": "Import Opted-In Contacts and Broadcast Messages seamlessly", "img": WtTheme.ImageBaseUrl + "/ImportExport.svg" },
        { "t": "Template Messages Approvals", "d": "Quick Fast Template Message Approval Process (2-3 Days) via Interactive Dashboard", "img": WtTheme.ImageBaseUrl + "/ApprovalMsg.svg" },
        { "t": "Send Personalised Messages", "d": "Use User attributes effectively to send personalised campaigns", "img": WtTheme.ImageBaseUrl + "/PersonalizeMessage.svg" },
        { "t": "Chat CRM Included", "d": "Manage Contacts & Create segregated audience using various tags, attributes & more", "img": WtTheme.ImageBaseUrl + "/CRM.svg" }
    ]
    return (
        <Container className="mt-5 mb-5 pe-3 ps-3">
            <p className="mt-5 fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>Powerful  Features that you need</p>
            <p className="fw-bold" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>All Plans of WhatsTool Business are Inclusive of these features</p>
            <div className="features mt-4 qg_one">
                <Row>
                    {
                        FeaturesData.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2 p-3 hand" style={{ height: "auto" }}>
                                    <div className="rounded-3 p-4 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                        <div style={{ display: "flex" }}>
                                            <p className="fw-bold" style={{ fontSize: "18px", width: "75%" }}>{v.t}</p>
                                            <p className="float-end"><img src={v.img} alt={WtTheme.SiteName} /></p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px" }}>{v.d} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <p className="mt-5 fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>Why Choose Whatsapp?</p>
            <p className="fw-bold" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>Whatsapp is the One Platform that brings together Actionable Notifications &amp; Customer Support!</p>
            <div className="pt-5 mt-5 pb-5 rounded-3 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                <Row>
                    <Col>
                        <div>
                            <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/OpenRate.svg"} alt={WtTheme.SiteName} /></p>
                            <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor }}>95% Open Rates</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/ClickRate.svg"} alt={WtTheme.SiteName} /></p>
                            <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor }}>45-60% Click Rates</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/2BnUser.svg"} alt={WtTheme.SiteName} /></p>
                            <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor }}>2 Bn+ Users</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <p className="text-center mt-4 "><Link to={WtUrl.signupUrl}><button className="btn btn-sm theme_bg text-white mb-5 pt-3 pb-3 ps-5 pe-5 bRound" style={{ fontSize: "20px" }}>Get Started Now</button></Link></p>

            <p className="mt-5 fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>Experience Whatsapp Smart Notifications Now</p>
            <p className="fw-bold" style={{ color: WtColor.TextMidGrey, fontSize: "12px" }}>Which Notification would you like to Experience? Choose one &amp; we will send it instantly on your Whatsapp</p>
            <div className="pb-5 mt-5 qg_two">
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div >
                                <div className="p-3">
                                    <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/Cart.svg"} alt={WtTheme.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px" }} className="text-center theme_bg text-white p-2">Abandoned Cart Recovery</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/OrderCart.svg"} alt={WtTheme.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px" }} className="text-center theme_bg text-white p-2">Order Confirmation</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/Feedback.svg"} alt={WtTheme.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px" }} className="text-center theme_bg text-white p-2">Feedback Offer Loop</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/Product.svg"} alt={WtTheme.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px" }} className="text-center theme_bg text-white p-2">Product Details</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/Course.svg"} alt={WtTheme.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px" }} className="text-center theme_bg text-white p-2">Course Application Reminder</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/Event.svg"} alt={WtTheme.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px" }} className="text-center theme_bg text-white p-2">Event Reminder</p>
                        </div>
                    </Col>
                </Row >
            </div>

            <p className="mt-5 fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>Get WhatsApp Business API in 10 Minutes</p>
            <div className="qg_three">
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="p-5 images">
                            <img src={WtTheme.ImageBaseUrl + "/GreenTick.png"} className="img-fluid" alt={WtTheme.SiteName} />
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="p-5 text">
                            <p>WhatsTool Perform is powered by Official Whatsapp Business APIs and is in alignment with all Whatsapp Rules.</p>
                            <p>Get Verified Green Tick on your Whatsapp Broadcast upto 100,000 Notifications 24*7 Chat Support by WhatsTool Team</p>
                            <p className="mt-4 "><Link to={WtUrl.signupUrl}><button className="btn btn-sm theme_bg text-white mb-5 pt-1 pb-1 ps-3 pe-3 bRound" style={{ fontSize: "15px" }}>Apply Now</button></Link></p>
                        </div>
                    </Col>
                </Row>
            </div>


            <p className="text-center fw-bold mt-5 pt-5" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>Official WhatsApp API Key-Points</p>
            <div className="key-points mt-5 hand">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div className="theme_bg p-3 text-white fw-bold">
                                <ul>
                                    <li>Pre-requisites for Official WhatsAPP</li>
                                    <li>Documents and other requirements to get an Official WhatsApp API account created.</li>
                                </ul>
                            </div>
                            <div className="bg-white p-4" style={{ lineHeight: "2.15" }}>
                                <p>You will need the following to get started:</p>
                                <ol>
                                    <li>Email,</li>
                                    <li>Live website URL,</li>
                                    <li>Facebook account,</li>
                                    <li>A mobile number that has never been previously used on WhatsApp,</li>
                                    <li>Company formation document available in your country,</li>
                                    <li>Company address proof document such as tax certificate etc.</li>
                                </ol>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div>
                            <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="theme_bg p-3 text-white fw-bold">
                                    <ul>
                                        <li>Can I send any kind of message using official WhatsApp account?</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>You can send any kind of image, video, document message with official WhatsApp account, but please do note that you need to get the message template approved from WhatsApp first using our panel. Template approval takes around 5 mins to up to 24 hours duration.</p>
                                </div>
                            </div>
                            <div className="mt-2 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="theme_bg p-3 text-white fw-bold">
                                    <ul>
                                        <li>Set-up Charge</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>Completely free</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                        <div>
                            <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="theme_bg p-3 text-white fw-bold">
                                    <ul>
                                        <li>Verified Green Tick</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>Yes. But green-tick verification is subject to Facebook policies. Check your business eligibility for Green-tick verification here.</p>
                                </div>
                            </div>
                            <div className="mt-2 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="theme_bg p-3 text-white fw-bold">
                                    <ul>
                                        <li>Banning of Numbers</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>No, the number will not get banned unless reported or blocked by a significantly large number of users for several consecutive days.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div className="theme_bg p-3 text-white fw-bold">
                                <ul>
                                    <li>Number of WhatsApp messages per day</li>
                                </ul>
                            </div>
                            <div className="bg-white p-4" style={{ lineHeight: "2.15" }}>
                                <p className="text-center" >
                                    <img src={WtTheme.ImageBaseUrl + "/quickGuide_01.png"} alt={WtTheme.SiteName} style={{ height: "150px" }} className="img-fluid w-75" />
                                </p>
                            </div>
                        </div>
                        <Row className="mt-3">
                            <Col>
                                <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                    <div className="theme_bg p-3 text-white fw-bold">
                                        <ul>
                                            <li>Set-up Time</li>
                                        </ul>
                                    </div>
                                    <div className="bg-white p-3">
                                        <p>Application is processed immediately. Approval from Facebook may take 1-5 days.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                    <div className="theme_bg p-3 text-white fw-bold">
                                        <ul>
                                            <li>How it works</li>
                                        </ul>
                                    </div>
                                    <div className="bg-white p-3">
                                        <p>Use Contact Management and Campaign Management to send bulk messages.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div className="theme_bg p-3 text-white fw-bold">
                                <ul>
                                    <li>Per message charge</li>
                                    <li>Meta, parent company of WhatsApp charges message cost and it needs to be paid to them.</li>
                                </ul>
                            </div>
                            <div className="bg-white p-4" style={{ lineHeight: "2.15" }}>
                                <Table className="fw-bold" responsive>
                                    <thead style={{ background: WtColor.CloudyGrey, color: WtColor.TextDarkColor }}>
                                        <tr>
                                            <th>Country</th>
                                            <th>Business-initiated Rate</th>
                                            <th>User-initiated Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                                        <tr>
                                            <td>India</td>
                                            <td>₹ 0.4843</td>
                                            <td>₹ 0.2906</td>
                                        </tr>
                                        <tr>
                                            <td>USA</td>
                                            <td>₹ 1.0768</td>
                                            <td>₹ 0.6461</td>
                                        </tr>
                                        <tr>
                                            <td>Indonesia</td>
                                            <td>₹ 2.3151</td>
                                            <td>₹ 1.3890</td>
                                        </tr>
                                        <tr>
                                            <td>Brazil</td>
                                            <td>₹ 3.6646</td>
                                            <td>₹ 2.1987</td>
                                        </tr>
                                        <tr>
                                            <td>Malaysia</td>
                                            <td>₹ 5.3655</td>
                                            <td>₹ 1.6096</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='mt-5'>
                <h3 style={{ color: WtColor.TextDarkColor, marginTop: "100px" }}>360Dialog vs WhatsApp Cloud API</h3>
                <Table className="fw-bold w-75" responsive>
                    <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }}>
                        <tr>
                            <th>360Dialog</th>
                            <th>WhatsApp Cloud API</th>
                        </tr>
                    </thead>
                    <tbody style={{ background: "#fff", fontSize: "14px" }}>
                        <tr>
                            <td>Mandatory to Recharge after 1000 message in all plan except in Free plan (after 500 message)</td>
                            <td>Mandatory to Recharge after 1000 message in all plan except in Free plan (after 500 message)</td>
                        </tr>
                        <tr>
                            <td>Security Deposit of Rs 2000 is mandatory at the start of plan. It will be refunded at the end if you inform about your removal of account before 60 days.</td>
                            <td>No security Deposit Required</td>
                        </tr>
                        <tr>
                            <td>Recharge will include WhatsApp charges. For  Example for Recharge of Rs1000 user will get 1800 BIC = 1700x0.49(BIC) + 0.10 (WTB platform free)x1700</td>
                            <td>Recharge will not include WhatsApp charges & will only be our platform fee.<br />For Example for Recharge of Rs1000 user will be able to send 10,000 messages because we will only charge platform free</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Container >
    )
}
// End of Quick Guide Page UI








// Educational Industry Sector UI by Himanshu
export const EducationalSector = () => {
    return (
        <>
            <EducationSectorInfo />
            <Container className="clients" fluid><Client /></Container>
            <EducationSectorChatbot />
            <EducationSectorChat />
            <EducationSectorBroadcast />
            <Container><Testimonial /></Container>
            <EducationSectorBusinessGrowth />
        </>)
}
export const EducationSectorInfo = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div className="him_ram">
            <Container>
                <Row>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >

                        <h2 className="mt-5 him_fontsize">WhatsTool Business for Educational Institutions</h2>
                        <p className="mt-5 him_onboard">From Admit, Onboard, Engage, Give Updates, Solve Queries to Customer Support it helps Educational Institutions improve Admission Rates</p>
                        <p className="mt-5">
                            <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="ps-5 pe-5 pt-2 pb-2 mt-5 ms-3 text-white">Get Started</button>
                        </p>

                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                        <p className="mt-3 text-center">
                            <img src={WtUrl.imageBaseUrl + '/edu_01.png'} alt="" className="img-fluid " />
                        </p>

                    </Col>
                </Row>
            </Container>
        </div>

    );
}
export const EducationSectorChatbot = () => {
    return (
        <div>
            <Container>
                <Row>
                    <h3 className="text-center mt-5 mb-5" style={{ color: "#00A82D" }}>Use Chatbot to Make your work easy and simple </h3>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="him_box1 rounded-3 p-4 mt-5 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/edu_icon_01.png'} alt="Group 1784" className="img-fluid pt-2" width={90} />
                            </p>
                            <p className="fw-bold him_f-size mt-2">Design your chatbots to provide information from the first to the last step of student admission.</p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/edu_icon_02.png'} alt="Group 1784" className="img-fluid pt-2" width={90} />
                            </p>
                            <p className="fw-bold him_f-size mt-2">Chatbot Work 24/7 to solve queries from parents and students and do student support. </p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/edu_icon_03.png'} alt="Group 1784" className="img-fluid pt-3" width={110} />
                            </p>
                            <p className="fw-bold him_f-size mt-2">Provide information Instantly and complete the onboarding formalities by providing a step-by-step guide.</p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/edu_icon_04.png'} alt="Group 1784" className="img-fluid pt-1" width={100} />
                            </p>
                            <p className="fw-bold him_f-size mt-2">Send timely class reminders, schedules, notifications to students on their registered numbers. </p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/edu_icon_05.png'} alt="Group 1784" className="img-fluid pt-3" width={160} />
                            </p>
                            <p className="fw-bold him_f-size mt-2">With Multi-Lingual Chatbots Converse with prospective students and make them feel at home with 30+ language options to choose from.</p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <p className="float-end" style={{ marginTop: "50px" }}>
                            <img src={WtUrl.imageBaseUrl + '/edu_02.png'} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Row>
            </Container>


        </div>
    );
}
export const EducationSectorChat = () => {
    return (
        <div>
            <Container fluid className="him_backcolor">
                <Container>
                    <h3 className="text-center mt-5 pt-5 pb-5 him_studentstyle">Use Team Chat for student support by assigning people for that particular task </h3>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                        <p className="him_imagestyle">
                            <img src={WtUrl.imageBaseUrl + '/edu_03.png'} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Container>
            </Container>
        </div>
    );
}
export const EducationSectorBroadcast = () => {
    return (
        <div>
            <Container fluid className="pt-5 pb-5">
                <Container>
                    <h1 className="text-center mb-5 him_Broadcast">Use Broadcast to send messages to 1000+ students at a time</h1>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <p className="mt-5 text-center">
                                <img src={WtUrl.imageBaseUrl + '/edu_04.png'} alt="Group 1864" width=
                                    {1017} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
export const EducationSectorBusinessGrowth = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container className="him_color pt-5 pb-5" fluid>
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <h3 className="text-center fw-bold pb-5 him_mediaa">Use WhatsTool Business as a solution for your Business Growth</h3>
                            <p className="text-center him_text-color">Provide 24*7 students support and engage with the students without any human effort and increase your sales</p>

                            <h6 className="text-center him_zero-color pt-4">You can do it with zero cost</h6>
                            <p className="text-center">
                                <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 text-white">Get Started</button>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
// End of Educational Industry Sector UI by Himanshu



// ECommerce Industry Sector UI by Himanshu
export const ECommerceSector = () => {
    return (
        <>
            <ECommerceSectorInfo />
            <Container className="clients" fluid><Client /></Container>
            <ECommerceSectorSection1 />
            <ECommerceSectorSection2 />
            <ECommerceSectorSection3 />
            <ECommerceSectorSection4 />
            <ECommerceSectorSection5 />
            <ECommerceSectorChat />
            <ECommerceSectorBroadcast />
            <Container><Testimonial /></Container>
            <ECommerceSectorBusinessGrowth />
        </>
    )
}
export const ECommerceSectorInfo = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container>
                <Row>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <h2 className="mt-5 him_size">WhatsTool Business for<br />E-commerce</h2>
                        <p className="mt-5 him_provide">Promote Your products, share catalogs, receive orders online and provide customer support 24/7</p>
                        <p className="mt-5">
                            <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="ps-5 pe-5 pt-2 pb-2 mt-5 ms-3 text-white">Get Started</button>
                        </p>
                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} col={12} >
                        <p className="mt-4 text-center him_pading">
                            <img src={WtUrl.imageBaseUrl + '/ecom_01.png'} alt="" className="img-fluid" width={400} />
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export const ECommerceSectorSection1 = () => {
    return (
        <div>
            <Container>
                <Row className="him_div">
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="him_medis">
                        <div className="him_mtop">
                            <h3>Take Orders on WhatsApp</h3>
                            <h6 className="pt-3 him_colorr">Allow your customers to view your shop catalog, place orders, track shipments, cancel and edit items all from WhatsApp only.</h6>
                        </div>
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <p className="text-center him_pading">
                            <img src={WtUrl.imageBaseUrl + '/ecom_02.png'} alt="whatstool business" className="img-fluid" width={408} />
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export const ECommerceSectorSection2 = () => {
    return (
        <div>
            <Container fluid className="him_bgclass">
                <Container>
                    <Row >
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <p className="text-center me-5 mt-5 mb-5">
                                <img src={WtUrl.imageBaseUrl + '/ecom_03.png'} alt="whatstool business" className="img-fluid" width={505} />
                            </p>

                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="him_medis">
                            <div className="him_mtop">
                                <h3>Recover Abandoned Carts on WhatsApp</h3>
                                <h6 className="pt-3 him_colorr">Many times user will add the products to the cart and forget to order. Send a reminder on whatsApp in which through a single click it takes them to the checkout page.</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
export const ECommerceSectorSection3 = () => {
    return (
        <div>
            <Container>
                <Row className="pb-5 pt-3">
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="him_medis">
                        <div className="him_mtop">
                            <h3>Customer Support on WhatsApp</h3>
                            <h6 className="pt-3 him_colorr">Provide 24*7 customer support on WhatsApp without any human dependancy and without any delay in the service</h6>
                        </div>
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <p className="text-center him_pading">
                            <img src={WtUrl.imageBaseUrl + '/ecom_04.png'} alt="whatstool business" className="img-fluid" width={386} />
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export const ECommerceSectorSection4 = () => {
    return (
        <div>
            <Container fluid className="him_bgclass">
                <Container>
                    <Row className="pb-5 pt-5">
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <p className="text-center me-5 mt-5 mb-5">
                                <img src={WtUrl.imageBaseUrl + '/ecom_05.png'} alt="whatstool business" className="img-fluid" width={389} />
                            </p>

                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="him_medis">
                            <div className="him_mtop">
                                <h3>Collect Ratings & Reviews on WhatsApp</h3>
                                <h6 className="pt-3 him_colorr">Use WhatsApp to collect reviews, ratings and feedback personally</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </div>
    );
}
export const ECommerceSectorSection5 = () => {
    return (
        <div>
            <Container>
                <Row className="mb-5 mt-3">
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="him_medis">
                        <div className="him_mtop">
                            <h3>Green Tick Mark</h3>
                            <h6 className="pt-3 him_colorr">Get green tick for your business in WhatsApp, make your business official and allow your customer to trust you more </h6>
                        </div>
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <p className="text-center him_pading">
                            <img src={WtUrl.imageBaseUrl + '/ecom_06.png'} alt="whatstool business" className="img-fluid" width={505} />
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export const ECommerceSectorChat = () => {
    return (
        <div>
            <Container fluid className="him_bgcolor">
                <Container>
                    <h4 className="text-center mt-5 pt-5 pb-5 him_usestyle">Use Team Chat for patient support by assigning people for that particular task </h4>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                        <p className="him_picturestyle">
                            <img src={WtUrl.imageBaseUrl + '/ecom_07.png'} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Container>
            </Container>

        </div>
    );
}
export const ECommerceSectorBroadcast = () => {
    return (
        <div>
            <Container fluid className="pt-5 pb-5">
                <Container>
                    <h1 className="text-center mb-5 him_Broadcast">Use Broadcast to send messages to 1000+ students at a time</h1>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} col={12} >
                            <p className="mt-5 text-center">
                                <img src={WtUrl.imageBaseUrl + '/ecom_08.png'} alt="Group 1864" width={1017} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
export const ECommerceSectorBusinessGrowth = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container fluid className="him_color pt-5 pb-5">
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <h3 className="text-center fw-bold pb-5 him_mediaa">Use WhatsTool Business as a solution for your Business Growth</h3>
                            <p className="text-center him_text-color">Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</p>

                            <h6 className="text-center him_zero-color pt-4">You can do it with zero cost</h6>
                            <p className="text-center">
                                <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 ms-3 text-white" >Get Started</button>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
// End of ECommerce Industry Sector UI by Himanshu



// Health Industry Sector UI by Himanshu
export const HealthSector = () => {
    return (
        <>
            <HealthSectorInfo />
            <Container className="clients" fluid><Client /></Container>
            <HealthSectorChatbot />
            <HealthSectorChat />
            <HealthSectorBroadcast />
            <Container><Testimonial /></Container>
            <HealthSectorBusinessGrowth />
        </>
    )
}
export const HealthSectorInfo = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container>
                <Row>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >

                        <h2 className="mt-5 him_fontsize">WhatsTool Business for Healthcare & Medicals</h2>
                        <p className="mt-5 him_extra">Go the extra mile by providing your patients and customers the ability to reach out to you 24/7 and at every step of their journey</p>
                        <p className="mt-5">
                            <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="ps-5 pe-5 pt-2 pb-2 mt-5 ms-3 text-white" >Get Started</button>
                        </p>

                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                        <p className="text-center mt-4">
                            <img src={WtUrl.imageBaseUrl + '/hel_01.png'} alt="" width={420} className="img-fluid" />
                        </p>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export const HealthSectorChatbot = () => {
    return (
        <div>
            <Container>
                <Row>
                    <h2 className="text-center mt-5 mb-5" style={{ color: "#00A82D" }}>Use Chatbot to Make your work easy and simple</h2>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="him_box2 rounded-3 p-4 mt-5 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/hel_icon_01.png'} alt="Group 1784" className="img-fluid pt-2" width={60} />
                            </p>

                            <p className="fw-bold him_font-size mt-3">Get Automated Appointment Booking from the patients.
                            </p>

                        </div>

                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/hel_icon_02.png'} alt="Group 1784" className="img-fluid pt-2 him_mediawidth" width={60} />
                            </p>

                            <p className="fw-bold him_font-size mt-4">Appointment Reminders for each day.</p>

                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4 ">
                                <img src={WtUrl.imageBaseUrl + '/hel_icon_03.png'} alt="Group 1784" className="img-fluid pt-2" width={60} />
                            </p>

                            <p className="fw-bold him_font-size mt-4">Get Notification of Samples & Report
                                Collection. </p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/hel_icon_04.png'} alt="Group 1784" className="img-fluid pt-3" width={80} />
                            </p>

                            <p className="fw-bold him_font-size mt-2">Follow Up Post Consultation and be in touch with your patients regarding their treatments. </p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/hel_icon_05.png'} alt="Group 1784" className="img-fluid pt-3" width={90} />
                            </p>

                            <p className="fw-bold him_font-size mt-2">Follow Up Post Consultation and be in touch with your patients regarding their treatments.</p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/hel_icon_06.png'} alt="Group 1784" className="img-fluid pt-3" width={200} />
                            </p>

                            <p className="fw-bold him_font-size mt-2">With Multi-Lingual Chatbots Converse with prospective customer and make them feel easy to plan their vacation in the language they know with 30+ language options to choose from.</p>
                        </div>


                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <p style={{ marginTop: "170px", marginLeft: "80px" }}>
                            <img src={WtUrl.imageBaseUrl + '/hel_02.png'} alt="" className="img-fluid" />
                        </p>

                    </Col>
                </Row>
            </Container>

        </div>
    );
}
export const HealthSectorChat = () => {
    return (
        <div>

            <Container fluid className="him_bgcolor">
                <Container>
                    <h3 className="text-center mt-5 pt-5 pb-5 him_patientstyle">Use Team Chat for patient support by assigning people for that particular task   </h3>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                        <p className="him_imgstyle">
                            <img src={WtUrl.imageBaseUrl + '/hel_03.png'} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Container>
            </Container>


        </div>
    );
}
export const HealthSectorBroadcast = () => {
    return (
        <div>
            <Container fluid className="pt-5 pb-5">
                <Container>
                    <h1 className="text-center mb-5 him_Broadcast">Use Broadcast to send messages to 1000+ people at a time</h1>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} col={12} >
                            <p className="mt-5 text-center">
                                <img src={WtUrl.imageBaseUrl + '/hel_04.png'} alt="Group 1864" width=
                                    {1017} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
export const HealthSectorBusinessGrowth = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container fluid className="him_color pt-5 pb-5">
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <h3 className="text-center fw-bold pb-5 him_mediaa">Use WhatsTool Business as a solution for your Business Growth</h3>
                            <p className="text-center him_text-color">Provide 24*7 patient customer support and engage with the patients without any human effort and increase your business</p>

                            <h6 className="text-center him_zero-color pt-4">You can do it with zero cost</h6>
                            <p className="text-center">
                                <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 text-white">Get Started</button>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
// End of Health Industry Sector UI by Himanshu



// Tour Travel Industry Sector UI by Himanshu
export const TourTravelSector = () => {
    return (
        <>
            <TourTravelSectorInfo />
            <Container className="clients" fluid><Client /></Container>
            <TourTravelSectorChatbot />
            <TourTravelSectorChat />
            <TourTravelSectorBroadcast />
            <Container><Testimonial /></Container>
            <TourTravelSectorBusinessGrowth />
        </>
    )
}
export const TourTravelSectorInfo = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container>
                <Row>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                        <h2 className="mt-5 him_fontsize">WhatsTool Business for Tours & Travels</h2>
                        <p className="mt-5 him_plan">Assist, Coordinate, Recommend & Plan for your customers journey and make it a perfect trip by providing customer trip 24/7</p>
                        <p className="mt-5">
                            <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="ps-5 pe-5 pt-2 pb-2 mt-5 ms-3 text-white" >Get Started</button>
                        </p>

                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} col={12} >
                        <p className="mt-4 text-center">
                            <img src={WtUrl.imageBaseUrl + '/tt_01.png'} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export const TourTravelSectorChatbot = () => {
    return (
        <div>
            <Container>
                <Row>
                    <h2 className="text-center mt-5 mb-5" style={{ color: "#00A82D" }}>Use Chatbot to Make your work easy and simple</h2>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} col={12}>
                        <div className="him_box3 rounded-3 p-4 mt-5 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/tt_icon_01.png'} alt="Group 1784" className="img-fluid pt-2" width={50} />
                            </p>

                            <p className="fw-bold him_text-size mt-3">Increase direct booking with 24x7 customer support.
                            </p>

                        </div>

                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/tt_icon_02.png'} alt="" className="img-fluid pt-4" width={100} />
                            </p>

                            <p className="fw-bold him_text-size mt-4">Help the customer in choosing best time for trip, vacation packages, flying facilities, hotel reservations and much more.</p>

                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4 ">
                                <img src={WtUrl.imageBaseUrl + '/tt_icon_03.png'} alt="Group 1784" className="img-fluid pt-4" width={60} />
                            </p>

                            <p className="fw-bold him_text-size mt-4">Automate booking confirmations, payment reminders and other notifications.</p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/tt_icon_04.png'} alt="Group 1784" className="img-fluid pt-3" width={50} />
                            </p>

                            <p className="fw-bold him_text-size mt-2">Secure trip details and payments with integration partners. </p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/tt_icon_05.png'} alt="Group 1784" className="img-fluid pt-3" width={70} />
                            </p>

                            <p className="fw-bold him_text-size mt-2">Provide 24*7 Automated Customer Support without any human dependancy.</p>
                        </div>
                        <div className="him_box1 rounded-3 p-4 mt-3 ">
                            <p className="pe-4">
                                <img src={WtUrl.imageBaseUrl + '/tt_icon_06.png'} alt="Group 1784" className="img-fluid pt-4" width={180} />
                            </p>

                            <p className="fw-bold him_text-size mt-2">With Multi-Lingual Chatbots Converse with prospective customers and make them feel easy to explain their problem in the language they know with 30+ language options to choose from!</p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <p style={{ marginTop: "170px", marginLeft: "80px" }}>
                            <img src={WtUrl.imageBaseUrl + '/tt_02.png'} width={500} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export const TourTravelSectorChat = () => {
    return (
        <div>
            <Container fluid className="him_bgcolor">
                <Container>
                    <h4 className="text-center mt-5 pt-5 pb-5 him_Teamstyle">Use Team Chat for patient support by assigning people for that particular task</h4>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                        <p className="him_picstyle mt-4 text-center">
                            <img src={WtUrl.imageBaseUrl + '/tt_03.png'} alt="" className="img-fluid" />
                        </p>
                    </Col>
                </Container>
            </Container>
        </div>

    );
}
export const TourTravelSectorBroadcast = () => {
    return (
        <div>
            <Container fluid className="pt-5 pb-5">
                <Container>
                    <h1 className="text-center mb-5 him_Broadcast">Use Broadcast to send messages to 1000+ students at a time</h1>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <p className="mt-5 text-center">
                                <img src={WtUrl.imageBaseUrl + '/tt_04.png'} alt="Group 1864" width=
                                    {1017} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
export const TourTravelSectorBusinessGrowth = () => {
    const s1 = { background: "#00A82D", borderRadius: "8px", border: "#00A82D", marginTop: "20px" }
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <div>
            <Container fluid className="him_color pt-5 pb-5">
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <h3 className="text-center fw-bold pb-5 him_mediaa">Use WhatsTool Business as a solution for your Business Growth</h3>
                            <p className="text-center him_text-color">Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</p>

                            <h6 className="text-center him_zero-color pt-4">You can do it with zero cost</h6>
                            <p className="text-center">
                                <button onClick={() => openURL(WtUrl.signupUrl)} style={s1} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 text-white">Get Started</button>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
// End of Tour Travel Industry Sector UI by Himanshu