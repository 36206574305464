import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AddOutlined, AssessmentOutlined, ContentCopyOutlined, PlayCircleOutlineOutlined, PauseCircleOutlineOutlined, Delete } from '@mui/icons-material'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { ShowDateTime, ConfirmDialog, CampaignAnalyticsDialog, DemoDialog } from '../../controller/BaseTool'
import { changeCampaignStatus, duplicateCampaign, deleteCampaign } from '../../controller/APIs'
import { WtTheme } from '../../controller/theme'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'
import { WtString } from '../../constants/strings'
import globalContext from '../../context/global/globalContext'
import { Tooltip } from '@material-ui/core'

export const CampaignList = ({ campaignList, fetchCampaignList }) => {
    const notificationBarStatus = useContext(globalContext)

    // Campaign Started
    const startCampaign = async (id, title, status) => {
        setOpenConfirmDialog({
            open: true, title: 'Whatstool Business', msg: 'Are you sure, you want to ' + ((status === 'processing') ? 'Start' : 'Pause') + ' this Campaign ? ', onClose: async (confirm) => {
                setOpenConfirmDialog(false);
                if (confirm) {
                    try {
                        const data = "campaignId=" + id + "&status=" + status
                        const response = await changeCampaignStatus(data)
                        if (response.status === 202) {
                            await fetchCampaignList()
                            openCampiagnAnalyticsDialogBox(title, id)
                        }
                    } catch (error) { }
                }
            }
        })
    }
    // End of Campaign Started


    // Duplicate Campaign
    const duplicateCampaignList = async (title, id) => {
        setOpenConfirmDialog({
            open: true, title: 'Whatstool Business', msg: 'Are you sure, you want to Create Duplicate of this Campaign?', onClose: async (confirm) => {
                setOpenConfirmDialog(false)
                if (confirm) {
                    try {
                        const data = "title=" + title + "_Copy&campaignId=" + id
                        const response = await duplicateCampaign(data)
                        if (response.status === 200) await fetchCampaignList()
                    } catch (error) { }
                }
            }
        })
    }
    // End of Duplicate Campaign


    // Delete Campaign List
    const deleteCampaignList = async (id) => {
        setOpenConfirmDialog({
            open: true, title: 'Whatstool Business', msg: 'Are you sure, you want to Delete this Campaign?', onClose: async (confirm) => {
                setOpenConfirmDialog(false)
                if (confirm) {
                    try {
                        const data = "campaignId=" + id
                        const response = await deleteCampaign(data)
                        if (response.status === 204) await fetchCampaignList()
                    } catch (error) { }
                }
            }
        })
    }
    // End of Delete Campaign List



    //Campaign Search
    const [searchValue, setSearchValue] = useState('')
    const [searchLs, setSearchLs] = useState(campaignList)
    const searchItems = (sv) => {
        setSearchValue(sv)
        const filteredData = campaignList.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }
    // End of Campaign Search

    const history = useHistory()
    const openURL = (url) => history.push(url)


    // Dialogs Handing Code
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
    const [openCampaignAnalyticsDialog, setOpenCampaignAnalyticsDialog] = useState({ open: false, onClose: null })
    const openCampiagnAnalyticsDialogBox = (title, id) => setOpenCampaignAnalyticsDialog({
        open: true, title: title, id: id, onClose: async (confirm) => {
            setOpenCampaignAnalyticsDialog(false)
            await fetchCampaignList()
        }
    })
    // End of Dialogs Handing Code

    useEffect(() => { setSearchLs(campaignList) }, [campaignList])

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: (confirm) => setOpenDemoDialog(false) })
    return (
        <div className="campaign_ui">
            <div className="ps-3 pt-2 pb-1 pe-5" style={{ display: "flex", borderBottom: '1px solid ' + WtColor.LineColor }}>
                <div style={{ flex: 1, display: 'flex', paddingTop: "4px" }}>
                    <h5 style={{ color: WtColor.TextDarkColor, fontSize: '22px' }}>Campaign</h5>
                    <div style={{ display: "flex", border: "1px solid" + WtColor.LineColor, borderRadius: WtDimen.borderRadius, marginTop: '-3px', marginBottom: '2px' }} className="w-25 ms-5">
                        <img src={WtTheme.ImageBaseUrl + "/search.svg"} style={{ width: '20px', marginLeft: '3px' }} alt={WtString.SiteName} />
                        <input type="search" value={searchValue} maxLength={30} onChange={(e) => searchItems(e.target.value)} className="w-100" style={{ border: "none", outline: "none", paddingLeft: "6px", borderRadius: WtDimen.borderRadius, fontSize: '14px' }} placeholder="Search" />
                    </div>
                </div>
                <div>
                    <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openURL(WtUrl.broadcastCreateUrl) : openDemoDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius, marginTop: '2px' }} ><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Create Broadcast</button>
                </div>
            </div>

            <div className="app_list mt-2" style={{ height: notificationBarStatus.notificationBarStatus ? '600px' : '640px' }}>
                <Table responsive>
                    <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: '14px' }}>
                        <tr><th className="ps-3">Campaign Title</th><th>Template</th><th>Channel</th><th>Agent</th><th>Total</th><th>Created on</th><th>Status</th><th>Action</th></tr>
                    </thead>
                    {searchLs.length !== 0 ?
                        <tbody style={{ background: "#fff", fontSize: "12px" }}>
                            {
                                searchLs.map((v, i) => {
                                    return (
                                        <tr className="hand" key={i}>
                                            <Tooltip title={v.title} placement='end'><td className="" style={{ color: WtColor.TextMidGrey }}>{v.title?.length > 15 ? v.title?.substring(0, 15) + '...' : v.title}</td></Tooltip>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>{v.templateName?.length > 15 ? v.templateName?.substring(0, 15) + '...' : v.templateName}</td>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>{v.channel?.title}</td>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>{v.emp?.name}</td>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>{v.numbersCount}</td>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>{ShowDateTime(v.createDate)}</td>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>
                                                {
                                                    (() => {
                                                        if (v.status === "draft") {
                                                            return (<span className='text fw-bold' style={{ color: WtColor.DraftYellow }}>{v.status.slice(0, 1).toUpperCase() + v.status.slice(1)}</span>)
                                                        }
                                                        if (v.status === "completed") {
                                                            return (<span className='text fw-bold' style={{ color: WtColor.BaseColor, }}>{v.status.slice(0, 1).toUpperCase() + v.status.slice(1)}</span>)
                                                        }
                                                        if (v.status === "processing") {
                                                            return (<span className='text fw-bold' style={{ color: WtColor.ProcessingBlue }}>{v.status.slice(0, 1).toUpperCase() + v.status.slice(1)}</span>)
                                                        }
                                                        if (v.status === "paused") {
                                                            return (<span className='text fw-bold' style={{ color: WtColor.RedColor }}>{v.status.slice(0, 1).toUpperCase() + v.status.slice(1)}</span>)
                                                        }
                                                    })()
                                                }
                                            </td>
                                            <td style={{ paddingTop: "8px" }}>
                                                <Container fluid>
                                                    <Row>
                                                        <Col className="ps-0 pe-0 me-2">
                                                            {
                                                                (v.status === "draft" || v.status === "paused") ? <><PlayCircleOutlineOutlined onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? startCampaign(v._id, v.title, "processing") : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />&nbsp;&nbsp;</> : <></>
                                                            }
                                                            {
                                                                (v.status === "processing") ? <><PauseCircleOutlineOutlined onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? startCampaign(v._id, v.title, "paused") : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />&nbsp;&nbsp;</> : <></>
                                                            }
                                                        </Col>
                                                        <Col className="ps-0 pe-0 me-2">
                                                            {
                                                                (v.status === "draft" || v.status === "processing") ? " " : <AssessmentOutlined onClick={() => openCampiagnAnalyticsDialogBox(v.title, v._id)} style={{ color: WtColor.TextLightGrey }} />
                                                            }
                                                        </Col>
                                                        <Col className="ps-0 pe-0 me-2">
                                                            {
                                                                (v.status === "draft" || v.status === "processing" || v.status === "paused") ? "" : <ContentCopyOutlined onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? duplicateCampaignList(v.title, v._id) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                            }
                                                        </Col>
                                                        <Col className="ps-0 pe-0 me-2">
                                                            <Delete onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? deleteCampaignList(v._id) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        :
                        <tbody><tr> <td colSpan={8} style={{ color: WtColor.TextLightGrey, textAlign: 'center' }}>Sorry! no such Campaigns found.</td></tr></tbody>
                    }
                </Table>
            </div>

            <div>
                {openConfirmDialog.open && <ConfirmDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />}
                {openCampaignAnalyticsDialog.open && <CampaignAnalyticsDialog open={openCampaignAnalyticsDialog.open} title={openCampaignAnalyticsDialog.title} id={openCampaignAnalyticsDialog.id} onClose={openCampaignAnalyticsDialog.onClose} />}
                {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
            </div>
        </div>
    )
}