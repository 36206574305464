/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Snackbar, Tab, Tabs, Button, Drawer, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, FormControlLabel, FormGroup, Switch, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Slide } from '@mui/material'
import { Row, Col, Table } from 'react-bootstrap'
import ReactAudioPlayer from 'react-audio-player'
import { Close, AddBoxOutlined, Delete, ExpandLess, ExpandMore, VisibilityOutlined, VideocamOutlined, DescriptionOutlined, PanoramaOutlined, PictureAsPdfOutlined, OpenInNew } from '@mui/icons-material'
import { Link, useHistory } from 'react-router-dom'
import { getLangNameFromCode } from 'language-name-map'
import { createUpdateBotFlow, deleteOwnMediaAPI, fetchAllTemplatesAPI, fetchChannels, fetchAllMediaAPI, uploadOwnMedia } from '../../controller/APIs'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'
import { WtFile } from '../../constants/files'
import { WtLoader, getFileType } from '../../controller/BaseTool'
import chatbotContext from '../../context/chatbot/chatbotContext'

var FormData = require('form-data')

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

// Bot Dialogs
export function BotFlowOptionDialog({ open, title, id, ch, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const [botFlowName, setBotFlowName] = useState(title)

    useEffect(() => { setBotFlowName(title) }, [title])
    useEffect(() => { setActiveChannelList(ch) }, [ch])

    const handleBotFlowName = (e) => { setBotFlowName(e) }

    const updateBotFlow = async () => {
        try {
            const chList = activeChannelList.filter(i => channelList.findIndex(c => c._id === i) > -1)
            let data = { "_id": id, "title": botFlowName, "testWhatsapp": "", "channelsAssigned": chList }
            const response = await createUpdateBotFlow(data)
            if (response.status === 202) onCloseHere(true)
        } catch (error) { }
    }

    const handleChatbotLiveSwitch = (chId, checked) => {
        const new2 = activeChannelList.map(e => { return e });
        if (checked) {
            new2.push(channelList.find(ch => ch._id === chId)._id)
        } else {
            const index = new2.findIndex(id => id === chId)
            new2.splice(index, 1)
        }
        setActiveChannelList(new2)
    }

    const [activeChannelList, setActiveChannelList] = useState([])

    const containsChannel = (chId) => {
        const contains = (activeChannelList || []).findIndex(c => c === chId) > -1
        return contains
    }

    // List of Channels 
    const [channelList, setChannelList] = useState([])
    const fetchAllChannels = async () => {
        try {
            const response = await fetchChannels()
            setChannelList(response.data.data)
            if (response.status === 200) onCloseHere(true)
        } catch (err) { }
    }
    // End of List of Channels 

    useEffect(() => { fetchAllChannels() }, [])

    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                Edit Bot: "{title}" <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <div className="mb-3 mt-4">
                    <label for="bot-flow-name" style={{ color: WtColor.TextMidGrey, fontWeight: "bold" }}>Enter New Bot Name</label>
                    <input id="bot-flow-name" defaultValue={botFlowName} onChange={(e) => handleBotFlowName(e.target.value)} placeholder="Enter New Bot Name" className="form-control w-100" />
                </div>

                <div className="mt-4 mb-4">
                    <p style={{ color: WtColor.TextMidGrey, fontWeight: "bold" }}>List of Channels</p>
                    {
                        channelList.map((v, i) => {
                            return (
                                <FormGroup key={i}>
                                    <FormControlLabel control={<Switch checked={containsChannel(v._id)} onChange={(_event, checked) => handleChatbotLiveSwitch(v._id, checked)} name={"liveSwitch"} color="primary" />} label={v.title} />
                                </FormGroup>
                            )
                        })
                    }
                </div>
                <br />
                <hr />
                <button style={ValidButtonStyle} disabled={botFlowName !== "" ? false : true} onClick={() => updateBotFlow()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Update</button>
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Cancel</button>
            </DialogContent>
        </Dialog>
    )
}
export function CreateNewBotDialog({ open, searchValue, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const [botFlowName, setBotFlowName] = useState("")
    useEffect(() => { if (searchValue && searchValue !== "") setBotFlowName(searchValue) }, [searchValue])
    const handleBotFlowName = (e) => setBotFlowName(e)

    const history = useHistory()
    const openURL = (url) => history.push(url)

    const createNewBotFlow = async () => {
        try {
            let data = { "title": botFlowName, "testWhatsapp": "", "channelsAssigned": [] }
            const response = await createUpdateBotFlow(data)
            if (response.status === 201) {
                openURL("/dashboard/build-chatbots?botIndex=" + response?.data?.data?._id)
                onCloseHere(true)
            }
        } catch (error) { }
    }
    const CancelButtonStyle = { color: WtColor.TextMidGrey, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                Create New Bot <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="mt-3 mb-3">
                        <label for="bot-flow-name">Enter Bot Name</label>
                        <input id="bot-flow-name" value={botFlowName} onChange={(e) => handleBotFlowName(e.target.value)} placeholder="Bot Name" className="form-control w-100" />
                    </div>
                </DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} disabled={botFlowName !== "" ? false : true} onClick={() => createNewBotFlow()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_bg">Create</button>
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Cancel</button>
            </DialogContent>
        </Dialog>
    )
}
// Enf of Bot Dialogs

export function TemplatesDialog({ open, onClose }) {
    const isLoading = useContext(chatbotContext)
    const handleWTLoader = useContext(chatbotContext)

    const [templateList, setTemplateList] = useState([])

    // Template Code
    const [templateHeader, setTemplateHeader] = useState("")
    const [templateHeaderType, setTemplateHeaderType] = useState("")
    const [templateBody, setTemplateBody] = useState("")
    const [templateFooter, setTemplateFooter] = useState("")
    const [templateButtonText01, setTemplateButtonText01] = useState(undefined)
    // const [templateButtonText01Type, setTemplateButtonText01Type] = useState(undefined)
    const [templateButtonText01Url, setTemplateButtonText01Url] = useState(undefined)
    const [templateButtonText02, setTemplateButtonText02] = useState(undefined)
    // const [templateButtonText02Type, setTemplateButtonText02Type] = useState(undefined)
    const [templateButtonText03, setTemplateButtonText03] = useState(undefined)
    // const [templateButtonText03Type, setTemplateButtonText03Type] = useState(undefined)
    // End of Template Code



    const [filteredTemplate, setFilteredTemplate] = useState([])
    const [searchInput, setSearchInput] = useState('')

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = templateList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredTemplate(filteredData)
        } else {
            setFilteredTemplate(templateList)
        }
    }


    // Drawer Code
    const [templateDrawer, setTemplateDrawer] = useState({ right: false })
    const [templateComponent, setTemplateComponent] = useState([])
    const [entireTemplate, setEntireTemplate] = useState([])
    const [templateName, setTemplateName] = useState("")

    const toggleTemplateDrawer = (anchor, open, components, v, name) => (_event) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return }

        setTemplateComponent(components)
        setEntireTemplate(v)
        setTemplateName(name)

        // Header Code
        try {
            if (templateComponent[0].type === "HEADER" || templateComponent[1].type === "HEADER") {
                if (templateComponent[0].type === "HEADER" && templateComponent[0].format === "TEXT") {
                    setTemplateHeader(templateComponent[0].text)
                    setTemplateHeaderType("TEXT")
                }
                if (templateComponent[1].type === "HEADER" && templateComponent[1].format === "TEXT") {
                    setTemplateHeader(templateComponent[1].text)
                    setTemplateHeaderType("TEXT")
                }

                if (templateComponent[0].type === "HEADER" && templateComponent[0].format === "DOCUMENT") {
                    setTemplateHeader(<DescriptionOutlined style={{ fontSize: "100px" }} />)
                    setTemplateHeaderType("DOCUMENT")
                }
                if (templateComponent[1].type === "HEADER" && templateComponent[1].format === "DOCUMENT") {
                    setTemplateHeader(<DescriptionOutlined style={{ fontSize: "100px" }} />)
                    setTemplateHeaderType("DOCUMENT")
                }

                if (templateComponent[0].type === "HEADER" && templateComponent[0].format === "IMAGE") {
                    setTemplateHeader(<PanoramaOutlined style={{ fontSize: "100px" }} />)
                    setTemplateHeaderType("IMAGE")
                }
                if (templateComponent[1].type === "HEADER" && templateComponent[1].format === "IMAGE") {
                    setTemplateHeader(<PanoramaOutlined style={{ fontSize: "100px" }} />)
                    setTemplateHeaderType("IMAGE")
                }

                if (templateComponent[0].type === "HEADER" && templateComponent[0].format === "VIDEO") {
                    setTemplateHeader(<VideocamOutlined style={{ fontSize: "100px" }} />)
                    setTemplateHeaderType("VIDEO")
                }
                if (templateComponent[1].type === "HEADER" && templateComponent[1].format === "VIDEO") {
                    setTemplateHeader(<VideocamOutlined style={{ fontSize: "100px" }} />)
                    setTemplateHeaderType("VIDEO")
                }
            } else {
                setTemplateHeader("")
                setTemplateHeaderType("")
            }
        } catch (error) { }
        // End of Header Code

        // Body Code
        try {
            if (templateComponent[0].type === "BODY") {
                setTemplateBody(templateComponent[0].text)
            }
            if (templateComponent[1].type === "BODY") {
                setTemplateBody(templateComponent[1].text)
            }

        } catch (error) { }
        // End of Body Code


        // Footer Code
        try {
            if (templateComponent[1].type === "FOOTER") {
                setTemplateFooter(templateComponent[1].text)
            }
            if (templateComponent[2].type === "FOOTER") {
                setTemplateFooter(templateComponent[2].text)
            }
        } catch (error) { }
        // End of Footer Code


        // Buttons Code
        try {
            if (templateComponent[1].type === "BUTTONS") {
                if (templateComponent[1].buttons[0].text && templateComponent[1].buttons[0].type) {
                    setTemplateButtonText01(templateComponent[1].buttons[0].text)
                    // setTemplateButtonText01Type(templateComponent[1].buttons[0].type)
                    if (templateComponent[1].buttons[0].type === "URL") {
                        setTemplateButtonText01Url(templateComponent[1].buttons[0].url)
                    } else {
                        setTemplateButtonText01Url(undefined)
                    }
                } else {
                    setTemplateButtonText01(undefined)
                    // setTemplateButtonText01Type(undefined)
                }

                if (templateComponent[1].buttons[1].text && templateComponent[1].buttons[1].type) {
                    setTemplateButtonText02(templateComponent[1].buttons[1].text)
                    // setTemplateButtonText02Type(templateComponent[1].buttons[1].type)
                } else {
                    setTemplateButtonText02(undefined)
                    // setTemplateButtonText02Type(undefined)
                }

                if (templateComponent[1].buttons[2].text && templateComponent[1].buttons[2].type) {
                    setTemplateButtonText03(templateComponent[1].buttons[2].text)
                    // setTemplateButtonText03Type(templateComponent[1].buttons[2].type)
                } else {
                    setTemplateButtonText03(undefined)
                    // setTemplateButtonText03Type(undefined)
                }
            } else {
                setTemplateButtonText01(undefined)
                // setTemplateButtonText01Type(undefined)
                setTemplateButtonText01Url(undefined)

                setTemplateButtonText02(undefined)
                // setTemplateButtonText02Type(undefined)

                setTemplateButtonText03(undefined)
                // setTemplateButtonText03Type(undefined)
            }

            if (templateComponent[2].type === "BUTTONS") {
                if (templateComponent[2].buttons[0].text && templateComponent[2].buttons[0].type) {
                    setTemplateButtonText01(templateComponent[2].buttons[0].text)
                    // setTemplateButtonText01Type(templateComponent[2].buttons[0].type)

                    if (templateComponent[2].buttons[0].type === "URL") {
                        setTemplateButtonText01Url(templateComponent[2].buttons[0].url)
                    } else {
                        setTemplateButtonText01Url(undefined)
                    }
                } else {
                    setTemplateButtonText01(undefined)
                    // setTemplateButtonText01Type(undefined)
                }

                if (templateComponent[2].buttons[1].text && templateComponent[2].buttons[1].type) {
                    setTemplateButtonText02(templateComponent[2].buttons[1].text)
                    // setTemplateButtonText02Type(templateComponent[2].buttons[1].type)
                } else {
                    setTemplateButtonText02(undefined)
                    // setTemplateButtonText02Type(undefined)
                }

                if (templateComponent[2].buttons[2].text && templateComponent[2].buttons[2].type) {
                    setTemplateButtonText03(templateComponent[2].buttons[2].text)
                    // setTemplateButtonText03Type(templateComponent[2].buttons[2].type)
                } else {
                    setTemplateButtonText03(undefined)
                    // setTemplateButtonText03Type(undefined)
                }
            } else {
                setTemplateButtonText01(undefined)
                // setTemplateButtonText01Type(undefined)
                setTemplateButtonText01Url(undefined)

                setTemplateButtonText02(undefined)
                // setTemplateButtonText02Type(undefined)

                setTemplateButtonText03(undefined)
                // setTemplateButtonText03Type(undefined)
            }

            if (templateComponent[3].type === "BUTTONS") {
                if (templateComponent[3].buttons[0].text && templateComponent[3].buttons[0].type) {
                    setTemplateButtonText01(templateComponent[3].buttons[0].text)
                    // setTemplateButtonText01Type(templateComponent[3].buttons[0].type)

                    if (templateComponent[3].buttons[0].type === "URL") {
                        setTemplateButtonText01Url(templateComponent[3].buttons[0].url)
                    } else {
                        setTemplateButtonText01Url(undefined)
                    }
                } else {
                    setTemplateButtonText01(undefined)
                    // setTemplateButtonText01Type(undefined)
                }

                if (templateComponent[3].buttons[1].text && templateComponent[3].buttons[1].type) {
                    setTemplateButtonText02(templateComponent[3].buttons[1].text)
                    // setTemplateButtonText02Type(templateComponent[3].buttons[1].type)
                } else {
                    setTemplateButtonText02(undefined)
                    // setTemplateButtonText02Type(undefined)
                }

                if (templateComponent[3].buttons[2].text && templateComponent[3].buttons[2].type) {
                    setTemplateButtonText03(templateComponent[3].buttons[2].text)
                    // setTemplateButtonText03Type(templateComponent[3].buttons[2].type)
                } else {
                    setTemplateButtonText03(undefined)
                    // setTemplateButtonText03Type(undefined)
                }
            } else {
                setTemplateButtonText01(undefined)
                // setTemplateButtonText01Type(undefined)
                setTemplateButtonText01Url(undefined)

                setTemplateButtonText02(undefined)
                // setTemplateButtonText02Type(undefined)

                setTemplateButtonText03(undefined)
                // setTemplateButtonText03Type(undefined)
            }

        } catch (error) { }
        // End of Buttons Code


        setTemplateDrawer({ ...templateDrawer, [anchor]: open })
    }

    const templateListView = (anchor) => (
        <Box className="templateView" role="presentation" style={{ pointerEvents: "none" }} onClick={toggleTemplateDrawer(anchor, true)} onKeyDown={toggleTemplateDrawer(anchor, false)}>
            <div className="ms-4 me-4 mt-4">
                <p>Template view</p>
            </div>
            <div className="ms-4 me-4">
                <p className="fw-bold" style={{ color: WtColor.TextMidGrey }}>{templateName}</p>
            </div>
            <div className="ms-4 me-4 mt-4 p-3 rounded-3" style={{ background: WtColor.SelectionColor }}>
                {
                    (templateHeaderType === "TEXT") ? (<p className="fw-bold">{templateHeader}</p>) : (<></>)
                }
                {
                    (templateHeaderType !== "TEXT") ? (<p className="rounded-3 text-center" style={{ background: WtColor.LineColor }}>{templateHeader}</p>) : (<></>)
                }
                <p>{templateBody}</p>
                <p className="text-muted" style={{ fontSize: "12px" }}>{templateFooter}</p>
            </div>
            <div className="ms-4 me-4 mb-4 rounded-3">
                {
                    (templateButtonText01Url) ? (
                        <Link to={{ pathname: templateButtonText01Url }} target="_blank" className="btn btn-sm rounded-3 w-100 bg-white" style={{ color: WtColor.TextDarkColor, outline: "none" }}><OpenInNew /> {templateButtonText01}</Link>
                    ) : (
                        <>
                            {
                                (templateButtonText01) ? (
                                    <button className="btn btn-sm rounded-3 w-100 bg-white" style={{ color: WtColor.TextDarkColor, outline: "none" }}>{templateButtonText01}</button>
                                ) : (<></>)
                            }
                        </>
                    )
                }
                {
                    (templateButtonText02) && <button className="btn btn-sm rounded-3 w-100 bg-white mt-1" style={{ color: WtColor.TextDarkColor, outline: "none" }}>{templateButtonText02}</button>
                }
                {
                    (templateButtonText03) && <button className="btn btn-sm rounded-3 w-100 bg-white mt-1" style={{ color: WtColor.TextDarkColor, outline: "none" }}>{templateButtonText03}</button>
                }
            </div>
            <div style={{ position: "fixed", bottom: "20px", right: "120px", pointerEvents: "auto" }}>
                <Button variant="contained" className="btn btn-sm text-white theme_bg" onClick={() => onClose(entireTemplate)}>Use this Template</Button>
            </div>
        </Box >
    )
    // End of Drawer Code


    const [channelSwitch, setChannelSwitch] = useState(false)
    const handleChannelSwitch = () => { setChannelSwitch(!channelSwitch) }
    const [channelActiveN, setChannelActiveN] = useState(undefined)
    const [channelActiveW, setChannelActiveW] = useState(undefined)
    const [channelLs, setChannelLs] = useState(undefined)
    const getChannels = async () => {
        try {
            const response = await fetchChannels()
            if (response.status === 200) {
                setChannelLs(response.data.data)
                setChannelActiveN(response.data.data[0].title)
                setChannelActiveW(response.data.data[0].whatsapp)
            }
        } catch (error) { }
    }
    useEffect(() => {
        getChannels()
    }, [])


    const fetchWabaTemplates = async (channel) => {
        try {
            if (channelActiveN !== undefined && channelActiveW !== undefined) {
                let data = { 'extChannelId': channel }
                const response = await fetchAllTemplatesAPI(data)
                if (response.status === 200 && response.data.data.templates) {
                    setTemplateList(response.data.data.templates)
                }
            }
        } catch (error) { }
    }
    const handleChannelSwipe = (n, w) => {
        handleWTLoader.handleWTLoader(true)
        setChannelActiveN(n)
        setChannelActiveW(w)
        setChannelSwitch(false)
        handleWTLoader.handleWTLoader(false)
    }
    useEffect(() => {
        fetchWabaTemplates(channelActiveW)
    }, [channelActiveN, channelActiveW])
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title" style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ flex: "1" }}>All Templates</span>
                <List className='me-3' sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingTop: "0px", paddingBottom: "0px" }} component="nav" aria-labelledby="nested-list-subheader">
                    <ListItemButton onClick={handleChannelSwitch} style={{ border: '1px solid ' + WtColor.LineColor, borderRadius: "6px" }} >
                        {
                            (channelActiveN !== undefined && channelActiveW !== undefined) ?
                                <>
                                    <ListItemIcon><span className="text-white ps-1 pe-1 rounded-3" style={{ background: WtColor.TextLightGrey }}>{channelActiveN?.charAt(0)}</span></ListItemIcon>
                                    <ListItemText primary={channelActiveN} />
                                    {channelSwitch ? <ExpandLess /> : <ExpandMore />}
                                </> :
                                <>
                                    <ListItemIcon><span className="text-white ps-1 pe-1 rounded-3" style={{ background: WtColor.TextLightGrey }}>W</span></ListItemIcon>
                                    <ListItemText primary="Channel" />
                                    {channelSwitch ? <ExpandLess /> : <ExpandMore />}
                                </>
                        }
                    </ListItemButton>
                    <Collapse in={channelSwitch} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                channelLs ? channelLs.map((v, i) => {
                                    return (
                                        <ListItemButton onClick={() => handleChannelSwipe(v.title, v.whatsapp)} key={i}>
                                            <ListItemIcon><span className="text-white ps-1 pe-1 rounded-3" style={{ background: WtColor.TextLightGrey }}>{v.title?.charAt(0)}</span></ListItemIcon>
                                            <ListItemText primary={v.title} />
                                        </ListItemButton>
                                    )
                                }) :
                                    <ListItemButton>
                                        <ListItemIcon><AddBoxOutlined /></ListItemIcon>
                                        <ListItemText primary="Add Channel" />
                                    </ListItemButton>
                            }
                        </List>
                    </Collapse>
                </List>
                <span style={{ flex: "2" }}>
                    <input id="search-temp" value={searchInput} onChange={(e) => searchItems(e.target.value)} className="w-100 form-control pt-2 pb-3" style={{ outline: "none" }} placeholder="Search Template" />
                </span>
            </DialogTitle>
            <DialogContent>
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                <div>
                    <Table className="fw-bold" responsive>
                        <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }}>
                            <tr><th>Name</th><th>Category</th><th>Status</th><th>Language</th><th>View</th></tr>
                        </thead>
                        <tbody style={{ background: "#fff", fontSize: "14px" }}>
                            {
                                ((filteredTemplate.length === 0) ? templateList : filteredTemplate).map((v, i) => {
                                    return (
                                        <tr className="hand" key={i}>
                                            <td className="template_name" style={{ color: WtColor.TextMidGrey }} onClick={() => onClose(v)}>{v.name}</td>
                                            <td style={{ color: WtColor.TextLightGrey }}>{v.category}</td>
                                            <td className="text-uppercase">
                                                {
                                                    (v.status === "approved") ? (<span className="rounded-3 ps-3 pe-3 theme_bg text-white">{v.status}</span>) : (<></>)
                                                }
                                                {
                                                    (v.status === "rejected") ? (<span className="rounded-3 ps-3 pe-3 bg-danger text-white">{v.status}</span>) : (<></>)
                                                }
                                            </td>
                                            <td style={{ color: WtColor.TextLightGrey }}>
                                                {
                                                    (() => {
                                                        try {
                                                            return getLangNameFromCode(v.language).name
                                                        } catch (error) {
                                                            return v.language
                                                        }
                                                    })()
                                                }
                                            </td>
                                            <td onMouseEnter={toggleTemplateDrawer("right", false, v.components, v, v.name)}><VisibilityOutlined onClick={toggleTemplateDrawer("right", true, v.components, v, v.name)} style={{ color: WtColor.TextMidGrey }} /></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <div className="viewTemplate">
                        <Drawer anchor={"right"} open={templateDrawer["right"]} style={{ zIndex: 9999 }} onClose={toggleTemplateDrawer("right", false)}>{templateListView("right")}</Drawer>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{ textTransform: "none", boxShadow: "none" }} variant="contained" onClick={() => onClose()}>Close</Button>
            </DialogActions>
        </Dialog >
    )
}

function TransitionDown(props) { return <Slide {...props} direction="down" /> }
export function MediaUploadDialog({ open, onClose, onSelectMedia }) {
    const onCloseHere = () => {
        setChooseImg(undefined)
        if (onClose) onClose()
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props
        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (<Box sx={{ p: 0 }}><Typography>{children}</Typography></Box>)}
            </div>
        )
    }
    TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.number.isRequired, value: PropTypes.number.isRequired, }
    function a11yProps(index) { return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, } }
    const [mediaTabOpt, setMediaTabOpt] = useState(0)
    const handleChange = (_event, newValue) => {
        setMediaTabOpt(newValue)
        setChooseImg(undefined)
    }

    // Fetch Images
    const [imagesList, setImagesList] = useState([])
    const fetchImages = async () => {
        try {
            const response = await fetchAllMediaAPI()
            if (response.status === 200) setImagesList(response.data.data)
        } catch (error) { }
    }
    // End of Fetch Images


    // Select Image 
    const handleMediaSelect = (media) => {
        if (onSelectMedia) onSelectMedia(media)
        onCloseHere()
    }
    // End of Select Image


    // Media Upload
    const [mediaType, setMediaType] = useState("image")

    const onDropHandler = (ev) => {
        ev.preventDefault()
        let file = ""
        if (ev.dataTransfer.items) {
            file = [...ev.dataTransfer.items].find((item) => item.kind === "file").getAsFile()
        } else {
            file = ev.dataTransfer.files[0]
        }
        let file_size = ev.dataTransfer.files[0].size

        if (getFileType(file) !== "other") {
            if ((getFileType(file) === "image") && (file_size <= WtFile.imgSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImgFile(ev.dataTransfer.files[0])
                setChooseImg(URL.createObjectURL(file))
            } else if ((getFileType(file) === "video") && (file_size <= WtFile.VideoSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImgFile(ev.dataTransfer.files[0])
                setChooseImg(URL.createObjectURL(file))
            } else if ((getFileType(file) === "audio") && (file_size <= WtFile.audioSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImgFile(ev.dataTransfer.files[0])
                setChooseImg(URL.createObjectURL(file))
            } else if ((getFileType(file) === "document") && (file_size <= WtFile.pdfSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImgFile(ev.dataTransfer.files[0])
                setChooseImg(URL.createObjectURL(file))
            } else {
                setMediaType(getFileType(file))
                setErrorMsg('File size limit reached')
                setErrorMsgBg(WtColor.RedColor)
                ShowSnackbar()
            }
        } else {
            setMediaType(getFileType(file))
            setErrorMsg('File is not valid')
            setErrorMsgBg(WtColor.RedColor)
            ShowSnackbar()
        }

    }
    const onDragOver = (ev) => ev.preventDefault()
    const [chooseImgFile, setChooseImgFile] = useState([])
    const [chooseImg, setChooseImg] = useState(undefined)
    const onChooseImg = (e) => {
        const [file] = e.target.files
        let file_size = e.target.files[0].size
        if (getFileType(file) !== "other") {
            if ((getFileType(file) === "image") && (file_size <= WtFile.imgSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImg(URL.createObjectURL(file))
                setChooseImgFile(e.target.files[0])
            } else if ((getFileType(file) === "video") && (file_size <= WtFile.VideoSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImg(URL.createObjectURL(file))
                setChooseImgFile(e.target.files[0])
            } else if ((getFileType(file) === "audio") && (file_size <= WtFile.audioSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImg(URL.createObjectURL(file))
                setChooseImgFile(e.target.files[0])
            } else if ((getFileType(file) === "document") && (file_size <= WtFile.pdfSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseImg(URL.createObjectURL(file))
                setChooseImgFile(e.target.files[0])
            } else {
                setMediaType(getFileType(file))
                setErrorMsg('File size limit reached')
                setErrorMsgBg(WtColor.RedColor)
                ShowSnackbar()
            }
        } else {
            setMediaType(getFileType(file))
            setErrorMsg('File is not valid')
            setErrorMsgBg(WtColor.RedColor)
            ShowSnackbar()
        }
    }
    const uploadAnyMedia = async () => {
        try {
            var data = new FormData()
            data.append('file', chooseImgFile)
            const response = await uploadOwnMedia(data)
            if (response.status === 201) {
                setErrorMsg('Media added, Please select from Gallery...')
                setErrorMsgBg(WtColor.BaseColor)
                ShowSnackbar()
                fetchImages()
                setMediaTabOpt(0)
                setChooseImg(undefined)
                CloseSnackbar()
            } else {
                onCloseHere(false)
                CloseSnackbar()
            }
        } catch (error) {
            onCloseHere(false)
            CloseSnackbar()
        }
    }
    const deleteMedia = async (id) => {
        try {
            let data = { '_id': id }
            const response = await deleteOwnMediaAPI(data)
            if (response.status === 204) fetchImages()
        } catch (error) { }
    }
    // End of Media Upload


    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const ShowSnackbar = () => { setOpenSnackbar(true) }
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') { return }
        setOpenSnackbar(false)
    }
    const SnackbarBox = () => {
        return (
            <section>
                <Snackbar open={openSnackbar} autoHideDuration={2000} TransitionComponent={TransitionDown} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={CloseSnackbar} >
                    <div className="text-white ps-5 pt-2 pb-1" style={{ background: errorMsgBg, display: "flex", borderRadius: "20px" }} >
                        <Typography style={{ marginTop: "3px" }}>{errorMsg}</Typography>
                        <IconButton className="ms-5 pe-2 me-2" size="small" aria-label="close" color="inherit" onClick={CloseSnackbar}>
                            <Close fontSize="small" />
                        </IconButton>
                    </div>
                </Snackbar>
            </section>
        )
    }


    const MediaPreview = () => {
        return (
            <>
                <img style={{ width: "180px", height: "auto" }} src={WtTheme.ImageBaseUrl + "/upload_media.svg"} alt={WtTheme.SiteName} />
                <p className="text-center fw-bold"><span style={{ color: WtColor.TextDarkColor }}>Drop your Image or</span> <span style={{ color: WtColor.BaseColor }}>Browse</span></p>
                <p className="text-center" style={{ color: WtColor.TextLightGrey, marginTop: "-10px" }}>Supports : PNG, JPEG, JPG, MP4, MP3, PDF</p>
            </>
        )
    }
    useEffect(() => { fetchImages() }, [])
    return (
        <>
            <SnackbarBox />
            <Dialog open={open} fullWidth={true} maxWidth={"lg"} onClose={() => onCloseHere(false)} TransitionComponent={Transition} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>
                    <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor }}>Upload Media</p>
                    <Box className="bg-white mt-2">
                        <Tabs value={mediaTabOpt} onChange={handleChange} textColor="secondary" indicatorColor="secondary" style={{ display: "flex" }} aria-label="secondary tabs example" centered>
                            <Tab label="Previous Gallery" style={{ flex: 1, textTransform: "none" }}  {...a11yProps(0)} />
                            <Tab label="From Computer" style={{ flex: 1, textTransform: "none" }} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </DialogTitle>
                <DialogContent className="ps-5 pe-5">
                    <DialogContentText style={{ borderTop: "1px solid " + WtColor.LineColor }} id="alert-dialog-slide-description">
                        <TabPanel className="mt-3" value={mediaTabOpt} index={0}>
                            <Row>
                                {
                                    imagesList.map((v, i) => {
                                        return (
                                            <>
                                                {
                                                    (() => {
                                                        if (v.mimetype === "image/png" || v.mimetype === "image/jpg" || v.mimetype === "image/jpeg") {
                                                            return (
                                                                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-2" key={i}>
                                                                    <div className="imgChecked" style={{ border: "1px solid " + WtColor.LineColor }}>
                                                                        <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                        <p className="text-center"><img onClick={(e) => handleMediaSelect(v)} style={{ width: "auto", height: "180px" }} className="img-fluid hand p-2" src={v.link} alt={WtTheme.SiteName} /></p>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                        if (v.mimetype === "video/mp4") {
                                                            return (
                                                                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-2" key={i}>
                                                                    <div className="imgChecked" style={{ border: "1px solid " + WtColor.LineColor }}>
                                                                        <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                        <p className="text-center" onClick={(e) => handleMediaSelect(v)}>
                                                                            <video width="160px" height="auto" className="hand p-2" controls>
                                                                                <source src={v.link} type="video/mp4" />
                                                                            </video>
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                        if (v.mimetype === "audio/mpeg") {
                                                            return (
                                                                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-2" key={i}>
                                                                    <div className="imgChecked" style={{ border: "1px solid " + WtColor.LineColor }}>
                                                                        <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                        <p className="text-center" onClick={(e) => handleMediaSelect(v)}>
                                                                            <ReactAudioPlayer src={v.link} controls style={{ width: "175px" }} className="p-2" />
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                        if (v.mimetype === "application/pdf") {
                                                            return (
                                                                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-2" key={i}>
                                                                    <div className="imgChecked" style={{ border: "1px solid " + WtColor.LineColor }}>
                                                                        <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                        <p className="text-center" onClick={(e) => handleMediaSelect(v)}>
                                                                            <PictureAsPdfOutlined
                                                                                className="p-2"
                                                                                style={{ fontSize: "60px" }} />
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </>
                                        )
                                    })
                                }
                            </Row>
                        </TabPanel>
                        <TabPanel className="mt-3" value={mediaTabOpt} index={1}>
                            <div className="pt-2 pb-2">
                                {
                                    (chooseImg === undefined) ? (
                                        <p className="text-center pt-5 pb-5" onDrop={onDropHandler} onDragOver={onDragOver} style={{ borderStyle: "dotted", borderColor: WtColor.LineColor }}>
                                            <label htmlFor="multiple_media_files">
                                                <MediaPreview />
                                            </label>
                                        </p>
                                    ) : (
                                        <p className="text-center" onDrop={onDropHandler} onDragOver={onDragOver} style={{ borderStyle: "dotted", borderColor: WtColor.LineColor }}>
                                            <label htmlFor="multiple_media_files">
                                                {
                                                    (() => {
                                                        if (mediaType === "image") {
                                                            return <img style={{ width: "400px", height: "auto" }} src={chooseImg} alt={WtTheme.SiteName} />
                                                        }
                                                        if (mediaType === "other") {
                                                            return <MediaPreview />
                                                        }
                                                        if (mediaType === "video") {
                                                            return <video width="400px" height="auto" className="hand p-2" controls><source src={chooseImg} type="video/mp4" /></video>
                                                        }
                                                        if (mediaType === "audio") {
                                                            return <ReactAudioPlayer src={chooseImg} controls style={{ width: "400px" }} className="p-2" />
                                                        }
                                                        if (mediaType === "document") {
                                                            return <p className="text-center"><PictureAsPdfOutlined className="p-2" style={{ fontSize: "100px" }} /></p>
                                                        }
                                                    })()
                                                }
                                            </label>
                                        </p>
                                    )
                                }
                                <input type={"file"} onChange={onChooseImg} id="multiple_media_files" style={{ display: "none" }} accept="image/png, image/jpeg, image/jpg, video/mp4, audio/mpeg, application/pdf" className="form-control" multiple />
                                <div>
                                    <p style={{ color: WtColor.TextMidGrey }}>Important:</p>
                                    <ul style={{ color: WtColor.TextLightGrey }}>
                                        <li>Image and Audio size upto 5MB</li>
                                        <li>Video and PDF size upto 15MB</li>
                                    </ul>
                                </div>
                            </div>
                        </TabPanel>
                    </DialogContentText>
                </DialogContent>


                <DialogActions className="pt-3 pb-3 pe-5">
                    <Button onClick={() => onCloseHere(true)} className="btn btn-sm rounded-0 rounded-3" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey, textTransform: "none", boxShadow: "none" }} variant="contained">Close</Button>
                    {
                        (mediaTabOpt === 1) ? (
                            <Button onClick={uploadAnyMedia} disabled={chooseImg ? false : true} className="btn btn-sm rounded-0 text-white rounded-3" style={{ background: WtColor.BaseColor, textTransform: "none", boxShadow: "none" }} variant="contained">Upload</Button>
                        ) : (<></>)
                    }
                </DialogActions>



            </Dialog >
        </>
    )
}
