/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import { Button } from '@mui/material'
import { PhotoSizeSelectActualOutlined, OpenInNewOutlined, VideocamOutlined, PictureAsPdfOutlined, FormatListBulletedOutlined } from '@mui/icons-material'
import { WtTheme } from '../../controller/theme'
import { ChatTextFormater, ShowTimeDayDate, templateVariableSetter } from '../../controller/BaseTool'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from 'react-router-dom'
import { fetchMedia } from '../../controller/APIs'
import { WtColor } from '../../constants/colours'
import { WtString } from '../../constants/strings'

const textMsgStyle01 = { color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px" }
const textMsgStyle02 = { color: WtColor.TextLightGrey, fontSize: "12px", marginBottom: "0px", paddingBottom: "0px" }
const textMsgStyle03 = { color: WtColor.TextLightGrey, fontSize: "16px", marginBottom: "0px", paddingBottom: "0px" }
const textMsgStyle04 = { color: WtColor.TextDarkColor, fontWeight: "400", fontSize: '13px', marginBottom: "0px", paddingBottom: "0px" }
const ButtonStyle01 = { textTransform: "none", boxShadow: "none" }

const pdf_icon_url = "https://raw.githubusercontent.com/love-building-app/letsserve/1e75bfe9933a567a38f2a09bae0ec46c006bf7c4/prod/home/wtx_website/Icons/pdf_icon.svg"
const download_icon_url = "https://raw.githubusercontent.com/love-building-app/letsserve/1e75bfe9933a567a38f2a09bae0ec46c006bf7c4/prod/home/wtx_website/Icons/download_icon.svg"

export const NotValidMessage = (props) => {
    const { val } = props
    return (
        <p style={textMsgStyle01} className="text-danger">{val}</p>
    )
}
export const ActivityTextMessage = (props) => {
    const { v } = props
    return (
        <div>
            <p className="text-center p-2" style={{ background: WtColor.LineColor, border: "1px solid " + WtColor.CloudyGrey, color: WtColor.CloudyGrey, borderRadius: "10px", marginLeft: "150px", marginRight: "150px" }}>{v.activityText} &nbsp;&nbsp;&nbsp; {ShowTimeDayDate(v.sentDate)}</p>
        </div>
    )
}

export const PrivateTextMessage = (props) => {
    const { val } = props
    const [readMore, setReadMore] = useState(undefined)

    const handleReadMore = () => {
        setReadMore("read_more_close")
    }
    return (
        (readMore === undefined) ?
            <>
                <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(val).substring(0, 300) }}></p>
                {
                    (ChatTextFormater(val).trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                }
            </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(val) }}></p>
    )
}
export const PrivateImageMessage = (props) => {
    const { v } = props
    return (
        <div className="chat_image_view">
            <Link to={{ pathname: v.msg.image.link }} target="_blank">
                <img src={v.msg.image.link} className="rounded-2" alt={"WhatsTool Business"} />
            </Link>
            {
                (v.msg.image.caption && v.msg.image.caption !== "") ? <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(v.msg.image.caption) }}></p> : ""
            }
        </div>
    )
}
export const PrivateVideoMessage = (props) => {
    const { v } = props
    return (
        <div>
            <div className="chat_video_view">
                <video className="hand p-2" controls><source src={v.msg ? v.msg.video.link : ""} type="video/mp4" /></video>
            </div>
            {
                (v.msg.video.caption && v.msg.video.caption !== "") ? <p style={textMsgStyle01}>{v.msg.video.caption}</p> : ""
            }
            <br />
        </div>
    )
}
export const TextMessage = (props) => {
    const { val } = props
    const [readMore, setReadMore] = useState(undefined)

    const handleReadMore = () => {
        setReadMore("read_more_close")
    }
    let msg = ChatTextFormater(val)
    return (
        (readMore === undefined) ?
            <>
                <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg?.substring(0, 300) }}></p>
                {
                    (msg?.trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                }
            </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg }}></p>
    )
}
export const TextReplyMessage = (props) => {
    const { val, contextMsg } = props
    const [readMore, setReadMore] = useState(undefined)
    const handleReadMore = () => setReadMore("read_more_close")

    let msg = ChatTextFormater(val)
    return (
        (readMore === undefined) ?
            <>
                <div>
                    <div className="p-3 hand" style={{ background: "#fff", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>{contextMsg?.length > 60 ? contextMsg?.substring(0, 60) + '...' : contextMsg}</div>
                </div>
                <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg?.substring(0, 300) }}></p>
                {
                    (msg?.trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                }
            </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg }}></p>
    )
}
export const TextMenuReplyMessage = (props) => {
    const { val, contextMsg } = props
    const [readMore, setReadMore] = useState(undefined)
    const handleReadMore = () => setReadMore("read_more_close")

    let interactiveUI = []

    // Header UI
    if (contextMsg?.msg?.interactive?.header && contextMsg?.msg?.interactive?.header?.type && (contextMsg?.msg?.interactive?.header?.type === "text") && contextMsg?.msg?.interactive?.header?.text) {//for header type: text
        interactiveUI.push(
            <p style={textMsgStyle01} className="fw-bold">{contextMsg.msg?.interactive?.header?.text}</p>
        )
    }
    // End of Header UI

    // Body UI
    if (contextMsg?.msg?.interactive?.body && contextMsg?.msg?.interactive?.body?.text) {//for body
        interactiveUI.push(
            <p style={textMsgStyle01}>{contextMsg?.msg?.interactive?.body?.text?.substring(0, 60) + " ..."}</p>
        )
    }
    // End of Body UI

    let msg = ChatTextFormater(val)
    return (
        (readMore === undefined) ?
            <>
                <div>
                    <div className="p-3 hand" style={{ background: "#fff", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>{interactiveUI}</div>
                </div>
                <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg?.substring(0, 300) }}></p>
                {
                    (msg?.trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                }
            </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg }}></p>
    )
}
export const TextImageReplyMessage = (props) => {
    const { val, contextMsg } = props
    const [readMore, setReadMore] = useState(undefined)
    const handleReadMore = () => setReadMore("read_more_close")

    let msg = ChatTextFormater(val)
    return (
        (readMore === undefined) ?
            <>
                <div>
                    <div className="p-3 hand" style={{ background: "#fff", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>
                        <div className="">
                            <Link to={{ pathname: contextMsg }} target="_blank">
                                <img src={contextMsg} className="rounded-2" style={{ width: '40px', height: '40px' }} alt={WtString.SiteName} />
                            </Link>
                        </div>
                    </div>
                </div>
                <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg?.substring(0, 300) }}></p>
                {
                    (msg?.trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                }
            </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg }}></p>
    )
}
export const TextVideoReplyMessage = (props) => {
    const { val, contextMsg } = props
    const [readMore, setReadMore] = useState(undefined)
    const handleReadMore = () => setReadMore("read_more_close")

    let msg = ChatTextFormater(val)
    return (
        (readMore === undefined) ?
            <>
                <div>
                    <div className="p-3 hand" style={{ background: "#fff", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>
                        <div className="">
                            <video className="hand p-2" style={{ width: '100px', height: '60px' }}><source src={contextMsg} type="video/mp4" /></video>
                        </div>
                    </div>
                </div>
                <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg?.substring(0, 300) }}></p>
                {
                    (msg?.trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                }
            </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: msg }}></p>
    )
}


export const BusinessImageMessage = (props) => {
    const { link, caption } = props
    return (
        <div>
            <div className="chat_image_view">
                <Link to={{ pathname: link }} target="_blank">
                    <img src={link} className="rounded-2" alt={"WhatsTool Business"} />
                </Link>
            </div>
            {
                (caption && caption !== "") ? <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(caption) }}></p> : ""
            }
        </div>
    )
}
export const EndUserImageMessage = (props) => {
    const { src, caption } = props
    return (
        <div>
            <div className="chat_image_view">
                <Link to={{ pathname: src }} target="_blank">
                    <img src={src} className="rounded-2" alt={"WhatsTool Business"} />
                </Link>
            </div>
            {
                (caption && caption !== "") ? <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(caption).substring(0, 100) }}></p> : ""
            }
        </div>
    )
}
export const EndUserLocationMessage = (props) => {
    const { location } = props
    return (
        <div>
            <div className="chat_image_view">
                {location?.name ? <><span>Name: {location?.name}</span><br /></> : ''}
                {location?.address ? <><span>Address: {location?.address}</span><br /></> : ''}
                {location?.latitude ? <><span>Latitude: {location?.latitude}</span><br /></> : ''}
                {location?.longitude ? <><span>Longitude: {location?.longitude}</span></> : ''}
            </div>
        </div>
    )
}

export const ButtonReplyMessage = (props) => {
    const { msg } = props
    return (
        <div>
            <div className="ps-3 pe-3 pt-1 pb-2 hand" style={{ background: "#f0f2f8", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>
                <Button variant='contained' className="w-100 rounded-2 mt-1 fw-bold interactive_btns" style={ButtonStyle01}>{msg.button.text}</Button>
            </div>
            <p>{msg.button.text}</p>
        </div>
    )
}

export const InteractiveButtonMessage = (props) => {
    const { id, interactive } = props
    const [readMore, setReadMore] = useState(undefined)

    const handleReadMore = () => {
        setReadMore("read_more_close")
    }

    let interactiveUI = []
    // Header UI
    if (interactive.header && interactive.header.type && (interactive.header.type === "text") && interactive.header.text) {//for header type: text
        interactiveUI.push(
            <p style={textMsgStyle01} className="fw-bold">{interactive.header.text}</p>
        )
    }
    // End of Header UI

    // Body UI
    if (interactive.body && interactive.body.text) {//for body
        interactiveUI.push(
            <>
                {
                    (readMore === undefined) ?
                        <>
                            <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.body.text).substring(0, 300) }}></p>
                            {
                                (ChatTextFormater(interactive.body.text).trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                            }
                        </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.body.text) }}></p>
                }
            </>

        )
    }
    // End of Body UI

    // Footer UI
    if (interactive.footer && interactive.footer.text) {//for Footer
        interactiveUI.push(
            <p style={textMsgStyle02}>{interactive.footer.text}</p>
        )
    }
    // End of Footer UI
    return (
        <div key={id.toString()}>{interactiveUI}</div>
    )
}
export const InteractiveButtonsMessage = (props) => {
    const { id, buttons } = props
    return (
        <div className="me-3" key={id}>
            {
                buttons.map((v, i) => {
                    return (
                        <div key={i}><Button variant='contained' className="w-100 rounded-2 mt-1 fw-bold interactive_btns" style={ButtonStyle01}>{v.reply.title}</Button><br /></div>
                    )
                })
            }
        </div>
    )
}
export const InteractiveButtonReplyMessage = (props) => {
    const { id, interactive, contextMsg } = props
    let interactiveUI = []
    // Header UI
    if (contextMsg?.msg?.interactive?.header && contextMsg?.msg?.interactive?.header?.type && (contextMsg?.msg?.interactive?.header?.type === "text") && contextMsg?.msg?.interactive?.header?.text) {//for header type: text
        interactiveUI.push(
            <p style={textMsgStyle01} className="fw-bold">{contextMsg.msg?.interactive?.header?.text}</p>
        )
    }
    // End of Header UI

    // Body UI
    if (contextMsg?.msg?.interactive?.body && contextMsg?.msg?.interactive?.body?.text) {//for body
        interactiveUI.push(
            <p style={textMsgStyle01}>{contextMsg?.msg?.interactive?.body?.text?.substring(0, 60) + " ..."}</p>
        )
    }
    // End of Body UI
    return (
        <div key={id}>
            <div className="p-3 hand" style={{ background: "#fff", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>{interactiveUI}</div>
            <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.button_reply.title) }}></p>
        </div>
    )
}

export const InteractiveListMessage = (props) => {
    const { id, interactive } = props

    const [readMore, setReadMore] = useState(undefined)
    const handleReadMore = () => setReadMore("read_more_close")

    let interactiveUI = []

    // Header UI
    if (interactive.header && interactive.header.type && (interactive.header.type === "text") && interactive.header.text) {//for header type: text
        interactiveUI.push(
            <p style={textMsgStyle01} className="fw-bold">{interactive.header.text}</p>
        )
    }
    // End of Header UI

    // Body UI
    if (interactive.body && interactive.body.text) {//for body
        interactiveUI.push(
            <>
                {
                    (readMore === undefined) ?
                        <>
                            <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.body.text).substring(0, 300) }}></p>
                            {
                                (ChatTextFormater(interactive.body.text).trim().length >= 300) ? <span onClick={() => handleReadMore()} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                            }
                        </> : <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.body.text) }}></p>
                }
            </>

        )
    }
    // End of Body UI

    // Footer UI
    if (interactive.footer && interactive.footer.text) {//for Footer
        interactiveUI.push(
            <p style={textMsgStyle02}>{interactive.footer.text}</p>
        )
    }
    // End of Footer UI


    // List Type UI
    if (interactive.action && interactive.action.button) {//for list
        interactiveUI.push(
            <div className="mt-2 hand" style={{ borderTop: '1px solid #fff' }}>
                <p className="text-center mt-1" style={{ color: WtColor.ProcessingBlue }}><FormatListBulletedOutlined style={{ color: WtColor.ProcessingBlue }} /> {interactive.action.button}</p>
            </div>
        )
    }
    // End of List Type UI
    return (
        <div key={id.toString()}>{interactiveUI}</div>
    )
}
export const InteractiveListReplyMessage = (props) => {
    const { id, interactive, contextMsg } = props

    let interactiveUI = []

    // Header UI
    if (contextMsg?.msg?.interactive?.header && contextMsg?.msg?.interactive?.header?.type && (contextMsg?.msg?.interactive?.header?.type === "text") && contextMsg?.msg?.interactive?.header?.text) {//for header type: text
        interactiveUI.push(
            <p style={textMsgStyle01} className="fw-bold">{contextMsg.msg?.interactive?.header?.text}</p>
        )
    }
    // End of Header UI

    // Body UI
    if (contextMsg?.msg?.interactive?.body && contextMsg?.msg?.interactive?.body?.text) {//for body
        interactiveUI.push(
            <p style={textMsgStyle01}>{contextMsg?.msg?.interactive?.body?.text?.substring(0, 60) + " ..."}</p>
        )
    }
    // End of Body UI
    return (
        <div key={id}>
            <div className="p-3 hand" style={{ background: "#fff", borderRadius: "6px", borderLeft: "3px solid " + WtColor.BaseColor }}>{interactiveUI}</div>
            {interactive.list_reply.title && <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.list_reply.title) }}></p>}
            {interactive.list_reply.description && <p style={textMsgStyle01} dangerouslySetInnerHTML={{ __html: ChatTextFormater(interactive.list_reply.description) }}></p>}
        </div>
    )
}

export const DocumentMessage = (props) => {
    const { document, link } = props
    return (
        <>
            <Link to={{ pathname: link }} target="_blank">
                <div className="ps-2 pt-2 pb-2 pe-3 hand" style={{ background: "#fff", borderRadius: "6px", display: "flex", width: "280px" }}>
                    <img src={pdf_icon_url} style={{ width: "40px" }} alt={WtTheme.SiteName} />&nbsp;&nbsp;
                    <p style={textMsgStyle03} className="pt-2 fw-bold">{(!document.filename || document.filename === "") ? "PDF Document" : document.filename.substring(0, 18) + " ... "}</p>&nbsp;&nbsp;
                    <p style={{ flex: 1 }}>&nbsp;</p>
                    <img src={download_icon_url} style={{ width: "25px" }} alt={WtTheme.SiteName} />
                </div>
            </Link>
        </>
    )
}

export const AudioMessage = (props) => {
    const { src } = props
    return (
        <>
            <ReactAudioPlayer src={src} controls style={{ width: "280px" }} />
        </>
    )
}

export const VideoMessage = (props) => {
    const { v, channel } = props
    return (
        <div>
            <div className="chat_video_view">
                <video className="hand p-2" controls><source src={channel ? fetchMedia(channel, v.msg.video.id) : v.msg.video.link} type="video/mp4" /></video>
            </div>
            {
                (v.msg.video.caption && v.msg.video.caption !== "") ? <p style={textMsgStyle01}>{v.msg.video.caption}</p> : ""
            }
            <br />
        </div>
    )
}


export const TemplateMessageWithVariable = (props) => {
    const { templateComponent } = props
    let templateUI = []
    const [readMore, setReadMore] = useState(undefined)
    const handleReadMore = (val) => { setReadMore(val) }
    try {
        // Header UI
        templateComponent.map((v, i) => {
            if (templateComponent && templateComponent[i].type === "HEADER" && templateComponent[i].format === "TEXT") {
                templateUI.push(
                    <p style={textMsgStyle01} className="fw-bold" key={i}>{v.text}</p>
                )
            }
            if (templateComponent && templateComponent[i].type === "HEADER" && templateComponent[i].format === "DOCUMENT") {
                templateUI.push(
                    <p className="text-center rounded-2 mb-2" style={{ color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px", border: "1px solid " + WtColor.LineColor }} key={i}>
                        <PictureAsPdfOutlined style={{ fontSize: "40px", color: WtColor.ProcessingBlue }} />
                    </p>
                )
            }
            if (templateComponent && templateComponent[i].type === "HEADER" && templateComponent[i].format === "VIDEO") {
                templateUI.push(
                    <p className="text-center rounded-2 mb-2" style={{ color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px", border: "1px solid " + WtColor.LineColor }} key={i}>
                        <VideocamOutlined style={{ fontSize: "40px", color: WtColor.ProcessingBlue }} />
                    </p>
                )
            }
            if (templateComponent && templateComponent[i].type === "HEADER" && templateComponent[i].format === "IMAGE") {
                templateUI.push(
                    <p className="text-center rounded-2 mb-2" style={{ color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px", border: "1px solid " + WtColor.LineColor }} key={i}>
                        <PhotoSizeSelectActualOutlined style={{ fontSize: "40px", color: WtColor.ProcessingBlue }} />
                    </p>
                )
            }
        })
        // End of Header UI

        // Body UI
        templateComponent.map((v, i) => {
            if (templateComponent && templateComponent[i].type === "BODY" && templateComponent[i].text) {
                if (readMore === undefined) {
                    templateUI.push(
                        <>
                            <p style={textMsgStyle04} dangerouslySetInnerHTML={{ __html: v.text.trim().substring(0, 28) }}></p>
                            {
                                (ChatTextFormater(v.text).trim().length >= 28) ? <span onClick={() => handleReadMore("read_more_close")} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read More</span> : ""
                            }
                        </>
                    )
                } else {
                    templateUI.push(
                        <>
                            <p style={textMsgStyle04} dangerouslySetInnerHTML={{ __html: ChatTextFormater(v.text) }}></p>
                            {
                                (ChatTextFormater(v.text).trim().length >= 28) ? <span onClick={() => handleReadMore(undefined)} className="hand" style={{ color: WtColor.ProcessingBlue }}>... Read Less</span> : ""
                            }
                        </>
                    )
                }
            }
        })
        // End of Body UI




        // Footer UI
        templateComponent.map((v, i) => {
            if (templateComponent && templateComponent[i].type === "FOOTER" && templateComponent[i].text) {
                templateUI.push(
                    <p style={textMsgStyle02} key={i}>{v.text?.substring(0, 25) + '...'}</p>
                )
            }
        })
        // End of Footer UI
    } catch (error) {
        templateUI.push(
            <NotValidMessage val={"Not Valid Template"} />
        )
    }

    return (<div>{templateUI}</div>)
}
export const TemplateButtonMessageForMultiPurpose = (props) => {
    const { buttons } = props
    return (
        <div>
            {
                buttons.map((v, i) => {
                    return (
                        <div key={i}>
                            {
                                (buttons[i].type === "URL" && buttons[i].url) ?
                                    <Button variant='contained' className="w-100 rounded-2 mt-1 fw-bold interactive_btns" style={ButtonStyle01}><OpenInNewOutlined style={{ fontSize: "14px" }} /> {v.text}</Button>
                                    :
                                    <Button variant='contained' className="w-100 rounded-2 mt-1 fw-bold interactive_btns" style={ButtonStyle01}>{v.text}</Button>
                            }
                            <br />
                        </div>
                    )
                })
            }
        </div>
    )
}
export const TemplateMessage = (props) => {
    const { templateComponent, msgTemplate } = props
    let templateUI = []
    // const [readMore, setReadMore] = useState(undefined)
    // const handleReadMore = (val) => { setReadMore(val) }
    try {
        // Header UI
        templateComponent.map((v, i) => {
            if (templateComponent && templateComponent[i]?.type === "HEADER" && templateComponent[i]?.format === "TEXT") {
                templateUI.push(
                    <p style={textMsgStyle01} className="fw-bold" key={i}>{v.text}</p>
                )
            }
            if (templateComponent && templateComponent[i]?.type === "HEADER" && templateComponent[i]?.format === "DOCUMENT") {
                templateUI.push(
                    <p style={textMsgStyle01} className="fw-bold" key={i}>Document</p>
                )
            }
            if (templateComponent && templateComponent[i]?.type === "HEADER" && templateComponent[i]?.format === "VIDEO") {
                templateUI.push(
                    <div className="chat_video_view" key={i}>
                        <video className="hand p-2" controls><source src={msgTemplate.components[i].parameters[i].video.link} type="video/mp4" /></video>
                    </div>
                )
            }
            if (templateComponent && templateComponent[i]?.type === "HEADER" && templateComponent[i]?.format === "IMAGE" && msgTemplate?.components && msgTemplate?.components[i]?.parameters[i]?.image?.link) {
                templateUI.push(
                    <div className="chat_image_view" key={i}>
                        <img src={msgTemplate?.components[i]?.parameters[i]?.image?.link} className="rounded-2" alt={WtTheme.SiteName} />
                    </div>
                )
            }
        })
        // End of Header UI

        // Body UI
        templateComponent.map((v, i) => {
            if (templateComponent && templateComponent[i].type === "BODY" && templateComponent[i].text) {
                let applyVariableInTextMsg = ""
                if (msgTemplate && msgTemplate?.components) {
                    msgTemplate?.components?.map((el) => {
                        if (el.type === "body") {
                            applyVariableInTextMsg = templateVariableSetter(v.text, el.parameters)
                        }
                    })
                } else {
                    applyVariableInTextMsg = v.text
                }
                templateUI.push(
                    <p style={textMsgStyle01} key={i} dangerouslySetInnerHTML={{ __html: applyVariableInTextMsg }}></p>
                )
            }
        })
        // End of Body UI

        // Footer UI
        templateComponent.map((v, i) => {
            if (templateComponent && templateComponent[i]?.type === "FOOTER" && templateComponent[i]?.text) {
                templateUI.push(
                    <p style={textMsgStyle02} key={i}>{v.text}</p>
                )
            }
        })
        // End of Footer UI
    } catch (error) {
        templateUI.push(
            <NotValidMessage val={"Not Valid Template"} />
        )
    }

    return (<>{templateUI}</>)
}
export const TemplateButtonMessage = (props) => {
    const { buttons } = props
    return (
        <div className="me-3">
            {
                buttons.map((v, i) => {
                    return (
                        <div key={i}>
                            {
                                (buttons[i].type === "URL" && buttons[i].url) ?
                                    <Button variant='contained' className="w-100 rounded-2 mt-1 fw-bold interactive_btns" style={ButtonStyle01}><OpenInNewOutlined style={{ fontSize: "14px" }} /> {v.text}</Button>
                                    :
                                    <Button variant='contained' className="w-100 rounded-2 mt-1 fw-bold interactive_btns" style={ButtonStyle01}>{v.text}</Button>
                            }
                            <br />
                        </div>
                    )
                })
            }
        </div>
    )
}
export const TemplateVariableInputFieldUI = (props) => {
    const { inputFields } = props
    return (
        <>
            {
                inputFields.map((v, i) => {
                    let vCount = ++i
                    return (
                        <>
                            <input text="text" className="form-control mb-2" placeholder={"Variable {{" + vCount + "}}"} />
                        </>
                    )
                })
            }
        </>
    )
}