import React, { useState } from 'react'
import { getCaptionFromMedia, getId, getLinkFromMedia } from './App'
import { List, ListItemButton, ListItemText, Collapse, IconButton, InputAdornment, Button, Tooltip, TextField, FormControl, Select, MenuItem } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { MTextField } from './CustomComponents'
import { MediaUploadDialog } from './Dialogs'
import { ShowSnackbar } from '../../controller/BaseTool'
import { useContext } from 'react'
import chatbotContext from '../../context/chatbot/chatbotContext'
import { WtColor } from '../../constants/colours'
import globalContext from '../../context/global/globalContext'

const RightSidebar = () => {
	const setSelectedNodeUpdated = useContext(chatbotContext)
	const selectedNode = useContext(chatbotContext)
	const actionList = useContext(chatbotContext)

	const openSnackbar = useContext(globalContext)
	const snackbarShow = useContext(globalContext)
	const snackbarClose = useContext(globalContext)

	const customFieldKeys = useContext(chatbotContext);
	// const setCustomFieldKeys = useContext(chatbotContext);

	const [propertiesSwitch, setPropertiesSwitch] = useState(false)
	const handlePropertiesSwitch = () => { setPropertiesSwitch(!propertiesSwitch) }

	const payload = selectedNode.selectedNode?.bot?.payload;
	const alertEmpy = (text) => snackbarShow.snackbarShow(text || 'Empty value not allowed', WtColor.RedColor)

	const keys = selectedNode.selectedNode?.bot?.keys?.join(', ')
	const nextKeys = selectedNode.selectedNode?.bot?.nextKeys?.join(', ')
	const keyWords = selectedNode.selectedNode?.bot?.keyWords?.join(', ')
	const header = payload?.interactive?.header?.text || getLinkFromMedia(payload?.interactive?.header)
	const body = payload?.text?.body || payload?.interactive?.body?.text
	const footer = payload?.interactive?.footer?.text
	const buttons = payload?.interactive?.action?.buttons || []
	// const handlePos = selectedNode.selectedNode?.bot?.handlePos || 'right'
	const menuTitle = payload?.interactive?.action?.button;
	const sections = payload?.interactive?.action?.sections || []
	const buttonId = selectedNode.selectedNode?.bot?.buttonId || ''
	const index = selectedNode.selectedNode?.bot?.index || 0
	const bodyType = payload?.type
	const imageUrl = getLinkFromMedia(payload)
	const caption = getCaptionFromMedia(payload)
	const headerType = payload?.interactive?.header?.type || 'text';
	const catalogId = payload?.interactive?.action?.catalog_id
	const productRetailerId = payload?.interactive?.action?.product_retailer_id


	const updateNode = (node) => {
		const newNode = { ...node }
		setSelectedNodeUpdated.setSelectedNodeUpdated(newNode)
	}

	const updateKeyWords = (evt) => {
		if (evt.target.value !== evt.target.defaultValue) {
			selectedNode.selectedNode.bot.keyWords = evt.target.value.toLowerCase().split(',').filter(e => e.trim() !== '').map(s => s.trim())
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateButtonIds = (evt) => {
		if (evt.target.value !== evt.target.defaultValue) {
			selectedNode.selectedNode.bot.buttonId = evt.target.value
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateKeys = (evt) => {
		if (evt.target.value !== evt.target.defaultValue) {
			selectedNode.selectedNode.bot.keys = evt.target.value.split(',').filter(e => e.trim() !== '').map(s => s.trim())
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateNextKeys = (evt) => {
		if (evt.target.value !== evt.target.defaultValue) {
			selectedNode.selectedNode.bot.nextKeys = evt.target.value.split(',').filter(e => e.trim() !== '').map(s => s.trim())
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateIndex = (evt) => {
		if (evt.target.value !== evt.target.defaultValue) {
			selectedNode.selectedNode.bot.index = Number.parseInt(evt.target.value)
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateHeader = (evt) => {
		const str = evt.target.value.trim()
		if (str.includes('*')) {
			alertEmpy('* not allowed in header')
			evt.target.value = evt.target.defaultValue
		} else if (str !== evt.target.defaultValue) {
			payload.interactive.header = { type: 'text', text: str }
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateBody = (evt) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			if (payload.interactive) {
				payload.interactive.body = str ? { text: str } : undefined
			} else if (payload.text) {
				payload.text = str ? { body: str } : undefined
			}
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateFooter = (evt) => {
		const str = evt.target.value.trim()
		if (str.includes('*')) {
			alertEmpy('* not allowed in footer')
			evt.target.value = evt.target.defaultValue
		} else if (str !== evt.target.defaultValue) {
			payload.interactive.footer = str ? { text: str } : undefined
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateMenuTitle = (evt) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.button = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateButtonsTitle = (evt, index) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			buttons[index].reply.title = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const addButton = () => {
		if (payload.interactive?.action?.buttons.length < 3) {
			payload.interactive?.action?.buttons.push({ type: "reply", reply: { id: getId(), title: "Button Title" } })
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow('Maximum 3 Buttons allowed', WtColor.RedColor) }
	}

	const deleteButton = (evt, index) => {
		if (payload.interactive?.action?.buttons?.length > 1) {
			payload.interactive?.action?.buttons.splice(index, 1)
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow('Minimum 1 Buttons is required', WtColor.RedColor) }
	}

	// section 
	const updateSectionTitle = (evt, index) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.sections[index].title = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const addSection = (evt) => {
		const length = payload.interactive?.action?.sections.flatMap(r => r.rows).length
		if (length < 9) {
			payload.interactive?.action?.sections.push({
				"title": `Section Title ${length + 1}`,
				"rows": [{ "id": getId(), "title": "Title 1", "description": "Description 1" }]
			})
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow('Maximum 10 Buttons allowed', WtColor.RedColor) }
	}

	const deleteSection = (evt, index) => {
		if (payload.interactive?.action?.sections.length > 1) {
			payload.interactive?.action?.sections.splice(index, 1)
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow("Minimum 1 Section is required", WtColor.RedColor) }
	}

	const addSectionButton = (evt, index) => {
		const length = payload.interactive?.action?.sections[index].rows.length
		if (length < 10) {
			payload.interactive?.action?.sections[index].rows.push({ "id": getId(), "title": "Title 1", "description": "Description 1" })
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow("Maximum 10 Buttons allowed", WtColor.RedColor) }
	}

	const deleteSectionButton = (evt, i, j) => {
		if (payload.interactive?.action?.sections[j].rows.length > 1) {
			payload.interactive?.action?.sections[j].rows.splice(i, 1)
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow("Minimum 1 Button is required", WtColor.RedColor) }
	}

	const updateSectionButtonsTitle = (evt, i, j) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.sections[j].rows[i].title = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateSectionButtonsDescription = (evt, i, j) => {
		const str = evt.target.value.trim()
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.sections[j].rows[i].description = str
			updateNode(selectedNode.selectedNode)
		}
	}

	// product section

	const addProductSection = (evt) => {
		const length = payload.interactive?.action?.sections.flatMap(r => r.product_items).length
		if (length < 9) {
			payload.interactive?.action?.sections.push({
				"title": `Section Title ${length + 1}`,
				"product_items": [{ product_retailer_id: '' }]
			})
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow('Maximum 10 Products allowed', WtColor.RedColor) }
	}

	const addProductSectionButton = (evt, index) => {
		const length = payload.interactive?.action?.sections[index].product_items.length
		if (length < 10) {
			payload.interactive?.action?.sections[index].product_items.push({ product_retailer_id: '' })
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow("Maximum 10 Products allowed", WtColor.RedColor) }
	}

	const deleteProductSectionButton = (evt, i, j) => {
		if (payload.interactive?.action?.sections[j].product_items.length > 1) {
			payload.interactive?.action?.sections[j].product_items.splice(i, 1)
			updateNode(selectedNode.selectedNode)
		} else { snackbarShow.snackbarShow("Minimum 1 Products is required", WtColor.RedColor) }
	}

	const updateCatalogId = (evt) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.catalog_id = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateRetailId = (evt) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.product_retailer_id = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateSectionProductTitle = (evt, j) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.sections[j].title = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateSectionProductRetailId = (evt, i, j) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			payload.interactive.action.sections[j].product_items[i].product_retailer_id = str
			updateNode(selectedNode.selectedNode)
		}
	}

	// others
	const onClickButtonCopy = (evt) => {
		const str = evt.target.value.trim()
		navigator.clipboard.writeText(str);
		snackbarShow.snackbarShow('Copied to clicpboard', WtColor.BaseColor)
	}

	const updateButtonTitleIndividual = (evt) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			if (selectedNode.selectedNode.buttonObj.reply?.id) {
				selectedNode.selectedNode.buttonObj.reply.title = str
			} else if (selectedNode.selectedNode.buttonObj.id) {
				selectedNode.selectedNode.buttonObj.title = str
			}
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateButtonDescriptionIndividual = (evt) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			if (selectedNode.selectedNode.buttonObj.id) {
				selectedNode.selectedNode.buttonObj.description = str
			}
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateParameterValue = (evt, i, j) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			selectedNode.selectedNode.bot.payload.template.components[i].parameters[j].text = str
			updateNode(selectedNode.selectedNode)
		}
	}

	const updateImageUrl = (evt, bodyType) => {
		const str = evt.target.value.trim()
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		if (str !== evt.target.defaultValue) {
			['image', 'audio', 'video', 'document'].forEach(e => selectedNode.selectedNode.bot.payload[e] = undefined);
			selectedNode.selectedNode.bot.payload[bodyType] = { link: str };
			updateNode(selectedNode.selectedNode);
		}
	}

	const updateCaption = (evt) => {
		const str = evt.target.value.trim()
		if (str !== evt.target.defaultValue) {
			['image', 'audio', 'video', 'document'].forEach(e => {
				if (selectedNode.selectedNode.bot.payload[e]) {
					selectedNode.selectedNode.bot.payload[e].caption = str;
				}
			});
			updateNode(selectedNode.selectedNode);
		}
	}

	const handleHeaderType = (value) => {
		if (value === "text") {
			payload.interactive.header = { type: 'text', text: '' }
		} else {
			openMediaUpload()
			payload.interactive.header = { type: 'image', text: '' }
		}
		updateNode(selectedNode.selectedNode)
	}

	const handleActionTypeChange = (value) => {
		if (value === 'action_set_custom_field') selectedNode.selectedNode.bot.action = {}
		selectedNode.selectedNode.bot.action.type = value
		updateNode(selectedNode.selectedNode)
	}

	const handleActionCustomFieldKeyChange = (value) => {
		selectedNode.selectedNode.bot.action.fieldKey = value;
		updateNode(selectedNode.selectedNode);
	}

	const handleActionTitleChange = (evt) => {
		const str = evt.target.value.trim();
		if (str === '') {
			evt.target.value = evt.target.defaultValue
			alertEmpy()
			return
		}
		selectedNode.selectedNode.bot.title = str;
		updateNode(selectedNode.selectedNode);
	}

	const updateCustomFieldValue = (evt) => {
		const str = evt.target.value.trim();
		selectedNode.selectedNode.bot.action.fieldValue = str
		updateNode(selectedNode.selectedNode);
	}

	const updateActionWebhookUrl = (evt) => {
		const str = evt.target.value.trim();
		selectedNode.selectedNode.bot.action.webhookUrl = str
		updateNode(selectedNode.selectedNode);
	}

	// webhook params 
	const deleteWebhookParam = (i) => {
		selectedNode.selectedNode?.bot.action.webhookParams?.splice(i, 1);
		updateNode(selectedNode.selectedNode);
	}

	const addWebhookParam = () => {
		let params = selectedNode.selectedNode?.bot.action.webhookParams || [];
		params.push({ key: getId(), value: getId() });
		selectedNode.selectedNode.bot.action.webhookParams = params;
		updateNode(selectedNode.selectedNode);
	}

	const updateWebhookParamKey = (evt, i) => {
		const str = evt.target.value.trim();
		if (str) {
			selectedNode.selectedNode.bot.action.webhookParams[i].key = str
			updateNode(selectedNode.selectedNode);
		}
	}

	const updateWebhookParamValue = (evt, i) => {
		const str = evt.target.value.trim();
		if (str) {
			selectedNode.selectedNode.bot.action.webhookParams[i].value = str
			updateNode(selectedNode.selectedNode);
		}
	}

	let divs = []

	if (selectedNode.selectedNode?.bot?.type === 'action') {
		divs.push(
			<div className="mb-1" style={{ display: "block" }}>
				<div style={{ color: WtColor.TextMidGrey, fontSize: "16px", fontWeight: "500", width: '100%' }}>Action title</div>
				<MTextField
					sx={{ mb: 1, width: '100%' }} size="small" multiline minRows={1} maxRows={1} focused
					defaultValue={selectedNode.selectedNode?.bot.title} value={selectedNode.selectedNode?.bot.title} onBlur={handleActionTitleChange}
					minCount={0} maxCount={30} />
				<div style={{ color: WtColor.TextMidGrey, fontSize: "16px", fontWeight: "500", width: '100%' }}>Action type</div>
				<FormControl fullWidth>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedNode.selectedNode?.bot.action.type}
						size='small'
						onChange={(e) => handleActionTypeChange(e.target.value)}>
						{actionList.actionList.map(e => <MenuItem value={e.action}>{e.title}</MenuItem>)}
					</Select>
				</FormControl>
				{
					selectedNode.selectedNode?.bot.action.type === 'action_set_custom_field' &&
					<div id={getId()} style={{ display: "block", width: '100%' }}>
						<span style={{ color: WtColor.TextMidGrey, fontSize: "16px", fontWeight: "500", width: '100%' }}>Variable key -</span>
						<FormControl fullWidth>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={selectedNode.selectedNode?.bot.action.fieldKey}
								size='small'
								onChange={(e) => handleActionCustomFieldKeyChange(e.target.value)}>
								{customFieldKeys.customFieldKeys.map(e => <MenuItem value={e.field_key}>{e.field_key}</MenuItem>)}
							</Select>
						</FormControl>
						<span style={{ color: WtColor.TextMidGrey, fontSize: "16px", fontWeight: "500", width: '100%' }}>Set value</span>
						<MTextField
							sx={{ mb: 1, width: '100%' }} size="small" multiline minRows={1} maxRows={1} focused
							defaultValue={selectedNode.selectedNode?.bot.action.fieldValue} onBlur={updateCustomFieldValue}
							minCount={0} maxCount={1025} />
					</div>
				}
				{
					selectedNode.selectedNode?.bot.action.type === 'action_input' &&
					<div id={getId()} style={{ display: "block", width: '100%' }}>
						<span style={{ color: WtColor.TextMidGrey, fontSize: "16px", fontWeight: "500", width: '100%' }}>Wait for user input</span>
					</div>
				}
				{
					selectedNode.selectedNode?.bot.action.type === 'action_webhook' &&
					<div id={getId()} style={{ display: "block", width: '100%' }}>
						<span style={{ color: WtColor.TextMidGrey, fontSize: "16px", fontWeight: "500", width: '100%' }}>Webhook URL</span>
						<MTextField
							sx={{ mb: 1, width: '100%' }} size="small" multiline minRows={1} maxRows={1} focused
							defaultValue={selectedNode.selectedNode?.bot.action.webhookUrl} onBlur={updateActionWebhookUrl}
							minCount={0} maxCount={1025} />

						{
							selectedNode.selectedNode?.bot.action.webhookParams?.map((obj, i) => {
								return <div key={getId()} style={{ display: 'flex' }}>
									<div>
										<MTextField
											label={'Key'} sx={{ mb: 3, width: '47%' }} size="small" key={getId()}
											defaultValue={`${obj.key}`} onBlur={evt => updateWebhookParamKey(evt, i)} minCount={0} maxCount={20} />
										<MTextField
											label={'Value'} sx={{ paddingLeft: '5px', mb: 3, width: '47%' }} size="small" key={getId()}
											defaultValue={`${obj.value}`} onBlur={evt => updateWebhookParamValue(evt, i)} minCount={0} maxCount={72} />
									</div>
									<div>
										<IconButton><CloseOutlinedIcon key={getId()} onClick={evt => deleteWebhookParam(i)} /></IconButton>
									</div>
								</div >
							})
						}

						<Button size='small' style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor, borderRadius: "6px", textTransform: "none", boxShadow: "none" }} variant='contained' onClick={addWebhookParam}>Add parameter</Button>
					</div>
				}
			</div>
		);
	}


	if (selectedNode.selectedNode?.extent === 'parent') {
		divs.push(
			<TextField
				label={'Button Id'} size="small" sx={{ mb: 3, width: '100%' }} className="hand" disabled
				key={getId()} defaultValue={selectedNode.selectedNode.id} onClick={onClickButtonCopy}
				InputLabelProps={{ shrink: false }}
				InputProps={{
					endAdornment: <InputAdornment position="end"><ContentCopyOutlinedIcon style={{ color: WtColor.TextLightGrey }} /></InputAdornment>,
				}}
			/>
		)
		if (selectedNode.selectedNode.buttonObj) {
			if (selectedNode.selectedNode.buttonObj.reply?.title) {
				divs.push(
					<MTextField
						sx={{ mb: 3, width: '100%' }} size="small" key={getId()}
						defaultValue={selectedNode.selectedNode.buttonObj.reply?.title} onBlur={(evt) => updateButtonTitleIndividual(evt)} minCount={0} maxCount={20} />
				)
			}
			if (selectedNode.selectedNode.buttonObj?.title) {
				divs.push(
					<MTextField
						sx={{ mb: 3, width: '100%' }} size="small" key={getId()}
						defaultValue={selectedNode.selectedNode.buttonObj.title} onBlur={(evt) => updateButtonTitleIndividual(evt)} minCount={0} maxCount={20} />
				)
				divs.push(
					<MTextField
						sx={{ mb: 3, width: '100%' }} size="small" multiline minRows={3} maxRows={50} key={getId()}
						defaultValue={selectedNode.selectedNode.buttonObj.description} onBlur={(evt) => updateButtonDescriptionIndividual(evt)} minCount={0} maxCount={72} />
				)
			}
		}
	}

	if (selectedNode.selectedNode?.bot) {
		const headerMedia = headerType === 'text' ? 'text' : 'media'
		if (selectedNode.selectedNode.bot?.payload) {
			divs.push(
				<div key={getId()}>
					{
						(payload?.interactive?.header) &&
						<>
							<div className="mb-1" style={{ display: "flex" }}>
								<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Header </div>
								{
									selectedNode.selectedNode.bot?.payload?.interactive?.type !== 'product_list' &&
									<div style={{ fontSize: "16px", fontWeight: "500" }} className="me-2 hand">
										<Tooltip title='Select header type' placement='bottom'>
											<select selected="text" defaultValue={headerMedia} onChange={(e) => handleHeaderType(e.target.value)} style={{ textDecoration: "none", outline: "none", border: "1px solid " + WtColor.CloudyGrey, borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px', color: WtColor.TextLightGrey }}>
												<option value="text">Text</option>
												<option value="media">Media</option>
											</select>
										</Tooltip>
									</div>
								}
								<div>
									<Tooltip title="Enter header information" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip>
								</div>
							</div>
							{
								(() => {
									if (headerType === "text") {
										return (
											<MTextField
												sx={{ mb: 3, width: '100%' }} size="small"
												defaultValue={header} onBlur={updateHeader} minCount={0} maxCount={60} />
										)
									}
									if (headerType === "image") {
										return (
											<TextField
												label="Header" sx={{ mb: 3, width: '100%' }} size="small" placeholder="Select Image" focused
												defaultValue={header} onBlur={updateHeader}
												InputLabelProps={{ shrink: false }}
												InputProps={{
													endAdornment: <InputAdornment position="end"
														onClick={() => openMediaUpload()}
													><CollectionsOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey }} /></InputAdornment>,
												}}
											/>
										)
									}
								})()
							}

						</>
					}
					{
						body &&
						<>
							<div className="mb-1" style={{ display: "flex" }}>
								<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Body</div>
								<div><Tooltip title="Enter body information" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
							</div>
							<MTextField
								sx={{ mb: 3, width: '100%' }} size="small" multiline minRows={4} maxRows={100} focused
								defaultValue={body} onBlur={updateBody}
								minCount={0} maxCount={1025}
							/>
						</>
					}
					{
						payload?.interactive &&
						<>
							<div className="mb-1" style={{ display: "flex" }}>
								<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Footer</div>
								<div><Tooltip title="Enter footer information" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
							</div>
							<MTextField
								sx={{ mb: 3, width: '100%' }} size="small" multiline minRows={1} maxRows={50}
								defaultValue={footer} onBlur={updateFooter} minCount={0} maxCount={60} />
						</>
					}
					{
						payload?.interactive?.action?.button &&
						<>
							<div className="mb-1" style={{ display: "flex" }}>
								<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Menu Button Title</div>
								<div><Tooltip title="Menu Button Title" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
							</div>
							<MTextField
								sx={{ mb: 3, width: '100%' }} size="small"
								defaultValue={menuTitle} onBlur={updateMenuTitle} minCount={0} maxCount={20} />
						</>
					}
					{
						catalogId &&
						<>
							<div className="mb-1" style={{ display: "flex" }}>
								<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Product Catalog Id</div>
								<div><Tooltip title="Product Catalog Id" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
							</div>
							<MTextField
								sx={{ mb: 3, width: '100%' }} size="small"
								defaultValue={catalogId} onBlur={updateCatalogId} minCount={0} maxCount={20} />
						</>
					}
					{
						productRetailerId &&
						<>
							<div className="mb-1" style={{ display: "flex" }}>
								<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Product Retail Id</div>
								<div><Tooltip title="Product Retail Id" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
							</div>
							<MTextField
								sx={{ mb: 3, width: '100%' }} size="small"
								defaultValue={productRetailerId} onBlur={updateRetailId} minCount={0} maxCount={20} />
						</>
					}
				</div>
			)
		}

		if (payload?.interactive) {
			if (buttons.length > 0) {
				divs.push(<div key={getId()} className='dndflow_section mb-3' style={{ fontSize: "16px", fontWeight: "500" }}>Buttons<br /></div>)
				buttons.forEach((btn, i) => {
					divs.push(
						<div style={{ display: "flex" }}>
							<MTextField
								label={"Button Title"} sx={{ mb: 1, width: '100%' }} size="small" key={getId()}
								defaultValue={btn.reply.title} onBlur={evt => updateButtonsTitle(evt, i)} minCount={0} maxCount={20} />
							<div className="ms-2">
								<IconButton key={getId()} onClick={e => deleteButton(e, i)}><CloseOutlinedIcon /></IconButton>
							</div>
						</div>
					)
				})
				divs.push(
					<div>
						<Button key={getId()} disabled={buttons?.length >= 3 ? true : false} size='small' style={{ display: buttons?.length >= 3 ? 'none' : 'block', color: WtColor.TextMidGrey, background: WtColor.LineColor, borderRadius: "6px", textTransform: "none", boxShadow: "none" }} variant='contained' onClick={addButton} >Add Another Button</Button>
					</div>
				)
			}

			if (sections.length > 0 && !catalogId) {
				sections.forEach((sec, j) => {
					divs.push(
						<div key={getId()} style={{ display: 'flex' }}>
							<MTextField
								label={`Section ${j + 1} Title`} sx={{ mb: 3, width: '100%' }} size="small" key={getId()}
								defaultValue={sec.title} id={j} onBlur={e => updateSectionTitle(e, j)} minCount={0} maxCount={60} />
							<div className="ms-2">
								<IconButton key={getId()} onClick={e => deleteSection(e, j)}><CloseOutlinedIcon /></IconButton>
							</div>
						</div>
					)
					const secButtons = sec.rows

					secButtons.forEach((btn, i) => {
						divs.push(
							<div key={getId()} style={{ paddingLeft: '20px', display: 'flex' }}>
								<div>
									<MTextField
										label={`Button ${i + 1} Title`} sx={{ mb: 3, width: '100%' }} size="small" key={getId()}
										defaultValue={btn.title} onBlur={e => updateSectionButtonsTitle(e, i, j)} minCount={0} maxCount={20} />
									<MTextField
										label={`Button ${i + 1} Description`} sx={{ mb: 3, width: '100%' }} size="small" key={getId()} multiline minRows={3} maxRows={50}
										defaultValue={btn.description} onBlur={e => updateSectionButtonsDescription(e, i, j)} minCount={0} maxCount={72} />
								</div>
								<div>
									<IconButton><CloseOutlinedIcon key={getId()} onClick={e => deleteSectionButton(e, i, j)} /></IconButton>
								</div>
							</div >
						)
					});
					divs.push(
						<div key={getId()} style={{ paddingLeft: '30px' }}>
							<Button size='small' style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor, borderRadius: "6px", textTransform: "none", boxShadow: "none" }} variant='contained' id={j} onClick={(evt) => addSectionButton(evt, j)}>Add button</Button>
						</div>
					)
					divs.push(<hr className="mb-4" />)
				});
				divs.push(
					<div key={getId()} >
						<Button size='small' style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor, borderRadius: "6px", textTransform: "none", boxShadow: "none" }} variant='contained' onClick={addSection} >Add section</Button>
					</div>
				)
			}

			if (sections.length > 0 && catalogId) {
				sections.forEach((sec, j) => {
					divs.push(
						<div key={getId()} style={{ display: 'flex' }}>
							<MTextField
								label={`Section ${j + 1} Title`} sx={{ mb: 3, width: '100%' }} size="small" key={getId()}
								defaultValue={sec.title} id={j} onBlur={e => updateSectionProductTitle(e, j)} minCount={0} maxCount={60} />
							<div className="ms-2">
								<IconButton key={getId()} onClick={e => deleteSection(e, j)}><CloseOutlinedIcon /></IconButton>
							</div>
						</div>
					)
					const secProducts = sec.product_items
					console.log("Products", secProducts)
					secProducts.forEach((product, i) => {
						console.log("Product-id", product)
						divs.push(
							<div key={getId()} style={{ paddingLeft: '20px', display: 'flex' }}>
								<div>
									<MTextField
										label={`Product Retail Id ${i + 1}`} sx={{ mb: 3, width: '100%' }} size="small" key={getId()}
										defaultValue={product.product_retailer_id} onBlur={e => updateSectionProductRetailId(e, i, j)} minCount={0} maxCount={20} />

								</div>
								<div>
									<IconButton><CloseOutlinedIcon key={getId()} onClick={e => deleteProductSectionButton(e, i, j)} /></IconButton>
								</div>
							</div >
						)
					});
					divs.push(
						<div key={getId()} style={{ paddingLeft: '30px' }}>
							<Button size='small' style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor, borderRadius: "6px", textTransform: "none", boxShadow: "none" }} variant='contained' id={j} onClick={(evt) => addProductSectionButton(evt, j)}>Add button</Button>
						</div>
					)
					divs.push(<hr className="mb-4" />)
				});
				divs.push(
					<div key={getId()} >
						<Button size='small' style={{ color: WtColor.TextMidGrey, background: WtColor.LineColor, borderRadius: "6px", textTransform: "none", boxShadow: "none" }} variant='contained' onClick={addProductSection} >Add section</Button>
					</div>
				)
			}
		}

		if (['image', 'audio', 'video', 'document'].includes(bodyType)) {
			divs.push(
				<div>
					<div className="mb-1" style={{ display: "flex" }}>
						<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>{bodyType.capitalize() + ' URL'}</div>
						<div><Tooltip title={bodyType + ' URL'} placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
					</div>
					<TextField
						label={bodyType + ' URL'} sx={{ mb: 3, width: '100%' }} size="small" placeholder="Select Image" focused
						defaultValue={imageUrl} value={imageUrl} onBlur={e => updateImageUrl(e, bodyType)}
						InputLabelProps={{ shrink: false }}
						InputProps={{
							endAdornment: <InputAdornment position="end"
								onClick={() => openMediaUpload()}>
								<CollectionsOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey }} /></InputAdornment>,
						}}
					/>
				</div>
			)
		}

		if (['image', 'video', 'document'].includes(bodyType)) {
			divs.push(
				<div>
					<div className="mb-1 mt-3" style={{ display: "flex" }}>
						<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Caption</div>
						<div><Tooltip title="Caption" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
					</div>
					<TextField fullWidth size='small' label={'Caption'} defaultValue={caption} onBlur={updateCaption} InputLabelProps={{ shrink: false }} focused />
				</div>
			)
		}

		if (selectedNode.selectedNode.bot?.buttonId) {
			divs.push(
				<>
					<div className="mb-1" style={{ display: "flex" }}>
						<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>External Button Id / Title</div>
						<div><Tooltip title="External Button Id / Title" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
					</div>
					<TextField key={getId()} size='small' label={"Abc"} fullWidth defaultValue={buttonId} InputLabelProps={{ shrink: false }} onBlur={updateButtonIds} focused />
				</>

			)
		}

		selectedNode.selectedNode?.bot?.payload?.template?.components?.forEach((comp, i) => {
			comp.parameters?.forEach((p, j) => {
				if (p.text) {
					divs.push(<TextField key={getId()} size='small' fullWidth label={`Component: ${comp.type} Type: ${p.type} Parameter: ${(j + 1)}`} defaultValue={p.text} InputLabelProps={{ shrink: false }} style={{ marginBottom: 15 }} onBlur={(evt) => updateParameterValue(evt, i, j)} focused />)
				}
			})
		})

		divs.push(
			<div className="propertiesSection">
				<List sx={{ width: '100%', maxWidth: 400 }} component="nav" aria-labelledby="nested-list-subheader">
					<ListItemButton onClick={handlePropertiesSwitch}>
						<ListItemText primary={"Detailed properties"} style={{ fontWeight: "500" }} />
						{propertiesSwitch ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={propertiesSwitch} timeout="auto" unmountOnExit>
						<div className="pe-3 ps-3">
							{
								(() => {
									let listItems = [];

									if (payload || selectedNode.selectedNode?.bot?.type === 'action') {
										listItems.push(
											<div key={getId()}>
												<div style={{ display: 'none' }}>
													<div className="mb-1" style={{ display: "flex" }}>
														<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Show this node when</div>
														<div><Tooltip title="Node handling" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
													</div>
													<div className="mb-1" style={{ display: 'flex', fontSize: '15px', color: WtColor.TextMidGrey }}>
														<div style={{ flex: 1 }} className="me-2">
															<label htmlFor='select-type'>Select type</label>
															<select id='select-type' style={{ textDecoration: "none", outline: "none", border: "1px solid " + WtColor.CloudyGrey, borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px', color: WtColor.TextLightGrey }}>
																{['text', 'number'].map((v, i) => { return (<option value={v}>{v}</option>) })}
															</select>
														</div>
														<div>
															<label htmlFor='select-condition'>Select condition</label>
															<select id='select-condition' style={{ textDecoration: "none", outline: "none", border: "1px solid " + WtColor.CloudyGrey, borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px', color: WtColor.TextLightGrey }}>
																{[
																	{ condition: 'equal to', expression: 'eq' },
																	{ condition: 'starts with', expression: 'starts_with' },
																	{ condition: 'ends with', expression: 'ends_with' },
																	{ condition: 'contains', expression: 'contains' },
																].map((v, i) => { return (<option value={v.expression}>{v.condition}</option>) })}
															</select>
														</div>
													</div>
													<TextField size='small' fullWidth label={'Keywords to Match'} placeholder="Enter keywords, seperate it by commas" InputLabelProps={{ shrink: false }} style={{ marginBottom: 15 }} focused />
												</div>
												<div className="mb-1" style={{ display: "flex" }}>
													<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Keywords to Match</div>
													<div><Tooltip title="Keywords to Match" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
												</div>
												<TextField size='small' fullWidth label={'Keywords to Match'} placeholder="Enter keywords, seperate it by commas" defaultValue={keyWords} onBlur={updateKeyWords} InputLabelProps={{ shrink: false }} style={{ marginBottom: 15 }} focused />
												<div className="mb-1" style={{ display: "flex" }}>
													<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Identifiers</div>
													<div><Tooltip title="Button Ids" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
												</div>
												<TextField size='small' fullWidth label={'Button Ids'} placeholder="Enter button ids, seperate it by commas" defaultValue={keys} onBlur={updateKeys} InputLabelProps={{ shrink: false }} style={{ marginBottom: 15 }} focused />
												<div className="mb-1" style={{ display: "flex" }}>
													<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Next Identifiers</div>
													<div><Tooltip title="Button Ids" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
												</div>
												<TextField size='small' fullWidth label={'Button Ids'} placeholder="Enter next ids, seperate it by commas" defaultValue={nextKeys} onBlur={updateNextKeys} InputLabelProps={{ shrink: false }} style={{ marginBottom: 15 }} focused />
												<div className="mb-1" style={{ display: "flex" }}>
													<div style={{ color: WtColor.TextMidGrey, flex: 1, fontSize: "16px", fontWeight: "500" }}>Message Order</div>
													<div><Tooltip title="Message Order" placement="left"><InfoOutlinedIcon className="hand" style={{ color: WtColor.TextLightGrey, fontSize: "16px" }} /></Tooltip></div>
												</div>
												<TextField size='small' fullWidth type='number' label='Message Order' placeholder='Enter Message Order' defaultValue={index} onChange={updateIndex} InputLabelProps={{ shrink: false }} style={{ marginBottom: 15 }} focused />
											</div>
										)
									}

									return listItems
								})()
							}
						</div>
					</Collapse>
				</List>
			</div>
		)
	}


	// // Snackbar Code
	// const [errorMsg, setErrorMsg] = useState('')
	// const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)
	// const [openSnackbar, setOpenSnackbar] = useState(false)
	// const SnackbarShow = (errorMsg, errorMsgBg) => { setErrorMsg(errorMsg); setErrorMsgBg(errorMsgBg); setOpenSnackbar(true) }
	// const SnackbarClose = () => { setOpenSnackbar(false) }
	// // End of Snackbar Code

	const onSelectMedia = (media) => {
		let type = ''
		if (media.mimetype.includes('image')) {
			type = 'image'
		} else if (media.mimetype.includes('video')) {
			type = 'video'
		} else if (media.mimetype.includes('pdf')) {
			type = 'document'
		} else if (media.mimetype.includes('audio')) {
			type = 'audio'
		}

		if (type) {
			if (payload.interactive) {
				payload.interactive.header = { type: type };
				payload.interactive.header[type] = { link: media.link };
			} else {
				payload.type = type;
				if (payload[type]) {
					payload[type].link = media.link;
				} else {
					payload[type] = { link: media.link };
				}
			}
			updateNode(selectedNode.selectedNode);
		}
	}

	const [openMediaUploadDialog, setOpenMediaUploadDialog] = useState({ open: false, onClose: null, onSelectMedia: null })
	const openMediaUpload = () => {
		setOpenMediaUploadDialog({
			open: true,
			onClose: () => setOpenMediaUploadDialog(false),
			onSelectMedia: onSelectMedia
		})
	}
	return (
		<aside className="rightSideBarMenu" style={{ overflowX: "hidden", height: "100%" }}>
			<MediaUploadDialog open={openMediaUploadDialog.open} onClose={openMediaUploadDialog.onClose} onSelectMedia={openMediaUploadDialog.onSelectMedia} />
			<div className='dndflow_section mb-3' style={{ fontSize: "16px", fontWeight: "500" }}>Set Message</div>
			{divs}
			{openSnackbar.openSnackbar.open && <ShowSnackbar open={openSnackbar.openSnackbar.open} errorMsg={openSnackbar.openSnackbar.errorMsg} errorMsgBg={openSnackbar.openSnackbar.errorMsgBg} close={snackbarClose.snackbarClose} />}
		</aside >
	)
}

export default RightSidebar