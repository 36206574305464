/* eslint-disable react-hooks/exhaustive-deps */
import Form from "react-bootstrap/Form";
import { Env } from "../../controller/constant";
import { Card, Col, Container, Row, Button, Collapse } from "react-bootstrap";
import "./index.css";
import { WtTheme } from "../../controller/theme";
import { useState } from "react";
import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { WtColor } from "../../constants/colours";

export const Wtd = () => {
  const [comboRadio, setcombo] = useState("");
  const [desktopRadio, desktop] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [currency, setcurrency] = useState([]);
  const [paymentGateway, setPaymentGateway] = useState("razorpay");
  const [isEmailshow, setIsEmailShow] = useState(false);
  const [getId, setId] = useState(undefined);
  const [getEmail, setEmail] = useState(undefined);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [price, setPrice] = useState(undefined);



  const handleToClose = () => {
    setOpen(false);
  };

  const successBtnHandle = () => {
    setSuccess(false);
  };

  const header = {
    authorization: "wtd5As987dZe78Xm2j",
  };

  const init = () => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("id");
    const payment_intent = params.get("payment_intent");
    if (paramValue) {
      setIsEmailShow(false);
      setId(paramValue);
    } else {
      setIsEmailShow(true);
    }
    if (payment_intent) {
      setSuccess(true);
    }
  };

  const requestOptions = (razorpayObj) => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "wtd5As987dZe78Xm2j");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    if (getId) {
      urlencoded.append("userId", getId);
    } else {
      urlencoded.append("email", getEmail);
    }

    urlencoded.append("validity", razorpayObj.validity);
    urlencoded.append("gateway", paymentGateway);
    urlencoded.append("deviceType", razorpayObj.type.toLowerCase());

    return {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
  };

  const requestVerifyOptions = (razorpayObj) => {
    let myHeaders = new Headers();
    myHeaders.append("authorization", "wtd5As987dZe78Xm2j");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    console.log("VerifyHeaders", razorpayObj.razorpay_payment_id);
    let urlencoded = new URLSearchParams();
    urlencoded.append("razorpayPaymentId", razorpayObj.razorpay_payment_id);
    urlencoded.append("razorpayOrderId", razorpayObj.razorpay_order_id);
    urlencoded.append("razorpaySignature", razorpayObj.razorpay_signature);
    console.log(myHeaders, myHeaders);
    console.log("urlencoded", urlencoded);

    return {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
  };

  const handleAlertDialog = (result) => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (result.message === "User not found") {
        return "This email id is not registed in desktop app. Please register by downloading the desktop app";
      } else {
        return result.message;
      }
    }
  };

  const handlePaymentOption = async (razorpayObj) => {
    if (paymentGateway === "razorpay") {
      function loadScript(src) {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
      }
      async function displayRazorpay() {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Razorpay SDK failed to load. Are you online?");
          return;
        }

        fetch(
          Env.baseApiCallURL + razorpayObj.paymentUrl,
          requestOptions(razorpayObj)
        )
          .then((response) => response.json())
          .then((result) => {
            console.log("result: " + result);
            if (result.status !== 200) {
              alert(handleAlertDialog(result));
              return;
            }
            const data = result.data;

            const { amount, id: order_id, currency } = data;
            // console.log(result)

            const options = {
              key: Env.razorpayKey,
              amount: amount,
              currency: currency,
              name: WtTheme.SiteName,
              description: razorpayObj.planName,
              order_id: order_id,
              handler: async function (data) {
                verifyPayment(data);
              },
              prefill: {
                name: localStorage.getItem("userName"),
                email: localStorage.getItem("userEmail"),
                contact: "",
              },
              notes: { address: "" },
              theme: { color: WtColor.BaseColor },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          })
          .catch((error) => {
            console.log("error", error.message);
            alert("error", error.message);
          });
      }
      await displayRazorpay();
    }
    if (paymentGateway === "stripe") {
      console.log("payment gateway", paymentGateway);

      fetch(
        Env.baseApiCallURL + razorpayObj.paymentUrl,
        requestOptions(razorpayObj)
      )
        .then((response) => response.json())
        .then((result) => {
          const data = result.data;
          setClientSecret(data.clientSecret);
          setOpen(true);
          setSuccess(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const verifyPayment = async (data) => {
    console.log("verify payment", requestVerifyOptions(data));
    const components = requestVerifyOptions(data);
    fetch(Env.baseApiCallURL + "/wtd/verify", components)
      .then((response) => response.json())
      .then((result) => {
        console.log("verify: ", result);
        if (result.status === 200) {
          setSuccess(true);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const getWTDPlan = async () => {
    try {
      fetch(Env.baseApiCallURL + "/wtd/plan-list", {
        method: "GET",
        headers: header,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            console.log("data: " + data.data);
            setcurrency(data.data);
          }
        });
    } catch (error) {
      console.log("🔴fetchWTDPayment");
    }
  };

  const fetchCountry = async () => {
    try {
      fetch("https://iplist.cc/api")
        .then((response) => response.json())
        .then((data) => {
          setCountryCode(data.countrycode);
          if (data.countrycode === "IN") {
            setPaymentGateway("razorpay");
          } else {
            setPaymentGateway("stripe");
          }

          console.log("countryCode: " + data.countrycode);
        });
    } catch (error) { }
  };

  const currencyRadioSelect = (e) => {
    setCountryCode(e.target.value);
    setComboLifeTimeLabel();
    console.log("currencyRadio", e.target.value);
  };

  const handlecomboRadio = (e) => {
    setcombo(e.target.value);
    const index = currency.findIndex((currency) => currency.name === "Combo");
    if (e.target.value === "yearly") {
      setPrice(currency[index].yearlyPrice);
    } else {
      setPrice(currency[index].lifetimePrice);
    }
    console.log("comboPlanClick", e.target.value + ", " + index);
  };

  const handlecomboBtn = () => {
    console.log("handlecombo");
    const index = currency.findIndex((currency) => currency.name === "Combo");
    if (
      (getId === null && getEmail === null) ||
      (getId === undefined && getEmail === undefined)
    ) {
      alert("Please Enter your WhatsTool Desktop Registration Email!");
    } else {
      if (comboRadio === "") {
        alert("Please select Plan Yearly or Lifetime");
      } else {
        let razorpayPaymentObj = {
          paymentUrl: "/wtd/payments/desktop",
          planId: currency[index]._id.toString,
          validity: comboRadio,
          type: "Combo",
        };
        handlePaymentOption(razorpayPaymentObj);
      }
    }
  };

  const onWritingEmail = (e) => {
    console.log("writingEmail", e.target.value);
    setEmail(e.target.value);
    console.log("writingEmail", e.target.value);
  };

  const handleDesktopBuyBtn = () => {
    console.log("handleDesktopBuyBtn");
    const index = currency.findIndex((currency) => currency.name === "Desktop");
    if (
      (getId === null && getEmail === null) ||
      (getId === undefined && getEmail === undefined)
    ) {
      alert("Please Enter your WhatsTool Desktop Registration Email!");
    } else {
      if (desktopRadio === "") {
        alert("Please select Plan Yearly or Lifetime");
      } else {
        let razorpayPaymentObj = {
          paymentUrl: "/wtd/payments/desktop",
          planId: currency[index]._id.toString,
          validity: desktopRadio,
          type: "Desktop",
        };
        handlePaymentOption(razorpayPaymentObj);
      }
    }
  };

  const totalpayment = () => {
    return `Total Amount: ₹${price}`;
  };

  const handleDesktopRadio = (e) => {
    desktop(e.target.value);
    const index = currency.findIndex((currency) => currency.name === "Desktop");
    if (e.target.value === "yearly") {
      setPrice(currency[index].yearlyPrice);
    } else {
      setPrice(currency[index].lifetimePrice);
    }
    console.log("comboPlanClick", e.target.value);
  };

  const handledownloadBtn = () => {
    window.location.href =
      "https://whatstool.in/app/DesktopApp/WhatsToolSetup.msi";
  };

  const setComboLifeTimeLabel = () => {
    const index = currency.findIndex((e) => e.name === "Combo");
    let labelText = "Lifetime Plan";
    console.log(index, currency);
    // return labelText
    if (countryCode === "IN") {
      labelText = "Lifetime plan ₹" + currency[index]?.lifetimePrice;
      return labelText;
    } else {
      labelText =
        "Lifetime plan $" + Math.round(currency[index]?.lifetimePrice / 77);
      return labelText;
    }
  };

  const setDesktopLifeTimeLabel = () => {
    const index = currency.findIndex((e) => e.name === "Desktop");
    let labelText = "Lifetime Plan";
    console.log(index, currency);
    // return labelText
    if (countryCode === "IN") {
      labelText = "Lifetime plan ₹" + currency[index]?.lifetimePrice;
      return labelText;
    } else {
      labelText =
        "Lifetime plan $" + Math.round(currency[index]?.lifetimePrice / 77);
      return labelText;
    }
  };

  const setDesktopYearlyLabel = () => {
    const index = currency.findIndex((e) => e.name === "Desktop");
    let labelText = "Lifetime Plan";
    console.log(index, currency);
    // return labelText
    if (countryCode === "IN") {
      labelText = "Yearly plan ₹" + currency[index]?.yearlyPrice;
      return labelText;
    } else {
      labelText =
        "Yearly plan $" + Math.round(currency[index]?.yearlyPrice / 77);
      return labelText;
    }
  };

  const setComboYearlyLabel = () => {
    const index = currency.findIndex((e) => e.name === "Combo");
    let labelText = "Lifetime Plan";
    console.log(index, currency);
    // return labelText
    if (countryCode === "IN") {
      labelText = "Yearly plan ₹" + currency[index]?.yearlyPrice;
      return labelText;
    } else {
      labelText =
        "Yearly plan $" + Math.round(currency[index]?.yearlyPrice / 77);
      return labelText;
    }
  };

  useEffect(() => {
    init();
    getWTDPlan();
    fetchCountry();
  }, []);

  useEffect(() => {
    setStripePromise(loadStripe(Env.stripePublishableKey));
  }, []);

  return (
    <section style={{ marginTop: "20px" }} className="wtd mb-5">
      <Container>
        <img
          src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/website_new/Temp/logo_text_dark.jpg"
          width={200}
          className="img-fluid"
          alt={WtTheme.SiteName}
        />
      </Container>
      <Container style={{ marginTop: "50px" }} className="wtd mb-5">
        <Row>
          <Col lg={5} md={5} sm={12} xs={12}>
            <Row>
              <h2 className="text-success">WhatsTool Desktop</h2>
            </Row>
            <Row>
              <h3 className="text-success">Upgrade</h3>
            </Row>
            <Row>
              <h4 className="text-success"> to Premium</h4>
            </Row>
            <Row className="text-center">
              <Container style={{ marginTop: "20px" }}>
                <div
                  className="card"
                  style={{
                    backgroundColor: "#05713f",
                    width: "16rem",
                    height: "4rem",
                  }}
                  onClick={() => handledownloadBtn()}
                >
                  <Row>
                    <Col lg={2} md={2} sm={3} xs={3}>
                      <div>
                        <img
                          src="https://www.freepnglogos.com/uploads/windows-logo-png/windows-logo-logok-0.png"
                          height="70px"
                          width="70px"
                          alt={WtTheme.SiteName}
                        />
                      </div>
                    </Col>
                    <Col lg={10} md={10} sm={9} xs={9}>
                      <p
                        className="text-center"
                        style={{ marginTop: "19px", color: "white" }}
                      >
                        Download for Windows
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Row>
            <p style={{ fontSize: 12, marginTop: "10px", width: "16rem" }}>
              <strong>New User:</strong> Download the desktop app and login
              before making the payment. Logged-in
            </p>

            <p style={{ fontSize: 12, marginTop: "10px", width: "16rem" }}>
              <strong>Logged-in User:</strong> Enter your registered Email id
              and make the payment. On successful payment it will be auto
              upgraded to premium.
            </p>

            <br />
          </Col>
          <Col lg={7} md={7}>
            <Container>
              <Card>
                <p className="text-center">
                  <div
                    style={{
                      boxShadow: "0px 1px 3px 0px #ddd",
                      padding: "10px",
                    }}
                  >
                    <Form>
                      <Form.Check
                        inline
                        type="radio"
                        id="indiaCurrency"
                        name="cdolarCurrency"
                        label="Indian Rupees"
                        value="IN"
                        checked={countryCode === "IN"}
                        onChange={(e) => currencyRadioSelect(e)}
                      ></Form.Check>
                      <Form.Check
                        inline
                        type="radio"
                        id="dolarCurrency"
                        name="cdolarCurrency"
                        label="US Dollar"
                        value="other"
                        checked={countryCode !== "IN"}
                        onChange={(e) => currencyRadioSelect(e)}
                      ></Form.Check>
                    </Form>
                  </div>
                </p>

                <p className="text-center">
                  <Collapse in={isEmailshow}>
                    <Container>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Enter Your Registered email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter registered email id from desktop app"
                            className="text-center"
                            onChange={(e) => onWritingEmail(e)}
                          />
                          <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                          </Form.Text>
                        </Form.Group>
                      </Form>
                    </Container>
                  </Collapse>
                </p>

                <Row>
                  <Col lg={6}>
                    <Container className="mt-2 mb-2">
                      <div
                        style={{
                          boxShadow: "0px 1px 3px 0px #ddd",
                          padding: "10px",
                        }}
                      >
                        <p className="text-center">
                          <h3>Combo Plan</h3>
                          <h3>Mobile + Desktop</h3>
                          <Container style={{ marginTop: "20px" }}>
                            <Form>
                              <Form.Check
                                type="radio"
                                id="comboYearlyPlan"
                                name="comboYearlyPlan"
                                label={setComboYearlyLabel()}
                                value="yearly"
                                onChange={(e) => handlecomboRadio(e)}
                              ></Form.Check>
                              <Form.Check
                                type="radio"
                                id="comboLifeTimePlan"
                                name="comboYearlyPlan"
                                label={setComboLifeTimeLabel()}
                                value="lifetime"
                                onChange={(e) => handlecomboRadio(e)}
                              ></Form.Check>
                            </Form>
                            <div>
                              <p
                                className="text-center"
                                style={{
                                  marginTop: "20px",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                <Button
                                  id="comboBuyBtn"
                                  variant="success"
                                  className="ps-4 pe-4"
                                  size="sm"
                                  onClick={() => handlecomboBtn()}
                                >
                                  Buy Now
                                </Button>
                              </p>
                            </div>
                          </Container>
                        </p>
                      </div>
                    </Container>
                  </Col>

                  <Col>
                    <Container className="mt-2 mb-2">
                      <div
                        style={{
                          boxShadow: "0px 1px 3px 0px #ddd",
                          padding: "10px",
                        }}
                      >
                        <p className="text-center">
                          <h3>Desktop Plan</h3>
                          <h3>For Windows</h3>
                          <Container style={{ marginTop: "20px" }}>
                            <Form>
                              <Form.Check
                                type="radio"
                                id="desktopYearlyPlan"
                                name="desktopYearlyPlan"
                                label={setDesktopYearlyLabel()}
                                value="yearly"
                                onChange={(e) => {
                                  handleDesktopRadio(e);
                                }}
                              ></Form.Check>
                              <Form.Check
                                type="radio"
                                id="desktopLifeTimePlan"
                                name="desktopYearlyPlan"
                                label={setDesktopLifeTimeLabel()}
                                value="lifetime"
                                onChange={(e) => {
                                  handleDesktopRadio(e);
                                }}
                              ></Form.Check>
                            </Form>
                            <div>
                              <p
                                className="text-center"
                                style={{
                                  marginTop: "20px",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                <Button
                                  id="desktopBuyBtn"
                                  variant="success"
                                  className="ps-4 pe-4"
                                  size="sm"
                                  onClick={() => {
                                    handleDesktopBuyBtn();
                                  }}
                                >
                                  Buy Now
                                </Button>
                              </p>
                            </div>
                          </Container>
                        </p>
                      </div>
                    </Container>
                  </Col>
                </Row>
              </Card>
            </Container>
          </Col>
        </Row>
        <div>
          <Dialog open={open} onClose={handleToClose}>
            <DialogContent>
              {clientSecret && stripePromise && (
                <div className="paymentPage">
                  <h1 className="text-center">WhatsTool Desktop Payment</h1>
                  <center>
                    <div className="paymentBox">
                      <Elements
                        stripe={stripePromise}
                        options={{ clientSecret }}
                      >
                        <div
                          className="mt-3"
                          style={{
                            color: WtColor.TextMidGrey,
                            lineHeight: "0.6",
                          }}
                        >
                          <p>Plan: WTD</p>
                          <p>{totalpayment()}</p>
                        </div>
                        <CheckoutForm />
                      </Elements>
                    </div>
                  </center>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleToClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog open={success} onClose={successBtnHandle}>
            <DialogTitle>
              <h3 className="text-center">Payment Success</h3>
            </DialogTitle>
            <DialogContent className="text-center">
              <p>Your Plan has been activated successfully !</p>
              <p>
                Now Your ready to send bulk message from your Whatstool Desktop
                app.
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={successBtnHandle}
                style={{ backgroundColor: "#048449" }}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </section>
  );
};
