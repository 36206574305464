import React, { useState } from 'react'
import { useContext } from 'react'
import chatbotContext from '../../context/chatbot/chatbotContext'
import { MentionsInput, Mention } from 'react-mentions'

import './mention.css'

export const MTextField = ({ onBlur, multiline, defaultValue, minRows, maxRows, sx, size, InputLabelProps, minCount, maxCount, helperText }) => {
    let defaultVal = (defaultValue === undefined) ? 0 : defaultValue.length
    const [charCount, setCharCount] = useState(defaultVal)
    const customFieldKeys = useContext(chatbotContext);

    const [value, setValue] = useState(defaultValue);

    const userMentionData = ['name', 'whatsapp_number', 'channel', 'user_input'].map(e => { return { id: e, display: e } });
    customFieldKeys.customFieldKeys?.forEach(e => { userMentionData.push({ id: e.field_key, display: e.fieldName }) });

    console.log('customFieldKeys', customFieldKeys.customFieldKeys);

    const handleChange = (evt, newValue, newPlainTextValue, mentions) => {
        setValue(newValue);
        minMaxCheck(newValue);
    };

    const minMaxCheck = (val) => {
        if (maxCount) { setValue(val.substr(0, maxCount)); }
        const length = value.length
        setCharCount(length)
    };

    const onBlurThis = (e, b) => {
        if (!b) {
            e.target.value = value;
            e.target.defaultValue = defaultValue;
            onBlur(e);
        };
    }

    return <div
        className="bot_text_field_ui"
        style={{ position: "relative" }}>
        <MentionsInput
            value={value}
            onChange={handleChange}
            placeholder={helperText}
            onBlur={onBlurThis}
            //singleLine={!multiline}
            className="mentions">
            <Mention
                trigger="@"
                markup="[[__id__]]"
                data={userMentionData}
                className="mentions__mention"
                appendSpaceOnAdd={true}
            />
        </MentionsInput>
        <div style={{ bottom: '0', position: 'absolute', width: '100%' }}><span style={{ paddingBottom: '0px', paddingRight: '3px', float: 'right', fontSize: "11px" }}>{charCount}/{maxCount}</span></div>
    </div>
    // return <TextField
    //     label={'Abc'} sx={sx} size={size} multiline={multiline} minRows={minRows} maxRows={maxRows}
    //     defaultValue={defaultValue} onBlur={onBlur} onChange={minMaxCheck}
    //     helperText={helperText} InputLabelProps={{ shrink: false }} focused error={error}
    //     InputProps={{
    //         endAdornment: <InputAdornment position="end" style={{ position: "absolute", right: "6px", bottom: "10px" }}><span style={{ fontSize: "11px" }}>{charCount}/{maxCount}</span></InputAdornment>,
    //     }}
    // />
}