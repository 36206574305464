import React, { useState, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '@mui/material'
import { ProfileSidebar } from './ProfileSidebar'
import { changePasswordAPI } from '../../controller/APIs'
import { ShowSnackbar } from '../../controller/BaseTool'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const PasswordChange = () => {
    const notificationBarStatus = useContext(globalContext)
    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [newPassConfirm, setNewPassConfirm] = useState("")

    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const handleCurrentPass = (val) => {
        setCurrentPass(val)
    }
    const handleNewPass = (val) => {
        setNewPass(val)
    }
    const handleNewPassConfirm = (val) => {
        setNewPassConfirm(val)
    }

    const resetInputFields = () => {
        setCurrentPass("")
        setNewPass("")
        setNewPassConfirm("")
    }

    const changePassword = async () => {
        try {
            if (newPass === newPassConfirm) {
                let data = { 'currPass': currentPass, 'newPass': newPass }
                const response = await changePasswordAPI(data)
                if (response.status === 202) {
                    resetInputFields()
                }
            } else {
                SnackbarShow("New Password & Re-entered Password didn't match!!!", WtColor.RedColor)
            }
        } catch (error) { resetInputFields() }
    }

    // Snackbar Code

    const SnackbarShow = (errorMsg, errorMsgBg) => { setErrorMsg(errorMsg); setErrorMsgBg(errorMsgBg); setOpenSnackbar(true) }
    const SnackbarClose = () => { setOpenSnackbar(false) }
    // End of Snackbar Code


    const ProfileStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh',
        width: '94vw'
    }
    return (
        <div style={ProfileStyle}>
            <ProfileSidebar />
            <Container className="profile_menu_item bg-white" fluid>
                <div className="profile_menu_header border-bottom ps-3 pt-3 pb-2">
                    <h6 style={{ color: WtColor.TextDarkColor }}>Profile Information</h6>
                </div>
                <div className="pt-2 ps-4">
                    <p style={{ color: WtColor.TextColor }} className="fw-bold">Current Password</p>
                    <p style={{ color: WtColor.TextColor }} className="mt-3">Your Current Password</p>
                    <input type={"text"} onChange={(e) => handleCurrentPass(e.target.value)} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter the Current Password" />
                    <p style={{ color: WtColor.TextColor }} className="mt-3">New Password</p>
                    <input type={"text"} onChange={(e) => handleNewPass(e.target.value)} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter a New Password" />
                    <p style={{ color: WtColor.TextColor }} className="mt-3">Re-Enter Password</p>
                    <input type={"text"} onChange={(e) => handleNewPassConfirm(e.target.value)} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Re-Enter your New Password" />
                    <Button onClick={() => changePassword()} style={{ background: WtColor.BaseColor }} className="btn btn-sm mt-3 pt-2 pb-2 ps-4 pe-4 text-white">Change Password</Button>
                </div>
            </Container>
            <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} close={SnackbarClose} />
        </div>
    )
}