/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import React, { useContext, useRef, useState, useEffect } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { parse } from 'papaparse'
import { CSVLink } from 'react-csv'
import { Close, ReplayOutlined } from '@mui/icons-material'
import broadcastContext from '../../context/broadcast/broadcastContext'
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material'
import { TemplateButtonMessageForMultiPurpose, TemplateMessageWithVariable } from '../Chat/MessagesUI'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'
import { ConfirmcustomBtnDialog, WtLoader } from '../../controller/BaseTool'

export const SelectContacts = () => {
    const isLoading = useContext(broadcastContext)

    // Contacts List Work
    const getContacts = useContext(broadcastContext)
    const contactLs = useContext(broadcastContext)
    const contactLsLen = useContext(broadcastContext)
    // End of Contact List Work

    const contactLsUI = useContext(broadcastContext) // contact_filter || contact_ls_ui || contact_template_var_maping
    const filterAppliedStatus = useContext(broadcastContext)
    const setFilterAppliedStatus = useContext(broadcastContext)

    // Label Work
    const labelLs = useContext(broadcastContext)
    const getLabels = useContext(broadcastContext)
    const labelFields = useContext(broadcastContext)
    const addLabelFields = useContext(broadcastContext)
    const removeLabelFields = useContext(broadcastContext)
    const updateLabelFieldsData = useContext(broadcastContext)
    const resetLabelFieldData = useContext(broadcastContext)
    // End of Label Work

    // Custom Field Work
    const customFieldLs = useContext(broadcastContext)
    const getCustomFieldLs = useContext(broadcastContext)
    const listCustomField = useContext(broadcastContext)
    const customFields = useContext(broadcastContext)
    const addCustomFields = useContext(broadcastContext)
    const removeCustomFields = useContext(broadcastContext)
    const updateCustomFieldsData = useContext(broadcastContext)
    const resetCustomFieldData = useContext(broadcastContext)
    // End of Custom Field Work

    // Created At Work
    const createAtOpt = useContext(broadcastContext)
    const updateCreatedAtOpt = useContext(broadcastContext)
    const createdAtFrom = useContext(broadcastContext)
    const updateCreatedAtOptFrom = useContext(broadcastContext)
    const createdAtTo = useContext(broadcastContext)
    const updateCreatedAtOptTo = useContext(broadcastContext)
    const finalCreatedFrom = useContext(broadcastContext)
    const finalCreatedTo = useContext(broadcastContext)
    const resetCreatedAtData = useContext(broadcastContext)
    // End of Created At Work

    // Last Seen Work
    const lastSeenOpt = useContext(broadcastContext)
    const updateLastSeenOpt = useContext(broadcastContext)
    const lastSeenFrom = useContext(broadcastContext)
    const updateLastSeenOptFrom = useContext(broadcastContext)
    const lastSeenTo = useContext(broadcastContext)
    const updateLastSeenOptTo = useContext(broadcastContext)
    const finalLastSeenFrom = useContext(broadcastContext)
    const finalLastSeenTo = useContext(broadcastContext)
    const resetLastSeenData = useContext(broadcastContext)
    // End of Last Seen Work

    // Opted In
    const optedInOpt = useContext(broadcastContext)
    const handleOptedInOpt = useContext(broadcastContext)
    // End of Opted In

    // Apply Filter
    const applyFilterStatus = useContext(broadcastContext)
    // const resetFilterStatus = useContext(broadcastContext)
    const setApplyFilterStatus = useContext(broadcastContext)
    const setResetFilterStatus = useContext(broadcastContext)
    // End of Apply Filter

    const resetAllFilter = useContext(broadcastContext)

    const templateVarCount = useContext(broadcastContext)

    const contactSelectionUITab = useContext(broadcastContext)


    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    // Label Handling Work
    let discardLabelsLs = []
    let labIdInclude = labelFields.labelFields?.filter((v) => { return v['l_action'] === 'include' }).map((v) => {
        discardLabelsLs.push(v['l_id'])
        return v['l_id']
    })
    let labIdExclude = labelFields.labelFields?.filter((v) => { return v['l_action'] === 'exclude' }).map((v) => {
        discardLabelsLs.push(v['l_id'])
        return v['l_id']
    })
    // Label Handling Work

    // Custom Field Handling Work
    let discardCustomFieldLs = []
    let customFieldList = customFields.customFields?.filter((v) => { return v['cf_id'] !== '' && v['cf_type'] !== '' && v['cf_key'] !== '' && v['cf_operator'] !== '' && v['cf_value'] !== '' }).map((v) => {
        discardCustomFieldLs.push(v['cf_key'])
        return v
    })
    // console.log("CF:" + JSON.stringify(customFieldList))
    // End of Custom Field Handling Work


    // Apply Filter Work
    const applyFilter = () => {
        getContacts.getContacts(9999, 0, labIdInclude.join(","), labIdExclude.join(","), customFieldList, finalCreatedFrom.finalCreatedFrom, finalCreatedTo.finalCreatedTo, finalLastSeenFrom.finalLastSeenFrom, finalLastSeenTo.finalLastSeenTo)
        setApplyFilterStatus.setApplyFilterStatus(false)
        setResetFilterStatus.setResetFilterStatus(false)
        setFilterAppliedStatus.setFilterAppliedStatus(true)
    }
    // End of Apply Filter Work



    useEffect(() => {
        if (contactLs.contactLs?.length === 0 && contactSelectionUITab.contactSelectionUITab === "select_your_contacts") {
            getContacts.getContacts(9999, 0, '', '', '', '', '', '', '')
        }
        getLabels.getLabels()
        getCustomFieldLs.getCustomFieldLs()
    }, [contactSelectionUITab.contactSelectionUITab])

    const form_item_ui_style = { outline: "none", marginRight: "10px", borderRadius: "5px", padding: "4px", border: "1px solid " + WtColor.CloudyGrey, color: WtColor.TextMidGrey }
    const RadioBtnUIStyle = { color: WtColor.TextDarkColor, '&.Mui-checked': { color: WtColor.BaseColor } }
    return (
        <div className="contact_filter app_list" style={{ height: '600px' }}>
            {
                (() => {
                    if (contactLsUI.contactLsUI === "contact_filter") {
                        return (
                            <Container fluid>
                                <Row className="ms-2 mt-4">
                                    <Col>
                                        <div style={{ display: "flex" }}>
                                            <p style={{ flex: "1", color: WtColor.TextLightGrey }}>Apply Filter to your contacts </p>
                                            <div style={{ display: "flex", marginRight: "30px" }}>
                                                <div>
                                                    <button style={ValidButtonStyle} onClick={() => resetAllFilter.resetAllFilter()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey">Reset</button>
                                                </div>
                                                <div>
                                                    <button disabled={applyFilterStatus.applyFilterStatus ? false : true} style={ValidButtonStyle} onClick={() => applyFilter()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey">Apply Filter</button>
                                                </div>
                                                <div>
                                                    <div className='pt-2 ps-3 pe-3' style={{ border: "1px solid " + WtColor.BaseColor, borderRadius: "10px", background: WtColor.NeonGreenColor, marginTop: "-24px" }}>
                                                        <p className='text-center fw-bold' style={{ color: WtColor.TextDarkColor, fontSize: "15px" }}>Total Selected Contacts</p>
                                                        <p className='text-center fw-bold' style={{ color: WtColor.BaseColor, fontSize: "24px", marginTop: "-20px", marginBottom: '4px' }}>{contactLsLen.contactLsLen}</p>
                                                    </div>
                                                    <p className='text-center' style={{ color: WtColor.BaseColor, marginTop: "2px", fontSize: "12px" }}>{filterAppliedStatus.filterAppliedStatus ? 'Filter Applied' : 'All Contacts Selected'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Container className='ms-2 campaignList' style={{ height: "550px" }} fluid>
                                    <Row className="label_work">
                                        <Col>
                                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Labels&nbsp;
                                                {
                                                    discardLabelsLs?.length !== 0 ?
                                                        <Tooltip title="Reset Label Filter" placement='bottom'>
                                                            <ReplayOutlined onClick={() => resetLabelFieldData.resetLabelFieldData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                                        </Tooltip> : ''
                                                }
                                            </p>
                                            <div>
                                                {
                                                    labelFields.labelFields?.map((v, i) => {
                                                        return (
                                                            <div style={{ display: "flex", fontSize: "16px", marginBottom: "6px" }} key={i}>
                                                                <select value={v.l_id} name="l_id" onChange={(e) => updateLabelFieldsData.updateLabelFieldsData(i, e)} style={form_item_ui_style}>
                                                                    <option value="">Select Label</option>
                                                                    {
                                                                        labelLs.labelLs
                                                                            .map((v1, i1) => {
                                                                                let showStatus = discardLabelsLs?.includes(v1._id)
                                                                                return (
                                                                                    <option style={{ display: showStatus ? 'none' : 'block' }} value={v1._id} key={i1}>{v1.label_name}</option>
                                                                                )
                                                                            })
                                                                    }
                                                                </select>
                                                                <select value={v.l_action} name="l_action" onChange={(e) => updateLabelFieldsData.updateLabelFieldsData(i, e)} style={form_item_ui_style}>
                                                                    <option value="">Select Action</option>
                                                                    <option value="include">Include This</option>
                                                                    <option value="exclude">Exclude This</option>
                                                                </select>
                                                                <Close style={{ color: "#fff", display: i === 0 ? "block" : "none" }} />
                                                                <Close onClick={() => removeLabelFields.removeLabelFields(i)} className='fw-bold hand' style={{ display: i === 0 ? "none" : "block", color: WtColor.RedColor, marginTop: "6px" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    discardLabelsLs.length === labelFields.labelFields.length ?
                                                        <p onClick={() => addLabelFields.addLabelFields()} className='hand' style={{ color: WtColor.BaseColor }}>+ Add new label</p> : ''
                                                }
                                            </div>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="custom_field_work mt-3">
                                        <Col>
                                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Custom Fields&nbsp;
                                                {
                                                    discardCustomFieldLs?.length !== 0 ?
                                                        <Tooltip title="Reset Custom Field Filter" placement='bottom'>
                                                            <ReplayOutlined onClick={() => resetCustomFieldData.resetCustomFieldData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                                        </Tooltip> : ''
                                                }
                                            </p>
                                            <div>
                                                {
                                                    customFields.customFields?.map((v, i) => {
                                                        return (
                                                            <div style={{ display: "flex", fontSize: "16px", marginBottom: "6px" }} key={i}>
                                                                {/* Select Custom Field Work */}
                                                                <select value={[v.cf_id, v.cf_type, v.cf_key]} name="cf_id" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                    <option value="">Select Custom Field</option>
                                                                    {
                                                                        customFieldLs.customFieldLs?.map((v1, i1) => {
                                                                            let showStatus = discardCustomFieldLs?.includes(v1.field_key)
                                                                            return (
                                                                                <option style={{ display: showStatus ? 'none' : 'block' }} value={[v1._id, v1.field_type, v1.field_key]} key={i1}>{v1.field_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                {/* End of Select Custom Field Work */}

                                                                {/* Select Operator Work */}
                                                                {
                                                                    v.cf_type === "text" || v.cf_type === "link" ?
                                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                            <option value="">Select Operation</option>
                                                                            <option value="contains">contains</option>
                                                                            <option value="eq">equal to</option>
                                                                            <option value="starts_with">starts with</option>
                                                                            <option value="ends_with">ends with</option>
                                                                            <option value="not_contains">not contains</option>
                                                                        </select> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "number" ?
                                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                            <option value="">Select Operation</option>
                                                                            <option value="eq">is equal to</option>
                                                                            <option value="ne">doesn't equal to</option>
                                                                            <option value="contains">contains</option>
                                                                            <option value="eq">=</option>
                                                                            <option value="lt">&lt;</option>
                                                                            <option value="lte">&lt;=</option>
                                                                            <option value="gt">&gt;</option>
                                                                            <option value="gte">&gt;=</option>
                                                                            <option value="ne">!=</option>
                                                                        </select> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "date" ?
                                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                            <option value="">Select Operation</option>
                                                                            <option value="eq">=</option>
                                                                            <option value="lt">&lt;</option>
                                                                            <option value="gt">&gt;</option>
                                                                        </select> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "checkbox" || v.cf_type === "list" ?
                                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                            <option value="">Select Operation</option>
                                                                            <option value="eq">equal to</option>
                                                                            <option value="ne">not equal to</option>
                                                                        </select> : ""
                                                                }
                                                                {/* End of Select Operator Work */}

                                                                {/* Select Value Work */}
                                                                {
                                                                    v.cf_type === "text" ? <input defaultValue={v.cf_value} type="text" placeholder="Enter Text" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "number" ? <input defaultValue={v.cf_value} type="number" placeholder="Enter Number" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "link" ? <input defaultValue={v.cf_value} type="url" placeholder="Enter Link or URL" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "date" ? <input defaultValue={v.cf_value} type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "checkbox" ?
                                                                        <select value={v.cf_value} name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                            <option value="">Select Value</option>
                                                                            <option value="true">True</option>
                                                                            <option value="false">False</option>
                                                                        </select> : ""
                                                                }
                                                                {
                                                                    v.cf_type === "list" ?
                                                                        <select defaultValue={v.cf_value} name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                                            <option value="">Select Value</option>
                                                                            {
                                                                                listCustomField.listCustomField?.map((v1, i1) => {
                                                                                    return (
                                                                                        <option value={v1} key={i1}>{v1}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select> : ""
                                                                }
                                                                {/* End of Select Value Work */}
                                                                <Close style={{ color: "#fff", display: i === 0 ? "block" : "none" }} />
                                                                <Close onClick={() => removeCustomFields.removeCustomFields(i)} className='fw-bold hand' style={{ display: i === 0 ? "none" : "block", color: WtColor.RedColor, marginTop: "6px" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    discardCustomFieldLs.length === customFields.customFields.length ?
                                                        <p onClick={() => addCustomFields.addCustomFields()} className='hand' style={{ color: WtColor.BaseColor }}>+ Add new custom field</p> : ''
                                                }
                                            </div>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="created_at mt-3">
                                        <Col>
                                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Created At&nbsp;
                                                {
                                                    createAtOpt.createAtOpt !== "none" ?
                                                        <Tooltip title="Reset Created At Filter" placement='bottom'>
                                                            <ReplayOutlined onClick={() => resetCreatedAtData.resetCreatedAtData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                                        </Tooltip> : ''
                                                }
                                            </p>
                                            <RadioGroup row name="row-radio-buttons-group" value={createAtOpt.createAtOpt} onChange={updateCreatedAtOpt.updateCreatedAtOpt}>
                                                <FormControlLabel value="none" style={{ display: "none" }} control={<Radio sx={RadioBtnUIStyle} />} label="None" />
                                                <FormControlLabel value="today" control={<Radio sx={RadioBtnUIStyle} />} label="Today" />
                                                <FormControlLabel value="week" control={<Radio sx={RadioBtnUIStyle} />} label="This Week" />
                                                <FormControlLabel value="month" control={<Radio sx={RadioBtnUIStyle} />} label="This Month" />
                                                <FormControlLabel value="custom" control={<Radio sx={RadioBtnUIStyle} />} label="Custom Range" />
                                            </RadioGroup>
                                            {
                                                createAtOpt.createAtOpt === 'today' || createAtOpt.createAtOpt === 'week' || createAtOpt.createAtOpt === 'month' ?
                                                    <div>
                                                        <p style={{ color: WtColor.TextLightGrey }}>{createdAtFrom.createdAtFrom} to {createdAtTo.createdAtTo}</p>
                                                    </div> : ''
                                            }
                                            {
                                                createAtOpt.createAtOpt === 'custom' ?
                                                    <div className='mt-3' style={{ display: "flex" }}>
                                                        <div className="me-2">
                                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>From</label>
                                                            <input value={createdAtFrom.createdAtFrom} id="created_at_from" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateCreatedAtOptFrom.updateCreatedAtOptFrom(e.target.value)} style={form_item_ui_style} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>To</label>
                                                            <input value={createdAtTo.createdAtTo} id="created_at_to" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateCreatedAtOptTo.updateCreatedAtOptTo(e.target.value)} style={form_item_ui_style} />
                                                        </div>
                                                    </div> : ''
                                            }
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="last_seen_at mt-3 mb-5 pb-5">
                                        <Col>
                                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Last Seen&nbsp;
                                                {
                                                    lastSeenOpt.lastSeenOpt !== "none" ?
                                                        <Tooltip title="Reset Last Seen Filter" placement='bottom'>
                                                            <ReplayOutlined onClick={() => resetLastSeenData.resetLastSeenData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                                        </Tooltip> : ''
                                                }</p>
                                            <RadioGroup row name="row-radio-buttons-group" value={lastSeenOpt.lastSeenOpt} onChange={updateLastSeenOpt.updateLastSeenOpt}>
                                                <FormControlLabel value="none" style={{ display: "none" }} control={<Radio sx={RadioBtnUIStyle} />} label="None" />
                                                <FormControlLabel value="today" control={<Radio sx={RadioBtnUIStyle} />} label="Today" />
                                                <FormControlLabel value="week" control={<Radio sx={RadioBtnUIStyle} />} label="This Week" />
                                                <FormControlLabel value="month" control={<Radio sx={RadioBtnUIStyle} />} label="This Month" />
                                                <FormControlLabel value="custom" control={<Radio sx={RadioBtnUIStyle} />} label="Custom Range" />
                                            </RadioGroup>
                                            {
                                                lastSeenOpt.lastSeenOpt === 'today' || lastSeenOpt.lastSeenOpt === 'week' || lastSeenOpt.lastSeenOpt === 'month' ?
                                                    <div>
                                                        <p style={{ color: WtColor.TextLightGrey }}>{lastSeenFrom.lastSeenFrom} to {lastSeenTo.lastSeenTo}</p>
                                                    </div> : ''
                                            }
                                            {
                                                lastSeenOpt.lastSeenOpt === 'custom' ?
                                                    <div className='mt-3' style={{ display: "flex" }}>
                                                        <div className="me-2">
                                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>From</label>
                                                            <input value={lastSeenFrom.lastSeenFrom} id="created_at_from" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateLastSeenOptFrom.updateLastSeenOptFrom(e.target.value)} style={form_item_ui_style} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>To</label>
                                                            <input value={lastSeenTo.lastSeenTo} id="created_at_to" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateLastSeenOptTo.updateLastSeenOptTo(e.target.value)} style={form_item_ui_style} />
                                                        </div>
                                                    </div> : ''
                                            }
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="opted_in mt-3 mb-5 pb-5" style={{ display: "none" }}>
                                        <Col>
                                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Opted In</p>
                                            <RadioGroup row name="row-radio-buttons-group" value={optedInOpt.optedInOpt} onChange={handleOptedInOpt.handleOptedInOpt}>
                                                <FormControlLabel value="none" style={{ display: "none" }} control={<Radio sx={RadioBtnUIStyle} />} label="None" />
                                                <FormControlLabel value="true" control={<Radio sx={RadioBtnUIStyle} />} label="Yes" />
                                                <FormControlLabel value="false" control={<Radio sx={RadioBtnUIStyle} />} label="No" />
                                                <FormControlLabel value="all" control={<Radio sx={RadioBtnUIStyle} />} label="All" />
                                            </RadioGroup>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Container>
                            </Container>
                        )
                    }
                    if (contactLsUI.contactLsUI === "contact_ls_ui") { return <ShowSelectedContacts /> }
                    if (contactLsUI.contactLsUI === "contact_template_var_maping" && templateVarCount.templateVarCount !== 0) { return <ShowTemplateVarMaping /> }
                })()
            }
            {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
        </div>
    )
}
export const ShowSelectedContacts = () => {
    const contactLsLen = useContext(broadcastContext)
    const contactLs = useContext(broadcastContext)

    const tableRef = useRef(null)
    return (
        <Container className='campaignList' style={{ height: "600px" }} fluid>
            <Table className='mt-3' ref={tableRef} responsive>
                <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }}>
                    <tr>
                        <th className="ps-5">WhatsApp</th><th>Name</th></tr>
                </thead>
                {
                    contactLsLen.contactLsLen !== 0 ?
                        <tbody style={{ background: "#fff", fontSize: "14px" }}>
                            {
                                contactLs.contactLs?.map((v, i) => {
                                    return (
                                        <tr className="hand" key={i}>
                                            <td className="ps-5" style={{ color: WtColor.TextMidGrey }}>{v.whatsapp ? v.whatsapp : ""}</td>
                                            <td style={{ color: WtColor.TextLightGrey, paddingTop: "8px" }}>{v.name ? v.name : ""}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        :
                        <tbody><tr><td colSpan={6} style={{ color: WtColor.TextLightGrey, textAlign: 'center' }}>Contacts Loading... Please wait...</td></tr></tbody>
                }
            </Table>
        </Container>
    )
}
export const ShowTemplateVarMaping = () => {
    const wholeTemplate = useContext(broadcastContext)
    const templateVarNames = useContext(broadcastContext)
    const customFieldLs = useContext(broadcastContext)
    const updateTempFieldMap = useContext(broadcastContext)

    let template = JSON.parse(wholeTemplate.wholeTemplate)

    let tempVarName = templateVarNames.templateVarNames?.split("|")



    const form_item_ui_style = { outline: "none", marginRight: "10px", borderRadius: "5px", padding: "4px", border: "1px solid " + WtColor.CloudyGrey, color: WtColor.TextMidGrey }
    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className="chatViewU m-5" >
                        <div>
                            <div className="chat_bubble">
                                <TemplateMessageWithVariable templateComponent={template.components} />
                            </div>
                            <div className="chat_bubble_for_template">
                                {
                                    template.components?.map((bv, bi) => {
                                        return (
                                            <div>
                                                {
                                                    (() => {
                                                        if (bv.type && bv.type === "BUTTONS" && bv.buttons) { return <TemplateButtonMessageForMultiPurpose buttons={bv.buttons} key={bi} /> }
                                                    })()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="mt-5">
                        <p style={{ color: WtColor.TextMidGrey }}>Default Variable</p>
                        <p className='text-center'>
                            {
                                tempVarName?.map((v, i) => {
                                    let vCount = ++i
                                    return (
                                        <input text="text" value={v} defaultValue={v} disabled className="form-control mb-2 w-100" placeholder={"Default Variable {{" + vCount + "}}"} />
                                    )
                                })
                            }
                        </p>
                    </div>
                </Col>
                <Col>
                    <div className="mt-5">
                        <p style={{ color: WtColor.TextMidGrey }}>Custom Field</p>
                        <p className='text-center'>
                            {
                                tempVarName?.map((v, i) => {
                                    return (
                                        <select style={form_item_ui_style} key={i} name="fKey" className='mb-2 w-100' onChange={(e) => updateTempFieldMap.updateTempFieldMap(e, i)}>
                                            <option value="">Select Custom Field</option>
                                            {
                                                customFieldLs.customFieldLs?.map((v1, i1) => {
                                                    return (
                                                        <option value={v1.field_key} key={i1}>{v1.field_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    )
                                })
                            }
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}





export const ImportContact = () => {
    const templateVarCount = useContext(broadcastContext)
    const templateVarNames = useContext(broadcastContext)
    const setSelectedContacts = useContext(broadcastContext)
    const setSelectCSV = useContext(broadcastContext)
    const updateMainBroadcastUI = useContext(broadcastContext)
    const setButtonActive = useContext(broadcastContext)

    //Hooks
    const inputRef = useRef(null)
    const [isCsvValid, setIsCsvValid] = useState(true)
    const [errorMsg, setErrorMsg] = useState('')
    const variableNameArr = templateVarNames.templateVarNames.split('|')

    //Handlers
    const onBtnClick = () => {
        inputRef.current.click()
        setIsCsvValid(true)
    }

    const uiSelectProp = (val) => {
        updateMainBroadcastUI.updateMainBroadcastUI(val)
        setButtonActive.setButtonActive(false)
    }

    const handleChange = (e) => {
        setErrorMsg('')
        setIsCsvValid(true)

        setSelectCSV.setSelectCSV(e.target.files[0])
        Array.from(e.target.files)
            .filter((file) => file.type === "text/csv")
            .forEach(async (file) => {
                let text = await file.text()
                let res = parse(text)

                // CSV validation
                // if (res) {
                //     res.data.slice(0, res.data.length - 1).forEach((col, i) => {
                //         //Variable length
                //         if (col.length !== templateVarCount.templateVarCount + 1) {
                //             setErrorMsg(`You can't add variables more or less than requirement. Required: ${templateVarCount.templateVarCount} Actual: ${col.length - 1}`)
                //             setIsCsvValid(false)
                //             throw 'Invalid CSV Format'
                //         }

                //         //Empty Field Validation
                //         col.forEach((row, j) => {
                //             if (row.trim() === "") {
                //                 setErrorMsg(`Required fields can not empty at Row: ${i + 1}, Col: ${j + 1}.`)
                //                 setIsCsvValid(false)
                //                 throw 'Invalid CSV Format. Empty Fields'
                //             }
                //         })

                //         //Mobile Number Validation
                //         if (!(/^(?:[0-9]?){6,14}[0-9]$/.test(col[0]))) {
                //             setErrorMsg(`Invalid Mobile no at Row: ${i + 1} - '${col[0]}'`)
                //             setIsCsvValid(false)
                //             throw 'Invalid Mobile Number'
                //         }
                //     })
                // }
                if (isCsvValid) {
                    setSelectedContacts.setSelectedContacts(res.data.slice(0, res.data.length - 1))
                    uiSelectProp('contact_list')
                }
            })
    }


    //Generating CSV File
    const csvHeader = [
        { label: '919987654321', key: 'mobileno' },
        variableNameArr.map((e, i) => {
            return { label: `${e}`, key: e }
        })
    ]
    const body = [{ mobileno: "" }]
    const csvLink = {
        headers: csvHeader.flat(),
        data: body,
        filename: "samplefile.csv"
    }

    let contactFile = document.getElementsByTagName('input')[0]
    contactFile.onclick = function () { this.value = null }
    return (
        <div className="mt-3" style={{ border: "1px solid " + WtColor.LineColor, margin: "auto", width: "30%", paddingLeft: "45px", paddingRight: "45px", paddingTop: "40px", paddingBottom: "40px" }}>
            <div className="app_list" style={{ height: '410px' }}>
                <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor }}>Select Contact</p>
                <div className="mt-4" style={{ lineHeight: "0.6" }}>
                    {(templateVarCount.templateVarCount > 0)
                        ?
                        (<p className="text-center fw-bold" style={{ color: WtColor.TextMidGrey, fontSize: "16px" }}>Selected Template required {templateVarCount.templateVarCount} variables</p>)
                        :
                        (<p className="text-center fw-bold" style={{ color: WtColor.TextMidGrey, fontSize: "16px" }}>Selected Template required no variables</p>)
                    }
                    <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "14px" }}>You need to import contacts using csv</p>
                </div>
                {
                    !isCsvValid &&
                    <div className='csv-error'>
                        <p>
                            <span>ERROR: {errorMsg}</span><br />
                            <span>Please download sample csv file from the below link.</span>
                        </p>
                    </div>
                }
                <div className="mt-4" style={{ display: "flex" }}>
                    <p style={{ flex: 1, color: WtColor.TextMidGrey }}>Download this Sample CSV to arrange your Data</p>
                    <CSVLink {...csvLink} >
                        <p>
                            <img alt='csv-downloader' src={WtTheme.ImageBaseUrl + "/green_download_icon.svg"} />
                        </p>
                    </CSVLink>

                </div>

                <Table className="fw-bold" responsive>
                    <thead style={{ background: WtColor.CloudyGrey, color: WtColor.TextLightGrey, position: 'relative' }}>
                        <tr style={{ color: "#fff" }}>
                            <th className="text-center">Number</th>
                            {
                                [...Array(templateVarCount.templateVarCount)].map((v, i) => {
                                    ++i
                                    return (
                                        <th className="text-center" key={i}>{`{{${i}}}`} </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>

                    <tbody style={{ background: WtColor.LineColor, fontSize: "14px" }}>
                        {
                            [...Array(3)].map((v, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center" style={{ color: WtColor.TextMidGrey }}>919987654321</td>
                                        {
                                            [...Array(templateVarCount.templateVarCount)].map((v1, j) => {
                                                return (<td className="text-center" style={{ color: WtColor.TextMidGrey }} key={j}>Hello</td>)
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                <div>
                    <input type='file' id='file' ref={inputRef} style={{ display: 'none' }} accept='.csv' onChange={handleChange} />
                    <p className="text-center" style={{ display: "flex" }}>
                        <button className="btn btn-md theme_grey01 w-75" onClick={() => uiSelectProp('create_campaign')}>Cancel</button> &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-md theme_bg text-white w-75" onClick={onBtnClick}>Import Contact</button>
                    </p>
                </div>
            </div>
        </div>
    )
}
export const ContactList = () => {
    const templateVarCount = useContext(broadcastContext)
    const templateVarNames = useContext(broadcastContext)
    const updateMainBroadcastUI = useContext(broadcastContext)
    const setButtonActive = useContext(broadcastContext)
    const selectedContacts = useContext(broadcastContext)
    const setContactCount = useContext(broadcastContext)
    const stepTwoStatus = useContext(broadcastContext)
    const setStepThreeStatus = useContext(broadcastContext)

    let variableNameArr = templateVarNames.templateVarNames?.split('|').slice(0, templateVarCount.templateVarCount)

    const [correctCount, setCorrectCount] = useState(0)
    const [wrongCount, setWrongCount] = useState(0)

    const uiSelectProp = (val) => {
        if (wrongCount !== 0) {
            openConfirmationDialogBox(wrongCount, val, "Re-Import Contact", "Continue")
        } else {
            if (val === "continue") {
                updateMainBroadcastUI.updateMainBroadcastUI("create_campaign")
                setButtonActive.setButtonActive(false)
                if (selectedContacts.selectedContacts.length > 0) {
                    setContactCount.setContactCount(`Total: ${selectedContacts.selectedContacts.length} Contacts`)
                    if (stepTwoStatus.stepTwoStatus === 'completed') {
                        setStepThreeStatus.setStepThreeStatus('completed')
                        setButtonActive.setButtonActive('active')
                    }
                }
            }
            if (val === "create_campaign") {
                updateMainBroadcastUI.updateMainBroadcastUI(val)
                setButtonActive.setButtonActive(false)
            }
        }
    }

    const isValidNumber = (input) => {
        if (input?.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) return true
    }
    const isNotValidNumber = (input) => {
        if (!input?.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) return true
    }

    const checkCSVData = () => {
        let c = selectedContacts.selectedContacts?.map(d => d[0])
        c?.filter((v) => { return isValidNumber(v) }).map((v, i) => { return setCorrectCount(i + 1) })
        c?.filter((v) => { return isNotValidNumber(v) }).map((v, i) => { return setWrongCount(i + 1) })
    }

    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const openConfirmationDialogBox = (wrongCount, val, cancelBtn, continueBtn) => {
        setOpenConfirmDialogBox({
            open: true, cancelBtn: cancelBtn, continueBtn: continueBtn, title: 'Contacts Deletion', message: "Your imported contacts' contain <span className='text-success'>" + wrongCount + "</span> wrong data.<br/>So, do you still want to continue?", onClose: (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) {
                    if (val === "continue") {
                        updateMainBroadcastUI.updateMainBroadcastUI("create_campaign")
                        setButtonActive.setButtonActive(false)
                        if (selectedContacts.selectedContacts.length > 0) {
                            setContactCount.setContactCount(`Total: ${selectedContacts.selectedContacts.length} Contacts`)
                            if (stepTwoStatus.stepTwoStatus === 'completed') {
                                setStepThreeStatus.setStepThreeStatus('completed')
                                setButtonActive.setButtonActive('active')
                            }
                        }
                    }
                    if (val === "create_campaign") {
                        updateMainBroadcastUI.updateMainBroadcastUI(val)
                        setButtonActive.setButtonActive(false)
                    }
                }
                if (!confirm) {
                    updateMainBroadcastUI.updateMainBroadcastUI('create_campaign')
                    setButtonActive.setButtonActive(false)
                }
            }
        })
    }

    useEffect(() => { checkCSVData() }, [])
    return (
        <div>
            <div style={{ display: 'flex', fontSize: '16px', marginTop: '8px', marginLeft: '14px', marginBottom: '-15px' }}>
                {
                    correctCount !== 0 &&
                    <>
                        {correctCount !== 0 && <p style={{ color: WtColor.BaseColor }}>Correct WhatsApp No.: {correctCount}</p>}
                        {wrongCount !== 0 && <p style={{ color: WtColor.RedColor }} className="ms-3">Wrong WhatsApp No.: {wrongCount}</p>}
                        {
                            (selectedContacts.selectedContacts[0]?.length - 1) - variableNameArr?.length !== 'NaN' &&
                            <p style={{ color: (selectedContacts.selectedContacts[0]?.length - 1) - variableNameArr?.length === 0 ? WtColor.BaseColor : WtColor.BrownColor }} className="ms-3">Variable:&nbsp;
                                {
                                    (selectedContacts.selectedContacts[0]?.length - 1) - variableNameArr?.length < 0 ? ' Missing Variables' : (selectedContacts.selectedContacts[0]?.length - 1) - variableNameArr?.length === 0 ? ' Exactly provided' : (selectedContacts.selectedContacts[0]?.length - 1) - variableNameArr?.length + ' Extra'
                                }
                            </p>
                        }
                    </>
                }
                {
                    correctCount === 0 &&
                    <>
                        <p style={{ color: WtColor.RedColor }}>Invalid CSV File</p>
                    </>
                }
            </div>
            <Container fluid className="app_list" style={{ height: '510px' }} responsive>
                <Table className="fw-bold mt-3" responsive>
                    <thead style={{ color: WtColor.TextLightGrey, fontSize: '14px' }}>
                        {
                            selectedContacts.selectedContacts?.filter((v, i) => i === 0)?.map((v, i) => {
                                return (
                                    <tr className="text-center" style={{ color: WtColor.TextLightGrey }} key={i}>
                                        {
                                            Object.values(v).map((_val, i1) => {
                                                return (
                                                    <th style={{ background: variableNameArr?.length >= i1 ? WtColor.LineColor : WtColor.CloudyGrey }}>{i1 === 0 ? 'WhatsApp Number' : 'Variable {{' + i1 + '}}'}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody style={{ fontSize: "12px" }}>
                        {
                            selectedContacts.selectedContacts?.map((v, i) => {
                                return (
                                    <tr className="text-center" style={{ color: WtColor.TextLightGrey }} key={i}>
                                        {
                                            Object.values(v).map((val, i1) => {
                                                return (
                                                    <td style={{ color: i1 === 0 ? isValidNumber(val) ? WtColor.TextLightGrey : WtColor.RedColor : '', background: variableNameArr?.length >= i1 ? '#fff' : WtColor.CloudyGrey }}>{val}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                <div className="fixed-bottom bg-white pt-4 pb-2" style={{ left: '62px', borderTop: "1px solid " + WtColor.LineColor }}>
                    <div className="float-end">
                        <p className="me-5" style={{ display: "flex" }}>
                            <button className="btn btn-md theme_grey01 w-75" onClick={() => uiSelectProp('create_campaign')}>Cancel</button> &nbsp;&nbsp;&nbsp;&nbsp;
                            <button className="text-white theme_bg btn btn-md ps-5 pe-5" disabled={(selectedContacts.selectedContacts.length === 0 || correctCount === 0) ? true : false} onClick={() => uiSelectProp("continue")}>Continue</button>
                        </p>
                    </div>
                </div>
            </Container>

            {openConfirmDialogBox.open && <ConfirmcustomBtnDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} wrongCount={openConfirmDialogBox.wrongCount} val={openConfirmDialogBox.val} cancelBtn={openConfirmDialogBox.cancelBtn} continueBtn={openConfirmDialogBox.continueBtn} onClose={openConfirmDialogBox.onClose} />}
        </div >
    )
}