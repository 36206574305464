import React, { useEffect } from "react"
import { Footer, Header, WhatsAppSideFAB } from "../controller/BaseToolUI"
import { Client, Home } from "../view/home/ui"
import { Pricing } from "../view/Static/PricingUI"
import { BroadcastMsg, Chatbots, ECommerceSector, EducationalSector, HealthSector, MultiAgent, QuickGuide, TourTravelSector } from "../view/Static/InfoUIs"
import { Cancellation, FAQ, PrivacyPolicy, Support, TermsOfService } from "../view/Static/ui"
import { Testing } from "../view/Testing/Testing"

export const HomeUIAdapter = () => {
    return (
        <>
            <Home />
            <WhatsAppSideFAB />
        </>
    )
}
export const PricingUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <Pricing />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const ChatBotsUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <Chatbots />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const MultiAgentUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <MultiAgent />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const BroadcastMsgUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <BroadcastMsg />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const ClientsUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <Client ClientPage={true} />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const QuickGuideUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <QuickGuide />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}

// Integration UI
export const EducationalSectorUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <EducationalSector />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const ECommerceSectorUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <ECommerceSector />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const HealthSectorUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <HealthSector />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const TourTravelSectorUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <TourTravelSector />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
// End of Integration UI


export const SupportUIAdapter = () => {
    return (
        <>
            <Header />
            <Support />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const TermsOfServiceUIAdapter = () => {
    return (
        <>
            <Header />
            <TermsOfService />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const PrivacyPolicyUIAdapter = () => {
    return (
        <>
            <Header />
            <PrivacyPolicy />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const CancellationUIAdapter = () => {
    return (
        <>
            <Header />
            <Cancellation />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}
export const FAQUIAdapter = () => {
    return (
        <>
            <Header />
            <FAQ />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}


export const TestingUIAdapter = () => {
    return (
        <Testing />
    )
}



export const DummyUIAdapter = () => {
    return (
        <>
            <Header />
            <h1 className="text-center mt-5 mb-5 pt-5 pb-5">Coming Soon...<br />Under development...</h1>
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}