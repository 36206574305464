class WtAnalytics {
    constructor() {
        // Left Navigation
        this.dashboardBtnClicked = 'Dashboard Button Clicked'
        this.chatBtnClicked = 'Chat Button Clicked'
        this.conctactsBtnClicked = 'Contacts Button Clicked'
        this.broadcastBtnClicked = 'Broadcast Button Clicked'
        this.analyticsBtnClicked = 'Analytics Button Clicked'
        this.chatbotsBtnClicked = 'ChatBots Button Clicked'
        this.settingsBtnClicked = 'Settings Button Clicked'
        this.profileBtnClicked = 'Profile Button Clicked'
        this.upgradePlanBtnClicked = 'Upgrade Plan Button Clicked'
        // End of Left Navigation


        // Offer 
        this.topFullWidthOfferBannerClicked = 'Top Offer Banner Img Clicked'
        // End of Offer 
    }
}
export const WtAnalytic = new WtAnalytics()