/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import GlobalContext from './globalContext'
import { assignAgentAPI, assignAttributesAPI, assignEditAttributesAPI, assignedLabelsAPI, fetchAllAttributesAPI, fetchAllLabelsAPI, fetchAllMediaAPI, fetchAllTemplatesAPI, fetchEmployeeListAPI, fetchUserInfoAPI, removeAttributeAPI, removeLabelsAPI, templateSync } from '../../controller/APIs'

const GlobalState = (props) => {
    // Loader Handling
    const [isLoading, setIsLoading] = useState(undefined)
    const handleWTLoader = (val) => setIsLoading(val)
    // End of Loader Handling


    // Snackbar Code
    const [openSnackbar, setOpenSnackbar] = useState({ open: false, errorMsg: '', errorMsgBg: '' })
    const snackbarShow = (errorMsg, errorMsgBg) => setOpenSnackbar({ open: true, errorMsg: errorMsg, errorMsgBg: errorMsgBg })
    const snackbarClose = () => setOpenSnackbar(false)
    // End of Snackbar Code


    // User Info State Management
    const [userInfo, setUserInfo] = useState([])
    const [agent, setAgent] = useState(undefined)
    const [labels, setLabels] = useState([])
    const [cusAttr, setCusAttr] = useState([])
    const getUserInfo = async (channel, whatsapp) => {
        try {
            let data = { "extChannelId": channel, "extUserId": whatsapp }
            const response = await fetchUserInfoAPI(data)
            if (response.status === 200) {
                response.data?.data?.details ? setUserInfo(response.data?.data?.details) : setUserInfo([])
                response.data?.data?.details?.emp ? setAgent(response.data?.data?.details?.emp) : setAgent(undefined)
                response.data?.data?.details?.labels ? setLabels(response.data?.data?.details?.labels) : setLabels([])
                response.data?.data?.details?.contactField ? setCusAttr(response.data?.data?.details?.contactField) : setCusAttr([])
            }
        } catch (error) { }
    }

    const [agentLs, setAgentLs] = useState([])
    const getAgents = async () => {
        try {
            const response = await fetchEmployeeListAPI()
            if (response.status === 200) { setAgentLs(response.data.data) }
        } catch (error) { }
    }
    const assignedAgent = async (channel, whatsapp, id, chatId) => {
        try {
            let data = { 'empId': id, 'chatId': chatId, 'extUserId': whatsapp, 'extChannelId': channel }
            await assignAgentAPI(data)
            setAgent(undefined)
            await getUserInfo(channel, whatsapp)
            // await getChats("inbox",localStorage.getItem("ch_filterInbox_key"), localStorage.getItem("ch_filterInbox_chatSkip"), 20, "fetch_more")
        } catch (error) { }
    }

    const [lableLs, setLabelLs] = useState([])
    const getLabelLs = async () => {
        try {
            const response = await fetchAllLabelsAPI()
            if (response.status === 200) {
                setLabelLs(response.data.data)
            }
        } catch (error) { }
    }
    const assignLabel = async (channel, whatsapp, id) => {
        try {
            let data = { "extChannelId": channel, "extUserId": whatsapp, "labelId": id }
            await assignedLabelsAPI(data)
            setLabels([])
            await getUserInfo(channel, whatsapp)
        } catch (error) { }
    }
    const removeLabels = async (channel, whatsapp, id) => {
        try {

            let data = { "extChannelId": channel, "extUserId": whatsapp, "labelId": id }
            await removeLabelsAPI(data)
            setLabels([])
            await getUserInfo(channel, whatsapp)
        } catch (error) { }
    }


    const [attributeLs, setAttributeLs] = useState([])
    const getAttributeLs = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) setAttributeLs(response.data.data)
        } catch (error) { }
    }
    const assignAttribute = async (channel, whatsapp, id, inputData, key) => {
        try {
            let data = {
                "extUserId": whatsapp,
                "extChannelId": channel,
                "fieldId": id,
                "inputData": inputData,
                "fieldKey": key
            }
            await assignAttributesAPI(data)
            setCusAttr([])
            await getUserInfo(channel, whatsapp)
        } catch (error) { }
    }
    const assignEditAttribute = async (channel, whatsapp, id, inputData, key) => {
        try {
            let data = {
                "extUserId": whatsapp,
                "extChannelId": channel,
                "fieldId": id,
                "inputData": inputData,
                "fieldKey": key
            }
            await assignEditAttributesAPI(data)
            setCusAttr([])
            await getUserInfo(channel, whatsapp)
        } catch (error) { }
    }
    const removeAttribute = async (channel, whatsapp, id, key) => {
        try {
            let data = { "extChannelId": channel, "extUserId": whatsapp, "fieldId": id, "fieldKey": key }
            await removeAttributeAPI(data)
            setCusAttr([])
            await getUserInfo(channel, whatsapp)
        } catch (error) { }
    }
    // End of User Info State Management  


    // Offer Image
    const [offerImg, setOfferImg] = useState(false)
    // End of Offer Image



    // Template Work
    const [templateLs, setTemplateLs] = useState([])
    const getTemplatesUI = async (ch) => {
        handleWTLoader(true)
        try {
            let data = { 'extChannelId': ch }
            const response = await fetchAllTemplatesAPI(data)
            if (response.status === 200 && response.data.data.templates) {
                setTemplateLs(response.data.data.templates)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    const setTemplateSync = async (ch) => {
        handleWTLoader(true)
        try {
            let data = { 'extChannelId': ch }
            const response = await templateSync(data)
            if (response.status === 200) getTemplatesUI(ch)
        } catch (error) { }
    }
    const [templateUIView, setTemplateUIView] = useState('normal') // normal | grid

    // End of Template Work


    // Media Work
    const [mediaLs, setMediaLs] = useState([])
    const getMediaLs = async () => {
        try {
            const response = await fetchAllMediaAPI()
            if (response.status === 200) {
                setMediaLs(response.data.data)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    // End of Media Work


    // Notification Top Bar Work
    const [notificationBarStatus, setNotificationBarStatus] = useState(false)
    const handleNotificationBarStatus = (v) => setNotificationBarStatus(v)
    // End of Notification Top Bar Work
    return (
        <GlobalContext.Provider value={{
            isLoading, handleWTLoader,
            openSnackbar, snackbarShow, snackbarClose,
            userInfo, setUserInfo, getUserInfo,
            agentLs, getAgents, assignedAgent, agent,
            getLabelLs, lableLs, assignLabel, labels, removeLabels,
            getAttributeLs, attributeLs, assignAttribute, assignEditAttribute, cusAttr, removeAttribute,
            offerImg, setOfferImg,
            templateLs, setTemplateLs, getTemplatesUI, setTemplateSync, templateUIView, setTemplateUIView,
            mediaLs, getMediaLs,
            notificationBarStatus, handleNotificationBarStatus
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}
export default GlobalState