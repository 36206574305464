/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import ReactFlow, { MarkerType, ReactFlowProvider, Background, BackgroundVariant, addEdge, useReactFlow, Position, Controls } from 'react-flow-renderer'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { InsertPhotoOutlined, PictureAsPdfOutlined, DeleteOutlineOutlined, AudioFileOutlined, VideocamOutlined } from '@mui/icons-material'
import TopBar from './TopBar'
import RightSidebar from './RightSidebar'
import LeftSidebar from './LeftSidebar'
import CustomEdge from './CustomEdge'
import ReactAudioPlayer from 'react-audio-player'
import { botUpdateDelete, fetchAllBots } from '../../controller/APIs'
import { ConfirmDialog, DemoDialog, ShowSnackbar, WtLoader } from '../../controller/BaseTool'
import ChatbotState from '../../context/chatbot/chatbotState'
import chatbotContext from '../../context/chatbot/chatbotContext'
import { Info, MoreVert } from '@mui/icons-material'
import globalContext from '../../context/global/globalContext'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'
import { fetchAllCustomFields } from '../../controller/APIs'


const tag = 'App'

const theme = createTheme({ palette: { primary: { main: WtColor.BaseColor, }, }, })

const parentNodeWidth = 200
const parentNodeHeight = 130
const imageNodeExtraHeight = 80
const buttonHeight = 30
const createMongoObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

export function getId() {
	let digits = '0123456789'
	let OTP = ''
	for (let i = 0; i < 5; i++) { OTP += digits[Math.floor(Math.random() * 10)] }
	return OTP
}

const onDragOver = (event) => {
	event.preventDefault()
	event.dataTransfer.dropEffect = 'move'
}

let botsOld = []
let deletedNodes = []
let businessId = ''

const edgeTypes = { customEdge: CustomEdge };

const BasicFlow = () => {
	const isLoading = useContext(chatbotContext)
	const handleWTLoader = useContext(chatbotContext)

	const openSnackbar = useContext(globalContext)
	const snackbarShow = useContext(globalContext)
	const snackbarClose = useContext(globalContext)

	const selectedNode = useContext(chatbotContext)
	const setSelectedNode = useContext(chatbotContext)

	const selectedNodeUpdated = useContext(chatbotContext)

	const nodes = useContext(chatbotContext)
	const setNodes = useContext(chatbotContext)
	const onNodesChange = useContext(chatbotContext)

	const edges = useContext(chatbotContext)
	const setEdges = useContext(chatbotContext)
	const onEdgesChange = useContext(chatbotContext)

	const setIsBotActive = useContext(chatbotContext)

	const actionList = useContext(chatbotContext)
	const setActionList = useContext(chatbotContext)

	// const customFieldKeys = useContext(chatbotContext)
	const setCustomFieldKeys = useContext(chatbotContext)

	const setBotId = useContext(chatbotContext)

	const notificationBarStatus = useContext(globalContext)


	const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
	const [flow, setFlow] = useState(undefined)

	const instance = useReactFlow()

	const findNode = (id) => {
		const index = instance.toObject().nodes.findIndex(n => n.id === id)
		if (index > -1) return instance.toObject().nodes[index]
	}

	const containsLoopInNodeGraph = () => {
		let nodesCurrent = instance.toObject().nodes;
		let dict = {};
		nodesCurrent.forEach(e => dict[`${e.id}`] = e);
		for (let i = 0; i < nodesCurrent.length; i++) {
			const node = nodesCurrent[i];
			if (node.bot?.nextKeys) {
				let queue = [node];
				let visited = {};
				while (queue.length > 0) {
					const n = queue.pop();
					if (visited[`${n.id}`]) return true
					visited[`${n.id}`] = true;
					n.bot?.nextKeys?.forEach(e => queue.push(dict[`${e}`]));
				}
			}
		}
		return false
	}

	const oldBot = (_id) => {
		const index = botsOld.findIndex((b) => b._id === _id)
		return index > -1 ? botsOld[index] : undefined
	}

	const onInit = () => fetchBots()

	const onSelectionChange = (params) => {
		if (params.nodes.length === 1) { setSelectedNode.setSelectedNode(params.nodes[0]) } else { setSelectedNode.setSelectedNode(undefined) }
	}

	const reloadData = () => {
		localStorage.setItem('userActivity', false)
		const showAlert = () => {
			setOpenConfirmDialog({
				open: true, title: 'Whatstool Business', msg: 'Are you sure you want to discard changes and reload?', onClose: (confirm) => {
					setOpenConfirmDialog(false)
					if (confirm) fetchBots()
				}
			})
		}

		if (deletedNodes.length === 0) {
			const bots = nodes.nodes.filter((node) => node.bot !== undefined).map((node) => node.bot)
			const changed = bots.filter((bot) => {
				const old = oldBot(bot._id);
				return old ? JSON.stringify(bot) !== JSON.stringify(old) : true
			})
			if (changed.length > 0) {
				showAlert()
				return;
			}
		} else {
			showAlert()
			return
		}
		fetchBots()
	}

	const onNodeDragStop = (evt, node) => {
		node.bot.x = node.position.x
		node.bot.y = node.position.y
	}

	const CreateMessageHeader = ({ type, subType, node }) => {
		let icon = ''
		let title = ''
		let bgColor = ''
		if (type === 'text') {
			icon = WtTheme.ImageBaseUrl + "/textMsgIcon.svg"
			title = 'Text Message'
			bgColor = WtColor.TextMsgColor
		}
		if (type === 'image') {
			icon = WtTheme.ImageBaseUrl + "/imageMsgIcon.svg"
			title = 'Image'
			bgColor = WtColor.ImageMsgColor
		}
		if (type === 'video') {
			icon = WtTheme.ImageBaseUrl + "/videoMsgIcon.svg"
			title = 'Video'
			bgColor = WtColor.VideoMsgColor
		}
		if (type === 'audio') {
			icon = WtTheme.ImageBaseUrl + "/audioMsgIcon.svg"
			title = 'Audio'
			bgColor = WtColor.AudioMsgColor
		}
		if (type === 'document') {
			icon = WtTheme.ImageBaseUrl + "/documentMsgIcon.svg"
			title = 'Document'
			bgColor = WtColor.DocumentMsgColor
		}
		if (type === 'action') {
			icon = WtTheme.ImageBaseUrl + "/actionMsgIcon.svg"
			title = 'Action'
			bgColor = WtColor.ActionMsgColor
		}
		if (type === 'interactive' && subType === 'button') {
			icon = WtTheme.ImageBaseUrl + "/interactiveMsgIcon.svg"
			title = 'Interactive'
			bgColor = WtColor.InteractiveMsgColor
		}
		if (type === 'interactive' && subType === 'list') {
			icon = WtTheme.ImageBaseUrl + "/menuMsgIcon.svg"
			title = 'Menu Message'
			bgColor = WtColor.MenuMsgColor
		}
		if (type === 'interactive' && subType === 'product') {
			icon = WtTheme.ImageBaseUrl + "/singleProductMsgIcon.svg"
			title = 'Single Product'
			bgColor = WtColor.SingleProductMsgColor
		}
		if (type === 'interactive' && subType === 'product_list') {
			icon = WtTheme.ImageBaseUrl + "/multiProductMsgIcon.svg"
			title = 'Multi Product'
			bgColor = WtColor.MultiProductMsgColor
		}
		if (type === 'template') {
			icon = WtTheme.ImageBaseUrl + "/templateMsgIcon.svg"
			title = 'Template'
			bgColor = WtColor.TemplateMsgColor
		}

		// Option Menu Handing
		const [msgMenuAnchor, setMsgMenuAnchor] = useState(null)
		const open = Boolean(msgMenuAnchor)
		const handleMsgOptOpen = (event) => setMsgMenuAnchor(event.currentTarget)
		const handleMsgOptClose = () => setMsgMenuAnchor(null)
		const handleNodeDuplicate = (event) => {
			onNodeDuplicateButtonClick(node)
			setMsgMenuAnchor(null)
		}
		const handleNodeDelete = (event) => {
			onNodeDeleteButtonClick(node)
			setMsgMenuAnchor(null)
		}
		// End of Option Menu Handing
		return (
			<div className="node_title" style={{ display: 'flex', background: bgColor, width: "200px", marginLeft: '-11px', marginTop: '-40px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
				<div style={{ flex: 1 }}>
					<p className="mb-0 float-start" style={{ padding: '5px', color: '#fff', }}><img src={icon} className="img-fluid" alt={WtTheme.SiteName} style={{ width: "30px", padding: '3px' }} /> {title}</p>
				</div>
				<div>
					<IconButton style={{ marginTop: '3px' }}><Info style={{ color: bgColor, fontSize: '18px' }} /></IconButton>
					<IconButton style={{ marginTop: '3px' }} onClick={handleMsgOptOpen}><MoreVert style={{ color: '#fff', fontSize: '18px' }} /></IconButton>
					<>
						<Menu anchorEl={msgMenuAnchor} open={open} onClose={handleMsgOptClose}>
							<MenuItem onClick={handleNodeDuplicate}>Duplicate</MenuItem>
							<MenuItem onClick={handleNodeDelete}>Delete</MenuItem>
						</Menu>
					</>
				</div>
			</div >
		)
	}

	const createButtonLabel = (data) => {
		console.log("quick_reply_btn_data, ", data)
		// const bot = data?.buttonNode
		return (
			<div className="node_button_input">
				<span className="ms-4">{data.title}</span>
				<DeleteOutlineOutlined className="float-end me-3 mt-1" style={{ fontSize: "14px", display: data.showDelete ? 'block' : 'none' }} onClick={() => onButtonDeleteButtonClick(data.buttonNode)} />
			</div>
		)
	}

	const createActionLabel = (data) => {
		const bot = data.buttonNode.bot
		const action = data.buttonNode.bot.action;
		const node = data.buttonNode;
		return (
			<div onMouseEnter={() => onMouseEnterIntoNode(node.id)} onMouseLeave={() => onMouseLeaveIntoNode(node.id)} style={{ height: '100%', width: parentNodeWidth + 30 }}>
				{
					(() => {
						if (bot?.type === 'action') return (<CreateMessageHeader type={bot?.type} node={node} />)
					})()
				}
				<div>
					<div className="node_button_input">
						<span style={{ fontWeight: 'bold' }} className="">Action: {data.title}</span>
					</div>
					{
						action.type === 'action_set_custom_field' ?
							<div>
								<span>{actionList.actionList.find(e => e.action === action.type)?.title} <br />Variable: {action.fieldKey} <br />Value: {action.fieldValue} <br /></span>
							</div> : <></>
					}
					{
						action.type === 'action_input' ?
							<div>
								<span>Wait for users input</span>
							</div> : <></>
					}
					{
						action.type === 'action_webhook' ?
							<div>
								{action.webhookParams?.map(e => <span>{`${e.key}: ${e.value}`}<br /></span>)}
							</div> : <></>
					}
					{
						action.type === 'action_subscribe' ?
							<div>
								<span>Subscribe user for all communications</span>
							</div> : <></>
					}
					{
						action.type === 'action_unsubscribe' ?
							<div>
								<span>Unsubscribe user for all communications</span>
							</div> : <></>
					}
				</div>
			</div>
		)
	}

	const onMouseEnterIntoNode = (id) => {
		const obj = document.getElementById('options_' + id);
		if (obj) obj.style.display = 'block';
	}
	const onMouseLeaveIntoNode = (id) => {
		const obj = document.getElementById('options_' + id);
		if (obj) obj.style.display = 'none';
	}

	const createMessageBody = (bot, node) => {
		const error = bot.keys?.includes('error')
		let header = getDataFromTemplate(bot.template, 'header')?.text || bot.payload?.interactive?.header?.text
		let body = getDataFromTemplate(bot.template, 'body')?.text || bot.payload?.text?.body || bot.payload?.interactive?.body?.text
		let footer = getDataFromTemplate(bot.template, 'footer')?.text || bot.payload?.interactive?.footer?.text
		const menuTitle = bot.payload?.interactive?.action?.button
		const caption = getCaptionFromMedia(bot.payload)

		const params = getTemplateParamsFromPayload(bot.payload, 'body');
		body?.match(/{{[0-9]}}/g)?.forEach((f, i) => { if (i < params?.length) body = body.replace(f, params[i].text) })
		const bodyLink = getLinkFromMedia(bot.payload)
		const bodyType = bot.payload?.type

		const headerLink = getLinkFromMedia(bot.payload?.interactive?.header)
		const headerType = bot.payload?.interactive?.header?.type

		return (
			<div onMouseEnter={() => onMouseEnterIntoNode(node.id)} onMouseLeave={() => onMouseLeaveIntoNode(node.id)} style={{ height: '100%', width: parentNodeWidth + 30 }}>
				{
					(() => {
						if (bot?.payload?.type === 'text') return (<CreateMessageHeader type={bot?.payload?.type} node={node} />)
						if (bot?.payload?.type === 'image') return (<CreateMessageHeader type={bot?.payload?.type} node={node} />)
						if (bot?.payload?.type === 'video') return (<CreateMessageHeader type={bot?.payload?.type} node={node} />)
						if (bot?.payload?.type === 'audio') return (<CreateMessageHeader type={bot?.payload?.type} node={node} />)
						if (bot?.payload?.type === 'document') return (<CreateMessageHeader type={bot?.payload?.type} node={node} />)
						if (bot?.type === 'action') return (<CreateMessageHeader type={bot?.type} node={node} />)
						if (bot?.payload?.type === 'interactive' && bot?.payload?.interactive?.type === 'button') return (<CreateMessageHeader type={bot?.payload?.type} subType={bot?.payload?.interactive?.type} node={node} />)
						if (bot?.payload?.type === 'interactive' && bot?.payload?.interactive?.type === 'list') return (<CreateMessageHeader type={bot?.payload?.type} subType={bot?.payload?.interactive?.type} node={node} />)
						if (bot?.payload?.type === 'interactive' && bot?.payload?.interactive?.type === 'product') return (<CreateMessageHeader type={bot?.payload?.type} subType={bot?.payload?.interactive?.type} node={node} />)
						if (bot?.payload?.type === 'interactive' && bot?.payload?.interactive?.type === 'product_list') return (<CreateMessageHeader type={bot?.payload?.type} subType={bot?.payload?.interactive?.type} node={node} />)
						if (bot?.payload?.type === 'template' && bot?.payload?.template) return (<CreateMessageHeader type={bot?.payload?.type} node={node} />)
					})()
				}
				<div className="node_body_container mt-2" style={{ width: "180px", color: WtColor.TextDarkColor, background: WtColor.LineColor, padding: '8px' }}>
					<div style={{ display: error ? '' : 'none' }} className='node_body_error'>error</div>
					{
						(() => {
							if (headerType === 'image') {
								return headerLink ? <p className="text-center"><img src={headerLink} style={{ display: headerLink ? '' : 'none', maxWidth: '100%', objectFit: 'cover', height: imageNodeExtraHeight }} alt={WtTheme.SiteName} /></p> :
									<div>Select Header Image?<br /><p className="text-center"><InsertPhotoOutlined /></p></div>
							} else if (headerType === 'video') {
								return headerLink ?
									<p className="text-center">
										<video width="160px" height={imageNodeExtraHeight} className="hand p-2" controls><source src={headerLink} type="video/mp4" /></video>
									</p> : <div>Select Header video?<br /><p className="text-center"><VideocamOutlined /></p></div>
							} else if (headerType === 'audio') {
								return headerLink ?
									<ReactAudioPlayer src={headerLink} controls style={{ width: "180px" }} className="p-2" />
									: <div>Select Header Audios? ?<br /><p className="text-center"><AudioFileOutlined /> </p></div>
							} else if (headerType === 'document') {
								return headerLink ?
									<p className="text-center"><PictureAsPdfOutlined className="p-2" style={{ fontSize: "60px" }} /></p>
									: <div>Select Header PDF?<br /><p className="text-center"><PictureAsPdfOutlined /></p></div>
							}
						})()
					}
					{
						(() => {
							if (bodyType === 'image') {
								return bodyLink ? <p className="text-center"><img src={bodyLink} style={{ display: bodyLink ? '' : 'none', maxWidth: '100%', objectFit: 'cover', height: imageNodeExtraHeight }} alt={WtTheme.SiteName} /></p> :
									<div>Select Image?<br /><p className="text-center"><InsertPhotoOutlined /></p></div>
							} else if (bodyType === 'video') {
								return bodyLink ?
									<p className="text-center">
										<video width="160px" height={imageNodeExtraHeight} className="hand p-2" controls><source src={bodyLink} type="video/mp4" /></video>
									</p> : <div>Select Video?<br /><p className="text-center"><VideocamOutlined /></p></div>
							} else if (bodyType === 'audio') {
								return bodyLink ?
									<ReactAudioPlayer src={bodyLink} controls style={{ width: "180px" }} className="p-2" />
									: <div>Select Audio?<br /><p className="text-center"><AudioFileOutlined /> </p></div>
							} else if (bodyType === 'document') {
								return bodyLink ?
									<p className="text-center"><PictureAsPdfOutlined className="p-2" style={{ fontSize: "60px" }} /></p>
									: <div>Select PDF?<br /><p className="text-center"><PictureAsPdfOutlined /></p></div>
							}
						})()
					}
					<span style={{ display: caption ? '' : 'none', whiteSpace: 'pre-line' }} className='node_body_body'>{caption}</span>
					<span style={{ display: header ? '' : 'none', whiteSpace: 'pre-line' }} className='node_body_header'>{header}</span>
					<span style={{ display: body ? '' : 'none', whiteSpace: 'pre-line' }} className='node_body_body'>{body}</span>
					<span style={{ display: footer ? '' : 'none', whiteSpace: 'pre-line' }} className='node_body_footer'>{footer}</span>
					<div style={{ display: menuTitle ? 'flex' : 'none' }} className='node_body_menu_title'><div style={{ margin: 'auto', display: 'flex' }} ><div className='gg-menu' style={{ marginRight: '4px' }} />{menuTitle}</div></div>
				</div>
			</div >
		)
	}

	const onDrop = (event) => {
		event.preventDefault()
		if (instance && flow) {
			const data = JSON.parse(event.dataTransfer.getData('application/reactflow'))
			const position = instance.project({ x: event.clientX - 300, y: event.clientY - 40 })

			let newNodes = [];
			if (data.msgType === 'action') {
				const bot = {
					_id: createMongoObjectId(),
					businessId: businessId,
					botFlowId: flow._id,
					x: position.x,
					y: position.y,
					title: 'Untitled',
					type: 'action',
					action: {},
				}
				newNodes = createNodes(bot);
			} else if (data.msgType === 'button_ref') {
				const bot = {
					_id: createMongoObjectId(),
					businessId: businessId,
					botFlowId: flow._id,
					x: position.x,
					y: position.y,
					buttonId: 'button_id_' + getId()
				}
				newNodes = createNodes(bot);
			} else {
				const payload = createWAPayload(data.msgType);
				if (payload) {
					const bot = {
						_id: createMongoObjectId(),
						businessId: businessId,
						botFlowId: flow._id,
						x: position.x,
						y: position.y,
						keys: [],
						payload: payload
					}
					newNodes = createNodes(bot);
				}
			}

			if (newNodes.length > 0) {
				const node = newNodes[0];
				node.selected = true;
				instance.setNodes((nds) => {
					nds.forEach(n => n.selected = false)
					return nds.concat(newNodes)
				})
				setTimeout(() => {
					const height = node.style.height || node.style.width;
					instance.setCenter(node.position.x + node.style.width / 2, node.position.y + height / 2, { zoom: 1, duration: 500 })
				}, 500)
			}
		}
	}

	const onConnect = (params) => {
		const sourceNode = findNode(params.source);
		if (sourceNode.nodeType === 'msg_payload' || sourceNode.nodeType === 'action') {
			const nextKeys = sourceNode.bot.nextKeys?.filter(e => e !== params.target) || []
			nextKeys.push(params.target)
			sourceNode.bot.nextKeys = nextKeys
			if (containsLoopInNodeGraph()) {
				snackbarShow.snackbarShow('Cannot connect, it will form a loop!', WtColor.RedColor);
				sourceNode.bot.nextKeys.pop();
				return;
			}
		} else {
			const node = findNode(params.target);
			if (node) {
				const keys = node.bot?.keys || [];
				if (!keys.includes(params.source)) {
					keys.push(params.source);
					node.bot.keys = keys;
				}
			}
		}
		setEdges.setEdges((eds) => addEdge({ ...params, ...commonEdgeParams }, eds));
	}

	const onNodeDeleteButtonClick = (node) => {
		console.log('onNodeDeleteButtonClick', node)
		const edges = instance.toObject().edges.filter(e => e.source === node.id || e.target === node.id);
		onEdgesDelete(edges)

		deletedNodes.push(node);
		let nodes = instance.toObject().nodes
			.filter((n) => n.id !== node.id && n.parentNode !== node.id)
		setNodes.setNodes(nodes)
	}

	const onButtonDeleteButtonClick = (node) => {
		console.log('onButtonDeleteButtonClick', node);
		let nodes = instance.toObject().nodes
		if (node.nodeType === 'payload_button') {
			const parentIndex = nodes.findIndex(n => n.id === node.parentNode)
			if (parentIndex > -1) {
				const buttons = nodes[parentIndex].bot.payload?.interactive?.action?.buttons
				if (buttons?.length > 1) {
					nodes[parentIndex].bot.payload.interactive.action.buttons = buttons.filter(b => b.reply.id !== node.id);
				}

				nodes[parentIndex].bot.payload?.interactive?.action?.sections?.forEach(s => {
					if (s.rows.length > 1) {
						s.rows = s.rows.filter(b => b.id !== node.id)
					}
				})

				nodes.forEach(n => {
					if (n.bot) {
						n.bot.keys = n.bot.keys.filter(e => e !== node.id);
					}
				});
				const newNodes = createNodes(nodes[parentIndex].bot);
				nodes.splice(parentIndex, 1);
				nodes = nodes.filter(n => n.id !== node.id);
				newNodes.forEach(n => nodes.push(n));
				setNodes.setNodes(nodes);
			}
		} else if (node.nodeType === 'ref_button') {
			deletedNodes.push(node);
			setNodes.setNodes(nodes => {
				nodes.forEach(n => {
					if (n.bot?.keys) {
						n.bot.keys = n.bot.keys.filter(e => e !== node.id);
					}
				});
				return nodes.filter(n => n.id !== node.id)
			});
		}
	}

	const onNodeDuplicateButtonClick = (node) => {
		let nodes = instance.toObject().nodes;
		const bot = JSON.parse(JSON.stringify(node.bot));

		delete bot.keyWords;
		delete bot.keys;
		delete bot.nextKeys;
		bot.x = node.position.x + parentNodeWidth + 100;
		bot.y = node.position.y;
		bot._id = createMongoObjectId()
		const buttons = bot.payload?.interactive?.action?.buttons
			|| bot.payload?.interactive?.action?.sections?.flatMap((s) => s.rows)
		buttons?.forEach(b => {
			if (b.id) { b.id = getId() }
			else if (b.reply?.id) { b.reply.id = getId(); }
		})

		const newNodes = createNodes(bot);
		newNodes.forEach((n) => nodes.push(n))
		setNodes.setNodes(nodes)
	}

	const onNodesDelete = (nodes) => {
		nodes.forEach(n => {
			if (n.nodeType === 'ref_button' || n.nodeType === 'msg_payload' || n.nodeType === 'action') deletedNodes.push(n)
		})
	}

	const onEdgesDelete = (edges) => {
		for (const edge of edges) {
			const sourceNode = instance.toObject().nodes.find(n => {
				const nextKeys = n.bot?.nextKeys || [];
				return nextKeys.findIndex(e => e === edge.target) > -1;
			});
			console.log('onEdgesDelete', edge.source, edge.target, sourceNode);
			if (!!sourceNode) {
				sourceNode.bot.nextKeys = sourceNode.bot.nextKeys.filter(e => e !== edge.target);
			} else {
				const node = findNode(edge.target)
				if (node) {
					const keys = node.bot?.keys
					if (keys) {
						node.bot.keys = keys.filter(e => e !== edge.source)
					}
				}
			}
		}
	}

	const fetchBots = async () => {
		console.log(tag, 'fetchBots', flow);
		if (!flow) { return }
		handleWTLoader.handleWTLoader(true)
		try {
			let data = { botFlowId: flow._id }
			const response = await fetchAllBots(data);
			const res = await fetchAllCustomFields();
			setCustomFieldKeys.setCustomFieldKeys(res.data.data || []);
			if (response.status === 200) {
				setActionList.setActionList(response.data.data.actions || []); // action list
				deletedNodes = [];
				console.log(tag, 'fetch_bots', 'count', response.data.data.bots.length, 'actions', response.data.data.actions.length)
				localStorage.setItem("activeBotFlowID", response.data.data.flow._id)
				businessId = response.data.data.businessId
				botsOld = JSON.parse(JSON.stringify(response.data.data.bots))
				createNodesAndEdges(response.data.data.bots)
				setIsBotActive.setIsBotActive(response.data.data.flow.isLive)
				setBotId.setBotId(response.data.data.flow._id)

				handleWTLoader.handleWTLoader(false)
			} else {
				console.log("Bot Fetched Failed!!! Error Code: " + response.data.status + ", Error Message" + response.data.message)
				handleWTLoader.handleWTLoader(false)
				snackbarShow.snackbarShow('Reload failed', WtColor.RedColor)
			}
		} catch (error) {
			console.log(tag, error.message)
			handleWTLoader.handleWTLoader(false)
			snackbarShow.snackbarShow('Reload failed', WtColor.RedColor)
		}
	}



	const saveBots = async () => {
		if (localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true') {
			const bots = nodes.nodes.filter((node) => node.bot !== undefined).map((node) => node.bot)
			const changed = bots.filter((bot) => {
				const old = oldBot(bot._id);
				return old ? JSON.stringify(bot) !== JSON.stringify(old) : true
			})

			// validate incorrect payloads
			changed.forEach(bot => {
				if (bot.payload?.interactive?.header?.link === '' || bot.payload?.interactive?.header?.text === '') delete bot.payload.interactive.header
			})

			console.log(tag, 'changed', changed.length)
			console.log(tag, 'deleted', deletedNodes.length)

			const deleted = deletedNodes.map(n => n.bot)

			if (changed.length > 0 || deleted.length > 0) {
				handleWTLoader.handleWTLoader(true)
				snackbarShow.snackbarShow('Saving....', WtColor.BaseColor)
				try {
					let data = { updateList: changed, deleteList: deleted }
					const response = await botUpdateDelete(data)
					if (response.status === 200) {
						localStorage.setItem('userActivity', false)
						botsOld = JSON.parse(JSON.stringify(bots))
						deletedNodes = []
						handleWTLoader.handleWTLoader(false)
						snackbarShow.snackbarShow('Saved Successfully', WtColor.BaseColor)
					} else {
						console.log(response.data.message)
						handleWTLoader.handleWTLoader(false)
						snackbarShow.snackbarShow('Save failed', WtColor.RedColor)
						localStorage.setItem('userActivity', false)
					}
				} catch (error) {
					console.log(tag, error.response.data)
					handleWTLoader.handleWTLoader(false)
					snackbarShow.snackbarShow('Save failed', WtColor.RedColor)
					localStorage.setItem('userActivity', false)
				}
			} else {
				snackbarShow.snackbarShow('Nothing to save!', WtColor.RedColor)
				localStorage.setItem('userActivity', false)
			}
		} else { openDemoDialogBox() }
	}

	const createNodes = (bot) => {
		let nodes = []
		if (bot.type === 'action') {
			const newAction = {
				bot: bot,
				zIndex: 1001,
				id: bot._id,
				type: 'default',
				position: { x: bot.x || 0, y: bot.y || 0 },
				targetPosition: Position.Left,
				sourcePosition: Position.Right,
				style: { width: parentNodeWidth, height: parentNodeHeight },
				key: getId(),
				nextBtnId: createMongoObjectId(),
				nodeType: 'action',
			};
			newAction.data = { label: createActionLabel({ title: bot.title, buttonNode: newAction, showDelete: true }) };
			nodes.push(newAction);
		} else if (bot.buttonId) {
			const newButton = {
				bot: bot,
				zIndex: 1001,
				id: bot.buttonId,
				type: 'input',
				position: { x: bot.x || 0, y: bot.y || 0 },
				sourcePosition: Position.Right,
				style: { width: parentNodeWidth, height: buttonHeight },
				key: getId(),
				nodeType: 'ref_button'
			};
			newButton.data = { label: createButtonLabel({ title: bot.buttonId, buttonNode: newButton, showDelete: true }) }
			nodes.push(newButton)
		} else if ((bot.payload && bot.payload?.interactive?.type !== "product_list") || bot.template) {
			const payload = bot.payload;
			const buttons = payload?.interactive?.action?.buttons
				|| payload?.interactive?.action?.sections?.flatMap((s) => s.rows)
				|| bot.template?.components?.flatMap(e => e.buttons).filter(e => !!e)
				|| [];


			const newNode = {
				id: bot._id,
				type: 'default',
				position: { x: bot.x || 0, y: bot.y || 0 },
				key: getId(),
				style: { height: parentNodeHeight, width: parentNodeWidth },
				targetPosition: Position.Left,
				sourcePosition: Position.Right,
				nextBtnId: createMongoObjectId(),
				nodeType: 'msg_payload'
			}
			newNode.data = { label: createMessageBody(bot, newNode) }
			newNode.bot = bot

			const extraHeight = (bot.payload?.interactive?.header?.type || 'text') === 'text' ? 0 : imageNodeExtraHeight;
			if (payload?.interactive) {
				newNode.style = { width: parentNodeWidth, height: parentNodeHeight + extraHeight + buttons.length * buttonHeight + 15 }
			}
			nodes.push(newNode);

			let y = parentNodeHeight + extraHeight + 2
			for (const btn of buttons) {
				const newButton = {
					zIndex: 1001,
					id: btn.reply?.id || btn.id || btn.buttonPayload?.split('|')[0],
					type: 'input',
					parentNode: newNode.id,
					extent: 'parent',
					draggable: false,
					selectable: true,
					position: { x: 5, y: y },
					sourcePosition: Position.Right,
					style: { width: parentNodeWidth - 10, height: buttonHeight },
					key: getId(),
					buttonObj: btn,
					nodeType: 'payload_button'
				}
				newButton.data = { label: createButtonLabel({ title: btn.reply?.title || btn.title || btn.text, buttonNode: newButton, showDelete: buttons.length > 1 }) }
				nodes.push(newButton)
				y += (buttonHeight + 2)
			}
		}
		else if ((bot.payload && bot.payload?.interactive?.type === "product_list")) {
			const payload = bot.payload
			// const retailId = bot.payload?.interactive?.action?.sections?.flatMap((s) => s.product_items) || [];
			const newNode = {
				id: bot._id,
				type: 'default',
				position: { x: bot.x || 0, y: bot.y || 0 },
				key: getId(),
				style: { height: parentNodeHeight, width: parentNodeWidth },
				targetPosition: Position.Left,
				sourcePosition: Position.Right,
				nextBtnId: createMongoObjectId(),
				nodeType: 'msg_payload'
			}
			newNode.data = { label: createMessageBody(bot, newNode) }
			newNode.bot = bot

			const extraHeight = (bot.payload?.interactive?.header?.type || 'text') === 'text' ? 0 : imageNodeExtraHeight;
			if (payload?.interactive) {
				newNode.style = { width: parentNodeWidth, height: parentNodeHeight + extraHeight }
			}
			nodes.push(newNode);
		}

		if ((bot.keys || []).findIndex(e => e === bot._id) === -1) {
			const keys = bot.keys || [];
			keys.push(bot._id);
			bot.keys = keys;
		}

		return nodes
	}

	const commonEdgeParams = {
		markerEnd: { type: MarkerType.ArrowClosed, },
		type: 'customEdge',
		data: {
			onEdgesDeleteId: (id) => {
				const edge = instance.toObject().edges.find(e => e.id === id);
				if (edge) {
					onEdgesDelete([edge]);
					setEdges.setEdges((eds) => eds.filter((e) => e.id !== id));
				}
			}
		}
	}

	const createEdges = (nodes) => {
		let edges = [];
		for (const node of nodes) {
			if (node.nodeType === 'payload_button' || node.nodeType === 'ref_button') {
				const buttonId = node.id || node.bot?.buttonId
				const connectedNodes = nodes.filter((n) => n.bot?.keys?.includes(buttonId) && n.extent !== 'parent')
				for (const n of connectedNodes) {
					edges.push({ id: getId(), source: buttonId, target: n.id, ...commonEdgeParams });
				}
			} else if (node.nodeType === 'msg_payload' || node.nodeType === 'action') {
				node.bot?.nextKeys?.forEach(k => {
					edges.push({ id: getId(), source: node.id, target: k, ...commonEdgeParams });
				})
			}
		}
		console.log(tag, 'edges', edges.length)
		setEdges.setEdges(edges)
	}

	const createNodesAndEdges = (bots) => {
		const nodes = [];
		for (const bot of bots) {
			const newNodes = createNodes(bot)
			newNodes.forEach((n) => nodes.push(n))
		}
		console.log(tag, 'nodes', nodes.length)
		setNodes.setNodes(nodes)
		createEdges(nodes)
		instance.fitView({ duration: 500 })
	}

	const onSelectFlow = (flow) => { if (flow) setFlow(flow) }

	const onSelectTemplate = (template) => {
		const bot = {
			_id: createMongoObjectId(),
			businessId: businessId,
			botFlowId: flow._id,
			x: 0,
			y: 0,
			keys: [],
			template: template,
			payload: createPayloadFromTemplate(template)
		}
		const news = createNodes(bot);
		news[0].selected = true;
		setNodes.setNodes((nodes) => nodes.concat(news));
		setTimeout(() => {
			instance.setCenter(0, 0, { zoom: 1, duration: 500 })
		}, 500)
	}

	useEffect(() => {
		if (selectedNodeUpdated.selectedNodeUpdated?.id) {
			let node = selectedNodeUpdated.selectedNodeUpdated
			if (selectedNodeUpdated.selectedNodeUpdated.extent === 'parent') {
				node = findNode(selectedNodeUpdated.selectedNodeUpdated.parentNode);
			}
			let nodes = instance.toObject().nodes
			nodes = nodes.filter(n => n.id !== node.id && n.parentNode !== node.id)
			const newNodes = createNodes(node.bot)

			newNodes.forEach((n) => nodes.push(n))
			if (selectedNodeUpdated.selectedNodeUpdated.extent === 'parent') {
				const index = newNodes.findIndex((n) => n.id === selectedNodeUpdated.selectedNodeUpdated.id)
				if (index > -1) newNodes[index].selected = true;
			} else {
				newNodes[0].selected = true;
			}
			setNodes.setNodes(nodes)
			createEdges(nodes)
		}
	}, [selectedNodeUpdated.selectedNodeUpdated])


	// To Check -  any activity is happen
	useEffect(() => {
		const bots = nodes.nodes.filter((node) => node.bot !== undefined).map((node) => node.bot)
		const changed = bots.filter((bot) => {
			const old = oldBot(bot._id);
			return old ? JSON.stringify(bot) !== JSON.stringify(old) : true
		})
		const deleted = deletedNodes.map(n => n.bot)

		if (changed.length > 1 || deleted.length > 0) {
			console.log("changed: ", changed.length, "deleted: ", deleted.length)
			localStorage.setItem('userActivity', true)
		}
	}, [nodes.nodes, deletedNodes])
	// End of To Check -  any activity is happen


	useEffect(() => { fetchBots() }, [flow])

	const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
	const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })
	return (
		<ThemeProvider theme={theme}>
			<div className="dndflow" style={{ height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh' }}>
				<ConfirmDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />

				{openSnackbar.openSnackbar.open && <ShowSnackbar open={openSnackbar.openSnackbar.open} errorMsg={openSnackbar.openSnackbar.errorMsg} errorMsgBg={openSnackbar.openSnackbar.errorMsgBg} close={snackbarClose.snackbarClose} />}

				{isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
				<DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />

				<div className="leftsidebar"><LeftSidebar saveBots={saveBots} onSelectTemplate={onSelectTemplate} style={{ height: "100%" }} /></div>

				<div className="reactflow-wrapper" style={{ borderLeft: "1px solid " + WtColor.LineColor, borderRight: "1px solid " + WtColor.LineColor }}>
					<div><TopBar onSelectFlow={onSelectFlow} onSelectTemplate={onSelectTemplate} reloadData={reloadData} saveBots={saveBots} /></div>
					<ReactFlow nodes={nodes.nodes} edgeTypes={edgeTypes} edges={edges.edges} onNodesChange={onNodesChange.onNodesChange} onEdgesChange={onEdgesChange.onEdgesChange} onNodeDragStop={onNodeDragStop} onSelectionChange={onSelectionChange} onConnect={onConnect} onEdgesDelete={onEdgesDelete} onNodesDelete={onNodesDelete} defaultEdgeOptions={1} defaultZoom={1} minZoom={0.2} maxZoom={2} fitView={true} snapToGrid={false} selectNodesOnDrag={false} onDrop={onDrop} onDragOver={onDragOver} onInit={onInit} >
						<Controls />
						<Background variant={BackgroundVariant.Dots} />
						<div className="hand" style={{ position: 'absolute', left: 0, bottom: -16, zIndex: 9999 }}>
							<p className="pe-2 ps-2 pt-1 pb-0" style={{ background: WtColor.SelectionColor, fontWeight: 500, fontSize: "8px", color: WtColor.TextDarkColor }}>⚡powered by WhatsTool Business</p>
						</div>
					</ReactFlow>
				</div>

				<div className="rightsidebar" style={{ display: selectedNode.selectedNode ? '' : 'none' }}><RightSidebar /></div>
			</div>
		</ThemeProvider >
	)
}

export default function App() { return (<ChatbotState><ReactFlowProvider><BasicFlow /></ReactFlowProvider></ChatbotState>) }

function createWAPayload(type) {
	switch (type) {
		case 'interactive_buttons':
			return {
				"recipient_type": "individual",
				"type": "interactive",
				"interactive": {
					"type": "button",
					"header": {
						"type": "text",
						"text": "Header"
					},
					"body": {
						"text": "Body"
					},
					"footer": {
						"text": "Footer"
					},
					"action": {
						"buttons": [
							{
								"type": "reply",
								"reply": {
									"id": getId(),
									"title": "Button Title"
								}
							}
						]
					}
				}
			}
		case 'interactive_menu':
			return {
				"recipient_type": "individual",
				"type": "interactive",
				"interactive": {
					"type": "list",
					"header": {
						"type": "text",
						"text": "Header"
					},
					"body": {
						"text": "Body"
					},
					"footer": {
						"text": "Footer"
					},
					"action": {
						"button": "Select Here",
						"sections": [
							{
								"title": "Section title 1",
								"rows": [
									{
										"id": getId(),
										"title": "Title 1",
										"description": "Description 1"
									},
									{
										"id": getId(),
										"title": "Title 2",
										"description": "Description 2"
									}
								]
							},
							{
								"title": "Section title 2",
								"rows": [
									{
										"id": getId(),
										"title": "Title 1",
										"description": "Description 1"
									},
									{
										"id": getId(),
										"title": "Title 2",
										"description": "Description 2"
									}
								]
							}
						]
					}
				}
			}
		case 'interactive_products_single': {
			return {
				"recipient_type": "individual",
				"type": "interactive",
				"interactive": {
					"type": "product",
					"body": {
						"text": "Product Body",
					},
					"footer": {
						"text": "Product Footer"
					},
					"action": {
						"catalog_id": "Catalog Id",
						"product_retailer_id": "Retailer Id",
					}
				}
			}
		}
		case 'interactive_products_multiple': {
			return {
				"recipient_type": "individual",
				"type": "interactive",
				"interactive": {
					"type": "product_list",
					"header": {
						"type": "text",
						"text": "All Product List"
					},
					"body": {
						"text": "Catalog Body"
					},
					"footer": {
						"text": "Catalog Footer"
					},
					"action": {
						"catalog_id": "Catalog Id",
						"sections": [
							{
								"title": "Section title 1",
								"product_items": [
									{
										"product_retailer_id": "Retailer Id",
									},
									{
										"product_retailer_id": "Retailer Id",
									},
									{
										"product_retailer_id": "Retailer Id",
									},
								]
							},
							{
								"title": "Section title 2",
								"product_items": [
									{
										"product_retailer_id": "Retailer Id",
									},
									{
										"product_retailer_id": "Retailer Id",
									},
									{
										"product_retailer_id": "Retailer Id",
									},
								]
							},
						]
					}
				}
			}
		}
		case 'text':
			return {
				"recipient_type": "individual",
				"type": "text",
				"text": {
					"body": "Body"
				}
			}
		case 'image':
			return {
				"recipient_type": "individual",
				"type": "image",
				"image": {
					"link": ""
				}
			}
		case 'video':
			return {
				"recipient_type": "individual",
				"type": "video",
				"video": {
					"link": ""
				}
			}
		case 'audio':
			return {
				"recipient_type": "individual",
				"type": "audio",
				"audio": {
					"link": ""
				}
			}
		case 'document':
			return {
				"recipient_type": "individual",
				"type": "document",
				"document": {
					"link": ""
				}
			}
		default:
			return undefined
	}
}

function createPayloadFromTemplate(template) {
	const payload = {
		'type': 'template',
		'template': {
			'namespace': template.namespace,
			'language': {
				'policy': 'deterministic',
				'code': template.language
			},
			'name': template.name,
			'components': []
		}
	}

	template.components?.forEach(e => {
		e.buttons?.forEach(b => {
			const id = [template.namespace, template.language, template.name, b.text].join('').split('').reduce((r, c, i) => i % 2 === 0 ? r + c : r);
			b.buttonPayload = [id].join('|');
		});
	});

	template.components?.forEach((comp) => {
		if (comp.format) {
			if (comp.format === 'IMAGE') {
				const payComp = {}
				payComp.type = comp.type.toLowerCase();
				payComp.parameters = [{
					type: comp.format.toLowerCase(),
					[`${comp.format.toLowerCase()}`]: {
						link: ''
					}
				}];
				payload.template.components.push(payComp);
			}
		} else if (comp.text) {
			const payComp = {}
			const vars = comp.text.match(/{{[0-9]}}/g)
			if (vars?.length > 0) {
				payComp.type = comp.type.toLowerCase()
				payComp.parameters = []

				vars.forEach((each, i) => {
					payComp.parameters.push({
						"type": "text",
						"text": `value ${i + 1}`
					});
				});
			}
			if (Object.keys(payComp).length > 0) {
				payload.template.components.push(payComp);
			}
		}

		if (comp.buttons) {
			comp.buttons.forEach((e, i) => {
				if (e.type === 'quick_reply') {
					const payComp = {};
					payComp.type = 'button';
					payComp.index = `${i}`;
					payComp.sub_type = e.type.toLowerCase();
					payComp.parameters = [];
					payComp.parameters.push({
						'type': "payload",
						'payload': e.buttonPayload
					});
					payload.template.components.push(payComp);
				}
			});
		}

	})

	return payload
}

function getDataFromTemplate(template, type) {
	const i = template?.components?.findIndex((c) => c.type.toLowerCase() === type)
	if (i > - 1)
		return template.components[i]
}

function getTemplateParamsFromPayload(payload, type) {
	if (payload?.template?.components) {
		const i = payload?.template?.components?.findIndex((c) => c.type.toLowerCase() === type)
		if (i > - 1)
			return payload?.template?.components[i].parameters
	}
}

export function getLinkFromMedia(header) {
	return header?.image?.link || header?.video?.link || header?.audio?.link || header?.document?.link
}

export function getCaptionFromMedia(header) {
	return header?.image?.caption || header?.video?.caption || header?.document?.caption
}