/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Chip, Switch, Tooltip } from '@mui/material'
import { Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { ProfileSidebar } from '../UserProfile/ProfileSidebar'
import { fetchPlanList, fetchPlanUsage } from '../../controller/APIs'
import { useLocation } from 'react-router-dom'
import { AppOnboardingDialog, PaymentGatewayChooseDialog, ShowDateTime, WtLoader } from '../../controller/BaseTool'
import { InfoOutlined } from '@mui/icons-material'
import { useWindowDimensions } from '../useWindowDimensions'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const AddOns = () => {
    const notificationBarStatus = useContext(globalContext)
    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }
    let query = useQuery()
    let auth = query.get("auth")

    if (auth) { localStorage.setItem('userAuthToken', auth) }


    const { width } = useWindowDimensions()

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    const [activePlan, setActivePlan] = useState([])
    const [wtbAddOnsPlanLs, setWTBAddOnPlanLs] = useState([])
    const [msgCreditAddOnsPlanLs, setMsgCreditAddOnPlanLs] = useState([])

    const getPlanUsage = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchPlanUsage()
            if (response.status === 200) {
                handleWTLoader.handleWTLoader(false)
                setActivePlan(response.data.data[0])
                setWTBAddOnPlanLs(response.data.data[0].wtbSetup)
                setMsgCreditAddOnPlanLs(response.data.data[0].chatAddons)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }

    const [chatAddOnId, setChatAddOnId] = useState("")
    const [chatAddOnName, setChatAddOnName] = useState("")

    const [wtbAddOnId, setWtbAddOnId] = useState("")
    const [wtbAddOnName, setWtbAddOnName] = useState("")
    const [wtbAddOnPrice, setWtbAddOnPrice] = useState("")

    const getPlanList = async () => {
        try {
            const response = await fetchPlanList()
            if (response.status === 200) {
                response.data.data?.map((v, i) => {
                    if (v._id === "WTBSetup") {
                        v.plans?.map((v1) => {
                            setWtbAddOnId(v1._id)
                            setWtbAddOnName(v1.name)
                            if (v1.percentageS === 0) setWtbAddOnPrice(v1.price)
                            if (v1.percentageS !== 0) {
                                let finalPrice = Math.floor(v1.price - ((v1.price * v1.percentageS) / 100))
                                setWtbAddOnPrice(finalPrice)
                            }
                        })
                    }
                    if (v._id === "ChatAddon") {
                        v.plans?.map((v1) => {
                            if (v1.purpose === "free" && activePlan.planName === "Free") {
                                console.log("Free: " + v1._id + " " + v1.name)
                                setChatAddOnId(v1._id)
                                setChatAddOnName(v1.name)
                            }
                            if (v1.purpose === "premium" && activePlan.planName !== "Free") {
                                console.log("Premium: " + v1._id + " " + v1.name)
                                setChatAddOnId(v1._id)
                                setChatAddOnName(v1.name)
                            }
                        })
                    }
                })
            } else { }
        } catch (error) { }
    }
    useEffect(() => {
        getPlanUsage()
        getPlanList()
    }, [])


    const [amount, setAmount] = useState(0)
    const handleAmount = (val) => {
        let number = val.replace(/[^0-9]/ig, "")
        setAmount(number)
    }

    const handleWTBServiceAddOnsPaymentBtn = (pid, name, price) => {
        let stripePaymentObj = {
            "paymentUrl": "/payments/wtb-setup",
            "planId": pid,
            "validity": "",
            "qty": "",
            "amount": price,
            "billId": "",
            "planName": name
        }
        let razorpayPaymentObj = {
            "paymentUrl": "/payments/razorpay/wtb-setup",
            "planId": pid,
            "validity": "",
            "qty": "",
            "amount": price,
            "billId": "",
            "planName": name
        }
        openPaymentGatewayOptDialogBox(razorpayPaymentObj, stripePaymentObj)
    }
    const handleAddOnsChatPaymentBtn = (pid, name, price) => {
        let stripePaymentObj = {
            "paymentUrl": "/payments/chats-addon",
            "planId": pid,
            "validity": "",
            "qty": "",
            "amount": price,
            "billId": "",
            "planName": name
        }
        let razorpayPaymentObj = {
            "paymentUrl": "/payments/razorpay/chats-addon",
            "planId": pid,
            "validity": "",
            "qty": "",
            "amount": price,
            "billId": "",
            "planName": name
        }
        openPaymentGatewayOptDialogBox(razorpayPaymentObj, stripePaymentObj)
    }

    const [openPaymentGatewayOptDialog, setOpenPaymentGatewayOptDialog] = useState({ open: false, onClose: null })
    const openPaymentGatewayOptDialogBox = (razorpayObj, stripeObj) => setOpenPaymentGatewayOptDialog({ open: true, razorpayObj: razorpayObj, stripeObj: stripeObj, onClose: () => setOpenPaymentGatewayOptDialog(false) })

    useEffect(() => {
        if (wtbAddOnsPlanLs?.length > 0) {
            if (localStorage.getItem("onboardingStatus") && localStorage.getItem("onboardingStatus") === "buy_now_success") openAppOnboardingDialog()
        } else {
            localStorage.removeItem("addons_p")
        }
    }, [wtbAddOnsPlanLs])

    const [appOnboardingDialog, setAppOnboardingDialog] = useState({ open: false, onClose: null })
    const openAppOnboardingDialog = () => {
        setAppOnboardingDialog({
            open: true, onClose: (confirm) => {
                setAppOnboardingDialog(false)
                if (confirm) { setAppOnboardingDialog(false) }
            }
        })
    }

    let usdVal = 82
    const [currencyVal, setCurrencyVal] = useState(false)
    const handleCurrencyVal = (event) => { setCurrencyVal(event.target.checked) }


    const ProfileStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh',
        width: '94vw'
    }
    return (
        <div className={width <= 780 ? '' : ''} style={ProfileStyle}>
            {(width <= 780) ? "" : <ProfileSidebar />}
            <div className={width <= 780 ? '' : "profile_menu_item bg-white"}>
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                <Container className="addOns" fluid>
                    <div className="profile_menu_header ps-3 pt-3 pb-2">
                        <h4 style={{ color: WtColor.TextDarkColor }}>Message Credit</h4>
                    </div>
                    {
                        wtbAddOnsPlanLs?.length > 0 ? <AppOnboardingDialog open={appOnboardingDialog.open} onClose={appOnboardingDialog.onClose} /> : ""
                    }
                    <PaymentGatewayChooseDialog open={openPaymentGatewayOptDialog.open} razorpayObj={openPaymentGatewayOptDialog.razorpayObj} stripeObj={openPaymentGatewayOptDialog.stripeObj} onClose={openPaymentGatewayOptDialog.onClose} />
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} style={{ borderRight: "1px solid " + WtColor.LineColor }}>
                            <div className="ms-3 mt-2">
                                <div>
                                    <p style={{ color: WtColor.BaseColor, fontSize: "40px", fontWeight: "bold" }}>
                                        {(msgCreditAddOnsPlanLs?.reduce((a, v) => a = a + v.addonUsed, 0))} / {(msgCreditAddOnsPlanLs?.reduce((a, v) => a = a + v.addonBal, 0))}
                                    </p>
                                    <p style={{ color: WtColor.TextLightGrey, marginTop: '-20px' }}>Available Message Balance</p>
                                </div>
                                <div style={{ width: width <= 780 ? '90%' : "75%", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "20px", paddingRight: "20px", background: WtColor.LineColor, borderRadius: "10px" }}>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor }}>Add money to your Message Credits &nbsp;&nbsp;
                                        <Tooltip title="Add money to your Message Credits" placement="top"><InfoOutlined style={{ fontSize: "15px", color: WtColor.TextLightGrey }} /></Tooltip>
                                    </p>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text className="fw-bold" style={{ fontSize: "20px", background: "#fff", color: WtColor.TextMidGrey, border: "none" }}>₹</InputGroup.Text>
                                        <Form.Control value={amount} onChange={(e) => handleAmount(e.target.value)} type="tel" style={{ fontSize: "20px", border: "none", color: WtColor.TextMidGrey, }} id="inlineFormInputGroup" placeholder="Enter Amount" />
                                    </InputGroup>
                                    <div className="hand">
                                        <Chip label={"+ ₹100"} onClick={() => setAmount(100)} variant="filled" className="mb-2 me-2" size="small" style={{ background: "#fff", color: WtColor.TextMidGrey }} />
                                        <Chip label={"+ ₹500"} onClick={() => setAmount(500)} variant="filled" className="mb-2 me-2" size="small" style={{ background: "#fff", color: WtColor.TextMidGrey }} />
                                        <Chip label={"+ ₹1,000"} onClick={() => setAmount(1000)} variant="filled" className="mb-2 me-2" size="small" style={{ background: "#fff", color: WtColor.TextMidGrey }} />
                                        <Chip label={"+ ₹2,000"} onClick={() => setAmount(2000)} variant="filled" className="mb-2 me-2" size="small" style={{ background: "#fff", color: WtColor.TextMidGrey }} />
                                        <Chip label={"+ ₹5,000"} onClick={() => setAmount(5000)} variant="filled" className="mb-2 me-2" size="small" style={{ background: "#fff", color: WtColor.TextMidGrey }} />
                                    </div>
                                    <div className="mt-5">
                                        <button onClick={() => handleAddOnsChatPaymentBtn(chatAddOnId, chatAddOnName, amount)} disabled={amount > 99 ? false : true} style={{ background: WtColor.BaseColor }} className="btn btn-md rounded-3 text-white fw-bold">Add Credits</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className='scrollBox mt-3 mb-3' style={{ height: "250px" }}>
                                <Table style={{ width: "90%" }} responsive>
                                    <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                                        <tr>
                                            <th>Name</th>
                                            <th>Balance</th>
                                            <th>Start Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "none" }}>
                                        {
                                            msgCreditAddOnsPlanLs?.map((v, i) => {
                                                return (
                                                    <tr style={{ color: WtColor.TextColor, fontSize: "12px" }} key={i}>
                                                        <th>{v.planName}</th>
                                                        <th>{v.addonUsed} / {v.addonBal}</th>
                                                        <th>{v.startDate ? ShowDateTime(v.startDate) : ""}</th>
                                                        <th>{v.status}</th>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <div className="profile_menu_header  mt-4 ps-3 pt-3 pb-2">
                        <h4 style={{ color: WtColor.TextDarkColor }}>WhatsTool Business Setup Service</h4>
                    </div>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} style={{ borderRight: "1px solid " + WtColor.LineColor }}>
                            <div className="ms-3" style={{ width: width <= 780 ? '90%' : "50%", border: "2px solid " + WtColor.LineColor, borderRadius: "10px" }}>
                                <div className="bg-white rounded-3">
                                    <div className="rounded-3" style={{ background: WtColor.LineColor, paddingTop: "8px", paddingBottom: "8px" }}>
                                        <p className="text-center pb-0 mb-0" style={{ color: WtColor.TextMidGrey }}>Best for starting</p>
                                    </div>
                                    <div className="p-3">
                                        <p className="text-center mt-4" style={{ fontWeight: "bold", marginTop: "-8px" }}>
                                            <span>INR (₹)</span>&nbsp;<Switch checked={currencyVal} onChange={handleCurrencyVal} />&nbsp;<span>USD ($)</span>
                                        </p>
                                        <div>
                                            <p className="fw-bold" style={{ color: WtColor.RedColor, fontSize: "30px" }}>{wtbAddOnName}</p>
                                            <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px", marginTop: "-24px" }}>{currencyVal ? '$' : '₹'}{currencyVal ? Math.floor(wtbAddOnPrice / usdVal) : wtbAddOnPrice}</p>
                                        </div>
                                        <button className="btn mt-4 btn-sm w-100 theme_bg text-white fw-bold" onClick={() => handleWTBServiceAddOnsPaymentBtn(wtbAddOnId, wtbAddOnName, wtbAddOnPrice)}>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className='scrollBox mt-3 mb-3' style={{ height: "250px" }}>
                                <Table style={{ width: "90%" }} responsive>
                                    <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                                        <tr>
                                            <th>Name</th>
                                            <th>Purchase Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "none" }}>
                                        {
                                            wtbAddOnsPlanLs?.map((v, i) => {
                                                return (
                                                    <tr style={{ color: WtColor.TextColor, fontSize: "12px" }} key={i}>
                                                        <th>{v.name}</th>
                                                        <th>{v.buyDate ? ShowDateTime(v.buyDate) : ""}</th>
                                                        <th>{v.planStatus}</th>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}