import React, { useState, useEffect } from 'react'
import './index.css'
import { ListItemText, List, ListItemButton } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'

export const SideMenu = () => {
    const history = useHistory()
    const openUrl = (url) => { history.push(url) }

    const [sidebarIndex, setSidebarIndex] = useState(0)
    useEffect(() => {
        if (window.location.href === WtUrl.localUI_URL + WtUrl.businessInformationUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.businessInformationUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.businessInformationUrl) setSidebarIndex(0)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.channelUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.channelUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.channelUrl) setSidebarIndex(1)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.agentsUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.agentsUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.agentsUrl) setSidebarIndex(2)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.labelsUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.labelsUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.labelsUrl) setSidebarIndex(3)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.customFieldsUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.customFieldsUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.customFieldsUrl) setSidebarIndex(4)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.quickReplyUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.quickReplyUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.quickReplyUrl) setSidebarIndex(5)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.templateUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.templateUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.templateUrl) setSidebarIndex(6)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.mediaUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.mediaUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.mediaUrl) setSidebarIndex(7)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.apiIntegrationUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.apiIntegrationUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.apiIntegrationUrl) setSidebarIndex(8)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.appIntegrationUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.appIntegrationUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.appIntegrationUrl) setSidebarIndex(9)
    }, [])
    return (
        <div className="settingSidebar bg-white" style={{ flex: "0.15", borderRight: "1px solid " + WtColor.LineColor }}>
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                <ListItemButton selected={sidebarIndex === 0} onClick={() => openUrl(WtUrl.businessInformationUrl)}>
                    <ListItemText primary="Business Info." />
                </ListItemButton>
                {
                    localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ?
                        <ListItemButton selected={sidebarIndex === 1} onClick={() => openUrl(WtUrl.channelUrl)}>
                            <ListItemText primary="Channels" />
                        </ListItemButton> : ''
                }
                <ListItemButton selected={sidebarIndex === 2} onClick={() => openUrl(WtUrl.agentsUrl)}>
                    <ListItemText primary="Agents" />
                </ListItemButton>
                <ListItemButton selected={sidebarIndex === 3} onClick={() => openUrl(WtUrl.labelsUrl)}>
                    <ListItemText primary="Labels" />
                </ListItemButton>
                <ListItemButton selected={sidebarIndex === 4} onClick={() => openUrl(WtUrl.customFieldsUrl)}>
                    <ListItemText primary="Custom Fields" />
                </ListItemButton>
                <ListItemButton selected={sidebarIndex === 5} onClick={() => openUrl(WtUrl.quickReplyUrl)}>
                    <ListItemText primary="Quick Reply" />
                </ListItemButton>
                {
                    localStorage.getItem("c1") &&
                    <>
                        {/* <ListItemButton selected={sidebarIndex === 6} onClick={() => openUrl(WtUrl.templateUrl)}>
                            <ListItemText primary="Templates" />
                        </ListItemButton> */}
                        < ListItemButton selected={sidebarIndex === 7} onClick={() => openUrl(WtUrl.mediaUrl)}>
                            <ListItemText primary="Media" />
                        </ListItemButton>
                    </>
                }

                <ListItemButton selected={sidebarIndex === 8} onClick={() => openUrl(WtUrl.apiIntegrationUrl)}>
                    <ListItemText primary="API Integration" />
                </ListItemButton>
                <ListItemButton selected={sidebarIndex === 9} onClick={() => openUrl(WtUrl.appIntegrationUrl)}>
                    <ListItemText primary="APP Integration" />
                </ListItemButton>
            </List >
        </div >
    )
}