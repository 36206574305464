import React from 'react'
import { Button } from '@mui/material'
import { Carousel, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { WtTheme } from '../../controller/theme'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'

export const BroadcastOnBoarding = () => {
    return (
        <section className="p-5">
            <Carousel style={{ position: "absolute", top: "20%", left: "20%" }} controls={true} slide={false}>
                <Carousel.Item interval={4000}>
                    <Row style={{ height: "600px" }}>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className="float-start pt-5 ps-5 pe-5 mt-2">
                                <h3 className="text-center" style={{ color: WtColor.TextDarkColor }}>Create Campaign in 3 Simple Steps</h3>
                                <h4 className="mt-5 pt-5 text-center" style={{ color: WtColor.TextMidGrey }}>Channel</h4>
                                <p className="text-center" style={{ color: WtColor.TextLightGrey }}>Select the Channel on which you will like to Campaign</p>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <p className="text-center"><img className="img-fluid float-end pe-5 ps-5" width="550px" src="https://raw.githubusercontent.com/love-building-app/letsserve/925df99449b4fa9cf86c1ef80958debfb5a23ace/prod/home/wtx_website/images/BroadcastOnboard01.svg" alt={WtTheme.SiteName} /></p>
                        </Col>
                    </Row>
                    <Row style={{ position: "absolute", top: "350px", left: "180px" }}>
                        <Link to={WtUrl.broadcastCreateUrl}>
                            <p className="text-center w-100"><Button variant="contained" style={{ textTransform: "none", whiteSpace: "nowrap" }} className="btn btn-sm theme_bg text-white rounded-3 w-100 "><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/11dc9b0611b6a53279e5c5b8d4d02d9e855cbb2a/prod/home/wtx_website/images/startBroadcast.svg"} alt={WtTheme.SiteName} />&nbsp; Start New Campaign</Button></p>
                        </Link>
                    </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <Row style={{ height: "600px" }}>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className="float-start pt-5 ps-5 pe-5 mt-2">
                                <h3 className="text-center" style={{ color: WtColor.TextDarkColor }}>Create Campaign in 3 Simple Steps</h3>
                                <h4 className="mt-5 pt-5 text-center" style={{ color: WtColor.TextMidGrey }}>Template</h4>
                                <p className="text-center" style={{ color: WtColor.TextLightGrey }}>Select the Template that you will like to Campaign</p>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <p className="text-center"><img className="img-fluid float-end pe-5 ps-5" width="550px" src="https://raw.githubusercontent.com/love-building-app/letsserve/925df99449b4fa9cf86c1ef80958debfb5a23ace/prod/home/wtx_website/images/BroadcastOnboard02.svg" alt={WtTheme.SiteName} /></p>
                        </Col>
                    </Row>
                    <Row style={{ position: "absolute", top: "350px", left: "180px" }}>
                        <Link to={WtUrl.broadcastCreateUrl}>
                            <p className="text-center w-100"><Button variant="contained" style={{ textTransform: "none", whiteSpace: "nowrap" }} className="btn btn-sm theme_bg text-white rounded-3 w-100 "><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/11dc9b0611b6a53279e5c5b8d4d02d9e855cbb2a/prod/home/wtx_website/images/startBroadcast.svg"} alt={WtTheme.SiteName} />&nbsp; Start New Campaign</Button></p>
                        </Link>
                    </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <Row style={{ height: "600px" }}>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className="float-start pt-5 ps-5 pe-5 mt-2">
                                <h3 className="text-center" style={{ color: WtColor.TextDarkColor }}>Create Campaign in 3 Simple Steps</h3>
                                <h4 className="mt-5 pt-5 text-center" style={{ color: WtColor.TextMidGrey }}>Contact</h4>
                                <p className="text-center" style={{ color: WtColor.TextLightGrey }}>Select the Contacts with whom you would like <br />to share the Campaign</p>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <p className="text-center"><img className="img-fluid float-end pe-5 ps-5" width="550px" src="https://raw.githubusercontent.com/love-building-app/letsserve/7d53594a1cb24278770842bea9163671bffd4be8/prod/home/wtx_website/images/BroadcastOnboard03.svg" alt={WtTheme.SiteName} /></p>
                        </Col>
                    </Row>
                    <Row style={{ position: "absolute", top: "350px", left: "180px" }}>
                        <Link to={WtUrl.broadcastCreateUrl}>
                            <p className="text-center w-100"><Button variant="contained" style={{ textTransform: "none", whiteSpace: "nowrap" }} className="btn btn-sm theme_bg text-white rounded-3 w-100 "><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/11dc9b0611b6a53279e5c5b8d4d02d9e855cbb2a/prod/home/wtx_website/images/startBroadcast.svg"} alt={WtTheme.SiteName} />&nbsp; Start New Campaign</Button></p>
                        </Link>
                    </Row>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}