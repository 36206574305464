/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'
import { useLocation } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Env } from '../../controller/constant'
import './App.css'
import { Buffer } from 'buffer'
import { Col, Container, Row } from 'react-bootstrap'
import { useWindowDimensions } from '../useWindowDimensions'
import { isMobile } from 'react-device-detect'
import { WtTheme } from '../../controller/theme'

export const Payment = () => {
  const { width } = useWindowDimensions()
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('')


  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  let planId = query.get("planId")
  let planName = query.get("planName")
  let amount = query.get("amount")
  let validity = query.get("validity")
  let qty = query.get("qty")
  let billId = query.get("billId")
  let planUrl = query.get("planUrl")
  // let addressID = query.get("addressID")


  let token_gen = String(Number(Date.now()))
  token_gen = Buffer.from(token_gen).toString('base64').slice(0, -2)
  token_gen = token_gen.split('').reverse().join('')

  let busy = false
  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append("authorization", localStorage.getItem("userAuthToken"))
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")
    myHeaders.append("token", token_gen)
    myHeaders.append("bid", localStorage.getItem("bId") ? localStorage.getItem("bId") : '')
    myHeaders.append("devicetype", isMobile ? 'mobile_web' : 'desktop_web')
    myHeaders.append("empemail", localStorage.getItem("userEmail"))


    var urlencoded = new URLSearchParams()
    urlencoded.append("planId", planId)
    urlencoded.append("amount", amount)
    urlencoded.append("validity", validity)
    urlencoded.append("qty", qty)
    urlencoded.append("billId", billId)

    var requestOptions = { method: 'POST', headers: myHeaders, body: urlencoded, redirect: 'follow' }

    if (!busy) {
      busy = true
      fetch(Env.baseApiCallURL + planUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
          setClientSecret(result.data.clientSecret)
          busy = false
        })
        .catch(error => {
          busy = false
        })
    }
  }, [])

  useEffect(() => { setStripePromise(loadStripe(Env.stripePublishableKey)) }, [])
  return (
    <Container style={{ background: "rgb(15, 101, 80)" }} fluid>
      {
        (width <= 780) ?
          <Row>
            <Col style={{ color: "#fff", marginTop: "20px" }} >
              <div className="ms-3" style={{ display: "flex" }}>
                <div className="me-3">
                  <img src={WtTheme.ImageBaseUrl + '/wtb_green_round_images.png'} style={{ width: "60px" }} alt={WtTheme.SiteName} />
                </div>
                <div>
                  <h4>WhatsTool Business</h4>
                  <p>Payment Page</p>
                </div>
              </div>
              <hr className="ms-3 w-25" />
              <div className="ms-3">
                <h6>Plan Name: {planName}</h6>
                <h6>Total Amount: ₹{amount}</h6>
              </div>
            </Col>
            <Col>
              <div>
                {clientSecret && stripePromise && (
                  <div className="paymentPage">
                    <center>
                      <div className="paymentBox" style={{ marginTop: "20px", marginBottom: "210px" }}>
                        <Elements stripe={stripePromise} options={{ clientSecret, }}>
                          <CheckoutForm />
                        </Elements>
                      </div>
                    </center>
                  </div>
                )}

                {
                  !clientSecret || !stripePromise && (
                    <div className='paymentPage'>
                      <center>
                        <div className="paymentBox" style={{ marginTop: "20px", marginBottom: "210px" }}>
                          <p className="text-center text-danger">Payment Failed!!! try again</p>
                        </div>
                      </center>

                    </div>
                  )
                }
              </div>
            </Col>
          </Row>
          :
          <Row>
            <Col style={{ color: "#fff", marginTop: "200px" }} >
              <div className="mt-5 ms-5" style={{ display: "flex" }}>
                <div className="me-3">
                  <img src={WtTheme.ImageBaseUrl + '/wtb_green_round_images.png'} style={{ width: "60px" }} alt={WtTheme.SiteName} />
                </div>
                <div>
                  <h2>WhatsTool Business</h2>
                  <p>Payment Page</p>
                </div>
              </div>
              <hr className="ms-5 w-25" />
              <div className="mt-5 ms-5">
                <h4>Plan Name: {planName}</h4>
                <h4>Total Amount: ₹{amount}</h4>
              </div>
            </Col>
            <Col>
              <div>
                {clientSecret && stripePromise && (
                  <div className="paymentPage">
                    <center>
                      <div className="paymentBox" style={{ marginTop: "200px", marginBottom: "210px" }}>
                        <Elements stripe={stripePromise} options={{ clientSecret, }}>
                          <CheckoutForm />
                        </Elements>
                      </div>
                    </center>
                  </div>
                )}

                {
                  !clientSecret || !stripePromise && (
                    <div className='paymentPage'>
                      <center>
                        <div className="paymentBox" style={{ marginTop: "200px", marginBottom: "210px" }}>
                          <p className="text-center text-danger">Payment Failed!!! try again</p>
                        </div>
                      </center>

                    </div>
                  )
                }
              </div>
            </Col>
          </Row>
      }
    </Container>
  )
}
