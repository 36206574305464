class WtStrings {
    constructor() {
        // Site Information
        this.SiteName = 'WhatsTool Business'
        // End of Site Information


        this.nonLoggedInUserText = 'Non-Logged User'
    }
}
export const WtString = new WtStrings()

