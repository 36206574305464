class WtUrls {
    constructor() {
        // URLs
        this.localUI_URL = 'http://localhost:3000'
        this.localLiveUI_URL = 'https://teamtesting.whatstool.in'
        this.productionUI_URL = 'https://business.whatstool.in'
        this.imageBaseUrl = "https://whatstool.in/wtb_files/images"


        this.loginUrl = '/login'
        this.changePasswordWithEmailUrl = '/change-password-with-email'
        this.forgotPasswordUrl = '/forgot-password'
        this.signupUrl = '/signup'
        this.signupSuccessUrl = '/signup-success'
        this.emailVerificationUrl = '/email-verification'
        this.whatsappVerificationUrl = '/whatsapp-verification'
        this.otpVerificationUrl = '/otp-verification'
        this.dashboardUrl = '/dashboard'

        this.profileUrl = '/dashboard/profile'
        this.profileChangePasswordUrl = '/dashboard/profile/change-password'

        this.paymentPlanSubscriptionUrl = '/dashboard/payment/plan-subscription'
        this.paymentAddOnsUrl = '/dashboard/payment/add-ons'
        this.paymentSummaryUrl = '/dashboard/payment/summary'

        this.paymentUrl = '/dashboard/payment'
        this.paymentCompletionUrl = '/dashboard/completion'
        this.paymentSuccessUrl = '/dashboard/payment-success'
        this.paymentFailedUrl = '/dashboard/payment-failed'
        this.chatUrl = '/dashboard/chat'
        this.ChatbotListUrl = '/dashboard/chatbot-list'
        this.buildChatbotsUrl = '/dashboard/build-chatbots'
        this.contactsUrl = '/dashboard/contacts'
        this.broadcastUrl = '/dashboard/broadcast'
        this.broadcastCreateUrl = '/dashboard/broadcast-create'
        this.analyticsUrl = '/dashboard/analytics'
        this.settingsUrl = '/dashboard/settings'
        this.businessInformationUrl = '/dashboard/settings/business-information'
        this.channelUrl = '/dashboard/settings/channels'
        this.agentsUrl = '/dashboard/settings/agents'
        this.labelsUrl = '/dashboard/settings/labels'
        this.customFieldsUrl = '/dashboard/settings/custom-fields'
        this.quickReplyUrl = '/dashboard/settings/quick-reply'
        this.templateUrl = '/dashboard/settings/templates'
        this.mediaUrl = '/dashboard/settings/media'
        this.apiIntegrationUrl = '/dashboard/settings/api-integration'
        this.appIntegrationUrl = '/dashboard/settings/app-integration'
        this.testingUrl = '/dashboard/testing'

        // Static Pages URls
        this.whatsappBusinessApiUrl = '/whatsApp-business-api'
        this.crmUrl = '/crm'
        this.intergrationUrl = '/integration'
        this.educationalSectorUrl = '/integration/educational-sector-industry'
        this.ecommerceSectorUrl = '/integration/ecommerce-sector-industry'
        this.healthSectorUrl = '/integration/health-sector-industry'
        this.tourTravelSectorUrl = '/integration/tour-travel-sector-industry'
        this.pricingUrl = '/pricing'
        this.wtdPaymentUrl = '/wtd-payment'
        this.quickGuideUrl = '/quick-guide'
        this.chatbotsUrl = '/chatbots'
        this.multiAgentsUrl = '/multi-agents'
        this.broadcastMessageUrl = '/broadcast-message'
        this.clientsUrl = '/clients'

        this.supportUrl = '/support'
        this.termsOfServiceUrl = '/terms-of-service'
        this.privacyPolicyUrl = '/privacy-policy'
        this.cancellationUrl = '/cancellation'
    }
}
export const WtUrl = new WtUrls()

