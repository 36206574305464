/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Tooltip, Zoom } from '@mui/material'
import { Table } from 'react-bootstrap'
import { InfoOutlined, AddOutlined, Delete, ModeEdit } from '@mui/icons-material'
import { deleteAttributeAPI, fetchAllAttributesAPI } from '../../controller/APIs'
import { AddEditAttributeDialog, ConfirmDialog, DemoDialog, WtLoader } from '../../controller/BaseTool'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'


export const CustomFields = () => {
    const notificationBarStatus = useContext(globalContext)
    const [attributeList, setAttributeList] = useState([])

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)

    const [openAddEditAttributeDialog, setOpenAddEditAttributeDialog] = useState({ open: false, onClose: null })
    const openAddEditAttributeDialogBox = (purpose, params) => {
        setOpenAddEditAttributeDialog({
            open: true, purpose: purpose, params: params, onClose: (confirm) => {
                setOpenAddEditAttributeDialog(false)
                if (confirm) {
                    fetchAllAttribute()
                }
            }
        })
    }

    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const openConfirmationForDeletionBox = (id) => {
        setOpenConfirmDialogBox({
            open: true, title: 'Attribute Deletion', message: 'Are your sure to delete this Attribute?', onClose: (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) deleteAttribute(id)
            }
        })
    }

    // List of Attributes 
    const fetchAllAttribute = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) {
                handleWTLoader.handleWTLoader(false)
                setAttributeList(response.data.data)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of List of Attributes 

    // Delete Attributes 
    const deleteAttribute = async (id) => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = { "fieldId": id, "deletedBy": localStorage.getItem("userName") }
            const response = await deleteAttributeAPI(data)
            if (response.status === 204) {
                fetchAllAttribute()
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of Delete Attributes 

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })

    useEffect(() => { fetchAllAttribute() }, [])

    const profile_base_view = { display: 'flex', backgroundColor: '#f8f8f8', height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh', width: '94vw' }
    const profile_menu_item = { flex: '0.85' }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} onClose={openConfirmDialogBox.onClose} />}
                {openAddEditAttributeDialog.open && <AddEditAttributeDialog open={openAddEditAttributeDialog.open} purpose={openAddEditAttributeDialog.purpose} params={openAddEditAttributeDialog.params} onClose={openAddEditAttributeDialog.onClose} />}
                {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Custom Fields &nbsp;
                                <Tooltip title="A custom fields tracks facts about your contacts/conversation - like the subscription plan, or when they ordered the first item etc." placement="bottom-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }}>
                                    <InfoOutlined style={{ color: WtColor.TextLightGrey, marginTop: "-5px", fontSize: '15px' }} />
                                </Tooltip>
                            </h5>
                        </div>
                        <div>
                            <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditAttributeDialogBox("add") : openDemoDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius }}><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Add Custom Fields</button>
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <div className="app_list" style={{ height: "610px" }}>
                            <Table className="fw-bold" responsive>
                                <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: "15px" }}>
                                    <tr><th>Name</th><th>Description</th><th>Type</th><th>Key</th><th>Action</th></tr>
                                </thead>
                                <tbody style={{ background: "#fff", fontSize: "12px" }}>
                                    {
                                        attributeList.map((v, i) => {
                                            return (
                                                <tr className="hand" key={i}>
                                                    <td style={{ color: WtColor.TextMidGrey }}>{v.field_name}</td>
                                                    <td style={{ color: WtColor.TextLightGrey }}>{v.field_description}</td>
                                                    <td style={{ color: WtColor.TextLightGrey }}>{v.field_type}</td>
                                                    <td style={{ color: WtColor.TextLightGrey }}>{v.field_key}</td>
                                                    <td>
                                                        <ModeEdit onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditAttributeDialogBox("edit", v) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} /> &nbsp;&nbsp;&nbsp;
                                                        <Delete onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openConfirmationForDeletionBox(v._id) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}