/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useCallback, useState } from 'react'
import { CallOutlined, ChangeCircleOutlined, Close, ControlPointOutlined, FiberManualRecord, PermIdentityOutlined, SupportAgentOutlined } from '@mui/icons-material'
import { Avatar, Chip, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import chatContext from '../../context/chat/chatContext'
import { AssignedAttributeNewDialog, copyToClipboard, DemoDialog, EditAssignedAttributeNewDialog, ShowDate } from '../../controller/BaseTool'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const UserInfo = (props) => {
    const activeChatInfo = useContext(chatContext)
    const userInfo = useContext(globalContext)

    const agentLs = useContext(globalContext)
    const assignedAgent = useContext(globalContext)
    const agent = useContext(globalContext)

    const lableLs = useContext(globalContext)
    const assignLabel = useContext(globalContext)
    const labels = useContext(globalContext)

    const cusAttr = useContext(globalContext)
    const removeLabels = useContext(globalContext)
    const removeAttribute = useContext(globalContext)

    const { handleChatAndUserInfoScr } = props


    // Agent Work
    const [agentLsMenuAnchor, setAgentLsMenuAnchor] = useState(null)
    const agentLsMenuOpen = Boolean(agentLsMenuAnchor)
    const handleAgentLsMenuClick = (event) => { setAgentLsMenuAnchor(event.currentTarget) }
    const handleAgentLsMenuClose = () => { setAgentLsMenuAnchor(null) }

    const [loaderA, setLoaderA] = useState(false)
    const assAgent = useCallback(async (channel, whatsapp, id, chatId) => {
        handleAgentLsMenuClose()
        setLoaderA(true)
        await assignedAgent.assignedAgent(channel, whatsapp, id, chatId)
        const timer = setTimeout(() => { setLoaderA(false) }, 1000)
        return () => clearTimeout(timer)
    }, [])
    // End of Agent Work


    // Label Work
    const [labelCreateMenuAnchor, setLabelCreateMenuAnchor] = useState(null)
    const labelCreateMenuOpen = Boolean(labelCreateMenuAnchor)
    const handleLabelCreateMenuClick = (event) => { setLabelCreateMenuAnchor(event.currentTarget) }
    const handleLabelCreateMenuClose = () => { setLabelCreateMenuAnchor(null) }

    const [loaderL, setLoaderL] = useState(false)
    const assLabel = useCallback(async (channel, whatsapp, id) => {
        handleLabelCreateMenuClose()
        setLoaderL(true)
        await assignLabel.assignLabel(channel, whatsapp, id)
        const timer = setTimeout(() => { setLoaderL(false) }, 1000)
        return () => clearTimeout(timer)
    }, [])
    const remLabel = useCallback(async (channel, whatsapp, id) => {
        setLoaderL(true)
        await removeLabels.removeLabels(channel, whatsapp, id)
        const timer = setTimeout(() => { setLoaderL(false) }, 1000)
        return () => clearTimeout(timer)
    }, [])
    // End of Label Work


    // Custom Field Work
    const [loaderCA, setLoaderCA] = useState(false)
    const remCusAttr = useCallback(async (channel, whatsapp, id, key) => {
        setLoaderCA(true)
        await removeAttribute.removeAttribute(channel, whatsapp, id, key)
        const timer = setTimeout(() => { setLoaderCA(false) }, 1000)
        return () => clearTimeout(timer)
    }, [])
    const [openAssignAttributeDialog, setOpenAssignAttributeDialog] = useState({ open: false, onClose: null })
    const openAssignAttributeDialogBox = (channel, whatsapp) => {
        setOpenAssignAttributeDialog({
            open: true, channel: channel, endUserWhatsApp: whatsapp, onClose: (confirm) => {
                setOpenAssignAttributeDialog(false)
            }
        })
    }
    const [openEditAssignAttributeDialog, setOpenEditAssignAttributeDialog] = useState({ open: false, onClose: null })
    const openEditAssignAttributeDialogBox = (channel, whatsapp, attr_info) => {
        setOpenEditAssignAttributeDialog({
            open: true, channel: channel, endUserWhatsApp: whatsapp, attr_info: attr_info, onClose: (confirm) => {
                setOpenEditAssignAttributeDialog(false)
            }
        })
    }
    // End of Custom Field Work


    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: (confirm) => setOpenDemoDialog(false) })
    return (
        <div className="list_scroll" style={{ height: '100vh' }}>
            <div className='user_info bg-white'>
                <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />
                <div className='msg_header bg-white ps-3 pe-3 pt-3 pb-4' style={{ flex: "0.05", display: "flex", borderBottom: "1px solid " + WtColor.LineColor }}>
                    <div style={{ flex: "1" }}></div>
                    <div>
                        <IconButton onClick={() => handleChatAndUserInfoScr("0.60", "0.00", "none")}><Close /></IconButton>
                    </div>
                </div>
                <div className='msg_body bg-white'>
                    <div className="ps-3 pe-3 pt-3 pb-0 bg-white" style={{ display: "flex" }}>
                        <div style={{ display: "flex", flex: "1", fontSize: "20px" }}>
                            <Avatar style={{ background: WtColor.LineColor, width: "50px", height: "50px", color: WtColor.BaseColor }}><PermIdentityOutlined /></Avatar>
                            <div className="ms-2">
                                <Tooltip title={userInfo.userInfo.name} placement="bottom"><p style={{ color: WtColor.TextDarkColor, fontWeight: "500", fontSize: "18px" }}>{userInfo.userInfo.name ? (userInfo.userInfo.name?.length > 20 ? userInfo.userInfo.name?.substring(0, 20) + "..." : userInfo.userInfo.name) : userInfo.userInfo.whatsapp}</p></Tooltip>
                                <Tooltip title="Click to Copy" placement='bottom'>
                                    <p onClick={() => copyToClipboard(userInfo.userInfo.whatsapp)} style={{ marginTop: '-20px' }} className="hand"><CallOutlined style={{ color: WtColor.TextDarkColor, fontSize: "16px" }} /> <span style={{ color: WtColor.TextMidGrey, fontSize: "14px" }}> +{userInfo.userInfo.whatsapp}</span></p>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ fontSize: "20px", fontWeight: "500" }}>
                            {/* <p style={{ marginTop: "10px", color: WtColor.BaseColor }}><MoreVertOutlinedIcon /></p> */}
                        </div>
                    </div>
                    <div>
                        <div className="agent_section" style={{ padding: "10px", borderBottom: "1px solid " + WtColor.LineColor }}>
                            <div style={{ display: "flex" }}>
                                <p style={{ flex: 1, fontSize: "18px", color: WtColor.TextMidGrey, fontWeight: "bold" }}>Assigned Agent</p>
                                {
                                    agent.agent ? "" :
                                        <div>
                                            <Tooltip title="Add Agent" placement='left-start'><ControlPointOutlined onClick={handleAgentLsMenuClick} className="hand me-3" style={{ color: WtColor.TextMidGrey }} /></Tooltip>
                                            <Menu
                                                id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }}
                                                anchorEl={agentLsMenuAnchor} open={agentLsMenuOpen} onClose={handleAgentLsMenuClose}
                                                PaperProps={{ style: { maxHeight: 48 * 4.5, width: '30ch' }, }}>
                                                {
                                                    agentLs.agentLs?.length !== 0 ?
                                                        agentLs.agentLs?.map((v, i) => {
                                                            return (
                                                                <MenuItem onClick={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await assAgent(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id, activeChatInfo.activeChatInfo.chatId) : localStorage.getItem('demoMobileNo') === userInfo.userInfo.whatsapp ? await assAgent(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id, activeChatInfo.activeChatInfo.chatId) : openDemoDialogBox()} key={i}>{v.name}</MenuItem>
                                                            )
                                                        }) : <p style={{ color: WtColor.TextLightGrey }} className="text-center">Empty</p>
                                                }
                                            </Menu>
                                        </div>
                                }
                            </div>
                            {
                                loaderA ? <CircularProgress style={{ marginLeft: "50%" }} color="primary" /> :
                                    agent.agent ?
                                        <div className="hand" style={{ display: "flex" }}>
                                            <p className='fw-semibold' style={{ flex: 1, color: WtColor.TextMidGrey, fontSize: "15px" }} onClick={handleAgentLsMenuClick}> <SupportAgentOutlined style={{ color: WtColor.TextMidGrey, marginTop: "-4px", fontSize: "18px" }} /> &nbsp; {agent.agent.name}</p>
                                            <Tooltip title="Change Agent" placement='left-start'><ChangeCircleOutlined onClick={handleAgentLsMenuClick} className="me-3" style={{ color: WtColor.TextMidGrey }} /></Tooltip>
                                            <Menu
                                                id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }}
                                                anchorEl={agentLsMenuAnchor} open={agentLsMenuOpen} onClose={handleAgentLsMenuClose}
                                                PaperProps={{ style: { maxHeight: 48 * 4.5, width: '30ch' }, }}>
                                                {
                                                    agentLs.agentLs?.length !== 0 ?
                                                        agentLs.agentLs?.filter((v) => { return agent.agent._id !== v._id }).map((v, i) => {
                                                            return (
                                                                <MenuItem onClick={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await assAgent(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id, activeChatInfo.activeChatInfo.chatId) : localStorage.getItem('demoMobileNo') === userInfo.userInfo.whatsapp ? await assAgent(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id, activeChatInfo.activeChatInfo.chatId) : openDemoDialogBox()} key={i}>{v.name}</MenuItem>
                                                            )
                                                        }) : <p style={{ color: WtColor.TextLightGrey }} className="text-center">Empty</p>
                                                }
                                            </Menu>
                                        </div>
                                        : ""
                            }
                        </div>
                        <div className="label_section" style={{ padding: "10px", borderBottom: "1px solid " + WtColor.LineColor }}>
                            <div style={{ display: "flex" }}>
                                <p style={{ flex: 1, fontSize: "18px", color: WtColor.TextMidGrey, fontWeight: "bold" }}>Labels</p>
                                <Tooltip title="Add Custom Labels" placement='left-start'><ControlPointOutlined onClick={handleLabelCreateMenuClick} className="hand me-3" style={{ color: WtColor.TextMidGrey }} /></Tooltip>
                                <div>
                                    <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={labelCreateMenuAnchor} open={labelCreateMenuOpen} onClose={handleLabelCreateMenuClose} PaperProps={{ style: { maxHeight: 48 * 4.5, width: '30ch' } }}>
                                        {
                                            lableLs.lableLs?.length !== 0 ?
                                                lableLs.lableLs?.sort((a, b) => {
                                                    return (a.label_name > b.label_name) ? 1 : -1
                                                }).map((v, i) => {
                                                    return (
                                                        <MenuItem onClick={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await assLabel(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id) : localStorage.getItem('demoMobileNo') === userInfo.userInfo.whatsapp ? await assLabel(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id) : openDemoDialogBox()} key={i}>{v.label_name}</MenuItem>
                                                    )
                                                }) : <p style={{ color: WtColor.TextLightGrey }} className="text-center">Empty</p>
                                        }
                                    </Menu>
                                </div>
                            </div>
                            {
                                loaderL ? <CircularProgress style={{ marginLeft: "50%" }} color="primary" /> :
                                    labels.labels && labels.labels?.length !== 0 ?
                                        labels.labels?.map((v, i) => {
                                            return (
                                                <Chip label={<div><FiberManualRecord style={{ color: v.label_color }} /> {v.label_name}</div>} key={i} variant="outlined" className="mb-2 me-2" size="small" onDelete={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await remLabel(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id) : localStorage.getItem('demoMobileNo') === userInfo.userInfo.whatsapp ? await remLabel(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v._id) : openDemoDialogBox()} style={{ border: "1px solid " + WtColor.TextDarkColor, color: WtColor.TextMidGrey }} deleteIcon={<Close style={{ fontWeight: "bold", color: WtColor.TextMidGrey }} />} />
                                            )
                                        }) : ""
                            }
                        </div>
                        <div className="custom_field_section" style={{ padding: "10px" }}>
                            <div style={{ display: "flex" }}>
                                <p style={{ flex: 1, fontSize: "18px", color: WtColor.TextMidGrey, fontWeight: "bold" }}>Custom Fields</p>
                                <Tooltip title="Add Custom Fields" placement='left-start'><ControlPointOutlined onClick={() => openAssignAttributeDialogBox(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp)} className="hand me-3" style={{ color: WtColor.TextMidGrey }} /></Tooltip>
                            </div>
                            {
                                loaderCA ? <CircularProgress style={{ marginLeft: "50%" }} color="primary" /> :
                                    cusAttr.cusAttr && cusAttr.cusAttr?.length !== 0 ?
                                        cusAttr.cusAttr?.map((v, i) => {

                                            let fData = ""
                                            if (v.fieldType === 'date') {
                                                fData = ShowDate(v.fieldData)
                                            } else if (v.fieldType === 'checkbox') {
                                                fData = v.fieldData ? "Yes" : "No"
                                            } else {
                                                fData = v.fieldData?.length > 15 ? v.fieldData?.substring(0, 15) + " ..." : v.fieldData
                                            }
                                            return (
                                                <Chip label={<div onClick={() => openEditAssignAttributeDialogBox(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, { "id": v.fieldId, "attr_data": v.fieldData, "key": v.fieldKey })}><span style={{ fontWeight: "bold", cursor: "pointer" }}>{v.fieldName}</span> : <span>{fData}</span></div>} key={i} variant="filled" className="mb-2 me-2" size="small" onDelete={async () => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? await remCusAttr(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v.fieldId, v.fieldKey) : localStorage.getItem('demoMobileNo') === userInfo.userInfo.whatsapp ? await remCusAttr(userInfo.userInfo.extChannelId, userInfo.userInfo.whatsapp, v.fieldId, v.fieldKey) : openDemoDialogBox()} style={{ border: "1px solid " + WtColor.TextMidGrey, background: "#fff", color: WtColor.TextMidGrey }} deleteIcon={<Close style={{ fontWeight: "bold", color: WtColor.TextMidGrey }} />} />
                                            )
                                        }) : ""
                            }
                            {
                                openAssignAttributeDialog.open === true ? <AssignedAttributeNewDialog open={openAssignAttributeDialog.open} channel={openAssignAttributeDialog.channel} endUserWhatsApp={openAssignAttributeDialog.endUserWhatsApp} onClose={openAssignAttributeDialog.onClose} /> : ""
                            }
                            {
                                openEditAssignAttributeDialog.open === true ? <EditAssignedAttributeNewDialog open={openEditAssignAttributeDialog.open} channel={openEditAssignAttributeDialog.channel} endUserWhatsApp={openEditAssignAttributeDialog.endUserWhatsApp} attr_info={openEditAssignAttributeDialog.attr_info} onClose={openEditAssignAttributeDialog.onClose} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}