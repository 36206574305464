/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState, useContext } from 'react'
import './index.css'
import { styled } from '@mui/material/styles'
import { Container, Row, Col, Tabs, Tab, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { PhotoSizeSelectActualOutlined, PictureAsPdfOutlined, VideocamOutlined, DescriptionOutlined, ContactsOutlined, KeyboardBackspaceOutlined, ExpandLess, ExpandMore, AddBoxOutlined, Delete, Close, InfoOutlined, WhatsApp, TrendingFlatOutlined, PersonOutlineOutlined, ViewList, ViewModule, Sync, TrendingFlat } from '@mui/icons-material'
import { TemplateButtonMessageForMultiPurpose, TemplateMessageWithVariable } from '../view/Chat/MessagesUI'
import { deleteOwnMediaAPI, fetchChannels, sendMessage, sendPrivateMessageAPI, uploadOwnMedia } from './APIs'
import { WtTheme } from './theme'
import ReactAudioPlayer from 'react-audio-player'
import { ChatTextFormater, ConfirmSingleActionForTemplateDialog, ShowSnackbar, WtLoader, analyticsEvent, getFileType, getFileType2 } from './BaseTool'
import { Avatar, Badge, Button, Collapse, Dialog, DialogContent, DialogTitle, Fab, List, ListItemButton, ListItemIcon, ListItemText, Slide, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import globalContext from '../context/global/globalContext'
import { WtColor } from '../constants/colours'
import { WtFile } from '../constants/files'
import { Link, useHistory } from 'react-router-dom'
import { WtUrl } from '../constants/urls'
import { OutsideOfferUI } from '../view/OfferUI/App'
import { WtString } from '../constants/strings'
import { LoginValidate } from './caller'
import { Env } from './constant'
import { WtAnalytic } from '../constants/analyticsKeys'

var FormData = require('form-data')

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

export const TemplateUI = ({ open, purpose, reqDataVal, varName, wholeTemplate, mediaInfo, onCloseHere }) => {
    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)

    const templateLs = useContext(globalContext)
    const getTemplatesUI = useContext(globalContext)
    const templateUIView = useContext(globalContext)
    const setTemplateUIView = useContext(globalContext)

    const setTemplateSync = useContext(globalContext)

    //Search
    const [searchLs, setSearchLs] = useState([])
    useEffect(() => { setSearchLs(templateLs.templateLs) }, [templateLs.templateLs])
    const searchItems = (searchValue) => {
        const filteredData = templateLs.templateLs.filter((item) => { return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase()) })
        if (filteredData.length !== 0) {
            setSearchLs(filteredData)
        } else {
            setSearchLs([])
        }
    }
    // End of Search

    // Channel Work
    const [channelSwitch, setChannelSwitch] = useState(false)
    const handleChannelSwitch = () => { setChannelSwitch(!channelSwitch) }

    const [channelLs, setChannelLs] = useState(undefined)
    const getChannels = async () => {
        try {
            const response = await fetchChannels()
            if (response.status === 200) {
                setChannelLs(response.data.data)
                if (purpose === "Chat") {
                    if (response.data.data.length !== 0) setChInfo(response.data.data[0].title, response.data.data[0].whatsapp)
                    if (!reqDataVal && templateLs.templateLs?.length !== 0) getTemplatesUI.getTemplatesUI(response.data.data[0].whatsapp)
                } else {
                    if (!reqDataVal) {
                        if (response.data.data.length !== 0) setChInfo(response.data.data[0].title, response.data.data[0].whatsapp)
                        if (!reqDataVal && templateLs.templateLs?.length !== 0) getTemplatesUI.getTemplatesUI(response.data.data[0].whatsapp)
                    } else {
                        if (response.data.data.length !== 0) setChInfo(reqDataVal?.channel_name, reqDataVal?.channel_number)
                        if (!reqDataVal && templateLs.templateLs?.length !== 0) getTemplatesUI.getTemplatesUI(reqDataVal?.channel_number)
                    }
                }
            }
        } catch (error) { }
    }

    const [chName, setChName] = useState(undefined)
    const [chWhatsApp, setChWhatsApp] = useState(undefined)
    const setChInfo = (n, w) => {
        handleWTLoader.handleWTLoader(true)
        getTemplatesUI.getTemplatesUI(w)
        setChName(n)
        setChWhatsApp(w)
        setChannelSwitch(false)
    }
    // End of Channel Work

    const handleTemplateSync = async () => await setTemplateSync.setTemplateSync(chWhatsApp)

    // UI Handling
    const [uiOpt, setUiOpt] = useState("template_view") // template_view | template_edit
    const handleUI = (ui) => setUiOpt(ui)

    const handleTemplateUIView = (e, view) => {
        if (view === null) return
        setTemplateUIView.setTemplateUIView(view)
    }
    // End of UI Handling

    // Custom Style
    const ChooseButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold" }
    // End of Custom Style

    const [template, setTemplate] = useState(undefined)
    const handleTemplate = (template) => { setTemplate(template) }

    const [headerType, setHeaderType] = useState("TEXT")
    const [headerText, setHeaderText] = useState("")
    const [mediaAcceptType, setMediaAcceptType] = useState("")
    const [bodyText, setBodyText] = useState("")


    const handleHeaderType = (type) => { setHeaderType(type) }
    const handleHeaderText = (text) => { setHeaderText(text) }
    const handleMediaAcceptType = (type) => { setMediaAcceptType(type) }
    const handleBodyText = (text) => { setBodyText(text) }

    const setTemplateInfoForEdit = (component) => {
        component.map((v) => {
            if (v.type === "HEADER" && v.format === "TEXT") { handleHeaderType("TEXT"); handleHeaderText(v.text) }
            if (v.type === "HEADER" && v.format === "DOCUMENT") { handleHeaderType("DOCUMENT"); handleMediaAcceptType("application/pdf") }
            if (v.type === "HEADER" && v.format === "VIDEO") { handleHeaderType("VIDEO"); handleMediaAcceptType("video/mp4") }
            if (v.type === "HEADER" && v.format === "IMAGE") { handleHeaderType("IMAGE"); handleMediaAcceptType("image/png, image/jpeg, image/jpg") }
            if (v.type === "BODY" && v.text) handleBodyText(v.text)
        })
        handleUI("template_edit")
    }

    const [sendBtnStats, setSendBtnStats] = useState(false)


    const checkTemplateVar = (input) => {
        const count = (input.match(/[^{\}]+(?=}{2})/g) || []).length
        let j = 1
        for (let index = 0; index < count; index++) {
            if (input.includes('{{' + j + '}}')) {
                j++
            }
        }

        return j - 1
    }

    // Template Default Variable Handling
    const [varCountStatus, setVarCountStatus] = useState(false)
    const [tempVar, setTempVar] = useState(undefined)
    const handleVariable = (event, index) => {
        const joinVariables = { ...tempVar }
        joinVariables[index] = event.target.value
        setTempVar(joinVariables)
        setVarCountStatus(false)
        if (Object.values(joinVariables).length >= checkTemplateVar(bodyText) && Object.values(joinVariables).every((e) => e.trim() !== "")) {
            setVarCountStatus(true)
        } else {
            setVarCountStatus(false)
        }
    }
    const defaultVar = (tempVar !== undefined) ? Object.values(tempVar).join("|") : ""
    // End of Template Default Variable Handling



    // Media Handling
    const mediaInputFileChooser = useRef(null)
    const handleMediaInputFileChooser = () => { mediaInputFileChooser.current.click() }

    const [chooseMediaFile, setChooseMediaFile] = useState([])
    const [chooseMedia, setChooseMedia] = useState(undefined)

    const [mediaType, setMediaType] = useState("image")

    const onMediaChoose = (e) => {
        const [file] = e.target.files
        let file_size = e.target.files[0].size
        mediaSetter(e, file, file_size, "click")
    }
    const mediaSetter = (e, file, file_size, val) => {
        if (getFileType(file) !== "other") {
            if (headerType === "IMAGE" && (getFileType(file) === "image")) {
                if (file_size <= WtFile.imgSizeLimit) {
                    setMediaType(getFileType(file))
                    setChooseMedia(URL.createObjectURL(file))
                    setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
                } else {
                    setMediaType(getFileType(file))
                    setChooseMedia(undefined)
                    SnackbarShow("File size limit reached", WtColor.RedColor)
                }
            } else if (headerType === "VIDEO" && (getFileType(file) === "video")) {
                if (file_size <= WtFile.VideoSizeLimit) {
                    setMediaType(getFileType(file))
                    setChooseMedia(URL.createObjectURL(file))
                    setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
                } else {
                    setMediaType(getFileType(file))
                    setChooseMedia(undefined)
                    SnackbarShow("File size limit reached", WtColor.RedColor)
                }
            } else if (headerType === "AUDIO" && (getFileType(file) === "audio")) {
                if (file_size <= WtFile.audioSizeLimit) {
                    setMediaType(getFileType(file))
                    setChooseMedia(URL.createObjectURL(file))
                    setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
                } else {
                    setMediaType(getFileType(file))
                    setChooseMedia(undefined)
                    SnackbarShow("File size limit reached", WtColor.RedColor)
                }
            } else if (headerType === "DOCUMENT" && (getFileType(file) === "document")) {
                if (file_size <= WtFile.pdfSizeLimit) {
                    setMediaType(getFileType(file))
                    setChooseMedia(URL.createObjectURL(file))
                    setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
                } else {
                    setMediaType(getFileType(file))
                    setChooseMedia(undefined)
                    SnackbarShow("File size limit reached", WtColor.RedColor)
                }
            } else {
                setMediaType(getFileType(file))
                setChooseMedia(undefined)
                SnackbarShow("Wrong File Selected!!!", WtColor.RedColor)
            }
        } else {
            setMediaType(getFileType(file))
            setChooseMedia(undefined)
            SnackbarShow("File is not valid", WtColor.RedColor)
        }
    }
    // End of Media Handling


    useEffect(() => {
        if (headerType !== "TEXT" && checkTemplateVar(bodyText) > 0) {
            if (chooseMedia !== undefined && defaultVar !== "" && varCountStatus) { // var + media
                setSendBtnStats(true)
            } else {
                setSendBtnStats(false)
            }
        }
        if (headerType === "TEXT" && checkTemplateVar(bodyText) > 0) {
            if (chooseMedia === undefined && defaultVar !== "" && varCountStatus) { // only var
                setSendBtnStats(true)
            } else {
                setSendBtnStats(false)
            }
        }
        if (headerType !== "TEXT" && checkTemplateVar(bodyText) === 0) {
            if (chooseMedia !== undefined && defaultVar === "") { // only media
                setSendBtnStats(true)
            } else {
                setSendBtnStats(false)
            }
        }
        if (headerType === "TEXT" && checkTemplateVar(bodyText) === 0) {
            if (defaultVar === "" && chooseMedia === undefined) { // nothing
                setSendBtnStats(true)
            } else {
                setSendBtnStats(false)
            }
        }
    }, [headerType, bodyText, chooseMedia, defaultVar])


    // Template Send [purpose === "NewChat" || purpose === "Chat"]
    const handleSendBtn = async () => {
        handleWTLoader.handleWTLoader(true)
        if (!reqDataVal) return
        let w = ""
        if (purpose === "Chat") {
            w = reqDataVal ? reqDataVal : ""
            w = w.substring(1)
        } else {
            w = reqDataVal ? reqDataVal.whatsapp_number : ""
            w = w.substring(1)
        }
        let data = ""
        try {
            if (headerType !== "TEXT" && chooseMedia !== undefined) {
                var mediaData = new FormData()
                mediaData.append('file', chooseMediaFile)
                const mediaResponse = await uploadOwnMedia(mediaData)
                if (mediaResponse.status === 201) {
                    data = {
                        "extUserId": w,
                        "extChannelId": template?.extChannelId,
                        "namespace": template?.namespace,
                        "templateName": template?.name,
                        "language": template?.language,
                        "headerLink": mediaResponse?.data?.data?.link,
                        "headerType": mediaType,
                        "defaultVariables": defaultVar,
                        "template": template
                    }
                    console.log(JSON.stringify(data))
                    const response = await sendMessage(data)
                    if (response.status === 200) { handleWTLoader.handleWTLoader(false); onCloseHere(true) }
                }
            }

            if (headerType === "TEXT") {
                if (defaultVar === "") {
                    data = {
                        "extUserId": w,
                        "extChannelId": template?.extChannelId,
                        "namespace": template?.namespace,
                        "templateName": template?.name,
                        "language": template?.language,
                        "template": template
                    }
                } else {
                    data = {
                        "extUserId": w,
                        "extChannelId": template?.extChannelId,
                        "namespace": template?.namespace,
                        "templateName": template?.name,
                        "language": template?.language,
                        "defaultVariables": defaultVar,
                        "template": template
                    }
                }
                const response = await sendMessage(data)
                if (response.status === 200) { handleWTLoader.handleWTLoader(false); onCloseHere(true) }
            }

        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    // End of Template Send [purpose === "NewChat" || purpose === "Chat"]


    // [Purpose === Campaign]
    const handleSendBtnForCampaignPurpose = async () => {
        handleWTLoader.handleWTLoader(true)
        if (purpose === "Campaign") {
            if (headerType !== "TEXT" && chooseMedia !== undefined) {
                try {
                    SnackbarShow("Please wait... Media is uploading...", WtColor.BaseColor)
                    handleWTLoader.handleWTLoader(true)
                    var mediaData = new FormData()
                    mediaData.append('file', chooseMediaFile)
                    const mediaResponse = await uploadOwnMedia(mediaData)
                    if (mediaResponse.data.status === 201) {
                        handleWTLoader.handleWTLoader(false)
                        mediaInfo(mediaType, mediaResponse?.data?.data?.link)
                        varName(defaultVar)
                        wholeTemplate(template)
                        onCloseHere(true)
                    } else { handleWTLoader.handleWTLoader(false) }
                } catch (error) {
                    handleWTLoader.handleWTLoader(false)
                }
            }
            if (headerType === "TEXT") {
                handleWTLoader.handleWTLoader(false)
                varName(defaultVar)
                wholeTemplate(template)
                onCloseHere(true)
            }
        }
    }
    // End of [Purpose === Campaign]


    const handleSelection = () => {
        if (purpose === "NewChat" || purpose === "Chat") handleSendBtn()
        if (purpose === "Campaign") handleSendBtnForCampaignPurpose()
    }

    const [finalBtnText, setFinalBtnText] = useState(undefined)
    const handleFinalBtnText = () => {
        if (purpose === "NewChat") setFinalBtnText("Send Template")
        if (purpose === "Chat") setFinalBtnText("Send Template")
        if (purpose === "Campaign") setFinalBtnText("Select Template")
    }
    useEffect(() => { handleFinalBtnText() }, [purpose])

    useEffect(() => { getChannels() }, [])

    // Snackbar Code
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const SnackbarShow = (errorMsg, errorMsgBg) => { setErrorMsg(errorMsg); setErrorMsgBg(errorMsgBg); setOpenSnackbar(true) }
    const SnackbarClose = () => { setOpenSnackbar(false) }
    // End of Snackbar Code


    const openTemplateView = () => {
        handleUI("template_view")
        setHeaderType("TEXT")
        setHeaderText("")
        setChooseMedia(undefined)
    }
    const setTemplateComponent = (v) => {
        handleTemplate(v)
        setTemplateInfoForEdit(v.components)
    }

    const chatBubbleNormal = { fontWeight: '400', fontSize: '14px', maxWidth: '300px', minWidth: '150px', overflowWrap: 'break-word', padding: '6px 10px', border: '1px solid ' + WtColor.LineColor, background: '#fff', borderRadius: '6px 6px 0px 6px', minHeight: '220px', maxHeight: 'auto' }
    const chatBubbleGrid = { fontWeight: '400', fontSize: '14px', maxWidth: '300px', minWidth: '150px', overflowWrap: 'break-word', padding: '6px 10px', border: '1px solid ' + WtColor.LineColor, background: '#fff', borderRadius: '6px 6px 0px 6px' }
    return (
        <Dialog fullScreen open={open} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                {(uiOpt === "template_edit") && <KeyboardBackspaceOutlined className='ms-3 me-2 hand' onClick={() => openTemplateView()} style={{ color: WtColor.TextDarkColor, marginTop: "-4px" }} />}
                Select Template&nbsp;
                <Tooltip title="Only Approved Templates are Listed Below" placement='bottom'><InfoOutlined style={{ color: WtColor.TextLightGrey, fontSize: '16px' }} /></Tooltip><Close className="float-end hand" onClick={() => onCloseHere(false)} />
                {
                    uiOpt === "template_edit" &&
                    <button onClick={() => sendBtnStats ? handleSelection() : {}} disabled={sendBtnStats ? false : true} className="btn btn-sm float-end me-4 ps-3 pe-3" style={{ color: '#fff', background: WtColor.BaseColor, borderRadius: '8px' }}>{finalBtnText}</button>
                }
                <Link to={{ pathname: 'https://business.facebook.com/wa/manage/message-templates' }} target="_blank" className="btn btn-sm float-end me-4 ps-3 pe-3 fw-bold" style={{ border: '1px solid ' + WtColor.ProcessingBlue, color: WtColor.ProcessingBlue, background: "#fff", borderRadius: '8px' }}>Create Template</Link>
            </DialogTitle>
            <DialogContent className="pt-2 ps-4 pe-4">
                <Container fluid>
                    {
                        (() => {
                            if (uiOpt === "template_view") {
                                return (
                                    <div>
                                        <div className="mb-2 pb-2" style={{ borderBottom: '1px solid ' + WtColor.LineColor }}>
                                            <Row>
                                                <Col>
                                                    <div style={{ display: 'flex' }}>
                                                        <List sx={{ flex: 1, width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingTop: "0px", paddingBottom: "0px" }} component="nav" aria-labelledby="nested-list-subheader">
                                                            <ListItemButton onClick={handleChannelSwitch} style={{ border: '1px solid ' + WtColor.LineColor, borderRadius: "8px", padding: '2px 10px 2px 10px' }} >
                                                                {
                                                                    (chName !== undefined && chWhatsApp !== undefined) ?
                                                                        <>
                                                                            <ListItemIcon><span className="text-white ps-1 pe-1 rounded-3" style={{ background: WtColor.TextLightGrey }}>{chName.charAt(0)}</span></ListItemIcon>
                                                                            <ListItemText primary={chName} />
                                                                            {channelSwitch ? <ExpandLess /> : <ExpandMore />}
                                                                        </> :
                                                                        <>
                                                                            <ListItemIcon><span className="text-white ps-1 pe-1 rounded-3" style={{ background: WtColor.TextLightGrey }}>W</span></ListItemIcon>
                                                                            <ListItemText primary="Channel" />
                                                                            {channelSwitch ? <ExpandLess /> : <ExpandMore />}
                                                                        </>
                                                                }
                                                            </ListItemButton>
                                                            <Collapse in={channelSwitch} timeout="auto" unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    {
                                                                        channelLs ? channelLs.map((v, i) => {
                                                                            return (
                                                                                <ListItemButton onClick={() => setChInfo(v.title, v.whatsapp)} key={i}>
                                                                                    <ListItemIcon><span className="text-white ps-1 pe-1 rounded-3" style={{ background: WtColor.TextLightGrey }}>{v.title.charAt(0)}</span></ListItemIcon>
                                                                                    <ListItemText primary={v.title} />
                                                                                </ListItemButton>
                                                                            )
                                                                        }) :
                                                                            <ListItemButton>
                                                                                <ListItemIcon><AddBoxOutlined /></ListItemIcon>
                                                                                <ListItemText primary="Add Channel" />
                                                                            </ListItemButton>
                                                                    }
                                                                </List>
                                                            </Collapse>
                                                        </List>
                                                        <div>
                                                            <button className="btn btn-sm" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: '8px', margin: '2px 0px 0px 6px', paddingBottom: '6px', paddingTop: '4px', paddingLeft: '10px', paddingRight: '12px' }} onClick={() => handleTemplateSync()}><Sync style={{ fontSize: '14px' }} /> Sync</button>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="template_ui_toggle">
                                                        <ToggleButtonGroup orientation="horizontal" value={templateUIView.templateUIView} exclusive onChange={handleTemplateUIView}>
                                                            <ToggleButton value="normal" aria-label="normal"><ViewList /></ToggleButton>
                                                            <ToggleButton value="grid" aria-label="grid"><ViewModule /></ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <input onChange={(e) => searchItems(e.target.value)} type="search" className="w-100" style={{ flex: 1, fontSize: "16px", outlineWidth: "0px", borderRadius: "30px", height: "40px", paddingLeft: "20px", paddingRight: "20px" }} placeholder="Search Template" />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className="app_list" style={{ height: '560px' }}>
                                            {
                                                searchLs?.filter((v) => { return v.status === "APPROVED" | v.status === "approved" }).map((v, i) => {
                                                    return (
                                                        <>
                                                            {
                                                                templateUIView.templateUIView === 'normal' &&
                                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} key={i} className="mb-3">
                                                                    <div>
                                                                        <div>
                                                                            <div style={chatBubbleNormal}>
                                                                                <div>
                                                                                    <p className='text-center'>
                                                                                        <button style={ChooseButtonStyle} onClick={() => setTemplateComponent(v)} className="btn btn-sm rounded-3 ps-3 pe-3 pb-1 theme_grey mt-1">Choose <TrendingFlat /></button>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <Tooltip title={v.name} placement='bottom'>
                                                                                        <p className='text-center fw-bold' style={{ color: WtColor.TextMidGrey, fontSize: '13px' }}>{v.name?.substring(0, 25) + '...'}</p>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                <TemplateMessageWithVariable templateComponent={v.components} />
                                                                            </div>
                                                                            <div className="chat_bubble_for_template">
                                                                                {
                                                                                    v.components ? v.components.map((bv, bi) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {
                                                                                                    (() => {
                                                                                                        if (bv.type && bv.type === "BUTTONS" && bv.buttons) { return <TemplateButtonMessageForMultiPurpose buttons={bv.buttons} key={bi} /> }
                                                                                                    })()
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }) : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            }

                                                            {
                                                                templateUIView.templateUIView === 'grid' &&
                                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} key={i} className="mb-3">
                                                                    <div>
                                                                        <div>
                                                                            <div style={chatBubbleGrid}>
                                                                                <div>
                                                                                    <p className='text-center'>
                                                                                        <button style={ChooseButtonStyle} onClick={() => setTemplateComponent(v)} className="btn btn-sm rounded-3 ps-3 pe-3 pb-1 theme_grey mt-1">Choose <TrendingFlat /></button>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <Tooltip title={v.name} placement='bottom'>
                                                                                        <p className='text-center fw-bold' style={{ color: WtColor.TextMidGrey, fontSize: '13px' }}>{v.name?.substring(0, 25) + '...'}</p>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                searchLs?.length === 0 && <div><p className="text-center" style={{ color: WtColor.TextMidGrey }}>Sorry! No such template found...</p></div>
                                            }
                                        </Row>
                                    </div>
                                )
                            }
                            if (uiOpt === "template_edit") {
                                return (
                                    <Row className="mt-5 app_list" style={{ height: '560px' }}>
                                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                            <div className="chatViewU ms-5 me-5">
                                                <div className="chat_bubble">
                                                    <div className="mb-3">
                                                        {
                                                            (() => {
                                                                if (headerType === "TEXT") { return (<p className="fw-bold">{headerText}</p>) }
                                                            })()
                                                        }
                                                    </div>
                                                    <div onClick={handleMediaInputFileChooser} className="mb-3 hand">
                                                        {
                                                            (() => {
                                                                if (headerType === "IMAGE") {
                                                                    return (
                                                                        <div>
                                                                            <p className="text-center rounded-2 mb-2" style={{ color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px" }}>
                                                                                {chooseMedia === undefined ? <PhotoSizeSelectActualOutlined style={{ fontSize: "70px" }} /> : <img style={{ width: "100%", height: "auto" }} className="img-fluid" alt={WtTheme.SiteName} src={chooseMedia} />}
                                                                            </p>
                                                                            <p className="text-center fw-bold" style={{ color: chooseMedia === undefined ? WtColor.ProcessingBlue : WtColor.BaseColor }}>{chooseMedia === undefined ? "Click to Select Image" : "Image Selected"}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                                if (headerType === "VIDEO") {
                                                                    return (
                                                                        <div>
                                                                            <p className="text-center rounded-2 mb-2" style={{ color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px" }}>
                                                                                {chooseMedia === undefined ? <VideocamOutlined style={{ fontSize: "70px" }} /> : <video className="hand p-2" style={{ width: "100%", height: "auto" }} controls><source src={chooseMedia} type="video/mp4" /></video>}
                                                                            </p>
                                                                            <p className="text-center fw-bold" style={{ color: chooseMedia === undefined ? WtColor.ProcessingBlue : WtColor.BaseColor }}>{chooseMedia === undefined ? "Click to Select Video" : "Video Selected"}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                                if (headerType === "DOCUMENT") {
                                                                    return (
                                                                        <div>
                                                                            <p className="text-center rounded-2 mb-2" style={{ color: WtColor.TextDarkColor, fontWeight: "400", marginBottom: "0px", paddingBottom: "0px" }}>
                                                                                {chooseMedia === undefined ? <PictureAsPdfOutlined style={{ fontSize: "70px" }} /> : <PictureAsPdfOutlined style={{ fontSize: "70px", color: WtColor.BaseColor }} />}
                                                                            </p>
                                                                            <p className="text-center fw-bold" style={{ color: chooseMedia === undefined ? WtColor.ProcessingBlue : WtColor.BaseColor }}>{chooseMedia === undefined ? "Click to Document Image" : "Document Selected"}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                        <p className="text-center">
                                                            <input type={"file"} onChange={onMediaChoose} ref={mediaInputFileChooser} id="multiple_media_files" style={{ display: "none" }} accept={mediaAcceptType} multiple />
                                                        </p>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: ChatTextFormater(bodyText) }}></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                            <div className="mt-3 ms-5 me-5">
                                                {
                                                    [...Array(checkTemplateVar(bodyText))].map((v, i) => {
                                                        let vCount = ++i
                                                        return (
                                                            <input text="text" onChange={(e) => handleVariable(e, i)} className="form-control w-100 mb-2" placeholder={"Default Variable {{" + vCount + "}}"} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
                                    </Row>
                                )
                            }
                        })()
                    }
                    <WtLoader open={isLoading.isLoading} />
                    <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} close={SnackbarClose} />
                </Container>
            </DialogContent>
        </Dialog>

    )
}



export const MediaUI = ({ open, reqDataVal, onCloseHere }) => {
    const mediaLs = useContext(globalContext)
    const getMediaLs = useContext(globalContext)
    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    const checkMediaType = (fileName) => { return fileName?.substr(fileName?.lastIndexOf('.') + 1) }

    // Media Handle
    const [mediaSendType, setMediaSendType] = useState("choose")

    const [uiOpt, setUiOpt] = useState("media_view") // media_view | media_edit
    const handleUI = () => {
        if (uiOpt === "media_view") {
            setUiOpt("media_edit")
            setMediaSendType("upload")
        } else {
            setUiOpt("media_view")
            setMediaSendType("choose")
        }
    }


    const mediaInputFileChooser = useRef(null)
    const handleMediaInputFileChooser = () => { mediaInputFileChooser.current.click() }


    const [chooseMediaFile, setChooseMediaFile] = useState([])
    const [chooseMedia, setChooseMedia] = useState(undefined)

    const [mediaType, setMediaType] = useState("image")

    const onDragOverHandler = (e) => e.preventDefault()
    const onDropHandler = (e) => {
        e.preventDefault()
        let file = ""
        if (e.dataTransfer.items) {
            file = [...e.dataTransfer.items].find((item) => item.kind === "file").getAsFile()
        } else {
            file = e.dataTransfer.files[0]
        }
        let file_size = e.dataTransfer.files[0].size

        mediaSetter(e, file, file_size, "drag")
    }
    const onMediaChoose = (e) => {
        const [file] = e.target.files
        let file_size = e.target.files[0].size
        mediaSetter(e, file, file_size, "click")
    }
    const mediaSetter = (e, file, file_size, val) => {
        if (getFileType(file) !== "other") {
            if ((getFileType(file) === "image") && (file_size <= WtFile.imgSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else if ((getFileType(file) === "video") && (file_size <= WtFile.VideoSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else if ((getFileType(file) === "audio") && (file_size <= WtFile.audioSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else if ((getFileType(file) === "document") && (file_size <= WtFile.pdfSizeLimit)) {
                setMediaType(getFileType(file))
                setChooseMedia(URL.createObjectURL(file))
                setChooseMediaFile((val === "click") ? e.target.files[0] : e.dataTransfer.files[0])
            } else {
                setMediaType(getFileType(file))
                SnackbarShow("File size limit reached", WtColor.RedColor)
            }
        } else {
            setMediaType(getFileType(file))
            SnackbarShow("File is not valid", WtColor.RedColor)
        }
    }


    const sendMediaOnChoose = async (type, link) => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = {
                "extUserId": reqDataVal.endUserWhatsApp,
                "extChannelId": reqDataVal.channel,
                "payload": {
                    "recipient_type": "individual",
                    "type": type,
                    [type]: {
                        "link": link
                    }
                }
            }
            const response = (reqDataVal.sendType === "reply_to_end_user") ? await sendMessage(data) : await sendPrivateMessageAPI(data)
            if (response.status === 200 || response.status === 202) {
                SnackbarShow(mediaType + " Sent 👍", WtColor.BaseColor)
                SnackbarClose()
                handleWTLoader.handleWTLoader(false)
                onCloseHere(true)
            }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }


    const sendMediaOnUpload = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            if (mediaSendType === "upload") {
                let mediaData = new FormData()
                mediaData.append('file', chooseMediaFile)
                const mediaResponse = await uploadOwnMedia(mediaData)
                if (mediaResponse.status === 201) {
                    setChooseMediaFile([])
                    setChooseMedia(undefined)

                    let data = {
                        "extUserId": reqDataVal.endUserWhatsApp,
                        "extChannelId": reqDataVal.channel,
                        "payload": {
                            "recipient_type": "individual",
                            "type": mediaType,
                            [mediaType]: {
                                "link": mediaResponse.data.data.link
                            }
                        }
                    }
                    const response = (reqDataVal.sendType === "reply_to_end_user") ? await sendMessage(data) : await sendPrivateMessageAPI(data)
                    if (response.status === 200 || response.status === 202) {
                        SnackbarShow(mediaType + " Sent 👍", WtColor.BaseColor)
                        SnackbarClose()
                        handleWTLoader.handleWTLoader(false)
                        onCloseHere(true)
                    }
                }
            }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    // End of Media Handle


    const deleteMedia = async (id) => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = { '_id': id }
            const response = await deleteOwnMediaAPI(data)
            if (response.status === 204) {
                getMediaLs.getMediaLs()
                handleWTLoader.handleWTLoader(false)
                SnackbarShow("Media Deleted", WtColor.BaseColor)
            } else { SnackbarShow("Media Deletion Failed", WtColor.RedColor); handleWTLoader.handleWTLoader(false) }
        } catch (error) { SnackbarShow("Media Deletion Failed, Error:" + error.response.data.message, WtColor.RedColor); handleWTLoader.handleWTLoader(false) }
    }

    // Snackbar Code
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const SnackbarShow = (errorMsg, errorMsgBg) => { setErrorMsg(errorMsg); setErrorMsgBg(errorMsgBg); setOpenSnackbar(true) }
    const SnackbarClose = () => { setOpenSnackbar(false) }
    // End of Snackbar Code

    useEffect(() => {
        if (mediaLs.mediaLs?.length === 0) {
            handleWTLoader.handleWTLoader(true)
            getMediaLs.getMediaLs()
        }
    }, [])
    return (
        <Dialog fullScreen open={open} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>Select Media<Close className="float-end hand" onClick={() => onCloseHere(false)} /></DialogTitle>
            <DialogContent className="pt-3 pb-5 ps-5 pe-5">
                <Container fluid>
                    <Tabs defaultActiveKey="media_view" onClick={() => handleUI()} className="">
                        <Tab eventKey="media_edit" title="Upload Media">
                            <div className="p-5 m-3" onClick={handleMediaInputFileChooser} onDrop={onDropHandler} onDragOver={onDragOverHandler} style={{ border: "6px dotted", borderColor: WtColor.LineColor, height: "400px" }}>
                                {
                                    (chooseMedia === undefined) ? (
                                        <div className="dragDropView pt-0" >
                                            <label className="dragDropLabel">
                                                <DefaultMediaUIPreview />
                                            </label>
                                        </div>
                                    ) : (
                                        <div className="dragDropView" >
                                            <label className="dragDropLabel">
                                                {
                                                    (() => {
                                                        if (mediaType === "image") {
                                                            return <img src={chooseMedia} alt={WtTheme.SiteName} />
                                                        }
                                                        if (mediaType === "other") {
                                                            return <DefaultMediaUIPreview />
                                                        }
                                                        if (mediaType === "video") {
                                                            return <video className="hand p-2" controls><source src={chooseMedia} type="video/mp4" /></video>
                                                        }
                                                        if (mediaType === "audio") {
                                                            return <ReactAudioPlayer src={chooseMedia} controls className="p-2" />
                                                        }
                                                        if (mediaType === "document") {
                                                            return <p className="text-center"><PictureAsPdfOutlined className="p-2" /></p>
                                                        }
                                                    })()
                                                }
                                            </label>
                                        </div>
                                    )
                                }
                                <input type={"file"} onChange={onMediaChoose} ref={mediaInputFileChooser} style={{ display: "none" }} accept="image/png, image/jpeg, image/jpg, video/mp4, audio/mpeg, application/pdf" className="form-control" multiple />
                            </div>
                            <p className="text-center">
                                {
                                    (chooseMedia === undefined) ? "" :
                                        <Button onClick={() => sendMediaOnUpload()} className="btn btn-sm rounded-0 text-white rounded-3" style={{ background: WtColor.BaseColor, textTransform: "none", boxShadow: "none" }} variant="contained">Send</Button>
                                }
                            </p>
                        </Tab>
                        <Tab eventKey="media_view" title="Choose Media">
                            <Row className="app_list" style={{ height: '540px' }}>
                                {
                                    mediaLs.mediaLs.map((v, i) => {
                                        return (
                                            <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} key={i} className="hand mb-3">
                                                {
                                                    (() => {
                                                        if (checkMediaType(v.link) === "png" || checkMediaType(v.link) === "jpg" || checkMediaType(v.link) === "jpeg" || checkMediaType(v.link) === "gif") {
                                                            return (
                                                                <div className="chat_image_view rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey }}>
                                                                    <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                    <img src={v.link} className="rounded-2" alt={WtTheme.SiteName} onClick={() => sendMediaOnChoose(getFileType2(checkMediaType(v.link)), v.link)} />
                                                                </div>
                                                            )
                                                        }
                                                        if (checkMediaType(v.link) === "mp4") {
                                                            return (
                                                                <div className="chat_video_view rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey }}>
                                                                    <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                    <video className="rounded-2" onClick={() => sendMediaOnChoose(getFileType2(checkMediaType(v.link)), v.link)}><source src={v.link} type="video/mp4" /></video>
                                                                </div>
                                                            )
                                                        }
                                                        if (checkMediaType(v.link) === "pdf") {
                                                            return (
                                                                <div className="chat_image_view rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey }}>
                                                                    <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                    <p className="text-center mt-5"><PictureAsPdfOutlined style={{ fontSize: "70px" }} onClick={() => sendMediaOnChoose(getFileType2(checkMediaType(v.link)), v.link)} /></p>
                                                                </div>
                                                            )
                                                        }
                                                        if (checkMediaType(v.link) === "xls") {
                                                            return (
                                                                <div className="chat_image_view rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey }}>
                                                                    <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                    <p className="text-center mt-5"><DescriptionOutlined style={{ fontSize: "70px" }} onClick={() => sendMediaOnChoose(getFileType2(checkMediaType(v.link)), v.link)} /></p>
                                                                </div>
                                                            )
                                                        }
                                                        if (checkMediaType(v.link) === "csv") {
                                                            return (
                                                                <div className="chat_image_view rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey }}>
                                                                    <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                    <p className="text-center mt-5"><ContactsOutlined style={{ fontSize: "70px" }} onClick={() => sendMediaOnChoose(getFileType2(checkMediaType(v.link)), v.link)} /></p>
                                                                </div>
                                                            )
                                                        }
                                                        if (checkMediaType(v.link) === "mp3") {
                                                            return (
                                                                <div className="chat_image_view rounded-2" style={{ border: "1px solid " + WtColor.CloudyGrey }}>
                                                                    <Delete className="float-end" style={{ color: WtColor.TextLightGrey }} onClick={() => deleteMedia(v._id)} />
                                                                    <p className="text-center mt-5"><ReactAudioPlayer src={v.link} controls style={{ width: "260px" }} onClick={() => sendMediaOnChoose(getFileType2(checkMediaType(v.link)), v.link)} /></p>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Tab>
                    </Tabs>
                    <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} close={SnackbarClose} />
                    {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                </Container>
            </DialogContent>
        </Dialog>
    )
}

export const DefaultMediaUIPreview = () => {
    return (
        <div className="">
            <p className="text-center">
                <img style={{ width: "250px", height: "100%" }} src={WtTheme.ImageBaseUrl + "/upload_media.svg"} alt={WtTheme.SiteName} />
            </p>
            <p className="text-center fw-bold"><span style={{ color: WtColor.TextDarkColor }}>Drag &amp; Drop your Media or</span> <span style={{ color: WtColor.BaseColor }}>Browse</span></p>
            <p className="text-center" style={{ color: WtColor.TextLightGrey, marginTop: "-10px" }}>Supports : PNG, JPEG, JPG, MP4, MP3, PDF, etc</p>
        </div>
    )
}



export const UserNotificationTopBarUI = () => {
    const [infoBarStatus, setInfoBarStatus] = useState(localStorage.getItem('isDemo') === 'true' ? true : false)
    const handleInfoBarStatus = (val) => setInfoBarStatus(val)
    return (
        <>
            {
                infoBarStatus &&
                <Container style={{ background: WtColor.BrownColor, paddingTop: "14px", paddingBottom: "1px" }} fluid>
                    <p className="text-center text-white" style={{ paddingBottom: '0px' }}>You are in Demo Dashboard <Close onClick={() => handleInfoBarStatus(false)} className="float-end hand" /></p>
                </Container>
            }
        </>
    )
}


export const TopBarNotification = ({ msgText }) => {
    const notificationBarStatus = useContext(globalContext)
    const handleNotificationBarStatus = useContext(globalContext)
    return (
        <>
            {
                LoginValidate() && window.location.href.startsWith(Env.baseUI_URL + WtUrl.dashboardUrl) && notificationBarStatus.notificationBarStatus && localStorage.getItem('isDemo') === 'false' &&
                <Container className="notificationTopBar" style={{ background: WtColor.RedColor, paddingTop: "14px", paddingBottom: "1px" }} fluid>
                    <p className="text-center text-white" style={{ paddingBottom: '0px' }}>{msgText} <Close onClick={() => handleNotificationBarStatus.handleNotificationBarStatus(false)} className="float-end hand" /></p>
                </Container>
            }
        </>
    )
}


export const WhatsAppSideFAB = () => {
    return (
        <>
            <Link to={{ pathname: "https://wa.me/917353902312?text=I'm%20interested%20in%20WhatsApp%20Business%20API!!!" }} target="_blank">
                <Fab size="medium" color="success" style={{ position: "fixed", bottom: "20px", right: "20px" }}>
                    <WhatsApp style={{ margin: "20px", fontSize: "33px" }} />
                </Fab>
            </Link>
        </>
    )
}



// Header UI
export const Header = () => {
    const offerImg = useContext(globalContext)
    const setOfferImg = useContext(globalContext)
    useEffect(() => { setTimeout(() => { setOfferImg.setOfferImg(true) }, 2000) }, [])
    return (
        <>
            {offerImg.offerImg && <div><OutsideOfferUI /></div>}
            <Container>
                <Navbar bg="white" className="headerMenu" expand="lg">
                    <Container>
                        <Navbar.Brand ><Link to="/" style={{ display: "flex" }}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" height="40px" alt={WtTheme.SiteName} />&nbsp; <img src={WtTheme.ImageBaseUrl + "/logo_text_dark.png"} width="auto" height="35px" className="mt-1" alt={WtTheme.SiteName} /></Link></Navbar.Brand>
                        <Navbar.Toggle aria-controls="header" />
                        <Navbar.Collapse id="header">
                            <Nav className="ms-auto">
                                <NavDropdown title="Industry" className="me-5 fw-bold" style={{ color: WtColor.TextDarkColor }} id="industry-dropdown">
                                    <NavDropdown.Item className="mt-2 mb-2"><Link to={WtUrl.educationalSectorUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold ps-3 pe-3">Educational Sector</Link></NavDropdown.Item>
                                    <NavDropdown.Item className="mt-2 mb-2"><Link to={WtUrl.ecommerceSectorUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold ps-3 pe-3">E-Commerce Sector</Link></NavDropdown.Item>
                                    <NavDropdown.Item className="mt-2 mb-2"><Link to={WtUrl.healthSectorUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold ps-3 pe-3">Health Sector</Link></NavDropdown.Item>
                                    <NavDropdown.Item className="mt-2 mb-2"><Link to={WtUrl.tourTravelSectorUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold ps-3 pe-3">Tour &amp; Travel Sector</Link></NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Item className="mt-2 me-5"><Link to={WtUrl.pricingUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Pricing</Link></Nav.Item>
                                <Nav.Item className="mt-2 me-5"><Link to={WtUrl.quickGuideUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Quick Guide</Link></Nav.Item>
                                <Nav.Item className="mt-2 me-3 onSmallBtn "><Link to={WtUrl.signupUrl} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 text-white theme_bg fw-bold" style={{ border: "2px solid " + WtColor.BaseColor }}>Get Started</Link></Nav.Item>
                                <Nav.Item className="mt-2 me-3 onSmallBtn "><Link to={WtUrl.loginUrl} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 loginBtn fw-bold" style={{ color: WtColor.BaseColor, border: "2px solid " + WtColor.BaseColor }}>Login <TrendingFlatOutlined className="loginArrow" /></Link></Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </>
    )
}
// End of Header UI


// Footer UI
export const Footer = () => {
    return (
        <Container className="footer" fluid>
            <Container className="in p-5">
                <Row>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4 theme">WhatsTool Business</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><Link to={WtUrl.chatbotsUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">ChatBot</Link></p>
                            <p><Link to={WtUrl.multiAgentsUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Multi Agent Support</Link></p>
                            <p><Link to={WtUrl.signupUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">CRM</Link></p>
                            <p><Link to={WtUrl.broadcastMessageUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Broadcast Message</Link></p>
                            <p><Link to={WtUrl.signupUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Integration</Link></p>
                            <p><Link to={WtUrl.pricingUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Pricing</Link></p>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4 theme">About</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><Link to={{ pathname: WtTheme.teamUrl }} target="_blank" style={{ color: WtColor.TextDarkColor }} className="fw-bold">Team</Link></p>
                            <p><Link to={{ pathname: WtTheme.blogUrl }} target="_blank" style={{ color: WtColor.TextDarkColor }} className="fw-bold">Blog</Link></p>
                            <p><Link to={WtUrl.quickGuideUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Quick Guide</Link></p>
                            <p><Link to={WtUrl.clientsUrl} style={{ color: WtColor.TextDarkColor }} className="fw-bold">Clients</Link></p>
                            {/* <p><Link to={{ pathname: WtTheme.faqUrl }} target="_blank" style={{ color: WtColor.TextDarkColor }} className="fw-bold">FAQs</Link></p> */}
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4 theme">Support</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLScKQj5z44Nf8XvAtdn7tFPBN-HzKBR9-HqmDVoWOtLgUY_Zng/viewform?usp=sf_link" }} target="_blank" style={{ color: WtColor.TextDarkColor }} className="fw-bold">Request a Call</Link></p>
                            <p><Link to={{ pathname: "mailto:support@whatstool.in" }} target="_blank" style={{ color: WtColor.TextDarkColor }} className="fw-bold">Email Us</Link></p>
                            <p><Link to={{ pathname: "https://wa.me/917353902312" }} target="_blank" style={{ color: WtColor.TextDarkColor }} className="fw-bold">WhatsApp Us</Link></p>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4 theme">Download</h5>
                        <div>
                            <Link to={{ pathname: WtTheme.whatstoolBusinessAppUrl }} target="_blank"><img src={WtTheme.ImageBaseUrl + "/download_app.png"} className="mb-2 dImg shadow-lg" width="165px" alt={WtTheme.SiteName} /></Link>
                        </div>
                        <div className="mt-3">
                            <img src={WtTheme.ImageBaseUrl + "/performer_icon.png"} alt={WtTheme.SiteName} style={{ width: "165px" }} className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr style={{ backgroundColor: "#9FF2A7", height: "2px" }} />
            <Container className="pt-2 pb-2 pe-5 ps-5 in social">
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}><p className="float-start fw-bold" style={{ fontSize: "14px", color: WtColor.TextDarkColor }}>© WhatsTool 2022</p></Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="float-end">
                            <Link to={{ pathname: "https://www.facebook.com/whatstooltech" }} target="_blank"><i className="fab fa-facebook-f fb" style={{ paddingLeft: "12px", paddingRight: "12px" }}></i></Link>
                            <Link to={{ pathname: "https://www.instagram.com/whatstool/" }} target="_blank"><i className="fab fa-instagram"></i></Link>
                            <Link to={{ pathname: "https://twitter.com/ToolsWhats" }} target="_blank"><i className="fab fa-twitter"></i></Link>
                            <Link to={{ pathname: "https://www.linkedin.com/company/whatstool" }} target="_blank"><i className="fab fa-linkedin-in"></i></Link>
                            <Link to={{ pathname: "https://www.youtube.com/c/WhatsToolTech" }} target="_blank"><i className="fab fa-youtube"></i></Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
// End of Footer UI



// Left Navigation
export const LeftNavigation = () => {
    // Icons Links
    const site_logo_icon = WtUrl.imageBaseUrl + "/wtb_green_round_images.png"
    const chat_icon = WtUrl.imageBaseUrl + "/conversation.svg"
    const chat_active_icon = WtUrl.imageBaseUrl + "/chatActiveIcon.svg"
    const contacts_icon = WtUrl.imageBaseUrl + "/contacts.svg"
    const contacts_active_icon = WtUrl.imageBaseUrl + "/contactsActiveIcon.svg"
    const campaign_icon = WtUrl.imageBaseUrl + "/broadcast.svg"
    const campaign_active_icon = WtUrl.imageBaseUrl + "/campaignActiveIcon.svg"
    const chatbot_icon = WtUrl.imageBaseUrl + "/chatbot.svg"
    const chatbot_active_icon = WtUrl.imageBaseUrl + "/chatbotActiveIcon.svg"
    const settings_icon = WtUrl.imageBaseUrl + "/settings.svg"
    const settings_active_icon = WtUrl.imageBaseUrl + "/settingsActiveIcon.svg"
    const upgrade_icon = WtUrl.imageBaseUrl + "/upgradeIcon.svg"
    const upgrade_active_icon = WtUrl.imageBaseUrl + "/upgradeActiveIcon.svg"
    // End of Icons Links

    const leftNavgationIndexData = [
        { title: 'Chat', url: WtUrl.chatUrl, keyEvent: WtAnalytic.chatBtnClicked, img: chat_icon, imgActive: chat_active_icon },
        { title: 'Contacts', url: WtUrl.contactsUrl, keyEvent: WtAnalytic.conctactsBtnClicked, img: contacts_icon, imgActive: contacts_active_icon },
        { title: 'Campaign', url: WtUrl.broadcastUrl, keyEvent: WtAnalytic.broadcastBtnClicked, img: campaign_icon, imgActive: campaign_active_icon },
        { title: 'Chatbots', url: WtUrl.ChatbotListUrl, keyEvent: WtAnalytic.chatbotsBtnClicked, img: chatbot_icon, imgActive: chatbot_active_icon },
        { title: 'Settings', url: WtUrl.settingsUrl, keyEvent: WtAnalytic.settingsBtnClicked, img: settings_icon, imgActive: settings_active_icon }
    ]

    // Dialogs
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
    const openConfirmDialogBox = (url) => {
        setOpenConfirmDialog({
            open: true, title: 'Your changes are not saved', msg: 'Work in progress, Please save it before leaving this page.',
            onClose: async (confirm) => {
                if (!confirm) {
                    localStorage.setItem('userActivity', 'false')
                    history.push(url)
                }
                setOpenConfirmDialog(false)
            }
        })
    }
    // End of Dialogs

    const [sidebarIndex, setSidebarIndex] = useState()
    const history = useHistory()
    const openURL = (url, keyName) => {
        if (localStorage.getItem('userActivity') === 'false') {
            analyticsEvent(localStorage.getItem("userName"), keyName, window.location.search)
            history.push(url)
        }
        if (localStorage.getItem('userActivity') === 'true') openConfirmDialogBox(url)
    }

    useEffect(() => {
        if (window.location.href.startsWith(WtUrl.localUI_URL + WtUrl.chatUrl) || window.location.href.startsWith(WtUrl.localLiveUI_URL + WtUrl.chatUrl) || window.location.href.startsWith(WtUrl.productionUI_URL + WtUrl.chatUrl)) setSidebarIndex(0)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.contactsUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.contactsUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.contactsUrl) setSidebarIndex(1)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.broadcastUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.broadcastUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.broadcastUrl) setSidebarIndex(2)
        if (window.location.href === WtUrl.localUI_URL + WtUrl.ChatbotListUrl || window.location.href === WtUrl.localLiveUI_URL + WtUrl.ChatbotListUrl || window.location.href === WtUrl.productionUI_URL + WtUrl.ChatbotListUrl) setSidebarIndex(3)
        if (window.location.href.startsWith(WtUrl.localUI_URL + WtUrl.settingsUrl) || window.location.href.startsWith(WtUrl.localLiveUI_URL + WtUrl.settingsUrl) || window.location.href.startsWith(WtUrl.productionUI_URL + WtUrl.settingsUrl)) setSidebarIndex(4)
    }, [])


    const GreenBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: WtColor.BaseColor,
            color: WtColor.BaseColor,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }))

    // Style Work
    let s1 = { display: 'flex', background: '#fff', paddingTop: '6px', paddingLeft: '4px', paddingRight: '4px', position: 'fixed', zIndex: 1, top: 0, left: 0, flexDirection: 'column', height: '100vh', borderRight: '1px solid' + WtColor.LineColor }
    // End of Style Work
    return (
        <div style={s1}>
            <div style={{ flex: 1 }} className="left-navigation">
                <div>
                    <p className="text-center hand" onClick={() => openURL(WtUrl.dashboardUrl, WtAnalytic.dashboardBtnClicked)}>
                        <img src={site_logo_icon} width='35px' height='35px' alt={WtString.SiteName} />
                    </p>
                </div>
                <div>
                    {
                        leftNavgationIndexData?.map((v, i) => {
                            return (
                                <div className="hand mt-3" onClick={() => openURL(v.url, v.keyEvent)} key={i}>
                                    <p className="text-center">
                                        <img src={i === sidebarIndex ? v.imgActive : v.img} width='35px' height='35px' style={{ borderRadius: "8px", background: i === sidebarIndex ? WtColor.NeonGreenColor : '#fff' }} alt={WtString.SiteName} />
                                    </p>
                                    <p className="text-center" style={{ fontSize: '11px', marginTop: i === sidebarIndex ? '-14px' : '-18px', color: i === sidebarIndex ? WtColor.BaseColor : WtColor.TextLightGrey }}>{v.title}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div>
                    <p className="text-center hand" onClick={() => openURL(WtUrl.paymentPlanSubscriptionUrl, WtAnalytic.upgradePlanBtnClicked)}>
                        <Tooltip title={localStorage.getItem('activePlan') === 'Free' ? 'Upgrade Plan' : localStorage.getItem('activePlan') + ' Plan Active'} placement='right'>
                            <img src={localStorage.getItem('activePlan') === 'Free' ? upgrade_icon : upgrade_active_icon} width='25px' height='25px' alt={WtString.SiteName} />
                        </Tooltip>
                    </p>
                    <p className="text-center" style={{ fontSize: '11px', marginTop: '-14px', color: WtColor.TextLightGrey }}>{localStorage.getItem('activePlan') === 'Free' && <>Upgrade<br /></>}Plan</p>
                </div>
                <div>
                    <p className="text-center hand">
                        <Tooltip title={localStorage.getItem('userName') + "'s Profile"} placement='right'>
                            <GreenBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot"><Avatar onClick={() => openURL(WtUrl.profileUrl, WtAnalytic.profileBtnClicked)} className="theme_bg" style={{ width: "35px", height: "35px", border: "1px solid #fff" }} ><PersonOutlineOutlined /></Avatar></GreenBadge>
                        </Tooltip>
                    </p>
                    <p className="text-center" style={{ fontSize: '11px', marginTop: '-14px', color: WtColor.TextLightGrey }}>Profile</p>
                </div>
            </div>


            {openConfirmDialog.open && <ConfirmSingleActionForTemplateDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />}
        </div >

    )
}

// End of Left Navigation 