import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Container, Row, Col, Carousel, Tab, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { WtTheme } from '../../controller/theme'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'
import { WtString } from '../../constants/strings'


export const Support = () => {
    return (
        <Container className="mb-5 pb-5">
            <h4 className="text-center mt-5 mb-5" style={{ color: WtColor.TextMidGrey }}>Customer Support</h4>
            <div>
                <h6><span style={{ color: WtColor.TextDarkColor }}>Official WhatsApp No.:</span> <span style={{ color: WtColor.TextMidGrey }}>+91 98443 44170</span></h6>
                <h6><span style={{ color: WtColor.TextDarkColor }}>Email:</span> <span style={{ color: WtColor.TextMidGrey }}>support@whatstool.in</span></h6>
            </div>
        </Container>
    )
}
export const TermsOfService = () => {
    return (
        <Container className="mb-5 pb-5">
            <h4 className="text-center mt-5 mb-5" style={{ color: WtColor.TextMidGrey }}>Terms of Service</h4>
            <div>
                <h1>Website Terms and Conditions of Use</h1>

                <h2>1. Terms</h2>

                <p>By accessing this Website, accessible from https://business.whatstool.in/, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>

                <h2>2. Use License</h2>

                <p>Permission is granted to temporarily download one copy of the materials on WhatsTool Tech's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

                <ul>
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial purpose or for any public display;</li>
                    <li>attempt to reverse engineer any software contained on WhatsTool Tech's Website;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                </ul>

                <p>This will let WhatsTool Tech to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

                <h2>3. Disclaimer</h2>

                <p>All the materials on WhatsTool Tech’s Website are provided "as is". WhatsTool Tech makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, WhatsTool Tech does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

                <h2>4. Limitations</h2>

                <p>WhatsTool Tech or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on WhatsTool Tech’s Website, even if WhatsTool Tech or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

                <h2>5. Revisions and Errata</h2>

                <p>The materials appearing on WhatsTool Tech’s Website may include technical, typographical, or photographic errors. WhatsTool Tech will not promise that any of the materials in this Website are accurate, complete, or current. WhatsTool Tech may change the materials contained on its Website at any time without notice. WhatsTool Tech does not make any commitment to update the materials.</p>

                <h2>6. Links</h2>

                <p>WhatsTool Tech has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by WhatsTool Tech of the site. The use of any linked website is at the user’s own risk.</p>

                <h2>7. Site Terms of Use Modifications</h2>

                <p>WhatsTool Tech may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

                <h2>8. Your Privacy</h2>

                <p>Please read our Privacy Policy.</p>

                <h2>9. Governing Law</h2>

                <p>Any claim related to WhatsTool Tech's Website shall be governed by the laws of in without regards to its conflict of law provisions.</p>
            </div>
        </Container>
    )
}
export const PrivacyPolicy = () => {
    return (
        <Container className="mb-5 pb-5">
            <h4 className="text-center mt-5 mb-5" style={{ color: WtColor.TextMidGrey }}>Privacy Policy</h4>
            <div>
                <h4>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h4>
                When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.<br />
                When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.<br />
                Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.<br />
                <br /><br />
                <h4>SECTION 2 - CONSENT</h4>
                How do you get my consent?<br />
                When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.<br />
                If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.<br />
                <br /><br />
                <h4>How do I withdraw my consent?</h4>
                If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at support@whatstool.in or mailing us at: C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India.<br />
                <br /><br />
                <h4>SECTION 3 - DISCLOSURE</h4>
                We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.<br />
                <br /><br />
                <h4>SECTION 4 - PAYMENT</h4>
                We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.<br />
                Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.<br />
                PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.<br />
                For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com<br />
                <br /><br />
                <h4>SECTION 5 - THIRD-PARTY SERVICES</h4>
                In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.<br />
                However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.<br />
                For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.<br />
                In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.<br />
                Once you leave our store's website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website's Terms of Service. <br />
                <br /><br />
                <h5>Links</h5>
                When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.<br />
                <br /><br />
                <h4>SECTION 6 - SECURITY</h4>
                To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.<br />
                <br /><br />
                <h4>SECTION 7 - COOKIES</h4>
                We use cookies to maintain session of your user. It is not used to personally identify you on other websites.<br />
                <br /><br />
                <h4>SECTION 8 - AGE OF CONSENT</h4>
                By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.<br />
                <br /><br />
                <h4>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h4>
                We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.<br />
                If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.<br />
                <br /><br />
                <h4>QUESTIONS AND CONTACT INFORMATION</h4>
                If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at whatstoolsapp@gmail.com or by mail at C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India.<br />
                <br /><br />
                [Re: Privacy Compliance Officer]<br />
                [C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India. 560017]<br />
                <br /><br />
            </div>
        </Container>
    )
}
export const Cancellation = () => {
    return (
        <Container className="mb-5 pb-5">
            <h4 className="text-center mt-5 mb-5" style={{ color: WtColor.TextMidGrey }}>Cancellation &amp; Refund Policy</h4>
            <div>
                <strong>Full Refund</strong><br />
                ✺ Full refund if facing issues with automatic forwarding and reported within 3 days of purchase.<br /><br />
                <strong>80% refund </strong><br />
                ✺ 80% refund of the total amount if having minor issues with bulk message forwarding &amp; reported after 3 days of purchase.<br /><br />
                <strong>No refund</strong><br />
                ✺ No refund request will be accepted if reported after 7-10 days(or longer) of purchase. +<br />
                ✺ No refund request will be accepted if having no issues with bulk message forwarding. +<br />
                ✺ No other reasons would be accepted than these for refund request.
            </div>
        </Container>
    )
}

export const FAQ = () => {
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const FAQ_Data_01 = [
        { "t": "What are the USPs of the WhatsApp Business account?", "a": "WhatsApp API is a software interface by WhatsApp that can allow organizations receive and deliver messages to multiple users at scale. You may have heard the word API multiple times without fully understanding its meaning. For your convenience, we'll break everything down for you.<br />The Application Programming Interface (API) is software that allows two programs to communicate with one another. You're utilizing an API whenever you use an app to send a message, perform a search, or upload a photo to the internet. The Business API for WhatsApp is similar. It can assist your company in communicating with other users via a WhatsApp API account for the company. While this may appear hard at first, it's relatively simple once you realize how it might benefit your company.<br />As compared to Whatso API, here’s what WhatsApp API brings to the table; 100,000 messages per day Green tick for verification Uses official API" },
        { "t": "How can I get the WhatsApp Business Verified green tick?", "a": "WhatsApp API Verification is not the same as WhatsApp Green Tick Verification. We'll walk you through the Whatsapp business verification procedure and how to get verified on WhatsApp quickly in this video. Although a company may be approved to use WhatsApp APIs, it may not receive a Green Tick.<br />After you've been approved for WhatsApp Business APIs, you can apply for Green Tick. Only a small percentage of firms match WhatsApp's official business account requirements. WhatsApp normally only gives Green Tick or an Official Business account to a small number of well-known companies.<br />The steps to get a Green Tick on WhatsApp are as follows: You must first request API access from a WhatsApp Business Solution Provider (BSP), such as Whatsoever. You can contact your BSP to apply for Green Tick once your WhatsApp Business API access has been approved.<br />However, if your company is not well-known, your Green Tick application may be denied. Only roughly ten percent of enterprises, in our experience, are qualified for WhatsApp Green Tick. WhatsApp retains the authority to approve your Green Tick, and the BSP has no role to play in this." },
        { "t": "Can I use my existing WhatsApp number with the Official WhatsApp Business account?", "a": "Yes, you can. Your company must have a working phone number that matches the following requirements:<ul><li>Landline and cell phone numbers have a country and area code.</li><li>You are the owner of the property.</li><li>Able to receive voice or text messages.</li><li>This is not a shortcode.</li><li>The WhatsApp Business API had never been utilized before.</li></ul>" },
        { "t": "Can I send any kind of message through WhatsApp Business account?", "a": "Yes, you can send PDFs, images, text messages, documents, contacts, and any kind of messages through WhatsApp." },
        { "t": "What are template messages?", "a": "A WhatsApp template, also known as a Highly Structured Message (HSM), is a pre-designed message structure that businesses can use to deliver notifications to clients on a regular basis. Delivery updates, appointment reminders, and automatic replies are just a few kinds of messages." }
    ]
    const FAQ_Data_02 = [
        { "t": "Can I only use the pre-approved templates to send messages to my customers via WhatsApp API?", "a": "Yes, there are only certain templates you can use as per WhatsApp's official guidelines. Your templates need to be approved by WhatsApp before you can begin using them. At Whatso, you can share your templates and we can get them approved for you." },
        { "t": "Can I send Broadcasts to people who haven't saved my number?", "a": "Yes, you can send broadcasts to anyone who has WhatsApp. All you need is their contact details, which are usually fetched through your website itself." },
        { "t": "What kind of the Analytics functionality is available?", "a": "In the Analytics Tab you'll get all the data about every campaign you run, number of messages sent and read, the number of clicks, orders, and sales generated, as well as the cost spent and the ROI." },
        { "t": "How do I send Broadcast messages?", "a": "Once you've onboarded with WhatsTool Business' WhatsApp Marketing, you can simply go to the 'Broadcast' section and click on the 'New Broadcast' button. You'll be redirected to a page where you can select your target audience and then a diverse list of templates will be curated for you. You can select any of these templates and hit send." },
        { "t": "What is WhatsApp Marketing? Why do I need it?", "a": "WhatsTool Business is a WhatsApp Marketing platform that enables you to engage with your customers & target them on WhatsApp to increase sales by driving more traffic to your stores & increasing retention" }
    ]
    return (
        <Container className="mt-5 mb-5 pb-5">
            <h2 className="theme_lite text-center mb-5">Frequently <span className="theme fw-bold">Asked Questions</span></h2>
            <div>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        {
                            FAQ_Data_01.map((v, i) => {
                                return (

                                    <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-l-' + i} onChange={handleChange('panel-l-' + i)} key={i}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon className="bRound" style={{ color: WtColor.BaseColor, background: WtColor.LineColor, }} />}>
                                            <Typography className="fw-bold" sx={{ fontSize: "18px", color: WtColor.TextDarkColor, width: '90%', flexShrink: 0 }}>{v.t}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography dangerouslySetInnerHTML={{ __html: v.a }} style={{ fontSize: "14px", color: WtColor.TextMidGrey }}></Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                )
                            })
                        }
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        {
                            FAQ_Data_02.map((v, i) => {
                                return (
                                    <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-r-' + i} onChange={handleChange('panel-r-' + i)} key={i}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon className="bRound" style={{ color: WtColor.BaseColor, background: WtColor.LineColor, }} />}>
                                            <Typography className="fw-bold" sx={{ fontSize: "18px", color: WtColor.TextDarkColor, width: '90%', flexShrink: 0 }}>{v.t}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography dangerouslySetInnerHTML={{ __html: v.a }} style={{ fontSize: "14px", color: WtColor.TextMidGrey }}></Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </Col>
                </Row>
            </div>
        </Container >
    )
}


export const Testimonial = () => {
    const testimonialSchema = [
        { feedback: "Kapila is in cattle feeding business from 25 years and we wanted some solid solution for our 2 Millions farmers. So we approached WhatsTool. <br/>We recently implemented WhatsTool Business's WhatsApp Marketing solution and have been very pleased with the results. We've seen an increase in customer engagement, response times, and overall satisfaction with our communications.", name: 'Saurabh Shivhare', designation: 'Chief Executive', planInfo: 'Plan: Enterprise', category: 'Cattle Feed Industry, India', banner: WtUrl.imageBaseUrl + '/tes_ind_kapilagro_banner.png', logo: WtUrl.imageBaseUrl + "/tes_ind_kapilagro.png" },
        { feedback: "Our company were operating all manually. We wanted to go with WhatsApp for automating our booking for our cleaning services. WhatsTool team not only made us automate they made our success by integrating with our existing system and increased our booking by 12%. We are very happy so far.", name: 'Nishant Prasad', designation: 'Founder', planInfo: 'Plan: Enterprise', category: 'Cleaning Service, India', banner: WtUrl.imageBaseUrl + '/tes_ind_cleanfanatic_banner.png', logo: WtUrl.imageBaseUrl + "/tes_ind_cleanfanatic.png" },
        { feedback: "Whatstool is definitely one of the most affordable & intuitive platform. We could go live within a day and the team helped us in building the chatbot ! I definitely recommend the solution to startups !", name: 'Tharun Sai E', designation: 'Founder', planInfo: 'Plan: Enterprise', category: 'Mental Health Support, India', banner: WtUrl.imageBaseUrl + '/tes_ind_findhope_banner.png', logo: WtUrl.imageBaseUrl + "/tes_ind_findhope.png" },
        { feedback: "We wanted solution for our kids who are learning through our app. We got amazed with WhatsApp API solution provided by WhatsTool. Their team support is amazing ,they always make sure to deliver best for us. We wanted chatbot to handle our learning segment and the flexibility in their chatbot functionality helps deliver what we needed. Highly recommended.", name: 'Gaurav Chowdhary', designation: 'Co-founder', planInfo: 'Plan: Growth', category: 'EdTech Company, India', banner: WtUrl.imageBaseUrl + '/tes_ind_kico_banner.png', logo: WtUrl.imageBaseUrl + "/tes_ind_kico.png" },
    ]
    return (
        <Container className="mt-5 mb-5 pb-5">
            <h3 className="theme_lite text-center mb-5">There's Word <span className="theme fw-bold">About Us</span> all Around</h3>
            <div className="testimonials">
                <Carousel variant="dark">
                    {
                        testimonialSchema?.map((v, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <Row className="bg-white">
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}><img className="mainSideImg shadow-lg" src={v.banner} style={{ height: "500px", width: '80%' }} alt={WtTheme.SiteName} /></Col>
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <div className="tesBody ps-5 pt-4">
                                                <p style={{ width: "80%", fontSize: "16px", color: WtColor.TextMidGrey }} dangerouslySetInnerHTML={{ __html: v.feedback }}></p>
                                                <div className="tes_info" style={{ display: 'flex' }}>
                                                    <div><img src={v.logo} style={{ width: 'auto', height: '60px' }} className="img-fluid logo" alt={WtString.SiteName} /></div>
                                                    <div className="ms-3">
                                                        <h3 className="fw-bold" style={{ fontSize: "24px", color: WtColor.TextDarkColor }}>{v.name}</h3>
                                                        <p style={{ fontSize: "18px", color: WtColor.TextDarkColor }}>{v.designation}</p>
                                                        <p style={{ fontSize: "15px", color: WtColor.TextDarkColor, marginTop: '-11px' }}>{v.planInfo}</p>
                                                        <p style={{ fontSize: "15px", color: WtColor.TextDarkColor, marginTop: '-11px' }}>{v.category}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </Container>
    )
}


export const FeaturesNavbar = () => {
    return (
        <Container className="mt-5 mb-5 pt-5 pb-5">
            <h3 className="text-center fw-bold" style={{ color: WtColor.TextDarkColor }}>The Most 'Complete' Solution Whatsapp Platform</h3>
            <p className="text-center text-muted" style={{ color: WtColor.TextMidGrey, fontSize: "13px" }}>Everything you need to notify, chat &amp; engage your users<br />All in one place</p>
            <div className="tabUI mt-4">
                <Tab.Container defaultActiveKey="live_chat">
                    <Nav variant="pills" className="justify-content-center">
                        <Nav.Item>
                            <Nav.Link eventKey="live_chat" className="fw-bold hand">Live Chat</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="broadcast_messaging" className="fw-bold hand">Broadcast</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="ai_chatbots" className="fw-bold hand">A.I. Chatbots</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="in_built_crm" className="fw-bold hand">In-Built CRM</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="automate_notifications" className="fw-bold hand">Automate Notifications</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="mt-5">
                        <Tab.Pane eventKey="live_chat">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/home_chat_bg.png"} alt={WtTheme.SiteName} width="500px" className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-2">
                                            <h4 className="fw-bold" style={{ color: WtColor.TextDarkColor }}>Multiple Agent Live Chat</h4>
                                            <p className="text-muted mt-3">Have multiple team members to drive Live Chat<br /> Support on the Same Whatsapp Business Number.</p>
                                            <p className="text-muted mt-3 mb-5">Filter Chats according to tags, campaigns and<br /> attributes for Smart Agent Chat Routing.</p>
                                            <Link to={WtUrl.multiAgentsUrl} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 bg-white theme fw-bold" style={{ border: "2px solid " + WtColor.BaseColor }}>Learn More</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="broadcast_messaging">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/home_broadcast_bg.png"} alt={WtTheme.SiteName} width="500px" className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-2">
                                            <h4 className="fw-bold" style={{ color: WtColor.TextDarkColor }}>Import &amp; Broadcast Instantly</h4>
                                            <p className="text-muted mt-3">You can simply Import all your Contacts and<br /> Broadcast Approved Messages Instantly.</p>
                                            <p className="text-muted mt-3 mb-5">See real-time analytics on the WhatsTool Platform for<br /> delivered, read rates and more.</p>
                                            <Link to={WtUrl.broadcastMessageUrl} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 bg-white theme fw-bold" style={{ border: "2px solid " + WtColor.BaseColor }}>Learn More</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ai_chatbots">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/wtb_chatbot_01.png"} alt={WtTheme.SiteName} width="500px" className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-2">
                                            <h4 className="fw-bold" style={{ color: WtColor.TextDarkColor }}>Solve Customer Queries 24x7</h4>
                                            <p className="text-muted mt-3">Connect Smart A.I. Chatbots from Google Dialogflow<br />seamlessly &amp; monitor conversations from Live Chat<br />Dashboard</p>
                                            <p className="text-muted mt-3 mb-5">Solve Customer Queries, Send Images, Files &amp; Videos<br /> in a Smart Automated Manner.</p>
                                            <Link to={WtUrl.chatbotsUrl} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 bg-white theme fw-bold" style={{ border: "2px solid " + WtColor.BaseColor }}>Learn More</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="in_built_crm">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/home_crm_bg.png"} alt={WtTheme.SiteName} width="500px" className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-2" style={{ color: WtColor.TextDarkColor }}>
                                            <h4 className="fw-bold">Manage Contacts, tags &amp; more</h4>
                                            <p className="text-muted mt-3">Our In-Built CRM enables you to add various tags and<br /> attributes to each customer.</p>
                                            <p className="text-muted mt-3 mb-5">WhatsTool provides you with an extensive User Profile<br /> to drive important Insights</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="automate_notifications">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/home_broadcast_bg.png"} alt={WtTheme.SiteName} width="300px" className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-2" style={{ color: WtColor.TextDarkColor }}>
                                            <h4 className="fw-bold">Connect your CRMs with APIs</h4>
                                            <p className="text-muted mt-3">Connect your CRM, Payment Portals, eCommerce<br /> Platforms like Shopify, WooCommerce, Hubspot.<br />Zapier via APIs.</p>
                                            <p className="text-muted mt-3 mb-5">Automate notifications for <b className="text-dark">Abandoned Cart,<br /> Application Drop-off, Order Confirmation, Event<br />Updates</b> &amp; more</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Container>
    )
}