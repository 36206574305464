import { ArrowBack } from '@mui/icons-material'
import React, { useContext, useState } from 'react'
import broadcastContext from '../../context/broadcast/broadcastContext'
import { DemoDialog } from '../../controller/BaseTool'
import { WtColor } from '../../constants/colours'

export const TopBar = ({ broadCast }) => {
    const mainBroadcastUI = useContext(broadcastContext) // create_campaign || contact_selection || contact_list
    const buttonActive = useContext(broadcastContext)

    const updateMainBroadcastUI = useContext(broadcastContext)
    const contactLsStatus = useContext(broadcastContext)
    const updateContactFilterUI = useContext(broadcastContext)
    const contactLsUI = useContext(broadcastContext) // contact_filter || contact_ls_ui || contact_template_var_maping
    const templateVarCount = useContext(broadcastContext)

    const campaignName = useContext(broadcastContext)
    const channelId = useContext(broadcastContext)
    const templateName = useContext(broadcastContext)
    const templateNamespace = useContext(broadcastContext)
    const templateLanguage = useContext(broadcastContext)
    const templateVarNames = useContext(broadcastContext)
    const labelFields = useContext(broadcastContext)
    const customFields = useContext(broadcastContext)
    const finalCreatedFrom = useContext(broadcastContext)
    const finalCreatedTo = useContext(broadcastContext)
    const finalLastSeenFrom = useContext(broadcastContext)
    const finalLastSeenTo = useContext(broadcastContext)
    const tempFieldMap = useContext(broadcastContext)
    const contactSelectionUITab = useContext(broadcastContext)

    // Label Handling Work
    let discardLabelsLs = []
    let labIdInclude = labelFields.labelFields?.filter((v) => { return v['l_action'] === 'include' }).map((v) => {
        discardLabelsLs.push(v['l_id'])
        return v['l_id']
    })
    let labIdExclude = labelFields.labelFields?.filter((v) => { return v['l_action'] === 'exclude' }).map((v) => {
        discardLabelsLs.push(v['l_id'])
        return v['l_id']
    })
    // Label Handling Work

    // Custom Field Handling Work
    let discardCustomFieldLs = []
    let customFieldList = customFields.customFields?.filter((v) => { return v['cf_id'] !== '' && v['cf_type'] !== '' && v['cf_key'] !== '' && v['cf_operator'] !== '' && v['cf_value'] !== '' }).map((v) => {
        discardCustomFieldLs.push(v['cf_id'])
        return v
    })
    // console.log("CF:" + JSON.stringify(customFieldList))
    // End of Custom Field Handling Work


    let fieldKeyLs = tempFieldMap.tempFieldMap?.map((v) => {
        return v['fKey']
    })
    // console.log(JSON.stringify(fieldKeyLs.join(',')))

    const createBroadcastV2 = useContext(broadcastContext)


    const handleBroadCast = () => { broadCast(true) }
    const handleMainUI = (ui) => { updateMainBroadcastUI.updateMainBroadcastUI(ui) }

    const handleContactFilterUI = (ui) => {
        updateContactFilterUI.updateContactFilterUI(ui) // contact_filter || contact_ls_ui || contact_template_var_maping
        if (buttonActive.buttonActive) {
            createBroadcast_V2()
        }
    }
    const createBroadcast_V2 = () => {
        createBroadcastV2.createBroadcastV2(campaignName.campaignName, channelId.channelId, templateName.templateName, templateNamespace.templateNamespace,
            templateLanguage.templateLanguage, templateVarNames.templateVarNames, fieldKeyLs.join(","),
            labIdInclude.join(","), labIdExclude.join(","), customFieldList, finalCreatedFrom.finalCreatedFrom, finalCreatedTo.finalCreatedTo,
            finalLastSeenFrom.finalLastSeenFrom, finalLastSeenTo.finalLastSeenTo)
    }
    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = (id) => {
        setOpenDemoDialog({
            open: true, onClose: (confirm) => {
                setOpenDemoDialog(false)
            }
        })
    }
    return (
        <header className='create-campaign bg-white' style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
            {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
            <div className='info pt-2 ms-2' style={{ display: "flex" }}>
                <div>
                    {
                        mainBroadcastUI.mainBroadcastUI !== "create_campaign" ?
                            <button onClick={() => handleMainUI("create_campaign")} style={{ background: "none", color: WtColor.CloudyGrey, border: "none", marginTop: "-20px", fontSize: "24px" }}><ArrowBack /></button> : ""
                    }
                </div>
                <div>
                    <h2 className="fw-bold ps-1" style={{ color: WtColor.TextDarkColor, fontSize: "18px" }}>Create Campaign</h2>
                </div>
            </div>
            <div className='buttons'>
                {
                    mainBroadcastUI.mainBroadcastUI === "contact_selection" ?
                        <>
                            {
                                (() => {
                                    if (contactLsUI.contactLsUI === "contact_filter" && contactSelectionUITab.contactSelectionUITab !== 'import_new_contacts') {
                                        return (
                                            <>
                                                <button className={contactLsStatus.contactLsStatus ? 'btn-camp btn-save-active' : 'btn-camp btn-save-disabled'} onClick={() => handleContactFilterUI("contact_ls_ui")}>Select Contacts</button>
                                            </>
                                        )
                                    }
                                    if (contactLsUI.contactLsUI === "contact_ls_ui") {
                                        return (
                                            <>
                                                <button className={contactLsStatus.contactLsStatus ? 'btn-camp btn-save-active-cancel' : 'btn-camp btn-save-disabled'} style={{ marginRight: "10px" }} onClick={() => handleContactFilterUI("contact_filter")}>Back</button>
                                                <button className={contactLsStatus.contactLsStatus ? 'btn-camp btn-save-active' : 'btn-camp btn-save-disabled'} onClick={() => templateVarCount.templateVarCount === 0 ? (localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true') ? createBroadcast_V2() : openDemoDialogBox() : handleContactFilterUI("contact_template_var_maping")}>{templateVarCount.templateVarCount === 0 ? 'Save' : 'Next'}</button>
                                            </>
                                        )
                                    }
                                    if (contactLsUI.contactLsUI === "contact_template_var_maping") {
                                        return (
                                            <>
                                                <button className={contactLsStatus.contactLsStatus ? 'btn-camp btn-save-active-cancel' : 'btn-camp btn-save-disabled'} style={{ marginRight: "10px" }} onClick={() => handleContactFilterUI("contact_ls_ui")}>Back</button>
                                                <button className={contactLsStatus.contactLsStatus && buttonActive.buttonActive ? 'btn-camp btn-save-active' : 'btn-camp btn-save-disabled'} onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? createBroadcast_V2() : openDemoDialogBox()}>Save</button>
                                            </>
                                        )
                                    }
                                })()
                            }
                        </>
                        :
                        <button className={buttonActive.buttonActive ? 'btn-camp btn-save-active' : 'btn-camp btn-save-disabled'} onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? handleBroadCast() : openDemoDialogBox()}>Save</button>
                }
            </div>
        </header>
    )
}