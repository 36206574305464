import React, { useEffect, useState } from 'react'
import './App.css'
import { genNewTokenAPI } from './controller/APIs'
import { PlanCheckDialog, ShowSnackbar } from './controller/BaseTool'
import { LoginValidate, Logout } from './controller/caller'
import { Env } from './controller/constant'
import axios from 'axios'
import './App.css'
import { Buffer } from 'buffer'
import { RouteController } from './controller/routes'
import { isMobile } from 'react-device-detect'
import GlobalState from './context/global/globalState'
import './messaging_init_in_sw'
import { initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { WtUrl } from './constants/urls'
import { WtColor } from './constants/colours'


axios.interceptors.request.use(
  (request) => {
    let token_gen = String(Number(Date.now()))
    token_gen = Buffer.from(token_gen).toString("base64").slice(0, -2)
    token_gen = token_gen.split("").reverse().join("")

    if (Env.env_type === "testing" || Env.env_type === "testing_live") console.log("📣 Request:", request.method, request.url.replace(Env.baseApiCallURL, ""), request.data)
    // if (Env.env_type === 'production') console.log('📣 Request:', request.method, request.url.replace(Env.baseApiCallURL, ''), request.data)

    request.headers["token"] = token_gen
    if (localStorage.getItem("userAuthToken")) request.headers["authorization"] = localStorage.getItem("userAuthToken")
    if (localStorage.getItem("bId")) request.headers["bid"] = localStorage.getItem("bId")
    if (localStorage.getItem("userEmail")) request.headers["empemail"] = localStorage.getItem("userEmail")
    request.headers["devicetype"] = isMobile ? "mobile_web" : "desktop_web"

    return request
  },
  (error) => { return Promise.reject(error) }
)

axios.interceptors.response.use(
  (response) => {
    if (Env.env_type === "testing" || Env.env_type === "testing_live") console.log("🟢 Success:", response.config.method, response.config.url.replace(Env.baseApiCallURL, ""), response.status)
    // if (Env.env_type === 'production') console.log('🟢 Success:', response.config.method, response.config.url.replace(Env.baseApiCallURL, ''), response.status)
    return response;
  },
  (error) => {
    const response = error.response;
    if (Env.env_type === "testing" || Env.env_type === "testing_live") console.log("🔴 Failure:", response.config.method, response.config.url.replace(Env.baseApiCallURL, ""), response.status)
    // if (Env.env_type === 'production') console.log('🔴 Failure:', response.config.method, response.config.url.replace(Env.baseApiCallURL, ''), response.status)
    return Promise.reject(error)
  }
)

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "capitalize", { value: function () { return this.charAt(0).toUpperCase() + this.slice(1) }, enumerable: false, })

function App() {
  const genNewToken = () => {
    let busy = false
    if (!busy) {
      busy = true
      try {
        if (localStorage.getItem("userAuthToken") && localStorage.getItem("userAuthToken") !== "") {
          const response = genNewTokenAPI();
          if (response.status === 200 && response?.data?.authToken) localStorage.setItem("userAuthToken", response?.data?.authToken)
          if (response.status === 402) Logout()
        }
      } catch (error) { }
    }
  }

  const [openPlanCheckDialog, setPlanCheckDialog] = useState({ open: false, onClose: null, })

  // Snackbar Code
  const [errorMsg, setErrorMsg] = useState("")
  const [errorMsgBg, setErrorMsgBg] = useState(WtColor.RedColor)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const SnackbarShow = (errorMsg, errorMsgBg) => {
    setErrorMsg(errorMsg)
    setErrorMsgBg(errorMsgBg)
    setOpenSnackbar(true)
  }
  const SnackbarClose = () => setOpenSnackbar(false)
  // End of Snackbar Code

  axios.interceptors.response.use(
    (response) => {
      let status = response?.status;
      if (status === 200 && response.data.message !== "Success" && response.config.url !== "http://ip-api.com/json") SnackbarShow(response.data.message, WtColor.BaseColor);
      if (status === 201) SnackbarShow(response.data.message, WtColor.BaseColor);
      if (status === 202) SnackbarShow(response.data.message, WtColor.BaseColor);
      if (status === 204) SnackbarShow("Delete Successfully", WtColor.BaseColor);
      return response;
    },
    (error) => {
      if (error?.response?.status === 500) SnackbarShow("Internal Server Error", WtColor.RedColor);
      if (error?.response?.status === 501) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 510) {
        if (Env.env_type === "testing" || Env.env_type === "testing_live") {
          console.log(error.response.data.message);
          SnackbarShow(error.response.data.message, WtColor.RedColor);
        }
        // if (Env.env_type === "production") SnackbarShow("Something went wrong!!!", WtColor.RedColor);
      }
      if (error?.response?.status === 400) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 401) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 402) Logout();
      if (error?.response?.status === 403 && error?.response.config.url !== "http://ip-api.com/json") SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 404) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 405) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 406) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 409) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 410) SnackbarShow(error.response.data.message, WtColor.RedColor);
      if (error?.response?.status === 426) setPlanCheckDialog({ open: true, purpose: "FreeTrialOver", onClose: () => setPlanCheckDialog(false) })
      if (error?.response?.status === 429) setPlanCheckDialog({ open: true, purpose: "LimitExhausted", onClose: () => setPlanCheckDialog(false) })

      return Promise.reject(error)
    }
  )


  // Chat Notification Work
  if (LoginValidate()) {
    const app = initializeApp(Env.firebaseConfig)
    const messaging = getMessaging(app)
    onMessage(messaging, (payload) => {
      if (Env.env_type === "testing" || Env.env_type === "testing_live") console.log(payload)
      if (LoginValidate() && !window.location.href.startsWith(Env.baseUI_URL + WtUrl.chatUrl)) {
        toast(<ToastifyNotification payload={payload} />)
      }
    })
    const notiticlick = (hash) => {
      localStorage.setItem('notificationClicked', 'true')
      window.location.href = Env.baseUI_URL + WtUrl.chatUrl + '/' + hash
    }

    const ToastifyNotification = ({ payload }) => (
      <div className="push-notification" onClick={() => notiticlick(payload?.data?.hash)}>
        <h6 className="push-notification-title" style={{ fontWeight: "bold", color: WtColor.TextDarkColor }}>{payload?.notification?.title}</h6>
        <p className="push-notification-text" style={{ color: WtColor.TextMidGrey }}>{payload?.notification?.body}</p>
      </div>
    )
  }
  // End of Chat Notification Work

  useEffect(() => { genNewToken() }, [])

  useEffect(() => {
    if (LoginValidate()) {
      if (!localStorage.getItem("userActivity")) localStorage.setItem("userActivity", "false")
      if (!localStorage.getItem("botLeftSidebar")) localStorage.setItem("botLeftSidebar", "false")
      if (!localStorage.getItem("isDemo")) localStorage.setItem("isDemo", false)
    }
  }, [])

  // useEffect(() => {
  //   let scaleAmount = 1 - 0.07
  //   window.parent.document.body.style.transform = `scale(${scaleAmount})`
  //   window.parent.document.body.style.width = `${100 * (1 / scaleAmount)}%`
  //   window.parent.document.body.style.webkitTransform = scaleAmount;
  //   window.parent.document.body.style.msTransform = scaleAmount;
  //   window.parent.document.body.style.transform = scaleAmount
  // }, [])
  return (
    <>
      <GlobalState><RouteController /></GlobalState>
      <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} close={SnackbarClose} />
      <PlanCheckDialog open={openPlanCheckDialog.open} purpose={openPlanCheckDialog.purpose} onClose={openPlanCheckDialog.onClose} />
      <ToastContainer hideProgressBar={true} autoClose={6000} />
    </>
  )
}

export default App
