/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { CloseOutlined, Description, Headphones, PermIdentityOutlined, PhotoCamera, Search, Videocam } from '@mui/icons-material'
import { WtTheme } from '../../controller/theme'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Avatar, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { MultiPurposeDialog, NewChatAskForWhatsAppUI, ShowTimeDayDateInChat } from '../../controller/BaseTool'
import chatContext from '../../context/chat/chatContext'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'


export const ChatList = () => {
    let { hash } = useParams()
    const inboxFilter = useContext(chatContext)
    const updateActiveChatInfo = useContext(chatContext)
    const chatLsSeed = useContext(chatContext)
    const chatListData = useContext(chatContext)
    const setMessageLsData = useContext(chatContext)
    const setActiveChatInfo = useContext(chatContext)
    const getChats = useContext(chatContext)
    const getChatsBySearch = useContext(chatContext)
    const chatListSizeStatus = useContext(chatContext)
    const setUserInfo = useContext(globalContext)
    const getUserInfo = useContext(globalContext)
    const getAgents = useContext(globalContext)
    const getLabelLs = useContext(globalContext)
    const searchValue = useContext(chatContext)
    const setSearchValue = useContext(chatContext)
    const handleSearchValue = useContext(chatContext)
    const busyC = useContext(chatContext)
    const busyM = useContext(chatContext)
    const setBusyM = useContext(chatContext)


    const [userHash, setUserHash] = useState(undefined)

    // Get Chat List
    useEffect(() => {
        getChats.getChats(inboxFilter.inboxFilter.section, inboxFilter.inboxFilter.key, 0, 20, "first_time")
    }, [chatLsSeed.chatLsSeed])
    // Get Chat List


    //Search Chat
    const handleChatSearch = async (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            await getChatsBySearch.getChatsBySearch(e.target.value)
        } else if (e.keyCode === 8 && e.target.value.length === 1) {
            setSearchValue.setSearchValue('')
            e.preventDefault()
            await getChats.getChats(inboxFilter.inboxFilter.section, inboxFilter.inboxFilter.key, 0, 20, "first_time")
        }
    }

    const handleSearchBtnClick = async () => await getChatsBySearch.getChatsBySearch(searchValue.searchValue)
    useEffect(() => { if (searchValue.searchValue?.length === 0) getChatsBySearch.getChatsBySearch('') }, [searchValue.searchValue])
    // End of Search Chat


    // Scroll More
    let busy = false
    const [chatSkip, setChatSkip] = useState(20)
    const handleChatList = () => {
        setChatSkip(chatSkip + 20)
        localStorage.setItem("ch_filterInbox_chatSkip", chatSkip)
        getChats.getChats(inboxFilter.inboxFilter.section, inboxFilter.inboxFilter.key, chatSkip, 20, "fetch_more")
    }
    // const [scrollPermission, setScrollPermission] = useState(true)
    const handleChatScroll = (e) => {
        if (true) {
            if (!busy) {
                busy = true
                const currentScrollY = e.target.scrollTop
                const cHeight = e.target.clientHeight
                const sHeight = e.target.scrollHeight
                const substractHeight = (sHeight - cHeight)
                if ((currentScrollY / substractHeight).toFixed(3) >= "0.990" && chatListSizeStatus.chatListSizeStatus) {
                    handleChatList()
                    busy = false
                } else { busy = false }
            }
        }
    }
    // End of Scroll More


    // Filter Menu
    const [filterMenuAnchor, setFilterMenuAnchor] = useState(null)
    const filterMenuOpen = Boolean(filterMenuAnchor)
    const handleFilterMenuClick = (event) => { setFilterMenuAnchor(event.currentTarget) }
    const handleFilterMenuClose = () => { setFilterMenuAnchor(null) }
    const [filterMenuOptionClicked, setFilterMenuOptionClick] = useState(false)
    const [filterMenuOption, setFilterMenuOption] = useState("newDate")
    const handleFilterMenuOption = (filter) => {
        setFilterMenuOptionClick(true)
        setFilterMenuOption(filter)
        if (filter === "newDate") setFilterMenuOptionClick(false)
    }
    // End of Filter Menu


    // ChatStatus Work
    const history = useHistory()
    const openURL = (url) => history.push(url)
    const handleChatClick = (name, whatsapp, channel, channelName, hash, msgDate, chatId, unreadCount) => {
        setBusyM.setBusyM(false)
        openURL('/dashboard/chat/' + hash)
        handleChatLsClick(name, whatsapp, channel, channelName, hash, msgDate, chatId, unreadCount)
    }
    const handleChatLsClick = async (name, whatsapp, channel, channelName, hash, msgDate, chatId, unreadCount) => {
        console.log('1')
        if (!busyM.busyM) {
            console.log('2')
            setBusyM.setBusyM(true)

            localStorage.setItem("chat_status", "true")
            setActiveChatInfo.setActiveChatInfo([])
            setMessageLsData.setMessageLsData([])
            setUserInfo.setUserInfo([])

            console.log('3')
            updateActiveChatInfo.updateActiveChatInfo(name, whatsapp, channel, channelName, hash, msgDate, chatId, unreadCount)
            console.log('4')
            await getUserInfo.getUserInfo(channel, whatsapp)
            await getAgents.getAgents()
            await getLabelLs.getLabelLs()
            console.log('5')
            setBusyM.setBusyM(false)
            console.log('6')
        }
    }
    // End of ChatStatus Work


    // Start New Chat Work
    const [openMultiPurposeDialog, setOpenMultiPurposeDialog] = useState({ open: false, onClose: null })
    const openMultiPurposeDialogBox = (purposeVal, demandVal, reqData) => setOpenMultiPurposeDialog({ open: true, purposeVal: purposeVal, demandVal: demandVal, reqData: reqData, onClose: (confirm) => setOpenMultiPurposeDialog(false) })


    const [openNewChatDialog, setOpenNewChatDialog] = useState({ open: false, onClose: null })
    const openNewChatDialogBox = () => {
        setOpenNewChatDialog({
            open: true, onClose: (confirm, whatsapp_number, channel_name, channel_number) => {
                setOpenNewChatDialog(false)
                if (confirm && whatsapp_number) openMultiPurposeDialogBox("NewChat", "template", { "channel_name": channel_name, "channel_number": channel_number, "whatsapp_number": whatsapp_number })
            }
        })
    }
    // End of Start New Chat Work


    // Handle Auto-Chat-Open
    useEffect(() => {
        if (hash === undefined && chatListData.chatListData?.length > 0) {
            openURL('/dashboard/chat/' + hash)
            chatListData.chatListData?.map(async (v, i) => {
                if (i === 0) {
                    let name = v.user ? (v.user?.name ? v.user?.name : v.user?.whatsapp) : v.extUserId
                    name = v.user ? ((name?.length > 24) ? name?.substring(0, 24) : name) : v.extUserId
                    let whatsapp = v.user ? v.user?.whatsapp : v.extUserId
                    let channel = v.extChannelId
                    let _hash = v.hash
                    let msgDate = v.userUpdateDate ? v.userUpdateDate : 0
                    let chatId = v._id
                    let unreadCount = v.unreadCount ? v.unreadCount : 0
                    let channelName = ""

                    handleChatClick(name, whatsapp, channel, channelName, _hash, msgDate, chatId, unreadCount)
                }
            })
        }
    }, [hash, chatListData.chatListData])


    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }
    let query = useQuery()

    useEffect(() => {
        if (hash !== undefined && localStorage.getItem('notificationClicked') === 'true') {
            setTimeout(() => { setUserHash(hash) }, 2000)
            chatListData.chatListData?.map(async (v) => {
                let name = v.user ? (v.user?.name ? v.user?.name : v.user?.whatsapp) : v.extUserId
                name = v.user ? ((name?.length > 24) ? name?.substring(0, 24) : name) : v.extUserId
                let whatsapp = v.user ? v.user?.whatsapp : v.extUserId
                let channel = v.extChannelId
                let _hash = v.hash
                let msgDate = v.userUpdateDate ? v.userUpdateDate : 0
                let chatId = v._id
                let unreadCount = v.unreadCount ? v.unreadCount : 0
                let channelName = ""

                if (_hash === hash) {
                    handleChatLsClick(name, whatsapp, channel, channelName, _hash, msgDate, chatId, unreadCount)
                    localStorage.setItem('notificationClicked', 'false')
                }
            })
        }
        if (hash !== undefined && localStorage.getItem('openChatFromContacts') && localStorage.getItem('openChatFromContacts') === 'true') {
            setTimeout(() => { setUserHash(hash) }, 2000)

            let name = query.get("name")
            let whatsapp = query.get("whatsapp")
            let channel = query.get("channel")
            let chatId = query.get("chatId")
            let msgDate = query.get("msgDate")
            handleChatLsClick(name, whatsapp, channel, "", hash, msgDate, chatId, 0)
            localStorage.removeItem('openChatFromContacts')
        }
    }, [busyC.busyC, hash, userHash])
    // End of Handle Auto-Chat-Open 
    useEffect(() => {
        localStorage.setItem('notificationClicked', 'true')
    }, [])
    return (
        <div className='chat_list' style={{ flex: "0.25", borderRight: "1px solid " + WtColor.LineColor }}>
            <div className="mt-2" style={{ display: "flex", paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                <div style={{ display: "flex", flex: 1, border: "1px solid " + WtColor.TextLightGrey, borderRadius: "30px", }}>
                    <img src={WtTheme.ImageBaseUrl + '/search.svg'} style={{ marginLeft: "8px" }} alt={WtTheme.SiteName} />
                    <input value={searchValue.searchValue} onChange={(e) => handleSearchValue.handleSearchValue(e.target.value)} onKeyDown={(e) => handleChatSearch(e)} type="search" className="w-100" style={{ border: 'none', fontSize: "18px", outlineWidth: "0px", borderRadius: "30px", height: "40px", paddingLeft: "20px", paddingRight: "20px" }} placeholder="Search Chat" />
                    <Search className='hand' value={searchValue.searchValue} onClick={() => searchValue.searchValue?.length === 0 ? {} : handleSearchBtnClick()} style={{ display: searchValue.searchValue?.length === 0 ? 'none' : 'block', color: searchValue.searchValue?.length === 0 ? WtColor.CloudyGrey : WtColor.TextDarkColor, marginTop: '8px', marginRight: '8px' }} />
                </div>
                <Tooltip title={filterMenuOptionClicked ? "Filter Applied" : "Filter"} placement="left">
                    <IconButton onClick={handleFilterMenuClick} className="ms-2">
                        {
                            filterMenuOptionClicked ?
                                <img src={WtTheme.ImageBaseUrl + "/filter_active.svg"} style={{ width: "24px" }} alt={WtTheme.SiteName} />
                                :
                                <img src={WtTheme.ImageBaseUrl + "/filter.svg"} style={{ width: "24px" }} alt={WtTheme.SiteName} />
                        }
                    </IconButton>
                </Tooltip>
                <div>
                    <Menu anchorEl={filterMenuAnchor} id="filter-menu" open={filterMenuOpen} onClose={handleFilterMenuClose} onClick={handleFilterMenuClose}
                        PaperProps={{ elevation: 0, sx: { overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1, }, '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0, }, }, }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                        <MenuItem onClick={() => handleFilterMenuOption("newDate")}>Newest First</MenuItem>
                        <MenuItem onClick={() => handleFilterMenuOption("oldDate")}>Oldest First</MenuItem>
                        <MenuItem onClick={() => handleFilterMenuOption("aToz")}>A to Z Sort</MenuItem>
                        <MenuItem onClick={() => handleFilterMenuOption("zToa")}>Z to A Sort</MenuItem>
                    </Menu>
                </div>
            </div>
            <div style={{ display: "flex", paddingLeft: "10px", paddingRight: "10px", }}>
                <p className="fw-semibold" style={{ flex: 1, color: WtColor.TextDarkColor, fontSize: "18px", marginTop: "4px" }}>{inboxFilter.inboxFilter.title}</p>
                <div>
                    <Tooltip title="Start New Chat" placement='left'>
                        <button onClick={() => openNewChatDialogBox()} className="btn btn-sm mt-0 pt-2 pb-2 fw-bold" style={{ background: WtColor.LineColor, color: WtColor.TextDarkColor }}><img src={WtTheme.ImageBaseUrl + "/edit.svg"} style={{ width: "25px" }} alt={WtTheme.SiteName} /></button>
                    </Tooltip>
                </div>
                <MultiPurposeDialog open={openMultiPurposeDialog.open} purposeVal={openMultiPurposeDialog.purposeVal} demandVal={openMultiPurposeDialog.demandVal} reqData={openMultiPurposeDialog.reqData} onClose={openMultiPurposeDialog.onClose} />
                <NewChatAskForWhatsAppUI open={openNewChatDialog.open} onClose={openNewChatDialog.onClose} />
            </div>
            <div className="list_scroll" style={{ height: "83%" }} onScrollCapture={handleChatScroll}>
                {busyC.busyC && <CircularProgress style={{ marginLeft: "45%", marginTop: "50%" }} color="primary" />}
                {
                    !busyC.busyC &&
                        chatListData.chatListData?.length !== 0 ?
                        <>
                            {
                                chatListData.chatListData?.sort((a, b) => {
                                    if (filterMenuOption === "newDate") { return (a.updateDate < b.updateDate) ? 1 : -1 }
                                    if (filterMenuOption === "oldDate") { return (a.updateDate > b.updateDate) ? 1 : -1 }
                                    if (filterMenuOption === "aToz") { return (a.user?.name > b.user?.name) ? 1 : -1 }
                                    if (filterMenuOption === "zToa") { return (a.user?.name < b.user?.name) ? 1 : -1 }
                                }).filter((value, index, self) => {
                                    return index === self.findIndex((t) => (
                                        t.hash === value.hash && t.extUserId === value.extUserId
                                    ))
                                }).map((v, i) => {
                                    let name = v.user ? (v.user?.name ? v.user?.name : v.user?.whatsapp) : v.extUserId
                                    name = v.user ? ((name?.length > 24) ? name?.substring(0, 24) : name) : v.extUserId
                                    let whatsapp = v.user ? v.user?.whatsapp : v.extUserId
                                    let channel = v.extChannelId
                                    let hash = v.hash
                                    let msgDate = v.userUpdateDate ? v.userUpdateDate : 0
                                    let chatId = v._id
                                    let unreadCount = v.unreadCount ? v.unreadCount : 0
                                    let channelName = ""
                                    return (
                                        <div className="chat_hover_view ps-2 pe-2 pt-2 pb-2 hand" onClick={() => handleChatClick(name, whatsapp, channel, channelName, hash, msgDate, chatId, unreadCount)} style={(localStorage.getItem("uHash") === hash) ? { display: "flex", background: WtColor.LineColor } : { display: "flex" }} key={i}>
                                            <Avatar style={(localStorage.getItem("uHash") === hash) ? { background: WtColor.LineColor, color: WtColor.BaseColor, padding: "25px", border: "1px solid " + WtColor.BaseColor } : { background: WtColor.LineColor, color: WtColor.BaseColor, padding: "25px" }} className="userImage"><PermIdentityOutlined /></Avatar>
                                            <div className="ms-3" style={{ display: "flex", flexDirection: "column", flex: "1" }}>
                                                <p className="fw-bold chat_list_name" style={{ color: (localStorage.getItem("uHash") === hash) ? WtColor.BaseColor : WtColor.TextDarkColor, fontSize: "15px" }}>{name}</p>
                                                <p style={{ marginTop: "-15px", color: WtColor.TextLightGrey, fontSize: "13px" }}>
                                                    {
                                                        (() => {
                                                            if (v.lastMsg?.type === "text" && v.lastMsg?.text) {
                                                                let lastTextMessage = ((typeof (v.user?.name) === "string") && (v.lastMsg?.text?.body.length <= 35)) ? v.lastMsg?.text?.body : v.lastMsg?.text?.body?.substring(0, 35) + " ..."
                                                                return (
                                                                    <>{lastTextMessage}</>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "image" && (v.lastMsg?.image?.id || v.lastMsg?.image?.link)) {
                                                                return (
                                                                    <>
                                                                        <PhotoCamera style={{ fontSize: "15px" }} /> &nbsp;
                                                                        {(v.lastMsg?.image?.caption && v.lastMsg?.image?.caption !== "") ? v.lastMsg?.image?.caption?.substring(0, 20) + " ..." : "Image"}
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "location" && v.lastMsg?.location) {
                                                                return (
                                                                    <>
                                                                        Location: &nbsp;
                                                                        {(v.lastMsg?.location?.name) ? v.lastMsg?.location?.name?.substring(0, 20) + " ..." : v.lastMsg?.location?.latitude}
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "audio" && v.lastMsg?.audio?.id) {
                                                                return (
                                                                    <>
                                                                        <Headphones style={{ fontSize: "15px" }} /> &nbsp;Audio
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "audio" && v.lastMsg?.audio?.link) {
                                                                return (
                                                                    <>
                                                                        <Headphones style={{ fontSize: "15px" }} /> &nbsp;Audio
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "document" && v.lastMsg?.document?.id) {
                                                                return (
                                                                    <>
                                                                        <Description style={{ fontSize: "15px" }} /> &nbsp;Document
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "document" && v.lastMsg?.document?.link) {
                                                                return (
                                                                    <>
                                                                        <Description style={{ fontSize: "15px" }} /> &nbsp;Document
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "video") {
                                                                return (
                                                                    <>
                                                                        <Videocam style={{ fontSize: "15px" }} /> &nbsp;Video
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "video" && v.lastMsg?.video?.id) {
                                                                return (
                                                                    <>
                                                                        <Videocam style={{ fontSize: "15px" }} /> &nbsp;Video
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "unsupported") {
                                                                return (
                                                                    <>
                                                                        <CloseOutlined style={{ color: WtColor.RedColor, fontSize: "15px" }} /> &nbsp;Message type is not supported.
                                                                    </>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "template" && v.lastMsg?.template?.name) {
                                                                let lastTextMessage = ((typeof (v.user?.name) === "string") && (v.lastMsg?.template?.name?.length <= 30)) ? v.lastMsg?.template?.name : v.lastMsg?.template?.name?.substring(0, 30) + " ..."
                                                                return (
                                                                    <>Template: {lastTextMessage?.substring(0, 20) + " ..."}</>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "interactive" && v.lastMsg?.interactive && v.lastMsg?.interactive?.body && v.lastMsg?.interactive?.body?.text) {
                                                                let lastTextMessage = ((typeof (v.user?.name) === "string") && (v.lastMsg?.interactive?.body?.text.length <= 20)) ? v.lastMsg?.interactive?.body?.text : v.lastMsg?.interactive?.body?.text?.substring(0, 20) + " ..."
                                                                return (
                                                                    <>Interactive: {lastTextMessage}</>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "interactive" && v.lastMsg?.interactive && v.lastMsg?.interactive?.button_reply && v.lastMsg?.interactive?.button_reply?.title) {
                                                                let lastTextMessage = ((typeof (v.user?.name) === "string") && (v.lastMsg?.interactive?.button_reply?.title?.length <= 20)) ? v.lastMsg?.interactive?.button_reply?.title : v.lastMsg?.interactive?.button_reply?.title?.substring(0, 20) + " ..."
                                                                return (
                                                                    <>Interactive: {lastTextMessage}</>
                                                                )
                                                            }
                                                            if (v.lastMsg?.type === "button" && v.lastMsg?.button && v.lastMsg?.button?.text) {
                                                                return (
                                                                    <>Button Reply: {v.lastMsg.button.text}</>
                                                                )
                                                            }
                                                        })()
                                                    }
                                                    &nbsp;
                                                </p>
                                                <div style={{ marginTop: "-15px" }}>
                                                    {
                                                        v.labels && v.labels.length !== 0 ?
                                                            v.labels?.slice(0, 2).map((lv, li) => {
                                                                return (
                                                                    <span className='fw-bold me-2' style={{ color: WtColor.TextMidGrey, fontSize: "10px", paddingTop: "2px", paddingBottom: "2px" }} key={li}>{lv.label_name}</span>
                                                                )
                                                            }) : ""
                                                    }
                                                </div>
                                                {
                                                    localStorage.getItem("cObj") && localStorage.getItem("cObj") !== "" ?
                                                        JSON.parse(localStorage.getItem("cObj"))?.filter((f) => { return f.whatsapp === v.extChannelId && f.title }).map((f, index) => {
                                                            channelName = f.title
                                                        })
                                                        : ""
                                                }
                                            </div>
                                            <div>
                                                <p style={{ color: WtColor.TextLightGrey, fontSize: "10px", marginTop: "6px" }}>
                                                    {ShowTimeDayDateInChat(v.updateDate)}<br />
                                                    {
                                                        (unreadCount !== 0) ? <span style={{ background: WtColor.BaseColor, color: "#fff", fontSize: "10px", position: "relative", top: "3px", float: "right", borderRadius: "20%", paddingLeft: "8px", paddingRight: "8px", paddingTop: "3px", paddingBottom: "3px" }}>{v.unreadCount >= 30 ? "30+" : v.unreadCount}</span> : ""
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <div className='p-3'>
                            <p className="text-center" style={{ color: WtColor.TextMidGrey }}><span style={{ color: WtColor.TextDarkColor, fontWeight: '500' }}>{searchValue.searchValue?.substring(0, 20)}{searchValue.searchValue?.length > 20 ? ' ...' : ''}</span> not found...</p>
                        </div>
                }
            </div>
        </div>
    )
}