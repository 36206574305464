import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'

export const SignupSuccess = () => {
    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, paddingLeft: "100px", paddingRight: "100px", paddingTop: "15px", paddingBottom: "15px", textTransform: "none", boxShadow: "none" }
    return (
        <Container className="pb-5 mt-3">
            <div>
                <Link to="/" style={{ display: "flex" }}><img src={WtTheme.ImageBaseUrl + '/wtb_green_round_images.png'} width="40px" height="40px" alt={WtTheme.SiteName} />&nbsp; <img src={WtTheme.ImageBaseUrl + "/logo_text_dark.png"} width="auto" height="35px" className="mt-1" alt={WtTheme.SiteName} /></Link>
            </div>
            <div className="pb-5 mt-3">
                <p className="fw-bold text-center" style={{ color: WtColor.BaseColor, fontSize: "24px" }}>Hi {localStorage.getItem("userNameT")?.replace(/ .*/, '')}!👋</p>
                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>Welcome to WhatsTool Business</p><br />
                <p className="text-center fw-bold" style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>{localStorage.getItem("userNameT")?.replace(/ .*/, '')}, We are super excited to onboard you 🤗</p><br /><br />
                <p className="text-center fw-bold mb-5" style={{ color: "#000", fontSize: "24px" }}>We have exciting offer for you 🎁</p>
                <center>
                    <div className='rounded-3 w-50 mt-5' style={{ border: "1px solid " + WtColor.BaseColor }}>
                        <p className='text-center mb-5'>
                            <img src={WtTheme.ImageBaseUrl + "/SignupSuccess.gif"} style={{ padding: "40px" }} className="img-fluid" alt={WtTheme.SiteName} />
                            <Link to="/login" >
                                <button style={ValidButtonStyle} className="btn btn-lg rounded-3 text-white theme_bg">Get Started Now</button>
                            </Link>
                        </p>
                        <p className="text-center ps-4 pe-4 fw-bold mt-4 mb-5">
                            <span style={{ color: WtColor.TextDarkColor }}>Unlock the power of WhatsApp</span> <span style={{ color: WtColor.TextLightGrey }}>Api just by</span><span style={{ color: WtColor.TextDarkColor }}> investing your 10 mins.</span><br />
                            <span style={{ color: WtColor.TextLightGrey }}>We have made it super simple to get started by yourself.</span>
                        </p>
                    </div>
                </center>
            </div>
        </Container>
    )
}