/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import './index.css'
import { AddCircleOutlineOutlined, Business, ChatBubbleOutlineOutlined, ChatOutlined, Check, CheckSharp, Close, CloseSharp, Info, PersonOutlineOutlined, RocketLaunchOutlined } from '@mui/icons-material'
import { Container, Row, Col, Accordion, Table } from 'react-bootstrap'
import { WtTheme } from '../../controller/theme'
import { useHistory } from 'react-router-dom'
import { Switch } from '@mui/material'
import { fetchPlanListOpen, fetchUserLocation } from '../../controller/APIs'
import { LoginSignupDialog } from '../../controller/BaseTool'
import { LoginValidate } from '../../controller/caller'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'
import { Player } from '@lottiefiles/react-lottie-player'
import { PricingFeaturesDialog } from '../../basetool/dialogs'
import { Testimonial } from './ui'


export const Pricing = () => {
    const [planList, setPlanList] = useState([])

    const [whichCountry, setWhichCountry] = useState('india')
    const checkCountry = async () => {
        try {
            const response = await fetchUserLocation()
            if (response.status === 200) {
                if (response.data.country !== 'India') setWhichCountry('outside')
            }
        } catch (error) { }
    }
    useEffect(() => { checkCountry() }, [])

    const [planType, setPlanType] = useState('yearly')
    const [planTypeUI, setPlanTypeUI] = useState([{ "planTypeName": "Monthly", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" }, { "planTypeName": "Quaterly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }, { "planTypeName": "Yearly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }])
    const handlePlanType = (type) => { setPlanType(type) }

    const [gBasePrice, setGBasePrice] = useState(undefined)
    const [pBasePrice, setPBasePrice] = useState(undefined)
    const [eBasePrice, setEBasePrice] = useState(undefined)

    const [extraOfferFree, setExtraOfferFree] = useState('Free Setup + Guidance + Assistance')
    const [extraOfferGrowth, setExtraOfferGrowth] = useState('Free Setup + Guidance + Assistance')
    const [extraOfferPro, setExtraOfferPro] = useState('Free Setup + Guidance + Assistance')
    const [extraOfferEnterprise, setExtraOfferEnterprise] = useState('Free Setup + Guidance + Assistance')

    useEffect(() => {
        setGBasePrice(planList[1]?.price)
        setPBasePrice(planList[2]?.price)
        setEBasePrice(planList[3]?.price)
    }, [planList])


    const history = useHistory()

    let usdVal = 82

    const [currencyVal, setCurrencyVal] = useState(false)
    useEffect(() => { setCurrencyVal(whichCountry === 'outside' ? true : false) }, [whichCountry])
    const handleCurrencyVal = (event) => { setCurrencyVal(event.target.checked) }

    const [planPrice, setPlanPrice] = useState({ "free": 0, "growth": 0, "pro": 0, "enterprise": 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePlanPrice = () => {
        let growthBasePrice = planList[1]?.price
        let proBasePrice = planList[2]?.price
        let enterpriseBasePrice = planList[3]?.price

        let growthMPercentage = planList[1]?.percentageM
        let growthQPercentage = planList[1]?.percentageQ
        let growthYPercentage = planList[1]?.percentageY
        let growthSPercentage = planList[1]?.percentageS

        let proMPercentage = planList[2]?.percentageM
        let proQPercentage = planList[2]?.percentageQ
        let proYPercentage = planList[2]?.percentageY
        let proSPercentage = planList[2]?.percentageS

        let enterpriseMPercentage = planList[3]?.percentageM
        let enterpriseQPercentage = planList[3]?.percentageQ
        let enterpriseYPercentage = planList[3]?.percentageY
        let enterpriseSPercentage = planList[3]?.percentageS

        let f = 0
        let g = 0
        let p = 0
        let e = 0
        const calculatePrice = (planTypeVal, gPTPer, pPTPer, yPTPer) => {
            g = (growthBasePrice * planTypeVal) - ((growthBasePrice * planTypeVal * (gPTPer + growthSPercentage)) / 100)
            p = (proBasePrice * planTypeVal) - ((proBasePrice * planTypeVal * (pPTPer + proSPercentage)) / 100)
            e = (enterpriseBasePrice * planTypeVal) - ((enterpriseBasePrice * planTypeVal * (yPTPer + enterpriseSPercentage)) / 100)
        }

        if (planType === "monthly") {
            let monthly = 1
            calculatePrice(monthly, growthMPercentage, proMPercentage, enterpriseMPercentage)
            if (currencyVal) {
                setPlanPrice({ "free": Math.floor(f / usdVal), "growth": Math.floor(g / usdVal), "pro": Math.floor(p / usdVal), "enterprise": Math.floor(e / usdVal) })
                setGBasePrice(Math.floor(planList[1]?.price / usdVal))
                setPBasePrice(Math.floor(planList[2]?.price / usdVal))
                setEBasePrice(Math.floor(planList[3]?.price / usdVal))
            } else {
                setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
                setGBasePrice(planList[1]?.price)
                setPBasePrice(planList[2]?.price)
                setEBasePrice(planList[3]?.price)
            }
            setPlanTypeUI(
                [
                    { "planTypeName": "Monthly", "planTypePrice": "", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" },
                    { "planTypeName": "Quaterly", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Yearly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }
                ]
            )
            setExtraOfferFree('No Free Setup')
            setExtraOfferGrowth('No Free Setup')
            setExtraOfferPro('Free Setup')
            setExtraOfferEnterprise('Free Setup + Guidance')
        }
        if (planType === "quarterly") {
            let quarterly = 3
            calculatePrice(quarterly, growthQPercentage, proQPercentage, enterpriseQPercentage)
            if (currencyVal) {
                setPlanPrice({ "free": Math.floor(f / usdVal), "growth": Math.floor(g / usdVal), "pro": Math.floor(p / usdVal), "enterprise": Math.floor(e / usdVal) })
                setGBasePrice(Math.floor((planList[1]?.price * 3) / usdVal))
                setPBasePrice(Math.floor((planList[2]?.price * 3) / usdVal))
                setEBasePrice(Math.floor((planList[3]?.price * 3) / usdVal))
            } else {
                setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
                setGBasePrice(planList[1]?.price * 3)
                setPBasePrice(planList[2]?.price * 3)
                setEBasePrice(planList[3]?.price * 3)
            }
            setPlanTypeUI(
                [
                    { "planTypeName": "Monthly", "planTypePrice": "", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Quaterly", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" },
                    { "planTypeName": "Yearly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }
                ]
            )
            setExtraOfferFree('No Free Setup')
            setExtraOfferGrowth('Free Setup + Guidance')
            setExtraOfferPro('Free Setup + Guidance')
            setExtraOfferEnterprise('Free Setup + Guidance')

        }
        if (planType === "yearly") {
            let yearly = 12
            calculatePrice(yearly, growthYPercentage, proYPercentage, enterpriseYPercentage)
            if (currencyVal) {
                setPlanPrice({ "free": Math.floor(f / usdVal), "growth": Math.floor(g / usdVal), "pro": Math.floor(p / usdVal), "enterprise": Math.floor(e / usdVal) })
                setGBasePrice(Math.floor((planList[1]?.price * 12) / usdVal))
                setPBasePrice(Math.floor((planList[2]?.price * 12) / usdVal))
                setEBasePrice(Math.floor((planList[3]?.price * 12) / usdVal))
            } else {
                setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
                setGBasePrice(planList[1]?.price * 12)
                setPBasePrice(planList[2]?.price * 12)
                setEBasePrice(planList[3]?.price * 12)
            }
            setPlanTypeUI(
                [
                    { "planTypeName": "Monthly", "planTypePrice": "", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Quaterly", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Yearly", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" }
                ]
            )
            setExtraOfferFree('No Free Setup')
            setExtraOfferGrowth('Free Setup + Guidance')
            setExtraOfferPro('Free Setup + Guidance + Assistance')
            setExtraOfferEnterprise('Free Setup + Guidance + Assistance')
        }
    }

    useEffect(() => { handlePlanPrice() }, [planType, currencyVal, handlePlanPrice])

    const getPlanList = async () => {
        let f = 0
        let g = 0
        let p = 0
        let e = 0

        let monVal = 12
        try {
            const response = await fetchPlanListOpen()
            if (response.status === 200) {
                response.data.data?.map((v) => {
                    if (v._id === "Primary") {
                        setPlanList(v.plans)
                        g = Math.floor((v.plans[1]?.price * monVal) - ((v.plans[1]?.price * monVal * (v.plans[1]?.percentageM + v.plans[1]?.percentageS)) / 100))
                        p = Math.floor((v.plans[2]?.price * monVal) - ((v.plans[2]?.price * monVal * (v.plans[2]?.percentageM + v.plans[2]?.percentageS)) / 100))
                        e = Math.floor((v.plans[3]?.price * monVal) - ((v.plans[3]?.price * monVal * (v.plans[3]?.percentageM + v.plans[3]?.percentageS)) / 100))


                        console.log("price", "g:" + g, "p:" + p, "e:" + e)

                        setPlanPrice({ "free": f, "growth": g, "pro": p, "enterprise": e })
                    }
                })
            }
        } catch (error) { }
    }
    useEffect(() => { getPlanList() }, [])

    const [openLoginSignupDialog, setOpenLoginSignupDialog] = useState({ open: false, onClose: null })
    const openLoginSignupDialogBox = (purpose) => setOpenLoginSignupDialog({ open: true, purpose: purpose, onClose: () => setOpenLoginSignupDialog(false) })

    const [openPricingFeatureDialog, setOpenPricingFeatureDialog] = useState({ open: false, onClose: null })
    const openPricingFeatureDialogBox = (title, message) => setOpenPricingFeatureDialog({ open: true, title: title, message: message, onClose: () => setOpenPricingFeatureDialog(false) })
    const pricingFeatureMessage = "<p><ul><li><b>Setup Service</b>: Our team will come on a video call to onboard you successfully and make your number live for use.</li></br><li><b>Guidance</b>: We will guide you on how to use WhatsApp Business manager, creating a template and full overview of our dashboard.</li></br><li><b>Assistance</b>: We will give full assistance in making your chatbot, running broadcasts, and resolving issues over the video call.</li></ul></p>"


    const openURL = (url) => {
        localStorage.setItem('planPageTab', 'upgrage_plan')
        history.push(url)
    }
    useEffect(() => { handlePlanType("yearly") }, [])
    return (
        <Container className="mt-3 mb-5 pt-4 pricing">
            <div className="pt-4 pb-5 top-bar">
                <h1 className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "45px" }}>
                    Grow your business with Us,<br />Start Now
                </h1>
                <h5 className="text-center" style={{ color: WtColor.TextMidGrey }}>With the most affordable pricing, we are highly preferred by startups</h5>
            </div>

            <Row>
                <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}></Col>
                <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                    <div className="tips" style={{ display: 'flex', background: WtColor.NeonGreenColor, borderRadius: WtDimen.borderRadius }}>
                        <div>
                            <Player autoplay loop src="https://assets3.lottiefiles.com/packages/lf20_x3iibsyx.json" className="items-lottie" ></Player>
                        </div>
                        <div>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: '13px', marginTop: '12px' }}>Give your business a new boost from this offer. Grab it Now </p>
                        </div>
                    </div>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}></Col>
            </Row>


            {/* Monthly - Quaterly - Yearly - Currency Section */}
            <Container className='planTypeUI mt-3' fluid>
                <Row className="mt-4 ">
                    <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className="hand">
                        <p className="text-center pTag" style={{ fontWeight: "bold" }}>
                            <span onClick={() => handlePlanType("monthly")} style={{ color: planTypeUI[0].planTypeColor, background: planTypeUI[0].planTypeBackground, paddingLeft: "24px", paddingRight: "24px", paddingTop: "6px", paddingBottom: "6px", border: "2px solid #028425", borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px" }}>
                                <span className="pe-2">{planTypeUI[0].planTypeName}</span>
                                {planList[1]?.percentageM !== 0 && <span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "13px", borderRadius: "20px" }}>Save {planList[1]?.percentageM}%</span>}
                            </span>
                            <span onClick={() => handlePlanType("quarterly")} style={{ color: planTypeUI[1].planTypeColor, background: planTypeUI[1].planTypeBackground, paddingLeft: "24px", paddingRight: "24px", paddingTop: "6px", paddingBottom: "6px", border: "2px solid #028425", borderLeft: "2px solid " + WtColor.BaseColor, borderRight: "2px solid " + WtColor.BaseColor }}>
                                <span className="pe-2">{planTypeUI[1].planTypeName}</span>
                                {planList[1]?.percentageQ !== 0 && <span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "13px", borderRadius: "20px" }}>Save {planList[1]?.percentageQ}%</span>}
                            </span>
                            <span onClick={() => handlePlanType("yearly")} style={{ color: planTypeUI[2].planTypeColor, background: planTypeUI[2].planTypeBackground, paddingLeft: "24px", paddingRight: "24px", paddingTop: "6px", paddingBottom: "6px", border: "2px solid #028425", borderTopRightRadius: "15px", borderBottomRightRadius: "15px" }}>
                                <span className="pe-2">{planTypeUI[2].planTypeName}</span>
                                {planList[1]?.percentageY && <span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "13px", borderRadius: "20px" }}>Save {planList[1]?.percentageY}%</span>}
                            </span>
                        </p>
                        <p className="text-center mt-4" style={{ display: whichCountry === 'india' ? 'block' : 'none', fontWeight: "bold", marginTop: "-8px" }}>
                            <span>INR (₹)</span>&nbsp;<Switch checked={currencyVal} onChange={handleCurrencyVal} />&nbsp;<span>USD ($)</span>
                        </p>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                </Row>
            </Container>
            {/* End of Monthly - Quaterly - Yearly - Currency Section */}


            {/* Main Plan Section */}
            <div className='mt-4 mb-5'>
                <Row>
                    <Col className="free_plan" xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div style={{ border: "1px solid " + WtColor.LineColor, borderRadius: "10px" }}>
                            <div style={{ background: WtColor.NeonGreenColor, borderTopLeftRadius: "9px", borderTopRightRadius: "9px" }}>
                                <p className='text-center' style={{ color: WtColor.TextMidGrey, paddingTop: "4px", paddingBottom: "4px" }}>Best for starting</p>
                            </div>
                            <div className="p-2">
                                <h1 className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px" }}>{planList[0]?.name}</h1>
                                <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "25px" }}>{currencyVal ? "$" : "₹"}{planPrice.free}</p>
                                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}>&nbsp;</p>
                                <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>&nbsp;</p>
                                <p className="text-center"><small style={{ fontSize: '12px', background: WtColor.LineColor, color: WtColor.TextDarkColor, borderTopLeftRadius: WtDimen.borderRadius, borderTopRightRadius: WtDimen.borderRadius }} className="ps-3 pe-3 pt-1 pb-2">{extraOfferFree} <Info onClick={() => openPricingFeatureDialogBox("Info", pricingFeatureMessage)} style={{ color: '#fff', fontSize: '14px' }} /></small></p>
                                <hr style={{ marginTop: '-10px' }} />
                                <div className='ps-3'>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chats</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Agents (1)</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Quick Reply</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Canned Response</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Labels (3)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Custom Fields (2)</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Event Hooks</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Broadcast (5)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chatbots (1)</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Analytics</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Intergration</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Developer API Access</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Email Support</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> WhatsApp Support</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Advance WTB Training</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Immediate Support Available</p>
                                </div>
                                <div>
                                    <p className="text-center">
                                        <button onClick={() => LoginValidate() ? openURL(WtUrl.paymentPlanSubscriptionUrl) : openLoginSignupDialogBox('free')} style={{ background: WtColor.BaseColor }} className="btn btn-sm text-white rounded-3 pe-4 ps-4">Get Started</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="growth_plan" xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div style={{ border: "1px solid " + WtColor.LineColor, borderRadius: "10px" }}>
                            <div style={{ background: WtColor.NeonGreenColor, borderTopLeftRadius: "9px", borderTopRightRadius: "9px" }}>
                                <p className='text-center' style={{ color: WtColor.TextMidGrey, paddingTop: "4px", paddingBottom: "4px" }}>Best for small teams</p>
                            </div>
                            <div className="p-2">
                                <h1 className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px" }}>{planList[1]?.name}</h1>
                                <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "25px" }}>{currencyVal ? "$" : "₹"}{planPrice.growth} {planList[1]?.percentageS !== 0 && <span style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[1]?.percentageS}% OFF</span>}</p>
                                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}><del>{currencyVal ? "$" : "₹"}{gBasePrice}</del></p>
                                <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>
                                    {planType === "monthly" ? "" : currencyVal ? "$" : "₹"}
                                    {
                                        planType === "quarterly" ? Math.floor(planPrice.growth / 3) + "/month" : ""
                                    }
                                    {
                                        planType === "yearly" ? Math.floor(planPrice.growth / 12) + "/month" : ""
                                    }
                                    &nbsp;
                                </p>
                                <p className="text-center"><small style={{ fontSize: '12px', background: extraOfferGrowth === 'No Free Setup' ? WtColor.LineColor : WtColor.BaseColor, color: extraOfferGrowth === 'No Free Setup' ? WtColor.TextDarkColor : '#fff', borderTopLeftRadius: WtDimen.borderRadius, borderTopRightRadius: WtDimen.borderRadius }} className="ps-3 pe-3 pt-1 pb-2">{extraOfferGrowth}  <Info onClick={() => openPricingFeatureDialogBox("Info", pricingFeatureMessage)} style={{ color: '#fff', fontSize: '14px' }} /></small></p>
                                <hr style={{ marginTop: '-10px' }} />
                                <div className='ps-3'>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chats</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Agents (5)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Quick Reply</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Canned Response</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Labels (10)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Custom Fields (10)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Event Hooks (10)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Broadcast (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chatbots (10)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Analytics</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Intergration</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Developer API Access</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Email Support</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> WhatsApp Support</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Advance WTB Training</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} /> Immediate Support Available</p>
                                </div>
                                <div>
                                    <p className="text-center">
                                        <button onClick={() => LoginValidate() ? openURL(WtUrl.paymentPlanSubscriptionUrl) : openLoginSignupDialogBox('growth')} style={{ background: WtColor.BaseColor }} className="btn btn-sm text-white rounded-3 pe-4 ps-4">Buy Now</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="pro_plan" xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div style={{ border: "1px solid " + WtColor.LineColor, borderRadius: "10px" }}>
                            <div style={{ background: WtColor.NeonGreenColor, borderTopLeftRadius: "9px", borderTopRightRadius: "9px" }}>
                                <p className='text-center fw-bold' style={{ color: "#F64545", paddingTop: "4px", paddingBottom: "4px" }}>Recommended for all & you</p>
                            </div>
                            <div className="p-2">
                                <h1 className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px" }}>{planList[2]?.name}</h1>
                                <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "25px" }}>{currencyVal ? "$" : "₹"}{planPrice.pro} {planList[2]?.percentageS !== 0 && <span style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[2]?.percentageS}% OFF</span>}</p>
                                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}><del>{currencyVal ? "$" : "₹"}{pBasePrice}</del></p>
                                <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>
                                    {planType === "monthly" ? "" : currencyVal ? "$" : "₹"}
                                    {
                                        planType === "quarterly" ? Math.floor(planPrice.pro / 3) + "/month" : ""
                                    }
                                    {
                                        planType === "yearly" ? Math.floor(planPrice.pro / 12) + "/month" : ""
                                    }
                                    &nbsp;
                                </p>
                                <p className="text-center"><small style={{ fontSize: '12px', background: extraOfferPro === 'No Free Setup' ? WtColor.LineColor : WtColor.BaseColor, color: extraOfferPro === 'No Free Setup' ? WtColor.TextDarkColor : '#fff', borderTopLeftRadius: WtDimen.borderRadius, borderTopRightRadius: WtDimen.borderRadius }} className="ps-3 pe-3 pt-1 pb-2">{extraOfferPro}  <Info onClick={() => openPricingFeatureDialogBox("Info", pricingFeatureMessage)} style={{ color: '#fff', fontSize: '14px' }} /></small></p>
                                <hr style={{ marginTop: '-10px' }} />
                                <div className='ps-3'>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chats</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Agents (5)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Quick Reply</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Canned Response</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Labels (20)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Custom Fields (20)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Event Hooks (20)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Broadcast (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chatbots (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Analytics</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Intergration</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Developer API Access</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Email Support</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> WhatsApp Support</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} />  Advance WTB Training</p>
                                    <p><CloseSharp style={{ color: WtColor.RedColor }} />  Immediate Support Available</p>
                                </div>
                                <div>
                                    <p className="text-center">
                                        <button onClick={() => LoginValidate() ? openURL(WtUrl.paymentPlanSubscriptionUrl) : openLoginSignupDialogBox('pro')} style={{ background: WtColor.BaseColor }} className="btn btn-sm text-white rounded-3 pe-4 ps-4">Buy Now</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="enterprise_plan" xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div style={{ border: "1px solid " + WtColor.LineColor, borderRadius: "10px" }}>
                            <div style={{ background: WtColor.NeonGreenColor, borderTopLeftRadius: "9px", borderTopRightRadius: "9px" }}>
                                <p className='text-center' style={{ color: WtColor.TextMidGrey, paddingTop: "4px", paddingBottom: "4px" }}>Best for Enterprise</p>
                            </div>
                            <div className="p-2">
                                <h1 className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px" }}>{planList[3]?.name}</h1>
                                <p className="text-center fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "25px" }}>{currencyVal ? "$" : "₹"}{planPrice.enterprise} {planList[3]?.percentageS !== 0 && <span style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[3]?.percentageS}% OFF</span>}</p>
                                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: "24px" }}><del>{currencyVal ? "$" : "₹"}{eBasePrice}</del></p>
                                <p className="text-center" style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>
                                    {planType === "monthly" ? "" : currencyVal ? "$" : "₹"}
                                    {
                                        planType === "quarterly" ? Math.floor(planPrice.enterprise / 3) + "/month" : ""
                                    }
                                    {
                                        planType === "yearly" ? Math.floor(planPrice.enterprise / 12) + "/month" : ""
                                    }
                                    &nbsp;
                                </p>
                                <p className="text-center"><small style={{ fontSize: '12px', background: extraOfferEnterprise === 'No Free Setup' ? WtColor.LineColor : WtColor.BaseColor, color: extraOfferEnterprise === 'No Free Setup' ? WtColor.TextDarkColor : '#fff', borderTopLeftRadius: WtDimen.borderRadius, borderTopRightRadius: WtDimen.borderRadius }} className="ps-3 pe-3 pt-1 pb-2">{extraOfferEnterprise}  <Info onClick={() => openPricingFeatureDialogBox("Info", pricingFeatureMessage)} style={{ color: '#fff', fontSize: '14px' }} /></small></p>
                                <hr style={{ marginTop: '-10px' }} />
                                <div className='ps-3'>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chats</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Agents (5)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Quick Reply</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Canned Response</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Labels (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Custom Fields (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Event Hooks (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Broadcast (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Chatbots (Unlimited)</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Analytics</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Intergration</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Developer API Access</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Email Support</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> WhatsApp Support</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Advance WTB Training</p>
                                    <p><CheckSharp style={{ color: WtColor.BaseColor }} /> Immediate Support Available</p>
                                </div>
                                <div>
                                    <p className="text-center">
                                        <button onClick={() => LoginValidate() ? openURL(WtUrl.paymentPlanSubscriptionUrl) : openLoginSignupDialogBox('enterprise')} style={{ background: WtColor.BaseColor }} className="btn btn-sm text-white rounded-3 pe-4 ps-4">Buy Now</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* End of Main Plan Section */}



            <p style={{ 'color': "gray", 'fontSize': "14px" }}><span><strong>* FREE PLAN:</strong></span> 500 chats/month Free. Addition chats will cost <strong>₹0.50/chat</strong> (excluding WhatsApp Charges ) <br />
                &nbsp; <strong>GROWTH, PRO, ENTERPRISE PLAN:</strong> 1000 chats/month Free. Addition chats will cost <strong>₹0.10/chat </strong> (excluding WhatsApp Charges )<br />
                &nbsp; <b>Setup Service is available with Quarterly Plan Upgradation.</b>
            </p>


            <Accordion className="mt-5">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><RocketLaunchOutlined /> &nbsp;Check detailed Plan Comparison</Accordion.Header>
                    <Accordion.Body>
                        <div className="main-pricing">
                            <div>
                                <Row className="pt-2 pb-2 price-topbar" >
                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <div className="text-center pt-4 pb-5">
                                            <br />
                                            <span className="name0" style={{ color: WtColor.TextMidGrey, fontSize: "15px", fontWeight: "bold" }}>Features</span>
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                        <div className="text-center pt-4 pb-5 ps-2 pe-3">
                                            <br />
                                            <span className="name1 text-uppercase" style={{ color: WtColor.TextMidGrey, fontSize: "15px", fontWeight: "bold" }}>{planList[0]?.name}</span><br /><br />
                                            <span className="price" style={{ color: WtColor.TextDarkColor, fontSize: "30px", fontWeight: "bolder" }}>{currencyVal ? "$" : "₹"}{planPrice.free}</span><br />
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                        <div className="text-center pt-4 pb-5 ps-2 pe-3">
                                            <br />
                                            <span className="name2 text-uppercase" style={{ color: WtColor.TextMidGrey, fontSize: "15px", fontWeight: "bold" }} >{planList[1]?.name}</span><br /><br />
                                            <span className="price" style={{ color: WtColor.TextDarkColor, fontSize: "30px", fontWeight: "bolder" }}>{currencyVal ? "$" : "₹"}{planPrice.growth}</span><br />
                                            <span className="cut_price2" style={{ color: WtColor.TextDarkColor, fontSize: "25px", fontWeight: "lighter" }}><del>{currencyVal ? "$" : "₹"}{gBasePrice}</del></span><br />
                                            {
                                                planType === "quarterly" ? <span style={{ color: WtColor.TextDarkColor, fontSize: "18px", fontWeight: "bold" }}>{currencyVal ? "$" : "₹"}{Math.floor(planPrice.growth / 3)}/month</span> : ""
                                            }
                                            {
                                                planType === "yearly" ? <span style={{ color: WtColor.TextDarkColor, fontSize: "18px", fontWeight: "bold" }}>{currencyVal ? "$" : "₹"}{Math.floor(planPrice.growth / 12)}/month</span> : ""
                                            }
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                        <div className="text-center pt-0 pb-5 ps-2 pe-3">
                                            <span className="pop" style={{ background: "#F64545", color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "30px", paddingRight: "30px", fontSize: "15px", borderRadius: "20px" }}>Popular</span><br /><br />
                                            <span className="name3 text-uppercase" style={{ color: WtColor.TextMidGrey, fontSize: "15px", fontWeight: "bold" }} >{planList[2]?.name}</span><br /><br />
                                            <span className="price3" style={{ color: WtColor.TextDarkColor, fontSize: "30px", fontWeight: "bolder" }}>{currencyVal ? "$" : "₹"}{planPrice.pro}</span><br />
                                            <span className="cut_price3" style={{ color: WtColor.TextDarkColor, fontSize: "25px", fontWeight: "lighter" }}><del>{currencyVal ? "$" : "₹"}{pBasePrice}</del></span><br />
                                            {
                                                planType === "quarterly" ? <span style={{ color: WtColor.TextDarkColor, fontSize: "18px", fontWeight: "bold" }}>{currencyVal ? "$" : "₹"}{Math.floor(planPrice.pro / 3)}/month</span> : ""
                                            }
                                            {
                                                planType === "yearly" ? <span style={{ color: WtColor.TextDarkColor, fontSize: "18px", fontWeight: "bold" }}>{currencyVal ? "$" : "₹"}{Math.floor(planPrice.pro / 12)}/month</span> : ""
                                            }
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                        <div className="text-center pt-5 pb-5 ps-2 pe-3">
                                            <span className="name4 text-uppercase" style={{ color: WtColor.TextMidGrey, fontSize: "15px", fontWeight: "bold" }} >{planList[3]?.name}</span><br /><br />
                                            <span className="price4" style={{ color: WtColor.TextDarkColor, fontSize: "30px", fontWeight: "bolder" }}>{currencyVal ? "$" : "₹"}{planPrice.enterprise}</span><br />
                                            <span className="cut_price4" style={{ color: WtColor.TextDarkColor, fontSize: "25px", fontWeight: "lighter" }}><del>{currencyVal ? "$" : "₹"}{eBasePrice}</del></span><br />
                                            {
                                                planType === "quarterly" ? <span style={{ color: WtColor.TextDarkColor, fontSize: "18px", fontWeight: "bold" }}>{currencyVal ? "$" : "₹"}{Math.floor(planPrice.enterprise / 3)}/month</span> : ""
                                            }
                                            {
                                                planType === "yearly" ? <span style={{ color: WtColor.TextDarkColor, fontSize: "18px", fontWeight: "bold" }}>{currencyVal ? "$" : "₹"}{Math.floor(planPrice.enterprise / 12)}/month</span> : ""
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pricing-home'>
                                <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7']} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>MULTI AGENT TEAM CHAT SUPPORT</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Chats</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited*</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited*</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited*</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited*</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Agents</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>1</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>5</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>5</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>5</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Agents Assigned</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Agent Analytics</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Assign Rules</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Quick Reply</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Multiple Quick Reply</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Canned Response</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2">
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Advance Canned Response</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>CRM</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Labels</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>3</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>10</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>20</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Custom Fields</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>2</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>10</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>20</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Event Hooks</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>10</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>20</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>BROADCAST</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Broadcast</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>5</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Broadcast Segmentation</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Advance Broadcast Analytics</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Rebroadcast</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>AUTOMATION (CHATBOT + FLOWBUILDER)</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Basic Chatbot</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>1</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>10</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Advance Chatbot</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>10</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>Unlimited</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Chatbot Analytics</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>ANALYTICS</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Analytics</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>INTEGRATION</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Intergration</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Developer API Access</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Apps</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>WTB SERVICE</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Template Resources</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Chatbot Resources</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Email Support</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>WhatsApp Support</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Advance WTB Training</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Immediate Support Available</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Check style={{ color: WtColor.BaseColor }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>ADDITIONAL AGENTS</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + WtColor.LineColor }}>
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <div>
                                                            <span style={{ color: WtColor.TextMidGrey, fontSize: "18px" }}>Agents</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <Close style={{ color: WtColor.RedColor }} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>₹300 per additional agent/month</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>₹500 per additional agent/month</span>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} style={{ borderLeft: "1px solid " + WtColor.LineColor }}>
                                                        <div className="text-center">
                                                            <span>₹800 per additional agent/month</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


            <div className='mb-3'>
                <h3 style={{ color: WtColor.TextDarkColor, marginTop: "70px" }} className="text-center">Pricing Comparison</h3>
            </div>
            <div className='price_comparsion ps-3 pe-3'>
                <Row>
                    <Col className='colOne' xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} style={{ color: WtColor.TextDarkColor, fontWeight: "bold" }}>
                        <div className='p-4'>
                            <p style={{ marginBottom: "28px" }}>&nbsp;</p>
                            <p><Business /> Company</p>
                            <hr />
                            <p><ChatOutlined /> 10,000 Chats/month</p>
                            <hr />
                            <p><PersonOutlineOutlined /> Agent<br />&nbsp;</p>

                            <hr />
                            <p><ChatBubbleOutlineOutlined /> Free Chats</p>
                            <hr />
                            <p><AddCircleOutlineOutlined /> Add-ons Chats<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colTow mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: WtColor.BaseColor, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: "#EFFFED" }}>
                            <p className='text-center'>
                                <img src='https://business.whatstool.in/favicon.ico' width="40px" className='img-fluid' alt={WtTheme.SiteName} />
                            </p>
                            <p className='text-center'>WT Business</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(1938 / usdVal) : "₹" + 1938} &nbsp;&nbsp;<span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "6px", paddingRight: "6px", fontSize: "11px", borderRadius: "20px" }}>Save 60%</span></p>
                            <hr />
                            <p>5 Agents Extra - 300/Agent</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>₹0.1<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colThree mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: WtColor.TextDarkColor, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: WtColor.LineColor }}>
                            <p className='text-center'>
                                <img src='https://www.wati.io/wp-content/uploads/2020/08/cropped-logo-192x192.png' width="40px" className='img-fluid' alt={WtTheme.SiteName} />
                            </p>
                            <p className='text-center'>Wati</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(4250 / usdVal) : "₹" + 4250}</p>
                            <hr />
                            <p>5 Agents Extra - ₹1500/Agent</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>₹0.17<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colFour mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: WtColor.TextDarkColor, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: WtColor.LineColor }}>
                            <p className='text-center'>
                                <img src='https://gallabox.com/favicon-32x32.png' width="40px" className='img-fluid' alt={WtTheme.SiteName} />
                            </p>
                            <p className='text-center'>Gallabox</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(6300 / usdVal) : "₹" + 6300}</p>
                            <hr />
                            <p>5 Agents Extra - ₹500/Agent</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>₹0.50<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colFive mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: WtColor.TextDarkColor, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: WtColor.LineColor }}>
                            <p className='text-center'>
                                <img src='https://ucarecdn.com/92f70b8f-59b7-465a-91e2-aaf641536e92/' width="40px" className='img-fluid' alt={WtTheme.SiteName} />
                            </p>
                            <p className='text-center'>AiSensy</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(5950 / usdVal) : "₹" + 5950}</p>
                            <hr />
                            <p>Unlimited Agents<br />&nbsp;</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>Included with WhatsApp pricing</p>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='mt-5'>
                <h3 style={{ color: WtColor.TextDarkColor, marginTop: "100px" }}>Sample Pricing</h3>
                <p style={{ color: WtColor.TextMidGrey }}>Let's see the price of send unlimited messages to 10K unique users in a month. <br /><span className="fw-bold">Below is the WTB platform pricing as per plan (excluding WhatsApp charges)</span></p>
            </div>
            <div className=''>
                <Table className="fw-bold" responsive>
                    <thead style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>
                        <tr>
                            <th>Sample Plan<br />&nbsp;</th>
                            {/* <th className="text-center">Base Price</th> */}
                            <th className="text-center">Monthly Subscription Cost<br />&nbsp;</th><th className="text-center">Messsage Add-on Cost (10k messages)<br />&nbsp;</th><th className="text-center" style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }} >Total Cost<br /><span style={{ fontSize: "10px" }}>(excluding whatsapp charges)</span></th>
                        </tr>
                    </thead>
                    <tbody style={{ background: "#fff", fontSize: "14px" }}>
                        <tr className="hand">
                            <td>Free Plan</td>
                            {/* <td className="text-center">{currencyVal ? "$" + 0 : "₹" + 0}</td> */}
                            <td className="text-center">{currencyVal ? "$" + 0 : "₹" + 0}</td>
                            <td className="text-center">{currencyVal ? "$" + Math.floor(5000 / usdVal) : "₹" + 5000}</td>
                            <td className="text-center" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>{currencyVal ? "$" + Math.floor(5000 / usdVal) : "₹" + 5000}</td>
                        </tr>
                        <tr className="hand">
                            <td>Growth Plan</td>
                            {/* <td className="text-center">{currencyVal ? "$" + Math.floor(1249 / usdVal) : "₹" + 1249}</td> */}
                            <td className="text-center">{currencyVal ? "$" + Math.floor(999 / usdVal) : "₹" + 999}</td>
                            <td className="text-center">{currencyVal ? "$" + Math.floor(1000 / usdVal) : "₹" + 1000}</td>
                            <td className="text-center" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>{currencyVal ? "$" + Math.floor(1999 / usdVal) : "₹" + 1999}</td>
                        </tr>
                        <tr className="hand">
                            <td>Pro Plan</td>
                            {/* <td className="text-center">{currencyVal ? "$" + Math.floor(2199 / usdVal) : "₹" + 2199}</td> */}
                            <td className="text-center">{currencyVal ? "$" + Math.floor(2199 / usdVal) : "₹" + 2199}</td>
                            <td className="text-center">{currencyVal ? "$" + Math.floor(1000 / usdVal) : "₹" + 1000}</td>
                            <td className="text-center" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>{currencyVal ? "$" + Math.floor(3199 / usdVal) : "₹" + 3199}</td>
                        </tr>
                        <tr className="hand">
                            <td>Enterprise Plan</td>
                            {/* <td className="text-center">{currencyVal ? "$" + Math.floor(3999 / usdVal) : "₹" + 3999}</td> */}
                            <td className="text-center">{currencyVal ? "$" + Math.floor(3999 / usdVal) : "₹" + 3999}</td>
                            <td className="text-center">{currencyVal ? "$" + Math.floor(1000 / usdVal) : "₹" + 1000}</td>
                            <td className="text-center" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }}>{currencyVal ? "$" + Math.floor(4999 / usdVal) : "₹" + 4999}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className='mt-5'>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Add-on in Each Plan case</Accordion.Header>
                        <Accordion.Body>
                            <ul style={{ lineHeight: "2" }}>
                                <li>1000 Unique Message /Month <b>will be free in Growth, Pro & Enterprise Plan.</b></li>
                                <li><b>Message Credits: ₹0.10 Rupees for all the extra unique conversation (Unique Conversation (BIC/UIC) he can do in a month)</b></li>
                                <li><b style={{ color: WtColor.TextMidGrey }}>For Free Plan:</b> He will be asked to recharge for sending extra message at <b style={{ color: WtColor.TextDarkColor }}>₹0.50 Rupees for all the extra unique conversation which will have all the limitation of free plan.</b></li>
                                <li><b style={{ color: WtColor.TextMidGrey }}>Agents/Team Members:</b> For each member it will cost additional as explained in the table.</li>
                                <li><b style={{ color: WtColor.ProcessingBlue }}>Channels: For every extra channel we will charge same price with 30% discount but Addons will be charged as per above rules.</b></li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className='mt-5'>
                <h3 style={{ color: WtColor.TextDarkColor, marginTop: "100px" }}>FAQs</h3>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>When I will be charged for monthly billing?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>
                                    Our billing is Monthly subscription. It starts and end with the month. You should pay before the start of the month to use for the next month. If you do recharge on mid of the month then your plan validity will increase.
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>How I will be charged if I start in mid of the month?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>Let's say you start on 10th of this month then you will be charged for remaining days of this month and the next full month. Same with all the plan.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What is the validity of my message recharge?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>Message recharge is valid for lifetime.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>How is the pricing of monthly messaging calculated?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>You will be charged for unique conversation you had in a month. </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>What is the unique conversation per month?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>Let's say you are chatting with Mayank today and you chat with him again on 7th of the month and again on 10th and so on then it will be only 1 unique conversation which will be charged only 0.10 Paisa.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>I want to upgrade in the mid of the month. How to do it? When I will be charged?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>You will be charged for the add-on money you will be required to pay for upgrading for the remaining days of the month.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>I want to downgrade in the mid of the month?How to do it? When I will be charged?</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>You cannot downgrade in the mid of the month. You can request and we will downgrade in the next month</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <PricingCTA />

            <Testimonial />

            {openLoginSignupDialog.open && <LoginSignupDialog open={openLoginSignupDialog.open} purpose={openLoginSignupDialog.purpose} onClose={openLoginSignupDialog.onClose} />}
            {openPricingFeatureDialog.open && <PricingFeaturesDialog open={openPricingFeatureDialog.open} title={openPricingFeatureDialog.title} message={openPricingFeatureDialog.message} onClose={openPricingFeatureDialog.onClose} />}
        </Container >
    )
}

export const PricingCTA = () => {
    const history = useHistory()
    const openURL = (url) => { history.push(url) }
    const CreateButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, display: "inline", fontSize: "20px", width: "20%", fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    return (
        <div className="mt-5" style={{ background: WtColor.NeonGreenColor, borderRadius: "10px" }}>
            <p className='text-center fw-bold pt-5 ps-2 pe-2' style={{ fontSize: "30px", color: WtColor.TextDarkColor }}>Many have utlised WhatsApp and growing with it</p>
            <p className="text-center ps-5 pe-5  mt-4"><img src={WtTheme.ImageBaseUrl + "/clients.png"} className="img-fluid w-75" alt={WtTheme.SiteName} /></p>
            <p className='text-center fw-bold mt-5' style={{ color: WtColor.BaseColor, fontSize: "20px" }}>You can do it too with zero cost</p>
            <p className="text-center mt-4 pb-5">
                <button style={CreateButtonStyle} onClick={() => openURL("/signup")} className="btn btn-sm me-3 rounded-3 pt-3 pb-3 theme_bg text-white">Start Now</button>
            </p>
        </div>
    )
}