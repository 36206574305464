/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react'
import { createCampaignV2, fetchAllAttributesAPI, fetchAllLabelsAPI, fetchChannels, fetchContactsV2API, fetchListAttributesAPI } from '../../controller/APIs'
import BroadcastContext from './broadcastContext'
import { useHistory } from "react-router-dom"
import moment from 'moment'

const BroadcastState = (props) => {
    // Loader Handling
    const [isLoading, setIsLoading] = useState(undefined)
    const handleWTLoader = (val) => setIsLoading(val)
    // End of Loader Handling


    const history = useHistory()
    // Main Broadcast UI Work
    const [mainBroadcastUI, setMainBroadcastUI] = useState("create_campaign") // create_campaign || contact_selection || contact_list
    const updateMainBroadcastUI = useCallback((ui) => { setMainBroadcastUI(ui) }, [])
    // End of Main Broadcast UI Work


    // Handle Contacts Selection Tabs
    const [contactSelectionUITab, setContactSelectionUITab] = useState('import_new_contacts')
    const handleContactSelectionTab = (e) => setContactSelectionUITab(e)
    // End of Handle Contacts Selection Tabs


    // Campaign Name Work
    const [campaignName, setCampaignName] = useState("Campaign_" + moment().format('DD_MM_YYYY_h:mm_a'))
    const updateCampaignName = useCallback((name) => { setCampaignName(name) }, [])
    // End of Campaign Name Work


    // Broadcast Steps Work
    const [stepOneStatus, setStepOneStatus] = useState('active')
    const [stepTwoStatus, setStepTwoStatus] = useState('disabled')
    const [stepThreeStatus, setStepThreeStatus] = useState('disabled')
    const [confirmationDia, setConfirmationDia] = useState(false)
    // End of Broadcast Steps Work


    // Normal Broadcast Flow Save Button
    const [buttonActive, setButtonActive] = useState(false)
    // End of Normal Broadcast Flow Save Button


    // Main Variables
    const [channelName, setChannelName] = useState("Select Channel")

    const [templateName, setTemplateName] = useState('Select Template')
    const [contactCount, setContactCount] = useState('Select Contact')
    const [selectedContacts, setSelectedContacts] = useState([])
    const [selectCSV, setSelectCSV] = useState({})

    const [wholeTemplate, setWholeTemplate] = useState('')
    const [templateNamespace, setTemplateNamespace] = useState('')
    const [templateLanguage, setTemplateLanguage] = useState('')
    const [templateVarNames, setTemplateVarNames] = useState('')
    const [templateVarCount, setTemplateVarCount] = useState('')
    const [tempHeaderType, setTempHeaderType] = useState('')
    const [mediaType, setMediaType] = useState('')
    const [mediaLink, setMediaLink] = useState('')

    const [channelId, setChannelId] = useState(0)
    const updateChanelSelection = (val) => {
        const splitter = val.split(" ")
        const channel_id = splitter[splitter.length - 1]
        splitter.pop()
        const channel_name = splitter.join(' ')

        setChannelName(channel_name)
        setChannelId(channel_id)
        setStepOneStatus('completed')
        setStepTwoStatus('active')
    }
    // End of Main Variables


    // Channel Fetch Work
    const [channelLs, setChannelLs] = useState([])
    const getChannelLs = async () => {
        try {
            const response = await fetchChannels()
            if (response.status === 200) {
                setChannelLs(response.data.data)
            }
        } catch (error) { }
    }
    // End of Channel Fetch Work


    const [filterAppliedStatus, setFilterAppliedStatus] = useState(false)

    // Label Work
    const [labelLs, setLabelLs] = useState([])
    const getLabels = async () => {
        try {
            const response = await fetchAllLabelsAPI()
            if (response.status === 200) {
                setLabelLs(response.data.data)
            }
        } catch (error) { }
    }

    const [labelFields, setLabelFields] = useState([{ l_id: "", l_action: "" }])
    const addLabelFields = () => {
        let newField = { l_id: "", l_action: "" }
        setLabelFields([...labelFields, newField])
    }
    const removeLabelFields = (index) => {
        let data = [...labelFields]
        data.splice(index, 1)
        setLabelFields(data)
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateLabelFieldsData = (index, event) => {
        let data = [...labelFields]
        data[index][event.target.name] = event.target.value
        setLabelFields(data)
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetLabelFieldData = () => {
        setLabelFields([{ l_id: "", l_action: "" }])
    }
    // End of Label Work


    // Custom Field
    const [customFieldLs, setCustomFieldLs] = useState([])
    const getCustomFieldLs = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) {
                setCustomFieldLs(response.data.data)
            }
        } catch (error) { }
    }
    const [listCustomField, setListCustomField] = useState([])
    const getListCustomFieldLs = async (url) => {
        try {
            const response = await fetchListAttributesAPI(url)
            if (response.status === 200) {
                setListCustomField(response.data.data[0].list)
            }
        } catch (error) { }
    }

    const [customFields, setCustomFields] = useState([{ cf_id: "", cf_type: "", cf_key: "", cf_operator: "", cf_value: "" }])
    const addCustomFields = () => {
        let newField = { cf_id: "", cf_type: "", cf_key: "", cf_operator: "", cf_value: "" }
        setCustomFields([...customFields, newField])
    }
    const removeCustomFields = (index) => {
        let data = [...customFields]
        data.splice(index, 1)
        setCustomFields(data)
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateCustomFieldsData = (index, event) => {
        let data = [...customFields]
        let info = []
        if (event.target.name === 'cf_id') {
            info = event.target.value.split(',')
            data[index]['cf_id'] = info[0]
            data[index]['cf_type'] = info[1]
            data[index]['cf_key'] = info[2]

            if (info[1] === 'list') getListCustomFieldLs('?_id=' + info[0])
        } else {
            data[index][event.target.name] = event.target.value
        }
        setCustomFields(data)
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetCustomFieldData = () => {
        setCustomFields([{ cf_id: "", cf_type: "", cf_key: "", cf_operator: "", cf_value: "" }])
    }
    // End of Custom Field


    // Created At
    const dataStringConvertor = (val) => {
        let d1 = new Date(val)
        let d2 = d1.getTime()
        return d2
    }
    const [finalCreatedFrom, setFinalCreatedFrom] = useState('')
    const [finalCreatedTo, setFinalCreatedTo] = useState('')
    const [createAtOpt, setCreatedAtOpt] = useState('none')
    const [createdAtFrom, setCreatedAtFrom] = useState('')
    const [createdAtTo, setCreatedAtTo] = useState('')
    const updateCreatedAtOpt = (e) => {
        setCreatedAtOpt(e.target.value)
        if (e.target.value === "today") {
            setCreatedAtFrom(moment().format('L'))
            setCreatedAtTo(moment().format('LLL'))
            setFinalCreatedFrom(dataStringConvertor(moment().format('L')))
            setFinalCreatedTo(dataStringConvertor(moment().format('LLL')))
        }
        if (e.target.value === "week") {
            setCreatedAtFrom(moment().subtract(7, 'days').calendar())
            setCreatedAtTo(moment().format('L'))
            setFinalCreatedFrom(dataStringConvertor(moment().subtract(7, 'days').calendar()))
            setFinalCreatedTo(dataStringConvertor(moment().format('L')))
        }
        if (e.target.value === "month") {
            setCreatedAtFrom(moment().subtract(30, 'days').calendar())
            setCreatedAtTo(moment().format('L'))
            setFinalCreatedFrom(dataStringConvertor(moment().subtract(30, 'days').calendar()))
            setFinalCreatedTo(dataStringConvertor(moment().format('L')))
        }
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateCreatedAtOptFrom = (d) => {
        setCreatedAtFrom(d)
        setFinalCreatedFrom(dataStringConvertor(d))
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateCreatedAtOptTo = (d) => {
        setCreatedAtTo(d)
        setFinalCreatedTo(dataStringConvertor(d))
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetCreatedAtData = () => {
        setFinalCreatedFrom('')
        setFinalCreatedTo('')
        setCreatedAtOpt('none')
        setCreatedAtFrom('')
        setCreatedAtTo('')
    }
    // End of Created At


    // Last Seen
    const [finalLastSeenFrom, setFinalLastSeenFrom] = useState('')
    const [finalLastSeenTo, setFinalLastSeenTo] = useState('')
    const [lastSeenOpt, setLastSeenOpt] = useState('none')
    const [lastSeenFrom, setLastSeenFrom] = useState('')
    const [lastSeenTo, setLastSeenTo] = useState('')
    const updateLastSeenOpt = (e) => {
        setLastSeenOpt(e.target.value)
        if (e.target.value === "today") {
            setLastSeenFrom(moment().format('L'))
            setLastSeenTo(moment().format('LLL'))
            setFinalLastSeenFrom(dataStringConvertor(moment().format('L')))
            setFinalLastSeenTo(dataStringConvertor(moment().format('LLL')))
        }
        if (e.target.value === "week") {
            setLastSeenFrom(moment().subtract(7, 'days').calendar())
            setLastSeenTo(moment().format('L'))
            setFinalLastSeenFrom(dataStringConvertor(moment().subtract(7, 'days').calendar()))
            setFinalLastSeenTo(dataStringConvertor(moment().format('L')))
        }
        if (e.target.value === "month") {
            setLastSeenFrom(moment().subtract(30, 'days').calendar())
            setLastSeenTo(moment().format('L'))
            setFinalLastSeenFrom(dataStringConvertor(moment().subtract(30, 'days').calendar()))
            setFinalLastSeenTo(dataStringConvertor(moment().format('L')))
        }
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateLastSeenOptFrom = (d) => {
        setLastSeenFrom(d)
        setFinalLastSeenFrom(dataStringConvertor(d))
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateLastSeenOptTo = (d) => {
        setLastSeenTo(d)
        setFinalLastSeenTo(dataStringConvertor(d))
        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetLastSeenData = () => {
        setFinalLastSeenFrom('')
        setFinalLastSeenTo('')
        setLastSeenOpt('none')
        setLastSeenFrom('')
        setLastSeenTo('')
    }
    // End of Last Seen

    // Complete Reset
    const resetAllFilter = () => {
        handleWTLoader(true)
        getContacts(1000, 0, '', '', '', '', '', '', '')
        resetLabelFieldData()
        resetCustomFieldData()
        resetCreatedAtData()
        resetLastSeenData()

        setContactLsStatus(false)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    // End of Complete Reset


    // Opted In
    const [optedInOpt, setOptedInOpt] = useState('none')
    const handleOptedInOpt = (e) => { setOptedInOpt(e.target.value) }
    // End of Opted In


    const [contactLs, setContactLs] = useState([])
    const [contactLsLen, setContactLsLen] = useState(0)
    const [contactLsStatus, setContactLsStatus] = useState(false)
    const [contactLsUI, setContactLsUI] = useState("contact_filter") // contact_filter || contact_ls_ui || contact_template_var_maping
    const getContacts = async (limit, skip, labelIdsInc, labelIdsExc, fieldList, createDateFrom, createDateTo, lastSeenFrom, lastSeenTo) => {
        handleWTLoader(true)
        try {
            let data = {
                'limit': limit,
                'skip': skip,
                'labelIdsInc': (labelIdsInc === "") ? null : labelIdsInc,
                'labelIdsExc': (labelIdsExc === "") ? null : labelIdsExc,
                'fieldList': (fieldList === "") ? null : fieldList,
                'createDateFrom': (createDateFrom === "" || createDateFrom === undefined) ? null : createDateFrom,
                'createDateTo': (createDateTo === "" || createDateTo === undefined) ? null : createDateTo,
                'lastSeenFrom': (lastSeenFrom === "" || lastSeenFrom === undefined) ? null : null,
                'lastSeenTo': (lastSeenTo === "" || lastSeenTo === undefined) ? null : null
            }
            // console.log(JSON.stringify(data))
            const response = await fetchContactsV2API(data)
            if (response.status === 200) {
                setContactLs(response.data?.data?.contacts)
                setContactLsLen(response.data?.data?.totalContacts)
                if (response.data?.data?.totalContacts === 0) { setContactLsStatus(false) } else { setContactLsStatus(true) }
                setApplyFilterStatus(false)
                setResetFilterStatus(false)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    const updateContactFilterUI = useCallback((ui) => { setContactLsUI(ui) }, [])


    const [applyFilterStatus, setApplyFilterStatus] = useState(false)
    const [resetFilterStatus, setResetFilterStatus] = useState(false)


    const [tempFieldMap, setTempFieldMap] = useState([{ fKey: '' }])
    const updateTempFieldMap = (e, i) => {
        if (e.target.value !== "") {
            let data = [...tempFieldMap]
            if (data[i] === undefined) {
                data[i] = []
            }
            data[i]['fKey'] = e.target.value
            setTempFieldMap(data)
            if (data.length === templateVarCount) setButtonActive(true)
        }
    }


    // Broadcast with Filter
    let busy = false
    const createBroadcastV2 = async (title, channel, tName, tNameSpace, tLang, tDefVar, fieldKey, labelIdsInc, labelIdsExc, fieldList, createDateFrom, createDateTo, lastSeenFrom, lastSeenTo) => {
        if (!busy) {
            busy = true
            try {
                let data = {
                    "title": title,
                    "extChannelId": channel,
                    "templateName": tName,
                    "namespace": tNameSpace,
                    "language": tLang,
                    "defaultVariables": tDefVar,
                    'labelIdsInc': (labelIdsInc === "") ? null : labelIdsInc,
                    'labelIdsExc': (labelIdsExc === "") ? null : labelIdsExc,
                    'fieldList': (fieldList === "" || fieldList.length === 0) ? null : fieldList,
                    'createDateFrom': (createDateFrom === "" || createDateFrom === undefined) ? null : createDateFrom,
                    'createDateTo': (createDateTo === "" || createDateTo === undefined) ? null : createDateTo,
                    'lastSeenFrom': (lastSeenFrom === "" || lastSeenFrom === undefined) ? null : lastSeenFrom,
                    'lastSeenTo': (lastSeenTo === "" || lastSeenTo === undefined) ? null : lastSeenTo,
                    "fieldKey": (fieldKey === "") ? null : fieldKey,
                    'headerType': (mediaType === "") ? null : mediaType,
                    'headerLink': (mediaLink === "") ? null : mediaLink
                }
                const response = await createCampaignV2(data)
                if (response.status === 201) {
                    history.push('/dashboard/broadcast')
                }
                busy = false
            } catch (error) { busy = false }
        }
    }
    // End of Broadcast with Filter
    return (
        <BroadcastContext.Provider value={{
            isLoading, handleWTLoader,
            mainBroadcastUI, updateMainBroadcastUI,
            buttonActive, setButtonActive,

            // Main Variable
            campaignName, updateCampaignName,
            templateName, setTemplateName,
            contactCount, setContactCount,
            selectedContacts, setSelectedContacts,
            selectCSV, setSelectCSV,
            wholeTemplate, setWholeTemplate,
            templateNamespace, setTemplateNamespace,
            templateLanguage, setTemplateLanguage,
            templateVarNames, setTemplateVarNames,
            templateVarCount, setTemplateVarCount,
            tempHeaderType, setTempHeaderType,
            mediaType, setMediaType,
            mediaLink, setMediaLink,
            // End of Main Variable

            stepOneStatus, setStepOneStatus, stepTwoStatus, setStepTwoStatus, stepThreeStatus, setStepThreeStatus, confirmationDia, setConfirmationDia,
            channelLs, getChannelLs,
            channelName,
            channelId, updateChanelSelection,

            contactSelectionUITab, handleContactSelectionTab,

            filterAppliedStatus, setFilterAppliedStatus,

            labelLs, getLabels, labelFields, addLabelFields, removeLabelFields, updateLabelFieldsData, resetLabelFieldData,

            customFieldLs, getCustomFieldLs, listCustomField, customFields, addCustomFields, removeCustomFields, updateCustomFieldsData, resetCustomFieldData,

            createAtOpt, updateCreatedAtOpt, createdAtFrom, updateCreatedAtOptFrom, createdAtTo, updateCreatedAtOptTo, finalCreatedFrom, finalCreatedTo, resetCreatedAtData,

            lastSeenOpt, updateLastSeenOpt, lastSeenFrom, updateLastSeenOptFrom, lastSeenTo, updateLastSeenOptTo, finalLastSeenFrom, finalLastSeenTo, resetLastSeenData,

            resetAllFilter,

            optedInOpt, handleOptedInOpt,

            getContacts, contactLs, contactLsLen, contactLsUI, updateContactFilterUI,

            contactLsStatus, applyFilterStatus, resetFilterStatus, setApplyFilterStatus, setResetFilterStatus,

            tempFieldMap, updateTempFieldMap,

            createBroadcastV2
        }}>
            {props.children}
        </BroadcastContext.Provider>
    )
}
export default BroadcastState