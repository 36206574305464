/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react"
import { FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from "@mui/material"
import { Close, ReplayOutlined } from "@mui/icons-material"
import contactsContext from "../../context/contacts/contactsContext"
import { WtColor } from "../../constants/colours"
import { Col, Container, Row } from "react-bootstrap"

export const ContactsFilter = () => {
    const userInfoUISize = useContext(contactsContext)
    const userInfoUIVisibility = useContext(contactsContext)
    const handleUI_On_Off = useContext(contactsContext)



    // Contacts List Work
    const getContacts = useContext(contactsContext) // main get contacts caller
    const getContactsAppliedFilter = useContext(contactsContext) // filtered get contacts caller
    const contactLsLen = useContext(contactsContext)
    // End of Contact List Work

    const setFilterAppliedStatus = useContext(contactsContext)

    // Label Work
    const labelLs = useContext(contactsContext)
    const getLabels = useContext(contactsContext)
    const labelFields = useContext(contactsContext)
    const addLabelFields = useContext(contactsContext)
    const removeLabelFields = useContext(contactsContext)
    const updateLabelFieldsData = useContext(contactsContext)
    const resetLabelFieldData = useContext(contactsContext)
    // End of Label Work

    // Custom Field Work
    const customFieldLs = useContext(contactsContext)
    const getCustomFieldLs = useContext(contactsContext)
    const listCustomField = useContext(contactsContext)
    const customFields = useContext(contactsContext)
    const addCustomFields = useContext(contactsContext)
    const removeCustomFields = useContext(contactsContext)
    const updateCustomFieldsData = useContext(contactsContext)
    const resetCustomFieldData = useContext(contactsContext)
    // End of Custom Field Work

    // Created At Work
    const createAtOpt = useContext(contactsContext)
    const updateCreatedAtOpt = useContext(contactsContext)
    const createdAtFrom = useContext(contactsContext)
    const updateCreatedAtOptFrom = useContext(contactsContext)
    const createdAtTo = useContext(contactsContext)
    const updateCreatedAtOptTo = useContext(contactsContext)
    const finalCreatedFrom = useContext(contactsContext)
    const finalCreatedTo = useContext(contactsContext)
    const resetCreatedAtData = useContext(contactsContext)
    // End of Created At Work

    // Last Seen Work
    const lastSeenOpt = useContext(contactsContext)
    const updateLastSeenOpt = useContext(contactsContext)
    const lastSeenFrom = useContext(contactsContext)
    const updateLastSeenOptFrom = useContext(contactsContext)
    const lastSeenTo = useContext(contactsContext)
    const updateLastSeenOptTo = useContext(contactsContext)
    const finalLastSeenFrom = useContext(contactsContext)
    const finalLastSeenTo = useContext(contactsContext)
    const resetLastSeenData = useContext(contactsContext)
    // End of Last Seen Work

    // Opted In
    const optedInOpt = useContext(contactsContext)
    const handleOptedInOpt = useContext(contactsContext)
    // End of Opted In

    // Apply Filter
    const applyFilterStatus = useContext(contactsContext)
    // const resetFilterStatus = useContext(contactsContext)
    const setApplyFilterStatus = useContext(contactsContext)
    const setResetFilterStatus = useContext(contactsContext)
    // End of Apply Filter

    const resetAllFilter = useContext(contactsContext)



    const ValidButtonStyle = { background: WtColor.LineColor, color: WtColor.BaseColor, fontWeight: "bold", textTransform: "none", boxShadow: "none" }

    // Label Handling Work
    let discardLabelsLs = []
    let labIdInclude = labelFields.labelFields?.filter((v) => { return v['l_action'] === 'include' }).map((v) => {
        discardLabelsLs.push(v['l_id'])
        return v['l_id']
    })
    let labIdExclude = labelFields.labelFields?.filter((v) => { return v['l_action'] === 'exclude' }).map((v) => {
        discardLabelsLs.push(v['l_id'])
        return v['l_id']
    })
    // Label Handling Work

    // Custom Field Handling Work
    let discardCustomFieldLs = []
    let customFieldList = customFields.customFields?.filter((v) => { return v['cf_id'] !== '' && v['cf_type'] !== '' && v['cf_key'] !== '' && v['cf_operator'] !== '' && v['cf_value'] !== '' }).map((v) => {
        discardCustomFieldLs.push(v['cf_key'])
        return v
    })
    // console.log("CF:" + JSON.stringify(customFieldList))
    // End of Custom Field Handling Work


    // Apply Filter Work
    const applyFilter = () => {
        getContactsAppliedFilter.getContactsAppliedFilter(1000, 0, labIdInclude.join(","), labIdExclude.join(","), customFieldList, finalCreatedFrom.finalCreatedFrom, finalCreatedTo.finalCreatedTo, finalLastSeenFrom.finalLastSeenFrom, finalLastSeenTo.finalLastSeenTo)
        setApplyFilterStatus.setApplyFilterStatus(false)
        setResetFilterStatus.setResetFilterStatus(false)
        setFilterAppliedStatus.setFilterAppliedStatus(true)
    }
    // End of Apply Filter Work

    // Reset Filter Work
    const applyResetFilter = () => {
        resetAllFilter.resetAllFilter()
        getContacts.getContacts(0)
        setApplyFilterStatus.setApplyFilterStatus(false)
    }
    // End of Reset Filter Work


    useEffect(() => {
        getLabels.getLabels()
        getCustomFieldLs.getCustomFieldLs()
    }, [])

    const form_item_ui_style = { outline: "none", marginRight: "10px", borderRadius: "5px", padding: "4px", border: "1px solid " + WtColor.CloudyGrey, color: WtColor.TextMidGrey }
    const RadioBtnUIStyle = { color: WtColor.TextDarkColor, '&.Mui-checked': { color: WtColor.BaseColor } }
    return (
        <div className="bg-white app_info_item" style={{ flex: userInfoUISize.userInfoUISize, display: userInfoUIVisibility.userInfoUIVisibility, borderLeft: "1px solid " + WtColor.LineColor }}>
            <div className="pt-3 pe-3 pb-3" style={{ display: "flex", borderBottom: "1px solid " + WtColor.LineColor }}>
                <div style={{ flex: "1", marginTop: '6px', color: WtColor.TextLightGrey }} className="ms-3">Apply Filter to your contacts&nbsp;</div>
                <div><IconButton onClick={() => handleUI_On_Off.handleUI_On_Off("off")}><Close /></IconButton></div>
            </div>

            <Container fluid>
                <div className="mt-2 ms-3" style={{ display: "flex" }}>
                    <p style={{ flex: "1", color: WtColor.TextLightGrey }}>Total Selected Contacts: {contactLsLen.contactLsLen}&nbsp; </p>
                    <div style={{ display: "flex", marginRight: "30px" }}>
                        <div>
                            <button style={ValidButtonStyle} onClick={() => applyResetFilter()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey">Reset</button>
                        </div>
                        <div>
                            <button disabled={applyFilterStatus.applyFilterStatus ? false : true} style={ValidButtonStyle} onClick={() => applyFilter()} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white theme_grey">Apply Filter</button>
                        </div>
                    </div>
                </div>
                <Container className='ms-2 campaignList' style={{ height: "550px" }} fluid>
                    <Row className="label_work">
                        <Col>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Labels&nbsp;
                                {
                                    discardLabelsLs?.length !== 0 ?
                                        <Tooltip title="Reset Label Filter" placement='bottom'>
                                            <ReplayOutlined onClick={() => resetLabelFieldData.resetLabelFieldData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                        </Tooltip> : ''
                                }
                            </p>
                            <div>
                                {
                                    labelFields.labelFields?.map((v, i) => {
                                        return (
                                            <div style={{ display: "flex", fontSize: "16px", marginBottom: "6px" }} key={i}>
                                                <select value={v.l_id} name="l_id" onChange={(e) => updateLabelFieldsData.updateLabelFieldsData(i, e)} style={form_item_ui_style}>
                                                    <option value="">Select Label</option>
                                                    {
                                                        labelLs.labelLs
                                                            .map((v1, i1) => {
                                                                let showStatus = discardLabelsLs?.includes(v1._id)
                                                                return (
                                                                    <option style={{ display: showStatus ? 'none' : 'block' }} value={v1._id} key={i1}>{v1.label_name}</option>
                                                                )
                                                            })
                                                    }
                                                </select>
                                                <select value={v.l_action} name="l_action" onChange={(e) => updateLabelFieldsData.updateLabelFieldsData(i, e)} style={form_item_ui_style}>
                                                    <option value="">Select Action</option>
                                                    <option value="include">Include This</option>
                                                    <option value="exclude">Exclude This</option>
                                                </select>
                                                <Close style={{ color: "#fff", display: i === 0 ? "block" : "none" }} />
                                                <Close onClick={() => removeLabelFields.removeLabelFields(i)} className='fw-bold hand' style={{ display: i === 0 ? "none" : "block", color: WtColor.RedColor, marginTop: "6px" }} />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    discardLabelsLs.length === labelFields.labelFields.length ?
                                        <p onClick={() => addLabelFields.addLabelFields()} className='hand' style={{ color: WtColor.BaseColor }}>+ Add new label</p> : ''
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="custom_field_work mt-3">
                        <Col>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Custom Fields&nbsp;
                                {
                                    discardCustomFieldLs?.length !== 0 ?
                                        <Tooltip title="Reset Custom Field Filter" placement='bottom'>
                                            <ReplayOutlined onClick={() => resetCustomFieldData.resetCustomFieldData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                        </Tooltip> : ''
                                }
                            </p>
                            <div>
                                {
                                    customFields.customFields?.map((v, i) => {
                                        return (
                                            <div style={{ display: "flex", fontSize: "16px", marginBottom: "6px" }} key={i}>
                                                {/* Select Custom Field Work */}
                                                <select value={[v.cf_id, v.cf_type, v.cf_key]} name="cf_id" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                    <option value="">Select Custom Field</option>
                                                    {
                                                        customFieldLs.customFieldLs?.map((v1, i1) => {
                                                            let showStatus = discardCustomFieldLs?.includes(v1.field_key)
                                                            return (
                                                                <option style={{ display: showStatus ? 'none' : 'block' }} value={[v1._id, v1.field_type, v1.field_key]} key={i1}>{v1.field_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {/* End of Select Custom Field Work */}

                                                {/* Select Operator Work */}
                                                {
                                                    v.cf_type === "text" || v.cf_type === "link" ?
                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                            <option value="">Select Operation</option>
                                                            <option value="contains">contains</option>
                                                            <option value="eq">equal to</option>
                                                            <option value="starts_with">starts with</option>
                                                            <option value="ends_with">ends with</option>
                                                            <option value="not_contains">not contains</option>
                                                        </select> : ""
                                                }
                                                {
                                                    v.cf_type === "number" ?
                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                            <option value="">Select Operation</option>
                                                            <option value="eq">is equal to</option>
                                                            <option value="ne">doesn't equal to</option>
                                                            <option value="contains">contains</option>
                                                            <option value="eq">=</option>
                                                            <option value="lt">&lt;</option>
                                                            <option value="lte">&lt;=</option>
                                                            <option value="gt">&gt;</option>
                                                            <option value="gte">&gt;=</option>
                                                            <option value="ne">!=</option>
                                                        </select> : ""
                                                }
                                                {
                                                    v.cf_type === "date" ?
                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                            <option value="">Select Operation</option>
                                                            <option value="eq">=</option>
                                                            <option value="lt">&lt;</option>
                                                            <option value="gt">&gt;</option>
                                                        </select> : ""
                                                }
                                                {
                                                    v.cf_type === "checkbox" || v.cf_type === "list" ?
                                                        <select value={v.cf_operator} name="cf_operator" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                            <option value="">Select Operation</option>
                                                            <option value="eq">equal to</option>
                                                            <option value="ne">not equal to</option>
                                                        </select> : ""
                                                }
                                                {/* End of Select Operator Work */}

                                                {/* Select Value Work */}
                                                {
                                                    v.cf_type === "text" ? <input defaultValue={v.cf_value} type="text" placeholder="Enter Text" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                }
                                                {
                                                    v.cf_type === "number" ? <input defaultValue={v.cf_value} type="number" placeholder="Enter Number" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                }
                                                {
                                                    v.cf_type === "link" ? <input defaultValue={v.cf_value} type="url" placeholder="Enter Link or URL" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                }
                                                {
                                                    v.cf_type === "date" ? <input defaultValue={v.cf_value} type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style} /> : ""
                                                }
                                                {
                                                    v.cf_type === "checkbox" ?
                                                        <select value={v.cf_value} name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                            <option value="">Select Value</option>
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select> : ""
                                                }
                                                {
                                                    v.cf_type === "list" ?
                                                        <select defaultValue={v.cf_value} name="cf_value" onChange={(e) => updateCustomFieldsData.updateCustomFieldsData(i, e)} style={form_item_ui_style}>
                                                            <option value="">Select Value</option>
                                                            {
                                                                listCustomField.listCustomField?.map((v1, i1) => {
                                                                    return (
                                                                        <option value={v1} key={i1}>{v1}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> : ""
                                                }
                                                {/* End of Select Value Work */}
                                                <Close style={{ color: "#fff", display: i === 0 ? "block" : "none" }} />
                                                <Close onClick={() => removeCustomFields.removeCustomFields(i)} className='fw-bold hand' style={{ display: i === 0 ? "none" : "block", color: WtColor.RedColor, marginTop: "6px" }} />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    discardCustomFieldLs.length === customFields.customFields.length ?
                                        <p onClick={() => addCustomFields.addCustomFields()} className='hand' style={{ color: WtColor.BaseColor }}>+ Add new custom field</p> : ''
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="created_at mt-3">
                        <Col>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Created At&nbsp;
                                {
                                    createAtOpt.createAtOpt !== "none" ?
                                        <Tooltip title="Reset Created At Filter" placement='bottom'>
                                            <ReplayOutlined onClick={() => resetCreatedAtData.resetCreatedAtData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                        </Tooltip> : ''
                                }
                            </p>
                            <RadioGroup row name="row-radio-buttons-group" value={createAtOpt.createAtOpt} onChange={updateCreatedAtOpt.updateCreatedAtOpt}>
                                <FormControlLabel value="none" style={{ display: "none" }} control={<Radio sx={RadioBtnUIStyle} />} label="None" />
                                <FormControlLabel value="today" control={<Radio sx={RadioBtnUIStyle} />} label="Today" />
                                <FormControlLabel value="week" control={<Radio sx={RadioBtnUIStyle} />} label="This Week" />
                                <FormControlLabel value="month" control={<Radio sx={RadioBtnUIStyle} />} label="This Month" />
                                <FormControlLabel value="custom" control={<Radio sx={RadioBtnUIStyle} />} label="Custom Range" />
                            </RadioGroup>
                            {
                                createAtOpt.createAtOpt === 'today' || createAtOpt.createAtOpt === 'week' || createAtOpt.createAtOpt === 'month' ?
                                    <div>
                                        <p style={{ color: WtColor.TextLightGrey }}>{createdAtFrom.createdAtFrom} to {createdAtTo.createdAtTo}</p>
                                    </div> : ''
                            }
                            {
                                createAtOpt.createAtOpt === 'custom' ?
                                    <div className='mt-3' style={{ display: "flex" }}>
                                        <div className="me-2">
                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>From</label>
                                            <input value={createdAtFrom.createdAtFrom} id="created_at_from" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateCreatedAtOptFrom.updateCreatedAtOptFrom(e.target.value)} style={form_item_ui_style} />
                                        </div>
                                        <div>
                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>To</label>
                                            <input value={createdAtTo.createdAtTo} id="created_at_to" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateCreatedAtOptTo.updateCreatedAtOptTo(e.target.value)} style={form_item_ui_style} />
                                        </div>
                                    </div> : ''
                            }
                        </Col>
                    </Row>
                    <Row className="last_seen_at mt-3 mb-5 pb-5">
                        <Col>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Last Seen&nbsp;
                                {
                                    lastSeenOpt.lastSeenOpt !== "none" ?
                                        <Tooltip title="Reset Last Seen Filter" placement='bottom'>
                                            <ReplayOutlined onClick={() => resetLastSeenData.resetLastSeenData()} className="hand" style={{ color: WtColor.TextLightGrey, marginTop: '-3px', fontSize: "18px" }} />
                                        </Tooltip> : ''
                                }</p>
                            <RadioGroup row name="row-radio-buttons-group" value={lastSeenOpt.lastSeenOpt} onChange={updateLastSeenOpt.updateLastSeenOpt}>
                                <FormControlLabel value="none" style={{ display: "none" }} control={<Radio sx={RadioBtnUIStyle} />} label="None" />
                                <FormControlLabel value="today" control={<Radio sx={RadioBtnUIStyle} />} label="Today" />
                                <FormControlLabel value="week" control={<Radio sx={RadioBtnUIStyle} />} label="This Week" />
                                <FormControlLabel value="month" control={<Radio sx={RadioBtnUIStyle} />} label="This Month" />
                                <FormControlLabel value="custom" control={<Radio sx={RadioBtnUIStyle} />} label="Custom Range" />
                            </RadioGroup>
                            {
                                lastSeenOpt.lastSeenOpt === 'today' || lastSeenOpt.lastSeenOpt === 'week' || lastSeenOpt.lastSeenOpt === 'month' ?
                                    <div>
                                        <p style={{ color: WtColor.TextLightGrey }}>{lastSeenFrom.lastSeenFrom} to {lastSeenTo.lastSeenTo}</p>
                                    </div> : ''
                            }
                            {
                                lastSeenOpt.lastSeenOpt === 'custom' ?
                                    <div className='mt-3' style={{ display: "flex" }}>
                                        <div className="me-2">
                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>From</label>
                                            <input value={lastSeenFrom.lastSeenFrom} id="created_at_from" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateLastSeenOptFrom.updateLastSeenOptFrom(e.target.value)} style={form_item_ui_style} />
                                        </div>
                                        <div>
                                            <label htmlFor='created_at_from' style={{ color: WtColor.TextLightGrey }}>To</label>
                                            <input value={lastSeenTo.lastSeenTo} id="created_at_to" type="date" placeholder="Enter Date" name="cf_value" onChange={(e) => updateLastSeenOptTo.updateLastSeenOptTo(e.target.value)} style={form_item_ui_style} />
                                        </div>
                                    </div> : ''
                            }
                        </Col>
                    </Row>
                    <Row className="opted_in mt-3 mb-5 pb-5" style={{ display: "none" }}>
                        <Col>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: "18px" }} className="fw-bold">Opted In</p>
                            <RadioGroup row name="row-radio-buttons-group" value={optedInOpt.optedInOpt} onChange={handleOptedInOpt.handleOptedInOpt}>
                                <FormControlLabel value="none" style={{ display: "none" }} control={<Radio sx={RadioBtnUIStyle} />} label="None" />
                                <FormControlLabel value="true" control={<Radio sx={RadioBtnUIStyle} />} label="Yes" />
                                <FormControlLabel value="false" control={<Radio sx={RadioBtnUIStyle} />} label="No" />
                                <FormControlLabel value="all" control={<Radio sx={RadioBtnUIStyle} />} label="All" />
                            </RadioGroup>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div >
    )
}