/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'


export const Templates = () => {
    const notificationBarStatus = useContext(globalContext)

    const mainStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    return (
        <div style={mainStyle}>
            <SideMenu />
            <div className="profile_menu_item bg-white">
                <div className="profile_menu">
                    <div className="profile_menu_header border-bottom ps-3 pt-3 pb-2 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor }}>Templates</h5>
                        </div>
                    </div>
                    <div className="pt-2 ps-4 pe-4">

                    </div>
                </div>
            </div>
        </div>
    )
}