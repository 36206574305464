import React, { useState, useEffect } from "react"
import { Footer, LeftNavigation, WhatsAppSideFAB } from "../controller/BaseToolUI"
import { Player } from "@lottiefiles/react-lottie-player"
import { Wtd } from "../view/Wtd/App"
import { LoginValidate } from "../controller/caller"
import { ChangePassword, EmailVerification, ForgotPassword, Login, OtpVerification, PaymentFailed, PaymentSuccess, Signup, WhatsAppVerification } from "../view/template"
import { SignupSuccess } from "../view/Registration/SignupSuccess"
import { Box } from "@mui/material"
import { Dashboard } from "../view/Dashboard/App"
import { Chat } from "../view/Chat/App"
import { WtTheme } from "../controller/theme"
import { WtString } from "../constants/strings"
import { NoChannelDialog } from "../controller/BaseTool"
import { Contacts } from "../view/Contacts/App"
import { Broadcast } from "../view/Broadcast/App"
import CreateCampaign from "../view/Broadcast/CreateCampaign"
import { ChatbotListUI } from "../view/BotEditor/ChatbotList"
import App from "../view/BotEditor/App"
import { Settings } from "../view/Settings/App"
import { BusinessInformation } from "../view/Settings/BusinessInfo"
import { Channels } from "../view/Settings/Channels"
import { Agents } from "../view/Settings/Agents"
import { Labels } from "../view/Settings/Labels"
import { CustomFields } from "../view/Settings/CustomFields"
import { QuickReply } from "../view/Settings/QuickReply"
import { Templates } from "../view/Settings/Template"
import { MediaFiles } from "../view/Settings/MediaFiles"
import { ApiIntegration } from "../view/Settings/ApiIntegration"
import { AppIntegration } from "../view/Settings/AppIntegration"
import { Profile } from "../view/UserProfile/Profile"
import { PasswordChange } from "../view/UserProfile/Password"
import { useWindowDimensions } from "../view/useWindowDimensions"
import { PlanSubscription } from "../view/Payment/PlanSubscription"
import { AddOns } from "../view/Payment/AddOns"
import { Summary } from "../view/Payment/Summary"
import { Analytics } from "../view/Analytics/ui"
import { WtDimen } from "../constants/dimens"

export const WTDUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Wtd />
            <Footer />
            <WhatsAppSideFAB />
        </>
    )
}


export const LoginLoaderUIAdapter = () => {
    return (
        <div>
            <Player autoplay loop src="https://assets2.lottiefiles.com/packages/lf20_zffo1hqh.json" style={{ width: "600px" }} className="items-lottie mt-3" ></Player>
        </div>
    )
}

// Login UI Adapter
export const LoginUIAdapter = () => {
    if (LoginValidate()) {
        window.location.href = "/dashboard"
    }
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Login />
            <WhatsAppSideFAB />
        </>

    )
}
export const ChangePasswordUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <ChangePassword />
            <WhatsAppSideFAB />
        </>

    )
}
export const ForgotPasswordUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <ForgotPassword />
            <WhatsAppSideFAB />
        </>

    )
}

export const SignupUIAdapter = () => {
    if (LoginValidate()) {
        window.location.href = "/dashboard"
    }
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Signup />
            <WhatsAppSideFAB />
        </>
    )
}
export const SignupSuccessUIAdapter = () => {
    return (<SignupSuccess />)
}
export const EmailVerificationUIAdapter = () => {
    return (
        <EmailVerification />
    )
}
export const WhatsAppVerificationUIAdapter = () => {
    return (
        <WhatsAppVerification />
    )
}
export const OtpVerificationUIAdapter = () => {
    return (
        <OtpVerification />
    )
}
// End of Login UI Adapter


// Dashboard UI Adapter
export const DashboardUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex' }} >
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Dashboard />
            </Box>
        </Box>
    )
}
// End of Dashboard UI Adapter


// Chat UI Adapter
export const ChatUIAdapter = () => {
    if (!LoginValidate()) { window.location.href = "/login" }
    const [openNoChannelDialog, setOpenNoChannelDialog] = useState({ open: false })
    useEffect(() => {
        if (!localStorage.getItem("c1") || localStorage.getItem("c1") === "" || localStorage.getItem("c1") === "undefined") {
            setOpenNoChannelDialog({ open: true })
        }
    }, [])
    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                {
                    localStorage.getItem("c1") && localStorage.getItem("c1") !== "" && localStorage.getItem("c1") !== "undefined" ?
                        <Chat />
                        :
                        <img src={WtTheme.chat_default_url} style={{ width: '100%', height: "100vh" }} alt={WtString.SiteName} />
                }
            </Box>
            <NoChannelDialog open={openNoChannelDialog.open} onClose={openNoChannelDialog.onClose} />
        </Box>
    )
}
// End of Chat UI Adapter


// Contacts UI Adapter
export const ContactsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Contacts />
            </Box>
        </Box>
    )
}
// End of Contacts UI Adapter


// Broadcast UI Adapter
export const BroadcastUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    const [openNoChannelDialog, setOpenNoChannelDialog] = useState({ open: false })
    useEffect(() => {
        if (!localStorage.getItem("c1") || localStorage.getItem("c1") === "" || localStorage.getItem("c1") === "undefined") {
            setOpenNoChannelDialog({ open: true })
        }
    }, [])
    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                {
                    localStorage.getItem("c1") && localStorage.getItem("c1") !== "" && localStorage.getItem("c1") !== "undefined" ?
                        <Broadcast /> : <img src={WtTheme.campaign_default_url} style={{ width: '100%', height: "100vh" }} alt={WtTheme.SiteName} />
                }
            </Box>
            <NoChannelDialog open={openNoChannelDialog.open} onClose={openNoChannelDialog.onClose} />
        </Box>
    )
}
export const CreateCampaignUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <CreateCampaign />
            </Box>
        </Box>
    )
}
// End of Broadcast UI Adapter


// Chatbot UI Adapter
export const ChatbotListUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    const [openNoChannelDialog, setOpenNoChannelDialog] = useState({ open: false })
    useEffect(() => {
        if (!localStorage.getItem("c1") || localStorage.getItem("c1") === "" || localStorage.getItem("c1") === "undefined") setOpenNoChannelDialog({ open: true })
    }, [])
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                {
                    localStorage.getItem("c1") && localStorage.getItem("c1") !== "" && localStorage.getItem("c1") !== "undefined" ?
                        <ChatbotListUI /> : <img src={WtTheme.chatbot_default_url} style={{ width: '100%', height: "100vh" }} alt={WtTheme.SiteName} />
                }
            </Box>
            <NoChannelDialog open={openNoChannelDialog.open} onClose={openNoChannelDialog.onClose} />
        </Box>
    )
}
export const BuildChatbotsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <App />
            </Box>
        </Box>
    )
}
// Chatbot UI Adapter


// Setting UI Adapter
export const SettingsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Settings />
            </Box>
        </Box>
    )
}
export const BusinessInformationUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <BusinessInformation />
            </Box>
        </Box>
    )
}
export const ChannelsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Channels />
            </Box>
        </Box>
    )
}
export const AgentsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Agents />
            </Box>
        </Box>
    )
}
export const LabelsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Labels />
            </Box>
        </Box>
    )
}
export const CustomFieldsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <CustomFields />
            </Box>
        </Box>
    )
}
export const QuickReplyUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <QuickReply />
            </Box>
        </Box>
    )
}
export const TemplatesUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Templates />
            </Box>
        </Box>
    )
}
export const MediaFilesUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <MediaFiles />
            </Box>
        </Box>
    )
}
export const APIIntegrationUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <ApiIntegration />
            </Box>
        </Box>
    )
}
export const APPIntegrationUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <AppIntegration />
            </Box>
        </Box>
    )
}
// End of Setting UI Adapter


// Profile UI Adapter
export const ProfileUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <Profile />
            </Box>
        </Box>
    )
}
export const ProfileChangePasswordUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                <PasswordChange />
            </Box>
        </Box>
    )
}
export const PaymentPlanSubscriptionUIAdapter = () => {
    const { width } = useWindowDimensions()
    // if (!LoginValidate()) {
    //   window.location.href = "/login"
    // }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            {
                (width <= 780) ? (
                    <Box sx={{ flexGrow: 1, p: 0 }}>
                        <PlanSubscription />
                    </Box>
                ) : (
                    <>
                        <LeftNavigation />
                        <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                            <PlanSubscription />
                        </Box>
                    </>
                )
            }
        </Box>
    )
}
export const PaymentAddOnsUIAdapter = () => {
    const { width } = useWindowDimensions()
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            {
                (width <= 780) ? (
                    <Box sx={{ flexGrow: 1, p: 0 }}>
                        <AddOns />
                    </Box>
                ) : (
                    <>
                        <LeftNavigation />
                        <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                            <AddOns />
                        </Box>
                    </>
                )
            }
        </Box>
    )
}
export const PaymentSummaryUIAdapter = () => {
    const { width } = useWindowDimensions()
    // if (!LoginValidate()) {
    //   window.location.href = "/login"
    // }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            {
                (width <= 780) ? (
                    <Box sx={{ flexGrow: 1, p: 0 }}>
                        <Summary />
                    </Box>
                ) : (
                    <>
                        <LeftNavigation />
                        <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                            <Summary />
                        </Box>
                    </>
                )
            }
        </Box>
    )
}
// End of Profile UI Adapter


// Payment Success and Failure Adapter
export const PaymentSuccessUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <PaymentSuccess />
        </Box>
    )
}
export const PaymentFailedUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <PaymentFailed />
        </Box>
    )
}
// End of Payment Success and Failure Adapter








export const AnalyticsUIAdapter = () => {
    if (!LoginValidate()) {
        window.location.href = "/login"
    }
    const [openNoChannelDialog, setOpenNoChannelDialog] = useState({ open: false })
    useEffect(() => {
        if (!localStorage.getItem("c1") || localStorage.getItem("c1") === "" || localStorage.getItem("c1") === "undefined") {
            setOpenNoChannelDialog({ open: true })
        }
    }, [])
    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavigation />
            <Box sx={{ flexGrow: 1, p: 0, ml: WtDimen.leftSidebarMargin }}>
                {
                    localStorage.getItem("c1") && localStorage.getItem("c1") !== "" && localStorage.getItem("c1") !== "undefined" ?
                        <Analytics /> : <img src={WtTheme.analytics_default_url} style={{ width: '100%', height: "100vh" }} alt={WtTheme.SiteName} />
                }
            </Box>
            <NoChannelDialog open={openNoChannelDialog.open} onClose={openNoChannelDialog.onClose} />
        </Box>
    )
}
