/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'react-bootstrap'
import { DeleteOutlineOutlined, AddOutlined } from '@mui/icons-material'
import { deleteEmployeeAPI, fetchEmployeeListAPI } from '../../controller/APIs'
import { AddEmployeeDialog, ConfirmDialog, DemoDialog, WtLoader } from '../../controller/BaseTool'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'
var moment = require('moment')

export const Agents = () => {
    const notificationBarStatus = useContext(globalContext)
    const [employeeList, setEmployeeList] = useState([])

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    const [openAddChannelDialog, setOpenAddChannelDialog] = useState({ open: false, onClose: null })
    const openAddChannelDialogBox = () => {
        setOpenAddChannelDialog({
            open: true, onClose: (confirm) => {
                setOpenAddChannelDialog(false)
                if (confirm) fetchAllEmployee()
            }
        })
    }


    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const openConfirmationForDeletionBox = (id) => {
        setOpenConfirmDialogBox({
            open: true, title: 'Employee Deletion', message: 'Are your sure to delete this Employee?', onClose: (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) deleteEmployee(id)
            }
        })
    }

    // List of Employee 
    const fetchAllEmployee = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchEmployeeListAPI()
            if (response.status === 200) {
                setEmployeeList(response.data.data)
                handleWTLoader.handleWTLoader(false)
            } else {
                handleWTLoader.handleWTLoader(false)
            }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of List of Employee 

    // Delete Channels 
    const deleteEmployee = async (id) => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = "_id=" + id
            const response = await deleteEmployeeAPI(data)
            if (response.status === 204) {
                fetchAllEmployee()
                handleWTLoader.handleWTLoader(false)
            } else {
                handleWTLoader.handleWTLoader(false)
            }
        } catch (err) { handleWTLoader.handleWTLoader(false) }
    }
    // End of Delete Channels 

    useEffect(() => { fetchAllEmployee() }, [])

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: (confirm) => setOpenDemoDialog(false) })

    const profile_base_view = { display: 'flex', backgroundColor: '#f8f8f8', height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh', width: '94vw' }
    const profile_menu_item = { flex: '0.85' }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} onClose={openConfirmDialogBox.onClose} />}
                {openAddChannelDialog.open && <AddEmployeeDialog open={openAddChannelDialog.open} onClose={openAddChannelDialog.onClose} />}
                {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}

                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Agents</h5>
                        </div>
                        <div>
                            {
                                (localStorage.getItem("userRole") !== "admin") &&
                                <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddChannelDialogBox() : openDemoDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius }} ><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Add Employee</button>
                            }
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <div className="app_list" style={{ height: "610px" }}>
                            <Table className="fw-bold" responsive>
                                <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: "15px" }}>
                                    <tr>
                                        <th>Name</th><th>Email</th><th>WhatsApp</th><th>Role</th><th>Last Active Date</th>
                                        {(localStorage.getItem("userRole") !== "admin") && <th>Action</th>}
                                    </tr>
                                </thead>
                                <tbody style={{ background: "#fff", fontSize: "12px" }}>
                                    {
                                        employeeList.map((v, i) => {
                                            return (
                                                <tr className="hand" key={i}>
                                                    <td style={{ color: WtColor.TextMidGrey }}>{v.name}</td>
                                                    <td style={{ color: WtColor.TextLightGrey }}>{v.email}</td>
                                                    <td style={{ color: WtColor.BaseColor }}>{v.whatsapp}</td>
                                                    <td className="text-uppercase" style={{ color: WtColor.ProcessingBlue }}>{v.role}</td>
                                                    <td style={{ color: WtColor.TextLightGrey }}>{moment.unix((v.updateDate) / 1000).format("DD MMM, YYYY")}</td>
                                                    {
                                                        (localStorage.getItem("userRole") === "admin") ? "" :
                                                            <td>
                                                                {
                                                                    (v.role === "admin") ? <DeleteOutlineOutlined onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openConfirmationForDeletionBox(v._id) : openDemoDialogBox()} className="text-danger ms-2" /> : ""
                                                                }
                                                            </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}