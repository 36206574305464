class WtColors {
    constructor() {
        this.BaseColor = '#00A82D'
        this.BaseDarkColor = '#009C2A'
        this.BaseLightColor = '#92CC37'
        this.SelectionColor = '#EFFFED'
        this.LineColor = '#F0F2F8'
        this.TextColor = '#9EA4B5'
        this.TextDarkColor = '#3F4A5F'
        this.TextMidGrey = '#616874'
        this.TextLightGrey = '#9EA4B5'
        this.ChatSelectionColor = '#F9F9F9'
        this.DraftYellow = '#F2B415'
        this.ProcessingBlue = '#0B86DF'
        this.CloudyGrey = '#B8BDCC'
        this.RedColor = '#B3261E'
        this.BrownColor = '#AC590B'
        this.NeonGreenColor = '#EFFFED'
        this.OrangeColor = '#F07A0C'
        this.PrivateNoteColor = "#FFFCEC"
        this.LiteGreen = "#40C900"
        this.DarkGreen = "#40C900"
        this.LiteBlue = "#36A4F3"
        this.DarkBlue = "#1C6CA7"
        this.LiteBrown = "#C78F00"
        this.BlackishBrown = "#856000"


        this.InteractiveMsgColor = '#FD7900'
        this.SingleProductMsgColor = '#C78F00'
        this.MultiProductMsgColor = '#AC590B'
        this.MenuMsgColor = '#856000'
        this.TextMsgColor = '#79CF35'
        this.QuickReplyMsgColor = '#2F8D02'
        this.ActionMsgColor = '#1C5500'
        this.ImageMsgColor = '#36A4F3'
        this.VideoMsgColor = '#3654F3'
        this.AudioMsgColor = '#C236F3'
        this.DocumentMsgColor = '#EB156F'
        this.TemplateMsgColor = '#1C6CA7'
    }
}
export const WtColor = new WtColors()