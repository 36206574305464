/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Switch, Tooltip } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { fetchBotListAPI, updateBotStatusAPI } from '../../controller/APIs'
import { useContext } from 'react'
import chatbotContext from '../../context/chatbot/chatbotContext'
import { WtColor } from '../../constants/colours'
import { ConfirmDialog } from '../../controller/BaseTool'


const TopBar = ({ onSelectFlow, reloadData, saveBots }) => {
    const isBotActive = useContext(chatbotContext)
    const setIsBotActive = useContext(chatbotContext)
    const botId = useContext(chatbotContext)
    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }
    let query = useQuery()
    let chatBotId = query.get("botIndex")

    let busy = false

    const history = useHistory()
    const openURL = (url) => history.push(url)

    const [selectedSidebarMain, setSelectedSidebarMain] = useState(undefined)
    const handleSidebarMainSelection = (cbotId) => {
        localStorage.setItem('userActivity', false)
        openURL("/dashboard/build-chatbots?botIndex=" + cbotId)
        setSelectedSidebarMain(cbotId)
    }

    const [flowList, setFlowList] = useState([])
    const fetchBotFlowList = async () => {
        if (!busy) {
            busy = true
            try {
                const response = await fetchBotListAPI()
                if (response.status === 200) {
                    setSelectedSidebarMain(chatBotId)
                    setFlowList(response.data.data)
                    onSelectFlow(response.data.data?.find(i => i._id?.toString() === chatBotId))
                    if (selectedSidebarMain === undefined) {
                        setSelectedSidebarMain(chatBotId)
                        onSelectFlow(onSelectFlow(response.data.data?.find(i => i._id?.toString() === chatBotId)))
                    }
                }
                busy = false
            } catch (error) { busy = false }
        }
    }

    useEffect(() => {
        onSelectFlow(flowList?.find(i => i._id?.toString() === chatBotId))
        setSelectedSidebarMain(chatBotId)
        fetchBotFlowList()
    }, [chatBotId])

    // Make Bot Live Code
    const handleIsBotActive = (event) => {
        setIsBotActive.setIsBotActive(event.target.checked)
        checkBotStatus()
    }
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
    const checkBotStatus = async () => {
        setOpenConfirmDialog({
            open: true, title: 'Whatstool Business', msg: !isBotActive.isBotActive ? "Are you sure, you want to Live this Bot?" : "Are you sure, you want to Stop this Bot?", onClose: async (confirm) => {
                setOpenConfirmDialog(false)
                if (confirm) {
                    try {
                        let data = { "isLive": !isBotActive.isBotActive, "_id": botId.botId }
                        await updateBotStatusAPI(data)
                    } catch (error) { }
                }
            }
        })
    }
    // End of Make Bot Live Code
    return (
        <div className="topBar bg-white" style={{ display: "flex", height: "60px", borderBottom: "1px solid " + WtColor.LineColor, zIndex: "1001", position: "relative" }}>
            <div>
                <select value={selectedSidebarMain} onChange={(e) => handleSidebarMainSelection(e.target.value)} style={{ border: '1px solid ' + WtColor.LineColor, padding: '6px', borderRadius: '8px', outlineOffset: 0, outline: "none", marginLeft: "10px", marginRight: "10px", marginTop: "11px", width: "200px" }}>
                    {
                        flowList.map((flow) => {
                            return (<option value={flow._id} key={flow._id}>{flow.title}</option>)
                        })
                    }
                </select>
            </div>
            <div style={{ display: localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? 'flex' : "none", flex: 1, marginLeft: '10px' }}>
                <p style={{ marginTop: "16px", color: WtColor.TextLightGrey }}><i>Draft</i></p>
                <Tooltip title={!isBotActive.isBotActive ? "Make Bot Live" : "Stop the Bot"} placement="bottom">
                    <Switch className="mt-2" checked={isBotActive.isBotActive} onChange={(e) => handleIsBotActive(e)} />
                </Tooltip>
                <p style={{ marginTop: "16px", color: WtColor.BaseColor }}>Live</p>
            </div>
            <div style={{ display: localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? 'none' : "flex", flex: 1 }}>
                &nbsp;
            </div>
            <div style={{ marginTop: "11px" }} className="pe-4">
                <Button className="fw-bold" variant="contained" onClick={reloadData} style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey, boxShadow: "none" }}><RefreshIcon /> </Button>&nbsp;&nbsp;
                <Button className="theme_bg text-white fw-bold" style={{ textTransform: "none", boxShadow: "none" }} onClick={saveBots} variant="contained"><SaveOutlinedIcon /> &nbsp;Save</Button>
            </div>

            <div>
                {openConfirmDialog.open && <ConfirmDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />}
            </div>
        </div>
    )
}

export default TopBar