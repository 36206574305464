/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import ContactsContext from './contactsContext'
import { fetchAllAttributesAPI, fetchAllLabelsAPI, fetchContactsAPI, fetchContactsV2API, fetchListAttributesAPI } from '../../controller/APIs'
import moment from 'moment'

const ContactsState = (props) => {
    // Loader Handling
    const [isLoading, setIsLoading] = useState(true)
    const handleWTLoader = (val) => setIsLoading(val)
    // End of Loader Handling

    const [contactLs, setContactLs] = useState([])
    const [contactLsCount, setContactLsCount] = useState(0)

    const [entriesVal, setEntriesVal] = useState(10)
    const [entries, setEntries] = useState(entriesVal)

    const handleEntries = (val) => {
        setEntriesVal(val)
        setEntries(val)
    }

    const getContacts = async (skip) => {
        handleWTLoader(true)
        try {
            let data = { 'limit': entriesVal, 'skip': skip }
            const response = await fetchContactsAPI(data)
            if (response.status === 200) {
                setContactLs(response.data.data.contacts)
                setContactLsCount(response.data.data.totalContacts)
                handleWTLoader(false)
            } else { handleWTLoader(false) }
        } catch (error) { handleWTLoader(false) }
    }

    const getContactsBySearch = async (searchVal) => {
        try {
            let data = { 'searchText': searchVal }
            const response = await fetchContactsAPI(data)
            if (response.status === 200) {
                setContactLs(response.data.data.contacts)
                setContactLsCount(response.data.data.totalContacts)
                handleWTLoader(false)
            } else { handleWTLoader(false) }
        } catch (error) { handleWTLoader(false) }
    }

    const getContactsByFilter = async (labelIds, labelIdParam, contactFieldIds, contactIdParam) => {
        let _labelIds = labelIds === "" ? null : labelIds
        let _contactFieldIds = contactFieldIds === "" ? null : contactFieldIds
        try {
            let data = { 'labelIds': _labelIds, 'labelIdParam': labelIdParam, 'contactFieldIds': _contactFieldIds, 'contactIdParam': contactIdParam }
            const response = await fetchContactsAPI(data)
            if (response.status === 200) {
                setContactLs(response.data.data.contacts)
                setContactLsCount(response.data.data.totalContacts)
                handleWTLoader(false)
            } else { handleWTLoader(false) }
        } catch (error) { handleWTLoader(false) }
    }

    const [contactSkip, setContactSkip] = useState(0)
    const handleContactSkip = async (skip) => {
        if (contactSkip === -10) { setContactSkip(0); return }
        setContactSkip(skip)
        await getContacts(skip)
    }


    // Handle UI on-off
    const [contactUISize, setContactUISize] = useState(1.00)
    const [userInfoUISize, setUserInfoUISize] = useState(0.00)
    const [userInfoUIVisibility, setUserInfoUIVisibility] = useState("none")
    const [splitUIName, setSplitUIName] = useState('off')
    const handleUI_On_Off = async (purpose) => {
        if (purpose === "on_user_info") {
            setSplitUIName(purpose); setContactUISize(0.80); setUserInfoUISize(0.20); setUserInfoUIVisibility("block")
        }
        if (purpose === "on_filter") {
            setSplitUIName(purpose); setContactUISize(0.60); setUserInfoUISize(0.40); setUserInfoUIVisibility("block")
        }
        if (purpose === "off") {
            setSplitUIName(purpose); setContactUISize(1.00); setUserInfoUISize(0.00); setUserInfoUIVisibility("none")
        }
    }
    // End of Handle UI on-off














    // Contact Filtering Work
    const [filterAppliedStatus, setFilterAppliedStatus] = useState(false)

    // Label Work
    const [labelLs, setLabelLs] = useState([])
    const getLabels = async () => {
        try {
            const response = await fetchAllLabelsAPI()
            if (response.status === 200) {
                setLabelLs(response.data.data)
            }
        } catch (error) { }
    }

    const [labelFields, setLabelFields] = useState([{ l_id: "", l_action: "" }])
    const addLabelFields = () => {
        let newField = { l_id: "", l_action: "" }
        setLabelFields([...labelFields, newField])
    }
    const removeLabelFields = (index) => {
        let data = [...labelFields]
        data.splice(index, 1)
        setLabelFields(data)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateLabelFieldsData = (index, event) => {
        let data = [...labelFields]
        data[index][event.target.name] = event.target.value
        setLabelFields(data)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetLabelFieldData = () => {
        setLabelFields([{ l_id: "", l_action: "" }])
    }
    // End of Label Work


    // Custom Field
    const [customFieldLs, setCustomFieldLs] = useState([])
    const getCustomFieldLs = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) {
                setCustomFieldLs(response.data.data)
            }
        } catch (error) { }
    }
    const [listCustomField, setListCustomField] = useState([])
    const getListCustomFieldLs = async (url) => {
        try {
            const response = await fetchListAttributesAPI(url)
            if (response.status === 200) {
                setListCustomField(response.data.data[0].list)
            }
        } catch (error) { }
    }

    const [customFields, setCustomFields] = useState([{ cf_id: "", cf_type: "", cf_key: "", cf_operator: "", cf_value: "" }])
    const addCustomFields = () => {
        let newField = { cf_id: "", cf_type: "", cf_key: "", cf_operator: "", cf_value: "" }
        setCustomFields([...customFields, newField])
    }
    const removeCustomFields = (index) => {
        let data = [...customFields]
        data.splice(index, 1)
        setCustomFields(data)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateCustomFieldsData = (index, event) => {
        let data = [...customFields]
        let info = []
        if (event.target.name === 'cf_id') {
            info = event.target.value.split(',')
            data[index]['cf_id'] = info[0]
            data[index]['cf_type'] = info[1]
            data[index]['cf_key'] = info[2]

            if (info[1] === 'list') getListCustomFieldLs('?_id=' + info[0])
        } else {
            data[index][event.target.name] = event.target.value
        }
        setCustomFields(data)
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetCustomFieldData = () => {
        setCustomFields([{ cf_id: "", cf_type: "", cf_key: "", cf_operator: "", cf_value: "" }])
    }
    // End of Custom Field


    // Created At
    const dataStringConvertor = (val) => {
        let d1 = new Date(val)
        let d2 = d1.getTime()
        return d2
    }
    const [finalCreatedFrom, setFinalCreatedFrom] = useState('')
    const [finalCreatedTo, setFinalCreatedTo] = useState('')
    const [createAtOpt, setCreatedAtOpt] = useState('none')
    const [createdAtFrom, setCreatedAtFrom] = useState('')
    const [createdAtTo, setCreatedAtTo] = useState('')
    const updateCreatedAtOpt = (e) => {
        setCreatedAtOpt(e.target.value)
        if (e.target.value === "today") {
            setCreatedAtFrom(moment().format('L'))
            setCreatedAtTo(moment().format('LLL'))
            setFinalCreatedFrom(dataStringConvertor(moment().format('L')))
            setFinalCreatedTo(dataStringConvertor(moment().format('LLL')))
        }
        if (e.target.value === "week") {
            setCreatedAtFrom(moment().subtract(7, 'days').calendar())
            setCreatedAtTo(moment().format('L'))
            setFinalCreatedFrom(dataStringConvertor(moment().subtract(7, 'days').calendar()))
            setFinalCreatedTo(dataStringConvertor(moment().format('L')))
        }
        if (e.target.value === "month") {
            setCreatedAtFrom(moment().subtract(30, 'days').calendar())
            setCreatedAtTo(moment().format('L'))
            setFinalCreatedFrom(dataStringConvertor(moment().subtract(30, 'days').calendar()))
            setFinalCreatedTo(dataStringConvertor(moment().format('L')))
        }
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateCreatedAtOptFrom = (d) => {
        setCreatedAtFrom(d)
        setFinalCreatedFrom(dataStringConvertor(d))
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateCreatedAtOptTo = (d) => {
        setCreatedAtTo(d)
        setFinalCreatedTo(dataStringConvertor(d))
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetCreatedAtData = () => {
        setFinalCreatedFrom('')
        setFinalCreatedTo('')
        setCreatedAtOpt('none')
        setCreatedAtFrom('')
        setCreatedAtTo('')
    }
    // End of Created At


    // Last Seen
    const [finalLastSeenFrom, setFinalLastSeenFrom] = useState('')
    const [finalLastSeenTo, setFinalLastSeenTo] = useState('')
    const [lastSeenOpt, setLastSeenOpt] = useState('none')
    const [lastSeenFrom, setLastSeenFrom] = useState('')
    const [lastSeenTo, setLastSeenTo] = useState('')
    const updateLastSeenOpt = (e) => {
        setLastSeenOpt(e.target.value)
        if (e.target.value === "today") {
            setLastSeenFrom(moment().format('L'))
            setLastSeenTo(moment().format('LLL'))
            setFinalLastSeenFrom(dataStringConvertor(moment().format('L')))
            setFinalLastSeenTo(dataStringConvertor(moment().format('LLL')))
        }
        if (e.target.value === "week") {
            setLastSeenFrom(moment().subtract(7, 'days').calendar())
            setLastSeenTo(moment().format('L'))
            setFinalLastSeenFrom(dataStringConvertor(moment().subtract(7, 'days').calendar()))
            setFinalLastSeenTo(dataStringConvertor(moment().format('L')))
        }
        if (e.target.value === "month") {
            setLastSeenFrom(moment().subtract(30, 'days').calendar())
            setLastSeenTo(moment().format('L'))
            setFinalLastSeenFrom(dataStringConvertor(moment().subtract(30, 'days').calendar()))
            setFinalLastSeenTo(dataStringConvertor(moment().format('L')))
        }
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateLastSeenOptFrom = (d) => {
        setLastSeenFrom(d)
        setFinalLastSeenFrom(dataStringConvertor(d))
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const updateLastSeenOptTo = (d) => {
        setLastSeenTo(d)
        setFinalLastSeenTo(dataStringConvertor(d))
        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    const resetLastSeenData = () => {
        setFinalLastSeenFrom('')
        setFinalLastSeenTo('')
        setLastSeenOpt('none')
        setLastSeenFrom('')
        setLastSeenTo('')
    }
    // End of Last Seen

    // Complete Reset
    const resetAllFilter = () => {
        setContactLsLen(0)
        resetLabelFieldData()
        resetCustomFieldData()
        resetCreatedAtData()
        resetLastSeenData()

        setApplyFilterStatus(true)
        setResetFilterStatus(true)
    }
    // End of Complete Reset


    // Opted In
    const [optedInOpt, setOptedInOpt] = useState('none')
    const handleOptedInOpt = (e) => { setOptedInOpt(e.target.value) }
    // End of Opted In


    const [contactLsLen, setContactLsLen] = useState(0)
    const getContactsAppliedFilter = async (limit, skip, labelIdsInc, labelIdsExc, fieldList, createDateFrom, createDateTo, lastSeenFrom, lastSeenTo) => {
        try {
            let data = {
                'limit': limit,
                'skip': skip,
                'labelIdsInc': (labelIdsInc === "") ? null : labelIdsInc,
                'labelIdsExc': (labelIdsExc === "") ? null : labelIdsExc,
                'fieldList': (fieldList === "") ? null : fieldList,
                'createDateFrom': (createDateFrom === "" || createDateFrom === undefined) ? null : createDateFrom,
                'createDateTo': (createDateTo === "" || createDateTo === undefined) ? null : createDateTo,
                'lastSeenFrom': (lastSeenFrom === "" || lastSeenFrom === undefined) ? null : null,
                'lastSeenTo': (lastSeenTo === "" || lastSeenTo === undefined) ? null : null
            }
            const response = await fetchContactsV2API(data)
            if (response.status === 200) {
                if (response.data?.data?.contacts?.length !== 0) {
                    setContactLs(response.data?.data?.contacts)
                    setContactLsLen(response.data?.data?.totalContacts)
                    setApplyFilterStatus(false)
                    setResetFilterStatus(false)
                    handleUI_On_Off('off')
                }
            }
        } catch (error) { }
    }

    const [applyFilterStatus, setApplyFilterStatus] = useState(false)
    const [resetFilterStatus, setResetFilterStatus] = useState(false)
    // End of Contact Filtering Work
    return (
        <ContactsContext.Provider value={{
            isLoading, handleWTLoader,
            getContacts, getContactsBySearch, getContactsByFilter, contactLs, setContactLs, contactLsCount, setContactLsCount,
            contactSkip, handleContactSkip,
            entriesVal, entries, setEntries, handleEntries,
            contactUISize, userInfoUISize, userInfoUIVisibility, splitUIName, handleUI_On_Off,


            // Contact Filtering Work
            filterAppliedStatus, setFilterAppliedStatus,

            labelLs, getLabels, labelFields, addLabelFields, removeLabelFields, updateLabelFieldsData, resetLabelFieldData,

            customFieldLs, getCustomFieldLs, listCustomField, customFields, addCustomFields, removeCustomFields, updateCustomFieldsData, resetCustomFieldData,

            createAtOpt, updateCreatedAtOpt, createdAtFrom, updateCreatedAtOptFrom, createdAtTo, updateCreatedAtOptTo, finalCreatedFrom, finalCreatedTo, resetCreatedAtData,

            lastSeenOpt, updateLastSeenOpt, lastSeenFrom, updateLastSeenOptFrom, lastSeenTo, updateLastSeenOptTo, finalLastSeenFrom, finalLastSeenTo, resetLastSeenData,

            resetAllFilter,

            optedInOpt, handleOptedInOpt,

            getContactsAppliedFilter, contactLsLen,

            applyFilterStatus, setApplyFilterStatus, resetFilterStatus, setResetFilterStatus
            // End of Contact Filtering Work
        }}>
            {props.children}
        </ContactsContext.Provider>
    )
}
export default ContactsState