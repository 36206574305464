export const LoginValidate = () => {
  const userId = localStorage.getItem("userId")
  const userWhatsApp = localStorage.getItem("userWhatsApp")
  const userName = localStorage.getItem("userName")
  const userEmail = localStorage.getItem("userEmail")
  const userEmailVerified = localStorage.getItem("userEmailVerified")
  const userWhatsAppVerified = localStorage.getItem("userWhatsAppVerified")

  const bId = localStorage.getItem("bId")
  const bName = localStorage.getItem("bName")
  const bWebsite = localStorage.getItem("bWebsite")
  const userRole = localStorage.getItem("userRole")

  const userAuthToken = localStorage.getItem("userAuthToken")

  if (userId === null && userWhatsApp === null && userName === null && userEmail === null && userEmailVerified === null && userWhatsAppVerified === null && bId === null && bName === null && bWebsite === null && userRole === null && userAuthToken === null) {
    return false
  } else {
    return true
  }
}

export const Logout = () => {
  localStorage.clear()
  window.location.href = "/login"
}
