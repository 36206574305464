import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { WtTheme } from './theme'
import { Payment } from '../view/StripePayment/Payment'
import { Completion } from '../view/StripePayment/Completion'
import { TopBarNotification } from './BaseToolUI'
import { WtUrl } from '../constants/urls'
import { BroadcastMsgUIAdapter, CancellationUIAdapter, ChatBotsUIAdapter, ClientsUIAdapter, DummyUIAdapter, ECommerceSectorUIAdapter, EducationalSectorUIAdapter, HealthSectorUIAdapter, HomeUIAdapter, MultiAgentUIAdapter, PricingUIAdapter, PrivacyPolicyUIAdapter, QuickGuideUIAdapter, SupportUIAdapter, TermsOfServiceUIAdapter, TestingUIAdapter, TourTravelSectorUIAdapter } from '../UICaller/StaticUI'
import { APIIntegrationUIAdapter, APPIntegrationUIAdapter, AgentsUIAdapter, AnalyticsUIAdapter, BroadcastUIAdapter, BuildChatbotsUIAdapter, BusinessInformationUIAdapter, ChangePasswordUIAdapter, ChannelsUIAdapter, ChatUIAdapter, ChatbotListUIAdapter, ContactsUIAdapter, CreateCampaignUIAdapter, CustomFieldsUIAdapter, DashboardUIAdapter, EmailVerificationUIAdapter, ForgotPasswordUIAdapter, LabelsUIAdapter, LoginUIAdapter, MediaFilesUIAdapter, OtpVerificationUIAdapter, PaymentAddOnsUIAdapter, PaymentFailedUIAdapter, PaymentPlanSubscriptionUIAdapter, PaymentSuccessUIAdapter, PaymentSummaryUIAdapter, ProfileChangePasswordUIAdapter, ProfileUIAdapter, QuickReplyUIAdapter, SettingsUIAdapter, SignupSuccessUIAdapter, SignupUIAdapter, TemplatesUIAdapter, WTDUIAdapter, WhatsAppVerificationUIAdapter } from '../UICaller/DynamicUI'

export const RouteController = () => {
  const [pageURL, setPageURL] = useState();
  useEffect(() => { setPageURL(window.location.href) }, [])
  return (
    <>
      <TopBarNotification msgText={'Plan is expired'} />
      <Router>
        <Switch>
          <Route exact path="/">
            <Helmet>
              <title>{WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta
                name="twitter:description"
                content={WtTheme.SiteDescription}
              />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <HomeUIAdapter />
          </Route>
          {/* Business Panel Routes */}
          <Route exact path={WtUrl.loginUrl}><LoginUIAdapter /></Route>
          <Route exact path={WtUrl.changePasswordWithEmailUrl}><ChangePasswordUIAdapter /></Route>
          <Route exact path={WtUrl.forgotPasswordUrl}><ForgotPasswordUIAdapter /></Route>
          <Route exact path={WtUrl.signupUrl}><SignupUIAdapter /></Route>
          <Route exact path={WtUrl.signupSuccessUrl}><SignupSuccessUIAdapter /></Route>
          <Route exact path={WtUrl.emailVerificationUrl}><EmailVerificationUIAdapter /></Route>
          <Route exact path={WtUrl.whatsappVerificationUrl}><WhatsAppVerificationUIAdapter /></Route>
          <Route exact path={WtUrl.otpVerificationUrl}><OtpVerificationUIAdapter /></Route>

          <Route exact path={WtUrl.dashboardUrl}><DashboardUIAdapter /></Route>

          <Route exact path={WtUrl.chatUrl}><ChatUIAdapter /></Route>
          <Route exact path={`${WtUrl.chatUrl}/:hash`}><ChatUIAdapter /></Route>

          <Route exact path={WtUrl.contactsUrl}><ContactsUIAdapter /></Route>

          <Route exact path={WtUrl.broadcastUrl}><BroadcastUIAdapter /></Route>
          <Route exact path={WtUrl.broadcastCreateUrl}><CreateCampaignUIAdapter /></Route>

          <Route exact path={WtUrl.ChatbotListUrl}><ChatbotListUIAdapter /></Route>
          <Route exact path={WtUrl.buildChatbotsUrl}><BuildChatbotsUIAdapter /></Route>

          <Route exact path={WtUrl.settingsUrl}><SettingsUIAdapter /></Route>
          <Route exact path={WtUrl.businessInformationUrl}><BusinessInformationUIAdapter /></Route>
          <Route exact path={WtUrl.channelUrl}><ChannelsUIAdapter /></Route>
          <Route exact path={WtUrl.agentsUrl}><AgentsUIAdapter /></Route>
          <Route exact path={WtUrl.labelsUrl}><LabelsUIAdapter /></Route>
          <Route exact path={WtUrl.customFieldsUrl}><CustomFieldsUIAdapter /></Route>
          <Route exact path={WtUrl.quickReplyUrl}><QuickReplyUIAdapter /></Route>
          <Route exact path={WtUrl.templateUrl}><TemplatesUIAdapter /></Route>
          <Route exact path={WtUrl.mediaUrl}><MediaFilesUIAdapter /></Route>
          <Route exact path={WtUrl.apiIntegrationUrl}><APIIntegrationUIAdapter /></Route>
          <Route exact path={WtUrl.appIntegrationUrl}><APPIntegrationUIAdapter /></Route>

          <Route exact path={WtUrl.profileUrl}><ProfileUIAdapter /></Route>
          <Route exact path={WtUrl.profileChangePasswordUrl}><ProfileChangePasswordUIAdapter /></Route>

          <Route exact path={WtUrl.paymentPlanSubscriptionUrl}><PaymentPlanSubscriptionUIAdapter /></Route>
          <Route exact path={WtUrl.paymentAddOnsUrl}><PaymentAddOnsUIAdapter /></Route>
          <Route exact path={WtUrl.paymentSummaryUrl}><PaymentSummaryUIAdapter /></Route>

          <Route exact path={WtUrl.paymentUrl}><Payment /></Route>
          <Route exact path={WtUrl.paymentCompletionUrl}><Completion /></Route>
          <Route exact path={WtUrl.paymentSuccessUrl}><PaymentSuccessUIAdapter /></Route>
          <Route exact path={WtUrl.paymentFailedUrl}><PaymentFailedUIAdapter /></Route>

          <Route exact path={WtUrl.wtdPaymentUrl}><WTDUIAdapter /></Route>

          <Route exact path={WtUrl.analyticsUrl}><AnalyticsUIAdapter /></Route>
          {/* End of Business Panel Routes */}

          {/* Static Pages Routes */}
          <Route exact path={WtUrl.testingUrl}><TestingUIAdapter /></Route>

          <Route exact path={WtUrl.whatsappBusinessApiUrl}><DummyUIAdapter /></Route>

          <Route exact path={WtUrl.crmUrl}><DummyUIAdapter /></Route>
          <Route exact path={WtUrl.intergrationUrl}><DummyUIAdapter /></Route>
          <Route exact path={WtUrl.ecommerceSectorUrl}><ECommerceSectorUIAdapter /></Route>
          <Route exact path={WtUrl.educationalSectorUrl}><EducationalSectorUIAdapter /></Route>
          <Route exact path={WtUrl.healthSectorUrl}><HealthSectorUIAdapter /></Route>
          <Route exact path={WtUrl.tourTravelSectorUrl}><TourTravelSectorUIAdapter /></Route>
          <Route exact path={WtUrl.pricingUrl}>
            <Helmet>
              <title>{WtTheme.pricingTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.pricingTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.pricingTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.pricingTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.pricingTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.pricingTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <PricingUIAdapter />
          </Route>
          <Route exact path={WtUrl.quickGuideUrl}>
            <Helmet>
              <title>{WtTheme.quickGuideTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.quickGuideTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.quickGuideTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.quickGuideTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.quickGuideTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.quickGuideTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <QuickGuideUIAdapter />
          </Route>
          <Route exact path={WtUrl.chatbotsUrl}>
            <Helmet>
              <title>{WtTheme.chatbotTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.chatbotTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.chatbotTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.chatbotTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.chatbotTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.chatbotTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <ChatBotsUIAdapter />
          </Route>
          <Route exact path={WtUrl.multiAgentsUrl}>
            <Helmet>
              <title>{WtTheme.multiAgentTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.multiAgentTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.multiAgentTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.multiAgentTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.multiAgentTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.multiAgentTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <MultiAgentUIAdapter />
          </Route>
          <Route exact path={WtUrl.broadcastMessageUrl}>
            <Helmet>
              <title>{WtTheme.broadcastMsgTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.broadcastMsgTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.broadcastMsgTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.broadcastMsgTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.broadcastMsgTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.broadcastMsgTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <BroadcastMsgUIAdapter />
          </Route>
          <Route exact path={WtUrl.clientsUrl}>
            <Helmet>
              <title>{WtTheme.clientsTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.clientsTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.clientsTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.clientsTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.clientsTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.clientsTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <ClientsUIAdapter />
          </Route>
          <Route exact path={WtUrl.supportUrl}>
            <Helmet>
              <title>{WtTheme.supportTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.supportTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.supportTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.supportTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.supportTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.supportTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <SupportUIAdapter />
          </Route>
          <Route exact path={WtUrl.termsOfServiceUrl}>
            <Helmet>
              <title>{WtTheme.termsOfServiceTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.termsOfServiceTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.termsOfServiceTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.termsOfServiceTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.termsOfServiceTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.termsOfServiceTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <TermsOfServiceUIAdapter />
          </Route>
          <Route exact path={WtUrl.privacyPolicyUrl}>
            <Helmet>
              <title>{WtTheme.privacyPolicyTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.privacyPolicyTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.privacyPolicyTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.privacyPolicyTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.privacyPolicyTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.privacyPolicyTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <PrivacyPolicyUIAdapter />
          </Route>
          <Route exact path={WtUrl.cancellationUrl}>
            <Helmet>
              <title>{WtTheme.cancellationTitle + " - " + WtTheme.SiteName}</title>
              <meta name="description" content={WtTheme.cancellationTitle + ". " + WtTheme.SiteDescription} />
              <meta name="keywords" content={WtTheme.commonKeywords} />
              <meta property="og:title" content={WtTheme.cancellationTitle + " - " + WtTheme.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={WtTheme.cancellationTitle + ". " + WtTheme.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={WtTheme.cancellationTitle + " - " + WtTheme.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={WtTheme.cancellationTitle + ". " + WtTheme.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <CancellationUIAdapter />
          </Route>
          {/* End of Static Pages Routes */}

          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  )
}
