/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Tooltip, Zoom } from '@mui/material'
import { InfoOutlined, AddOutlined, ContentPaste, LaunchOutlined, Close } from '@mui/icons-material'
import { createCallbackURL, fetchAPIGeneratedKey, getAPIKeyAndWebhookAPI } from '../../controller/APIs'
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { DemoDialog, WtLoader } from '../../controller/BaseTool'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'


export const ApiIntegration = () => {
    const notificationBarStatus = useContext(globalContext)
    const [webHookURL, setWebHookURL] = useState("")
    const [apiKey, setApiKey] = useState("")

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)

    const handleWebHookURL = (val) => { setWebHookURL(val) }

    const addWebHookURL = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = { "webhookUrl": webHookURL?.trim() }
            const response = await createCallbackURL(data)
            if (response.status === 202) {
                setWebHookURL(webHookURL?.trim())
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    const removeWebHookURL = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = { "webhookUrl": '' }
            const response = await createCallbackURL(data)
            if (response.status === 202) {
                setWebHookURL('')
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }

    const getAPIKeyAndWebhook = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await getAPIKeyAndWebhookAPI()
            if (response.status === 200) {
                setWebHookURL(response.data.data.webhook)
                setApiKey(response.data.data.apiKey)
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }

    const getAPIGeneratedKey = async () => {
        handleWTLoader.handleWTLoader(true)
        setApiKey("")
        try {
            const response = await fetchAPIGeneratedKey()
            if (response.status === 201) {
                setApiKey(response.data.data)
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }

    useEffect(() => { getAPIKeyAndWebhook() }, [])

    const copyToClipboard = (evt) => { navigator.clipboard.writeText(evt) }

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: (confirm) => setOpenDemoDialog(false) })


    const profile_base_view = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    const profile_menu_item = {
        flex: '0.85'
    }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>API Integration &nbsp;
                                <Tooltip title="An application programming interface (API) is a messenger that processes requests and ensures seamless functioning of enterprise systems. API enables interaction between data, applications, and devices." placement="bottom-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }}>
                                    <InfoOutlined style={{ color: WtColor.TextLightGrey, marginTop: "-5px", fontSize: '15px' }} />
                                </Tooltip>
                            </h5>
                        </div>
                        <div style={{ marginTop: "6px" }}>
                            <Link to={{ pathname: "https://documenter.getpostman.com/view/370600/2s83f1KG3L" }} target="_blank" style={{ color: WtColor.TextMidGrey, marginTop: "50px", fontSize: '15px' }} className="me-5 fw-bold"> 📢WTB Public API</Link>
                        </div>
                        <div>
                            <button onClick={async () => await getAPIGeneratedKey()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius }}><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Generate Key</button>
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <Container className="app_list" style={{ height: "610px" }} fluid>
                            <div className='mt-2'>
                                <p className='fw-bold' style={{ color: WtColor.TextMidGrey }}>Developer Documentation</p>
                                <Link to={{ pathname: "https://documenter.getpostman.com/view/370600/2s83f1KG3L" }} target="_blank" style={{ color: WtColor.ProcessingBlue, marginTop: "50px" }} className="me-5 fw-bold"> Read the API documentation here <LaunchOutlined style={{ fontSize: "15px" }} /></Link>
                            </div>
                            <Row className="mt-4">
                                <Col>
                                    <div className="p-3 rounded-3" style={{ border: "1px solid " + WtColor.LineColor }}>
                                        <Form.Label htmlFor="webhook_url" className="fw-bold" style={{ color: WtColor.TextMidGrey }}>Enter Webhook URL</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control defaultValue={webHookURL} value={webHookURL} id="webhook_url" onChange={(e) => handleWebHookURL(e.target.value)} placeholder="Enter Webhook URL" />
                                            <Tooltip title="Copy to Clipboard" placement="bottom"><InputGroup.Text id="copy_to_clipboard" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }} className="hand"><ContentPaste onClick={() => copyToClipboard(webHookURL)} /></InputGroup.Text></Tooltip>
                                            {
                                                webHookURL !== "" ?
                                                    <>
                                                        <Tooltip title="Add Webhook" placement="bottom"><InputGroup.Text id="add_webhook" onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? addWebHookURL() : openDemoDialogBox()} style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }} className="hand"><AddOutlined /> Add</InputGroup.Text></Tooltip>
                                                        <Tooltip title="Remove Webhook" placement="bottom"><InputGroup.Text id="remove_webhook" onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? removeWebHookURL() : openDemoDialogBox()} style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }} className="hand"><Close /> Remove</InputGroup.Text></Tooltip>
                                                    </> : ""
                                            }
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <div className="p-3 rounded-3" style={{ border: "1px solid " + WtColor.LineColor }}>
                                        <Form.Label htmlFor="webhook_url" className="fw-bold" style={{ color: WtColor.TextMidGrey }}>Generate API Key</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control defaultValue={apiKey} value={apiKey} id="webhook_url" onChange={(e) => handleWebHookURL(e.target.value)} style={{ background: "#fff" }} disabled placeholder="Generate API Key" />
                                            <Tooltip title="Copy to Clipboard" placement="bottom"><InputGroup.Text id="copy_to_clipboard" style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }} className="hand"><ContentPaste onClick={() => copyToClipboard(apiKey)} /></InputGroup.Text></Tooltip>
                                            <Tooltip title="Click to Generate API Key" placement="bottom"><InputGroup.Text id="add_webhook" onClick={async () => await getAPIGeneratedKey()} style={{ background: WtColor.LineColor, color: WtColor.TextMidGrey }} className="hand"><AddOutlined /> Generate</InputGroup.Text></Tooltip>
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}