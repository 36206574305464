import React, { useState, useEffect } from 'react'
import './index.css'
import { Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Box, TextField, InputAdornment, ListItemAvatar, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { WtTheme } from '../../controller/theme'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { WhatsApp } from '@mui/icons-material'
import { Player } from '@lottiefiles/react-lottie-player'
import { fetchBusinessInfo, fetchPlanUsage, sendTrailWhatsAppMessage } from '../../controller/APIs'
import { AppOnboardingDialog } from '../../controller/BaseTool'
import { DemoApp } from '../Demo/App'
import { UserNotificationTopBarUI } from '../../controller/BaseToolUI'
import { WtColor } from '../../constants/colours'

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

export const Dashboard = () => {
    const [msgBar, setMsgBar] = useState(false)
    const handleMsgBar = () => { setMsgBar(!msgBar) }
    useEffect(() => {
        if (localStorage.getItem("userWhatsAppVerified") === 'true' && localStorage.getItem("userEmailVerified") === 'true') {
            setMsgBar(false)
        } else { setMsgBar(true) }
    }, [])

    const checkOnBoardingStatus = async () => {
        try {
            const response = await fetchBusinessInfo()
            if (response.status === 200) {
                localStorage.setItem("onboardingStatus", response?.data?.data?.business?.onboardingStatus)
            }
        } catch (error) { }
    }

    const checkPlan = async () => {
        const response = await fetchPlanUsage()
        if (response.status === 200) {
            localStorage.setItem('activePlan', response?.data?.data[0]?.planName)
        }
    }

    useEffect(() => { checkPlan() }, [])
    useEffect(() => { checkOnBoardingStatus() }, [])
    useEffect(() => { if (localStorage.getItem('userActivity') === 'true') localStorage.setItem('userActivity', 'false') }, [])
    return (
        <Box className="app_list" sx={{ width: '100%', height: '100vh' }}>
            <UserNotificationTopBarUI />
            <div>
                {
                    (localStorage.getItem("userRole") === "owner") &&
                    (msgBar === true) &&
                    <div className="pt-3" style={{ background: WtColor.BaseColor, height: "63px" }}>
                        <p className="text-center" style={{ fontSize: "16px", fontWeight: "500" }}>
                            <span className="text-white">Your account verification is pending. Verify Now</span>&nbsp;&nbsp;
                            {
                                (localStorage.getItem("userWhatsAppVerified") === 'true') ? (<></>) : (<Link to={"/otp-verification?whatsapp=" + localStorage.getItem("userWhatsApp")} target="_blank" style={{ color: WtColor.BaseColor, background: "#fff", marginTop: "-6px" }} className="btn btn-sm fw-bold">WhatsApp</Link>)
                            }
                            &nbsp;&nbsp;
                            {
                                (localStorage.getItem("userEmailVerified") === 'true') ? (<></>) : (<Link to={{ pathname: "https://mail.google.com/" }} target="_blank" style={{ color: WtColor.BaseColor, background: "#fff", marginTop: "-6px" }} className="btn btn-sm fw-bold">Email</Link>)
                            }
                            <CloseOutlinedIcon onClick={handleMsgBar} className="float-end hand text-white me-4" />
                        </p>
                    </div>
                }
            </div>

            <div className="pt-5 pe-5 ps-5">
                <p className="text-center" style={{ color: WtColor.BaseColor, fontSize: "19px", fontWeight: "500" }}>Hi {localStorage.getItem("userName")}!<span style={{ fontSize: "30px" }}>👋</span></p>
                <p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: "22px", fontWeight: "500", marginTop: "-15px" }}>Welcome to WhatsTool Business</p>
                <p className="text-center mt-3" style={{ color: WtColor.TextMidGrey, fontSize: "22px", fontWeight: "500" }}>{localStorage.getItem("userName")}, We are super excited to onboard you</p>
            </div>

            {
                (!localStorage.getItem('c1') || localStorage.getItem('c1') === '' || localStorage.getItem('isDemo') === 'true') &&
                <div className="mt-5 mb-5"><DemoApp /></div>
            }

            <div className="mt-5 mb-5">
                {(localStorage.getItem("onboardingStatus") !== "completed") ? (<div className="pb-3"><RootOnBoardingStatus /></div>) : (<></>)}
            </div>

            <div style={{ marginTop: "80px" }}><TryOutAutomatedWhatsApp /></div>

            <VideoIframe />

            <BlogViewDashboard />

            {localStorage.getItem('activePlan') === 'Free' ? <UpgradeToPremiumCTA /> : ''}
        </Box>
    )
}

export const RootOnBoardingStatus = () => {
    const [appOnboardingDialog, setAppOnboardingDialog] = useState({ open: false, onClose: null })
    const openAppOnboardingDialog = () => {
        setAppOnboardingDialog({
            open: true, onClose: (confirm) => {
                setAppOnboardingDialog(false)
                if (confirm) { setAppOnboardingDialog(false) }
            }
        })
    }
    useEffect(() => {
        if (localStorage.getItem("onboardingStatus") && localStorage.getItem("onboardingStatus") !== "waiting_for_agent_response" && localStorage.getItem("onboardingStatus") !== 'pending') openAppOnboardingDialog()
    }, [])
    return (
        <Container className="mb-5" style={{ width: '98%' }} fluid>
            <Row className="p-4" style={{ border: '1px solid ' + WtColor.LineColor, borderRadius: '8px' }}>
                <Col>
                    <div>
                        <p className="">
                            <img src={WtTheme.ImageBaseUrl + '/OnBoarding.gif'} className="img-fluid" style={{ width: "300px", borderRadius: '8px' }} alt={WtTheme.SiteName} />
                        </p>
                    </div>
                </Col>
                <Col>
                    <div className="mt-5">
                        <p style={{ color: WtColor.TextMidGrey, fontSize: '20px', fontWeight: '500' }}>Setup your business number</p>
                        <p style={{ color: WtColor.TextMidGrey, fontSize: '16px', paddingTop: "30px", }}>
                            You are just 3 steps away from getting the power of WhatsApp API.<br />
                            Lets setup now to enable your business with chatbots and power of WhatsApp API.
                        </p>
                        <p className=" mt-5">
                            <button onClick={openAppOnboardingDialog} className="btn btn-sm theme_bg text-white rounded-2" style={{ paddingLeft: "40px", paddingRight: '40px', fontSize: "20px" }}>Setup Now</button>
                        </p>
                    </div>
                </Col>
            </Row>
            <AppOnboardingDialog open={appOnboardingDialog.open} onClose={appOnboardingDialog.onClose} />
        </Container>
    )
}

export const TryOutAutomatedWhatsApp = () => {
    const history = useHistory()

    const [whatsAppNo, setWhatsAppNo] = useState("")
    const minNoLength = 7
    const maxNoLength = 15
    const [sendBtnAction, setSendBtnAction] = useState(1)
    const handleWhatsAppNo = (e) => {
        let number = e.replace(/[^0-9+]/ig, "")
        setWhatsAppNo(number)
        if (whatsAppNo.length >= minNoLength && whatsAppNo.length <= maxNoLength) {
            setSendBtnAction(0)
        } else {
            setSendBtnAction(1)
        }
    }

    const [items, setItems] = useState([])
    const getItems = async () => {
        try {
            const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
            setItems(await response.json())
        } catch (error) { }
    }

    const [currentCountryCode, setCurrentCountryCode] = useState("+91")

    const [filteredCountryCodeList, setFilteredCountryCodeList] = useState([])
    const [searchInput, setSearchInput] = useState('')

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = items.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredCountryCodeList(filteredData)
        } else {
            setFilteredCountryCodeList(items)
        }
    }

    const [openCountryDialogBox, setOpenCountryDialogBox] = useState(false)
    const handleCountryCodeDialog = async () => {
        setOpenCountryDialogBox(!openCountryDialogBox)
        await getItems()
        setFilteredCountryCodeList(items)
    }
    const handleCountryCodeSelection = (e) => {
        setOpenCountryDialogBox(!openCountryDialogBox)
        setCurrentCountryCode(e)
    }


    const [dialogStatus, setDailogStatus] = useState(1)
    const [openTryWADialogBox, setOpenTryWADialogBox] = useState(false)

    const sendTrialWAMsg = async () => {
        if (localStorage.getItem("userAuthToken")) {
            try {
                let data = "to=" + currentCountryCode + whatsAppNo
                await sendTrailWhatsAppMessage(data)
                // alert(JSON.stringify(response.data.message))
                setDailogStatus(1)
                setOpenTryWADialogBox(true)
            } catch (error) {
                setDailogStatus(4)
                setOpenTryWADialogBox(true)
            }
        } else {
            setDailogStatus(2)
            setOpenTryWADialogBox(true)
        }
    }

    const handleSendTrialBtn = () => {
        if (!localStorage.getItem("userAuthToken")) {
            setDailogStatus(2)
            setOpenTryWADialogBox(true)
        } else {
            setDailogStatus(5)
            setOpenTryWADialogBox(true)
        }
    }
    const handleCancelSend = () => {
        setDailogStatus(3)
        // setOpenTryWADialogBox(!openTryWADialogBox)
    }
    const JustSendWAOnce = async () => {
        handleToClose()
        try {
            let data = "to=" + currentCountryCode + whatsAppNo
            await sendTrailWhatsAppMessage(data)
            // alert(JSON.stringify(response.data.message))
            setDailogStatus(1)
            setOpenTryWADialogBox(true)
        } catch (error) {
            setDailogStatus(4)
            setOpenTryWADialogBox(true)
        }
    }
    const handleToClose = () => {
        setOpenTryWADialogBox(false)
    }
    const doLogin = () => {
        history.push('/login')
    }

    useEffect(() => {
        getItems()
    }, [])
    return (
        <Container className="tryWhatsApp mt-5 pt-3 pe-3 ps-3 pb-0 mb-0" fluid>
            <div className="pt-5 pe-5 ps-5 pb-0 mb-0 rounded-3 innerBox" style={{ border: "0.4px solid " + WtColor.TextColor }}>
                <Row className="innerBox">
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} className="innerBox">
                        <div className="innerBox">
                            <p style={{ color: WtColor.TextMidGrey, fontSize: "18px", fontWeight: "500" }}>Test Power of WhatsApp API from here</p>
                            <p style={{ color: WtColor.TextMidGrey, fontSize: "14px", fontWeight: "400" }}>Just enter a valid mobile number and select the correct country code and hit the send button.</p>
                            <Row className="mt-5 innerBox">
                                <Col xxl={9} xl={9} lg={9} md={9} sm={12} xs={12} style={{ display: "flex" }}>
                                    <TextField label="WhatsApp Number" sx={{ width: '100ch' }} size="small" type="tel" minLength={7} maxLength={15}
                                        value={whatsAppNo} onChange={(e) => handleWhatsAppNo(e.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment onClick={handleCountryCodeDialog} className="hand" position="start">{currentCountryCode} | </InputAdornment>,
                                        }}
                                    />
                                </Col>
                                {
                                    ((sendBtnAction === 0) && localStorage.getItem("userAuthToken")) ? (
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}><button onClick={sendTrialWAMsg} className="btn btn-md text-white theme_bg ps-4 pe-4" style={{ display: "flex" }}><WhatsApp />&nbsp;&nbsp;Send</button></Col>

                                    ) : (
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}><button onClick={handleSendTrialBtn} className="btn btn-md text-white theme_bg ps-4 pe-4" style={{ display: "flex" }}><WhatsApp />&nbsp;&nbsp;Send</button></Col>

                                    )
                                }
                            </Row>
                            <Dialog open={openCountryDialogBox} TransitionComponent={Transition} keepMounted onClose={handleCountryCodeDialog} >
                                <DialogTitle><input value={searchInput} onChange={(e) => searchItems(e.target.value)} type="tel" className="rounded-0 form-control" placeholder="Select Country Code" /></DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        {
                                            filteredCountryCodeList.map((v, i) => {
                                                return (
                                                    <ListItemButton className="hand" key={i}>
                                                        <ListItem onClick={(e) => handleCountryCodeSelection(v.dial_code)} >
                                                            <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                                                            <ListItemText primary={v.name} secondary={v.dial_code} />
                                                        </ListItem>
                                                    </ListItemButton>
                                                )
                                            })
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <button className="theme bg-white rounded-3 pe-5 ps-5 pt-0 pb-0" style={{ border: "1px solid " + WtColor.BaseColor }} onClick={handleCountryCodeDialog}>Cancel</button>
                                </DialogActions>
                            </Dialog>

                            <Dialog className="tryWhatsApp" open={openTryWADialogBox} TransitionComponent={Transition} keepMounted onClose={handleToClose} >
                                {(() => {
                                    switch (dialogStatus) {
                                        case 1: return (
                                            <>
                                                <DialogTitle className="text-center">Message Sent Successfully</DialogTitle>
                                                <DialogContent>
                                                    <p className="text-center"><Player autoplay loop src="https://assets8.lottiefiles.com/packages/lf20_zraaxnew.json" className="items-lottie"></Player></p>
                                                </DialogContent>
                                                <DialogActions>
                                                    <button className="theme bg-white rounded-3 btn btn-sm pe-5 ps-5 pt-2 pb-2" style={{ border: "1px solid " + WtColor.BaseColor }} onClick={handleToClose}>Close</button>
                                                </DialogActions>
                                            </>
                                        )
                                        case 2: return (
                                            <>
                                                <DialogTitle>Please login to send</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        You are just a minute away from sending message from WhatsApp API
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <button className="theme bg-white rounded-3 btn btn-sm pe-5 ps-5 pt-2 pb-2" style={{ border: "1px solid " + WtColor.BaseColor }} onClick={handleCancelSend}>Cancel</button>
                                                    <button className="theme_bg btn btn-sm rounded-3 pe-5 ps-5 pt-2 pb-2 text-white" onClick={doLogin}>Login</button>
                                                </DialogActions>
                                            </>
                                        )
                                        case 3: return (
                                            <>
                                                <DialogTitle>It's Ok, Just send Now</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        Send now and you can login later. No hurry 🙏
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <button className="theme bg-white rounded-3 btn btn-sm pe-5 ps-5 pt-2 pb-2" style={{ border: "1px solid " + WtColor.BaseColor }} onClick={handleToClose}>Close</button>
                                                    <button className="theme_bg btn btn-sm rounded-3 pe-5 ps-5 pt-2 pb-2 text-white" onClick={JustSendWAOnce}>Send Now</button>
                                                </DialogActions>
                                            </>
                                        )
                                        case 4: return (
                                            <>
                                                <DialogTitle className="text-center">Invalid WhatsApp No.</DialogTitle>
                                                <DialogContent >
                                                    <p className="text-center"><Player autoplay loop src="https://assets7.lottiefiles.com/private_files/lf30_7a52nvr3.json" className="items-lottie"></Player></p>
                                                </DialogContent>
                                                <DialogActions>
                                                    <button className="theme bg-white rounded-3 btn btn-sm pe-5 ps-5 pt-2 pb-2" style={{ border: "1px solid " + WtColor.BaseColor }} onClick={handleToClose}>Close</button>
                                                </DialogActions>
                                            </>
                                        )
                                        case 5: return (
                                            <>
                                                <DialogTitle>Please Enter WhatsApp No.</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">

                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <button className="theme bg-white rounded-3 btn btn-sm pe-5 ps-5 pt-2 pb-2" style={{ border: "1px solid " + WtColor.BaseColor }} onClick={handleToClose}>Close</button>
                                                </DialogActions>
                                            </>
                                        )
                                        default: return ''
                                    }
                                })()}
                            </Dialog>
                        </div>
                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div>
                            <img style={{ position: "relative", width: "100%", marginBottom: "-100px", top: "-110px" }} src={WtTheme.ImageBaseUrl + "/try_wtb_api.png"} className="img-fluid tryWhatsAppImage" alt={WtTheme.SiteName} />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container >
    )
}

export const VideoIframe = () => {
    const [playVideo, setPlayVideo] = useState(false)
    const handlePlayVideoBtn = () => {
        setPlayVideo(!playVideo)
    }
    return (
        <div className="mt-5 mb-5 pt-5 pb-5">
            <p className="text-center">
                {
                    (playVideo === false) ? (
                        <img onClick={handlePlayVideoBtn} src={WtTheme.ImageBaseUrl + "/business_video_01.png"} className="img-fluid rounded-3 hand" width="800px" alt={WtTheme.SiteName} />
                    ) : (
                        <iframe className="rounded-3 iframeVideo" width="800" height="446" src="https://www.youtube-nocookie.com/embed/QhpxVYOBdiI?controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    )
                }
            </p>
        </div>
    )
}

export const BlogViewDashboard = () => {
    var blogJSON_one = [
        { "blogName": "How to Add Your Own Number in Whatsapp Cloud API?", "blogThumbnail": "https://images.tango.us/public/screenshot_e6a26140-fb5b-447e-af65-36627c93c449.png", "blogUrl": "https://blog-business.whatstool.in/how-to-add-your-own-number-in-whatsapp-cloud-api/" },
        { "blogName": "WhatsApp Auto Reply: How To Set Auto Reply In WhatsApp Business?", "blogThumbnail": "https://superblog.supercdn.cloud/site_cuid_cl42o8cru128221np38sipih76/images/frame-928-1661340116965-compressed.png", "blogUrl": "https://blog-business.whatstool.in/whatsapp-auto-reply-how-to-set-auto-reply-in-whatsapp-business/" },
        { "blogName": "How to Create Templates For WhatsApp Business API?", "blogThumbnail": "https://images.tango.us/public/screenshot_5a6b3c46-467f-4ad9-bd6e-1a5a668cf492?fm=png", "blogUrl": "https://blog-business.whatstool.in/how-to-create-templates-for-whatsapp-business-api/" }
    ]
    return (
        <div className="blogView p-5 mb-5">
            <p style={{ color: WtColor.TextMidGrey, fontSize: "20px", fontWeight: "500" }}>Read blogs to know more about Whatstool Business</p>
            <div className="blogList">
                <Row>
                    {
                        blogJSON_one.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12} key={i}>
                                    <div className="p-2 ms-4 me-4">
                                        <Link to={{ pathname: v.blogUrl }} target="_blank">
                                            <img src={v.blogThumbnail} className="img-fluid rounded-3" style={{ width: "100%", height: "240px", boxShadow: "0px 0px 6px " + WtColor.BaseColor }} alt={WtTheme.SiteName} />
                                            <p className="mt-3" style={{ color: WtColor.TextMidGrey, fontSize: "18px", fontWeight: "500" }}>{v.blogName}</p>
                                            <p className="mt-2" style={{ color: WtColor.TextLightGrey, fontSize: "14px", fontWeight: "500" }}>2 min read</p>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export const BlogViewHome = () => {
    var blogJSON_two = [
        { "blogName": "Why choose WhatsApp Broadcast Message instead of Email/SMS", "blogThumbnail": "https://d3qv1kdjsarkxh.cloudfront.net/site_cuid_cl4ib1qly100451lunqpy2kuxn/images/frame-58-4-1655467046886-compressed.png", "blogUrl": "https://blog-business.whatstool.in/why-choose-whatsapp-broadcast-message-instead-of-email-sms/" },
        { "blogName": "How to send Interactive WhatsApp Messages with Action Button", "blogThumbnail": "https://d3qv1kdjsarkxh.cloudfront.net/site_cuid_cl42o8cru128221np38sipih76/images/actions-1-1656668485072-compressed.png", "blogUrl": "https://blog-business.whatstool.in/how-to-send-interactive-whatsapp-messages-with-action-button/" },
        { "blogName": "Why All Direct to Consumer D2C Businesses Should Have a WhatsApp Number.", "blogThumbnail": "https://d3qv1kdjsarkxh.cloudfront.net/site_cuid_cl42o8cru128221np38sipih76/images/group-688-1656572113412-compressed.png", "blogUrl": "https://blog-business.whatstool.in/why-all-direct-to-consumer-d2c-businesses-should-have-a-whatsapp-number/" }
    ]
    return (
        <div className="blogView p-5 mb-5">
            <p style={{ color: WtColor.TextMidGrey, fontSize: "20px", fontWeight: "500" }}>Read blogs to know more about Whatstool Business</p>
            <div className="blogList">
                <Row>
                    {
                        blogJSON_two.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12} key={i}>
                                    <div className="p-2">
                                        <Link to={{ pathname: v.blogUrl }} target="_blank">
                                            <img src={v.blogThumbnail} className="img-fluid rounded-3" style={{ width: "100%", height: "200px", boxShadow: "0px 0px 6px " + WtColor.BaseColor }} alt={WtTheme.SiteName} />
                                            <p className="mt-3" style={{ color: WtColor.TextMidGrey, fontSize: "18px", fontWeight: "500" }}>{v.blogName}</p>
                                            <p className="mt-2" style={{ color: WtColor.TextLightGrey, fontSize: "14px", fontWeight: "500" }}>2 min read</p>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export const UpgradeToPremiumCTA = () => {
    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <Container className="mb-5 pb-5">
            <div className='rounded-3 pt-5 pb-5 ps-5 pe-5 ms-5 me-5' style={{ background: WtColor.NeonGreenColor }}>
                <p className="text-center mt-5" style={{ fontSize: "30px", color: WtColor.TextDarkColor, fontWeight: "700" }}>Upgrade to Premium and Enjoy many more Exciting Features</p>
                <p className="text-center mt-5" style={{ fontSize: "26px", color: WtColor.BaseColor, fontWeight: "700" }}>You are in Free Plan now</p>
                <p className="text-center mt-5">
                    <button onClick={() => openURL('/dashboard/payment/plan-subscription')} className="btn btn-sm theme_bg text-white rounded-2" style={{ paddingLeft: "60px", paddingRight: '60px', paddingTop: "10px", paddingBottom: "10px", fontSize: "20px" }}>Upgrade Now</button>
                </p>
            </div>
        </Container>
    )
}