/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './index.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { WtTheme } from '../../controller/theme'
import { BlogViewHome, VideoIframe } from '../Dashboard/App'
import { FAQ, FeaturesNavbar, Testimonial } from '../Static/ui'
import { PricingCTA } from '../Static/PricingUI'
import { useState } from 'react'
import { DemoForHomePageApp } from '../Demo/App'
import { WtUrl } from '../../constants/urls'
import { Footer, Header } from '../../controller/BaseToolUI'
import { WtColor } from '../../constants/colours'
import { WtString } from '../../constants/strings'


// Home Page UI
export const Home = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <section className="home">
            <Header />
            <Container className="hOne"><HomeOne /></Container>
            <Link to={"/signup"}><img src={WtTheme.ImageBaseUrl + "/free_offer.png"} className='img-fluid' alt={WtTheme.SiteName} /></Link>
            <Container className="clients" fluid><Client /></Container>
            <Container className="hFive pb-5" style={{ marginTop: "100px" }}><DemoForHomePageApp /></Container>
            <Container className="clients" fluid><ECommerceHomepage /></Container>
            {/* <Container className="hFive pb-5" style={{ marginTop: "100px" }}><TryOutAutomatedWhatsApp /></Container> */}
            <Container fluid><FeaturesNavbar /></Container>
            <Container className="hTwo homeTwo" fluid><Container><HomeTwo /></Container></Container>
            <Container className="hThree"><HomeThree /></Container>
            <Container className="hFour homeFour" fluid><Container><HomeFour /></Container></Container>
            <Container className="hFive"><HomeFive /></Container>
            <Container className="hSix homeFour" fluid><Container><HomeSix /></Container></Container>
            <Container className="hFive"><VideoIframe /></Container>
            <Container className="hFive"><BlogViewHome /></Container>
            <Container><Testimonial /></Container>
            <Container><FAQ /></Container>
            <Container><PricingCTA /></Container>
            <Footer />
        </section>
    )
}
export const Client = (props) => {
    const { ClientPage } = props
    return (
        <>
            {
                (ClientPage && ClientPage === true) ? (
                    <Container className="pt-5 pb-5 mt-5 mb-5">
                        <h3 className="text-center">Thousands of Business has trusted us in their growth</h3>
                        <p className="text-center mb-5">Join the journey of Growth on WhatsApp</p>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/clients.png"} className="img-fluid" alt={WtTheme.SiteName} /></p>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container className="pt-5 pb-5">
                        <h3 className="text-center">Thousands of Business has trusted us in their growth</h3>
                        <p className="text-center mb-5">Join the journey of Growth on WhatsApp</p>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/clients.png"} className="img-fluid" alt={WtTheme.SiteName} /></p>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <h4 className="text-center" style={{ marginTop: "12px" }}><Link to={"/signup"} className="theme fw-bold">Join the list now</Link></h4>
                            </Col>
                        </Row>
                    </Container>
                )
            }
        </>
    )
}
export const HomeOne = () => {
    const [homeImg, setHomeImg] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setHomeImg(true)
        }, 4000);
    }, [])
    return (
        <Container className="pb-5" fluid>
            <div className="mt-5">
                <p style={{ fontSize: '14px' }} className="tx1">Powered by WhatsApp Official Business API&nbsp;
                    <img src={WtTheme.ImageBaseUrl + '/green_tick.svg'} style={{ width: "15px", height: "15px", marginTop: '-3px' }} alt={WtTheme.SiteName} />
                </p>
                <p style={{ fontSize: '24px' }} className="tx2 mt-4">WT Business <b>solutions can</b><br />grow your business with WhatsApp</p>
            </div>
            <div style={{ marginTop: '-120px' }}>
                <img alt={WtTheme.SiteName} src={homeImg ? WtTheme.ImageBaseUrl + "/wtb_home_top_banner.png" : WtTheme.ImageBaseUrl + "/wtb_home_top_banner.gif"} className="img-fluid w-100" />
            </div>
        </Container>
    )
}
export const HomeTwo = () => {
    return (
        <Container className="p-5 pTwo" fluid>
            <Row className="pt-5 pTwo2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx theme" style={{ fontSize: '30px' }}>WhatsApp Power for your Business</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>WhatsApp Business API helps reach customers on the most<br />trusted app using WhatsTool platform in web and mobile.</p>
                    <Link to={WtUrl.signupUrl} className="btn btn-sm text-white shadow-lg mt-5 theme_bg fw-bold">Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt={WtTheme.SiteName} src={WtTheme.ImageBaseUrl + "/wtb_02.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeThree = () => {
    return (
        <Container className="p-5 pThree" fluid>
            <Row className="pt-5 pThree2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx theme" style={{ fontSize: '30px' }}>Work Together with<br />Multiple Agents on 1 number</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Your customers are on WhatsApp. All the time. So when they’re<br />looking to buy, and they message you, they’re at their moment<br />of highest intent. Engaging these visitors, get their attention<br />and move them along - on 1 number.</p>
                    <Link to={WtUrl.multiAgentsUrl} className="btn btn-sm text-white shadow-lg mt-5 theme_bg fw-bold">Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt={WtTheme.SiteName} src={WtTheme.ImageBaseUrl + "/wtb_03.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeFour = () => {
    return (
        <Container className="p-5 pFour" fluid>
            <Row className="pt-5 pFour2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx theme" style={{ fontSize: '30px' }}>Communicate, at scale<br />Broadcast Messages</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Customers who message you on WhatsApp are your most<br />engaged customers and prospects. Have personalized<br />conversations with them at scale – so you can engage, qualify,<br />and convert them in real time, day or night.</p>
                    <Link to={WtUrl.broadcastMessageUrl} className="btn btn-sm text-white shadow-lg mt-5 theme_bg fw-bold">Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="text-center"><img alt={WtTheme.SiteName} src={WtTheme.ImageBaseUrl + "/try_wtb_api.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeFive = () => {
    return (
        <Container className="p-5 pFive" fluid>
            <Row className="pt-5 pFive2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx theme" style={{ fontSize: '30px' }}>Build your Bot within a matter of minutes.<br />No codes required</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Setup your Chatbot to interact with users just like any agent<br />would. Create, test, and run your no-code Chatbot. Set a<br />standard with the quality of customer support, engagement,<br />and interaction.</p>
                    <Link to={WtUrl.chatbotsUrl} className="btn btn-sm text-white shadow-lg mt-5 theme_bg fw-bold">Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt={WtTheme.SiteName} src={WtTheme.ImageBaseUrl + "/chatbotHomePageImg.png"} style={{ boxShadow: '0px 0px 6px ' + WtColor.BaseColor, borderRadius: '8px' }} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeSix = () => {
    return (
        <Container className="p-5 pSix" fluid>
            <Row className="pt-5 pSix2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx theme" style={{ fontSize: '30px' }}>Integration with other apps<br />and system of your need </h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Easily integrate with your existing CRM databases,<br />ecommerce stores, payment &amp; logistic partners.</p>
                    <Link to={WtUrl.signupUrl} className="btn btn-sm text-white shadow-lg mt-5 theme_bg fw-bold">Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt={WtTheme.SiteName} src={WtTheme.ImageBaseUrl + "/wtb_06.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const ECommerceHomepage = () => {
    const s1 = { color: WtColor.TextMidGrey, fontSize: "16px" }
    const s2 = { color: WtColor.TextLightGrey, ontSize: '20px', fontWeight: '500' }
    return (
        <Container className="him_ecom_homepage pt-5 pb-5">
            <Row>
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                    <div>
                        <p className="text-center">
                            <img src={WtUrl.imageBaseUrl + '/ecomm_home_anim.gif'} className='img-fluid' alt={WtString.SiteName} />
                        </p>
                    </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                    <div className="ms-4" style={{ marginTop: '-10px' }}>
                        <p className="mt-5" style={{ fontSize: '24px', fontWeight: '500' }}>Sell, Promote and Grow Your E-Commerce Store Online </p>
                        <p style={s2} className="mt-2">Boost Your Sales Connecting with us</p>

                        <p style={s1} className="mt-4">Perform complete Process using single platform. Show your catalog, Get orders, Payment process, Sales update, Customised discount message, Recover abandoned cart, provide customer service, promote your sales and many more.</p>
                        <Link to={WtUrl.ecommerceSectorUrl} className="btn btn-sm text-white theme_bg ps-3 pe-3 rounded-3 fw-bold mt-4">Try Now</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
// End of Home Page UI


