import React, { useState, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '@mui/material'
import { ProfileSidebar } from './ProfileSidebar'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const Profile = () => {
    const notificationBarStatus = useContext(globalContext)
    const [userName, setUserName] = useState(localStorage.getItem("userName"))
    const [businessName, setBusinessName] = useState(localStorage.getItem("bName"))
    const [businessWebsite, setBusinessWebsite] = useState(localStorage.getItem("bWebsite"))

    const handleUserName = (val) => setUserName(val)
    const handleBusinessName = (val) => setBusinessName(val)
    const handleBusinessWebsite = (val) => setBusinessWebsite(val)


    const ProfileStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh',
        width: '94vw'
    }
    return (
        <div className='' style={ProfileStyle}>
            <ProfileSidebar />
            <Container className="profile_menu_item bg-white" fluid>
                <div className="profile_menu_header border-bottom ps-3 pt-3 pb-2">
                    <h6 style={{ color: WtColor.TextDarkColor }}>Profile Information</h6>
                </div>
                <div className="pt-2 ps-4">
                    <p style={{ color: WtColor.TextColor }} className="fw-bold">Profile</p>
                    <p style={{ color: WtColor.TextColor }} className="mt-3">Your Full Name</p>
                    <input type={"text"} onChange={(e) => handleUserName(e.target.value)} value={userName} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Your Full Name" />
                    <p style={{ color: WtColor.TextColor }} className="mt-3">Business Name</p>
                    <input type={"text"} onChange={(e) => handleBusinessName(e.target.value)} value={businessName} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Please enter a display name, this would be displayed in conversation" />
                    <p style={{ color: WtColor.TextColor }} className="mt-3">Business Website</p>
                    <input type={"text"} onChange={(e) => handleBusinessWebsite(e.target.value)} value={businessWebsite} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Please enter a display name, this would be displayed in conversation" />
                    <p style={{ color: WtColor.TextColor }} className="mt-3">Email Address</p>
                    <input type={"text"} disabled value={localStorage.getItem("userEmail")} className="form-control w-50" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Email Address" />
                    <Button style={{ background: WtColor.BaseColor }} className="btn btn-sm mt-3 pt-2 pb-2 ps-4 pe-4 text-white">Update Profile</Button>
                </div>
            </Container>
        </div>
    )
}