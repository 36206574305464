import React from 'react'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'

export const EmptyScreen = () => {
    return (
        <div className='empty_screen' style={{ flex: "0.60", background: WtColor.LineColor }}>
            <p className='text-center' style={{ marginTop: "30%" }}>
                <img src={WtTheme.ImageBaseUrl + "/no_chat.svg"} className='img-fluid' alt={WtTheme.SiteName} />
            </p>
            <p className='text-center' style={{ color: WtColor.TextDarkColor, fontSize: "18px" }}>Click any particular chat to see messages</p>
        </div>
    )
}