import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Slide, } from '@mui/material';

import { useHistory } from 'react-router-dom'
import { WtTheme } from '../../controller/theme';
import { WtColor } from '../../constants/colours';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const Analytics = () => {
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const handleClickOpen = () => { setOpen(true) }
    const handleCloseBtn = () => {
        history.push('/dashboard')
    }
    const handleUpgradeBtn = () => {
        history.push('/dashboard/profile')
    }
    useEffect(() => {
        handleClickOpen()
    }, [])
    return (
        <div>
            <div>
                <Dialog open={open} TransitionComponent={Transition} keepMounted>
                    <DialogTitle className="text-center" style={{ color: WtColor.TextDarkColor }}>Get Analytics of your business performance</DialogTitle>
                    <DialogContent className="ps-5 pe-5 pb-3 pt-1">
                        <p className="text-center" style={{ color: WtColor.TextColor }}>Upgrade to premium to unlock this feature</p>
                        <p className="text-center mt-5">
                            <button onClick={handleCloseBtn} style={{ color: WtColor.BaseColor, border: "1px solid " + WtColor.BaseColor }} className="btn btn-sm mt-1 pt-2 pb-2 ps-4 pe-4 me-1 mb-1">Cancel</button>
                            <button onClick={handleUpgradeBtn} style={{ background: WtColor.BaseColor }} className="btn btn-sm mt-1 pt-2 pb-2 ps-4 pe-4 me-1 mb-1 text-white">Upgrade to Premium</button>
                        </p>
                    </DialogContent>
                </Dialog>
            </div>
            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/analytics_bg.png" alt={WtTheme.SiteName} className="blur_img" />
        </div>
    )
}
