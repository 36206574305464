/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useContext } from 'react'
import { Button, Dialog, CircularProgress } from '@mui/material'
import { Container, Row, Col } from 'react-bootstrap'
import { Country, State, City } from "country-state-city"
import { WtTheme } from '../../controller/theme'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchPlanPriceCalc } from '../../controller/APIs'
import { PaymentGatewayChooseDialog, ShowDateTime, ShowMonth, ShowNextMonth, WtLoader } from '../../controller/BaseTool'
import { useWindowDimensions } from '../useWindowDimensions'
import { WtColor } from '../../constants/colours'
import globalContext from '../../context/global/globalContext'

export const Summary = () => {
    const { width } = useWindowDimensions()

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    function useQuery() {
        const { search } = useLocation()
        return useMemo(() => new URLSearchParams(search), [search])
    }
    let query = useQuery()
    let paymentUrlType = query.get("paymentUrlType")
    let planId = query.get("planId")
    let validity = query.get("validity")
    let extValidity = query.get("extValidity")
    let currency = query.get("currency")
    let billId = query.get("billId")
    let qty = query.get("qty")

    const [planPriceInfo, setPlanPriceInfo] = useState([])
    const getPriceInfoFromCalc = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            let val = 1
            let exVal = parseInt(extValidity)
            if (validity === "monthly") { val = (1 + exVal) }
            if (validity === "quarterly") { val = (3 + exVal) }
            if (validity === "yearly") { val = (12 + exVal) }
            let data = { "planId": planId, "validity": val }
            const response = await fetchPlanPriceCalc(data)
            if (response.status === 200) {
                setPlanPriceInfo(response.data.data)
                handleWTLoader.handleWTLoader(false)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    useEffect(() => { getPriceInfoFromCalc() }, [])

    const [billingInfoValues, setBillingInfoValues] = useState({ billingName: localStorage.getItem("bName") ? localStorage.getItem("bName") : "", billingAddress: '', billingPincode: '', billingMobileNo: localStorage.getItem("c1") ? localStorage.getItem("c1") : "", billingEmailId: localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : "", billingTaxType: '', billingTaxId: '' })
    const handleBillingInfoValues = (prop) => (event) => { setBillingInfoValues({ ...billingInfoValues, [prop]: event.target.value }) }

    const Countries = Country.getAllCountries()
    const [countryId, setCountryId] = useState("")
    const handleCountryCodeId = (e) => { setCountryId(e.target.value) }

    const States = State.getAllStates()
    const [stateId, setStateId] = useState("")
    const handleStateCode = (e) => { setStateId(e.target.value) }

    const [cityName, setCityName] = useState("")
    const Cities = City.getAllCities()
    const handleCity = (e) => { setCityName(e.target.value) }


    const history = useHistory()
    const openURL = (url) => { history.push(url) }


    const [paymentUrlR, setPaymentUrlR] = useState(undefined)
    const [paymentUrlS, setPaymentUrlS] = useState(undefined)
    const [validityValue, setValidityValue] = useState(undefined)
    useEffect(() => {
        let buyPlanUrl_R = "/payments/razorpay/buy-plan"
        let wtbSetupUrl_R = "/payments/razorpay/wtb-setup"
        let d360SecurityDepositUrl_R = "/payments/razorpay/d360-security-deposit"
        let chatAddOnUrl_R = "/payments/razorpay/chats-addon"
        let monthlyBillUrl_R = "/payments/razorpay/monthly-bill"
        let buyAgentUrl_R = "/payments/razorpay/buy-agent"

        let buyPlanUrl_S = "/payments/stripe_payment_init"
        let wtbSetupUrl_S = "/payments/wtb-setup"
        let d360SecurityDepositUrl_S = "/payments/stripe/d360-security-deposit"
        let chatAddOnUrl_S = "/payments/chats-addon"
        let monthlyBillUrl_S = "/payments/monthly_bill_payment"
        let buyAgentUrl_S = "/payments/razorpay/buy-agent"

        if (paymentUrlType === "buyPlanUrl") {
            setPaymentUrlR(buyPlanUrl_R)
            setPaymentUrlS(buyPlanUrl_S)
        }
        if (paymentUrlType === "wtbSetupUrl") {
            setPaymentUrlR(wtbSetupUrl_R)
            setPaymentUrlS(wtbSetupUrl_S)
        }
        if (paymentUrlType === "d360SecurityDepositUrl") {
            setPaymentUrlR(d360SecurityDepositUrl_R)
            setPaymentUrlS(d360SecurityDepositUrl_S)
        }
        if (paymentUrlType === "chatAddOnUrl") {
            setPaymentUrlR(chatAddOnUrl_R)
            setPaymentUrlS(chatAddOnUrl_S)
        }
        if (paymentUrlType === "monthlyBillUrl") {
            setPaymentUrlR(monthlyBillUrl_R)
            setPaymentUrlS(monthlyBillUrl_S)
        }
        if (paymentUrlType === "buyAgentUrl") {
            setPaymentUrlR(buyAgentUrl_R)
            setPaymentUrlS(buyAgentUrl_S)
        }

        let exVal = parseInt(extValidity)
        if (validity === "monthly") {
            if (extValidity === 0) {
                setValidityValue(1)
            } else {
                setValidityValue(1 + exVal)
            }
        }
        if (validity === "quarterly") {
            if (extValidity === 0) {
                setValidityValue(3)
            } else {
                setValidityValue(3 + exVal)
            }
        }
        if (validity === "yearly") {
            if (extValidity === 0) {
                setValidityValue(12)
            } else {
                setValidityValue(12 + exVal)
            }
        }
    }, [validity])

    // Proceed for Payment
    let stripePaymentObj = {
        "paymentUrl": paymentUrlS,
        "planId": planId,
        "validity": validityValue,
        "qty": qty,
        "amount": planPriceInfo?.amountTobePaid,
        "billId": billId,
        "planName": planPriceInfo?.planName
    }
    let razorpayPaymentObj = {
        "paymentUrl": paymentUrlR,
        "planId": planId,
        "validity": validityValue,
        "qty": qty,
        "amount": planPriceInfo?.amountTobePaid,
        "billId": billId,
        "planName": planPriceInfo?.planName
    }
    const proceedForPayment = async () => {
        openPaymentGatewayOptDialogBox(razorpayPaymentObj, stripePaymentObj)
    }
    // End of Proceed for Payment


    const [openPaymentGatewayOptDialog, setOpenPaymentGatewayOptDialog] = useState({ open: false, onClose: null })
    const openPaymentGatewayOptDialogBox = (razorpayObj, stripeObj) => {
        setOpenPaymentGatewayOptDialog({
            open: true, razorpayObj: razorpayObj, stripeObj: stripeObj, onClose: (confirm) => {
                setOpenPaymentGatewayOptDialog(false)
            }
        })
    }
    return (
        <div className='profile_base_view'>
            <Container className="bg-white" style={{ display: "flex", flexDirection: "column", flex: "1.0" }} fluid>
                <div className="profile_menu_item bg-white">
                    {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                    <div className="profile_menu_header border-bottom ps-3 pt-3 pb-2">
                        <h6 style={{ color: WtColor.TextDarkColor }}>Billing Information</h6>
                    </div>
                    <div className="pt-2 ps-4">
                        <Row className="mt-3" style={{ width: "90%" }}>
                            {
                                (width <= 780) ?
                                    ""
                                    :
                                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} className="mb-3">
                                        <div className="pe-5">
                                            <p style={{ color: WtColor.TextColor }}>Billing Name </p>
                                            <input type={"text"} value={billingInfoValues.billingName} onChange={handleBillingInfoValues('billingName')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Billing Name" />
                                            <p style={{ color: WtColor.TextColor }} className="mt-3">Billing address </p>
                                            <input type={"text"} value={billingInfoValues.billingAddress} onChange={handleBillingInfoValues('billingAddress')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Billing Address" />
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">Country</p>
                                                    <select className="form-control" onChange={(e) => handleCountryCodeId(e)} style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }}>
                                                        <option value="">Select Country</option>
                                                        {
                                                            Countries.map((v, i) => {
                                                                return (<option value={v.isoCode} key={i}>{v.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </Col>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">State</p>
                                                    <select className="form-control" onChange={(e) => handleStateCode(e)} style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }}>
                                                        <option value="">Select State</option>
                                                        {
                                                            States.map((v, i) => {
                                                                return (
                                                                    (countryId === v.countryCode) ? (<option value={v.isoCode} key={i}>{v.name}</option>) : (<></>)
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">City </p>
                                                    <select className="form-control" onChange={(e) => handleCity(e)} style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }}>
                                                        <option value="">Select City</option>
                                                        {
                                                            Cities.map((v, i) => {
                                                                return (
                                                                    (countryId === v.countryCode && stateId === v.stateCode) ? (<option value={v.name} key={i}>{v.name}</option>) : (<></>)
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </Col>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">Pincode </p>
                                                    <input type={"text"} value={billingInfoValues.billingPincode} onChange={handleBillingInfoValues('billingPincode')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Select Pincode" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">Mobile Number </p>
                                                    <input type={"text"} value={billingInfoValues.billingMobileNo} onChange={handleBillingInfoValues('billingMobileNo')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Mobile Number" />
                                                </Col>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">Email Id </p>
                                                    <input type={"email"} value={billingInfoValues.billingEmailId} onChange={handleBillingInfoValues('billingEmailId')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Emaild Id" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">Tax Id</p>
                                                    <input type={"text"} value={billingInfoValues.billingTaxType} onChange={handleBillingInfoValues('billingTaxType')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Tax Type" />
                                                </Col>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <p style={{ color: WtColor.TextColor }} className="mt-3">&nbsp;</p>
                                                    <input type={"text"} value={billingInfoValues.billingTaxId} onChange={handleBillingInfoValues('billingTaxId')} className="form-control w-100" style={{ marginTop: "-10px", border: "1px solid " + WtColor.TextColor }} placeholder="Enter Tax Id" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                            }
                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} className="mb-3">
                                <div>
                                    <PaymentGatewayChooseDialog open={openPaymentGatewayOptDialog.open} razorpayObj={openPaymentGatewayOptDialog.razorpayObj} stripeObj={openPaymentGatewayOptDialog.stripeObj} onClose={openPaymentGatewayOptDialog.onClose} />
                                    <div className="rounded-3 p-3" style={{ background: WtColor.SelectionColor, fontSize: "14px", lineHeight: "0.8" }}>
                                        <p className="fw-bold" style={{ color: WtColor.BaseColor }}>Plan: {planPriceInfo?.planName}</p>
                                        <Row>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}><div><p className="fw-bold" style={{ color: WtColor.TextColor }}>Start Date: {ShowDateTime(planPriceInfo?.startDate)}</p></div></Col>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}><div className="float-end"><p className="fw-bold" style={{ color: WtColor.TextColor }}>End Date: {ShowDateTime(planPriceInfo?.endDate)}</p></div></Col>
                                        </Row>
                                        <Row style={{ color: WtColor.TextColor }}>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}><div><p>Plan Amount <span style={{ color: WtColor.TextColor }}>({validity})</span></p></div></Col>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}><div className="float-end"><p>₹{planPriceInfo?.basePrice} / month</p></div></Col>
                                        </Row>

                                        <p className='fw-bold' style={{ color: WtColor.TextDarkColor }}>Price Breakup*</p>
                                        <Row style={{ color: WtColor.TextColor }}>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}><div><p>Rem. Days in Current Month ({ShowMonth(planPriceInfo?.startDate)})</p></div></Col>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}><div className="float-end"><p>₹{planPriceInfo?.remainDaysPrice}</p></div></Col>
                                        </Row>
                                        {
                                            planPriceInfo?.nextMonthsPrice === 0 ? "" :
                                                <Row style={{ color: WtColor.TextColor }}>
                                                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                                        <div>
                                                            <p>
                                                                Next &nbsp;
                                                                {
                                                                    (() => {
                                                                        if (validity === "monthly") return "Month "
                                                                        if (validity === "quarterly") return "Quarter "
                                                                        if (validity === "yearly") return "Year "
                                                                    })()
                                                                }
                                                                ({validity !== "monthly" ? "from " : ""}{ShowNextMonth(planPriceInfo?.startDate)})
                                                            </p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}><div className="float-end"><p>₹{planPriceInfo?.nextMonthsPrice}</p></div></Col>
                                                </Row>
                                        }
                                        <Row style={{ color: WtColor.TextColor }}>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}><div><p>Sub-Total <span style={{ color: WtColor.TextColor }}>({planPriceInfo?.totalDiscount}% OFF)</span></p></div></Col>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}><div className="float-end"><p><del>₹{planPriceInfo?.totalPrice}</del> &nbsp;₹{planPriceInfo?.totalPriceDiscount}</p></div></Col>
                                        </Row>
                                        <Row style={{ color: WtColor.TextColor }}>
                                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}><div><p>GST <span style={{ color: WtColor.TextColor }}>({planPriceInfo?.gst}%)</span></p></div></Col>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}><div className="float-end"><p>₹{planPriceInfo?.gstAmount}</p></div></Col>
                                        </Row>

                                        <hr />
                                        <Row>
                                            <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}><div><p style={{ color: WtColor.TextColor }} className="fw-bold">Total Amount <span style={{ color: WtColor.TextColor }}>(Exclusive of taxes)</span></p></div></Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}><div className="float-end"><p className="fw-bold" style={{ color: WtColor.BaseColor }}>₹{planPriceInfo?.amountTobePaid}</p></div></Col>
                                        </Row>
                                    </div>
                                    <div className='ps-3 pe-3 mt-2'>
                                        <p className='fw-bold' style={{ color: WtColor.TextDarkColor, fontSize: "13px" }}>
                                            *Our pricing is based on monthly basis which is based on WhatsApp Charges. So we are doing minimum of 1 month charges.
                                        </p>
                                    </div>

                                    <div className="mt-3" style={{ display: "none" }}>
                                        <p style={{ color: WtColor.TextColor }}>Referral Code</p>
                                        <div className="rounded-2" style={{ display: "flex", border: "1px solid " + WtColor.TextColor, marginTop: "-10px" }}>
                                            <input type="text" className="form-control border-0" placeholder="Enter Referral Code" />
                                            <Button style={{ background: WtColor.BaseColor }} className="btn btn-sm mt-1 pt-2 pb-2 ps-4 pe-4 me-1 mb-1 text-white">Apply</Button>
                                        </div>
                                    </div>
                                    <div className="fixed-bottom mb-3 me-5">
                                        <div className='float-end'>
                                            <Button style={{ color: WtColor.BaseColor, border: "1px solid " + WtColor.BaseColor }} onClick={() => openURL("/dashboard/payment/plan-subscription")} className="btn btn-sm mt-1 pt-2 pb-2 ps-4 pe-4 me-1 mb-1 bg-white">Back</Button>
                                            <Button style={{ background: WtColor.BaseColor }} onClick={proceedForPayment} className="btn btn-sm mt-1 pt-2 pb-2 ps-4 pe-4 me-1 mb-1 text-white">Proceed to Payment</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </div>
    )
}