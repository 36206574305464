/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from "react"
import './index.css'
import { Table } from "react-bootstrap"
import { AddEditContactsDialog, ConfirmDialog, ShowDateTime, UploadCSVDialog, DemoDialog } from "../../controller/BaseTool"
import { WtTheme } from "../../controller/theme"
import { Delete, ModeEdit, AddOutlined, KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined, OpenInNewOutlined, Search, ReplayOutlined, ImportExportOutlined, Close } from "@mui/icons-material"
import { Checkbox } from "@mui/material"
import { deleteContactsAPI } from "../../controller/APIs"
import contactsContext from "../../context/contacts/contactsContext"
import globalContext from "../../context/global/globalContext"
import { WtColor } from "../../constants/colours"
import { DownloadTableExcel } from "react-export-table-to-excel"
import { WtDimen } from "../../constants/dimens"
import { WtString } from "../../constants/strings"
import { WtUrl } from "../../constants/urls"

export const ContactList = () => {
    const splitUIName = useContext(contactsContext)

    const getContacts = useContext(contactsContext)
    const getContactsBySearch = useContext(contactsContext)
    const contactLs = useContext(contactsContext)
    const contactLsCount = useContext(contactsContext)
    const contactSkip = useContext(contactsContext)

    const entriesVal = useContext(contactsContext)
    const entries = useContext(contactsContext)
    const setEntries = useContext(contactsContext)
    const handleEntries = useContext(contactsContext)

    const handleContactSkip = useContext(contactsContext)
    const contactUISize = useContext(contactsContext)
    const handleUI_On_Off = useContext(contactsContext)

    const getUserInfo = useContext(globalContext)
    const userInfo = useContext(globalContext)
    const getLabelLs = useContext(globalContext)

    const contactLsLen = useContext(contactsContext)
    const resetAllFilter = useContext(contactsContext)
    const setApplyFilterStatus = useContext(contactsContext)

    const tableRef = useRef(null)


    // Search
    const [searchValue, setSearchValue] = useState([])
    const handleSearchValue = (val) => setSearchValue(val)

    const handleContactsSearch = async (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            await getContactsBySearch.getContactsBySearch(e.target.value)
        } else if (e.keyCode === 8 && e.target.value.length === 1) {
            setSearchValue('')
            e.preventDefault()
            await getContacts.getContacts(0)
        }
    }

    const handleSearchBtnClick = async () => await getContactsBySearch.getContactsBySearch(searchValue)
    useEffect(() => { if (searchValue?.length === 0) getContacts.getContacts(0) }, [searchValue])
    // End of Search

    const [openAddEditDialog, setOpenAddEditDialog] = useState({ open: false, onClose: null })
    const openAddEditDialogBox = (purpose, params) => {
        setOpenAddEditDialog({
            open: true, purpose: purpose, params: params, onClose: (confirm) => {
                setOpenAddEditDialog(false)
                if (confirm) getContacts.getContacts(0)
            }
        })
    }

    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const openConfirmationForDeletionBox = (id, purpose) => {
        setOpenConfirmDialogBox({
            // eslint-disable-next-line no-useless-concat
            open: true, title: 'Contacts Deletion', message: purpose === 'single' ? 'Are your sure to delete this Contact?' : 'Are your sure to delete these Contact?', onClose: (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) {
                    if (purpose === 'single') deleteContacts(id)
                    if (purpose === 'multiple') deleteMultipleContacts()
                }
            }
        })
    }

    const [openUploadCSVDialog, setOpenUploadCSVDialog] = useState({ open: false, onClose: null })
    const openUploadCSVDialogBox = () => {
        setOpenUploadCSVDialog({
            open: true, onClose: (confirm) => {
                setOpenUploadCSVDialog(false)
                if (confirm) { getContacts.getContacts(0) }
            }
        })
    }

    // Handle Next and Prev Action
    const handleSkipNext = () => {
        setEntries.setEntries(parseInt(entries.entries) + parseInt(entriesVal.entriesVal))
        handleContactSkip.handleContactSkip(entries.entries)
    }
    const handleSkipNothing = () => {
        setEntries.setEntries(contactSkip.contactSkip)
        handleContactSkip.handleContactSkip(contactSkip.contactSkip)
    }
    const handleSkipPrev = () => {
        if (contactSkip.contactSkip === 0) return
        setEntries.setEntries(contactSkip.contactSkip)
        handleContactSkip.handleContactSkip(parseInt(contactSkip.contactSkip) - parseInt(entriesVal.entriesVal))
    }
    // End of Handle Next and Prev Action


    // Delete Work
    const [selected, setSelected] = useState([])
    const [allSelected, setAllSelected] = useState(false)
    const isSelected = (_id) => selected.indexOf(_id) !== -1
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setAllSelected(true)
            const newSelected = contactLs.contactLs?.map((n) => n._id)
            setSelected(newSelected)
            return
        }
        setSelected([])
        setAllSelected(false)
    }
    const handleClick = (id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }
        setSelected(newSelected)
    }

    const deleteContacts = async (id) => {
        try {
            let data = { "contactId": [id] }
            const response = await deleteContactsAPI(data)
            if (response.status === 204) handleSkipNothing()
        } catch (err) { }
    }
    const deleteMultipleContacts = async () => {
        if (selected.length !== 0) {
            try {
                let data = { "contactId": selected }
                const response = await deleteContactsAPI(data)
                if (response.status === 204) {
                    handleSkipNothing()
                    resetContactSelection()
                }
            } catch (err) { }
        }
    }
    const resetContactSelection = () => {
        setSelected([])
        setAllSelected(false)
    }
    // End of Delete Work

    useEffect(() => { getContacts.getContacts(0) }, [entriesVal.entriesVal])

    const handleOpenUserInfo = async (v) => {
        let ch = v.chats[0]?.extChannelId ? v.chats[0].extChannelId : localStorage.getItem("c1")
        handleUI_On_Off.handleUI_On_Off("on_user_info")
        await getUserInfo.getUserInfo(ch, v.whatsapp)
        await getLabelLs.getLabelLs()
    }
    const handleOpenContactFilter = () => {
        handleUI_On_Off.handleUI_On_Off("on_filter")
    }

    // Reset Filter Work
    const applyResetFilter = () => {
        resetAllFilter.resetAllFilter()
        getContacts.getContacts(0)
        setApplyFilterStatus.setApplyFilterStatus(false)
    }
    // End of Reset Filter Work

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })
    return (
        <div className="contacts_crm_ui bg-white" style={{ flex: contactUISize.contactUISize }}>
            <div style={{ borderBottom: '1px solid ' + WtColor.LineColor }}>
                <div className="ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                    <div style={{ flex: 1, display: 'flex', paddingTop: "4px" }}>
                        <div>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '22px' }}>Contacts</h5>
                        </div>
                        <div style={{ display: "flex", border: "1px solid" + WtColor.LineColor, borderRadius: WtDimen.borderRadius, marginTop: '-3px', marginBottom: '2px' }} className="w-25 ms-5">
                            <img src={WtUrl.imageBaseUrl + "/search.svg"} style={{ width: '20px', marginLeft: '3px' }} alt={WtString.SiteName} />
                            <input type="search" value={searchValue} maxLength={30} onChange={(e) => handleSearchValue(e.target.value)} onKeyDown={(e) => handleContactsSearch(e)} className="w-100 camp-search" style={{ border: "none", outline: "none", paddingLeft: "6px", borderRadius: WtDimen.borderRadius, fontSize: '14px' }} placeholder="Search" />
                            <Search className='hand' onClick={() => searchValue.length === 0 ? {} : handleSearchBtnClick()} style={{ display: searchValue.length === 0 ? 'none' : 'block', color: searchValue.length === 0 ? WtColor.CloudyGrey : WtColor.TextDarkColor, marginRight: '8px', fontSize: '18px', marginTop: '8px' }} />
                        </div>

                        <div className="ms-3 hand" onClick={() => handleOpenContactFilter()}>
                            <div style={{ display: 'flex', background: WtColor.LineColor, fontWeight: '500', padding: '1px', border: contactLsLen.contactLsLen === 0 ? '1px solid ' + WtColor.LineColor : '1px solid ' + WtColor.BaseColor, borderRadius: '8px' }}>
                                <div style={{ flex: 1, marginBottom: '-7px' }}>
                                    <img src={contactLsLen.contactLsLen === 0 ? WtTheme.ImageBaseUrl + '/filter.svg' : WtTheme.ImageBaseUrl + '/filter_active.svg'} style={{ width: '16px', marginTop: '-1px', marginLeft: '6px' }} alt={WtTheme.SiteName} />&nbsp;
                                </div>
                                <div style={{ marginBottom: '-11px' }}>
                                    <p style={{ color: contactLsLen.contactLsLen === 0 ? WtColor.TextDarkColor : WtColor.BaseColor, fontSize: '15px', marginRight: '10px', marginLeft: '6px', marginTop: '2px' }}>{contactLsLen.contactLsLen === 0 ? 'Filter' : 'Filter Applied'}</p>
                                </div>
                            </div>
                        </div>
                        {
                            contactLsLen.contactLsLen !== 0 &&
                            <div className="ms-3">
                                <button onClick={() => applyResetFilter()} style={{ border: "1px solid " + WtColor.BaseColor, color: WtColor.BaseColor, background: '#fff', borderRadius: WtDimen.borderRadius, fontSize: '15px', marginTop: '0px' }} className="btn btn-sm"><Close style={{ color: WtColor.BaseColor, fontSize: '18px', marginTop: '-4px' }} /> Remove Filter</button>
                            </div>
                        }
                        {
                            splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 &&
                            <div className="ms-3">
                                <div style={{ display: 'flex', background: WtColor.LineColor, fontWeight: '500', padding: '4px', border: '1px solid ' + WtColor.LineColor, borderRadius: WtDimen.borderRadius, fontSize: '13px' }}>
                                    <div style={{ flex: 1, marginBottom: '-14px' }}>
                                        <p style={{ color: WtColor.TextDarkColor, fontSize: '15px', marginRight: '10px', marginLeft: '6px' }}>Entries</p>
                                    </div>
                                    <div style={{ marginBottom: '-16px' }}>
                                        <select className="hand" value={entriesVal.entriesVal} onChange={(e) => handleEntries.handleEntries(e.target.value)} style={{ background: WtColor.LineColor, outline: 'none', color: WtColor.TextLightGrey, marginTop: '2px', border: 'none' }}>
                                            {['10', '25', '50', '100'].map((v, i) => { return (<option value={v} key={i}>{v}</option>) })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div>
                        {
                            contactLsLen.contactLsLen > 0 && localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' &&
                            <div>
                                <DownloadTableExcel filename={localStorage.getItem('bName') + "_" + contactLsLen.contactLsLen + "_Contacts"} sheet="users" currentTableRef={tableRef.current}>
                                    <button style={{ border: "2px solid " + WtColor.BaseColor, color: WtColor.BaseColor, background: '#fff', borderRadius: WtDimen.borderRadius }} className="btn btn-sm"><ImportExportOutlined style={{ color: WtColor.BaseColor, fontSize: '18px', marginTop: '-4px' }} /> Export Contacts</button>
                                </DownloadTableExcel>
                            </div>
                        }
                        {
                            splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 &&
                            <div>

                                {
                                    (selected.length >= 2) &&
                                    <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openConfirmationForDeletionBox('', 'multiple') : openDemoDialogBox()} style={{ border: "2px solid " + WtColor.CloudyGrey, color: WtColor.CloudyGrey, borderRadius: WtDimen.borderRadius }} className="btn btn-sm bg-white"><Delete style={{ fontSize: '18px', marginTop: '-4px' }} /> &nbsp; Delete selected contacts</button>
                                }
                                {
                                    (selected.length < 2) &&
                                    <>
                                        <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openUploadCSVDialogBox() : openDemoDialogBox()} style={{ border: "2px solid " + WtColor.BaseColor, color: WtColor.BaseColor, background: '#fff', borderRadius: WtDimen.borderRadius, marginTop: '2px' }} className="btn btn-sm"><ImportExportOutlined style={{ color: WtColor.BaseColor, fontSize: '18px', marginTop: '-4px' }} /> Import Contacts</button> &nbsp;&nbsp;&nbsp;
                                        <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditDialogBox("add") : openDemoDialogBox()} style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius, marginTop: '2px' }} className="btn btn-sm"><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Create Contacts</button>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="ps-3 pt-2 pb-1 pe-5">
                    <p style={{ color: WtColor.TextMidGrey, marginTop: "0px", fontSize: '15px' }}>Total Contacts: {contactLsCount.contactLsCount} </p>
                </div>
            </div>

            <div className="app_list mt-2" style={{ height: '550px' }}>
                <Table className="fw-bold" ref={tableRef} responsive>
                    <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: '14px' }}>
                        <tr>
                            {
                                splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 &&
                                <th>
                                    <Checkbox color="primary" onClick={(e) => handleSelectAllClick(e)} checked={allSelected} style={{ padding: 0 }} />
                                    {selected?.length >= 2 && <ReplayOutlined onClick={() => resetContactSelection()} className="hand" />}
                                </th>
                            }
                            <th className="">Name</th><th>WhatsApp</th><th>Email</th><th>Last Seen</th>
                            {splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody style={{ background: "#fff", fontSize: "12px" }}>
                        {
                            contactLs.contactLs?.map((v, i) => {
                                const isItemSelected = isSelected(v._id)
                                return (
                                    <tr className="hand" style={{ background: contactUISize.contactUISize === 1.00 ? '#fff' : userInfo.userInfo?.whatsapp === v.whatsapp ? WtColor.SelectionColor : '#fff' }} key={i}>
                                        {splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 && <td><Checkbox color="primary" onClick={() => handleClick(v._id)} checked={isItemSelected} style={{ padding: 0 }} /></td>}
                                        <td onClick={() => handleOpenUserInfo(v)} style={{ color: WtColor.TextMidGrey }} className="">{(v.name && v.name !== "") ? v.name : "N/A"}</td>
                                        <td onClick={() => handleOpenUserInfo(v)} style={{ color: WtColor.TextLightGrey }}>{v.whatsapp}</td>
                                        <td onClick={() => handleOpenUserInfo(v)} style={{ color: WtColor.TextLightGrey }}>{v.email ? v.email : "N/A"}</td>
                                        <td onClick={() => handleOpenUserInfo(v)} style={{ color: WtColor.TextLightGrey }}>{v.updateDate ? ShowDateTime(v.updateDate) : "N/A"}</td>
                                        {
                                            splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 &&
                                            <td>
                                                <OpenInNewOutlined onClick={() => handleOpenUserInfo(v)} style={{ color: WtColor.TextLightGrey }} /> &nbsp;&nbsp;&nbsp;
                                                <ModeEdit onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditDialogBox("edit", v) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} /> &nbsp;&nbsp;&nbsp;
                                                {
                                                    (selected.length < 2) &&
                                                    <Delete onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openConfirmationForDeletionBox(v._id, 'single') : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                }
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                {contactLs.contactLs?.length === 0 && <p className="text-center mt-5 pt-5 mb-5 pb-5" style={{ color: WtColor.CloudyGrey }}><span style={{ color: WtColor.TextDarkColor, fontWeight: '500' }}>{searchValue}</span> not found in contacts...</p>}
            </div>
            {
                splitUIName.splitUIName === 'off' && contactLsLen.contactLsLen === 0 &&
                <div className="pt-3" style={{ borderTop: '1px solid ' + WtColor.LineColor }}>
                    {
                        contactLsCount.contactLsCount && contactLsCount.contactLsCount !== 0 && contactLsCount.contactLsCount > entriesVal.entriesVal && contactLs.contactLs?.length > entriesVal.entriesVal - 1 ?
                            <div className="float-end me-5 pe-5 hand">
                                <span className="fw-bold" style={{ color: WtColor.TextLightGrey }}>{parseInt(contactSkip.contactSkip) + 1} - {entries.entries === 0 ? entriesVal.entriesVal : entries.entries} of {contactLsCount.contactLsCount}</span> &nbsp; &nbsp; &nbsp;
                                {contactSkip.contactSkip !== 0 ? <span><KeyboardArrowLeftOutlined style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }} onClick={() => handleSkipPrev()} /></span> : <span><KeyboardArrowLeftOutlined style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }} /></span>} &nbsp; &nbsp;
                                {
                                    entries.entries > contactLsCount.contactLsCount ? '' :
                                        <span><KeyboardArrowRightOutlined style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey }} onClick={() => handleSkipNext()} /></span>
                                }
                            </div> : ""
                    }
                </div>
            }

            {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.message} onClose={openConfirmDialogBox.onClose} />}
            {openAddEditDialog.open && <AddEditContactsDialog open={openAddEditDialog.open} purpose={openAddEditDialog.purpose} params={openAddEditDialog.params} onClose={openAddEditDialog.onClose} />}
            {openUploadCSVDialog.open && <UploadCSVDialog open={openUploadCSVDialog.open} onClose={openUploadCSVDialog.onClose} />}
            {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
        </div >
    )
}