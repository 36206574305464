import { initializeApp } from "firebase/app"
import { getDatabase, ref, onValue, off } from "firebase/database"
import { Env } from "./constant"
const tag = 'firesocket:'


const app = initializeApp(Env.firebaseConfig)
const db = getDatabase(app)
var firesocketBusinessId
var firstEvent = true

function getRefence() {
    return ref(db, '/wtx-notifications/' + firesocketBusinessId)
}

export function listenFirebaseRealtimeDB(businessId, onMessage) {
    firesocketBusinessId = businessId;
    const reference = getRefence();

    onValue(reference, (snapshot) => {
        onMessage(snapshot.val())
        // if (!firstEvent) {
        //     onMessage(snapshot.val())
        // } else {
        //     firstEvent = false
        // }
    }, {
        onlyOnce: false
    });
    console.log(tag, 'connected')
}

export function listenFirebaseRealtimeDBOff() {
    if (firesocketBusinessId) {
        const reference = getRefence()
        off(reference);
    }
    console.log(tag, 'disconnected')
}