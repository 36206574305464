import React from 'react'
import { getBezierPath } from 'reactflow'
import './index.css'
import { Close } from '@mui/icons-material'

const foreignObjectSize = 40

export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, data }) {
    const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition })

    const onEdgeClick = (e, id) => {
        e.stopPropagation()
        data.onEdgesDeleteId(id)
    }
    return (
        <>
            <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
            <foreignObject
                width={foreignObjectSize} height={foreignObjectSize}
                x={labelX - foreignObjectSize / 3} y={labelY - foreignObjectSize / 3}
                className="edgebutton-foreignobject" requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div>
                    <button className="edgebutton" onClick={(e) => onEdgeClick(e, id)}>
                        <Close style={{ fontSize: '18px', position: 'absolute', top: '6px', left: '6px' }} />
                    </button>
                </div>
            </foreignObject>
        </>
    )
}
