/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { fetchBusinessInfo } from '../../controller/APIs'
import { Link } from 'react-router-dom'
import { ShowDateTime, WtLoader } from '../../controller/BaseTool'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'

export const BusinessInformation = () => {
    const notificationBarStatus = useContext(globalContext)

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)

    const [businessInfo, setBusinessInfo] = useState([])
    const getBusinessInfo = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchBusinessInfo()
            if (response.status === 200 && response.data.data.business) {
                handleWTLoader.handleWTLoader(false)
                setBusinessInfo(response.data.data.business)
                localStorage.setItem("bWebhookURL", response.data.data.business?.webhookUrl ? response.data.data.business?.webhookUrl : "")
            }
        } catch (error) { }
    }

    useEffect(() => { getBusinessInfo() }, [])

    const profile_base_view = { display: 'flex', backgroundColor: '#f8f8f8', height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh', width: '94vw' }
    const profile_menu_item = { flex: '0.85' }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}><h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Business Information</h5></div>
                    </div>
                    <div className="mt-2 ps-2">
                        <div className="app_list" style={{ height: "610px" }}>
                            <div className="rounded-3 mt-2 p-3" style={{ background: WtColor.LineColor }}>
                                <p><span style={{ color: WtColor.TextMidGrey, fontWeight: "bold" }}>Business Name: </span><span style={{ color: WtColor.TextMidGrey }}>{businessInfo?.name}</span></p>
                                <p><span style={{ color: WtColor.TextMidGrey, fontWeight: "bold" }}>Business Website: </span><span><Link to={{ pathname: businessInfo?.website }} target="_blank" style={{ color: WtColor.ProcessingBlue }} className="fw-bold"><u>{businessInfo?.website}</u></Link></span></p>
                                <p><span style={{ color: WtColor.TextMidGrey, fontWeight: "bold" }}>Last Activity: </span><span style={{ color: WtColor.TextMidGrey }}>{ShowDateTime(businessInfo?.updateDate)}</span></p>
                                <p><span style={{ color: WtColor.TextMidGrey, fontWeight: "bold" }}>Webhook URL: </span><span style={{ color: WtColor.TextMidGrey }}>{businessInfo?.webhookUrl ? businessInfo?.webhookUrl : "Empty"}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}