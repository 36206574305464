/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Switch } from '@mui/material'
import { Row, Col, Table, Tabs, Tab, Container } from 'react-bootstrap'
import { ProfileSidebar } from '../UserProfile/ProfileSidebar'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { fetchPaymentHistory, fetchPlanList, fetchPlanUsage } from '../../controller/APIs'
import { AppOnboardingDialog, ShowDateTime, WtLoader } from '../../controller/BaseTool'
import { useWindowDimensions } from '../useWindowDimensions'
import globalContext from '../../context/global/globalContext'
import { WtUrl } from '../../constants/urls'
import { WtColor } from '../../constants/colours'
var moment = require('moment')

export const PlanSubscription = () => {
    const notificationBarStatus = useContext(globalContext)
    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }
    let query = useQuery()
    let auth = query.get("auth")

    if (auth) { localStorage.setItem('userAuthToken', auth) }

    const { width } = useWindowDimensions()

    const [tabs, setTabs] = useState('upgrage_plan')
    const handleTabClick = (e) => {
        localStorage.setItem('planPageTab', e)
        setTabs(e)
    }

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    // Upgrade Plan Work
    const [planList, setPlanList] = useState([])

    const [planType, setPlanType] = useState('yearly')
    const [planTypeUI, setPlanTypeUI] = useState([{ "planTypeName": "Monthly", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" }, { "planTypeName": "Quaterly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }, { "planTypeName": "Yearly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }])
    const handlePlanType = (type) => { setPlanType(type) }

    let usdVal = 82
    const [currencyVal, setCurrencyVal] = useState(false)
    const handleCurrencyVal = (event) => { setCurrencyVal(event.target.checked) }

    const [planPrice, setPlanPrice] = useState({ "free": 0, "growth": 0, "pro": 0, "enterprise": 0 })
    const handlePlanPrice = () => {
        let growthBasePrice = planList[1]?.price
        let proBasePrice = planList[2]?.price
        let enterpriseBasePrice = planList[3]?.price

        let growthMPercentage = planList[1]?.percentageM
        let growthQPercentage = planList[1]?.percentageQ
        let growthYPercentage = planList[1]?.percentageY
        let growthSPercentage = planList[1]?.percentageS


        let proMPercentage = planList[2]?.percentageM
        let proQPercentage = planList[2]?.percentageQ
        let proYPercentage = planList[2]?.percentageY
        let proSPercentage = planList[2]?.percentageS

        let enterpriseMPercentage = planList[3]?.percentageM
        let enterpriseQPercentage = planList[3]?.percentageQ
        let enterpriseYPercentage = planList[3]?.percentageY
        let enterpriseSPercentage = planList[3]?.percentageS

        let f = 0
        let g = 0
        let p = 0
        let e = 0
        const calculatePrice = (planTypeVal, gPTPer, pPTPer, yPTPer) => {
            g = (growthBasePrice * planTypeVal) - ((growthBasePrice * planTypeVal * (gPTPer + growthSPercentage)) / 100)
            p = (proBasePrice * planTypeVal) - ((proBasePrice * planTypeVal * (pPTPer + proSPercentage)) / 100)
            e = (enterpriseBasePrice * planTypeVal) - ((enterpriseBasePrice * planTypeVal * (yPTPer + enterpriseSPercentage)) / 100)
        }

        if (planType === "monthly") {
            let monthly = 1
            calculatePrice(monthly, growthMPercentage, proMPercentage, enterpriseMPercentage)
            if (currencyVal) {
                setPlanPrice({ "free": Math.floor(f / usdVal), "growth": Math.floor(g / usdVal), "pro": Math.floor(p / usdVal), "enterprise": Math.floor(e / usdVal) })
            } else {
                setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
            }
            setPlanTypeUI(
                [
                    { "planTypeName": "Monthly", "planTypePrice": "", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" },
                    { "planTypeName": "Quaterly", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Yearly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }
                ]
            )
        }
        if (planType === "quarterly") {
            let quarterly = 3
            calculatePrice(quarterly, growthQPercentage, proQPercentage, enterpriseQPercentage)
            if (currencyVal) {
                setPlanPrice({ "free": Math.floor(f / usdVal), "growth": Math.floor(g / usdVal), "pro": Math.floor(p / usdVal), "enterprise": Math.floor(e / usdVal) })
            } else {
                setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
            }
            setPlanTypeUI(
                [
                    { "planTypeName": "Monthly", "planTypePrice": "", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Quaterly", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" },
                    { "planTypeName": "Yearly", "planTypeColor": "#fff", "planTypeBackground": "#028425" }
                ]
            )
        }
        if (planType === "yearly") {
            let yearly = 12
            calculatePrice(yearly, growthYPercentage, proYPercentage, enterpriseYPercentage)
            if (currencyVal) {
                setPlanPrice({ "free": Math.floor(f / usdVal), "growth": Math.floor(g / usdVal), "pro": Math.floor(p / usdVal), "enterprise": Math.floor(e / usdVal) })
            } else {
                setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
            }
            setPlanTypeUI(
                [
                    { "planTypeName": "Monthly", "planTypePrice": "", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Quaterly", "planTypeColor": "#fff", "planTypeBackground": "#028425" },
                    { "planTypeName": "Yearly", "planTypeColor": WtColor.BaseColor, "planTypeBackground": "#fff" }
                ]
            )
        }
    }
    useEffect(() => { handlePlanPrice() }, [planType, currencyVal, handlePlanPrice])

    // const [addOnsPlan, setAddOnPlans] = useState([])
    const getPlanList = async () => {
        handleWTLoader.handleWTLoader(true)
        let f = 0
        let g = 0
        let p = 0
        let e = 0
        try {
            const response = await fetchPlanList()
            if (response.status === 200) {
                response.data.data?.map((v) => {
                    if (v._id === "Primary") {
                        handleWTLoader.handleWTLoader(false)
                        setPlanList(v.plans)
                        g = (v.plans[1]?.price * 12) - ((v.plans[1]?.price * 12 * (v.plans[1]?.percentageM + v.plans[1]?.percentageS)) / 100)
                        p = (v.plans[2]?.price * 12) - ((v.plans[2]?.price * 12 * (v.plans[2]?.percentageM + v.plans[2]?.percentageS)) / 100)
                        e = (v.plans[3]?.price * 12) - ((v.plans[3]?.price * 12 * (v.plans[3]?.percentageM + v.plans[3]?.percentageS)) / 100)
                        setPlanPrice({ "free": Math.floor(f), "growth": Math.floor(g), "pro": Math.floor(p), "enterprise": Math.floor(e) })
                    }
                    // if (v._id === "WTBSetup") {
                    //     setAddOnPlans(v.plans)
                    // }
                })
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    useEffect(() => { getPlanList() }, [])

    const history = useHistory()
    const openURL = (url) => { history.push(url) }

    const handleGetStartedBtn = (pid) => {
        let extVal = 0
        if (moment().format("D") <= 3) {
            extVal = 0
        } else {
            extVal = 1
        }
        let cur = (currencyVal === true) ? "USD" : "INR"
        openURL(WtUrl.paymentSummaryUrl + "?paymentUrlType=buyPlanUrl&planId=" + pid + "&validity=" + planType + "&extValidity=" + extVal + "&currency=" + cur)
    }
    // End of Upgrade Plan Work


    // Add-Ons Plans
    const [wtbAddOnsPlanLs, setWTBAddOnPlanLs] = useState([])
    const [msgCreditAddOnsPlanLs, setMsgCreditAddOnPlanLs] = useState([])
    // End of Add-Ons Plans


    // Active Plan Work
    const [activePlan, setActivePlan] = useState([])
    const getPlanUsage = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchPlanUsage()
            if (response.status === 200) {
                handleWTLoader.handleWTLoader(false)
                setActivePlan(response.data.data[0])
                setWTBAddOnPlanLs(response.data.data[0].wtbSetup)
                setMsgCreditAddOnPlanLs(response.data.data[0].chatAddons)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    useEffect(() => { getPlanUsage() }, [])
    // End of Active Plan Work


    // Payment History
    const [paymentHistory, setPaymentHistory] = useState([])
    const getPaymentHistory = async (skip, limit) => {
        handleWTLoader.handleWTLoader(true)
        try {
            let data = "/payments/history?skip=" + skip + "&limit=" + limit
            const response = await fetchPaymentHistory(data)
            if (response.status === 200) {
                handleWTLoader.handleWTLoader(false)
                setPaymentHistory(response.data.data)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    useEffect(() => { getPaymentHistory(0, 100) }, [])
    // End of Payment History


    const [appOnboardingDialog, setAppOnboardingDialog] = useState({ open: false, onClose: null })
    const openAppOnboardingDialog = () => {
        setAppOnboardingDialog({
            open: true, onClose: (confirm) => {
                setAppOnboardingDialog(false)
                if (confirm) { setAppOnboardingDialog(false) }
            }
        })
    }
    useEffect(() => {
        if (activePlan?.usable?.length > 0) {
            if (localStorage.getItem("onboardingStatus") && localStorage.getItem("onboardingStatus") === "buy_now_success") openAppOnboardingDialog()
        } else {
            localStorage.removeItem("addons_p")
        }
    }, [activePlan?.usable])


    const ProfileStyle = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '100vh',
        width: '94vw'
    }

    const profile_menu_item = { flex: '0.85' }
    return (
        <>
            {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
            {
                (width <= 780) ? (
                    <Tabs defaultActiveKey={localStorage.getItem('planPageTab') && localStorage.getItem('planPageTab') !== '' ? localStorage.getItem('planPageTab') : tabs} activeKey={localStorage.getItem('planPageTab') && localStorage.getItem('planPageTab') !== '' ? localStorage.getItem('planPageTab') : tabs} onSelect={(e) => handleTabClick(e)} className="">
                        <Tab eventKey="upgrage_plan" title="Upgrade Plan">
                            <UpgradePlan currencyVal={currencyVal} handleCurrencyVal={handleCurrencyVal} handlePlanType={handlePlanType} planTypeUI={planTypeUI} planPrice={planPrice} planList={planList} handleGetStartedBtn={handleGetStartedBtn} />
                        </Tab>
                        <Tab eventKey="active_plan" title="Active Plan">
                            <ActivePlan activePlan={activePlan} wtbAddOnsPlanLs={wtbAddOnsPlanLs} msgCreditAddOnsPlanLs={msgCreditAddOnsPlanLs} />
                        </Tab>
                        <Tab eventKey="payment_history" title="Payment History">
                            <PaymentHistory paymentHistory={paymentHistory} />
                        </Tab>
                    </Tabs>
                ) : (
                    <div style={ProfileStyle}>
                        <ProfileSidebar />
                        <div style={profile_menu_item} className="bg-white">
                            {
                                activePlan?.usable?.length > 0 ? <AppOnboardingDialog open={appOnboardingDialog.open} onClose={appOnboardingDialog.onClose} /> : ""
                            }
                            <Tabs defaultActiveKey={localStorage.getItem('planPageTab') && localStorage.getItem('planPageTab') !== '' ? localStorage.getItem('planPageTab') : tabs} activeKey={localStorage.getItem('planPageTab') && localStorage.getItem('planPageTab') !== '' ? localStorage.getItem('planPageTab') : tabs} onSelect={(e) => handleTabClick(e)} className="">
                                <Tab eventKey="upgrage_plan" title="Upgrade Plan">
                                    <UpgradePlan currencyVal={currencyVal} handleCurrencyVal={handleCurrencyVal} handlePlanType={handlePlanType} planTypeUI={planTypeUI} planPrice={planPrice} planList={planList} handleGetStartedBtn={handleGetStartedBtn} />
                                </Tab>
                                <Tab eventKey="active_plan" title="Active Plan">
                                    <ActivePlan activePlan={activePlan} wtbAddOnsPlanLs={wtbAddOnsPlanLs} msgCreditAddOnsPlanLs={msgCreditAddOnsPlanLs} />
                                </Tab>
                                <Tab eventKey="payment_history" title="Payment History">
                                    <PaymentHistory paymentHistory={paymentHistory} />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const ActivePlan = (props) => {
    const { activePlan, wtbAddOnsPlanLs, msgCreditAddOnsPlanLs } = props
    return (
        <Container className='app_list' style={{ height: "640px" }} fluid>
            <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="rounded-3 mt-2 p-3" style={{ background: WtColor.LineColor }}>
                        <p style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}>Active Plan Info</p>
                        <p><span style={{ color: WtColor.TextLightGrey }}>Active Plan</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.planName}</span></p>
                        <p><span style={{ color: WtColor.TextLightGrey }}>Plan Status</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.status}</span></p>
                        <p><span style={{ color: WtColor.TextLightGrey }}>Plan Duration</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{ShowDateTime(activePlan.startDate)} to {ShowDateTime(activePlan.expDate)}</span></p>
                    </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="rounded-3 mt-2 p-3" style={{ background: WtColor.LineColor }}>
                        <div style={{ display: "flex" }}>
                            <p style={{ color: WtColor.TextDarkColor, fontSize: "20px", flex: 1 }}>Current Plan Usage</p>
                            <p><span style={{ color: WtColor.TextLightGrey }}><u>Quota Expiry Date</u></span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{ShowDateTime(activePlan.usageAllowded?.quotaExpDate)}</span></p>
                        </div>
                        <Row>
                            <Col>
                                <div>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Chats</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.messages} / {activePlan.usageAllowded?.messages >= 999990 ? "Unlimited" : activePlan.usageAllowded?.messages}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Contacts</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.contacts} / {activePlan.usageAllowded?.contacts >= 999990 ? "Unlimited" : activePlan.usageAllowded?.contacts}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Broadcast</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.broadcasts} / {activePlan.usageAllowded?.broadcasts >= 999990 ? "Unlimited" : activePlan.usageAllowded?.broadcasts}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Agents</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.agents} / {activePlan.usageAllowded?.labagentsels >= 999990 ? "Unlimited" : activePlan.usageAllowded?.agents}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Chatbots</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.chatbots} / {activePlan.usageAllowded?.chatbots >= 999990 ? "Unlimited" : activePlan.usageAllowded?.chatbots}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Channels</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.channels} / {activePlan.usageAllowded?.channels >= 999990 ? "Unlimited" : activePlan.usageAllowded?.channels}</span></p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Attributes</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.attributes} / {activePlan.usageAllowded?.attributes >= 999990 ? "Unlimited" : activePlan.usageAllowded?.attributes}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Labels</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.labels} / {activePlan.usageAllowded?.labels >= 999990 ? "Unlimited" : activePlan.usageAllowded?.labels}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Webhooks</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.totalUsage?.webhooks} / {activePlan.usageAllowded?.webhooks >= 999990 ? "Unlimited" : activePlan.usageAllowded?.webhooks}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Quick Replies</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.usageAllowded?.quickReplies >= 999990 ? "Unlimited" : activePlan.usageAllowded?.quickReplies}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Analytics</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.usageAllowded?.analyticsAccess ? "Enabled" : "Disabled"}</span></p>
                                    <p><span style={{ color: WtColor.TextLightGrey }}>Developer API Access</span>: <span className='fw-bold' style={{ color: WtColor.TextMidGrey }}>{activePlan.usageAllowded?.devApiAccess ? "Enabled" : "Disabled"}</span></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <hr />
            <div className='mt-5 scrollBox' style={{ height: "300px" }}>
                <p style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}>Upcoming Plans</p>
                <Table responsive>
                    <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                        <tr>
                            <th>Plan Name</th>
                            <th>Validity</th>
                            <th>Price</th>
                            <th>Purchase Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "none", fontSize: "12px" }}>
                        {
                            activePlan?.inactivePlan?.map((v, i) => {
                                return (
                                    <tr style={{ color: WtColor.TextColor }} key={i}>
                                        <th>{v.name}</th>
                                        <th>{v.validity}</th>
                                        <th>{(v.currency === "INR") ? "₹" : "$"}{v.price / 100}</th>
                                        <th>{v.buyDate ? ShowDateTime(v.buyDate) : ""}</th>
                                        <th>{v.planStatus === "Inactive" ? "In Queue" : ""}</th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <hr />
            <div className='add_ons_plan mt-5'>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='scrollBox' style={{ height: "300px", borderRight: "1px solid " + WtColor.LineColor }} >
                        <p style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}>WTB Setup Add-ons</p>
                        <Table responsive>
                            <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                                <tr>
                                    <th>Name</th>
                                    <th>Purchase Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "none", fontSize: "12px" }}>
                                {
                                    wtbAddOnsPlanLs?.map((v, i) => {
                                        return (
                                            <tr style={{ color: WtColor.TextColor }} key={i}>
                                                <th>{v.name}</th>
                                                <th>{v.buyDate ? ShowDateTime(v.buyDate) : ""}</th>
                                                <th>{v.planStatus}</th>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='scrollBox' style={{ height: "300px" }}>
                        <p style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}>Message Credit Plans</p>
                        <Table responsive>
                            <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                                <tr>
                                    <th>Name</th>
                                    <th>Balance</th>
                                    <th>Start Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "none", fontSize: "12px" }}>
                                {
                                    msgCreditAddOnsPlanLs?.map((v, i) => {
                                        return (
                                            <tr style={{ color: WtColor.TextColor }} key={i}>
                                                <th>{v.planName}</th>
                                                <th>{v.addonUsed} / {v.addonBal}</th>
                                                <th>{v.startDate ? ShowDateTime(v.startDate) : ""}</th>
                                                <th>{v.status}</th>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='mt-5 scrollBox' style={{ height: "300px" }}>
                <p style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}>Pending Bills</p>
                <Table responsive>
                    <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                        <tr>
                            <th>Plan Name</th>
                            <th>Period</th>
                            <th>Plan Quota</th>
                            <th>Amount Dues</th>
                            <th>Dues Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "none", fontSize: "12px" }}>
                        {
                            activePlan?.pendingBills?.map((v, i) => {
                                return (
                                    <tr style={{ color: WtColor.TextColor }} key={i}>
                                        <th>{v.planDetails?.planName}</th>
                                        <th>{v.billingPeriod?.month}, {v.billingPeriod?.year}</th>
                                        <th>{(v.planDetails?.unlimited === true) ? "Unlimited" : v.unlimited}</th>
                                        <th>{v.currency === "inr" ? "₹" : "$"}{v.amountDue / 100}</th>
                                        <th>{v.dueDate ? ShowDateTime(v.dueDate) : ""}</th>
                                        <th>{v.paymentStatus}</th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <hr style={{ display: 'none' }} />
            <div className='mt-5 scrollBox' style={{ height: "300px", display: 'none' }}>
                <p style={{ color: WtColor.TextDarkColor, fontSize: "20px" }}>Invoices</p>
                <p>Not found!!!</p>
            </div>
        </Container>
    )
}

export const UpgradePlan = (props) => {
    const { currencyVal, handleCurrencyVal, handlePlanType, planTypeUI, planPrice, planList, handleGetStartedBtn } = props
    return (
        <Container className="app_list mt-2" style={{ height: '640px' }} fluid>
            <div style={{ background: WtColor.BaseColor, height: "400px" }}>
                <p className="text-center text-white pt-4 fw-bold" style={{ fontSize: "24px" }}>Choose the best plan that suits for your Business</p>
                <Row className="mt-4">
                    <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className="hand">
                        <p className="text-center" style={{ fontWeight: "bold" }}>
                            <span onClick={() => handlePlanType("monthly")} style={{ color: planTypeUI[0].planTypeColor, background: planTypeUI[0].planTypeBackground, paddingLeft: "24px", paddingRight: "24px", paddingTop: "6px", paddingBottom: "6px", borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px" }}>{planTypeUI[0].planTypeName} {planList[1]?.percentageM !== 0 && <span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "13px", borderRadius: "20px" }}>Save {planList[1]?.percentageM}%</span>}</span>
                            <span onClick={() => handlePlanType("quarterly")} style={{ color: planTypeUI[1].planTypeColor, background: planTypeUI[1].planTypeBackground, paddingLeft: "24px", paddingRight: "24px", paddingTop: "6px", paddingBottom: "6px", borderLeft: "2px solid " + WtColor.BaseColor, borderRight: "2px solid " + WtColor.BaseColor }}>{planTypeUI[1].planTypeName} {planList[1]?.percentageQ !== 0 && <span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "13px", borderRadius: "20px" }}>Save {planList[1]?.percentageQ}%</span>}</span>
                            <span onClick={() => handlePlanType("yearly")} style={{ color: planTypeUI[2].planTypeColor, background: planTypeUI[2].planTypeBackground, paddingLeft: "24px", paddingRight: "24px", paddingTop: "6px", paddingBottom: "6px", borderTopRightRadius: "15px", borderBottomRightRadius: "15px" }}>{planTypeUI[2].planTypeName} {planList[1]?.percentageY !== 0 && <span style={{ background: WtColor.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "13px", borderRadius: "20px" }}>Save {planList[1]?.percentageY}%</span>}</span>
                        </p>
                        <p className="text-center mt-4" style={{ color: "#fff", fontWeight: "bold", marginTop: "-8px" }}>
                            <span>INR (₹)</span>&nbsp;<Switch checked={currencyVal} onChange={handleCurrencyVal} />&nbsp;<span>USD ($)</span>
                        </p>
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                </Row>
            </div>
            <div style={{ background: WtColor.LineColor }}>
                <Row className="ms-5 me-5" style={{ position: "relative", top: "-160px" }}>
                    {/* <Col className="mb-3">
                        <div className="bg-white rounded-3">
                            <div className="rounded-3" style={{ background: WtColor.LineColor, paddingTop: "8px", paddingBottom: "8px" }}>
                                <p className="text-center pb-0 mb-0" style={{ color: WtColor.TextMidGrey }}>Best for starting</p>
                            </div>
                            <div className="p-3">
                                <div>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "30px" }}>FREE</p>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px", marginTop: "-24px" }}>{currencyVal ? "$" : "₹"}{planPrice?.free}<br /><span style={{ fontSize: '15px' }}>&nbsp;</span></p>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px" }}>500 Unique conversation</p>
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px", marginTop: "-8px" }}>WhatsApp Charges - 1000 Free/Month</p>
                                    <Link to={{ pathname: "/pricing" }} target="_blank"><Link to={{ pathname: "/pricing" }} target="_blank"><p style={{ color: WtColor.BaseColor, fontSize: "13px", marginTop: "-18px" }}>check prices here</p></Link></Link>
                                </div>
                                <button className="btn mt-4 btn-sm w-100 theme_bg text-white fw-bold" disabled>Default Plan</button>
                            </div>
                        </div>
                    </Col> */}
                    <Col className="mb-3 ms-3 me-3">
                        <div className="bg-white rounded-3">
                            <div className="rounded-3" style={{ background: WtColor.LineColor, paddingTop: "8px", paddingBottom: "8px" }}>
                                <p className="text-center pb-0 mb-0" style={{ color: WtColor.TextMidGrey }}>Best for small teams</p>
                            </div>
                            <div className="p-3">
                                <div>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "30px" }}>GROWTH</p>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px", marginTop: "-24px" }}>
                                        {currencyVal ? "$" : "₹"}{planPrice?.growth}<br />
                                        {planList[1]?.percentageS === 0 && <span>&nbsp;</span>}
                                        {planList[1]?.percentageS !== 0 && <span style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[1]?.percentageS}% OFF</span>}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px" }}>Unlimited conversation *</p>
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px", marginTop: "-8px" }}>WhatsApp Charges - 1000 Free/Month</p>
                                    <Link to={{ pathname: "/pricing" }} target="_blank"><p style={{ color: WtColor.BaseColor, fontSize: "13px", marginTop: "-18px" }}>check prices here</p></Link>
                                </div>
                                <button className="btn mt-4 btn-sm w-100 theme_bg text-white fw-bold" onClick={() => handleGetStartedBtn(planList[1]?._id)}>Get Started</button>
                            </div>
                        </div>
                    </Col>
                    <Col className="mb-3 ms-3 me-3">
                        <div className="bg-white rounded-3">
                            <div className="rounded-3" style={{ background: WtColor.LineColor, paddingTop: "8px", paddingBottom: "8px" }}>
                                <p className="text-center pb-0 mb-0" style={{ color: WtColor.TextMidGrey }}>Best for medium to large teams</p>
                            </div>
                            <div className="p-3">
                                <div>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "30px" }}>PRO</p>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px", marginTop: "-24px" }}>
                                        {currencyVal ? "$" : "₹"}{planPrice?.pro} <br />
                                        {planList[2]?.percentageS === 0 && <span>&nbsp;</span>}
                                        {planList[2]?.percentageS !== 0 && <span style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[2]?.percentageS}% OFF</span>}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px" }}>Unlimited conversation *</p>
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px", marginTop: "-8px" }}>WhatsApp Charges - 1000 Free/Month</p>
                                    <Link to={{ pathname: "/pricing" }} target="_blank"><p style={{ color: WtColor.BaseColor, fontSize: "13px", marginTop: "-18px" }}>check prices here</p></Link>
                                </div>
                                <button className="btn mt-4 btn-sm w-100 theme_bg text-white fw-bold" onClick={() => handleGetStartedBtn(planList[2]?._id)}>Get Started</button>
                            </div>
                        </div>
                    </Col>
                    <Col className="mb-3 ms-3 me-3">
                        <div className="bg-white rounded-3">
                            <div className="rounded-3" style={{ background: WtColor.LineColor, paddingTop: "8px", paddingBottom: "8px" }}>
                                <p className="text-center pb-0 mb-0" style={{ color: WtColor.TextMidGrey }}>Best for Enterprise</p>
                            </div>
                            <div className="p-3">
                                <div>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "30px" }}>ENTERPRISE</p>
                                    <p className="fw-bold" style={{ color: WtColor.TextDarkColor, fontSize: "35px", marginTop: "-24px" }}>
                                        {currencyVal ? "$" : "₹"}{planPrice?.enterprise} <br />
                                        {planList[3]?.percentageS === 0 && <span>&nbsp;</span>}
                                        {planList[3]?.percentageS !== 0 && <span style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[3]?.percentageS}% OFF</span>}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px" }}>Unlimited conversation *</p>
                                    <p style={{ color: WtColor.TextMidGrey, fontSize: "13px", marginTop: "-8px" }}>WhatsApp Charges - 1000 Free/Month</p>
                                    <Link to={{ pathname: "/pricing" }} target="_blank"><p style={{ color: WtColor.BaseColor, fontSize: "13px", marginTop: "-18px" }}>check prices here</p></Link>
                                </div>
                                <button className="btn mt-4 btn-sm w-100 theme_bg text-white fw-bold" onClick={() => handleGetStartedBtn(planList[3]?._id)}>Get Started</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export const PaymentHistory = (props) => {
    const { paymentHistory } = props
    return (
        <Container className='app_list' style={{ height: "640px" }} fluid>
            <Table className='mt-2' responsive>
                <thead style={{ color: WtColor.TextColor, fontSize: "14px", background: WtColor.LineColor }}>
                    <tr>
                        <th>Product Name</th>
                        <th>Amount</th>
                        <th>Quantity</th>
                        <th>Created Date</th>
                        <th>Purchase Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody style={{ borderTop: "none", fontSize: "12px" }}>
                    {
                        paymentHistory?.map((v, i) => {
                            return (
                                <tr style={{ color: WtColor.TextColor, fontSize: "12px" }} key={i}>
                                    <th>{v.planName}</th>
                                    <th>₹{v.amount / 100}</th>
                                    <th>{v.planQuantity}</th>
                                    <th>{v.createDate ? ShowDateTime(v.createDate) : ""}</th>
                                    <th>{v.paymentDate ? ShowDateTime(v.paymentDate) : ""}</th>
                                    <th>{v.status}</th>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </Container>
    )
}