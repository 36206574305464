class WtFiles {
    constructor() {
        this.imgSizeLimit = 5242880
        this.VideoSizeLimit = 15728640
        this.pdfSizeLimit = 15728640
        this.audioSizeLimit = 5242880
    }
}
export const WtFile = new WtFiles()

