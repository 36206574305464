/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Tooltip, Zoom } from '@mui/material'
import { Table } from 'react-bootstrap'
import { InfoOutlined, Delete, ModeEdit, AddOutlined } from '@mui/icons-material'
import { AddEditLabelsDialog, ConfirmDialog, DemoDialog, WtLoader } from '../../controller/BaseTool'
import { deleteLabelsAPI, fetchAllLabelsAPI } from '../../controller/APIs'
import { SideMenu } from './SideMenu'
import globalContext from '../../context/global/globalContext'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'


export const Labels = () => {
    const notificationBarStatus = useContext(globalContext)

    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)


    const [labelsList, setLabelList] = useState([])
    const fetchLabels = async () => {
        handleWTLoader.handleWTLoader(true)
        try {
            const response = await fetchAllLabelsAPI()
            if (response.status === 200) {
                handleWTLoader.handleWTLoader(false)
                setLabelList(response.data.data)
            } else { handleWTLoader.handleWTLoader(false) }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }

    let creatorName = localStorage.getItem('userName')
    const [openConfirmDialogBox, setOpenConfirmDialogBox] = useState({ open: false, onClose: null })
    const deleteLable = (id) => {
        setOpenConfirmDialogBox({
            open: true, title: 'Whatstool Business', msg: 'Are you sure, you want to Delete this Label? <br/> Note: When you delete your label it will be deleted from all the customers and chat.', onClose: async (confirm) => {
                setOpenConfirmDialogBox(false)
                if (confirm) {
                    try {
                        const data = { "labelId": id, "deletedBy": creatorName }
                        const response = await deleteLabelsAPI(data)
                        if (response.status === 204) fetchLabels()
                    } catch (error) { }
                } else { setOpenConfirmDialogBox(false) }
            }
        })
    }

    const [openAddEditLabelDialog, setOpenAddEditLabelDialog] = useState({ open: false, onClose: null })
    const openAddEditLabelDialogBox = (purpose, labelData) => {
        setOpenAddEditLabelDialog({
            open: true, purpose: purpose, labelData: labelData, onClose: async (confirm) => {
                setOpenAddEditLabelDialog(false)
                if (confirm) await fetchLabels()
            }
        })
    }

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })

    useEffect(() => { fetchLabels() }, [])


    const profile_base_view = {
        display: 'flex',
        backgroundColor: '#f8f8f8',
        height: notificationBarStatus.notificationBarStatus ? '90vh' : '98vh',
        width: '94vw'
    }
    const profile_menu_item = {
        flex: '0.85'
    }
    return (
        <div style={profile_base_view}>
            <SideMenu />
            <div style={profile_menu_item} className="bg-white">
                {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
                {openAddEditLabelDialog.open && <AddEditLabelsDialog open={openAddEditLabelDialog.open} purpose={openAddEditLabelDialog.purpose} labelData={openAddEditLabelDialog.labelData} onClose={openAddEditLabelDialog.onClose} />}
                {openConfirmDialogBox.open && <ConfirmDialog open={openConfirmDialogBox.open} title={openConfirmDialogBox.title} message={openConfirmDialogBox.msg} onClose={openConfirmDialogBox.onClose} />}
                {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
                <div>
                    <div className="border-bottom ps-3 pt-2 pb-1 pe-5" style={{ display: "flex" }}>
                        <div style={{ flex: 1, paddingTop: "4px" }}>
                            <h5 style={{ color: WtColor.TextDarkColor, fontSize: '16px' }}>Labels &nbsp;
                                <Tooltip title="Labels help you to categorize conversations and prioritize them. You can assign label to a conversation from the sidepanel." placement="bottom-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }}>
                                    <InfoOutlined style={{ color: WtColor.TextLightGrey, marginTop: "-5px", fontSize: '15px' }} />
                                </Tooltip>
                            </h5>
                        </div>
                        <div>
                            <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditLabelDialogBox("add") : openDemoDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius }}><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Add Labels</button>
                        </div>
                    </div>
                    <div className="mt-2 ps-2">
                        <div className="app_list" style={{ height: "610px" }}>
                            <Table className="fw-bold" responsive>
                                <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: "15px" }}><tr><th>Name</th><th>Description</th><th>Color</th><th>Action</th></tr></thead>
                                <tbody style={{ background: "#fff", fontSize: "12px" }}>
                                    {
                                        labelsList.sort((a, b) => (a.positionAt > b.positionAt) ? 1 : -1).map((v, i) => {
                                            return (
                                                <tr className="hand" key={i}>
                                                    <td style={{ color: WtColor.TextMidGrey, paddingTop: "10px" }}>{v.label_name}</td>
                                                    <td style={{ color: WtColor.TextLightGrey, paddingTop: "10px" }}>{v.label_description ? v.label_description.substring(0, 60) : ""}</td>
                                                    <td className='text-uppercase' style={{ color: WtColor.TextLightGrey, paddingTop: "10px" }}><span className="rounded-2 " style={{ background: v.label_color, paddingLeft: "15px" }}>&nbsp;</span> {v.label_color}</td>
                                                    <td className='text-uppercase' style={{ paddingTop: "10px" }}>
                                                        <ModeEdit onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openAddEditLabelDialogBox("edit", v) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} /> &nbsp;&nbsp;&nbsp;
                                                        <Delete onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? deleteLable(v._id) : openDemoDialogBox()} style={{ color: WtColor.TextLightGrey }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}