import React, { useContext } from 'react'
import { WtTheme } from '../../controller/theme'
import { Close } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import globalContext from '../../context/global/globalContext'
import { analyticsEvent } from '../../controller/BaseTool'
import { WtString } from '../../constants/strings'
import { WtAnalytic } from '../../constants/analyticsKeys'


export const OutsideOfferUI = () => {
    const setOfferImg = useContext(globalContext)
    const history = useHistory()
    const openURL = (url) => {
        analyticsEvent(localStorage.getItem("userName") ? localStorage.getItem("userName") : WtString.nonLoggedInUserText, WtAnalytic.topFullWidthOfferBannerClicked, window.location.search)
        history.push(url)
    }
    return (
        <>
            <Close onClick={() => setOfferImg.setOfferImg(false)} className="hand" style={{ position: 'absolute', right: '10px', top: '10px', color: '#fff' }} />
            <img onClick={() => openURL('/pricing')} src={WtTheme.ImageBaseUrl + '/wtb_offer.png'} className="img-fluid w-100 d-block hand" alt={WtTheme.SiteName} />
        </>
    )
}