/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Box, Button, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, List, ListItemAvatar, ListItem, ListItemButton, ListItemText, Avatar, IconButton, FilledInput } from '@mui/material'
import { Logout } from '../controller/caller'
import { Env } from '../controller/constant'
import { Player } from '@lottiefiles/react-lottie-player'
import { Link, useLocation } from 'react-router-dom'
import { useWindowDimensions } from './useWindowDimensions'
import { CloseOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { loginAPI, otpVerificationAPI, resendOtpAPI, resetPasswordAPI, sendResetPasswordLinkAPI, signupAPI } from '../controller/APIs'
import { WtTheme } from '../controller/theme'
import { WtUrl } from '../constants/urls'
import { WtColor } from '../constants/colours'

// Login Work
export const Login = () => {
  const { width } = useWindowDimensions()
  return (
    <>
      <Container className="signUpBox" style={{ background: "#0F6550" }} fluid>
        {
          (width <= 780) ? (
            <Container fluid>
              <Row className="signForm">
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <p className="ps-2 pt-3">
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="30px" style={{ marginTop: "-8px" }} alt={WtTheme.SiteName} /></Link>
                    <span className="text-white fw-bold" style={{ fontSize: "20px" }}> &nbsp;Get Started with WT Business</span>
                  </p>
                  <div className="signForm">
                    <div className="bg-white mt-5 mb-5 signForm">
                      <h4 className="text-center pt-5">Login</h4>
                      <div className="pt-5 pe-5 ps-5 mb-5">
                        <LoginForm />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <div className="signForm">
                    <div className="pt-5 ps-5 mt-5" style={{ background: "#EFEFEF", borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">WhatsTool solutions can<br />grow your business with WhatsApp</h5>
                          <p className="mt-4" style={{ fontSize: "20px" }}>Powered by WhatsApp Official Business Api</p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="150px" style={{ marginTop: "-40px", height: "180px" }} alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5 ps-3 pb-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col xxl={7} xl={7} ld={7} md={7} sm={12} xs={12}>
                  <div className="mb-5 pb-5" style={{ marginLeft: "30px", marginTop: "30px" }}>
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} /> &nbsp;<img style={{ marginTop: "1px" }} src={WtTheme.ImageBaseUrl + "/logo_text_white.png"} width="150px" alt={WtTheme.SiteName} /></Link>
                    <h3 className="text-white mt-5">Get Started with WhatsTool Business</h3>
                    <div className="pt-5 ps-5 mt-5" style={{ background: "#EFEFEF", borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">WhatsTool solutions can<br />grow your business with WhatsApp</h5>
                          <p className="mt-4" style={{ fontSize: "20px" }}>Powered by WhatsApp Official Business Api</p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="150px" style={{ marginTop: "-40px", height: "180px" }} alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} xl={5} ld={5} md={5} sm={12} xs={12}>
                  <div className="mt-5 pt-4" style={{ marginTop: "100px", marginBottom: "100px" }}>
                    <div className="bg-white mt-5 mb-5" style={{ marginLeft: "24px", marginRight: "24px", borderRadius: "10px" }}>
                      <h4 className="text-center pt-5">Login</h4>
                      <div className="pt-5 pe-5 ps-5 mb-5">
                        <LoginForm />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )
        }
        <p>
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
        </p>
      </Container>
    </>
  )
}
export const LoginForm = () => {
  const history = useHistory()

  const [values, setValues] = useState({ email: '', password: '', showPassword: false })
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => { setValues({ ...values, showPassword: !values.showPassword, }) }
  const handleMouseDownPassword = (event) => { event.preventDefault() }

  const [startSpinner, setStartSpinner] = useState(false)
  const handleSpinner = () => {
    setStartSpinner(!startSpinner)
  }

  const [loginUpError, setLoginError] = useState("")
  const handleLoginSubmit = async () => {
    if (values.email === "" || values.password === "") {
      setLoginError("Parameters missing")
    } else {
      handleSpinner()
      try {
        const dataVal = "email=" + values.email + "&pass=" + values.password
        const response = await loginAPI(dataVal)
        if (response.status === 200) {
          history.push('/dashboard')

          localStorage.setItem('uInfo', JSON.stringify(response.data.data))

          localStorage.setItem("userId", response.data.data.emp._id)
          localStorage.setItem("userWhatsApp", response.data.data.emp.whatsapp)
          localStorage.setItem("userName", response.data.data.emp.name)
          localStorage.setItem("userEmail", response.data.data.emp.email)
          localStorage.setItem("userEmailVerified", response.data.data.emp.emailVerified)
          localStorage.setItem("userWhatsAppVerified", response.data.data.emp.whatsappVerified)

          localStorage.setItem("bId", response.data.data.business._id)
          localStorage.setItem("bName", response.data.data.business.name)
          localStorage.setItem("bWebsite", response.data.data.business.website)
          localStorage.setItem("userRole", response.data.data.business.role)
          localStorage.setItem("onboardingStatus", response.data.data.business.onboardingStatus)

          response.data.data?.channels?.map((v, i) => {
            localStorage.setItem("c" + (i + 1), response.data.data?.channels[i]?.whatsapp)
            localStorage.setItem("cObj", JSON.stringify(response.data?.data?.channels))
          })

          localStorage.setItem("userAuthToken", response.data.data.authToken)
          localStorage.setItem("isDemo", false)

          localStorage.removeItem("userEmailT")
          localStorage.removeItem("userNameT")

          localStorage.setItem("userActivity", false)
          localStorage.setItem('botLeftSidebar', 'false')
        } else {
          setStartSpinner(false)
          Logout()
        }
      } catch (error) {
        setStartSpinner(false)
        setLoginError(JSON.stringify(error.response.data.message))
      }
    }
  }

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (values.email !== "" || values.password !== "") {
        handleLoginSubmit()
      }
    }
  }

  return (
    <>
      <TextField fullWidth id="email" type={"email"} label="Email" value={values.email} defaultValue={localStorage.getItem("userEmailT") ? localStorage.getItem("userEmailT") : ""} onChange={handleChange('email')} variant="outlined" required />
      <FormControl fullWidth className="mt-3" variant="outlined">
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput id="password" type={values.showPassword ? 'text' : 'password'} value={values.password} onKeyDown={(e) => handleOnKeyDown(e)} onChange={handleChange('password')} endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password Visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end" >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>} label="Password" required />
      </FormControl>
      <div>
        <p className="float-end mt-1 text-primary hand"><Link to={WtUrl.changePasswordWithEmailUrl} style={{ color: WtColor.ProcessingBlue }}>Forgot your password?</Link></p>
        <br />
      </div>
      <p className="text-center">
        <Button fullWidth className="pe-5 ps-5 pt-2 pb-2 bRound text-white mt-3 theme_bg" onClick={handleLoginSubmit} style={{ border: "1px solid #E6F0E4" }} variant="contained">
          Login &nbsp;
          {
            (startSpinner === true) ? (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
            />) : (<></>)
          }
        </Button>
      </p>
      {
        (loginUpError === "Login Successful!!!") ? (<p className="text-center theme">{loginUpError}</p>) : (<p className="text-center text-danger">{loginUpError}</p>)
      }
      <div className="mt-4 pb-5">
        <p className="hand pb-2" style={{ color: WtColor.TextColor }}>Want to register your Business? <Link to={"/signup"} style={{ color: WtColor.BaseColor }} className="fw-semibold">Sign Up</Link></p>
      </div>
    </>
  )
}
// End of Login Work


// Change & Forgot Password UI
export const ChangePassword = () => {
  const { width } = useWindowDimensions()
  return (
    <>
      <Container className="signUpBox" style={{ background: "#0F6550" }} fluid>
        {
          (width <= 780) ? (
            <Container fluid>
              <Row className="signForm">
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <p className="ps-2 pt-3">
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="30px" style={{ marginTop: "-8px" }} alt={WtTheme.SiteName} /></Link>
                    <span className="text-white fw-bold" style={{ fontSize: "20px" }}> &nbsp;Get Started with WT Business</span>
                  </p>
                  <div className="signForm">
                    <div className="bg-white mt-5 mb-5 signForm">
                      <h4 className="text-center pt-5">Reset Password</h4>
                      <div className="pt-5 pe-5 ps-5 mb-5">
                        <ChangePasswordForm />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <div className="signForm">
                    <h3 className="text-white mt-5">Get Started with WhatsTool Business</h3>
                    <div className="pt-5 ps-5 mt-5" style={{ background: "#EFEFEF", borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">WhatsTool solutions can<br />grow your business with WhatsApp</h5>
                          <p className="mt-4" style={{ fontSize: "20px" }}>Powered by WhatsApp Official Business Api</p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="150px" style={{ marginTop: "-40px", height: "180px" }} alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5 ps-3 pb-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col xxl={7} xl={7} ld={7} md={7} sm={12} xs={12}>
                  <div className="mb-5 pb-5" style={{ marginLeft: "30px", marginTop: "30px" }}>
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} /> &nbsp;<img style={{ marginTop: "1px" }} src={WtTheme.ImageBaseUrl + "/logo_text_white.png"} width="150px" alt={WtTheme.SiteName} /></Link>
                    <h3 className="text-white mt-5">Get Started with WhatsTool Business</h3>
                    <div className="pt-5 ps-5 mt-5" style={{ background: "#EFEFEF", borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">WhatsTool solutions can<br />grow your business with WhatsApp</h5>
                          <p className="mt-4" style={{ fontSize: "20px" }}>Powered by WhatsApp Official Business Api</p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="150px" style={{ marginTop: "-40px", height: "180px" }} alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} xl={5} ld={5} md={5} sm={12} xs={12}>
                  <div className="mt-5 pt-4" style={{ marginTop: "100px", marginBottom: "100px" }}>
                    <div className="bg-white mt-5 mb-5" style={{ marginLeft: "24px", marginRight: "24px", borderRadius: "10px" }}>
                      <h4 className="text-center pt-5">Reset Password</h4>
                      <div className="pt-5 pe-5 ps-5 mb-5">
                        <ChangePasswordForm />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )
        }
        <p>
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
        </p>
      </Container>
    </>
  )
}
export const ChangePasswordForm = () => {

  const [loginUpError, setLoginError] = useState("")

  const [values, setValues] = useState({ email: '' })
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const [startSpinner, setStartSpinner] = useState(false)
  const handleSpinner = () => {
    setStartSpinner(!startSpinner)
  }

  const handleResetPasswordLink = async () => {
    if (values.email === "" || values.password === "") {
      setLoginError("Parameters missing")
    } else {
      handleSpinner()
      let responseVal = ""
      try {
        let dataVal = "email=" + values.email
        const response = await sendResetPasswordLinkAPI(dataVal)
        responseVal = response.data.message
        // console.log(JSON.stringify(response.data))
        if (responseVal === "Success") {
          setLoginError("Reset Password Link sent to your email!!!")
          setStartSpinner(false)
          // history.push('/forgot-password')
        } else {
          setStartSpinner(false)
          Logout()
        }
      } catch (error) {
        setStartSpinner(false)
        setLoginError(JSON.stringify(error.response.data.message))
        // console.log(error.response.data.message)
      }
    }
  }
  return (
    <>
      <TextField fullWidth id="email" type={"email"} label="Email" value={values.email} onChange={handleChange('email')} variant="outlined" required />
      <p className="text-center">
        <Button fullWidth className="pe-5 ps-5 pt-2 pb-2 bRound text-white mt-3 theme_bg" onClick={handleResetPasswordLink} style={{ border: "1px solid #E6F0E4" }} variant="contained">
          Send Reset Password Link &nbsp;
          {
            (startSpinner === true) ? (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
            />) : (<></>)
          }
        </Button>
      </p>
      {
        (loginUpError === "Reset Password Link sent to your email!!!") ? (<p className="text-center theme">{loginUpError}</p>) : (<p className="text-center text-danger">{loginUpError}</p>)
      }

      <div className="mt-5 pb-5" style={{ lineHeight: "0.4" }}>
        <p className="text-center mb-5 text-primary hand"><Link to={WtUrl.loginUrl} className="btn btn-sm text-white theme_bg bRound ps-4 pe-4 border-white shadow-lg">Back to Login</Link></p>
      </div>
    </>
  )
}

export const ForgotPassword = () => {
  const { width } = useWindowDimensions()
  return (
    <>
      <Container className="signUpBox" style={{ background: "#0F6550" }} fluid>
        {
          (width <= 780) ? (
            <Container fluid>
              <Row className="signForm">
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <p className="ps-2 pt-3">
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="30px" style={{ marginTop: "-8px" }} alt={WtTheme.SiteName} /></Link>
                    <span className="text-white fw-bold" style={{ fontSize: "20px" }}> &nbsp;Get Started with WT Business</span>
                  </p>
                  <div className="signForm">
                    <div className="bg-white mt-5 mb-5 signForm">
                      <h4 className="text-center pt-5">Forgot Password</h4>
                      <div className="pt-5 pe-5 ps-5 mb-5">
                        <ForgotPasswordForm />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <div className="signForm">
                    <div className="bg-white pt-5 ps-5 mt-5" style={{ borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="180px" alt={WtTheme.SiteName} /></p>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="signForm">
                          <h5 className="fw-bold">WhatsTool solutions can<br />grow your business with WhatsApp</h5>
                          <p className="mt-3 pb-4" style={{ fontSize: "15px" }}>Powered by WhatsApp Official Business Api</p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5 ps-3 pb-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col xxl={7} xl={7} ld={7} md={7} sm={12} xs={12}>
                  <div className="mb-5 pb-5" style={{ marginLeft: "30px", marginTop: "30px" }}>
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} /> &nbsp;<img style={{ marginTop: "1px" }} src={WtTheme.ImageBaseUrl + "/logo_text_white.png"} width="150px" alt={WtTheme.SiteName} /></Link>
                    <h3 className="text-white mt-5">Get Started with WhatsTool Business</h3>
                    <div className="bg-white pt-5 ps-5 mt-5" style={{ borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">WhatsTool solutions can<br />grow your business with WhatsApp</h5>
                          <p className="mt-5">Powered by WhatsApp Official Business Api</p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="180px" alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} xl={5} ld={5} md={5} sm={12} xs={12}>
                  <div className="mt-5 pt-4" style={{ marginTop: "100px", marginBottom: "100px" }}>
                    <div className="bg-white mt-5 mb-5" style={{ marginLeft: "24px", marginRight: "24px", borderRadius: "10px" }}>
                      <h4 className="text-center pt-5">Forgot Password</h4>
                      <div className="pt-5 pe-5 ps-5 mb-5">
                        <ForgotPasswordForm />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )
        }
        <p>
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
        </p>
      </Container>
    </>
  )
}
export const ForgotPasswordForm = () => {
  const history = useHistory()
  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  let resetPassToken = query.get("resetPassToken")

  const [loginUpError, setLoginError] = useState("")

  const [values, setValues] = useState({ new_pass: '', re_enter_pass: '' })
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const [startSpinner, setStartSpinner] = useState(false)
  const handleSpinner = () => {
    setStartSpinner(!startSpinner)
  }

  const handleForgotPassword = async () => {
    if (values.new_pass === "" || values.re_enter_pass === "") {
      setLoginError("Parameters missing")
    } else if (values.new_pass.length >= 8) {
      if (values.new_pass === values.re_enter_pass) {
        handleSpinner()
        let responseVal = ""
        try {
          const dataVal = "resetPassToken=" + resetPassToken + "&newPass=" + values.new_pass
          const response = await resetPasswordAPI(dataVal)
          responseVal = response.data.data.success
          // console.log(JSON.stringify(response.data))
          if (responseVal === true) {
            history.push('/reset_pass_result?success=true')
          } else if (responseVal === false) {
            history.push('/reset_pass_result?success=false')
          } else {
            setStartSpinner(false)
            Logout()
          }
        } catch (error) {
          setStartSpinner(false)
          setLoginError(JSON.stringify(error.response.data.message))
        }
      } else if (values.new_pass !== values.re_enter_pass) {
        setLoginError("Password is not matching, try again !!!")
      }
    } else if (values.new_pass.length < 8) {
      setLoginError("Password length must greater than 8 (length should be 8 to 16)")
    }
  }
  return (
    <>
      <TextField fullWidth id="new_pass" type={"text"} label="New Password" value={values.new_pass} onChange={handleChange('new_pass')} variant="outlined" required />
      <TextField className="mt-3" fullWidth id="re_enter_pass" type={"text"} label="Re-enter Password" value={values.re_enter_pass} onChange={handleChange('re_enter_pass')} variant="outlined" required />
      <p className="text-center">
        <Button fullWidth className="pe-5 ps-5 pt-2 pb-2 bRound text-white mt-3 theme_bg" onClick={handleForgotPassword} style={{ border: "1px solid #E6F0E4" }} variant="contained">
          Change Password &nbsp;
          {
            (startSpinner === true) ? (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
            />) : (<></>)
          }
        </Button>
      </p>
      {
        (loginUpError === "Login Successful!!!") ? (<p className="text-center theme">{loginUpError}</p>) : (<p className="text-center text-danger">{loginUpError}</p>)
      }

      <div className="mt-5 pb-5" style={{ lineHeight: "0.4" }}>
        <p className="text-center mb-5 text-primary hand"><Link to={WtUrl.loginUrl} className="btn btn-sm text-white theme_bg bRound ps-4 pe-4 border-white shadow-lg">Back to Login</Link></p>
      </div>
    </>
  )
}
// End of Change & Forgot Password UI


// Signup Work
export const Signup = () => {
  const { width } = useWindowDimensions()
  return (
    <>
      <Container className="signUpBox" style={{ background: "#0F6550" }} fluid>
        {
          (width <= 780) ? (
            <Container fluid>
              <Row className="signForm">
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <p className="ps-2 pt-3">
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="30px" style={{ marginTop: "-8px" }} alt={WtTheme.SiteName} /></Link>
                    <span className="text-white fw-bold" style={{ fontSize: "20px" }}> &nbsp;Get Started with WT Business</span>
                  </p>
                  <div className="signForm">
                    <div className="bg-white mt-5 mb-5 signForm">
                      <h4 className="text-center pt-5">Sign up</h4>
                      <div className="pt-5 pe-3 ps-3 mb-3">
                        <SignUpForm />
                        <p className="hand mt-5 pb-5" style={{ color: WtColor.TextColor }}>Already a User? <Link to={"/login"} style={{ color: WtColor.BaseColor }} className="fw-semibold">Login</Link></p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={12} xl={12} ld={12} md={12} sm={12} xs={12} className="signForm">
                  <div className="signForm">
                    <div className="pt-5 ps-5 mt-5" style={{ background: "#EFEFEF", borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">Signup now to get an exciting offer🤗</h5>
                          <p className="mt-4" style={{ fontSize: "20px" }}>Get chatbot🤖 running on your WhatsApp API number in minutes without any cost </p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="150px" style={{ marginTop: "-40px", height: "180px" }} alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5 ps-3 pb-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col xxl={7} xl={7} ld={7} md={7} sm={12} xs={12}>
                  <div style={{ marginLeft: "30px", marginTop: "30px" }}>
                    <Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} />&nbsp; <img style={{ marginTop: "1px" }} src={WtTheme.ImageBaseUrl + "/logo_text_white.png"} width="150px" alt={WtTheme.SiteName} /></Link>
                    <h3 className="text-white mt-5">Get Started with WhatsTool Business</h3>
                    <div className="pt-5 ps-5 mt-5" style={{ background: "#EFEFEF", borderRadius: "4px" }}>
                      <Row>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                          <h5 className="fw-bold">Signup now to get an exciting offer🤗</h5>
                          <p className="mt-4" style={{ fontSize: "20px" }}>Get chatbot🤖 running on your WhatsApp API number in minutes without any cost </p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                          <p className="text-center"><img src={WtTheme.ImageBaseUrl + "/signup_01.png"} width="150px" style={{ marginTop: "-40px", height: "180px" }} alt={WtTheme.SiteName} /></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-5">
                      <ul>
                        <li className="text-white" style={{ fontSize: "24px" }}>Chat Bots</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Team Chat Support</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Bulk Messaging</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Notifications</li>
                        <li className="text-white" style={{ fontSize: "24px" }}>Integrations</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} xl={5} ld={5} md={5} sm={12} xs={12}>
                  <div style={{ marginTop: "100px", marginBottom: "100px" }}>
                    <div className="bg-white mt-5 mb-5" style={{ marginLeft: "24px", marginRight: "24px", borderRadius: "10px" }}>
                      <h4 className="text-center pt-5">Sign up</h4>
                      <div className="pt-3 pe-5 ps-5 mb-3">
                        <SignUpForm />
                        <p className="hand mt-5 pb-5" style={{ color: WtColor.TextColor }}>Already a User? <Link to={"/login"} style={{ color: WtColor.BaseColor }} className="fw-semibold">Login</Link></p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )
        }

      </Container>
    </>
  )
}
export const SignUpForm = () => {
  const history = useHistory()
  const theme = useTheme()

  const [values, setValues] = useState({ oName: '', bName: '', bEmail: '', whatsapp_no: '', website_url: '', bPass: '' })
  const handleChange = (prop) => (event) => { setValues({ ...values, [prop]: event.target.value }) }

  const handleClickShowPassword = () => { setValues({ ...values, showPassword: !values.showPassword, }) }
  const handleMouseDownPassword = (event) => { event.preventDefault() }

  const [openCountryCodeDailog, setOpenCountryCodeDailog] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleCountryCodeDailog = () => {
    if (openCountryCodeDailog === true) {
      setOpenCountryCodeDailog(false)
    } else {
      setOpenCountryCodeDailog(true)
    }
  }

  const [currentCountryCode, setCurrentCountryCode] = useState("+91")
  const handleCountryCodeSelection = (e) => {
    setOpenCountryCodeDailog(false)
    setCurrentCountryCode(e)
  }

  const [countryCodeList, setCountryCodeList] = useState([])
  const getCountryCode = async () => {
    const countryCodeResponse = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
    setCountryCodeList(await countryCodeResponse.json())
  }

  const [filteredCountryCodeList, setFilteredCountryCodeList] = useState(countryCodeList)
  useEffect(() => { setFilteredCountryCodeList(countryCodeList) }, [countryCodeList])
  const searchItems = (searchValue) => {
    const filteredData = countryCodeList.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
    })
    if (filteredData.length !== 0) { setFilteredCountryCodeList(filteredData) } else { setFilteredCountryCodeList([]); }
  }

  const [startSpinner, setStartSpinner] = useState(false)
  const handleSpinner = () => { setStartSpinner(!startSpinner) }

  const [signUpError, setSignUpError] = useState("")
  const handleSignupSubmit = async () => {
    if (values.oName === "" || values.bName === "" || values.bEmail === "" || values.whatsapp_no === "" || values.website_url === "" || values.bPass === "") {
      setSignUpError("Parameters missing")
    } else if (values.bPass.length >= 8) {
      handleSpinner()
      try {
        const data = "name=" + values.oName + "&businessName=" + values.bName + "&email=" + values.bEmail + "&whatsapp=" + currentCountryCode + "-" + values.whatsapp_no + "&website=" + values.website_url + "&pass=" + values.bPass
        const response = await signupAPI(data)
        if (response.status === 201) {
          history.push('/signup-success')
          setSignUpError("")
          localStorage.setItem("userNameT", values.oName)
          localStorage.setItem("userEmailT", values.bEmail)
        } else {
          setStartSpinner(false)
        }
      } catch (error) {
        setStartSpinner(false)
        setSignUpError(JSON.stringify(error.response.data.message))
      }
    } else if (values.bPass.length < 8) {
      setSignUpError("Password length must greater than 8")
    }
  }

  useEffect(() => { getCountryCode() }, [])
  return (
    <>
      <TextField fullWidth className="mb-3" id="oName" type={"text"} label="Owner Name" value={values.oName} onChange={handleChange('oName')} variant="filled" required />
      <TextField fullWidth className="mb-3" id="bName" type={"text"} label="Business Name" value={values.bName} onChange={handleChange('bName')} variant="filled" required />
      <TextField fullWidth className="mb-3" id="bEmail" type={"email"} label="Business Email Id" value={values.bEmail} onChange={handleChange('bEmail')} variant="filled" required />
      <TextField fullWidth className="mb-3" id="website_url" type={"text"} label="Business Website" value={values.website_url} onChange={handleChange('website_url')} variant="filled" required />
      <TextField fullWidth className="mb-3" id="whatsapp_no" type={"tel"} label="WhatsApp Number"
        InputProps={{
          startAdornment: <InputAdornment position="start" className="hand"><span className="text-dark" onClick={() => handleCountryCodeDailog()}>{currentCountryCode}</span>&nbsp;&nbsp; | </InputAdornment>,
        }}
        value={values.whatsapp_no} onChange={handleChange('whatsapp_no')} variant="filled" required />

      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
        <FilledInput
          id="filled-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.bPass}
          onChange={handleChange('bPass')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password Visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <p className="text-center">
        <Button className="mt-3 bRound pe-5 ps-5 pt-2 pb-2 theme_bg" onClick={handleSignupSubmit} style={{ border: "1px solid #E6F0E4" }} variant="contained">
          Sign Up &nbsp;
          {
            (startSpinner === true) ? (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
            />) : (<></>)
          }
        </Button>
      </p>
      {
        (signUpError === "Signup Successful!!!") ? (<p className="text-center theme">{signUpError}</p>) : (<p className="text-center text-danger">{signUpError}</p>)
      }

      <div>
        <Dialog fullScreen={fullScreen} maxWidth={'sm'} open={openCountryCodeDailog} onClose={handleCountryCodeDailog} aria-labelledby="country-code-responsive-dialog-box">
          <DialogTitle id="country-code-responsive-dialog-box">Choose Country Code</DialogTitle>
          <div className="m-3">
            <TextField fullWidth id="search-country-code" onChange={(e) => searchItems(e.target.value)} type={"text"} label="Search" variant="outlined" />
          </div>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: 420 }}>
              <DialogContentText>
                <List sx={{ width: '100%', maxWidth: 420, bgcolor: 'background.paper' }}>
                  {
                    filteredCountryCodeList.map((v, i) => {
                      return (
                        <ListItemButton className="hand" key={i}>
                          <ListItem onClick={(e) => handleCountryCodeSelection(v.dial_code)} >
                            <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                            <ListItemText primary={v.name} secondary={v.dial_code} />
                          </ListItem>
                        </ListItemButton>
                      )
                    })
                  }
                </List>
              </DialogContentText>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}
// End of Signup Work

export const SignupSuccessOld = () => {
  return (
    <Container className="pt-3 mb-5 pb-5">
      <Row>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <p className='mt-2'><Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} /></Link></p>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <p className="float-end">
            <Link to={"/"}>
              <IconButton>
                <Avatar>
                  <CloseOutlined />
                </Avatar>
              </IconButton>
            </Link>
          </p>
        </Col>
      </Row>

      <p className="text-center mt-5"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/signup_success_01.png"} width="360px" className="img-fluid" alt={"WhatsApp Business"} /></p>
      <h2 className="text-center mt-5">Thank you for Signing In to WhatsTool Business</h2>
      <h5 className="text-center mt-3">We've sent you a verification link on <span className="fw-bold theme">Email</span> &amp; an OTP on <span className="fw-bold theme">WhatsApp</span>.</h5>
      <div className="mt-5 mb-5">
        <p className="text-center">
          <Link to={WtUrl.loginUrl} className="btn btn-md theme_bg text-white fw-bold bRound ps-5 pe-5">Login Now !</Link>
        </p>
      </div>
      <h5 className="text-center"> For any further inquiries contact us on <span><Link to={{ pathname: "https://wa.me/919844344170?text=Hi WhatsTool Business Team, I need to know about WhatsTool Business." }} target="_blank"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/signup_success_whatsapp.png"} width="50px" className="img-fluid" alt={"WhatsApp Business"} /></Link><Link to={{ pathname: "mailto:support@whatstool.in" }} target="_blank"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/signup_success_mail.png"} width="50px" className="img-fluid" alt={"WhatsApp Business"} /></Link></span></h5>
      {/* <h5 className="text-center mt-3">Book a Demo to know more about how it's work <span><Link to={{ pathname: "https://calendly.com/sujeet_whatstool/30min" }} target="_blank" className="btn btn-sm bRound fw-bold ps-3 pe-3 theme" style={{ border: "2px solid #0E5E53" }}>Book a Demo</Link></span></h5> */}
    </Container>
  )
}
export const EmailVerification = () => {
  return (
    <Container className="pt-3 mb-5 pb-5">
      <Row>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <p className='mt-2'><Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} /></Link></p>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <p className="float-end">
            <Link to={"/"}>
              <IconButton>
                <Avatar>
                  <CloseOutlined />
                </Avatar>
              </IconButton>
            </Link>
          </p>
        </Col>
      </Row>

      <p className="text-center mt-5"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/signup_success_01.png"} width="360px" className="img-fluid" alt={"WhatsApp Business"} /></p>
      <h3 className="text-center mt-5">Email Verification Successful</h3>
      <div className="mt-5 mb-5">
        <p className="text-center">
          <Link to={"/login"} className="btn btn-md theme_bg text-white fw-bold bRound ps-5 pe-5">Login Now !</Link>
        </p>
      </div>
    </Container>
  )
}
export const WhatsAppVerification = () => {
  return (
    <Container className="pt-3 mb-5 pb-5">
      <Row>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <p className='mt-2'><Link to={"/"}><img src={WtTheme.ImageBaseUrl + "/wtb_green_round_images.png"} width="40px" alt={WtTheme.SiteName} /></Link></p>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
          <p className="float-end">
            <Link to={"/"}>
              <IconButton>
                <Avatar>
                  <CloseOutlined />
                </Avatar>
              </IconButton>
            </Link>
          </p>
        </Col>
      </Row>

      <p className="text-center mt-5"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/signup_success_01.png"} width="360px" className="img-fluid" alt={"WhatsApp Business"} /></p>
      <h3 className="text-center mt-5">WhatsApp Verification Successful</h3>
      <div className="mt-5 mb-5">
        <p className="text-center">
          <Link to={"/login"} className="btn btn-md theme_bg text-white fw-bold bRound ps-5 pe-5">Login Now !</Link>
        </p>
      </div>
    </Container>
  )
}
export const OtpVerification = () => {
  const history = useHistory()
  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  let wa_number = query.get("whatsapp")
  const [otp, setOtp] = useState('')
  const VerifyOTP = async () => {
    try {
      let data = Env.baseApiCallURL + '/registration/verify?whatsapp=' + wa_number + '&otp=' + otp
      const response = await otpVerificationAPI(data)
      if (response.status === 200) {
        setTimeout(() => { }, 4000)
        history.push('/whatsapp-verification')
      } else { }
    } catch (error) { }
  }

  const [sendOtpAgain, setSendOtpAgain] = useState(0)

  const ResendOTP = async () => {
    try {
      let data = "whatsapp=" + wa_number;
      const response = await resendOtpAPI(data)
      if (response.status === 200) {
        setSendOtpAgain(1)
      } else {
        alert(response.data.message)
      }
    } catch (error) { }
  }

  return (
    <Container className="pt-3 mb-5 pb-5">
      <Row>
        <Col></Col>
        <Col>
          <h3 className="text-center mt-5 theme">WhatsApp<br />OTP Verification</h3>
          <p className="text-center theme_lite fw-bold mt-4">Your WhatsApp No.: +{query.get("whatsapp")}</p>
          <input type={"tel"} value={otp} maxLength={4} onChange={(e) => setOtp(e.target.value)} style={{ display: 'block', margin: 'auto' }} className="form-control mt-4 w-50" placeholder="Enter OTP" />
          <p className="text-center"><button onClick={VerifyOTP} className="btn btn-md theme_bg text-white mt-3">Submit OTP</button></p>
          <p className="text-center">or</p>
          {(sendOtpAgain === 0) ? (<p className="text-center"><button onClick={ResendOTP} className="btn btn-sm btn-info text-white">Resend OTP</button></p>) : (<p className="text-center text-info">Otp Sent !!!</p>)}
        </Col>
        <Col></Col>
      </Row>
    </Container>
  )
}


// Payment End Points
export const PaymentSuccess = () => {
  const history = useHistory()
  const handleBackToProfileBtn = () => {
    if (localStorage.getItem("onboardingStatus") === "buy_now_success" || localStorage.getItem("onboardingStatus") === "dialog360_purchase") {
      history.push('/dashboard')
    } else {
      history.push('/dashboard/payment/plan-subscription')
    }
  }

  const [btnText, setBtnText] = useState("Back to Plan Page")

  if (localStorage.getItem("onboardingStatus") === "need_help") {
    setBtnText("Go to Dashboard")
    localStorage.setItem("onboardingStatus", "buy_now_success")
    localStorage.setItem("addons_p", "success")
  } else if (localStorage.getItem("onboardingStatus") === "dialog360_purchase") {
    localStorage.setItem("onboardingStatus", "dialog360_purchase")
    localStorage.setItem("addons_p", "dialog360_buy_success")
  }

  useEffect(() => { setTimeout(() => { handleBackToProfileBtn() }, 2000) }, [])
  return (
    <Container>
      <p className="text-center m-2">
        <Player autoplay src="https://assets4.lottiefiles.com/packages/lf20_ufoeuntu.json" className="items-lottie"></Player>
        <Button variant='contained' onClick={handleBackToProfileBtn} style={{ fontSize: "18px" }} className="btn btn-sm mt-3 pt-2 pb-2 ps-5 pe-5 theme_bg text-white bRound">{btnText}</Button>
      </p>
    </Container>
  )
}
export const PaymentFailed = () => {
  const history = useHistory()
  const handleBackToProfileBtn = () => {
    history.push('/dashboard/payment/plan-subscription')
  }
  return (
    <Container>
      <p className="text-center m-2">
        <Player autoplay loop src="https://assets3.lottiefiles.com/packages/lf20_Dum1s3.json" className="items-lottie"></Player>
        <Button variant='contained' onClick={handleBackToProfileBtn} style={{ fontSize: "18px" }} className="btn btn-sm mt-3 pt-2 pb-2 ps-5 pe-5 theme_bg text-white bRound">Back to Profile</Button>
      </p>
    </Container>
  )
}
// End of End Points