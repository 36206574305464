/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { FormControlLabel, FormGroup, Menu, MenuItem, Switch } from '@mui/material'
import { AddOutlined, MoreVert, OpenInNew } from '@mui/icons-material'
import { Table } from 'react-bootstrap'
import { BotFlowOptionDialog, CreateNewBotDialog } from './Dialogs'
import { ConfirmDialog, DemoDialog, ShowDateTime } from '../../controller/BaseTool'
import chatbotContext from '../../context/chatbot/chatbotContext'
import ChatbotState from '../../context/chatbot/chatbotState'
import globalContext from '../../context/global/globalContext'
import { WtTheme } from '../../controller/theme'
import { WtColor } from '../../constants/colours'
import { WtDimen } from '../../constants/dimens'
import { WtString } from '../../constants/strings'
import { deleteChatBotFlow } from '../../controller/APIs'
import { useHistory } from 'react-router-dom'

export const ChatbotListUI = () => { return (<ChatbotState><ChatbotListUIApp /></ChatbotState>) }

export const ChatbotListUIApp = () => {
    const botLs = useContext(chatbotContext)
    const getBotLs = useContext(chatbotContext)
    const botSwitch = useContext(chatbotContext)
    const handleBotStatusSwitchAction = useContext(chatbotContext)
    const notificationBarStatus = useContext(globalContext)

    const history = useHistory()
    const openURL = (url) => history.push(url)

    const [openBotFlowOptionDialog, setOpenBotFlowOptionDialog] = useState({ open: false, title: "", id: "", ch: [], onClose: null })
    const openBotFlowOptionDialogBox = (title, id, ch) => {
        setOpenBotFlowOptionDialog({
            open: true, title: title, id: id, ch: ch, onClose: (confirm) => {
                setOpenBotFlowOptionDialog(false)
                if (confirm) getBotLs.getBotLs()
            }
        })
    }

    const [openCreateNewBotDialog, setOpenCreateNewBotDialog] = useState({ open: false, onClose: null })
    const openCreateNewBotDialogBox = (searchValue) => {
        setOpenCreateNewBotDialog({
            open: true, searchValue: searchValue, onClose: (confirm) => {
                setOpenCreateNewBotDialog(false)
                if (confirm) getBotLs.getBotLs()
            }
        })
    }

    const [searchValue, setSearchValue] = useState('')
    const [searchLs, setSearchLs] = useState([])
    useEffect(() => { setSearchLs(botLs.botLs) }, [botLs.botLs])
    const searchItems = (sv) => {
        setSearchValue(sv)
        const filteredData = botLs.botLs.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }

    const openBotFlow = (chatBotId) => openURL("/dashboard/build-chatbots?botIndex=" + chatBotId)

    const [openDemoDialog, setOpenDemoDialog] = useState({ open: false, onClose: null })
    const openDemoDialogBox = () => setOpenDemoDialog({ open: true, onClose: () => setOpenDemoDialog(false) })

    useEffect(() => { getBotLs.getBotLs() }, [])

    const handleOpenEdit = (title, id, ch) => {
        localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openBotFlowOptionDialogBox(title, id, ch) : openDemoDialogBox()
    }

    const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, title: '', msg: '', onClose: null })
    const deleteBot = async (id) => {
        setOpenConfirmDialog({
            open: true, title: 'Whatstool Business', msg: 'Are you sure, you want to Delete this Bot?', onClose: async (confirm) => {
                setOpenConfirmDialog(false)
                if (confirm) {
                    try {
                        let data = { "_id": id }
                        const response = await deleteChatBotFlow(data)
                        if (response.status === 204) getBotLs.getBotLs()
                    } catch (error) { }
                }
            }
        })
    }
    return (
        <div className="chatbot_ui">
            <div className="ps-3 pt-2 pb-1 pe-5" style={{ display: "flex", borderBottom: '1px solid ' + WtColor.LineColor }}>
                <div style={{ flex: 1, display: 'flex', paddingTop: "4px" }}>
                    <h5 style={{ color: WtColor.TextDarkColor, fontSize: '22px' }}>ChatBot</h5>
                    <div style={{ display: "flex", border: "1px solid" + WtColor.LineColor, borderRadius: WtDimen.borderRadius, marginTop: '-3px', marginBottom: '2px' }} className="w-25 ms-5">
                        <img src={WtTheme.ImageBaseUrl + "/search.svg"} style={{ width: '20px', marginLeft: '3px' }} alt={WtString.SiteName} />
                        <input type="search" value={searchValue} maxLength={30} onChange={(e) => searchItems(e.target.value)} className="w-100" style={{ border: "none", outline: "none", paddingLeft: "6px", borderRadius: WtDimen.borderRadius, fontSize: '14px' }} placeholder="Search" />
                    </div>
                </div>
                <div>
                    <button onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openCreateNewBotDialogBox() : openDemoDialogBox()} className="btn btn-sm float-end" style={{ background: WtColor.BaseColor, color: '#fff', borderRadius: WtDimen.borderRadius, marginTop: '2px' }} ><AddOutlined style={{ fontSize: '18px', marginTop: '-4px' }} /> Create Chatbot</button>
                </div>
            </div>
            <div className='list_scroll me-3 mt-2' style={{ height: notificationBarStatus.notificationBarStatus ? '600px' : '640px' }}>
                <Table className="fw-bold" responsive>
                    <thead style={{ background: WtColor.LineColor, color: WtColor.TextLightGrey, fontSize: '14px' }}>
                        <tr><th className="ps-3">Bot Title</th><th>Created on</th><th>Action</th><th>Status</th></tr>
                    </thead>
                    <tbody style={{ background: "#fff", fontSize: "12px" }}>
                        {
                            searchLs?.map((v, i) => {
                                return (
                                    <tr className="hand" key={i}>

                                        <td className="ps-3" onClick={() => openBotFlow(v._id)} style={{ color: WtColor.TextLightGrey }}><p style={{ paddingTop: '4px' }}>{v.title}</p></td>
                                        <td onClick={() => openBotFlow(v._id)} style={{ color: WtColor.TextLightGrey }}><p style={{ paddingTop: '4px' }}>{ShowDateTime(v.createDate)}</p></td>
                                        <td>
                                            <OpenInNew onClick={() => openBotFlow(v._id)} style={{ color: WtColor.TextLightGrey, marginTop: "2px", fontSize: '20px' }} />&nbsp;&nbsp;&nbsp;
                                            <OptionsUI v={v} deleteAction={deleteBot} openEdit={handleOpenEdit} />
                                        </td>
                                        <td >
                                            <FormGroup style={{ marginTop: '-6px' }}>
                                                <FormControlLabel label={botSwitch.botSwitch['switch-' + i] ? "Live" : "Not Live"} control={<Switch checked={botSwitch.botSwitch['switch-' + i]} name={"liveSwitch"} color="primary" onChange={(event, checked) => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? handleBotStatusSwitchAction.handleBotStatusSwitchAction(i, event, checked, v) : openDemoDialogBox()} />} />
                                            </FormGroup>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                {searchValue !== "" && searchLs?.length === 0 && <><p className="text-center" style={{ color: WtColor.TextLightGrey }}>Sorry! No such chatbot found...</p><p className="text-center" style={{ color: WtColor.TextDarkColor, fontSize: '18px' }}><span className="fw-bold">"{searchValue}"</span> would you like to <small className="hand" style={{ color: WtColor.BaseColor, paddingLeft: '6px', paddingRight: '6px', fontWeight: '500' }} onClick={() => localStorage.getItem('isDemo') && localStorage.getItem('isDemo') !== 'true' ? openCreateNewBotDialogBox(searchValue) : openDemoDialogBox()}>create one</small></p></>}
            </div>
            {openDemoDialog.open && <DemoDialog open={openDemoDialog.open} onClose={openDemoDialog.onClose} />}
            <BotFlowOptionDialog open={openBotFlowOptionDialog.open} title={openBotFlowOptionDialog.title} id={openBotFlowOptionDialog.id} ch={openBotFlowOptionDialog.ch} onClose={openBotFlowOptionDialog.onClose} />
            {openCreateNewBotDialog.open && <CreateNewBotDialog open={openCreateNewBotDialog.open} searchValue={openCreateNewBotDialog.searchValue} onClose={openCreateNewBotDialog.onClose} />}
            {openConfirmDialog.open && <ConfirmDialog open={openConfirmDialog.open} title={openConfirmDialog.title} message={openConfirmDialog.msg} onClose={openConfirmDialog.onClose} />}
        </div>

    )
}


export const OptionsUI = ({ v, deleteAction, openEdit }) => {
    // Option Menu Handing
    const [msgMenuAnchor, setMsgMenuAnchor] = useState(null)
    const open = Boolean(msgMenuAnchor)
    const handleMsgOptOpen = (event) => setMsgMenuAnchor(event.currentTarget)
    const handleMsgOptClose = () => setMsgMenuAnchor(null)
    const handleEdit = (event) => {
        openEdit(v.title, v._id, v.channelsAssigned)
        setMsgMenuAnchor(null)
    }
    const handleDelete = (event) => {
        deleteAction(v._id)
        setMsgMenuAnchor(null)
    }
    // End of Option Menu Handing
    return (
        <>
            <MoreVert onClick={handleMsgOptOpen} style={{ color: WtColor.TextLightGrey, marginTop: "2px", fontSize: '20px' }} />
            <>
                <Menu anchorEl={msgMenuAnchor} open={open} onClose={handleMsgOptClose}>
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
            </>
        </>
    )
}