import React, { useState } from 'react'
import axios from 'axios'
import { Env } from '../../controller/constant'
import { useHistory } from 'react-router-dom'

export const Testing = () => {
  const history = useHistory()

  const [mobile, setMobile] = useState('')

  const getMobile = (e) => {
    setMobile(e.target.value)
  }

  const genTokenForDemo = async () => {
    localStorage.clear()
    const payload = { mobile: mobile }
    const response = await axios.post(`${Env.baseApiCallURL}/demo/login`, payload)

    if (response.status === 200) {
      history.push('/dashboard')
      localStorage.setItem("userId", response.data.data.emp._id)
      localStorage.setItem("userWhatsApp", response.data.data.emp.whatsapp)
      localStorage.setItem("userName", response.data.data.emp.name)
      localStorage.setItem("userEmail", response.data.data.emp.email)
      localStorage.setItem("userEmailVerified", response.data.data.emp.emailVerified)
      localStorage.setItem("userWhatsAppVerified", response.data.data.emp.whatsappVerified)
      localStorage.setItem("bId", response.data.data.business._id)
      localStorage.setItem("bName", response.data.data.business.name)
      localStorage.setItem("bWebsite", response.data.data.business.website)
      localStorage.setItem("userRole", response.data.data.business.role)
      localStorage.setItem("onboardingStatus", response.data.data.business.onboardingStatus)
      localStorage.setItem('isDemo', true)
      localStorage.setItem('demoMobileNo', mobile)

      response.data.data?.channels?.map((v, i) => {
        localStorage.setItem("c" + (i + 1), response.data.data?.channels[i]?.whatsapp)
        localStorage.setItem("cObj", JSON.stringify(response.data?.data?.channels))
      })

      localStorage.setItem("userAuthToken", response.data.data.authToken)
      localStorage.removeItem("userEmailT")
      localStorage.removeItem("userNameT")
    }
  }

  return (
    <>
      <div>
        <p>Demo Account Login System</p>
        <input id='mobile' onChange={(e) => getMobile(e)} />
        <button onClick={genTokenForDemo}>Switch Demo</button>
      </div>
    </>
  )
}
