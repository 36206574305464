/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { WtUrl } from '../../constants/urls'

export const Settings = () => {
    const history = useHistory()
    const openUrl = (url) => { history.push(url) }
    useEffect(() => { openUrl(WtUrl.businessInformationUrl) }, [])
    return (
        <>
        </>
    )
}