/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import ChatbotContext from './chatbotContext'
import { fetchBotListAPI, updateBotStatusAPI } from '../../controller/APIs'
import { useNodesState, useEdgesState } from 'react-flow-renderer'

const ChatbotState = (props) => {
    // Loader Handling
    const [isLoading, setIsLoading] = useState(undefined)
    const handleWTLoader = (val) => setIsLoading(val)
    // End of Loader Handling


    const [botLs, setBotLs] = useState([])
    const [botSwitch, setBotSwitch] = useState({})
    const getBotLs = async () => {
        try {
            const response = await fetchBotListAPI()
            if (response.status === 200) {
                setBotLs(response.data.data)
                handleBotStatusSwitch(response.data.data)
            }
        } catch (error) { }
    }
    const updateBotStatus = async (flow, checked) => {
        let data = { isLive: checked, _id: flow._id }
        const response = await updateBotStatusAPI(data)
        if (response.data.status === 202) getBotLs()
    }
    const handleBotStatusSwitchAction = (index, event, checked, flow) => {
        const new1 = { ...botSwitch }
        new1['switch-' + index] = checked
        setBotSwitch(new1)
        updateBotStatus(flow, checked)
    }
    const handleBotStatusSwitch = (list) => {
        if (!list) { return }
        const botSwitch = {}
        list.forEach((item, index) => { botSwitch['switch-' + index] = item.isLive })
        setBotSwitch(botSwitch)
    }



    const [selectedNode, setSelectedNode] = useState(undefined)
    const [selectedNodeUpdated, setSelectedNodeUpdated] = useState(undefined)

    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])

    const [isBotActive, setIsBotActive] = useState(undefined)
    const [actionList, setActionList] = useState([])
    const [customFieldKeys, setCustomFieldKeys] = useState([])

    const [botId, setBotId] = useState("")

    return (
        <ChatbotContext.Provider
            value={{
                isLoading, handleWTLoader,

                botLs, getBotLs, botSwitch, handleBotStatusSwitchAction,

                selectedNode, setSelectedNode,
                selectedNodeUpdated, setSelectedNodeUpdated,
                nodes, setNodes, onNodesChange,
                edges, setEdges, onEdgesChange,

                isBotActive, setIsBotActive,
                actionList, setActionList,
                customFieldKeys, setCustomFieldKeys,

                botId, setBotId
            }}
        >
            {props.children}
        </ChatbotContext.Provider>
    )
}
export default ChatbotState